import React from "react";

export type CrudHeaderProps = {
    backgroundImage?: string,
    content?: React.ReactNode,
    className?: string,
    overlayClassName?: string,
}
export class CrudHeader extends React.Component<CrudHeaderProps> {

    render() {
        const props = this.props;
        const className = (props.className || "") + " flex-container CrudHeader";
        const overlayClassName = (props.overlayClassName || "") + " CrudHeader_overlay";
        return <div className={className + " CrudHeader_color"} style={props.backgroundImage ? { background: "url(" + (props.backgroundImage) + ") no-repeat 0 0 / cover" } : undefined}>
            <div className={className}>
                <div className={overlayClassName}>
                    <span className="CrudHeader_blur wh100" />
                    {props.content}
                </div>
            </div>
        </div>;
    }
}