import { Draggable, Droppable } from "@crispico/foundation-react/components/DragAndDrop/DragAndDrop";
import React from "react";
import { FlightItem, GANTT_ITEM_TYPES, HumanResourceScheduleItem } from "./Gantt";

export const FLIGHT_ARRIVAL_COLOR: string = '#FFA200';
export const FLIGHT_DEPARTURE_COLOR: string = '#0DC22C';
const SCHEDULE_DROP_ACCEPT_TYPE: string = 'flight-or-task';

export class GanttItemRenderer extends React.Component<{ item: any }>  {

    render() {
        switch (this.props.item.type) {   
            case GANTT_ITEM_TYPES.FLIGHT:
                return <FlightRenderer item={this.props.item} />
            case GANTT_ITEM_TYPES.OBJECTS:
                return <TasksAndObjectsRenderer item={this.props.item} />
            case GANTT_ITEM_TYPES.TASKS:
                return <HumanResourceRenderer item={this.props.item} />
            default:
                return <span></span>;
        }
    }
}


export class HumanResourceRenderer extends React.Component<{ item: HumanResourceScheduleItem }> {
    render() {
        return <span className='rct9k-items-inner'
            style={{padding: "0", margin: "3px", width: "100%", height: "32px"}}>
                <Droppable accept={SCHEDULE_DROP_ACCEPT_TYPE} drop={(item: unknown) => {
                    this.props.item.dispatchers?.dropAndLoadTasks({draggable: item, droppable: this.props.item})
                }} canDrop={this.props.item.canDrop} >
                    <div style={{...this.props.item.style, ...{width: '100%', margin: '0'}}} />
                </Droppable>
        </span>
    }

}

export class FlightRenderer extends React.Component<{ item: FlightItem }> {

    render() {
        let flightSegmentBackgroundColor: string = this.props.item.data.departure ? FLIGHT_DEPARTURE_COLOR : FLIGHT_ARRIVAL_COLOR;

        let style: any = {};
        if (this.props.item.data.departure) {
            style.right = 0;
        } else {
            style.left = 0;
        };
        return <Draggable type={SCHEDULE_DROP_ACCEPT_TYPE} item={this.props.item}><span className='rct9k-items-inner'
            style={{ backgroundColor: flightSegmentBackgroundColor, height: '32px' }}>
            <span className='rct9k-item-renderer-inner'>{this.props.item.data.airline! + this.props.item.data.number}</span>
            <span className="FlightItemRenderer_dateSegment" style={style} /> {/* this is the red segment corresponding to the arrival or departure date of the flight */}
        </span></Draggable>
    }

}

export class TasksAndObjectsRenderer extends React.Component<{ item: FlightItem }> {

    private calculateGanttItemSize(item: any) {
        let taskNumber: number = item.data?.taskList?.length
        var milestoneNumber: number[] = []
        var milestoneDoneNumber: number[] = []
        var objectList: any[] = []
        var activeObjectList: number[] = []
        var vracExists: Boolean = false
        item.data.tasks?.forEach((object: any) => {
            if ((object.task)) {
                activeObjectList.push(1)
            }
            objectList.push(object.id)
        })
        item.data?.taskList?.forEach((task: any) => {
            let milestoneCurrentNumber = 0
            let milestoneDoneCurrentNumber = 0
            task?.milestones?.forEach((milestone: any) => {
                milestoneCurrentNumber++
                if (!vracExists && (milestone?.object) && milestone?.object?.taskType.canBeSplit === "TRUE") {
                    vracExists = true
                }
                if (milestone.date) {
                    milestoneDoneCurrentNumber++
                }
            })
            milestoneNumber.push(milestoneCurrentNumber)
            milestoneDoneNumber.push(milestoneCurrentNumber)
        })
        let objectNumber = objectList.length //compare with flight objects
        let objectActiveNumber = activeObjectList.length //compare with flight objects
        return {
            taskNumber: taskNumber, milestoneNumber: milestoneNumber, milestoneDoneNumber: milestoneDoneNumber,
            objectNumber: objectNumber, activeObjects: objectActiveNumber, vracExists: vracExists
        }
    }

    render() {
        const ganttItemData = this.calculateGanttItemSize(this.props.item);
        return <span className='rct9k-items-inner' style={{ backgroundColor: 'alpha', opacity: 1.0 }}>
            {ganttItemData.milestoneNumber.map((milestoneNb, index) => {
                return <Draggable key={index} type={SCHEDULE_DROP_ACCEPT_TYPE} item={{...this.props.item, ...{index: index}}}>
                    <span className={'fa fa-stack fa-1x'}>
                        <i className={'fa fa-square fa-stack-2x'} style={{ color: "blue" }} />
                        {milestoneNb / ganttItemData.milestoneDoneNumber[index] === 1 && <i className={'fa fa-square fa-stack-1x'} style={{ color: "green" }} />}
                        {milestoneNb === 0 && <i className={'fa fa-square fa-stack-1x'} style={{ color: "grey" }} />}
                        {milestoneNb / ganttItemData.milestoneDoneNumber[index] < 1 &&
                            milestoneNb / ganttItemData.milestoneDoneNumber[index] > 0 && <i className={'fa fa-square fa-stack-1x'} style={{ color: "yellow" }} />}
                    </span>
                    <span className='rct9k-item-renderer-inner'>{ganttItemData.milestoneDoneNumber[index]}/{milestoneNb}</span></Draggable>
            }
            )}
            {ganttItemData.vracExists &&
                <span>
                    <span className={'fa fa-stack fa-1x'}><i className={'fa fa-circle fa-stack-2x'} style={{ color: "red" }} />
                    </span>
                    <span className='rct9k-item-renderer-inner'>
                    </span>
                </span>}
            {<span>
                <span className={'fa fa-stack fa-1x'}><i className={'fa fa-circle fa-stack-2x'} style={{ color: "purple" }} />
                </span>
                <span className='rct9k-item-renderer-inner'>{ganttItemData.objectNumber > 0 &&
                    <span>{ganttItemData.activeObjects}/{ganttItemData.objectNumber}</span>}
                </span>
            </span>}
        </span>
    }
} 

export const ScheduleRenderer = (props: any) => {    
    return <HumanResourceRenderer {...props} />;
}
