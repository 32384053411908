import { getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { DEFAULT_BLOCKLY_SETTINGS } from "@crispico/foundation-react/components/CustomQuery/BlocklyEditorTab";
import { PopupWithHelpTooltip } from "@crispico/foundation-react/components/semanticUiReactExt";
import { EntityEditorPage, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { organizationEntityDescriptor } from "@crispico/foundation-react/pages/SettingsEntity/settingsEntityDescriptor";
import * as Blockly from 'blockly';
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { EquipmentResourceManyToOneEditorStandalone } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Header, Icon, Segment } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { new_blocks_func } from "./new-blocks.js";
import { toolbox } from "./toolbox";

interface Props {
    content: string    
}
interface State {
    initialized: boolean
}
export class BlocklyScriptEditor extends React.Component<Props, State> {

    private blocklyReadonlyDivRef = React.createRef<any>();
    private workspace!: Blockly.WorkspaceSvg;

    constructor(props: Props) {
        super(props);

        this.state = { initialized: false };
    }

    private async initBlocklyEditor() {
        if (this.state.initialized) {
            return;
        }
        let query = gql(`query { blocklyScriptXopsService_initParams }`);
        let initParams = (await apolloClientHolder.apolloClient.query({ query })).data.blocklyScriptXopsService_initParams;
        new_blocks_func(this.createJsArrayForVariablesDropdown(initParams['variables']),
            this.createJsArrayForDropdown(initParams['functions'].map((pair: any) => pair.a)),
            this.createJSONForFunctionsDropdown(initParams['functions']));

        this.setState({ initialized: true });
    }

    private createJsArrayForDropdown(dropdownList: string[]) {
        let result = [];
        for (const dropdownElement of dropdownList) {
            let elem: Array<any> = [];
            elem.push(dropdownElement);
            elem.push(dropdownElement);
            result.push(elem);
        }
        return result;
    }

    private createJsArrayForVariablesDropdown(variables: { [key: string]: { [key: string]: string } }) {
        let varsForBlockly: { [key: string]: string[][] } = {};
        Object.keys(variables).forEach(v => {
            const vars = variables[v];
            let result = [];
            for (const key of Object.keys(vars)) {
                let elem = [];
                elem.push(vars[key]);
                elem.push(key);
                result.push(elem);
            }
            varsForBlockly[v] = result.sort((a, b) => a[0].localeCompare(b[0]));
        });
        return varsForBlockly;
    }

    private createJSONForFunctionsDropdown(functions: any[]) {
        let jsObject: Array<any> = [];
        for (var i = 0; i < functions.length; i++) {
            var fc = functions[i];
            jsObject[fc.a] = (fc.b);
        }
        return jsObject;
    }

    componentDidMount() {
        this.initBlocklyEditor();

        this.workspace = Blockly.inject(this.blocklyReadonlyDivRef.current, 
            {...DEFAULT_BLOCKLY_SETTINGS, ...{toolbox}
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        this.componentDidUpdateInternal(prevProps, prevState);
    }

    private componentDidUpdateInternal(prevProps?: Props, prevState?: State) {
        if (this.state.initialized && (prevState?.initialized !== this.state.initialized || prevProps?.content !== this.props.content)) {
            this.workspace.clear();
            this.props.content && Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(this.props.content), this.workspace);
        }
    }

    getContent() {
        return Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(this.workspace));
    }

    render() {
        return (
            <div ref={this.blocklyReadonlyDivRef} className="BlocklyScriptEditor_editorDiv" />
        );
    }
}

export class SliceBlocklyScriptEditorPage extends SliceEntityEditorPage {
    initialState = {
        ...sliceEntityEditorPageOnlyForExtension.initialState,
        selectedEquipmentId: undefined as any
    }

    reducers = {
        ...sliceEntityEditorPageOnlyForExtension.reducers,
        ...getBaseReducers<SliceBlocklyScriptEditorPage>(this)
    }
}

export class BlocklyScriptEditorPage extends EntityEditorPage<PropsFrom<SliceBlocklyScriptEditorPage>> {

    private blocklyEditorRef = React.createRef<BlocklyScriptEditor>();
    private runQuery: DocumentNode;

    constructor(props:PropsFrom<SliceBlocklyScriptEditorPage>) {
        super(props);
        this.runQuery = gql(`mutation runScriptForEquipment($scriptId: Long!, $equipmentId: Long!) { 
            blocklyScriptXopsService_runScriptForEquipment(scriptId: $scriptId, equipmentId: $equipmentId)
        }`);
    }

    private async save() {
        this.props.dispatchers.save({...this.props.entity, blocklyContent: this.blocklyEditorRef.current!.getContent()}, true, ["blocklyContent"]);
    }

    private async run() {
        if (!this.props.selectedEquipmentId) {
            Utils.showGlobalAlert({ message: _msg('blocklyScriptEditor.noEquipmentMessage') });
            return;
        }
        const id = (await this.props.dispatchers.save({...this.props.entity, blocklyContent: this.blocklyEditorRef.current!.getContent()}, false, ["blocklyContent"])).blocklyScriptXopsService_save.id;
      
        const error = (await apolloClientHolder.apolloClient.mutate({ mutation: this.runQuery, variables: { scriptId: id, equipmentId: this.props.selectedEquipmentId } })).data.blocklyScriptXopsService_runScriptForEquipment;
        Utils.showGlobalAlert({ message: !error ? _msg('blocklyScriptEditor.succes') : _msg('blocklyScriptEditor.fail', error) });
    }

    protected getExtraTabPanes() {
        const props = this.props;
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return props.entity?.id && [...super.getExtraTabPanes(),
            {
                routeProps: { path: "/blocklyEditor" },
                menuItemProps: { icon: "edit", content: _msg('blocklyScript.edit') },
                render: () =>
                    <Container className="EntityEditorPage_container" fluid>
                        <Segment className="BlocklyScriptEditor_segment">
                            <Header as="h2" dividing>
                                <Icon name={entityDescriptor.icon as SemanticICONS} />
                                <Header.Content>
                                    {entityDescriptor.toMiniString(props.entity)}
                                    <Header.Subheader>{props.entity.organization && organizationEntityDescriptor.toMiniString(props.entity.organization)}</Header.Subheader>
                                </Header.Content>
                            </Header>
                            <Segment className="buttonBar BlocklyScriptEditorBar_div">
                                <Button primary type="submit" onClick={() => this.save()}>{_msg("general.save")}</Button>
                                <Button as={Link} to={entityDescriptor.getEntityTableUrl()} secondary>{_msg("general.cancel")}</Button>
                                <div className="EntityTablePage_barDivider" />
                                <div className='BlocklyScriptEditor_equipmentDropdownDiv'>
                                    <EquipmentResourceManyToOneEditorStandalone onChange={value => { if (value) { props.dispatchers.setInReduxState({selectedEquipmentId: value.id})}}}></EquipmentResourceManyToOneEditorStandalone>
                                </div>
                                <Button onClick={() => this.run()}>{_msg('blocklyScript.run')}</Button>
                                <PopupWithHelpTooltip tooltip={_msg("BlocklyScript.runInfoText")} />
                            </Segment>

                            <BlocklyScriptEditor ref={this.blocklyEditorRef} content={props.entity.blocklyContent} />
                        </Segment>
                    </Container>
            }
        ]
    }

}
