// NOTE: from the samples, we used an import cf. below; however, out of the blue, it stopped working. It seemed
// that somehow there are 2 "Blockly" instances. Very randomly reproducible. Modifying the import style seems to have fixed it
// NOTE2: however when I look in samples, I see that this style of import is used everywhere; so maybe mixing causes issues
// import * as Blockly from "blockly/core"
import Blockly from "blockly";

export const SORT = 'sort';
export const SORT_INVALID = 'sort_invalid';
export const FILTER_NO_OUTPUT = 'filter_no_output';
export const FILTER_WITH_OUTPUT = 'filter_with_output';
export const FILTER_NO_OUTPUT_INVALID = 'filter_no_output_invalid';
export const FILTER_WITH_OUTPUT_INVALID = 'filter_with_output_invalid'
export const NO_VALUE_FILTER_NO_OUTPUT = 'no_value_filter_no_output';
export const NO_VALUE_FILTER_WITH_OUTPUT = 'no_value_filter_with_output';
export const LABELED_FILTER_NO_OUTPUT = 'labeled_filter_no_output';
export const LABELED_FILTER_WITH_OUTPUT = 'labeled_filter_with_output';
export const LOGIC_OPERATION_NO_OUTPUT = 'logic_operation_no_output';
export const LOGIC_OPERATION_WITH_OUTPUT = 'logic_operation_with_output';
export const EDITOR_LOGIC_OPERATION_NO_OUTPUT = 'editor_logic_operation_no_output';
export const EDITOR_LOGIC_OPERATION_WITH_OUTPUT = 'editor_logic_operation_with_output';

export const OR_BLOCK_COLOR = '#00b5ad';
export const AND_BLOCK_COLOR = '#2185d0';
const DISABLED_BLOCK = '#f0f0f5';
const INVALID_BLOCK = '#ce4040';

var sortBlock = {
    type: "sort",
    message0: "%1",
    args0: [
        {
            type: "field_input",
            name: "FIELD",
            text: "",
        }
    ],
    inputsInline: !0,
    style: "text_blocks"
};

Blockly.Blocks[SORT] = {
    init: function () {
        this.jsonInit(sortBlock);
        this.editable_ = false;
        this.setColour('#ECB22D'); // this is the orange used by XOPS, when moving the blocky-script to foundation, we need to find a solution for this
    }
};

Blockly.Blocks[SORT_INVALID] = {
    init: function () {
        this.jsonInit(sortBlock);
        this.editable_ = false;
        this.setColour(INVALID_BLOCK);
    }
};

var filterBlock = {
    type: "filter",
    message0: "%1",
    args0: [
        {
            type: "field_input",
            name: "FIELD",
            text: "",
        }
    ],
    message1: "%1",
    args1: [
        {
            type: "field_input",
            name: "OPERATOR",
            text: ""
        }
    ],
    message2: "%1",
    args2: [
        {
            type: "field_input",
            name: "VALUE",
            text: ""
        }
    ],
    inputsInline: !0,
    style: "text_blocks"
};

var noValueFilterBlock = {
    type: "no_value_filter",
    message0: "%1",
    args0: [
        {
            type: "field_input",
            name: "FIELD",
            text: "",
        }
    ],
    message1: "%1",
    args1: [
        {
            type: "field_input",
            name: "OPERATOR",
            text: ""
        }
    ],
    inputsInline: !0,
    style: "text_blocks"
};

var labeledFilterBlock = {
    type: "labeled_filter",
    message0: "%1",
    args0: [
        {
            type: "field_input",
            name: "LABEL",
            text: "",
        }
    ],
    inputsInline: !0,
    style: "text_blocks"
};

Blockly.Blocks[FILTER_NO_OUTPUT] = {
    init: function () {
        this.jsonInit(filterBlock);
        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);
        this.editable_ = false;
        this.setOnChange(function () {
            if (this.enableField.fieldRow[0].value_ === false) {
                this.setColour(DISABLED_BLOCK);
            }
        });
    }
};

Blockly.Blocks[FILTER_NO_OUTPUT_INVALID] = {
    init: function () {
        this.jsonInit(filterBlock);
        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);
        this.editable_ = false;
        this.setOnChange(function () {
            this.setColour(INVALID_BLOCK);
        });
    }
};

Blockly.Blocks[FILTER_WITH_OUTPUT] = {
    init: function () {
        this.jsonInit(filterBlock);
        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);
        this.editable_ = false;
        this.setOutput(true, "Boolean");
        this.setOnChange(function () {
            if (this.enableField.fieldRow[0].value_ === false) {
                this.setColour(DISABLED_BLOCK);
            }
        });
    }
};

Blockly.Blocks[FILTER_WITH_OUTPUT_INVALID] = {
    init: function () {
        this.jsonInit(filterBlock);
        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);
        this.editable_ = false;
        this.setOutput(true, "Boolean");
        this.setOnChange(function () {
            this.setColour(INVALID_BLOCK);
        });
    }
};

Blockly.Blocks[NO_VALUE_FILTER_NO_OUTPUT] = {
    init: function () {
        this.jsonInit(noValueFilterBlock);
        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);
        this.editable_ = false;
        this.setOnChange(function () {
            if (this.enableField.fieldRow[0].value_ === false) {
                this.setColour(DISABLED_BLOCK);
            }
        });
    }
};

Blockly.Blocks[NO_VALUE_FILTER_WITH_OUTPUT] = {
    init: function () {
        this.jsonInit(noValueFilterBlock);
        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);
        this.editable_ = false;
        this.setOutput(true, "Boolean");
        this.setOnChange(function () {
            if (this.enableField.fieldRow[0].value_ === false) {
                this.setColour(DISABLED_BLOCK);
            }
        });
    }
};

Blockly.Blocks[LABELED_FILTER_NO_OUTPUT] = {
    init: function () {
        this.jsonInit(labeledFilterBlock);
        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);
        this.editable_ = false;
        this.setOnChange(function () {
            if (this.enableField.fieldRow[0].value_ === false) {
                this.setColour(DISABLED_BLOCK);
            }
        });
    }
};

Blockly.Blocks[LABELED_FILTER_WITH_OUTPUT] = {
    init: function () {
        this.jsonInit(labeledFilterBlock);
        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);
        this.editable_ = false;
        this.setOutput(true, "Boolean");
        this.setOnChange(function () {
            if (this.enableField.fieldRow[0].value_ === false) {
                this.setColour(DISABLED_BLOCK);
            }
        });
    }
};

Blockly.Blocks[LOGIC_OPERATION_NO_OUTPUT] = {
    init: function () {
        this.setStyle("logic_blocks");
        this.operatorField = this.appendDummyInput('OPERATOR').appendField(
            new Blockly.FieldDropdown([
                ["%{BKY_LOGIC_OPERATION_AND}", "and"],
                ["%{BKY_LOGIC_OPERATION_OR}", "or"]
            ]),
            "OP"
        );
        this.itemCount_ = 2;
        this.updateShape_();
        this.setInputsInline(true);

        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);

        this.setOnChange(function (changeEvent) {
            if (this.isDisabled()) {
                this.setColour(DISABLED_BLOCK);
            } else if (this.operatorField.fieldRow[0].value_ === "and") {
                this.setColour(AND_BLOCK_COLOR);
            } else {
                this.setColour(OR_BLOCK_COLOR);
            }
        });
    },

    isDisabled: function () {
        return !this.enableField.fieldRow[0].value_;
    },

    mutationToDom: function () {
        var a = document.createElement("mutation");
        a.setAttribute("items", this.itemCount_);
        return a;
    },

    domToMutation: function (a) {
        this.itemCount_ = parseInt(a.getAttribute("items"), 10);
        this.updateShape_();
    },

    updateShape_: function () {
        this.itemCount_ && this.getInput("EMPTY")
            ? this.removeInput("EMPTY")
            : this.itemCount_ ||
            this.getInput("EMPTY") ||
            this.appendDummyInput("EMPTY").appendField(
                Blockly.Msg.LISTS_CREATE_EMPTY_TITLE
            );
        for (var a = 0; a < this.itemCount_; a++)
            if (!this.getInput("ADD" + a)) {
                this.appendValueInput("ADD" + a).setCheck("Boolean");
            }
        for (; this.getInput("ADD" + a);) {
            this.removeInput("ADD" + a);
            a++;
        }
    }
};

Blockly.Blocks[LOGIC_OPERATION_WITH_OUTPUT] = {
    init: function () {
        this.setStyle("logic_blocks");
        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);
        this.operatorField = this.appendDummyInput("OPERATOR").appendField(
            new Blockly.FieldDropdown([
                ["%{BKY_LOGIC_OPERATION_AND}", "and"],
                ["%{BKY_LOGIC_OPERATION_OR}", "or"]
            ]),
            "OP"
        );
        this.itemCount_ = 2;
        this.updateShape_();
        this.setOutput(true, "Boolean");
        this.setInputsInline(true);
        this.setOnChange(function (changeEvent) {
            if (this.isDisabled()) {
                this.setColour(DISABLED_BLOCK);
            } else if (this.operatorField.fieldRow[0].value_ === "and") {
                this.setColour(AND_BLOCK_COLOR);
            } else {
                this.setColour(OR_BLOCK_COLOR);
            }
        });
    },

    isDisabled: function () {
        return !this.enableField.fieldRow[0].value_;
    },

    mutationToDom: function () {
        var a = document.createElement("mutation");
        a.setAttribute("items", this.itemCount_);
        return a;
    },

    domToMutation: function (a) {
        this.itemCount_ = parseInt(a.getAttribute("items"), 10);
        this.updateShape_();
    },

    updateShape_: function () {
        this.itemCount_ && this.getInput("EMPTY")
            ? this.removeInput("EMPTY")
            : this.itemCount_ ||
            this.getInput("EMPTY") ||
            this.appendDummyInput("EMPTY").appendField(
                Blockly.Msg.LISTS_CREATE_EMPTY_TITLE
            );
        for (var a = 0; a < this.itemCount_; a++)
            if (!this.getInput("ADD" + a)) {
                this.appendValueInput("ADD" + a).setCheck("Boolean");
            }
        for (; this.getInput("ADD" + a);) {
            this.removeInput("ADD" + a);
            a++;
        }
    }
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Blockly.Blocks[EDITOR_LOGIC_OPERATION_NO_OUTPUT] = {
    init: function () {
        this.setStyle("logic_blocks");
        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);
        this.operatorField = this.appendDummyInput().appendField(
            new Blockly.FieldDropdown([
                ["%{BKY_LOGIC_OPERATION_AND}", "and"],
                ["%{BKY_LOGIC_OPERATION_OR}", "or"]
            ]),
            "OP"
        );
        this.itemCount_ = 2;
        this.updateShape_();
        this.setMutator(new Blockly.Mutator(["lists_create_with_item"]));
        this.setInputsInline(true);
        this.setOnChange(function (changeEvent) {
            if (this.operatorField.fieldRow[0].value_ === "and") {
                this.setColour(AND_BLOCK_COLOR);
            } else {
                this.setColour(OR_BLOCK_COLOR);
            }
        });
    },

    getOperator: function () {
        return this.operatorField.fieldRow[0].value_;
    },

    mutationToDom: function () {
        var a = document.createElement("mutation");
        a.setAttribute("items", this.itemCount_);
        return a;
    },

    domToMutation: function (a) {
        this.itemCount_ = parseInt(a.getAttribute("items"), 10);
        this.updateShape_();
    },

    decompose: function (a) {
        var b = a.newBlock("lists_create_with_container");
        b.initSvg();
        for (var c = b.getInput("STACK").connection, d = 0; d < this.itemCount_; d++) {
            var e = a.newBlock("lists_create_with_item");
            e.initSvg();
            c.connect(e.previousConnection);
            c = e.nextConnection;
        }
        return b;
    },

    compose: function (a) {
        var b = a.getInputTargetBlock("STACK");
        for (a = []; b;) {
            a.push(b.valueConnection_); b = b.nextConnection && b.nextConnection.targetBlock();
        }
        for (b = 0; b < this.itemCount_; b++) {
            var c = this.getInput("ADD" + b).connection.targetConnection;
            c && -1 === a.indexOf(c) && c.disconnect();
        }
        this.itemCount_ = a.length;
        this.updateShape_();
        for (b = 0; b < this.itemCount_; b++)Blockly.Mutator.reconnect(a[b], this, "ADD" + b);
    },
    saveConnections: function (a) {
        a = a.getInputTargetBlock("STACK");
        for (var b = 0; a;) {
            var c = this.getInput("ADD" + b);
            a.valueConnection_ = c && c.connection.targetConnection;
            b++;
            a = a.nextConnection && a.nextConnection.targetBlock()
        }
    },

    updateShape_: function () {
        this.itemCount_ && this.getInput("EMPTY")
            ? this.removeInput("EMPTY")
            : this.itemCount_ ||
            this.getInput("EMPTY") ||
            this.appendDummyInput("EMPTY").appendField(
                Blockly.Msg.LISTS_CREATE_EMPTY_TITLE
            );
        for (var a = 0; a < this.itemCount_; a++)
            if (!this.getInput("ADD" + a)) {
                this.appendValueInput("ADD" + a).setCheck("Boolean");
            }
        for (; this.getInput("ADD" + a);) {
            this.removeInput("ADD" + a);
            a++;
        }
    }
};


Blockly.Blocks[EDITOR_LOGIC_OPERATION_WITH_OUTPUT] = {
    init: function () {
        this.setStyle("logic_blocks");
        this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
        this.enableField.setVisible(false);
        this.operatorField = this.appendDummyInput().appendField(
            new Blockly.FieldDropdown([
                ["%{BKY_LOGIC_OPERATION_AND}", "and"],
                ["%{BKY_LOGIC_OPERATION_OR}", "or"]
            ]),
            "OP"
        );
        this.itemCount_ = 2;
        this.updateShape_();
        this.setOutput(true, "Boolean");
        this.setMutator(new Blockly.Mutator(["lists_create_with_item"]));
        this.setInputsInline(true);
        this.setOnChange(function (changeEvent) {
            if (this.isDisabled()) {
                this.setColour(DISABLED_BLOCK);
            } else if (this.operatorField.fieldRow[0].value_ === "and") {
                this.setColour(AND_BLOCK_COLOR);
            } else {
                this.setColour(OR_BLOCK_COLOR);
            }
        });
    },

    getOperator: function () {
        return this.operatorField.fieldRow[0].value_;
    },

    isDisabled: function () {
        return !this.enableField.fieldRow[0].value_;
    },

    mutationToDom: function () {
        var a = document.createElement("mutation");
        a.setAttribute("items", this.itemCount_);
        return a;
    },

    domToMutation: function (a) {
        this.itemCount_ = parseInt(a.getAttribute("items"), 10);
        this.updateShape_();
    },

    decompose: function (a) {
        var b = a.newBlock("lists_create_with_container");
        b.initSvg();
        for (var c = b.getInput("STACK").connection, d = 0; d < this.itemCount_; d++) {
            var e = a.newBlock("lists_create_with_item");
            e.initSvg();
            c.connect(e.previousConnection);
            c = e.nextConnection;
        }
        return b;
    },

    compose: function (a) {
        var b = a.getInputTargetBlock("STACK");
        for (a = []; b;) {
            a.push(b.valueConnection_); b = b.nextConnection && b.nextConnection.targetBlock();
        }
        for (b = 0; b < this.itemCount_; b++) {
            var c = this.getInput("ADD" + b).connection.targetConnection;
            c && -1 === a.indexOf(c) && c.disconnect();
        }
        this.itemCount_ = a.length;
        this.updateShape_();
        for (b = 0; b < this.itemCount_; b++)Blockly.Mutator.reconnect(a[b], this, "ADD" + b);
    },
    saveConnections: function (a) {
        a = a.getInputTargetBlock("STACK");
        for (var b = 0; a;) {
            var c = this.getInput("ADD" + b);
            a.valueConnection_ = c && c.connection.targetConnection;
            b++;
            a = a.nextConnection && a.nextConnection.targetBlock()
        }
    },

    updateShape_: function () {
        this.itemCount_ && this.getInput("EMPTY")
            ? this.removeInput("EMPTY")
            : this.itemCount_ ||
            this.getInput("EMPTY") ||
            this.appendDummyInput("EMPTY").appendField(
                Blockly.Msg.LISTS_CREATE_EMPTY_TITLE
            );
        for (var a = 0; a < this.itemCount_; a++)
            if (!this.getInput("ADD" + a)) {
                this.appendValueInput("ADD" + a).setCheck("Boolean");
            }
        for (; this.getInput("ADD" + a);) {
            this.removeInput("ADD" + a);
            a++;
        }
    }
};
