import gql from "graphql-tag";
import { EQUIPMENT_RESOURCE_FOR_MAP } from "graphql/queries";
import { TERRITORY_FOR_MAP } from "pages/Territory/queries";

export const AIRPORT_FOR_MAP = gql`
    fragment AirportForMap on Airport {
        id code latitude longitude name
    }
`;

export const LOAD_AIRPORTS_FOR_MAP = gql`
    query loadAirportsForMap($params: FindByFilterParamsInput) { 
        airportService_findByFilter(params: $params) {
            results  { ...AirportForMap } 
        }
    }
    ${AIRPORT_FOR_MAP}
`;

export const LOAD_EQUIPMENT_RESOURCES_FOR_MAP = gql`
    query loadEquipmentResourcesForMap($filter: FilterInput, $sorts: [SortInput], $coordinates:[Pair_Double_DoubleInput], $whichFields: [String]) { 
        equipmentResourceService_findByRectangle(filter: $filter, sorts: $sorts, coordinates: $coordinates) {
            results  { ...EquipmentResourceForMap } 
        }
    }
    ${EQUIPMENT_RESOURCE_FOR_MAP}
`;

export const LOAD_TERRITORIES_FOR_REAL_TIME_MAP = gql`
    query loadTerritoriesForRealTimeMap($filter: FilterInput, $sorts: [SortInput], $coordinates:[Pair_Double_DoubleInput]) { 
        territoryService_findByRectangle(filter: $filter, sorts: $sorts, coordinates: $coordinates) {
            results  { ...TerritoryForMap } 
        }
    }
    ${TERRITORY_FOR_MAP}
`;