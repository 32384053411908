import React from "react";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { DatePickerExt } from "./DatePickerExt";
import moment, { Moment } from "moment";
import { ShortcutRefForTest } from "@crispico/foundation-react/testsAreDemo/ShortcutRefForTest";
import { Utils } from "@crispico/foundation-react/utils/Utils";

export class DatePickerExtFieldEditor extends React.Component<FieldEditorProps> {

    onChange = (date: Moment | null) => {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.getFieldName(), date ? date.toISOString() : null);
    }

    render() {
        const value = this.props.fieldDescriptor.getFieldValue((this.props as FieldEditorProps).formikProps.values);
        const defaultValue = value ? moment(value) : undefined;
        return <>
            <ShortcutRefForTest objectToPublish={this} dataTestIdSuffix={this.props.fieldDescriptor.getFieldName()} />
            <DatePickerExt data-testid={`DatePickerExtFieldEditor_${this.props.fieldDescriptor.getFieldName()}`} {...this.props.fieldDescriptor.propsForEditor} onChange={this.onChange} value={defaultValue} />
        </>;
    }

    tadSetDate = (date: string) => {
        this.onChange(moment(date, Utils.dateTimeFormat));
    }
}