import React from "react";
import Cron from "react-js-cron";
import { Button, Form, Icon, Input, InputProps, Message, Popup } from "semantic-ui-react";
import { FieldEditorProps } from "../fieldRenderersEditors";

export class CronFieldEditor extends React.Component<FieldEditorProps> {
  setValue = (value: string) => {
    // this expression is saved and used server-side, where the cron expression with a leading zero is used
    this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, value ? '0 ' + value : undefined)
  }

  render() {
    let value: string
    const fieldValue: string | undefined = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values)
    if (fieldValue && fieldValue.length > 1) {
      value = fieldValue.slice(2, fieldValue.length)
    } else {
      value = ''
    }
    if (value === '') {
      return <>
        <span className="tiny-margin-right"><i>{_msg("CronFieldEditor.notSet")}</i></span>
        <Button onClick={e => this.setValue("0 0 * * *")} className="small-margin-right">{_msg("CronFieldEditor.set")}</Button>
      </>
    } else {
      return <>
          <div className="small-margin-right" style={{display: "inline-block", marginTop: '1em'}}><Cron allowEmpty="never" clearButtonAction="empty" value={value} setValue={this.setValue} /></div>
          <Popup
            trigger={<Icon name="question" color="blue" circular className="small-margin-right" />}
            content={_msg("CronFieldEditor.info")}
            position="bottom center"
          />
          <span><i>{_msg("CronFieldEditor.expression")}: {value}</i></span>
      </>
    }
  }

}
