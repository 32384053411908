import { TestState, Utils } from "@crispico/foundation-react";
import { eventValidationStatusPageMeta } from "./EventValidationStatusPage";

const setNow = () => Utils.constantNow = new Date("2018-08-31 15:00");

export const testStateMission = TestState.addTestState(eventValidationStatusPageMeta, "0", {
    actualNumberOfEvents: 3,
    expectedNumberOfEvents: 4,
    data: [ 
      { name: "DEPQ", validatedAt: "15:21", expectedBefore: "15:19", delay: "00:02", color: "orange", flightName: "AF1234", objectName: "CMX04733" },
      { name: "PIFA", validatedAt: "15:20", expectedBefore: "15:20", delay: null, color: "black", flightName: "AF1234", objectName: "CMX04733" },
      { name: "PIFA", validatedAt: null, expectedBefore: "14:59", delay: "00:01", color: "red", flightName: "AF1234", objectName: "PMC22163MP" },
      { name: "DEPQ", validatedAt: "15:20", expectedBefore: "15:20", delay: null, color: "black", flightName: "AF1234", objectName: "PMC22163MP" }
    ]
}, setNow);

export const testStateObject = TestState.addTestState(eventValidationStatusPageMeta, "1", {
  actualNumberOfEvents: 1,
  expectedNumberOfEvents: 2,
  data: testStateMission.data.filter(x => x.objectName === "PMC22163MP")
}, setNow);