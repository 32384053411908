import gql from "graphql-tag"
import { COLUMN_CONFIG } from "../ColumnConfig/queries"

const CUSTOM_QUERY = gql`
    fragment CustomQuery on CustomQuery {
        id name preferredColumnConfig {...ColumnConfig} screen customQueryDefinition displayAsCards emailScheduleRecipientList { id name } emailScheduleCron organization { id qualifiedName }
    }${COLUMN_CONFIG}
`

export const FIND_BY_FILTER = gql`
    query customQueryService_findByFilter ($params: FindByFilterParamsInput) {
        customQueryService_findByFilter(params: $params) { 
            results { ...CustomQuery }
        }
    }
    ${CUSTOM_QUERY}
`

export const GET_CUSTOM_QUERY_BY_ID = gql`
    query customQueryService_findById($id: Long) {
        customQueryService_findById(id: $id) 
            { ...CustomQuery }
    }
    ${CUSTOM_QUERY}
`