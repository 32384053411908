import { Messages } from "@crispico/foundation-gwt-js";
//@ts-ignore
import foundationMessages from "./messages-foundation.tsv";
//@ts-ignore
import appMessages from "messages/messages.tsv";

const messages = new Messages();
messages.setLanguage("en");
Messages.setInstance(messages);
messages.parseTsvContent(foundationMessages, false);
messages.parseTsvContent(appMessages, false);

// finally I think that forcing the dev to find a key right away is not a good thing; so even in dev mode
// we won't throw an error if the key is not found
messages.setProdMode(true);