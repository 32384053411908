import React from "react";
import { Button, Icon, Segment, Step } from "semantic-ui-react";
import { createSliceFoundation, PropsFrom, getBaseImpures, getBaseReducers, StateFrom } from "../../reduxHelpers";

export interface WizardStep {
    title: String;
    description?: String;
    icon: String;
    render: () => React.ReactNode;
    onPreviousClick?: () => boolean;
    onNextClick?: () => boolean;
    previousDisabled?: () => boolean;
    nextDisabled?: () => boolean;
}

export const sliceWizard = createSliceFoundation(class SliceWizard {

    initialState = {
        currentStepIndex: 0
    }

    reducers = {
        ...getBaseReducers<SliceWizard>(this),

        reset(state: StateFrom<SliceWizard>) {
            state.currentStepIndex = 0;
        }
    }

    impures = {
        ...getBaseImpures<SliceWizard>(this),

        back({ currentStepIndex, steps }: { currentStepIndex: number, steps: Array<WizardStep> }) {
            if (currentStepIndex > 0) {
                const currentStep = steps[currentStepIndex];
                if (currentStep.onPreviousClick && !currentStep.onPreviousClick.apply(null)) {
                    return;
                }
                this.getDispatchers().setInReduxState({ currentStepIndex: currentStepIndex - 1 });
            }
        },

        next(params: { currentStepIndex: number, steps: Array<WizardStep> }) {
            if (params.currentStepIndex < params.steps.length - 1) {
                const currentStep = params.steps[params.currentStepIndex];
                if (currentStep.onNextClick && !currentStep.onNextClick.apply(null)) {
                    return;
                }
                this.getDispatchers().setInReduxState({ currentStepIndex: params.currentStepIndex + 1 });
            }
        }
    };

})

type PropsNotFromState = {
    steps: Array<WizardStep>;
    onFinishClick?: () => void;
    onCancelClick?: () => void;
}

type Props = PropsFrom<typeof sliceWizard> & PropsNotFromState;

export class Wizard extends React.Component<Props> {

    render() {
        const props = this.props;
        return <React.Fragment>
            <Step.Group widths={props.steps.length as any} size="small">
                {props.steps.map((step, index) => {
                    return (
                        <Step data-cy={"step" + index} active={index === props.currentStepIndex} key={"step" + index}>
                            {step.icon && <Icon name={step.icon as any} />}
                            <Step.Content>
                                <Step.Title>{step.title}</Step.Title>
                                <Step.Description>{step.description}</Step.Description>
                            </Step.Content>
                        </Step>
                    );
                })}
            </Step.Group>

            {props.currentStepIndex >= 0 && props.steps[props.currentStepIndex].render?.apply(null)}

            <Segment className="buttonBar">
                <Button data-cy={"prevBtn"} disabled={props.currentStepIndex === 0 || props.steps[props.currentStepIndex].previousDisabled?.()} onClick={() => props.dispatchers.back(props)}>
                    <Icon name='arrow left' />
                    {_msg("Wizard.previous")}
                </Button>
                <Button data-cy={"nextBtn"} disabled={props.currentStepIndex === props.steps.length - 1 || props.steps[props.currentStepIndex].nextDisabled?.()} onClick={() => props.dispatchers.next(props)}>
                    {_msg("Wizard.next")}
                    <Icon name='arrow right' />
                </Button>
                <Button data-cy={"finishBtn"} disabled={props.currentStepIndex !== props.steps.length - 1} onClick={() => props.onFinishClick?.apply(null)}>{_msg("Wizard.finish")}</Button>
                <Button data-cy={"cancelBtn"} onClick={() => props.onCancelClick?.apply(null)} >{_msg("general.cancel")}</Button>
            </Segment>
        </React.Fragment>;
    }
}
