
import { apolloClient, apolloClientHolder, createSliceFoundation, EntityDescriptor, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/apollo-gen-foundation/Filter";
import { userService_getOrganizations, userService_getOrganizationsVariables } from "@crispico/foundation-react/apollo-gen-foundation/userService_getOrganizations";
import { Organization } from "@crispico/foundation-react/AppMeta";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { DropdownOption } from "@crispico/foundation-react/components/Dropdown/DropdownExt";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { sliceWizard } from "@crispico/foundation-react/components/Wizard/Wizard";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityNumberDisplayed } from "@crispico/foundation-react/entity_crud/EntityNumberDisplayed";
import { DispatchersFrom } from "@crispico/foundation-react/reduxHelpers";
import gql from "graphql-tag";
import _ from "lodash";
import React, { ReactElement } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import Measure from "react-measure";
import 'react-resizable/css/styles.css';
import { Button, ButtonProps, Divider, Icon, Message, Modal, Segment, SemanticICONS } from "semantic-ui-react";
import { v4 as uuid } from 'uuid';
import { organizationEntityDescriptor } from "../../SettingsEntity/settingsEntityDescriptor";
import { DashboardMode } from "../DashboardContants";
import { Dashboard, DashboardConfig, sliceEntityEditorPageDashboard } from "../DashboardEntityDescriptor";
import { DashboardWidgetFactories } from "../DashboardWidgetFactory";
import { DashboardWidgetWizard } from "./DashboardWidgetWizard";
import { WidgetWrapper } from "./WidgetWrapper";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export enum DashboardMaxRows {
    NORMAL = 200, FIT_HEIGHT = 20, ENTITY = 800
}

export enum DashboardRowHeight {
    NORMAL = 30, ENTITY = 10
}

const EXPANDED_ORGS_SIZE_INCREMENTS = 5;

export const sliceDashboardTab = createSliceFoundation(class SliceDashboardTab {
    initialState = {
        // these 2 are saved in the entity
        layouts: {} as any,
        cols: { lg: 36, md: 36, sm: 36, xs: 1, xxs: 1 } as any,
        rowHeight: DashboardRowHeight.NORMAL,
        currentBreakpoint: 'lg',
        compactType: 'vertical' as 'vertical' | 'horizontal' | null | undefined,
        mode: DashboardMode.VIEW,
        savedConfig: undefined as DashboardConfig | undefined,
        widgetWrapperConfigs: {} as any,
        saveAlertOpen: false,
        dirty: false,

        /**
         * null => component not yet mounted; needed for save when the tab hasn't been opened yet.
         * false => component mounted, but layoutChanged() not yet called.
         * true => the "parasite" layoutChanged() that happens one time after each mount has happened. We need this flag for a correct dirty state.
         */
        layoutInit: false as boolean | null,
        // wizard
        wizardOpen: false,
        errorOpen: false,
        editorOpen: undefined as string | undefined,
        widgetActionOrType: undefined as string | undefined,
        values: {} as any,
        deleteModalOpen: undefined as string | undefined,
        wizardCopyMoveDashboardId: undefined as number | undefined,
        wizardCopyMoveWidgetsAll: [] as DropdownOption[],
        wizardCopyMoveWidgetsSelected: [] as DropdownOption[],
        wizardMoveConfirmation: false,
        refresh: false,
        // customization
        widgetHeaderFontSize: undefined as number | undefined,
        widgetHeaderBackgroundColor: undefined as string | undefined,
        widgetHeaderIcon: undefined as SemanticICONS | undefined,

        // if undefined => expandOrganizations = false
        expandedOrgs: undefined as Organization[] | undefined,
        // by default we limit only to a couple of orgs. For performance reasons. The user will click then for more.
        expandedOrgsMaxSize: EXPANDED_ORGS_SIZE_INCREMENTS
    }

    nestedSlices = {
        wizard: sliceWizard
    }

    reducers = {
        ...getBaseReducers<SliceDashboardTab>(this),

        layoutChange(state: StateFrom<SliceDashboardTab>, layouts: Layouts) {
            if (!_.isEqualWith(layouts, state.layouts)) {
                if (state.layoutInit) {
                    state.dirty = true
                } else {
                    state.layoutInit = true
                }
                state.layouts = layouts
            }
        },

        newLayoutBreakpoint(state: StateFrom<SliceDashboardTab>, breakpoint: string) {
            if (state.currentBreakpoint === 'xxs') {
                state.layouts['xs'] = state.layouts['xxs']
            } else if (state.currentBreakpoint === 'xs') {
                state.layouts['xxs'] = state.layouts['xs']
            } else if (state.currentBreakpoint === 'sm') {
                state.layouts['md'] = state.layouts['sm']
                state.layouts['lg'] = state.layouts['sm']
            } else if (state.currentBreakpoint === 'md') {
                state.layouts['sm'] = state.layouts['md']
                state.layouts['lg'] = state.layouts['md']
            } else if (state.currentBreakpoint == 'lg') {
                state.layouts['sm'] = state.layouts['lg']
                state.layouts['md'] = state.layouts['lg']
            }

            state.currentBreakpoint = breakpoint
        },

        removeItem(state: StateFrom<SliceDashboardTab>, uid: string) {
            let newLayouts = state.layouts
            Object.keys(state.layouts).forEach(k => {
                newLayouts[k] = newLayouts[k].filter((l: Layout) => l.i !== uid)
            })
            state.layouts = newLayouts
            delete state.widgetWrapperConfigs[uid]
            state.dirty = true
        },

        addItem(state: StateFrom<SliceDashboardTab>, p: { type?: string, config?: any, dashboardEntity: any }) {
            let [valid, values] = p.type ? [true, p.config] : parseWidgetConfig(state.widgetActionOrType!, state.values, p.dashboardEntity)
            if (valid) {
                const uid = uuid()
                let newLayouts = state.layouts
                Object.keys(state.layouts).forEach(k => {
                    newLayouts[k].push({
                        x: (state.layouts[k].length) % (state.cols[k] || 12),
                        y: Infinity,
                        w: 3,
                        h: 5,
                        i: uid
                    })
                })
                state.widgetWrapperConfigs = { ...state.widgetWrapperConfigs, ...{ [uid]: { type: p.type ? p.type : state.widgetActionOrType!, widgetConfig: values } } }
                state.layouts = newLayouts
                state.widgetActionOrType = undefined
                state.values = {}
                state.wizard.currentStepIndex = 0
                state.wizardOpen = false
            } else {
                state.errorOpen = true
            }
            state.dirty = true
        },

        editItem(state: StateFrom<SliceDashboardTab>, p: { uid: string, dashboardEntity: any }) {
            let [valid, values] = parseWidgetConfig(state.widgetWrapperConfigs[p.uid].type, state.values, p.dashboardEntity)
            if (valid) {
                state.widgetWrapperConfigs[p.uid].widgetConfig = values
                state.values = {}
                state.editorOpen = undefined
            } else {
                state.errorOpen = true
            }
            state.dirty = true
        },

        onResize(state: StateFrom<SliceDashboardTab>, p: { height: number | undefined, fitHeight: boolean }) {
            if (p.height && p.fitHeight) {
                state.rowHeight = Math.floor((p.height - DashboardMaxRows.FIT_HEIGHT.valueOf() * 5) / (DashboardMaxRows.FIT_HEIGHT));
            }
        },

        selectWidgetType(state: StateFrom<SliceDashboardTab>, type: string) {
            state.widgetActionOrType = type
            state.wizard.currentStepIndex = 1
        },

        onCancelClick(state: StateFrom<SliceDashboardTab>) {
            state.wizardOpen = false
            state.wizard.currentStepIndex = 0
        }
    }

    impures = {
        ...getBaseImpures<SliceDashboardTab>(this),

        async copyWidgets(dashboardEntity: any) {
            const widgetWrapperConfigs = this.getState().widgetWrapperConfigs
            let titles = Object.keys(widgetWrapperConfigs).map(key => widgetWrapperConfigs[key].widgetConfig.title).filter(title => title && title !== "")
            const widgets = this.getState().wizardCopyMoveWidgetsSelected
            widgets.forEach(widget => {
                let title = widget.config.widgetConfig.title
                if (!title || title === "") {
                    // skip for empty title
                } else {
                    let counter = 0
                    while (titles.includes(title)) {
                        title = widget.config.widgetConfig.title + ' (' + (++counter) + ')'
                    }
                    if (counter) {
                        titles.push(title)
                    }
                }
                const widgetConfig = { ...widget.config.widgetConfig, ...{ title: title } }
                this.getDispatchers().addItem({ type: widget.config.type, config: widgetConfig, dashboardEntity: dashboardEntity })
            })
            this.getDispatchers().setInReduxState({ wizardOpen: false, wizardCopyMoveWidgetsAll: [], wizardCopyMoveWidgetsSelected: [] });
        },

        async moveWidgets(id: number | undefined, editorDispatchers: DispatchersFrom<typeof sliceEntityEditorPageDashboard>) {
            this.getDispatchers().setInReduxState({ wizardMoveConfirmation: false });
            if (id) {
                const mutation = gql(`mutation m($id: Long!, $dashboardId: Long!, $widgetsToDelete: [String]) {
                    dashboardService_saveAndDeleteWidgets(id: $id, dashboardId: $dashboardId, widgetsToDelete: $widgetsToDelete) { ${ID} }
                }`)

                const { data } = await apolloClient.mutate({
                    mutation: mutation,
                    variables: {
                        id: id,
                        dashboardId: this.getState().wizardCopyMoveDashboardId,
                        widgetsToDelete: this.getState().wizardCopyMoveWidgetsSelected.map(widget => widget.value)
                    }
                });

                this.getDispatchers().setInReduxState({ wizardOpen: false, wizardCopyMoveWidgetsAll: [], wizardCopyMoveWidgetsSelected: [] });
                editorDispatchers.load(id);
            }
        },

        async selectDashboard(dashboardId: number | undefined) {
            if (!dashboardId) {
                this.getDispatchers().setInReduxState({ wizardCopyMoveDashboardId: undefined, wizardCopyMoveWidgetsAll: [], wizardCopyMoveWidgetsSelected: [] })
                return;
            }
            const query = gql(`query ($id: Long) {
                dashboardService_findById(id: $id) {
                    configJson
                }
            }`)
            const configJson = (await apolloClientHolder.apolloClient.query({ query: query, variables: { id: dashboardId } })).data['dashboardService_findById'].configJson
            let config = { layouts: {}, widgetWrapperConfigs: {} as any }
            if (configJson) {
                config = JSON.parse(configJson)
            }
            const options = Object.keys(config.widgetWrapperConfigs).map(id => {
                const title = config.widgetWrapperConfigs[id].widgetConfig.title as string
                if (title) {
                    return { value: id, label: title, config: config.widgetWrapperConfigs[id] }
                } else {
                    return { value: id, label: id, config: config.widgetWrapperConfigs[id] }
                }
            }).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
            this.getDispatchers().setInReduxState({ wizardCopyMoveDashboardId: dashboardId, wizardCopyMoveWidgetsAll: options, wizardCopyMoveWidgetsSelected: [] })
        }
    }
})

export type DashboardTabProps = PropsFrom<typeof sliceDashboardTab> & {
    editorDispatchers?: DispatchersFrom<typeof sliceEntityEditorPageDashboard>,
    dashboardEntity: Dashboard,
    entityForAttachedDashboard?: any,
    buttons?: any,
    zeroTrainingMode?: boolean,
    dataExplorerFilter?: Filter,
    dataExplorerEntityDescriptor?: EntityDescriptor
}

export type RenderExpandedDashboardParams = { key: string | number, org: Organization | undefined, isEntityDashboard: boolean, mobileMode: boolean };

export class DashboardTab extends React.Component<DashboardTabProps> {

    componentDidMount() {
        this.props.dispatchers.newLayoutBreakpoint('lg')
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: DashboardTabProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: DashboardTabProps) {
        let props = this.props
        if (prevProps && props.dashboardEntity.id !== prevProps.dashboardEntity.id) {
            props.dispatchers.setInReduxState({ layoutInit: false })
        }
        if (props.dashboardEntity.id !== prevProps?.dashboardEntity?.id) {
            const config = props.dashboardEntity.config;
            props.dispatchers.setInReduxState({
                widgetWrapperConfigs: config.widgetWrapperConfigs,
                layouts: { ...config.layouts, ...{ 'md': config.layouts['lg'] }, ...{ 'sm': config.layouts['lg'] } },
                layoutInit: false,
                widgetHeaderFontSize: config.widgetHeaderFontSize,
                widgetHeaderBackgroundColor: config.widgetHeaderBackgroundColor,
                widgetHeaderIcon: config.widgetHeaderIcon
            });
        }
        if (!prevProps || prevProps.currentOrganization?.id !== this.props.currentOrganization?.id || 
            prevProps.currentOrganizationToFilterBy?.id !== this.props.currentOrganizationToFilterBy?.id) {
                this.onCurrentOrganizationChange(props.dashboardEntity, this.props.currentOrganization, this.props.currentOrganizationToFilterBy);
        }

    }

    protected shouldExpandOrganizations() {
        const { props } = this;
        return props.mode !== DashboardMode.EDIT && props.dashboardEntity.expandOrganizations;
    }

    // CS: too much code in the component; should have been in the slice/impure for testability; but I'm pressed by time
    protected async onCurrentOrganizationChange(dashboard: Dashboard, currentOrg?: Organization, currentOrganizationToFilterBy?:Organization) {
        let orgs: (Organization | undefined)[] | undefined = global.organizations?.filter(org => this.shouldDisplayCurrentOrganization(org as Organization, currentOrg, currentOrganizationToFilterBy));
        if (currentOrganizationToFilterBy?.qualifiedName === currentOrg?.qualifiedName && orgs) {
            if (!currentOrganizationToFilterBy) {
                orgs = [undefined, ...orgs];
            }
        } else if (orgs) {
            orgs = [orgs[0]];
        }
        this.props.dispatchers.setInReduxState({ expandedOrgs: orgs as any });
    }

    protected shouldDisplayCurrentOrganization(org: Organization, currentOrg?: Organization, currentOrganizationToFilterBy?:Organization) {
        return !currentOrg && !currentOrganizationToFilterBy || (org!.qualifiedName!.startsWith(currentOrganizationToFilterBy ? currentOrganizationToFilterBy.qualifiedName : currentOrg!.qualifiedName));
    }

    render() {
        const props = this.props
        const mobileMode = props.currentBreakpoint === 'xs' || props.currentBreakpoint === 'xxs' ? true : false
        let expandedOrgsLocal: undefined | Array<Organization | undefined>;
        if (!this.shouldExpandOrganizations()) {
            // we put one element in the list, to have the loop run for one iteration
            expandedOrgsLocal = [undefined];
        } else {
            if (!props.expandedOrgs) {
                // orgs not yet received
                expandedOrgsLocal = undefined;
            } else {
                expandedOrgsLocal = props.expandedOrgs.slice(0, props.expandedOrgsMaxSize);
            }
        }
        const isEntityDashboard = props.dashboardEntity.forEditor;
        return (<>
            <Measure bounds onResize={contentRect => this.props.dispatchers.onResize({ height: contentRect.bounds?.height, fitHeight: props.dashboardEntity.fitHeight && props.entityForAttachedDashboard })}>
                {({ measureRef }) => {
                    return (
                        <div data-cy="Dashboard.page" className={"flex-container" + (true || isEntityDashboard ? " Dashboard_EntityDashboardWrapper" : "")}>
                            {props.editorDispatchers && <Segment className="less-padding" style={{ margin: "0 5px" }} data-cy="Dashboard.toolbar">
                                <Button positive onClick={() => this.props.dispatchers.setInReduxState({ refresh: !this.props.refresh })}><Icon name='refresh' /> {_msg('dto_crud.refresh')}</Button>
                                {props.mode === DashboardMode.VIEW ?
                                    <Button onClick={e => props.editorDispatchers!.setInReduxState({ dashboardMode: DashboardMode.EDIT })}>
                                        <Icon name='edit' /> {_msg('Dashboard.mode.edit')}</Button> :
                                    <Button onClick={e => props.editorDispatchers!.setInReduxState({ dashboardMode: DashboardMode.VIEW })}>
                                        <Icon name='eye' /> {_msg('Dashboard.mode.view')}</Button>}
                                {props.mode === DashboardMode.EDIT ? <>
                                    <Button icon='add' label={_msg('Dashboard.button.add')} disabled={this.props.mode === DashboardMode.VIEW}
                                        onClick={e => this.props.dispatchers.setInReduxState({ wizardOpen: true })} data-cy="Dashboard.widget.wizard.add" />
                                    <div className="EntityTablePage_barDivider" />
                                    {props.buttons}
                                </> : null}
                            </Segment>}
                            {global.currentOrganizationToFilterBy && props.expandedOrgs && props.expandedOrgs.length > 1 && !props.dashboardEntity.expandOrganizations && !props.dashboardEntity.forEditor
                            && props.mode !== DashboardMode.EDIT ?
                            <div className="flex-container flex-grow" style={{ margin: "5px" }}><Message error>
                                <Message.Header>{_msg("Dashboard.error")}</Message.Header>
                                <p>{_msg("Dashboard.error.expandOrganizations")}</p>
                            </Message></div>
                            :
                            <div data-cy="Dashboard.layout" ref={measureRef} style={{ height: isEntityDashboard ? '100%' : 'calc(100% - 65px)' }}>
                                {expandedOrgsLocal?.map((org: Organization | undefined, i: number) => ( // cast to any because odd error in browser (older vers of TS), but not in VS Code (newer version of TS).
                                    this.renderExpandedDashboard({ key: i, org, isEntityDashboard, mobileMode })
                                ))}
                                {((expandedOrgsLocal && expandedOrgsLocal.length > 1)) && <>
                                    <Divider />
                                    <EntityNumberDisplayed ed={entityDescriptors["Organization"]} displayed={expandedOrgsLocal.length} total={props.expandedOrgs!.length} />
                                    {expandedOrgsLocal.length < props.expandedOrgs!.length ? <Button icon="angle double down" content={_msg("general.loadMore")} onClick={() => props.dispatchers.setInReduxState({ expandedOrgsMaxSize: props.expandedOrgsMaxSize + EXPANDED_ORGS_SIZE_INCREMENTS })} /> : null}
                                </>}
                                <ModalExt open={props.wizardOpen} onClose={() => props.dispatchers.onCancelClick()} data-cy="Dashboard.widget.wizard.description" style={{ width: '65vw' }}>
                                    <Modal.Header>{_msg('Dashboard.widget.wizard.addWidget')}</Modal.Header>
                                    <Modal.Content><Modal.Description>
                                        <DashboardWidgetWizard {...props} />
                                    </Modal.Description></Modal.Content>
                                </ModalExt>
                                <ModalExt open={props.errorOpen} size={"small"}>
                                    <Modal.Header>{_msg('Dashboard.widget.wizard.finish.error.header')}</Modal.Header>
                                    <Modal.Content><Modal.Description>
                                        <p>{_msg('Dashboard.widget.wizard.finish.error.content')}</p>
                                    </Modal.Description></Modal.Content>
                                    <Modal.Actions>
                                        <Button onClick={() => this.props.dispatchers.setInReduxState({ errorOpen: false })}>{_msg('general.ok')}</Button>
                                    </Modal.Actions>
                                </ModalExt>
                                <ModalExt open={props.wizardMoveConfirmation} size={"small"}>
                                    <Modal.Header>{_msg('Dashboard.wizard.confirmation.move.header')}</Modal.Header>
                                    <Modal.Content><Modal.Description>
                                        <p>{_msg('Dashboard.wizard.confirmation.move.content')}</p>
                                    </Modal.Description></Modal.Content>
                                    <Modal.Actions>
                                        <Button positive onClick={() => props.dispatchers.moveWidgets(props.dashboardEntity.id, props.editorDispatchers!)}>{_msg('general.ok')}</Button>
                                        <Button negative onClick={() => props.dispatchers.setInReduxState({ wizardMoveConfirmation: false })}>{_msg('general.cancel')}</Button>
                                    </Modal.Actions>
                                </ModalExt>
                            </div>}
                        </div>
                    );
                }}
            </Measure>
        </>
        );
    }

    protected renderOrganizationTitleBar(org: Organization) {
        return <h1 className="no-margin" id={org.qualifiedName}><Icon name={organizationEntityDescriptor.icon as any} />{organizationEntityDescriptor.toMiniString(org)}</h1>;
    }

    protected renderExpandedDashboard({ org, isEntityDashboard, ...params }: RenderExpandedDashboardParams): ReactElement | null {
        const props = this.props;
        const expandedOrganization = org ? org : this.props.currentOrganizationToFilterBy;
        return <React.Fragment key={params.key}>
            {this.renderOrganizationTitleBar(expandedOrganization ? expandedOrganization : {id: -1, name: _msg("general.all"), qualifiedName: _msg("general.all")})}
            <ResponsiveReactGridLayout maxRows={isEntityDashboard ? DashboardMaxRows.ENTITY.valueOf() : !params.mobileMode && props.dashboardEntity.fitHeight ? DashboardMaxRows.FIT_HEIGHT.valueOf() : DashboardMaxRows.NORMAL.valueOf()}
                cols={this.props.cols} rowHeight={isEntityDashboard ? DashboardRowHeight.ENTITY : this.props.rowHeight}
                isDraggable={props.mode === DashboardMode.EDIT && !props.wizardOpen && props.editorOpen === undefined && !props.errorOpen}
                isResizable={props.mode === DashboardMode.EDIT && !props.wizardOpen && props.editorOpen === undefined && !props.errorOpen}
                layouts={props.layouts} onLayoutChange={(layout, layouts) => props.dispatchers.layoutChange(layouts)}
                onBreakpointChange={(newBreakpoint: string) => this.props.dispatchers.newLayoutBreakpoint(newBreakpoint)} measureBeforeMount={false}
                compactType={props.compactType} preventCollision={false} margin={[5, 5]}
            >
                {Object.keys(props.widgetWrapperConfigs).map(id => {
                    const typeAndWidgetConfig = props.widgetWrapperConfigs[id];
                    const factory = DashboardWidgetFactories.INSTANCE.widgets[typeAndWidgetConfig.type];
                    if (!factory) {
                        throw new Error("Illegal widget type: " + typeAndWidgetConfig.type);
                    }
                    const orgFromFilter = global.currentOrganizationToFilterBy ? ("." + global.currentOrganizationToFilterBy.id) : "";
                    return <div key={id} className="DashboardWidget">      
                        <WidgetWrapper id={id} sliceName={props.dashboardEntity.id + "." + id + "." + params.key + orgFromFilter} mode={props.mode} factory={factory} widgetConfig={typeAndWidgetConfig.widgetConfig} expandedOrganization={expandedOrganization}
                            editorOpen={props.editorOpen} dispatchers={props.dispatchers} dashboardEntity={props.dashboardEntity} dataExplorerFilter={props.dataExplorerFilter} dataExplorerEntityDescriptor={props.dataExplorerEntityDescriptor}
                            entityForAttachedDashboard={props.entityForAttachedDashboard} zeroTrainingMode={props.zeroTrainingMode} deleteModalOpen={props.deleteModalOpen} refresh={this.props.refresh}
                            headerFontSize={props.widgetHeaderFontSize} headerBackgroundColor={props.widgetHeaderBackgroundColor} headerIcon={props.widgetHeaderIcon} />
                    </div>
                })}
            </ResponsiveReactGridLayout>
        </React.Fragment>
    }
}

export function createButtonForWidget(buttonProps: ButtonProps, onClick: () => any) {
    return <Button size="tiny" hidden className='DashboardWidgetButton' {...buttonProps} onClick={onClick} />
}

function parseWidgetConfig(type: string, widgetConfig: any, dashboardEntity: Dashboard) {
    let values: any = {};
    let valid = true;
    let fields = DashboardWidgetFactories.INSTANCE.widgets[type].getEntityDescriptor(widgetConfig, dashboardEntity).fields
    Object.keys(fields).forEach(f => {
        if (fields[f].optional !== true && !widgetConfig[f]) {
            valid = false
        } else {
            values[f] = fields[f].json && widgetConfig[f] ? JSON.parse(widgetConfig[f]) : widgetConfig[f]
        }
    });
    delete values['uid']
    return [valid, values]
}
