import { BigState, EntityEditorFormSimple, Utils } from '@crispico/foundation-react';
import { sliceFilterWidget } from '@crispico/foundation-react/components/CustomQuery/FilterWidget';
import { ListOfRecords, sliceListOfRecords } from '@crispico/foundation-react/components/ListOfRecords/ListOfRecords';
import { enableAllFilters, entityDescriptors } from '@crispico/foundation-react/entity_crud/entityCrudConstants';
import { EntityDescriptor, FieldDescriptor, addEntityDescriptor } from '@crispico/foundation-react/entity_crud/EntityDescriptor';
import { FieldEditorProps, OneToManyEditor } from '@crispico/foundation-react/entity_crud/fieldRenderersEditors';
import { ConnectedComponentInSimpleComponent, ConnectedPageInfo, createSliceFoundation, DispatchersFrom, getBaseReducers, PropsFrom, StateFrom } from '@crispico/foundation-react/reduxHelpers';
import React, { ReactElement } from 'react';
import { Button, Dropdown, DropdownItemProps, DropdownProps, LabelProps, Segment, Select } from 'semantic-ui-react';
import { slicePieCountByCriteriaTab, PieCountByCriteriaTab, PieCountByCriteriaProps } from './pieCountByCriteriaTab/PieCountByCriteriaTab';
import { PIE_COUNT_BY_CRITERIA, DEMO_WIDGET, DEMO_WIDGET_2, LIST_RECORDS_WIDGET, CALCULATE_RECORDS_WIDGET, FIELD_WIDGET, FREE_TEXT_WIDGET } from './DashboardContants';
import { testState0, configTestState0 } from './dashboardTab/testStates';
import { Filter } from '@crispico/foundation-react/components/CustomQuery/Filter';
import { CalculateForRecords, sliceCalculateForRecords, CalculateForRecordsProps, CalculateForRecordsWidgetConfig } from '@crispico/foundation-react/components/CalculateForRecords/CalculateForRecords';
import { Optional } from '@crispico/foundation-react/CompMeta';
import gql from 'graphql-tag';
import { apolloClientHolder } from '@crispico/foundation-react/apolloClient';
import { FieldWidget, sliceFieldWidget } from './dashboardTab/entity_widgets/FieldWidget/FieldWidget';
import { FieldType } from '@crispico/foundation-react/entity_crud/FieldType';
import { FreeTextWidget } from './dashboardTab/entity_widgets/FreeTextWidget';
import { WidgetProps } from './dashboardTab/WidgetWrapper';
import { EntityFilterMode } from '@crispico/foundation-react/entity_crud/fieldRenderersEditors/EntityFilter';

export enum DashboardWidgetType {
    FILTER, ENTITY, ANY
}

export class DashboardWidgetFactories {
    static INSTANCE = new DashboardWidgetFactories();

    widgets: { [widgetType: string]: DashboardWidgetFactory } = {};
}

export abstract class DashboardWidgetFactory {

    public type = DashboardWidgetType.FILTER;

    public allowMultiple = true;

    abstract getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor;
    renderWidget(id: string, connectedPageInfo: ConnectedPageInfo, widgetConfig: any, dashboardEntity: any, entity: any, buttonBarRef: any, zeroTrainingMode: boolean): JSX.Element {
        throw new Error("Implement me, or use hasRenderWidget2()");
    }

    // TODO by CS: this is the correct way: with an implementation here, and individual widgets shouldn't
    // have custom logic. Since I didn't have time, I added this "opt-in" logic. We should apply for all,
    // then delete old renderWidget, and rename this
    hasRenderWidget2 = () => false;
    renderWidget2(connectedPageInfo: ConnectedPageInfo, widgetProps: WidgetProps): ReactElement {
        return <ConnectedComponentInSimpleComponent info={connectedPageInfo} {...widgetProps} />
    }

    renderTitle(widgetConfig: any) {
        return widgetConfig.title;
    }
    renderEditor(id: string, widgetConfig: any, dispatchers: DispatchersFrom<any>, dashboardEntity: any): JSX.Element {
        return this.renderEditorInternal(id, widgetConfig, dispatchers, dashboardEntity);
    };
    protected renderEditorInternal(id: string, widgetConfig: any, dispatchers: DispatchersFrom<any>, dashboardEntity: any, entityDescriptor?: EntityDescriptor) {
        return <EntityEditorFormSimple
            entity={{ ...widgetConfig, ...{ uid: id } }}
            entityDescriptor={entityDescriptor ? entityDescriptor : this.getEntityDescriptor(widgetConfig, dashboardEntity)}
            onSubmitHandler={values => dispatchers.setInReduxState({ values: values }) && dispatchers.editItem({ uid: id, dashboardEntity: dashboardEntity })}
            onCancelHandler={() => dispatchers.setInReduxState({ editorOpen: undefined })} />
    }
    abstract getConnectedPageInfo(sliceName: string, widgetConfig: any): ConnectedPageInfo;
    abstract getWizardInfo(): { title: string, description: string, slice?: any, component?: any, testState?: any, image?: string, storybook?: boolean };
    isWidgetWrapperVisible(widgetConfig: any) {
        return true;
    }
}

export function DashboardWidgetEntityDescriptor(name: string) {
    return new EntityDescriptor({ name: name, hasAttachedDashboards: false }, false)
        .addFieldDescriptor({ name: "uid", type: FieldType.string, optional: true, enabled: false })
        .addFieldDescriptor({ name: "description", type: FieldType.text, optional: true })
        .addFieldDescriptor({ name: "headerFontSize", type: FieldType.number, optional: true })
        .addFieldDescriptor({ name: "headerBackgroundColor", type: FieldType.color, optional: true })
        .addFieldDescriptor({ name: "headerIcon", type: FieldType.string, optional: true })
        .addFieldDescriptor({ name: "backgroundColor", type: FieldType.color, optional: true })
}

export function DashboardWidgetWithFilterEntityDescriptor(name: string) {
    return DashboardWidgetEntityDescriptor(name)
        .addFieldDescriptor({ name: "entityType", type: FieldType.entityName })
        .addFieldDescriptor({ name: "filter", type: FieldType.filter, mode: EntityFilterMode.OBJECT, fieldForEntityName: "entityType", optional: true })
        .addFieldDescriptor({ name: "applyDataExplorerFilterTo", type: FieldType.entityFields, fieldForEntityName: "entityType", allowMultiple: false, optional: true })
}

const sliceDemoWidget = createSliceFoundation(class SliceDemoWidget {
    initialState = {
        counter: 1,
    }

    reducers = {
        ...getBaseReducers<SliceDemoWidget>(this),

        multiplyAndIncrement(state: StateFrom<SliceDemoWidget>, p: { multiplyExisting: number, add: number }) {
            state.counter = state.counter * p.multiplyExisting + p.add;
        }
    }
})

type DemoWidgetProps = PropsFrom<typeof sliceDemoWidget> & { rootFilter: Filter, widgetConfig: { someText: string, someColor: string } }
export function DemoWidget(props: DemoWidgetProps) {
    return (<><Segment>
        I'm a widget. Something from widgetConfig = <b>{props.widgetConfig.someText}</b>; something from state = <b>{props.counter}</b>&nbsp;
            <Button color={props.widgetConfig.someColor as any} onClick={() => props.dispatchers.multiplyAndIncrement({ multiplyExisting: 2, add: 1 })}>Modify the state</Button>
    </Segment>
    </>);
}


DashboardWidgetFactories.INSTANCE.widgets[DEMO_WIDGET] = new class extends DashboardWidgetFactory {
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor('DemoWidget');
    }
    renderWidget(id: string, connectedPageInfo: ConnectedPageInfo, widgetConfig: any, dashboardEntity: any, entity: any, buttonBarRef: any, zeroTrainingMode: boolean) {
        return <ConnectedComponentInSimpleComponent info={connectedPageInfo} widgetConfig={widgetConfig} />
    }
    getConnectedPageInfo(sliceName: string, widgetConfig: any) {
        return new ConnectedPageInfo(sliceDemoWidget, DemoWidget, sliceName);
    }
    getWizardInfo() {
        return { title: 'DemoWidget', description: 'This is a demonstration widget.', slice: sliceDemoWidget, component: DemoWidget, testState: { ...testState0.demoWidget1, ...configTestState0.widgetWrapperConfigs.demoWidget2 }, storybook: true }
    }
}();


type DemoWidget2Props = PropsFrom<typeof sliceDemoWidget> & { filter: Filter, rootFilter: Filter, entityType: string, rootFilterCount?: number, widgetConfig: { someText: string, someColor: string } }
export function DemoWidget2(props: DemoWidget2Props) {
    return (<><Segment>
        I'm a widget with access to root filter. Something from widgetConfig = <b>{props.widgetConfig.someText}</b>; something from state = <b>{props.counter}</b>&nbsp;
            <Button color={props.widgetConfig.someColor as any} onClick={() => props.dispatchers.multiplyAndIncrement({ multiplyExisting: 2, add: 1 })}>Modify the state</Button>
    </Segment>
        <div>
            <label>
                <b>Root filter:</b> {JSON.stringify(props.rootFilter)}
            </label><br />
            <label>
                <b>My filter:</b> {JSON.stringify((props as any).widgetConfig.filter)}
            </label><br />
            <label>
                <b>Entity type:</b> {props.entityType}
            </label><br />
            <label>
                <b>Root filter count:</b> {props.rootFilterCount}
            </label>
        </div>
        <Utils.Observer value={props.rootFilter} didUpdate={() => console.log(props.rootFilter)} />
    </>);
}


DashboardWidgetFactories.INSTANCE.widgets[DEMO_WIDGET_2] = new class extends DashboardWidgetFactory {
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor('DemoWidget2')
            .addFieldDescriptor({ name: "filter", type: FieldType.filter, entityDescriptor: entityDescriptors["Employee"], optional: true });
    }
    renderWidget(id: string, connectedPageInfo: ConnectedPageInfo, widgetConfig: any, dashboardEntity: any, entity: any, buttonBarRef: any, zeroTrainingMode: boolean) {
        return <ConnectedComponentInSimpleComponent info={connectedPageInfo} widgetConfig={widgetConfig} />
    }
    getConnectedPageInfo(sliceName: string, widgetConfig: any) {
        let connectedPageInfo = new ConnectedPageInfo(sliceDemoWidget, DemoWidget2, sliceName);
        connectedPageInfo.mapBigStateToProps = (state: BigState, props: DemoWidget2Props) => {
            if (widgetConfig.rootFilter) {
                props.rootFilter = ((state as any)[widgetConfig.rootFilter] as StateFrom<typeof sliceFilterWidget>).filter;
                props.entityType = ((state as any)[widgetConfig.rootFilter] as StateFrom<typeof sliceFilterWidget>).entityType;
                props.rootFilterCount = ((state as any)[widgetConfig.rootFilter] as StateFrom<typeof sliceFilterWidget>).count;
            }
        }
        return connectedPageInfo;
    }
    getWizardInfo() {
        return { title: _msg("Dashboard.widget.demoWidget.title"), description: 'TODO', slice: sliceDemoWidget, component: DemoWidget2, testState: { ...testState0.demoWidget2, ...configTestState0.widgetWrapperConfigs.demoWidget2 }, storybook: true }
    }
}();

class CalculationLogicFieldEditor extends React.Component<FieldEditorProps> {
    state = {
        calculationLogics: undefined as Optional<Array<String>>
    }

    constructor(props: FieldEditorProps) {
        super(props);
        this.getCalculationLogics();
    }

    async getCalculationLogics() {
        if (this.state.calculationLogics) {
            return;
        }
        const query = gql(`query q { dashboardService_calculationLogics }`);
        const result = (await apolloClientHolder.apolloClient.query({ query })).data.dashboardService_calculationLogics;
        this.setState({ calculationLogics: result });
    }

    handleChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, data.value);

    render = () => {
        const value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values);
        let options: any[] = [];
        this.state.calculationLogics?.sort().forEach((value: String) => {
            options.push({ key: value, text: value, value });
        });

        return <Dropdown data-cy={"calculationLogicDropdown"} fluid selection search={true} options={options} onChange={this.handleChange} value={value} />
    }
}

const calculationLogicFieldDescriptor = new class extends FieldDescriptor {
    constructor() {
        super();
        this.name = "calculationLogic";
        this.type = "custom";
    }

    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
        return React.createElement(CalculationLogicFieldEditor as any, props);
    }
}()

DashboardWidgetFactories.INSTANCE.widgets[CALCULATE_RECORDS_WIDGET] = new class extends DashboardWidgetFactory {
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetWithFilterEntityDescriptor("CalculateForRecords")
            .addFieldDescriptor({ name: "title", type: FieldType.string })
            .addFieldDescriptor(calculationLogicFieldDescriptor)
            .addFieldDescriptor({ name: "parameters", type: FieldType.string, json: true, optional: true })
            .addFieldDescriptor({ name: "saveIntervalExpression", type: FieldType.cron, optional: true })
            .addFieldDescriptor({ name: "rollingAverageExpression", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "showChartInWidget", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "fontSize", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "valueColor", type: FieldType.color, optional: true, defaultValue: "black" })
            .addFieldDescriptor({ name: "intervals", type: FieldType.string, json: true, optional: true })
            .addFieldDescriptor({ name: "scaleFactors", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "averageColor", type: FieldType.color, optional: true, defaultValue: "black" })
            .addFieldDescriptor({ name: "showAverageAsMainInfo", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "upwardTrendIndicatorIsRed", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "historyDaysToKeep", type: FieldType.number, optional: true })
            .addFieldDescriptor({ name: "groupBySuborganizations", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "groupByFields", type: FieldType.entityFields, fieldForEntityName: "entityType", showOnlyComposedFields: true, optional: true })
        }

    hasRenderWidget2 = () => true;

    renderWidget2(connectedPageInfo: ConnectedPageInfo, widgetProps: WidgetProps): ReactElement {
        const config: CalculateForRecordsWidgetConfig = widgetProps.widgetConfig;
        return <ConnectedComponentInSimpleComponent info={connectedPageInfo} {...widgetProps} />
    }

    renderEditor(id: string, widgetConfig: any, dispatchers: DispatchersFrom<any>, dashboardEntity: any) {
        return this.renderEditorInternal(id, widgetConfig, dispatchers, dashboardEntity, this.getEntityDescriptor({ ...widgetConfig, idIfReferenced: id }, dashboardEntity));
    }
    getConnectedPageInfo(sliceName: string, widgetConfig: any) {
        return new ConnectedPageInfo(sliceCalculateForRecords, CalculateForRecords, sliceName);
    }
    getWizardInfo() {
        return { title: _msg("CalculateForRecordsWidget"), description: 'TODO', slice: sliceCalculateForRecords, component: CalculateForRecords, testState: { ...testState0.calculateWidget, ...configTestState0.widgetWrapperConfigs.calculateWidget } }
    }
}();

addEntityDescriptor(new EntityDescriptor({ name: "EmployeeForDemo" }))
    .addFieldDescriptor({ name: "firstName", type: FieldType.string })
    .addFieldDescriptor({ name: "lastName", type: FieldType.string })
    .addFieldDescriptor({ name: "birthDate", type: FieldType.date })
    .addFieldDescriptor({ name: "stillEmployed", type: FieldType.boolean })
    .addFieldDescriptor({ name: "rating", type: FieldType.double });

type ListOfRecordsProps = PropsFrom<typeof sliceListOfRecords> & { rootFilter?: Filter }
DashboardWidgetFactories.INSTANCE.widgets[LIST_RECORDS_WIDGET] = new class extends DashboardWidgetFactory {
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetWithFilterEntityDescriptor("ListOfRecords")
            .addFieldDescriptor({ name: "title", type: FieldType.string })
            .addFieldDescriptor({ name: "fields", type: FieldType.entityFields, fieldForEntityName: "entityType", optional: true })
            .addFieldDescriptor({ name: "sorts", type: FieldType.sort, fieldForEntityName: "entityType", optional: true })
            .addFieldDescriptor({ name: "nrOfRecords", type: FieldType.defaultScalar })
            .addFieldDescriptor({ name: "displayAsTable", type: FieldType.boolean, optional: true })
    }

    hasRenderWidget2 = () => true;

    getConnectedPageInfo(id: string, widgetConfig: any) {
        return new ConnectedPageInfo(sliceListOfRecords, ListOfRecords, id)
    }
    getWizardInfo() {
        return { title: _msg("ListOfRecordsWidget"), description: 'TODO', slice: sliceListOfRecords, component: ListOfRecords, testState: { ...testState0.listWidget, ...configTestState0.widgetWrapperConfigs.listWidget } }
    }

}();

DashboardWidgetFactories.INSTANCE.widgets[PIE_COUNT_BY_CRITERIA] = new class extends DashboardWidgetFactory {
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetWithFilterEntityDescriptor("PieCountByCriteria")
            .addFieldDescriptor({ name: "title", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "currentDate", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "field", type: FieldType.string, json: true })
            .addFieldDescriptor({ name: "pieSliceType", type: FieldType.entityName, optional: true })
            .addFieldDescriptor({ name: "pieSlices", type: 'PieSlices', optional: true }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const entityName = props.formikProps.values["pieSliceType"]
                    const newProps: FieldEditorProps = { ...props, innerEntityDescriptor: entityName ? entityDescriptors[entityName] : undefined }
                    return React.createElement(OneToManyEditor as any, newProps);
                }
            })
            .addFieldDescriptor({ name: "excludeOthers", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "detailedTooltip", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "fontSizeForTotal", type: FieldType.number, optional: true })
            .addFieldDescriptor({ name: "colors", type: FieldType.pieCountColorPalette, optional: true})
            .addFieldDescriptor({ name: "showLegend", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "legendDoesNotShowValuesUnder", type: FieldType.number, defaultValue: 0, optional: true });
    }

    hasRenderWidget2 = () => true;

    getConnectedPageInfo(sliceName: string, widgetConfig: any) {
        return new ConnectedPageInfo(slicePieCountByCriteriaTab, PieCountByCriteriaTab, sliceName);
    }
    getWizardInfo() {
        return { title: _msg("Chart.pieCountByCriteria.title"), description: _msg("Chart.pieCountByCriteria.content"), slice: slicePieCountByCriteriaTab, component: PieCountByCriteriaTab, testState: { ...testState0.pieCountByCriteria, ...configTestState0.widgetWrapperConfigs.pieCountByCriteria } }
    };
}();

DashboardWidgetFactories.INSTANCE.widgets[FIELD_WIDGET] = new class extends DashboardWidgetFactory {
    type = DashboardWidgetType.ENTITY;

    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor("FieldWidget")
            .addFieldDescriptor({ name: "headerFontSize", defaultValue: 10 }) // default 10 for this widget, the field is in DashboardWidgetEntityDescriptor
            .addFieldDescriptor({ name: "title", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "entityName", type: FieldType.entityName, defaultValue: dashboardEntity.entityName, enabled: false })
            .addFieldDescriptor({ name: "field", type: FieldType.entityFields, fieldForEntityName: "entityName", allowMultiple: false })
            .addFieldDescriptor({ name: "fontSize", type: FieldType.string, optional: true })
    }
    renderWidget(id: string, connectedPageInfo: ConnectedPageInfo, widgetConfig: any, dashboardEntity: any, entity: any | undefined, buttonBarRef: any, zeroTrainingMode: boolean) {
        return <ConnectedComponentInSimpleComponent info={connectedPageInfo} config={widgetConfig} buttonBarRef={buttonBarRef} zeroTrainingMode={zeroTrainingMode} id={id} entity={entity} />
    }
    renderTitle(widgetConfig: any) {
        let fd: FieldDescriptor | undefined = entityDescriptors[widgetConfig.entityName].fields[widgetConfig.field];
        return fd ? fd.getLabel(false, false) : null;
    }
    getConnectedPageInfo(id: string, widgetConfig: any) {
        return new ConnectedPageInfo(sliceFieldWidget, FieldWidget, id);
    }
    getWizardInfo() {
        return { title: 'Field widget', description: 'Displays the value of a field and the date of the measurement.', slice: null, component: FieldWidget, testState: { entity: { odometer: 2.204, fieldsLastUpdate: { odometer: "2021-07-07T14:15:41+02:00[Europe/Paris]" } }, config: { entityName: 'EquipmentResource', field: 'odometer' } } }
    };
}

DashboardWidgetFactories.INSTANCE.widgets[FREE_TEXT_WIDGET] = new class extends DashboardWidgetFactory {
    type = DashboardWidgetType.ANY;

    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor("FreeTextWidget")
            .addFieldDescriptor({ name: "text", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "showWidgetWrapper", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "fontSize", type: FieldType.string, optional: true })
    }
    renderWidget(id: string, connectedPageInfo: ConnectedPageInfo, widgetConfig: any, dashboardEntity: any, entity: any | undefined, buttonBarRef: any, zeroTrainingMode: boolean) {
        return <FreeTextWidget config={widgetConfig} />
    }
    isWidgetWrapperVisible(widgetConfig: any) {
        return widgetConfig.showWidgetWrapper;
    }
    getConnectedPageInfo(id: string, widgetConfig: any) {
        return null as unknown as ConnectedPageInfo;
    }
    getWizardInfo() {
        return { title: 'Free text widget', description: 'Displays preconfigured text.', slice: null, component: FreeTextWidget, testState: { config: { text: "An example preconfigured text." } } };
    };
}
