import React from "react";
import {  Checkbox, Button, Label } from "semantic-ui-react";
import { BlocklyReadOnly } from "@crispico/foundation-react/blockly/BlocklyReadOnly";
import { createFilterBlock } from "./createFilterBlock";
import { Filter } from "./Filter";
import { LAST_ENTITY_MAPPING_ID_IN_FILTER } from "@crispico/foundation-react/pages/Audit/AuditUtils";

interface FilterBlockProps {
    filter: Filter;
    index: number;
    toggleFilterEnabled: any;
    removeFilter: any;
    entityDescriptorName: string;
    onBlockClick?: any;
    foundAuditEntityMappingId?: string;
}

export class FilterBlock extends React.Component<FilterBlockProps, any> {

    render() {
        const f = this.props.filter;
        const lastEntityMappingIdInFilter = window.sessionStorage.getItem(LAST_ENTITY_MAPPING_ID_IN_FILTER);
        
        return (
            <Label key={this.props.index} className={`CustomQueryBar_filterDiv`} data-testid={`CustomQueryBar_filterDiv_${f.field}`}>
                <Checkbox data-testid={`CustomQueryBar_filterDiv_${f.field}_checkbox`} onClick={() => this.props.toggleFilterEnabled(this.props.index)} checked={f.enabled ? true : false} />
                <BlocklyReadOnly openEditor={this.props.onBlockClick} key={this.props.index}>
                    {createFilterBlock(f, { entityDescriptorName: this.props.entityDescriptorName, foundAuditEntityMappingId: lastEntityMappingIdInFilter ? lastEntityMappingIdInFilter : undefined }, false, false, true)}
                </BlocklyReadOnly>
                <Button data-testid={`CustomQueryBar_filterDiv_${f.field}_delete`} color="red" size='mini' icon='delete' className="OnlyFilterBar_removeButton" onClick={() => this.props.removeFilter(this.props.index)} />
            </Label>
        );
    }
}