import React from "react";
import { Icon } from "semantic-ui-react";
import { DatePickerExt } from "../DatePickerExt/DatePickerExt";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import moment, { Moment } from "moment";
import { Filter } from "../CustomQuery/Filter";

interface RangePickerState {
    date1: string,
    date2: string,
}

export class RangePickerExtFieldEditor extends React.Component<FieldEditorProps, RangePickerState> {

    constructor(props: FieldEditorProps) {
        super(props);
        const value = this.props.formikProps.values[this.props.fieldDescriptor.name];
        if (value) {
            const values = value.split(Filter.BETWEEN_DELIMITER);
            this.state = {
                date1: values[0] ? values[0] : '',
                date2: values[1] ? values[1] : ''
            }
        } else {
            this.state = { date1: '', date2: ''}
        }
    }

    onChange = (date: Moment | null, index: number) => {
        let newDate = date ? date.toISOString() : '';
        index === 0 ? this.setState({ date1: newDate }) : this.setState({ date2: newDate });
        let value = index === 0 ? newDate + Filter.BETWEEN_DELIMITER + this.state.date2: this.state.date1 + Filter.BETWEEN_DELIMITER + newDate;
        if (value === Filter.BETWEEN_DELIMITER) { value = ''; }
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, value);
    }

    render = () => {
        const defaultValue1 = this.state.date1 ? moment(this.state.date1, moment.ISO_8601) : undefined;
        const defaultValue2 = this.state.date2 ? moment(this.state.date2, moment.ISO_8601) : undefined;
        return <div className='flex-center'>
            <DatePickerExt {...this.props.fieldDescriptor.propsForEditor} defaultValue={defaultValue1} onChange={(date: Moment | null) => this.onChange(date, 0)} />
            <Icon name='arrow right'></Icon>
            <DatePickerExt {...this.props.fieldDescriptor.propsForEditor} defaultValue={defaultValue2} onChange={(date: Moment | null) => this.onChange(date, 1)} />
        </div>
    }
}