import { CompMeta, TestState, AbstractThunksCreators, AbstractReducers, ActionsCreators, PropsFromState, apolloClient, DispatchFunction, Utils } from '@crispico/foundation-react';
import React from "react";
import { Header, Table, Segment } from "semantic-ui-react";
import { getMission, getMissionVariables } from 'apollo-gen/getMission';
import { getObject, getObjectVariables } from 'apollo-gen/getObject';

export const PAGE_URL = (entity: string, entityId: string | number) => `/EventValidationStatusPage/${entity}/${entityId}`;
const FORMAT_TIME = (date: Date) => date.toLocaleTimeString("default", { hour: '2-digit', minute: '2-digit', hour12: false });

export enum EntityType { MISSION = "mission", OBJECT = "object" }

interface EventValidationInfo {
    [key: string]: any;
    name: string;
    validatedAt: string;
    expectedBefore: string;
    delay: string;
    color: string;
    flightName: string;
    objectName: string;
}

interface EventMonitoringRule {
    event: string;
    offset: string;
}

const initialState = {
    actualNumberOfEvents: 0,
    expectedNumberOfEvents: 0,
    data: [] as EventValidationInfo[]
};

export type State = typeof initialState;

// modify oag type after task #23205 is done
const processEvent = (eventKey: string, eventValue: number, oag: any) => {
    const missionEvent2 = oag.mission.events.find((x: any) => x.descriptorType === eventKey && x.oagId === oag.id);
    const creationDate = missionEvent2 ? new Date(missionEvent2.creationdate) : undefined;
    const referenceTime = new Date(oag.object.taskGroup.date);
    const validatedAt = creationDate ? FORMAT_TIME(creationDate) : null;
    referenceTime.setMinutes(referenceTime.getMinutes() + eventValue);
    const delay = getDelay(referenceTime, creationDate);

    return {
        name: eventKey, validatedAt, expectedBefore: FORMAT_TIME(referenceTime), delay: getFormattedDelay(delay),
        color: getColor(delay, creationDate), flightName: oag.object.taskGroup.name, objectName: oag.object.name
    } as EventValidationInfo
}

const getColor = (delay: number, creationDate?: Date) => {
    let color: string = "black";
    if (!creationDate && delay > 0) {
        color = "red";
    }
    else if (creationDate && delay > 0) {
        color = "orange";
    }
    return color;
}

const getDelay = (referenceTime: Date, creationDate?: Date) => {
    let delay: number = 0;

    if (!creationDate) {
        delay = Utils.now().valueOf() - referenceTime.valueOf();
    }
    else {
        delay = creationDate.valueOf() - referenceTime.valueOf();
    }

    return delay;
}

const parseEventMonitoringRulesJson = (eventMonitoringRulesJson: string) => {
    let eventsAndOffsets = new Map<string, number[]>();

    if (!eventMonitoringRulesJson || eventMonitoringRulesJson.trim().length === 0) {
        return eventsAndOffsets;
    }

    if (eventMonitoringRulesJson.startsWith("[")) {
        let eventMonitoringRules: EventMonitoringRule[] = [];

        eventMonitoringRules = JSON.parse(eventMonitoringRulesJson.toLowerCase());

        eventMonitoringRules.map(item => {
            const event = item.event.toUpperCase().trim();
            let offsets = eventsAndOffsets.get(event);

            if (!offsets) {
                offsets = [];
            }

            offsets[offsets.length] = parseInt(item.offset.trim());
            eventsAndOffsets.set(event, offsets);

            return null;
        });
    }
    else {
        const eventsAndOffsetsArray: string[] = eventMonitoringRulesJson.split(",");

        eventsAndOffsetsArray.map(eventAndOffsets => {
            const singularEventAndOffsetsAsArray: string[] = eventAndOffsets.trim().split(/[\s|]+/);

            if (singularEventAndOffsetsAsArray.length < 2) {
                throw new Error("Incorrect non-json format");
            } else {
                let offsets: number[] = [];
                for (let i = 0; i < singularEventAndOffsetsAsArray.length - 1; i++) {
                    offsets[i] = parseInt(singularEventAndOffsetsAsArray[i + 1]);
                }
                eventsAndOffsets.set(singularEventAndOffsetsAsArray[0], offsets);
            }

            return null;
        });
    }

    return eventsAndOffsets;
}

const getFormattedDelay = (delay: number) => {
    if (delay <= 0) {
        return null;
    }

    let delayInMinutes = (delay / (60 * 1000) % 60).toFixed(0).toString();
    let delayInHours = (delay / (60 * 60 * 1000) % 60).toFixed(0).toString();

    delayInMinutes = delayInMinutes.length === 2 ? delayInMinutes : "0" + delayInMinutes;
    delayInHours = delayInHours.length === 2 ? delayInHours : "0" + delayInHours;

    return delayInHours + ":" + delayInMinutes;
}

export class Reducers extends AbstractReducers<State> {

}

export class ThunksFactory extends AbstractThunksCreators<Reducers> {
    loadData(entity: string, entityId: number) {
        return async (dispatch: DispatchFunction) => {
            let data: EventValidationInfo[] = [];
            let actualNumberOfEvents: number = 0;
            let expectedNumberOfEvents: number = 0;

            if (entity === EntityType.MISSION) {
                // const mission = (await apolloClient.query<getMission, getMissionVariables>({
                //     query: GET_MISSION,
                //     variables: { id: entityId }
                // })).data.experimentalWebService_missionService_getById;

                // if (!mission) {
                //     return;
                // }

                // actualNumberOfEvents = mission.eventMonitoringActualNumberOfEvents ? mission.eventMonitoringActualNumberOfEvents : 0;
                // expectedNumberOfEvents = mission.eventMonitoringExpectedNumberOfEvents ? mission.eventMonitoringExpectedNumberOfEvents : 0;

                // remove comment after task #23205 is done
                // for (let oag of mission.objectActionGroups) {
                //     if (!oag.object.eventMonitoringRulesJson) {
                //         continue;
                //     }

                //     parseEventMonitoringRulesJson(oag.object.eventMonitoringRulesJson)
                //         .forEach((value, key) => data.push(processEvent(key, value[0], oag)));
                // };
            }
            else {
                // const object = (await apolloClient.query<getObject, getObjectVariables>({
                //     query: GET_OBJECT,
                //     variables: { id: entityId }
                // })).data.experimentalWebService_objectService_getById;

                // if (!object) {
                //     return;
                // }

                // actualNumberOfEvents = object.eventMonitoringActualNumberOfEvents ? object.eventMonitoringActualNumberOfEvents : 0;
                // expectedNumberOfEvents = object.eventMonitoringExpectedNumberOfEvents ? object.eventMonitoringExpectedNumberOfEvents : 0;

                // remove comment after task #23205 is done
                // for (let oag of object.objectActionGroups) {
                //     if (!oag.object.eventMonitoringRulesJson) {
                //         continue;
                //     }

                //     parseEventMonitoringRulesJson(oag.object.eventMonitoringRulesJson)
                //         .forEach((value, key) => data.push(processEvent(key, value[0], oag)));
                // };
            }

            dispatch(this.actionsCreators.setState({
                actualNumberOfEvents,
                expectedNumberOfEvents,
                data: data.sort((a, b) => a.flightName.localeCompare(b.flightName) || a.objectName.localeCompare(b.objectName) || a.expectedBefore.localeCompare(b.expectedBefore))
            }));
        }
    }
};

export class EventValidationStatusPageMeta extends CompMeta<Reducers, ThunksFactory> {

    constructor(thunksFactoryClass: any) {
        super(initialState, Reducers, thunksFactoryClass, "EventValidationPage");
        this.routeExpression = PAGE_URL(":entity", ":entityId");
    }

    protected createComponent(acfa: ActionsCreators<Reducers>, thfa: ThunksFactory) {
        return (props: PropsFromState<State>) => {
            return (
                <Segment basic>
                    <TestState.RouteHashChangedObserver thunk={thfa.loadData(props.match?.params.entity, props.match?.params.entityId)} />
                    <Header as="h1">{`${props.actualNumberOfEvents}/${props.expectedNumberOfEvents} validated events`}</Header>
                    <Table striped celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Event</Table.HeaderCell>
                                <Table.HeaderCell>Validated at</Table.HeaderCell>
                                <Table.HeaderCell>Expected before</Table.HeaderCell>
                                <Table.HeaderCell>Delay</Table.HeaderCell>
                                <Table.HeaderCell>Flight</Table.HeaderCell>
                                <Table.HeaderCell>Flight object</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {props.data.map((dataRow, index) =>
                                <Table.Row key={"tr" + index}>
                                    {Object.keys(dataRow).filter(x => x !== "color").map((x, index) =>
                                        <Table.Cell key={"tc" + index} style={{ color: dataRow.color }}>{dataRow[x]}</Table.Cell>
                                    )}
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Segment>
            );
        }
    }
}

export const eventValidationStatusPageMeta = new EventValidationStatusPageMeta(ThunksFactory)