import gql from "graphql-tag";

const FLIGHTS_ASSIGNEMENTS_PAGE_FLIGHT_FRAGMENT = gql`
    fragment FlightsAssignmentsPage_Flight on Flight {
        id airline number date initialDate destination planeIdentifier parking { id name } galery { id name } flightType
        baggages { id number date status location object { id name } importedBy }
        missionSubsets { mission { id humanResource { id identifier firstName lastName } equipmentResource { id identifier } objectActionGroups { id object { id name processedBaggages } } type { color } status currentState { id name }  } }
    }    
`;

export const FLIGHTS_ASSIGNEMENTS_PAGE_LOAD_FLIGHTS_QUERY = gql`
    query FlightsAssignmentsPage_loadFlightsQuery($params: FindByFilterParamsInput) { 
        flightService_findByFilter(params: $params) {
            results  { ...FlightsAssignmentsPage_Flight } 
        }
    }
    ${FLIGHTS_ASSIGNEMENTS_PAGE_FLIGHT_FRAGMENT}
`;

export const FLIGHTS_ASSIGNEMENTS_PAGE_CREATE_MISSION = gql`
    mutation FlightsAssignmentsPage_createMissionMutation($flightId:Long!,$humanResourceId:Long,$comment:String,$objectStartAddressId:Long,$missionTypeId:Long) { 
        mission2Service_createMission(flightId:$flightId,humanResourceId:$humanResourceId,comment:$comment,objectStartAddressId:$objectStartAddressId,missionTypeId:$missionTypeId)
    }
`;

const MISSIONS_AWAITING_FOR_DRIVERS_PAGE_MISSION_FRAGMENT = gql`
    fragment MissionsAwaitingForDriversPage_Mission on Mission2 {
        id comment creationDate missionSubsets { flight { id parking { id name } } }
    }    
`;

export const MISSIONS_AWAITING_FOR_DRIVERS_PAGE_LOAD_MISSIONS_QUERY = gql`
    query MissionsAwaitingForDriversPage_loadMissionsQuery($params: FindByFilterParamsInput) { 
        mission2Service_findByFilter(params: $params) {
            results  { ...MissionsAwaitingForDriversPage_Mission } 
        }
    }
    ${MISSIONS_AWAITING_FOR_DRIVERS_PAGE_MISSION_FRAGMENT}
`;
