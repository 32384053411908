
import { combineReducers, configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { makeDecorator } from "@storybook/addons";
import gql from "graphql-tag";
import { createBrowserHistory } from "history";
import lodash from "lodash";
import React, { ReactElement, ReactNode, useEffect } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { apolloClient, apolloClientHolder } from "../apolloClient";
import { CompMeta } from "../CompMeta";
import { ConnectedPageHelper, ConnectedPageInfo, createSliceFoundation, DispatchersFrom, PropsFrom, RootReducerForPages, SliceFoundation, SliceFoundationFromNested, StateFrom, StatePartialFrom } from "../reduxHelpers";
import { ReduxReusableComponents } from "../reduxReusableComponents/ReduxReusableComponents";
import { Utils } from './Utils';

let infoAppContainer: ConnectedPageInfo;
let APP_CONTAINER: string;

init();

async function init() {
    let imported = await import("../AppMeta");
    infoAppContainer = imported.infoAppContainer;
    APP_CONTAINER = imported.APP_CONTAINER;
}

export interface TestUtilsStorybookOptions {
    dontWrapInRouter: boolean;
    additionalHelperAndStates: [CompMeta, any][];
    dontCreateSnapshotReducer: boolean;
}

const wrapWithStoreOptionsDefaults = {
    wrapInRouter: true,
    frozenReducer: false,
    addRef: false,
    mockApolloClient: null as any,
    globalState: null as any,
    additionalProps: null as any,
    extraResults: {} as ExtraResults
}

type ExtraResults = {
    pageHelper: ConnectedPageHelper,
    rootReducerForPages: RootReducerForPages,
    store: EnhancedStore
}

export type WrapWithStoreOptions = Partial<typeof wrapWithStoreOptionsDefaults> | undefined;

const identityReducer = (s: any) => s

export class TestUtils {

    static storybookMode = false;

    static mergeTestState(initialState: any, state: any) {
        // we don't use lodash.cloneDeep(), because it's "too smart". E.g. we may have state.tree and state.tree1, because an instance of a
        // nested component is used twice. But these 2 objects point to the same instance, i.e. the "initialState" constant. And during clone,
        // lodash is smart enough to detect this, and it clones the instance only once, providing 2 pointers towards it. But we need 2 different
        // objects, because otherwise, when we work with the first object, the same modifs are in the second one
        const initialStateClone = JSON.parse(JSON.stringify(initialState));

        // we clone the the first arg = initialState because "merge()" mutates the first arg
        // and we need initial state in order to allow the user to define in testState only
        // portions; and the rest is taken from the initial state
        // we clone the second cf. method doc
        return lodash.merge(initialStateClone, lodash.cloneDeep(state));
    }

    /**
     * Registers one helper + state or several helpers + state (cf. `options.additionalHelperAndStates`). For the multiple/list case, `helper` + `state`
     * are optional. If they exist, they are also appended to the list.
     */
    static createStore(helper: CompMeta | null, state: any, options?: Partial<TestUtilsStorybookOptions>) {
        const helperAndStates = options?.additionalHelperAndStates || [];
        if (helper) {
            helperAndStates.push([helper, state]);
        } else if (!options?.additionalHelperAndStates) {
            throw new Error("Please provide at least one helper.");
        }

        let preloadedState: any = {};
        const reducerCombination: any = {};
        for (let [helper, state] of helperAndStates) {
            if (options?.dontCreateSnapshotReducer) {
                helper.contributeRootReducer(reducerCombination);
            }
            if (state) {
                preloadedState[helper.getKeyInState()] = TestUtils.mergeTestState(helper.getOrCreateInitialState(), state);
            }
        }

        const reducer = options?.dontCreateSnapshotReducer ? combineReducers(reducerCombination) : identityReducer;
        if (Object.keys(preloadedState).length === 0) {
            preloadedState = undefined;
        }

        return configureStore({ reducer, preloadedState });
    }

    static wrapComponentForStorybook(store: any, component: ReactElement, options?: Partial<TestUtilsStorybookOptions>) {
        let result = <Provider store={store}>{component}</Provider>;
        if (!(options?.dontWrapInRouter)) {
            result = <Router history={createBrowserHistory()}>{result}</Router>;
        }
        return result;
    }

    static wrapPageHelperForStorybook(helper: CompMeta<any, any>, state: any, options?: Partial<TestUtilsStorybookOptions>) {
        return TestUtils.wrapComponentForStorybook(TestUtils.createStore(helper, state, options), <helper.wrappedComponent />, options);
    }

    static wrapWithStore(component: any) {
        return (<Provider store={configureStore({ reducer: {} })}>
            {component}
        </Provider>
        )
    }

    private static wrapWithStoreForInfoInternal(pageInfo: ConnectedPageInfo, preloadedState?: any, options?: WrapWithStoreOptions) {
        options = options || {};
        lodash.defaults(options, wrapWithStoreOptionsDefaults);

        if (!pageInfo.sliceName) { pageInfo.sliceName = "dummyForStorybook"; }

        const helper = options.extraResults!.pageHelper = new ConnectedPageHelper(pageInfo);
        const helperAppContainer = new ConnectedPageHelper(infoAppContainer);
        const rootReducer = options.extraResults!.rootReducerForPages = new RootReducerForPages([helper, helperAppContainer]);
        preloadedState = TestUtils.mergeTestState(helper.initialStateMerged, preloadedState);
        
        let globalPreloadedState = { [helper.sliceName]: preloadedState, [APP_CONTAINER]: { ...helperAppContainer.initialStateMerged, initializationsForClientLoaded: true } };
        if (options.globalState) {
            globalPreloadedState = TestUtils.mergeTestState(globalPreloadedState, options.globalState);
        }

        const store = options.extraResults!.store = configureStore({ reducer: options.frozenReducer ? identityReducer : rootReducer.reducer, preloadedState: globalPreloadedState, enhancers: [ReduxReusableComponents.storeEnhancer] })

        if (options.addRef) {
            helper.componentRef = React.createRef();
        }

        let result = (<RootReducerForPages.Context.Provider value={rootReducer}>
            <Provider store={store}>
                {/* 
                    TODO by CS: nu-mi aduc aminte de ce am wrapped in AppContainer; e clar ca am facut efort pentru asta. 
                    Pb e ca AppContainer se "ingrasa", si are nevoie de chestii in state. Care in modul Storybook lipsesc.
                    Ar fi o solutie sa furnizam niste "mocks" pt teste. Dar e error prone. Si din nou: de ce avem nevoie de 
                    AppContainer in modul storybook?
                 */}
                {/* <helperAppContainer.componentClass> */}
                    <helper.componentClass  {...options?.additionalProps} />
                {/* </helperAppContainer.componentClass> */}
            </Provider>
        </RootReducerForPages.Context.Provider>);

        if (options.wrapInRouter) {
            result = <Router history={createBrowserHistory()} >{result}</Router>;
        }

        if (options.mockApolloClient) {
            apolloClientHolder.apolloClient = {
                query() {
                    return { data: options!.mockApolloClient };
                }
            } as any;
        }

        return { jsx: result, helper, store };
    }

    private static wrapWithStoreForInfoInternalMemoized = lodash.memoize(TestUtils.wrapWithStoreForInfoInternal, (...args) => args[1]);

    /**
     * Because of the reason below, we tried (w/ success until now) to memoize this function. Caching the "preloadedState". So calling this function
     * several times w/ the same instance of "preloadedState", will invoke the logic once, and then reuse the initial result. Take this into account!
     * 
     * REASON: Initially this was used only during Storybook. And then we started to use the whole storybook
     * function within the prod app, in the ZeroTraining screens. On each render => we'd call the storybook
     * function, which would call this. Which would create a new: helper, reducer, etc. And more important:
     * a new component / HOC on EACH rerender. => catastrophy, i.e. mount/unmount of the children of the story. 
     * 
     * UPDATE: a small part shouldn't be memoized; i.e. the part that returns to the caller the pageHelper. The caller
     * may use this to get the ref.
     */
    static wrapWithStoreForInfo(pageInfo: ConnectedPageInfo, preloadedState?: any, options?: WrapWithStoreOptions) {
        let result;
        if (TestUtils.storybookMode) {
            // we have some stories (internal, not for ZeroTraining) which have state = undefined; using memoization,
            // the first one "wins", and the other ones are not invoked any more
            result = TestUtils.wrapWithStoreForInfoInternal(pageInfo, preloadedState, options);
        } else {
            result = TestUtils.wrapWithStoreForInfoInternalMemoized(pageInfo, preloadedState, options);
        }

        return result.jsx;
    }

    static wrapWithStoreForSlice<T extends SliceFoundation>(slice: T, componentClass: any, preloadedState: StatePartialFrom<T> | undefined,
        options?: WrapWithStoreOptions & { additionalProps?: any }) {

        const ComponentClass = componentClass;
        return TestUtils.wrapWithStoreForSlices({ c: slice }, { c: preloadedState as any }, options,
            props => <ComponentClass {...props.c} dispatchers={props.dispatchers.c} {...options?.additionalProps} />);
    }

    static wrapWithStoreForSlices<T extends { [K: string]: SliceFoundation }, S = SliceFoundationFromNested<T>>
        (slices: T, preloadedState: StatePartialFrom<S> | undefined, options: WrapWithStoreOptions, render: (props: PropsFrom<S>) => ReactNode) {

        const sliceContainer = createSliceFoundation(class SliceContainer {
            nestedSlices = slices
        });

        function Container(props: any) {
            return render(props);
        }

        const infoContainer = new ConnectedPageInfo(sliceContainer, Container);
        return this.wrapWithStoreForInfo(infoContainer, preloadedState, options);
    }

    static wrapWithTestStoreForSlice<T extends SliceFoundation>(slice: T, preloadedState?: StatePartialFrom<T>): {
        store: EnhancedStore,
        getState: () => StateFrom<T>,
        dispatchers: DispatchersFrom<T>
    } {
        const helper = new ConnectedPageHelper(new ConnectedPageInfo(slice, null, "dummyForTest"));
        helper.initReducer();
        preloadedState = TestUtils.mergeTestState(helper.initialStateMerged, preloadedState);
        const store = configureStore({ reducer: (state, action) => ({ [helper.sliceName]: helper.reducer(state[helper.sliceName], action) }), preloadedState: { [helper.sliceName]: preloadedState } })
        return {
            store,
            getState: () => store.getState()[helper.sliceName],
            dispatchers: helper.createMergedActionDispatchers(store)
        };
    }

    /**
     * Use only during test/testState. Instructions:
     *  
     * r = TestState.replaceDotWithSeparator;
     * 
     * // use as tagged template
     * s = r`a.b` // s == aSEPb where SEP = Utils.defaultSeparator
     * 
     * // use as function with a string or interpolated string as param
     * s = r(`a.b.${c}.d`) // s == aSEPbSEPcSEPd supposing c = "c"
     * 
     * Not intended for production code; use normal interpolation in this case:
     * 
     * ds = Utils.defaultSeparator;
     * s = `a${ds}b`
     */
    static replaceDotWithSeparator(strings: TemplateStringsArray | string, ...args: any[]) {
        let string: string;
        if (strings instanceof Array) {
            // eslint-disable-next-line no-template-curly-in-string
            if (strings.length > 1) { throw new Error("Please transform tagged template into function call. E.g.: replaceDotWithSeparator`text w/ ${interpolation} text` => replaceDotWithSeparator(`text w/ ${interpolation} text`)"); }
            string = strings[0];
        } else {
            string = strings as string;
        }
        // using regex to replace all
        return string.replace(/\./g, Utils.defaultIdSeparator);
    }

    static runTestOnServer(mochaContext: Mocha.Context, ...path: string[]) {
        mochaContext.timeout(10000);
        return apolloClient.mutate({
            mutation: gql(`mutation m($path: [String]){
            typedBddTestService_runTest(path: $path)
        }`), variables: { path }
        });
    }

    /**
     * If what you are calling may take a long time, consider wrapping the call in a separate step. E.g. `beforeEach` + `it`. Or a `describe` that has `it` + `it`.
     * And the first `it` has the call to the server. Or maybe better, `describe` that has `beforeEach` + `it`.
     */
    static runJUnitTestOnServer(className: string, methodName: string) {
        return apolloClient.mutate({
            mutation: gql(`mutation m($methodName: String!, $className: String!){
                typedBddTestService_runJUnitTest(methodName: $methodName, className: $className)
        }`), variables: { methodName, className}
        });
    }
       
    // TODO by CS: de sters, caci vom implementa conventia cu "now" constant; deci nu vom mai avea nevoie de translatii
    static setTranslateDatesToTodayFromOnServer(mochaContext: Mocha.Context, date: string) {
        mochaContext.timeout(10000);
        return apolloClient.mutate({
            mutation: gql(`mutation m($date: String!){
                typedBddTestService_setTranslateDatesToTodayFrom(date: $date)
        }`), variables: { date }
        });
    }
    
    static renderListenerDecorator = makeDecorator({
        // I don't understand why we need this; the type requires it anyway
        name: 'renderListenerDecorator',
        parameterName: 'renderListenerDecorator',
        // This means don't run this decorator if the notes decorator is not set
        skipIfNoParametersOrOptions: true,
        wrapper: (getStory, context, po) => {
            return <TestUtils.RenderListenerDecoratorWrapper render={getStory} callback={po.parameters as any} />
        }
    })

    static RenderListenerDecoratorWrapper(props: { render: Function, callback: Function, trigger?: any }) {
        // Functional components as static functions are not recommended, cf. https://github.com/facebook/react/issues/20342#issuecomment-1082564319
        // Hence silencing the error. Maybe we could have used somehow "namespaces"?
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            setTimeout(() => props.callback());
        }, [props.trigger]); // the trigger = maybe the render function; needed when invoked from ZTArticlePage: switching to another story may not unmount; hence we need this
        return props.render();
    }
   
}

