import gql from "graphql-tag";

export const CHECK_PASSWORD_RESET_KEY = gql`
  query checkPasswordResetKey($resetKey:String) {
    userService_checkPasswordResetKey(resetKey:$resetKey)
  }
`

export const FINISH_PASSWORD_RESET = gql`
    mutation finishPasswordReset($resetKey:String, $newPassword:String) {
        userService_finishPasswordReset(resetKey:$resetKey, newPassword:$newPassword)
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
    mutation requestPasswordReset($email:String) {
        userService_requestPasswordReset(email:$email)
  }
`;

export const CHANGE_PASSWORD_FOR_CURRENT_USER = gql`
    mutation changePasswordForCurrentUser($currentPassword:String, $newPassword:String) {
        userService_changePasswordForCurrentUser(currentPassword:$currentPassword, newPassword:$newPassword)
  }
`;