import { apolloClient, PrivateRoute, PrivateRouteProps, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { ganttAssignmentEntityDescriptor } from "AppEntityDescriptors";
import React from "react";
import { Redirect } from "react-router-dom";
import { Button, Header, Icon, Input, Message, Popup, Segment, TextArea } from "semantic-ui-react";
import { GET_PATHS_GA, SAVE_FROM_DISK_CSV_FILES } from "./queries";

const GANTT_ASSIGNMENT_FROM_FILES_ON_DISK_INPUT = "ganttAssignmentFromFilesOnDiskInput";

class GanttAssignmentFromFilesOnDiskPageState extends State {
    inputCsvFiles: string = "";
    inputUpdateGanttAssignment: string = "";
    paths = "";
}

export class GanttAssignmentFromFilesOnDiskPageReducers<S extends GanttAssignmentFromFilesOnDiskPageState = GanttAssignmentFromFilesOnDiskPageState> extends Reducers<S> {

}

export class GanttAssignmentFromFilesOnDiskPage extends React.Component<RRCProps<GanttAssignmentFromFilesOnDiskPageState, GanttAssignmentFromFilesOnDiskPageReducers>> {
    
    async getPaths() {
        const result = (await (apolloClient.query({ query: GET_PATHS_GA }))).data["ganttAssignmentDevService_paths"];
        const paths:string = result?result: "";
        this.props.r.setInReduxState({paths});
    }

    componentDidMount() {
        const { inputCsvFiles, inputUpdateGanttAssignment } = getGanttAssignmentFromFilesOnDiskInputToLocalStorage();
        this.props.r.setInReduxState({ inputCsvFiles, inputUpdateGanttAssignment });
        this.getPaths();
    }
    
    async readFilesAndCreateAssignment() {
        const variables: { paths: string[], id?: number } = { paths: this.props.s.inputCsvFiles.replaceAll(" ", "").split(",") };
        if (this.props.s.inputUpdateGanttAssignment !== "") {
            variables.id = Number(this.props.s.inputUpdateGanttAssignment);
        }
        const id = (await apolloClient.query({ query: SAVE_FROM_DISK_CSV_FILES!, variables })).data["ganttAssignmentDevService_saveFromDiskCsvFiles"];
        this.props.r.setInReduxState({ inputUpdateGanttAssignment: String(id) });
        setGanttAssignmentFromFilesOnDiskInputToLocalStorage(this.props.s.inputCsvFiles, id);
        window.open(Utils.adjustUrlToServerContext("#" + ganttAssignmentEntityDescriptor.getEntityEditorUrl(id) + "/gantt"));
    }
    
    render() {
        if (process.env.NODE_ENV !== "development" || !AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.isAdmin) {
            return <Redirect to={{ pathname: '/error', state: { from: undefined, headerMessage: _msg("error.insufficient.rights.title"), errorMessage: _msg("GanttAssignmentFromFilesOnDiskPage.invalidAccess") } }} />;
        }
        return <div className="flex-container flex-grow EntityEditorPage_container" style={{ margin: "1em" }}>
            <Segment className="flex-container flex-grow EntityEditorPage_segment">
                <Header as="h3" style={{ marginBottom: 0 }}><Icon name="chart bar" /> {_msg("GanttAssignmentFromFilesOnDiskPage.title")}</Header>
                <Message content={_msg("GanttAssignmentFromFilesOnDiskPage.message")} />
                <div className="flex-container-row">
                    <Header as="h4" style={{ marginTop: 0, flexGrow: "1" }}>{_msg("GanttAssignmentFromFilesOnDiskPage.header")}:</Header>
                    <Popup position="top right" trigger={<Icon color="blue" size="large" name="question circle" />} content={_msg("GanttAssignmentFromFilesOnDiskPage.tooltip") + this.props.s.paths} />
                </div>
                {/* input/dataset1, output/outputDir1 */}
                <TextArea onChange={(e, data) => this.props.r.setInReduxState({ inputCsvFiles: data.value as string})} rows={5} value={this.props.s.inputCsvFiles} />
                <Header as="h4">{_msg("GanttAssignmentFromFilesOnDiskPage.updateGanttAssignment")}:</Header>
                <Input type="number" onChange={(e, data) => this.props.r.setInReduxState({ inputUpdateGanttAssignment: data.value })} value={this.props.s.inputUpdateGanttAssignment} />
                <Button positive className="less-margin-top-bottom" onClick={async () => this.readFilesAndCreateAssignment()} >{_msg("GanttAssignmentFromFilesOnDiskPage.button." + (this.props.s.inputUpdateGanttAssignment === "" ? "create" : "update"))}</Button>
            </Segment>
        </div>;
    }
}

export const GanttAssignmentFromFilesOnDiskPageHOC = ReduxReusableComponents.connectRRC(GanttAssignmentFromFilesOnDiskPageState, GanttAssignmentFromFilesOnDiskPageReducers, GanttAssignmentFromFilesOnDiskPage);

export const ganttAssignmentFromFilesOnDiskPageUrl = "/AssignmentFromFilesOnDisk";
export const ganttAssignmentFromFilesOnDiskPageRoute = (computeRoute: (props: PrivateRouteProps) => JSX.Element) =>
    <PrivateRoute key="assignmentFromFilesOnDiskPage"
        path={ganttAssignmentFromFilesOnDiskPageUrl}
        render={(props) => <GanttAssignmentFromFilesOnDiskPageHOC {...props} id="assignmentFromFilesOnDiskPage" />}
        computeRoute={computeRoute} />

export const ganttAssignmentFromFilesOnDiskPageMenuEntry = () => {
    return {
        id: "assignmentFromFilesOnDiskPage",
        content: _msg("GanttAssignmentFromFilesOnDiskPage.title"),
        to: ganttAssignmentFromFilesOnDiskPageUrl, exact: true, icon: "chart bar"
    }
};

function setGanttAssignmentFromFilesOnDiskInputToLocalStorage(inputCsvFiles: string, inputUpdateGanttAssignment: string) {
    window.localStorage.setItem(GANTT_ASSIGNMENT_FROM_FILES_ON_DISK_INPUT, JSON.stringify({ inputCsvFiles, inputUpdateGanttAssignment }));
}

function getGanttAssignmentFromFilesOnDiskInputToLocalStorage(): { inputCsvFiles: string, inputUpdateGanttAssignment: string } {
    const item = window.localStorage.getItem(GANTT_ASSIGNMENT_FROM_FILES_ON_DISK_INPUT);
    if (item) {
        try {
            return JSON.parse(item);
        } catch {}
    }
    return { inputCsvFiles: "", inputUpdateGanttAssignment: "" };
}
