import { EntityDescriptor, EntityTablePage, EntityTablePageProps } from "@crispico/foundation-react";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import React from "react";
import { GanttResourcesRRC } from "./gantt/GanttResources";

export class HumanResourceEntityDescriptor extends EntityDescriptor {
    protected customize() {       
        this.isInDefaultColumnConfig(true, "unit", "organization", "identifier", "firstName", "lastName", "rfidIdentifier", "available", "vehicle", "lastEquipmentResourceAssignmentViaRfid", "pdaIdentifier")
        // .addFieldDescriptor(new EntityToTagFieldDescriptor())
        .addTabDescriptor({ oneToManyEntityName: "Qualification", oneToManyOppositeField: "humanResource" })
        .addTabDescriptor({ oneToManyEntityName: "EquipmentUsageLog", oneToManyOppositeField: "driver" })
        
        this.infoTable.wrappedComponentClass = class extends EntityTablePage<EntityTablePageProps> {

            protected getExtraTabPanes(): (TabRouterPane | null)[] {
                const that = this;
                let extraTabPanes = [...super.getExtraTabPanes(), 
                    {
                        routeProps: { path: "/ganttResources" },
                        menuItemProps: { content: _msg("GanttResources.title") },
                        render: () => <GanttResourcesRRC id="ganttResources" />
                    }
                ];
                return extraTabPanes;
            }
        }
    }
}