import { Optional } from "../CompMeta";
import { FieldType } from "./FieldType";

export interface CrudSettings {
    forEntities: ForEntity[];
    physicalQuantities: PhysicalQuantity[]
}

export interface ForEntity {
    entityName: string,
    fieldDescriptorSettings: FieldDescriptorSettings[],
    defaultColumnConfig: string,
    defaultCustomQuery: string,
    externalLink: string
}

export interface FieldDescriptorSettings {
    fieldRef: string,
    icon: Optional<string>;
    physicalQuantity: Optional<string>,
    measurementUnit: Optional<string>,
    inHeaderOrderIndex: Optional<number>,
    numberOfDecimals: Optional<number>,
    fieldIntervals: Optional<FieldInterval[]>
}

export interface FieldInterval {
    from: string,
    to: Optional<string>,
    enumOrderIndex: Optional<number>,
    label: Optional<string>,
    color: Optional<string>,
    applyColorTo: Optional<FieldIntervalColoring>,
}

export enum FieldIntervalColoring {
    TEXT = "TEXT",
    BULLET = "BULLET",
    BACKGROUND = "BACKGROUND"
}

export interface PhysicalQuantity {
    name: string,
    defaultMeasurementUnitSymbol: string,
    measurementUnits: MeasurementUnit[],
}

export interface MeasurementUnit {
    name: string,
    symbol: string,
    scaleFactorTowardsDefaultMeasurementUnit: Optional<number>,
    translationFactorTowardsDefaultMeasurementUnit: Optional<number>,
}