import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, Optional, TestUtils, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { EnrichProps, ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { loadFlightsForGantt, loadFlightsForGanttVariables, loadFlightsForGantt_flightService_findByFilter_results, loadFlightsForGantt_flightService_findByFilter_results_tasks } from "apollo-gen/loadFlightsForGantt";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Icon, Popup, SemanticICONS } from "semantic-ui-react";
import { AbstractGantt, AbstractGanttReducers, AbstractGanttState, GanttData, GanttGroup, GanttItem, GanttLayer, CellRenderer } from "./AbstractGantt";
import { LOAD_FLIGHTS_FOR_GANTT } from "./queries";
import { ApuOffStatus } from "apollo-gen/globalTypes";

const FIXED_FLIGHT_SEGMENT_WIDTH: number = 10; // value from flex, should be configurable

type FlightArray = { [key: number]: loadFlightsForGantt_flightService_findByFilter_results };

class GanttTasksState extends AbstractGanttState {
    flights: FlightArray = {};
}
class GanttTasksReducers<S extends GanttTasksState = GanttTasksState> extends AbstractGanttReducers<S> {

}

type GanttTasksProps = RRCProps<GanttTasksState, GanttTasksReducers> & {showDatePicker?: boolean };

export class GanttTasks extends AbstractGantt {

    props!: EnrichProps<AbstractGantt, GanttTasksState, GanttTasksReducers, {}>;

    constructor(props: GanttTasksProps) {
        super(props);
    }

    protected async requestFlights(filter: Filter) {
        return (await apolloClient.query<loadFlightsForGantt, loadFlightsForGanttVariables>({
            query: LOAD_FLIGHTS_FOR_GANTT, variables: FindByFilterParams.create().filter(filter)
        })).data.flightService_findByFilter?.results;
    }

    protected async loadFlights() { 
        let filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [
            Filter.create("showFlightInGantt", FilterOperators.forBoolean.equals, "true"),
            Filter.create("date", FilterOperators.forDate.greaterThan, this.props.s.start.toString()),
            Filter.create("date", FilterOperators.forDate.lessThan, this.props.s.end.toString())
        ]);

        let result = await (this.requestFlights(filter));

        const flights: FlightArray = {};
        const overlapFlightIds: Number[] = [];
        if (result) {
            result.forEach(flight => {
                flights[flight.id] = flight;
                if (flight.overlapFlight !== null) {
                    overlapFlightIds.push(flight.overlapFlight);
                }
            });
        }

        if (overlapFlightIds.length > 0) {
            filter = Filter.create("id", FilterOperators.forNumber.in, overlapFlightIds.map(id => id.toString()).join(","))
            result = await (this.requestFlights(filter));

            if (result) {
                result.forEach(flight => flights[flight.id] = flight);
            }
        }
        
        await this.props.r.setInReduxState({ flights });
        this.processData();
    }

    protected getTaskItems(flight: any, index: number): GanttItem[] {
        let items: GanttItem[] = [];
        AbstractGantt.find("Task", "taskGroup.id", flight.id, this.props.entities).forEach((task: any) => {
            if (!task.startTime && !task.endTime) {
                return;
            }
            
            const hasMission: boolean = AbstractGantt.find("ObjectActionGroup", "object.id", task.id, this.props.entities).find((oag: any) => {                   
                return AbstractGantt.find("Mission2", "id", oag.mission.id, this.props.entities).find(mission => {
                    if (mission.humanResource) {
                        return !(this.props.hideResources?.["HumanResource"] && this.props.hideResources["HumanResource"]?.findIndex(id => id === mission.humanResource.id) !== -1);
                    }
                    if (mission.equipmentResource) {
                        return !(this.props.hideResources?.["EquipmentResource"] && this.props.hideResources["EquipmentResource"]?.findIndex(id => id === mission.equipmentResource.id) !== -1);
                    }
                   return false;
                }) != undefined;
            });                             
            const taskType = AbstractGantt.findOne("TaskType", "id", task.taskType?.id, this.props.entities);
            items.push({
                row: index,
                start: moment(task.startTime).valueOf(),
                end: moment(task.endTime).valueOf(),
                key: Number(flight.id),
                data: { borderColor: hasMission ? "green": "red", backgroundColor: taskType?.color, tooltip: (flight?.airline || "") + (flight?.number || "") + " " + (taskType?.name || "") }
            });
        });
        return items;
    }

    protected processData() {
        let flights = this.props.s.flights;
        if (this.props.entities) {
            const entities = this.props.entities;
            flights = entities["Flight"] ? _.cloneDeep(entities["Flight"]) : {};
        }
        const data: GanttData = { layers: [], items: [], groups: [] };

        if (Object.keys(flights).length === 0) {
            data.groups.push({ id: 0, data: {} });
        }

        const sortedFlights = Object.keys(flights).map(key => Number.parseFloat(key)).filter(key => flights[key].showFlightInGantt).map(key => {
            const flight = flights[key];

            let startDate: number = moment(flight.date).valueOf(); 
            let endDate: number = startDate;

            if (flight.overlapFlight) {
                if (flights[flight.overlapFlight]) {
                    if (flight.departure) {
                        startDate = moment(flights[flight.overlapFlight].date).valueOf();
                    } else {
                        endDate = moment(flights[flight.overlapFlight].date).valueOf();
                    }
                } else {
                    endDate = moment(startDate).add(2, 'minute').valueOf();
                }
            } else {
                if (flight.departure) {
                    startDate = moment(startDate).add(-FIXED_FLIGHT_SEGMENT_WIDTH, 'minute').valueOf();
                } else {
                    endDate = moment(endDate).add(FIXED_FLIGHT_SEGMENT_WIDTH, 'minute').valueOf();;
                }
            }

            return {flight, startDate, endDate}
        }).sort((a, b) => a.startDate > b.startDate ? 1 : -1);

        let groupIndex = 0;
        sortedFlights.forEach((x) => {            
            const overlapFlight = flights[x.flight.overlapFlight];
            const arvFlight = x.flight.departure ? overlapFlight : x.flight;
            const depFlight = x.flight.departure ? x.flight : overlapFlight;
            
            // filter flights
            // if arv - dep already added => don't add it again
            if (data.groups.find(group => group.data.arvFlightId === arvFlight?.id && group.data.depFlightId === depFlight?.id)) {
                return;
            }
            const i = groupIndex++;
            data.groups.push({ id: i, data: { arvFlightId: arvFlight?.id, depFlightId: depFlight?.id, arvFlightName: (arvFlight?.airline || "") + (arvFlight?.number || ""), depFlightName: (depFlight?.airline || "") + (depFlight?.number || "") } });

            let itemFlight: GanttLayer = {
                rowNumber: i,
                start: x.startDate,
                end: x.endDate,
                style: { backgroundColor: x.flight.apuOffStatus === ApuOffStatus.DONE ? 'green' : ( x.flight.apuOffStatus === ApuOffStatus.IN_PROGRESS ? 'lightgreen' : 'darkgrey') }
            };
            if (arvFlight?.showFlightInGantt) {
                data.items.push.apply(data.items, this.getTaskItems(arvFlight, i));
            }
            if (depFlight?.showFlightInGantt) {
                data.items.push.apply(data.items, this.getTaskItems(depFlight, i));
            }           
            data.layers.push(itemFlight);
        });

        this.props.r.setInReduxState({ data });
    }

    protected entitiesChangedHandler() {
        this.processData();
    }

    protected startEndChangedHandler() {
        this.loadFlights();
    }
    
    protected ganttItemRenderer(object: { item: GanttItem }): React.ReactNode {
        const borderColor = object.item.data?.borderColor;
        const backgroundColor = object.item.data?.backgroundColor != null ? Utils.convertColorToHex(object.item.data?.backgroundColor) : "lightblue";
        const trigger = <span className='rct9k-items-inner no-padding-margin' style={{ height: "13px", backgroundColor, borderStyle: borderColor ? "solid" : undefined, borderWidth: borderColor ? "2px" : undefined, borderColor }}></span>;
        if (!object.item.data?.tooltip) {
            return trigger;
        }
        const content = <div className="flex-container-row flex-center no-wrap-no-overflow-ellipsis">{object.item.data?.tooltip}</div>;
        return <Popup hoverable={false} position="top center" size="small" trigger={trigger} content={content} />;
    }

    protected getTableColumns(): any {
        return [
            { headerRenderer: <HeaderRenderer iconName="arrow down" rotation={-45} />, width: 100, cellRenderer: (group: any) => <CellRenderer group={group.group} columnName="arvFlightName" /> }, 
            { headerRenderer: <HeaderRenderer iconName="arrow up" rotation={45} />, width: 100, cellRenderer: (group: any) => <CellRenderer group={group.group} columnName="depFlightName" /> }
        ]
    }
}
class HeaderRenderer extends React.Component<{ iconName: SemanticICONS, rotation?: number }> {
    render() {
        return <div className="wh100 flex-center flex-justify-content-center">
            <Icon name={this.props.iconName} style={{ transform: "rotate(" + (this.props.rotation ? this.props.rotation : 0) + "deg)" }} />
        </div>;
    }
}

export const GanttTasksRRC = ReduxReusableComponents.connectRRC(GanttTasksState, GanttTasksReducers, GanttTasks);