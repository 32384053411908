require("../../xops-mobile-lib/lib/bootstrap/dist/css/bootstrap.css");
require("../../xops-mobile-lib/lib/bootstrap/dist/css/bootstrap-theme.css");
require("../../xops-mobile-lib/lib/customcss/custom.css");

require('../../xops-mobile-lib/lib/basejs/css/base.css');
require('../../xops-mobile-lib/lib/bootstrap-select.css');
require('../../xops-mobile-lib/lib/jquery-ui/jquery-ui-1.10.4.custom.css');
require('../../xops-mobile-lib/lib/jquery-ui/bootstrap-datetimepicker.css');

require(["backbone",
	"jquery",
	"com/crispico/xops/Application",
	"basejs/app/AppUtils",
	"com/crispico/xops/mission2/views/Mission2View",
	"com/crispico/xops/mission2/views/LoginForUserView",
	"com/crispico/xops/mission2/views/LoginForDriverView",
	"com/crispico/xops/mission2/views/SettingsView",
	"com/crispico/xops/AppRouter",
	"basejs/app/models/BaseModel",
	"com/crispico/xops/AppInit"],
	function (Backbone,
		$,
		Application,
		AppUtils,
		Mission2View,
		LoginForUserView,
		LoginForDriverView,
		SettingsView,
		AppRouter,
		BaseModel,
		AppInit) {

		var app = {
			initialize: function() {    
				console.log("XopsMobileApp, start initialize!"); 
				window.showSettingsView = $.proxy(this.showSettingsView, this);
				window.showLoginForDriverView = $.proxy(this.showLoginForDriverView, this);
				window.loadDataOKCallback = $.proxy(this.loadDataOKCallback, this);

				window.App = window.App || new AppUtils({
					globalAlertElement: '#alert',
					h401Handler: function () {
						window.XopsLocalStorage.removeUser();
						window.xopsReact ? window.xopsReactLogin(Backbone.history.fragment) : Backbone.history.navigate(window.computeRoute(""), { trigger: true });
					},
					blockUIOnAjaxRequest: true,
					autoClose: false,
					CSRFProtection: false,
				});

				var appInit = new AppInit;
				appInit.initApp();

				this.loadLogger();    
				App.translationMessage = XopsGlobals.getInstance().messages;

				var $screenBlocker = $("#screenBlockerDiv");
				$screenBlocker.hide();
				
				if (window.isMobileApp) {	
					console.log("XopsMobileApp, start loadConfigProperties!"); 	
					this.loadConfigProperties(function() { 
						console.log("XopsMobileApp, start loadConfigProperties callback!"); 	   	
						window.XopsUtils.log("Stored URL: " + XopsGlobals.getInstance().getServerURL());
						if (XopsGlobals.getInstance().getServerURL() && XopsGlobals.getInstance().getServerURL() != "") {    				
								appInit.loadData(loadDataOKCallback, (window.XopsLocalStorage.getTemplates() != null ? window.showLoginForDriverView : window.showSettingsView));        						
						} else {    	    	
							window.showSettingsView(appInit);  			
						}
					});
				} else  {
					appInit.loadData(function () {
						if (!Backbone.History.started) {
							new AppRouter();
							Backbone.history.start({ silent: true });
							window.xopsReactLogin(Backbone.history.fragment);				
						}
					});
				}	
				
				console.log("XopsMobileApp, end initialize!"); 
			},

			showLoginForDriverView: function() {
				window.XopsUtils.log("showLoginForDriverView: CALL");
				if (!Backbone.History.started) {	
					new AppRouter();
					Backbone.history.start({ silent: true });
					Backbone.history.navigate(window.computeRoute(""), {trigger: true})
				} else {  		   		
					window.xopsReact ? window.xopsReactLogin(Backbone.history.fragment) : Backbone.history.navigate(window.computeRoute(""), {trigger: true});	
				}
			},

			loadDataOKCallback: function(appInit) {
				window.XopsUtils.log("loadDataOKCallback: CALL");
				if (window.xopsReact) {
					if (!Backbone.History.started) {	
						new AppRouter();						
						Backbone.history.start({ silent: true });
						window.xopsReactLoadInitializationsForClient(XopsGlobals.getInstance().getServerURL());
					} else {  		   		
						window.xopsReact ? window.xopsReactLogin(Backbone.history.fragment) : Backbone.history.navigate(window.computeRoute(""), {trigger: true});	
					}				
				} else {
					if (!Backbone.History.started) {	
						new AppRouter();
						Backbone.history.start();
					} else {  		   		
						window.xopsReact ? window.xopsReactLogin(Backbone.history.fragment) : Backbone.history.navigate(window.computeRoute(""), {trigger: true});	
					}				
				}
			},

			showSettingsView: function(appInit) {
				if (!appInit) {
					appInit = new AppInit;
				}	
				window.XopsUtils.log("showSettingsView: CALL");
				this.$content = $("#content");        			
				var settingsView = new SettingsView(
						{el: this.$content}, 
						function() {												
							appInit.loadData(loadDataOKCallback, (window.XopsLocalStorage.getTemplates() != null ? window.showLoginForDriverView : window.showSettingsView));
						}, 
						true);    			
				settingsView.render();
			},

			// force cordova js part to read/process any events left in queue
			// (from java part, or not yet processed since they come when js was processing other things)
			forceCordovaUpdate: function () {
				if (!this.fileSystemWorked || !this.getFileWorked || !this.fileWorked || !this.onloadendWorked) {
					var event = new Event("online");
					window.dispatchEvent(event);
				}
			},

			loadConfigProperties: function (callback) {
				if (!window.isWindowsApp) {
					var appDir = cordova.file.externalDataDirectory;
					if (appDir == null) {
						appDir = cordova.file.dataDirectory;
					}
					if (appDir == null) {
						appDir = cordova.file.externalRootDirectory;
					}
					var that = this;
					// apparently on Android 7 we have some problems when calling
					// resolveLocalFileSystemURL as the first time crashes and does not
					// call our fail/error callback
					// This will force the cordova js part to check if there are events
					// not read/processed from java part.
					setTimeout(function () {
						that.forceCordovaUpdate();
					}, 250);
					setTimeout(function () {
						that.forceCordovaUpdate();
					}, 500);
					setTimeout(function () {
						that.forceCordovaUpdate();
					}, 750);
					setTimeout(function () {
						that.forceCordovaUpdate();
					}, 1000);

					window.resolveLocalFileSystemURL(appDir.toString(), function (dir) {
						that.fileSystemWorked = true;
						dir.getFile("config.js", { create: true }, function (configFile) {
							that.getFileWorked = true;
							if (configFile) {
								configFile.file(function (file) {
									that.fileWorked = true;
									var reader = new FileReader();
									reader.onloadend = function (e) {
										try {
											that.onloadendWorked = true;
											XopsGlobals.getInstance().configProperties = JSON.parse(e.target.result);
											if (XopsGlobals.getInstance().configProperties) {
												window.XopsUtils.log("Trying to get data from config.js properties file");

												// set URL from profile
												var configProfile = XopsGlobals.getInstance().configProperties.profile;
												if (configProfile && XopsPredefinedProfile[configProfile]) {
													window.XopsUtils.log("Profile found " + XopsPredefinedProfile[configProfile]["APP_PATH"]);
													XopsGlobals.getInstance().setServerURL(XopsPredefinedProfile[configProfile]["APP_PATH"]);
												}

												// set device identifier
												var identifier = XopsGlobals.getInstance().configProperties.device;
												if (identifier) {
													window.XopsUtils.log("Identifier found " + identifier);
													XopsGlobals.getInstance().setIdentifier(identifier);
												}

												// set number of inactivity minutes before deconnexion
												var minutesBeforeDeconnexion = XopsGlobals.getInstance().configProperties.minutesBeforeDeconnexion;
												if (minutesBeforeDeconnexion) {
													window.XopsUtils.log("MinutesBeforeDeconnexion found " + minutesBeforeDeconnexion);
													XopsGlobals.getInstance().setMinutesBeforeDeconnexion(minutesBeforeDeconnexion);
												}

												// set activate vibration
												var activateVibration = XopsGlobals.getInstance().configProperties.activateVibration;
												if (activateVibration) {
													window.XopsUtils.log("ActivateVibration found " + activateVibration);
													XopsGlobals.getInstance().setActivateVibration(activateVibration);
												}
											}
										} catch (error) {
											XopsGlobals.getInstance().configProperties = null;
											window.XopsUtils.log("Failed to load & parse config.js! Code " + (error.target != undefined ? error.target.error.code : "unknown"));
										} finally {
											callback();
										}
									};
									reader.onerror = function (e) {
										window.XopsUtils.log("File 'config.js' could not be read! Code " + (e.target != undefined ? e.target.error.code : "unknown"));
										callback();
									};
									reader.readAsText(file);
								}, function (e) {
									window.XopsUtils.log("configFile.file: Failed to find config.js; Code " + (e.target != undefined ? e.target.error.code : "unknown"));
									callback();
								});
							} else {
								window.XopsUtils.log("Failed to find config.js");
								callback();
							}
						}, function (e) {
							window.XopsUtils.log("dir.getFile: Failed to find config.js Code " + (e.target != undefined ? e.target.error.code : "unknown"));
							callback();
						});
					}, function (e) {
						window.XopsUtils.log("window.resolveLocalFileSystemURL: Failed to find config.js Code " + (e.target != undefined ? e.target.error.code : "unknown"));
						callback();
					});

				} else {
					callback();
				}
			},

			loadLogger: function () {
				if (!window.isMobileApp) {
					return;
				}
				if (!window.isWindowsApp) {
					var appDir = cordova.file.externalDataDirectory;
					if (appDir == null) {
						appDir = cordova.file.dataDirectory;
					}
					if (appDir == null) {
						appDir = cordova.file.externalRootDirectory;
					}					   	
					
					window.resolveLocalFileSystemURL(appDir.toString(), function (dir) {
						dir.getFile("log_" + (new Date()).toString('dd_MM_yyyy') + ".txt", { create: true }, function (file) {
							window.XopsUtils.logger = file;
						});
					});

					setInterval(function () {
						if (window.XopsUtils.logStack.length > 0) { // if we have something to write
							window.XopsUtils.logger.createWriter(function (fileWriter) {	 // open file	 
								if (window.XopsUtils.logStack.length > 0) { // double check
									fileWriter.seek(fileWriter.length);	// go to end				
									fileWriter.write(window.XopsUtils.logStack[0]); // write
									window.XopsUtils.logStack.splice(0, 1);	// remove from stack
								}
							});
						}
					}, 2 * 1000);
				} else {
					var pathname = decodeURI(window.location.pathname);
					var directory = pathname.substr(1, pathname.lastIndexOf("/")) + "logs";
					var file = directory + "/log_" + (new Date()).toString('dd_MM_yyyy') + "bis.txt";
					window.XopsUtils.logger = file;
					appshell.fs.makedir(directory, parseInt("0777", 8), function (err) {
					});
					setInterval(function () {
						if (window.XopsUtils.logStack.length > 0) {
							if (window.XopsUtils.logStack[0] == undefined) {
								window.XopsUtils.logStack.splice(0, 1);
								return;
							}
							appshell.fs.readFile(file, "utf8", function (_err, _data) {
								appshell.fs.writeFile(file, _data + window.XopsUtils.logStack[0], "utf8", function (err) {
									if (err) {
									}
								});
							});
							window.XopsUtils.logStack.splice(0, 1);
						}
					}, 5 * 1000);
				}
			}
		}
		return app.initialize();
		

	});


