import React from "react";
import { Message, Divider, Button } from "semantic-ui-react";
//@ts-ignore
import StoryRouter from 'storybook-react-router';
import { TestUtils } from "../../utils/TestUtils";
import { EntityDescriptor } from '../EntityDescriptor';
import { EntityTablePage, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension } from '../EntityTablePage';
import { testState1 } from "../EntityTablePage.testStates";
import { FieldType } from '../FieldType';
import { createSliceFoundation, PropsFrom, getBaseReducers, StateFrom, getBaseImpures } from "@crispico/foundation-react/reduxHelpers";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";

export default {
    title: 'Entity CRUD/TablePage/Customized',
    decorators: [StoryRouter({}, { initialEntries: ['/EmployeeTable'] })],
    excludeStories: ["createDemoDescriptor", "sliceMyConnectedComponent1", "MyConnectedComponent1"]
};

// we only create the descriptor for local use; we don't add it in the registry; that's why we have this function;
// in the real world we'd use the commented line

// export const employeeEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
export function createDemoDescriptor() {
    return new EntityDescriptor({ name: "Employee", icon: "male", miniFields: ["name"] })
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "firstName", type: FieldType.string })
        .addFieldDescriptor({ name: "name", type: FieldType.string })
        .addFieldDescriptor({ name: "birthDate", type: FieldType.date })
        .addFieldDescriptor({ name: "stillEmployed", type: FieldType.boolean })
        .addFieldDescriptor({ name: "color", type: FieldType.color })
        .addFieldDescriptor({ name: "jobs", type: FieldType.oneToMany("Job") })
        // these 2 were added to show different field types; no correspondence on server side
        // TODO: maybe it will be better to split stories in 2: one where we have an entity with all available field types and another for employee
        .addFieldDescriptor({ name: "entityType", type: FieldType.entityName })
        .addFieldDescriptor({ name: "comment", type: FieldType.text })
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// #1: let's extend only the component; no nestedSlices/connected subcomponents
const customized1 = createDemoDescriptor();

// however we usually need to extend the class as well, because using props in not enough; like in this case
customized1.infoTable.wrappedComponentClass = class extends EntityTablePage {

    // override some logic
    renderPageHeader() {
        return (<>
            {super.renderPageHeader()}
            <Message color="blue">I'm some custom content in header</Message>
        </>);
    }

    protected getExtraTabPanes() {
        // we add a new tab after the main tab
        // take a look at the TabbedPage storybook; it shows how to play w/ order; e.g. to insert the tab before the main tab
        return [{
            routeProps: { path: "/myTab" }, menuItemProps: { icon: "chart pie", content: "My tab" },
            render: () => <><Message color="orange">I'm some tab content and something from state: {this.props.tableSimple.entities[0].firstName} (e.g. fields from the entity)</Message></>
        }];
    }
};

export const Customized1 = () => TestUtils.wrapWithStoreForInfo(customized1.infoTable, testState1, { wrapInRouter: false });

///////////////////////////////////////////////////////////////////////////////////////////////////
// #2: this time we are adding a connected component as tab

// the "nested" component, that I'll be putting in a new tab
const sliceMyConnectedComponent1 = createSliceFoundation(class SliceMyConnectedComponent1 {
    initialState = { counter1: 10 }

    reducers = {
        incrementCounter1(state: StateFrom<SliceMyConnectedComponent1>) { state.counter1 = state.counter1 + 1; }
    }
});

function MyConnectedComponent1(props: PropsFrom<typeof sliceMyConnectedComponent1>) {
    return (<Message color="blue">
        <b>MyConnectedComponent1</b>: counter1 = {props.counter1} <Button onClick={() => props.dispatchers.incrementCounter1()} >incrementCounter1 (reducer)</Button>
    </Message>);
}

// and now the customized slice + component for the table
const customized2 = createDemoDescriptor();

const sliceEntityTablePageEmployee2 = customized2.infoTable.slice = createSliceFoundation(class extends SliceEntityTablePage {

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
        comp: sliceMyConnectedComponent1
    }
}).setEntityDescriptor(customized2);

customized2.infoTable.wrappedComponentClass = class extends EntityTablePage<PropsFrom<typeof sliceEntityTablePageEmployee2>> {

    protected getExtraTabPanes() {
        return [{
            routeProps: { path: "/myTab" }, menuItemProps: { icon: "chart pie", content: "My tab" },
            render: () => <MyConnectedComponent1 {...this.props.comp} dispatchers={this.props.dispatchers.comp} />
        }];
    }
};

export const Customized2 = () => TestUtils.wrapWithStoreForInfo(customized2.infoTable, testState1, { wrapInRouter: false });

///////////////////////////////////////////////////////////////////////////////////////////////////
// #3: this example modifies/extends more things from the original slice; the previous example didn't
// actually touch the original logic; it simply added/inserted a connected component

const customized3 = createDemoDescriptor();

const sliceEntityTablePageEmployee3 = customized3.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
        comp: sliceMyConnectedComponent1
    }

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
        // I have additional state, so I have "initialState" block
        someValue: 0
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),

        // I have a new reducer, so I have "reducers" block as well
        increment(state: StateFrom<Ext>) {
            state.someValue++;
        },
    }

    // and here I'm overriding some existing impure functions; hence I need this block
    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        // overriding w/o calling super
        adjustFilterBeforeLoad(filter: Filter) {
            return Filter.createComposed(FilterOperators.forComposedFilter.and, [filter, Filter.create("user", FilterOperators.forString.equals, "current user")]) // NOTE: the filter tooling/API will evolve; at time of writing filters are work in progress
        },

        // overriding and calling super
        // to be able to access "super", we need this trick; i.e. copy the old function with a new name
        // Update CZ: this function doesn't exist anymore...it should be replaced with other example
        // loadCustomQueriesSuper: sliceEntityTablePageOnlyForExtension.impures.loadCustomQueries,
        // async loadCustomQueries(screen: string) {
        //     // do stuff before
        //     setInterval(() => this.getDispatchers().increment(), 1000);

        //     const result = await this.loadCustomQueriesSuper(screen);
        //     // do stuff after data has arrived

        //     return result;
        // }
    }
}).setEntityDescriptor(customized3);

customized3.infoTable.wrappedComponentClass = class extends EntityTablePage<PropsFrom<typeof sliceEntityTablePageEmployee3>> {

    renderPageHeader() {
        return (<>
            {/* this time I'm not creating a new tab; I insert the component here */}
            <MyConnectedComponent1 {...this.props.comp} dispatchers={this.props.dispatchers.comp} />
            <Divider />
            {/* and here is logic implemented ad-hoc; not coming from an already existing component */}
            someValue = {this.props.someValue}
            <Divider />
            {super.renderPageHeader()}
        </>);

    };
}

export const Customized3 = () => TestUtils.wrapWithStoreForInfo(customized3.infoTable, testState1, { wrapInRouter: false });