import { Utils } from "@crispico/foundation-react";
import { PointSymbolProps } from "@nivo/line";
import React from "react";
import { NavLink } from "react-router-dom";
import SplitPane from "react-split-pane";
import { Button, Checkbox, Grid, Icon, Label, List, Segment } from "semantic-ui-react";
import { ResponsivePieExt, PieDatum } from "@crispico/foundation-react/components/nivoExt";
import { ChartCurrentSelection } from "@crispico/foundation-react/components/ChartCurrentSelection/ChartCurrentSelection";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import moment from "moment";
import { LineChart, LineChartRRC } from "@crispico/foundation-react/components/responsiveLineChart/LineChart";
import Measure from "react-measure";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import lodash from 'lodash';
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

export interface Serie {
    id: string,
    color: string,
    data: Point[]
}

export interface PieEntry {
    id: string;
    label: string;
    value: number;
    color: string
}

export interface Point {
    id: number;
    x: number;
    y: number;
    series?: number;
    total: any[];
    in?: any[];
    out?: any[];
    label?: string;
}

export class HistogramState extends State {
    pieData = [] as Array<PieDatum>;
    dataToShow = {} as { [key: string]: boolean };
    selectedDate = undefined as number | undefined;
    selectedHistogramSeries = undefined as number | undefined;
    selectedHistogramPoint = undefined as number | undefined;
    isPinned = false;
    previousClickedPoint = undefined as string | undefined;
}

export class HistogramReducers<S extends HistogramState = HistogramState> extends Reducers<S> {
    changeShownData(id: string, selectedId: string | undefined) {
        this.s.dataToShow[id] = !this.s.dataToShow[id];
        if (selectedId && selectedId === id) {
            this.s.isPinned = false;
            this.s.selectedHistogramPoint = undefined;
        }
    }
}

type Histogramprops = {
    data: Array<Serie>,
    startDate: number,
    endDate: number,
    countBy: string,
    groupedBy: string,
};

type Props = RRCProps<HistogramState, HistogramReducers> & Histogramprops;
type LocalState = { height: number };
export class Histogram extends React.Component<Props, LocalState> {

    oldMouseOverId?: string;

    protected lineChartRef = React.createRef<LineChart>();

    prepareData() {
        let dataToShow = {} as { [key: string]: boolean };
        this.props.data.forEach(value => dataToShow[value.id] = true);
        this.props.r.setInReduxState({ dataToShow });
    }

    constructor(props: Props) {
        super(props);
        this.state = { height: 0 };
    }

    changeShownData(id: string) {
        this.props.r.changeShownData(id, this.props.s.selectedHistogramSeries ? this.props.data[this.props.s.selectedHistogramSeries].id : undefined);
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    private componentDidUpdateInternal(prevProps?: Props) {
        const { props } = this;
        if (!prevProps || !lodash.isEqual(prevProps.data, props.data)) {
            this.prepareData();
        }
    }

    drawPieAndFooter(p: { pointX: number, pointY: number, pointIndex: number, polygonLabel: string }) {
        let pieData = [] as PieDatum[];
        for (let i = 0; i < this.props.data.length; i++) {
            let pieEntryValue: number = 0;

            if (this.props.data[i].id !== p.polygonLabel) {
                let previous = 0;
                for (let j = 0; j < this.props.data[i].data.length; j++) {
                    if (this.props.data[i].data[j].x > p.pointX) {
                        // no value found in this interval (e.g. selected 10:00 and all other points >10:00 => 11:00, 15:00)
                        if (j === 0) {
                            break;
                        }
                        pieEntryValue = this.props.data[i].data[previous].y
                        break;
                    } else {
                        previous = j
                    }
                    // arrived at the end of list and no value found, all points are too early
                    // e.g. selected point = 10:00 and others = 05:00 ,06:00, 07:00, the result will
                    // be the closest value found in the list (07:00)
                    if (j === this.props.data[i].data.length - 1 && previous === j) {
                        pieEntryValue = this.props.data[i].data[previous].y
                    }
                }
            } else {
                this.props.r.setInReduxState({ selectedHistogramSeries: i });
                pieEntryValue = p.pointY;
            }
            if (pieEntryValue !== 0) {
                const pieEntry: PieDatum = {
                    id: this.props.data[i].id,
                    label: this.props.data[i].id,
                    color: this.props.data[i].color,
                    value: pieEntryValue,
                }
                pieData.push(pieEntry);
            }
        }
        this.props.r.setInReduxState({ pieData });
        this.props.r.setInReduxState({ selectedHistogramPoint: p.pointIndex });
    }

    renderList() {
        return <List className="Histogram_legend">
            {this.props.data.map((data) => {
                return <List.Item key={data.id} className='flex-container-row flex-center'>
                    <Checkbox className="tiny-margin-right" checked={this.props.s.dataToShow[data.id]} onChange={() => this.changeShownData(data.id)} />
                    <span className="tiny-margin-right" style={{ flexGrow: 1, textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }}>{data.id}</span>
                    <Label circular style={{ color: 'white', backgroundColor: data.color, minWidth: "1em", minHeight: "1em" }} />
                </List.Item>
            })}
        </List>;
    }

    getPointSymbol = ({ size, color, borderWidth, borderColor, datum }: PointSymbolProps) => {
        const selectedPoint = this.getSelectedPoint();
        if (selectedPoint?.id === datum.id && selectedPoint?.series === datum.series) {
            return (<g data-cy="Chart.histogram.selectedPoint">
                <circle fill="#fff" r={size * 2} strokeWidth={borderWidth} stroke={borderColor} />
                <circle r={size * 2} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />
            </g>)
        } else {
            return <circle r={size / 2} fill={color} />
        }
    }

    getSelectedPoint() {
        const props = this.props;

        let point = null;
        if (props.s.selectedHistogramSeries !== undefined) { // test against undefined because can be 0
            point = props.data[props.s.selectedHistogramSeries!]?.data[props.s.selectedHistogramPoint!];
        }
        return point;
    }

    drawBlockingButton() {
        const props = this.props;
        let icon: any
        if (props.s.isPinned) {
            icon = <Icon name='pin' />
        } else {
            icon = <Icon name='pin' rotated="clockwise" />
        }
        return (<React.Fragment>
            <Button toggle active={props.s.isPinned} onClick={(e) => props.r.setInReduxState({ isPinned: !this.props.s.isPinned })}>
                {icon}{_msg("Histogram.point." + (props.s.isPinned ? 'pinned' : 'notPinned'))}</Button>
        </React.Fragment>)

    }

    convertTooltip = (aList: any[]) => {
        let aLink = [];
        if (aList) {
            for (let i = 0; i < aList.length; i++) {
                if (aList[i].label) {
                    aLink.push(<React.Fragment key={i + "v"}>{aList[i].label}</React.Fragment>);
                    if (i < aList.length - 1) {
                        aLink.push(<React.Fragment key={i + "v"}>, </React.Fragment>);
                    }
                } else {
                    const url = entityDescriptors[this.props.countBy].getEntityEditorUrl(aList[i].id);
                    aLink.push(<React.Fragment key={i}><NavLink to={url}>{entityDescriptors[this.props.countBy].toMiniString(aList[i])}</NavLink></React.Fragment>)
                    if (i < aList.length - 1) {
                        aLink.push(<React.Fragment key={i + "v"}>, </React.Fragment>)
                    }
                }
            }
        }
        return <React.Fragment>{aLink}</React.Fragment>
    }

    renderFooterInfo(point: Point) {
        const props = this.props;
        const colorValue = props.data[props.s.selectedHistogramSeries!].color

        let ins = null;
        let out = null;
        let total = null;

        if (point.total?.length) {
            total = <React.Fragment> <Label circular color='blue'>{point.total?.length}</Label>{this.convertTooltip(point.total)}<br /></React.Fragment>
        }
        if (point.in?.length) {
            ins = <React.Fragment> <Label circular color='green'>{point.in?.length}</Label>{this.convertTooltip(point.in)}<br /></React.Fragment>
        }
        if (point.out?.length) {
            out = <React.Fragment> <Label circular color='red'>{point.out?.length}</Label>{this.convertTooltip(point.out)}<br /></React.Fragment>
        }
        // added opacity=1 for removing opacity from text
        return (<Grid celled='internally'>
            <Grid.Row data-cy="Chart.histogram.rightTer">
                <Grid.Column width={4}>{entityDescriptors[props.groupedBy].getLabel()}</Grid.Column>
                <Grid.Column width={12} >
                    <Label style={{ color: colorValue }}><Icon name='flag' /><b>{props.data[props.s.selectedHistogramSeries!].id}</b></Label>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row data-cy="Chart.histogram.rightTotal">
                <Grid.Column width={4}>{_msg("Histogram.total")}</Grid.Column>
                <Grid.Column width={12}>{total}</Grid.Column>
            </Grid.Row>
            <Grid.Row data-cy="Chart.histogram.rightIns">
                <Grid.Column width={4}>{_msg("Histogram.enter")}</Grid.Column>
                <Grid.Column width={12}>{ins}</Grid.Column>
            </Grid.Row>
            <Grid.Row data-cy="Chart.histogram.rightOuts">
                <Grid.Column width={4}>{_msg("Histogram.exit")}</Grid.Column>
                <Grid.Column width={12}>{out}</Grid.Column>
            </Grid.Row>
        </Grid>);
    }

    render() {
        const props = this.props;
        const selectedPoint = this.getSelectedPoint();
        const dataToShowPie = this.props.s.pieData.filter(p => props.s.dataToShow[p.id]);
        const dataToShowHistogram = this.props.data.filter(h => this.props.s.dataToShow[h.id]);
        return <div className="flex-container flex-grow-shrink-no-overflow less-padding gap5">
            <SplitPaneExt split="horizontal" defaultSize="40%" style={{ position: "static", minHeight: "auto" }} primary="second" >
                <Segment className="wh100 overflow-hidden">
                    {this.props.data.length > 0 ? <><SplitPane split="vertical" defaultSize="15%" minSize={100} >
                        <div className="wh100 less-padding">{this.renderList()}</div>
                        <Measure bounds onResize={(contentRect) => this.setState({ height: contentRect.bounds?.height ? contentRect.bounds?.height : 0 })}>
                            {({ measureRef }) => {
                                return <div className="wh100 less-padding" ref={measureRef}>
                                    <LineChartRRC id="lineChart" ref={this.lineChartRef} data={dataToShowHistogram}
                                        hasZoomSlider={true}
                                        initialStartDate={this.props.startDate} initialEndDate={this.props.endDate}
                                        currentStartDate={this.props.startDate} currentEndDate={this.props.endDate}
                                        initialSeries={dataToShowHistogram} curve="stepAfter" parentHeight={this.state.height}
                                        pointSymbol={this.getPointSymbol} legendX={"# of " + entityDescriptors[this.props.countBy].getLabel(true)} legendY="time"
                                        onClick={e => {
                                            const index = parseInt(e.id.substring(e.id.indexOf('.') + 1));
                                            if (this.props.s.isPinned && this.props.s.previousClickedPoint === e.id) {
                                                this.props.r.setInReduxState({ isPinned: false });
                                                this.props.r.setInReduxState({ previousClickedPoint: undefined });
                                            } else {
                                                this.drawPieAndFooter({ pointX: (e.data.x as Date).getTime(), pointY: e.data.y as number, pointIndex: index, polygonLabel: e.serieId as string });
                                                this.props.r.setInReduxState({ isPinned: true });
                                            }
                                            if (this.props.s.isPinned) {
                                                this.props.r.setInReduxState({ previousClickedPoint: e.id });
                                            }
                                        }}
                                        onMouseMove={(e) => {
                                            if (this.props.s.isPinned || e.id === this.oldMouseOverId) {
                                                return;
                                            }
                                            this.oldMouseOverId = e.id;
                                            this.drawPieAndFooter({ pointX: (e.data.x as Date).getTime(), pointY: e.data.y as number, pointIndex: parseInt(e.id.substring(e.id.indexOf('.') + 1)), polygonLabel: e.serieId as string });
                                        }}
                                    />
                                </div>;
                            }}
                        </Measure>
                    </SplitPane></> : null}
                </Segment>
                <div className="Histogram_segmentPie" data-cy="Chart.histogram.paneDown">
                    <ChartCurrentSelection date={selectedPoint ? moment(selectedPoint.x).format(Utils.dateTimeFormat) : undefined} currentPointDataCyAttribute="Chart.histogram.date"
                        contentHeader={
                            <div data-cy="Chart.histogram.pinButton" style={{ float: "left" }}>{this.drawBlockingButton()} <i>{_msg("Histogram.point.info")}</i></div>
                        }
                        contentMain={
                            <Segment className="flex-container flex-grow less-padding less-margin-top-bottom">
                                <SplitPaneExt defaultSize="25%">
                                    <div className="Histogram_pieContainer">
                                        <ResponsivePieExt data={dataToShowPie} arcLabelsTextColor="white"
                                            layers={['arcs', 'arcLabels', 'arcLinkLabels']} colors={{ datum: 'data.color' }} animate={false}
                                            tooltip={({ datum: { id, value, color } }) => (
                                                <div className={'ResponsivePieChart_Tooltip'}>
                                                    <div style={{ backgroundColor: color, width: '12px', height: '12px', display: 'inline-block' }}></div> <strong>{id}</strong>: {value} {entityDescriptors[props.countBy].getLabel(true)}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="Histogram_segment Histogram_segmentWithScroll">
                                        {selectedPoint && this.renderFooterInfo(selectedPoint)}
                                    </div>
                                </SplitPaneExt>
                            </Segment>
                        }
                    />
                </div>
            </SplitPaneExt>
        </div>;

    }
}

export const HistogramRRC = ReduxReusableComponents.connectRRC(HistogramState, HistogramReducers, Histogram);