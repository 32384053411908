import { FilterOperators } from "@crispico/foundation-gwt-js";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import React from "react";
import { Link } from "react-router-dom";
import { Label } from "semantic-ui-react";
import { Filter } from "../CustomQuery/Filter";

export class SearchEntityLink extends React.Component<{searchString: string, entityName: string, fields: string[], onClick?: any}> {
    
    shareLinkLogic = new ShareLinkLogic();

    render() {
        const props = this.props;
        const ed = entityDescriptors[props.entityName];
        const filters: Filter[] = [];
        const fields = props.fields.map((field, index) => {
            const filter = Filter.create(field, FilterOperators.forString.contains, props.searchString);
            filter.enabled = true;
            filters.push(filter);
            return <span key={field}><Label>{ed.getField(field).getLabel()}</Label> {index + 1 < props.fields.length ? ", " : ""}</span>;
        })
        const filter = Filter.createComposed(FilterOperators.forComposedFilter.or, filters);
        filter.enabled = true;
        return <Link to={this.shareLinkLogic.createLink(false, ed, filter, [])} onClick={props.onClick} className="a item" style={{lineHeight: '30px'}}>
            <span key="search">{_msg("SearchEntityLink.phrase.search")} "{props.searchString}" {_msg("SearchEntityLink.phrase.in")} </span>
            <span key="in">{ed.getLabelWithIcon()} {_msg("SearchEntityLink.phrase.by")} </span>
            <span key="by">{fields}</span>
        </Link>;
    }
}



