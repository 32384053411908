import arrayMove from 'array-move';
import _ from 'lodash';
import React from 'react';
import Select, { components, MenuProps, OptionTypeBase, Props, Styles, ValueType } from 'react-select';
import { SortableContainer, SortableContainerProps, SortableElement, SortEnd } from 'react-sortable-hoc';

export interface DropdownOption { value: any, label: any, [key: string]: any }

const customStyles: Partial<Styles> = {
  multiValueRemove: (provided) => {
    return { ...provided, borderRadius: '0px', paddingTop: '2px', backgroundColor: '#dddddd' }
  },
  multiValueLabel: (provided) => {
    return { ...provided, fontSize: '14px', color: 'var(--textColor)', fontWeight: 700, padding: '3px 4px', cursor: 'grab' }
  },
  multiValue: (provided) => {
    return { ...provided, backgroundColor: 'var(--background)', border: '1px solid #c4c4c4', borderRadius: '3px', zIndex: 1000 }
  },
  singleValue: (provided) => {
    return { ...provided, color: 'var(--textColor)' }
  },
  control: base => ({
    /* color to match form */
    ...base,
    borderColor: 'rgba(34,36,38,.15)',

    '&:hover': {
      borderColor: 'rgba(34,36,38,.15)'
    }
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 1000,
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: 'var(--background)'
  })
}

export type DropdownExtProps = { selectProps?: Props<any> & SortableContainerProps, options: DropdownOption[], selected: DropdownOption[], onChange?: (selected: DropdownOption[]) => void, allowMultiple?: boolean, hideOptions?: boolean, appendDoneMenuEntry?: boolean, closeOnKeys?: string[] }
export type CustomMenuProps = { appendDoneMenuEntry?: boolean, onDoneMenuEntryClick?: () => void };

export class DropdownExt extends React.Component<DropdownExtProps, { selected: DropdownOption[], opened: boolean }> {

  public static defaultProps = {
    allowMultiple: true
  };

  DONE_OPTION = { value: "DONE_OPTION", label: "" } as DropdownOption;

  constructor(props: DropdownExtProps) {
    super(props)
    this.onSortEnd = this.onSortEnd.bind(this)
    this.onChange = this.onChange.bind(this)
    this.getSelectedValues = this.getSelectedValues.bind(this)
    this.state = { selected: props.selected, opened: false }
    this.DONE_OPTION = { value: "DONE_OPTION", label: _msg("DropdownExt.done") };
  }

  componentDidUpdate(prevProps: DropdownExtProps) {
    if (prevProps && (!_.isEqual(prevProps.options, this.props.options) || (prevProps.selected.length !== this.props.selected.length))) {
      this.setState({ selected: this.props.selected })
    }
  }

  protected onSortEnd = (sort: SortEnd) => {
    const selected = arrayMove(this.state.selected, sort.oldIndex, sort.newIndex)
    this.setState({ selected: selected })
    if (this.props.onChange) {
      this.props.onChange(selected)
    }
  }

  protected onChange = (selectedOptions: ValueType<OptionTypeBase>) => {
    const selected = (selectedOptions ? this.props.allowMultiple ? selectedOptions : [selectedOptions] : []) as DropdownOption[];
    if (selected.includes(this.DONE_OPTION)) {
      this.onDoneMenuEntryClick();
      return;
    }
    this.setState({ selected: selected })
    if (this.props.onChange) {
      this.props.onChange(selected)
    }
  }

  protected onDoneMenuEntryClick = () => {
    this.setState({ opened: false });
  }

  getSelectedValues() {
    return this.state.selected
  }

  render() {
    let components: any = { MultiValue: SortableMultiValue as any };
    const appendDoneMenuEntry = this.state.selected.length > 0 && this.props.appendDoneMenuEntry;
    const onDoneMenuEntryClick = this.onDoneMenuEntryClick;
    if (this.props.selectProps?.customMenu) {
      components = { ...components, Menu: this.props.selectProps?.customMenu({appendDoneMenuEntry: appendDoneMenuEntry, onDoneMenuEntryClick: onDoneMenuEntryClick}) };
    }
    if (this.props.hideOptions) {
      components = { ...components, Menu: () => (<></>), IndicatorsContainer: () => (<></>) };
    }
    return (
      <SortableSelect
        className="wh100"
        axis="xy"
        onSortEnd={this.onSortEnd}
        distance={4}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        isMulti={this.props.allowMultiple ? true : false}
        options={appendDoneMenuEntry ? [this.DONE_OPTION].concat(this.props.options) : this.props.options}
        value={this.state.selected}
        onChange={this.onChange}
        components={components}
        menuIsOpen={this.state.opened} menuPlacement={"auto"} menuPosition={"absolute"}
        onFocus={() => this.setState({ opened: true })}
        onMenuOpen={() => this.setState({ opened: true })}
        closeMenuOnSelect={false}
        onMenuClose={() => this.setState({ opened: false })}
        styles={customStyles}
        placeholder={_msg("DropdownExt.placeholder")}
        onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
          this.props.closeOnKeys?.forEach(key => {
            if (key === event.key) {
              this.setState({ opened: false });
            }
          });
        }}
        {...this.props.selectProps}
      />
    );
  }
}

const SortableMultiValue = SortableElement((props: any) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e: { preventDefault: () => void; stopPropagation: () => void; }) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableSelect = SortableContainer(Select);