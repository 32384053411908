import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { sliceColumnConfigEntityEditorPage, ColumnConfigEntityEditorPage } from "./ColumnConfigEntityEditorPage";
import { addAfterStartupRunnable } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import _ from "lodash";
import { ClientColumnConfig } from "./ClientColumnConfig";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";

export let columnConfigEntityDescriptor: EntityDescriptor;

class MyEntityDescriptor extends EntityDescriptor {
    createNewEntity() {
        const entity: ClientColumnConfig = {
            id: null,
            dirty: false,
            entityName: '',
            name: '',
            configObject: { columns: [] },
            fromCrudSettings: false,
            organization: null
        };
        return entity;
    }

    getGraphQlFieldsToRequest(fields: string[]) {
        const newFields = fields.filter(field => field !== "configObject");
        return super.getGraphQlFieldsToRequest(newFields) + " configJson";
    }
}

addAfterStartupRunnable(() => {
    columnConfigEntityDescriptor = addEntityDescriptor(new MyEntityDescriptor({
        name: "ColumnConfig",
        miniFields: ["name"],
        showInUI: true,
        javaIdType: "Long",
        graphQlIdType: "Long"
    })
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "entityName", type: FieldType.entityName })
        .addFieldDescriptor({ name: "name", type: FieldType.string })
        
        /**
         * This field doesn't exist on the server. We do some overrides in the ColumnConfigEntityEditorPage.
         * 
         * @see ClientColumnConfig
         */
        .addFieldDescriptor({ name: "configObject", type: FieldType.columnConfigEntityFields, isInDefaultColumnConfigForTable: false, fieldForEntityName: "entityName", clientOnly: true, optional: true })
    );

    columnConfigEntityDescriptor.infoEditor.slice = sliceColumnConfigEntityEditorPage;
    columnConfigEntityDescriptor.infoEditor.wrappedComponentClass = ColumnConfigEntityEditorPage;

    sliceColumnConfigEntityEditorPage.setEntityDescriptor(columnConfigEntityDescriptor);

});