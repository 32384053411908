import { EntityDescriptor, apolloClient, SliceEntityTablePage, Utils, apolloClientHolder, FieldDescriptor } from "@crispico/foundation-react";
import React from "react";
import { CUSTOM_FIELDS, FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Chart } from "apollo-gen/Chart";
import { POPULATE_HISTORY_REPORT } from "./queries";
import { populateHistoryReport } from "apollo-gen/populateHistoryReport";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Label, Segment, Icon, Button, Dimmer, Loader } from "semantic-ui-react";
import { Cell, Column, Table } from "fixed-data-table-2";
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { TRUE_VALUES } from "@crispico/foundation-react/pages/Audit/AuditUtils";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import lodash from "lodash";
import gql from "graphql-tag";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { CrudGlobalSettings } from "@crispico/foundation-react/entity_crud/CrudGlobalSettings";
import Measure from "react-measure";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

const refreshRateMillis: number = 2 * 1000;
const FIELDS_HISTORY_REPORT_TYPE = "fieldsHistory";
const noChangeString = ` [${_msg("FieldHistoryReport.noChange")}]`;

export const fieldsHistoryConfigDescriptor = new EntityDescriptor({name: "FieldsHistoryReport"}, false)
    .addFieldDescriptor({ name: "date", type: FieldType.date, propsForEditor: { hasTime: true} })
    .addFieldDescriptor({ name: "entityName", type: FieldType.entityName })
    .addFieldDescriptor({ name: "filter", type: FieldType.filter, fieldForEntityName: "entityName" })
    .addFieldDescriptor({ name: "entityFields", type: FieldType.entityFields, fieldForEntityName: "entityName" })

export const fieldHistoryConfigDescriptor = new EntityDescriptor({name: "FieldHistoryReport"}, false)
    .addFieldDescriptor({ name: "date", type: FieldType.rangeDate, propsForEditor: { hasTime: true} })
    .addFieldDescriptor({ name: "entityName", type: FieldType.entityName })
    .addFieldDescriptor({ name: "filter", type: FieldType.filter, fieldForEntityName: "entityName" })
    .addFieldDescriptor({ name: "entityFields", type: FieldType.entityFields, fieldForEntityName: "entityName", allowMultiple: false })

interface Config {
    date: string;
    entityName: string;
    filter: Filter;
    entityFields: string;
}

export class FieldsHistoryTabState extends State {
    rows = [[]] as [string[]];
    noChangeRows = [[]] as [boolean[]];
    entityMiniStrings = [] as any[];
    percentage = 0;
}

export class FieldsHistoryTabReducers<S extends FieldsHistoryTabState = FieldsHistoryTabState> extends Reducers<S>  {}

type Props = RRCProps<FieldsHistoryTabState, FieldsHistoryTabReducers> & { config: Config, entity: Chart };
type LocalState = { measuredWidth: number | undefined, measuredHeight: number | undefined }
export class FieldsHistoryTab extends React.Component<Props, LocalState> {
    private timer: number | undefined = undefined;

    async prepareData(entity: Chart, config: Config, savedData: string) {
        const result: {[key: string]: any} = JSON.parse(savedData)
        let rows: [string[]];
        let noChangeRows: [boolean[]] = [[]];

        if (entity.type === FIELDS_HISTORY_REPORT_TYPE) {
            rows = [[config.entityName].concat(config.entityFields.split(","))];
            Object.keys(result).forEach(key => rows.push([key].concat(result[key])));
        } else {
            const date: string[] = config.date.split(",");
            const from = moment(date[0]).milliseconds(0);
            const to = moment(date[1]).milliseconds(0);
            let dataIntervals = Array.from(new Set(Object.keys(result).flatMap(id => Object.keys(result[id])))).sort();

            let beforeFrom: string | null = null;
            if (dataIntervals && dataIntervals.length > 0 && from.isAfter(moment(dataIntervals[0]))) {
                beforeFrom = dataIntervals.splice(0, 1)[0];
            }

            if (dataIntervals.length === 0 && beforeFrom) {
                dataIntervals.push(from.toISOString(), to.toISOString());
            }

            let data: {[key: string]: any[]} = {};
            for (const id of Object.keys(result)) {
                data[id] = [];
                let value = beforeFrom && beforeFrom in result[id] ? result[id][beforeFrom] : null;
                for (const interval of dataIntervals) {
                    if (interval in result[id]) {
                        value = result[id][interval];
                        data[id].push(value, false);
                    } else {
                        data[id].push(value, true);
                    }
                }
            }

            rows = [[_msg("general.date")].concat(Object.keys(data))];
            dataIntervals.forEach((date, index) => rows.push([date].concat(Object.keys(data).map(key => data[key][index * 2]))));
            dataIntervals.forEach((date, index) => noChangeRows.push([false].concat(Object.keys(data).map(key => data[key][index * 2 + 1]))));
        }

        this.props.r.setInReduxState({ percentage: 100, rows, noChangeRows});
        
        await this.getEntityMiniFields(config.entityName, Object.keys(result));
    }
    async populateReportImpure(config: Config, entityId: number, reportType: string) {
        const filter = Filter.eliminateDisabledFilters(config.filter)
        const date: string[] = config.date.split(",");

        this.props.r.setInReduxState({rows: [[]] as [string[]],
                    noChangeRows: [[]] as [boolean[]],
                    entityMiniStrings: [] as any[],
                    percentage: 0});
        
        const result: any = (await apolloClient.mutate<populateHistoryReport>({
            mutation: POPULATE_HISTORY_REPORT,
            variables: { from: date[0], to: date.length > 1 ? date[1] : null, entityName: config.entityName, 
                filter, entityFields: config.entityFields, entityId, reportType }
        }));
    }
    async getEntityMiniFields(entityName: string, ids: string[]) {
        const ed = entityDescriptors[entityName];
        const loadOperationName = `${lodash.lowerFirst(ed.name)}Service_findByFilter`;
        const query = gql(`query q($params: FindByFilterParamsInput) { 
            ${loadOperationName}(params: $params) {
                results { ${ID} ${ed.getGraphQlFieldsToRequest(ed.miniFields)} }
            }
        }`);

        const result = (await apolloClientHolder.apolloClient.query({ query: query, 
            variables: FindByFilterParams.create().filter(ids.length ? Filter.create(ID, FilterOperators.forNumber.in, ids.join(",")) : undefined) })
            ).data[loadOperationName];

        this.props.r.setInReduxState({ entityMiniStrings: result.results });
    }
    exportDataAsCSV(config: Config, entity: Chart) {
        const ed = entityDescriptors[config.entityName];
        const rows = lodash.cloneDeep(this.props.s.rows);

        if (entity.type === FIELDS_HISTORY_REPORT_TYPE) {
            rows[0][0] = ed.getLabel();
            for (var i = 1; i < rows[0].length; i++) {
                rows[0][i] = ed.getField(rows[0][i]).getLabel();
            }
            for (var i = 1; i < rows.length; i++) {
                rows[i][0] = ed.toMiniString(this.props.s.entityMiniStrings.find(x => x[ID] === Number.parseFloat(rows[i][0])));
            }
        } else {
            for (var i = 1; i < rows[0].length; i++) {
                rows[0][i] = ed.toMiniString(this.props.s.entityMiniStrings.find(x => x[ID] === Number.parseFloat(rows[0][i])));
            }
            for (var i = 1; i < rows.length; i++) {
                rows[i][0] = moment(rows[i][0]).format(Utils.dateTimeWithSecFormat);
            }
        }

        Utils.exportToCsv(entity.name + "_" + new Date().getTime() + ".csv", rows);
    }
    async checkIfDataAvailable(entity: Chart, config: Config) {
        const loadOperationName = "chartService_findById";
        const query = gql(`query q($id: ${CrudGlobalSettings.INSTANCE.defaultGraphQlIdType}) {
            ${loadOperationName}(id: $id) {
                ${ID} savedData percentage
            }
        }`);

        const result = (await apolloClientHolder.apolloClient.query({ query: query, variables: { id : entity.id } })).data[loadOperationName];

        this.props.r.setInReduxState({ percentage: result.percentage });
        if (result.percentage === 100) {
            await this.prepareData(entity, config, result.savedData);
        }
    }

    componentDidMount() {
        if (this.props.entity.savedData) {
            this.deserializeSavedData(this.props.entity.savedData);
        } else if (!this.props.entity.percentage || this.props.entity.percentage === 0) {
            this.populateReport();
        } else {
            this.props.r.setInReduxState({ percentage: this.props.entity.percentage });
            if (this.props.entity.percentage !== 100) {
                this.startTimer();
            }
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    protected deserializeSavedData(savedData: string) {
        this.prepareData(this.props.entity, this.props.config, savedData);
    }

    protected populateReport() {
        this.populateReportImpure(this.props.config, this.props.entity.id, this.props.entity.type!);
        this.startTimer();
    }

    private startTimer() {
        this.timer = window.setTimeout(() => {
            this.checkIfDataAvailable(this.props.entity, this.props.config);
            if (this.props.s.percentage !== 100) {
                this.startTimer();
            }
        }, refreshRateMillis);
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    protected renderFieldsHistoryHeader() {
        return <>
            {_msg("FieldsHistoryReport.date.label")}: <Label basic>
                <Icon name='calendar alternate outline' /> {this.props.config.date ? moment(this.props.config.date).format(Utils.dateTimeFormat) : undefined}
            </Label>
        </>
    }

    protected renderFieldHistoryHeader() {
        const date = this.props.config.date.split(",");
        const fd = entityDescriptors[this.props.config.entityName].getField(this.props.config.entityFields.split(",")[0])

        return <>
            {_msg("FieldHistoryReport.entityFields.label")}: <Label basic>
                {fd?.getIcon()}{fd?.getLabel()}
            </Label>
            {_msg("general.from")}: <Label basic>
                <Icon name='calendar alternate outline' /> {date.length > 0 && date[0] ? moment(date[0]).format(Utils.dateTimeFormat) : undefined}
            </Label>
            {_msg("general.to")}: <Label basic>
                <Icon name='calendar alternate outline' /> {date.length > 1 && date[1] ? moment(date[1]).format(Utils.dateTimeFormat) : undefined}
            </Label>
        </>
    }

    getTableWidth() {
        return this.state?.measuredWidth ? this.state.measuredWidth : 0;
    }

    getTableHeight() {
        return this.state?.measuredHeight ? this.state.measuredHeight - 10 : 0
    }

    renderFieldsHistoryTable() {
        const ed = entityDescriptors[this.props.config.entityName];

        return <Table rowsCount={this.props.s.rows.length - 1} rowHeight={50} width={this.getTableWidth()} maxHeight={this.getTableHeight()}
                        headerHeight={50} touchScrollEnabled isColumnResizing={false} isColumnReordering={false} >
            {this.props.s.rows[0].map((field, index) => {
                const fd = index > 0 ? ed.getField(field) : null;
                return <Column key={field} columnKey={field}
                    allowCellsRecycling width={150} isResizable={false} isReorderable={false}
                    flexGrow={index === this.props.s.rows[0].length - 1 ? 1 : undefined}
                    header={props => {
                        if (index === 0) {
                            return <Cell>{ed.getIcon()}{ed.getLabel()}</Cell>
                        } else {
                            return <Cell>{fd?.getIcon()}{fd?.getLabel()}</Cell>
                        }
                    }}
                    cell={props => {
                        const value = this.props.s.rows[props.rowIndex + 1][index];
                        if (index === 0) {
                            const entity = this.props.s.entityMiniStrings.find(x => x[ID] === Number.parseFloat(value));
                            return <Cell><NavLink key={entity?.[ID]} to={ed.getEntityEditorUrl(entity?.[ID])}>{ed.toMiniString(entity)}</NavLink></Cell>
                        } else {
                            const jsonValue = fd && { [fd.name]: fd?.type === FieldType.boolean ? TRUE_VALUES.includes(value) : value }
                            const simulatedEntity = fd ? (fd.isCustomField ? { [CUSTOM_FIELDS]: jsonValue } : jsonValue) : {};
                            return <Cell>{fd?.renderField(simulatedEntity)}</Cell>
                        }
                    }} /> 
                })}
        </Table>
    }

    renderFieldHistoryTable() {
        const ed = entityDescriptors[this.props.config.entityName];
        const fd = ed.getField(this.props.config.entityFields.split(",")[0]);

        const dateFieldDescriptor = new FieldDescriptor();
        dateFieldDescriptor.type = FieldType.date;
        dateFieldDescriptor.name = "date";
        dateFieldDescriptor.format = Utils.dateTimeWithSecFormat;

        return <Table rowsCount={this.props.s.rows.length - 1} rowHeight={50} width={this.getTableWidth()} maxHeight={this.getTableHeight()}
                        headerHeight={50} touchScrollEnabled isColumnResizing={false} isColumnReordering={false} >
            {this.props.s.rows[0].map((field, index) => {
                return <Column key={field} columnKey={field}
                    allowCellsRecycling width={150} isResizable={false} isReorderable={false}
                    flexGrow={index === this.props.s.rows[0].length - 1 ? 1 : undefined}
                    header={props => {
                        if (index === 0) {
                            return <Cell>{field}</Cell>
                        } else {
                            const entity = this.props.s.entityMiniStrings.find(x => x[ID] === Number.parseFloat(field));
                            return <Cell><NavLink key={field} to={ed.getEntityEditorUrl(entity?.[ID])}>{ed.toMiniString(entity)}</NavLink></Cell>
                        }
                    }}
                    cell={props => {
                        const value = this.props.s.rows[props.rowIndex + 1][index];
                        const hasNoChange = this.props.s.noChangeRows[props.rowIndex + 1][index];
                        if (index === 0) {
                            return <Cell>{dateFieldDescriptor.renderField({ [dateFieldDescriptor.name]: value})}</Cell>
                        } else {
                            const jsonValue = fd && { [fd.name]: fd?.type === FieldType.boolean ? TRUE_VALUES.includes(value) : value }
                            const simulatedEntity = fd ? (fd.isCustomField ? { [CUSTOM_FIELDS]: jsonValue } : jsonValue) : {};
                            return <Cell>
                                {fd?.renderField(simulatedEntity)}
                                {hasNoChange ? <span style={{ fontSize: "xx-small", fontStyle: "italic" }}>{noChangeString}</span> : null}
                            </Cell>
                        }
                    }} /> 
                })}
        </Table>
    }

    render() {
        return <>
            <Segment compact>
                {this.props.entity.type === FIELDS_HISTORY_REPORT_TYPE ? this.renderFieldsHistoryHeader() : this.renderFieldHistoryHeader()}
                <Button color='green' onClick={(event: any) => this.populateReport()}>{_msg("FieldsHistoryReport.generate")}</Button>
                <Button disabled={this.props.s.percentage != 100} onClick={(event: any) => this.exportDataAsCSV(this.props.config, this.props.entity)}>{_msg("FieldsHistoryReport.exportCsv")}</Button>
            </Segment>
            <Segment compact basic className="flex-container flex-grow no-padding-top-bottom no-margin">
                <Dimmer active={this.props.s.percentage != 100}>
                    <Loader size='large'>Loading {this.props.s.percentage + "%"}</Loader>
                </Dimmer>
                <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width, measuredHeight: contentRect.bounds?.height })}>
                    {({ measureRef }) => (<div className="flex-container flex-grow" ref={measureRef}>
                        {this.props.entity.type === FIELDS_HISTORY_REPORT_TYPE ? this.renderFieldsHistoryTable() : this.renderFieldHistoryTable()}
                    </div>)}
                </Measure>
            </Segment>
        </>;
    }
}

export const FieldsHistoryTabRRC = ReduxReusableComponents.connectRRC(FieldsHistoryTabState, FieldsHistoryTabReducers, FieldsHistoryTab);