import { createSliceFoundation, CrudEditorPageRenderHeaderParams, EditMode, EntityEditorFormSimple, EntityEditorPage, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, StateFrom } from "@crispico/foundation-react";
import { sliceWizard, Wizard, WizardStep } from "@crispico/foundation-react/components/Wizard/Wizard";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldEditorProps, FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import React, { ReactNode } from "react";
import { Divider, Dropdown, Header, Menu, Message, Icon, SemanticICONS } from "semantic-ui-react";
import { BarsDistanceTimeInTerritoriesTabRRC } from "./DistanceTimeInTerritories/BarsDistanceTimeInTerritoriesTab/BarsDistanceTimeInTerritoriesTab";
import { PieDistanceTimeInTerritoriesTabRRC } from "./DistanceTimeInTerritories/PieDistanceTimeInTerritoriesTab/PieDistanceTimeInTerritoriesTab";
import { equipmentUsageConfigDescriptor, equipmentUsageDayConfigDescriptor, EquipmentUsageTabRRC} from "./equipmentUsageTab/EquipmentUsageTab";
import { fieldHistoryConfigDescriptor, fieldsHistoryConfigDescriptor, FieldsHistoryTabRRC } from "./FieldsHistory/FieldsHistory";
import { HistogramCountInTerritoriesTabRRC } from "./HistogramCountInTerritoriesTab/HistogramCountInTerritoriesTab";

function getChartTypeMessage(type: string | undefined) {
    return type ? _msg("Chart." + type + ".title") : "";
}

export class ChartEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "Chart",
            miniFields: ["name", "type"],
            icon: "chart pie",
            defaultSort: { field: 'name', direction: 'DESC' },
            toMiniString: function toMiniString(entityWithMiniFields: any): string {
                return entityWithMiniFields.name + " - " + getChartTypeMessage(entityWithMiniFields.type);
            }
        });
    }

    protected customize() {
        this.isInDefaultColumnConfig(false, "config", "savedData", "showInRealtimeMap") // CS: disabled quickly, because they induced perf issues in the table
            .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false, isInDefaultColumnConfig: false })
            .addFieldDescriptor({ name: "name", type: FieldType.string })
            .addFieldDescriptor({ name: "type", type: FieldType.string, isInDefaultColumnConfigForEditor: false }, new class extends FieldDescriptor {
                renderField(entity: any) {
                    return getChartTypeMessage(entity.type);
                }
            })
            .addFieldDescriptor({ name: "percentage", type: FieldType.progress })
            .addFieldDescriptor({ name: "showInRealtimeMap", type: FieldType.boolean })
            .addFieldDescriptor(new class extends FieldDescriptor {
                name = "generateAutomatically";
        
                options: Array<any> = [{ key: 0, text: "", value: 0 }, { key: 1, text: "Daily", value: 1 }, { key: 2, text: "Weekly", value: 2 }];
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    return (<Dropdown defaultValue={0} selection options={this.options} value={props.fieldDescriptor.getFieldValue(props.formikProps.values)}
                        onChange={(e, { value }) => props.formikProps.setFieldValue(props.fieldDescriptor.getFieldName(), value)} />);
                }
        
                protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any) {
                    return (<>{(this.options.find((e: any) => e.value === props.entity.generateAutomatically)?.text || "")}</>);
                }
            })
            .addFieldDescriptor({ name: "sendEmailTo", type: FieldType.string })
            .addFieldDescriptor({ name: "lastAutomaticGenerationDate", type: FieldType.date })
            .addFieldDescriptor({ name: "icon", type: FieldType.string })
            .addFieldDescriptor({ name: "color", type: FieldType.color })
            .addFieldDescriptor({ name: "showInMenu", type: FieldType.boolean })
            .addFieldDescriptor({ name: "positionInMenu", type: FieldType.number })
            .addFieldDescriptor({ name: "organization", type: "Organization"})
        ;

        this.infoEditor.slice = sliceEntityEditorPageChart.setEntityDescriptor(this);
        this.infoEditor.wrappedComponentClass = ChartEntityEditorPage;
        this.infoEditor.routeProps!.routeIsModal = false;
    }
}

const CORRUPT = "corrupt";

export const sliceEntityEditorPageChart = createSliceFoundation(class Ext extends SliceEntityEditorPage {
    initialState = {
        ...sliceEntityEditorPageOnlyForExtension.initialState,
        config: undefined as any | typeof CORRUPT
    }

    nestedSlices = {
        ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
        wizard: sliceWizard,
    }

    reducers = {
        ...sliceEntityEditorPageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),

        updateChartType(state: StateFrom<Ext>, type: string) {
            state.entity.type = type;
        },

        clearStateBeforeAddOrEditSuper: sliceEntityEditorPageOnlyForExtension.reducers.clearStateBeforeAddOrEdit,
        clearStateBeforeAddOrEdit(state: StateFrom<Ext>) {
            this.clearStateBeforeAddOrEditSuper(state);
            state.config = {};
        },

        onModeLoadedSuper: sliceEntityEditorPageOnlyForExtension.reducers.onModeLoaded,
        onModeLoaded(state: StateFrom<Ext>, entity: any) {
            this.onModeLoadedSuper(state, entity);
            try {
                state.config = JSON.parse(entity.config);
            } catch (e) {
                state.config = CORRUPT;
            }
        }
    }

    impures = {
        ...sliceEntityEditorPageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
        async save(entity: any) {
            entity = { ...entity, config: JSON.stringify(this.getState().config) };
            delete entity.savedData;
            return this.saveSuper(entity, undefined, undefined, {
                initialFieldsAndValues: {
                    config: entity.config
                }
            });
        }
    }

    getFieldsToRequest() {
        return super.getFieldsToRequest() + " config savedData";
    }
});

export type ChartDescriptor = {
    typeKeySuffix: string,
    icon: SemanticICONS,
    tab: any,
    descriptor: EntityDescriptor,
}

const chartConfigEntityDescriptor = (name: string) => {
    return new EntityDescriptor({name: name}, false)
        .addFieldDescriptor({ name: "startDate", type: FieldType.date })
        .addFieldDescriptor({ name: "endDate", type: FieldType.date })
        .addFieldDescriptor({ name: "equipmentFilter", type: FieldType.filter, entityDescriptor: entityDescriptors['EquipmentResource'] })
        .addFieldDescriptor({ name: "territories", type: FieldType.oneToMany('Territory') })
}

export const chartTypes: { [type: string]: ChartDescriptor } = {
    fieldsHistory: { typeKeySuffix: "table", icon: "table", tab: FieldsHistoryTabRRC, descriptor: fieldsHistoryConfigDescriptor },
    fieldHistory: { typeKeySuffix: "table", icon: "table", tab: FieldsHistoryTabRRC, descriptor: fieldHistoryConfigDescriptor },
    equipmentUsage: { typeKeySuffix: "bars", icon: "chart bar", tab: EquipmentUsageTabRRC, descriptor: equipmentUsageConfigDescriptor },
    equipmentUsageDay: { typeKeySuffix: "lines", icon: "chart line", tab: EquipmentUsageTabRRC, descriptor: equipmentUsageDayConfigDescriptor },
    histogramCountInTerritories: {
        typeKeySuffix: "histogram", icon: "chart line", tab: HistogramCountInTerritoriesTabRRC, descriptor: chartConfigEntityDescriptor("HistogramCountInTerritoriesTab").addFieldDescriptor({ name: "samplingStep", type: FieldType.number })
    },
    pieDistanceTimeInTerritories: {
        typeKeySuffix: "pie", icon: "chart pie", tab: PieDistanceTimeInTerritoriesTabRRC, descriptor: chartConfigEntityDescriptor("PieDistanceTimeInTerritoriesTab")
    },
    barsDistanceTimeInTerritories: {
        typeKeySuffix: "bars", icon: "chart bar", tab: BarsDistanceTimeInTerritoriesTabRRC, descriptor: chartConfigEntityDescriptor("BarsDistanceTimeInTerritoriesTab").addFieldDescriptor({ name: "samplingStep", type: FieldType.number })
    }
}

type ChartType = 'histogramCountInTerritories' | 'pieDistanceTimeInTerritories' | 'barsDistanceTimeInTerritories' | "equipmentUsage" | "fieldsHistory" | "fieldHistory";

export function getChartComponentClass(type: string, throwErrorIfNotFound: boolean = true): any {
    const ComponentClass = chartTypes[type]?.tab;
    if (!ComponentClass && throwErrorIfNotFound) { throw new Error("Unknown chart type: " + type + "; known types = " + Object.keys(chartTypes)); }
    return ComponentClass;
}

class ChartEntityEditorPage extends EntityEditorPage<PropsFrom<typeof sliceEntityEditorPageChart>> {

    protected refForm2 = React.createRef<EntityEditorFormSimple>();

    protected onMatchChanged(match: any) {
        this.props.dispatchers.wizard.reset();
        super.onMatchChanged(match);
    }

    protected getExtraTabPanes() {
        if (this.props.mode === EditMode.ADD) {
            return super.getExtraTabPanes();
        }

        return [{
                routeProps: { path: "/chart" },
                menuItemProps: { icon: "chart pie", content: "Chart" },
                render: () => {
                    const ComponentClass = getChartComponentClass(this.props.entity?.type, false);
                    const chartType: ChartType = this.props.entity?.type;
                    return this.renderErrorIfNeeded(() => <ComponentClass id={chartType} entity={this.props.entity} config={this.props.config} editorDispatchers={this.props.dispatchers} />)
                }
            }, ...super.getExtraTabPanes()];
    }

    renderHeader(params: CrudEditorPageRenderHeaderParams) {
        if (this.props.mode === EditMode.ADD) {
            return null;
        }
        return super.renderHeader(params);
    }

    protected renderFormNew() {
        const chartDescriptor = chartTypes[this.props.entity.type];
        return (<>
            <Menu fluid vertical>
                <Row type={this.props.entity.type} />
            </Menu>
            <Divider />
            {super.renderForm()}
            <Divider />
            {this.renderErrorIfNeeded(() => <EntityEditorFormSimple hideButtonBar ref={this.refForm2} entity={this.props.config} entityDescriptor={chartDescriptor.descriptor} />)}
        </>);
    }

    protected renderErrorIfNeeded(what: () => ReactNode) {
        if (this.props.mode === EditMode.EDIT_LOADING) {
            return _msg("general.loading");
        }
        const chartDescriptor = chartTypes[this.props.entity?.type];
        if (chartDescriptor && this.props.config !== CORRUPT) {
            return what();
        } else {
            return <Message error>{_msg("Chart.noType", this.props.entity?.type)}</Message>
        }
    }

    protected renderForm() {
        if (this.props.mode === EditMode.ADD) {
            const m: any = this.getMainMenuItemProps();
            const steps: WizardStep[] = [
                {
                    title: _msg("Chart.chooseType"),
                    icon: "question circle outline",
                    nextDisabled: () => !this.props.entity.type,
                    render: () => (
                        <Menu fluid vertical>
                            {Object.keys(chartTypes).map(type => <Row parentProps={this.props} type={type} />)}
                        </Menu>
                    ),
                },
                { title: m.content, icon: m.icon, render: () => this.renderFormNew() }
            ];
            return <Wizard {...this.props.wizard} dispatchers={this.props.dispatchers.wizard} steps={steps}
                onFinishClick={() => this.onSave()} onCancelClick={this.onCancel} />
        } else {
            return this.renderFormNew();
        }
    }

    protected getContainerCssClasses() {
        const result = super.getContainerCssClasses();
        if (this.props.wizard.currentStepIndex === 0) {
            result.inner += " ChartEditorPage_innerFormContainer";
        }

        return result;
    }

    protected commitMain() {
        super.commitMain();
        if (!this.refForm2.current) {
            return;
        }
        this.props.dispatchers.setInReduxState({ config: this.refForm2.current.formikContext.values });
    }

    protected isDirty() {
        return super.isDirty() && this.refForm2.current?.formikContext.dirty;
    }
}

function Row(props: { type: string, parentProps?: PropsFrom<typeof sliceEntityEditorPageChart> }) {
    const cd = chartTypes[props.type];
    return (<Menu.Item className="flex-container-row" link active={props.type === props.parentProps?.entity.type} onClick={() => props.parentProps?.dispatchers.updateChartType(props.type)}>
        <div>
            <Icon name={cd.icon} size="massive"></Icon>
        </div>
        <div className="flex-grow">
            <Header as="h2" dividing>{getChartTypeMessage(props.type)}<Header.Subheader>{_msg("Chart.type")} <b>{_msg("Chart.type." + cd.typeKeySuffix)}</b></Header.Subheader></Header>
            <p>{_msg("Chart." + props.type + ".content")}</p>
        </div>
    </Menu.Item>)
}
