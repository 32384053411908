import { TestsAreDemoAppWrapper } from '@crispico/foundation-react/testsAreDemo/TestsAreDemoAppWrapper';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

function renderApp() {
    ReactDOM.render(<TestsAreDemoAppWrapper app={<App />} importTestsCallback={async () => await import("./appTests")} />, document.getElementById('root'));
}

async function initApp() {   
    const wrapper = await import("pages/XopsMobile/XopsMobileWrapper"); 
  
    if (wrapper.XopsMobileWrapper.isDeviceMobileApp()) {  
        console.log("adding cordova.js!");      
        // load cordova.js
        var cordovaScript = document.createElement('script');
        cordovaScript.setAttribute('src', 'cordova.js');
        // append the script tag to document head
        document.head.appendChild(cordovaScript);
    }
    
    if (wrapper.XopsMobileWrapper.isDeviceMobileApp()) {
        console.log("waiting for deviceready!");  
        // Init app on device ready
        document.addEventListener("deviceready", () => {
            console.log("deviceready!");  
            // at the end, render the app
            renderApp();          
        }, false);       
    } else {        
        renderApp();
    }
}

initApp();