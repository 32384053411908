import { APP_CONTAINER, Optional, RootReducerForPages, sliceAppContainer, Utils, TestUtils, FieldDescriptor } from "@crispico/foundation-react";
import { MessageExt } from "@crispico/foundation-react/components/semanticUiReactExt";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { EquipmentResourceForMap } from "apollo-gen/EquipmentResourceForMap";
import { MapSettings, MarkerSettings } from "app";
import { MarkerData } from "components/MapContainerLeaflet/MapContainerLeaflet";
import moment from "moment";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Image, Label, Table, Icon, Segment, Popup } from "semantic-ui-react";
import { getIcon, eqImages256x256 } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { Messages } from "@crispico/foundation-gwt-js";
import { territoryEntityDescriptor } from "pages/Territory/territoryEntityDescriptor";
import { FieldType, fieldTypeToPrimitiveFieldTypeMapping } from "@crispico/foundation-react/entity_crud/FieldType";
import { StringFieldRendererSpecificProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/StringFieldRenderer";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { getDropdownItemLabel } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DropdownFieldRenderer";
import { ColorRegistry } from "@crispico/foundation-react/utils/ColorRegistry";

export type MarkerColorInterval = {
    label?: string;
    from: string;
    to?: string;
    color: string;
}

export type EquipmentResourceSettings = {
    mainColorSettings?: {
        area: string;
        field: string;
        showInFilterBar: string;
        useDurationBetweenNowAndFieldValue: boolean | undefined;
        intervals: MarkerColorInterval[];
    } | undefined,
    mainColor?: number | undefined,
    topLeftColor?: number | undefined,
    topRightColor?: number | undefined,
    bottomLeftColor?: number | undefined,
    bottomRightColor?: number | undefined
};

export const EQUIPMENT_RESOURCE_TYPE: string = "equipmentResource";

/**
 * Used to store utility methods for equipment resources, used in different places (real time map, historical map)
 */
export class EquipmentResourceUtils {

    static getEquipmentResourceMapSettings(mapMarkSettings: MapSettings): Optional<MarkerSettings> {
        return mapMarkSettings.markers.find(m => m.markerType === EQUIPMENT_RESOURCE_TYPE);
    }

    static getMarkerFromEquipmentResource(rootReducerForPages: RootReducerForPages, eq: any, markerSettings: Optional<MarkerSettings>): MarkerData {
        const colors = this.getEquipmentMapColors(rootReducerForPages, eq, markerSettings);
        let marker: MarkerData = { id: eq.id, text: markerSettings?.showTextUnderIcon ? eq.identifier : undefined, icon: eq.equipmentType?.icon || undefined, point: { longitude: eq.lastPointLongitude, latitude: eq.lastPointLatitude } };

        marker.mainArea = colors.mainColor && colors.mainColor !== -1 ? { color: Utils.convertColorToHex(colors.mainColor) } : undefined;
        marker.topLeftArea = colors.topLeftColor && colors.topLeftColor !== -1 ? { color: Utils.convertColorToHex(colors.topLeftColor) } : undefined;
        marker.topRightArea = colors.topRightColor && colors.topRightColor !== -1 ? { color: Utils.convertColorToHex(colors.topRightColor) } : undefined;
        marker.bottomLeftArea = colors.bottomLeftColor && colors.bottomLeftColor !== -1 ? { color: Utils.convertColorToHex(colors.bottomLeftColor) } : undefined;
        marker.bottomRightArea = colors.bottomRightColor && colors.bottomRightColor !== -1 ? { color: Utils.convertColorToHex(colors.bottomRightColor) } : undefined;

        return marker;
    }

    static checkIfEquipmentInInterval(interval: MarkerColorInterval, useDurationBetweenNowAndFieldValue: boolean, value: any, fd: FieldDescriptor) {
        try {
            const from = Number(interval.from);
            if (useDurationBetweenNowAndFieldValue) {
                const diff = Utils.now().getTime() - moment(value).toDate().getTime();
                if (diff >= from && diff < Number(interval.to) || (interval.to === null && diff >= from)) {
                    return true;
                }
            } else if (interval.to) {
                if (value >= from && value < Number(interval.to)) {
                    return true;
                }
            } else {
                if (FieldType.boolean === fd.type) {
                    if (String(Boolean(value)) === interval.from) {
                        return true;
                    }
                } else if (!isNaN(from) && value >= from || value === interval.from || String(value) === interval.from) {
                    return true;
                }
            }
        } catch (e) {
            console.log("Error while EquipmentResourceUtils.checkIfEquipmentInInterval: " + e);
        }
        return false;
    }

    static getMarkerColorIntervals(color: EquipmentResourceSettings["mainColorSettings"]) {
        if (color) {
            if (color.intervals?.length) {
                return color.intervals;
            }
            const fieldIntervals = crudSettings?.forEntities.find(fe => fe.entityName === "EquipmentResource")!.fieldDescriptorSettings.find(fds => fds.fieldRef === color.field)?.fieldIntervals;
            if (fieldIntervals) {
                return fieldIntervals as MarkerColorInterval[];
            }
        }
        return [];
    }

    static getEquipmentMapColors(rootReducerForPages: RootReducerForPages, eq: EquipmentResourceForMap, markerSettings: Optional<MarkerSettings>): EquipmentResourceSettings {
        let settings: EquipmentResourceSettings = {};

        if (TestUtils.storybookMode) {
            // there is an issue when accesing storybook for historicalMap because it doesn't find a mountedHelper for AppContainer
            // this is a provisory solution until we know what's missing
            return settings;
        }
        const ed = entityDescriptors["EquipmentResource"];

        if (markerSettings && markerSettings.colors) {
            markerSettings.colors.forEach(item => {
                let color = -1;              
                try {
                    const fd = ed.getField(item.field);                
                    const intervals = EquipmentResourceUtils.getMarkerColorIntervals(item);
                    for (let i = 0; i < intervals.length; i ++) {                        
                        if (this.checkIfEquipmentInInterval(intervals[i], item.useDurationBetweenNowAndFieldValue, fd.getFieldValue(eq), fd)) {
                            color = ColorRegistry.INSTANCE!.get(intervals[i].color);
                            break;
                        }
                    }
                } catch (e) {
                    console.log("Field not found: " + item.field);
                }
                
                switch (item.area) {
                    case "main":
                        settings.mainColor = color;
                        settings.mainColorSettings = item;
                        break;
                    case "topLeft":
                        settings.topLeftColor = color;
                        break;
                    case "topRight":
                        settings.topRightColor = color;
                        break;
                    case "bottomRight":
                        settings.bottomRightColor = color;
                        break;
                    case "bottomLeft":
                        settings.bottomLeftColor = color;
                        break;
                }
            })
        }
        return settings;
    }

    static getImage(er: EquipmentResourceForMap, invertedColor: boolean = false) {
        return er?.equipmentType && er.equipmentType.icon
            ? <img className={"EqRes_mapIcon_svg_big" + (invertedColor ? " EqRes_icon_svg" : "")} src={
                (er.equipmentType.icon as string).endsWith(".svg")
                    ? er.equipmentType.icon
                    : (eqImages256x256[er.equipmentType.icon] || getIcon(er.equipmentType.icon)?.image.src)} alt={er.equipmentType.icon}></img>
            : <>{equipmentResourceEntityDescriptor.icon}</>;
    }

    static renderEquipmentResourceIcon(eq: EquipmentResourceForMap, data: MarkerData, markerSettings: Optional<MarkerSettings>, additionalStyles?: { selected?: boolean, hovered?: boolean }): ReactNode {
        const icon = getIcon(eq?.equipmentType?.icon);
        if (!icon) {
            data.icon = {};
        }
        return <><span className='fa fa-stack fa-lg'>
            <i className={'fa ' + (data.mainArea?.icon || 'fa-square-o') + ' fa-stack-2x'} style={{ color: (data.mainArea?.color || 'grey') }} />
            {icon?.url.endsWith(".svg")
                ? <img className='EqRes_mapIcon_svg EqRes_icon_svg' src={icon?.image.src} alt='icon' />
                : typeof (data.icon) === 'string'
                    ? <img className='fa fa-stack-1x' src={icon?.image.src} alt='icon' />
                    : <i className={'fa ' + (data.icon?.icon || 'fa-times') + ' fa-stack-1x'} style={{ color: (data.icon?.color || 'red') }}></i>}
            {data.bottomRightArea ? <i className={'fa ' + (data.bottomRightArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-br'} style={{ color: (data.bottomRightArea.color || undefined) }} /> : undefined}
            {data.bottomLeftArea ? <i className={'fa ' + (data.bottomLeftArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-bl'} style={{ color: (data.bottomLeftArea.color || undefined) }} /> : undefined}
            {data.topRightArea ? <i className={'fa ' + (data.topRightArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-tr'} style={{ color: (data.topRightArea.color || undefined) }} /> : undefined}
            {data.topLeftArea ? <i className={'fa ' + (data.topLeftArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-tl'} style={{ color: (data.topLeftArea.color || undefined) }} /> : undefined}
        </span>{markerSettings?.showTextUnderIcon ? <div style={{ font: 'bold 12px Lato', whiteSpace: "nowrap" }}>{data.text}</div> : undefined}</>;
    }

    static renderSmallInfoArea(eq: EquipmentResourceForMap, mapMarkSettings: MapSettings): React.ReactElement {
        return (<div className="flex-container">
            <div>{EquipmentResourceUtils.displayEqInfo(eq, mapMarkSettings)}<div>{equipmentResourceEntityDescriptor.getField("tags").renderTags({ value: eq.tags } as FieldRendererProps, false)}</div></div>
            <div>{EquipmentResourceUtils.getEqDataForSmallInfoArea(eq, mapMarkSettings).map(data => <Label key={data.name}>{data.value}</Label>)}</div>
        </div>);
    }

    private static getEqDataForSmallInfoArea(eq: EquipmentResourceForMap, mapMarkSettings: MapSettings): { name: string, value: any }[] {
        let data: any[] = [];
        const markerSetings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(mapMarkSettings);
        const fields = markerSetings ? markerSetings.smallPopupFields : [];
        fields.forEach(field => {
            const fieldDescriptor = equipmentResourceEntityDescriptor.getFieldDescriptorChain(field.name)[0];
            if (fieldDescriptor && !fieldDescriptor.typeIsEntity()) {
                data.push({ name: fieldDescriptor.getLabel(), value: fieldDescriptor.getFieldValue(eq) || "-" })
            }
        });
        return data;
    }

    static displayEqInfo(eq: EquipmentResourceForMap, mapSettings: MapSettings) {
        const markerSettings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(mapSettings);
        const fieldDescriptor = equipmentResourceEntityDescriptor.getFieldDescriptorChain(markerSettings?.markerIdField ? markerSettings?.markerIdField : "identifier")[0];
        return fieldDescriptor ? fieldDescriptor.getFieldValue(eq) : "";
    }

}

type EquipmentResourceBigInfoAreaProps = { eq: EquipmentResourceForMap, mapMarkSettings: MapSettings }

export class EquipmentResourceBigInfoArea extends React.Component<EquipmentResourceBigInfoAreaProps> {

    private getEqDataForBigInfoArea(): any[] {
        let data: any[] = [];
        const markerSetings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(this.props.mapMarkSettings);
        const fields = markerSetings ? markerSetings.bigPopupFields : [];
        fields.forEach(field => {
            const fieldDescriptor = equipmentResourceEntityDescriptor.getFieldDescriptorChain(field.name)[0];
            if (fieldDescriptor) {
                data.push({
                    name: fieldDescriptor.getLabel(),
                    value: fieldDescriptor.getFieldValue(this.props.eq) !== undefined ? fieldDescriptor.renderField(this.props.eq) : _msg("general.notAvailable"),
                    icon: fieldDescriptor.getIcon()
                })
            }
        });
        return data;
    }

    render = () => {
        const { eq } = this.props;
        if (!this.props.eq) {
            return <></>;
        }
        const data = this.getEqDataForBigInfoArea();
        const lastDetectionDate = moment(this.props.eq.lastDetectionDate);
        const updated = moment(this.props.eq.updated);
        const showLastDetectionDate = moment().diff(lastDetectionDate, "days") > 0;
        const showUpdatedDate = moment().diff(updated, "days") > 0;
        return <div className="wh100 EqResBigInfoArea">
            <MessageExt headerClassName="flex-container-row flex-center flex-wrap">
                {eq.equipmentType && eq.equipmentType.icon ? <Image className="EqRes_icon_svg" width="64px" height="64px" src={eqImages256x256[eq.equipmentType.icon] || getIcon(eq.equipmentType.icon)?.image.src} /> : null}
                <div className="flex-container flex-center flex-grow-shrink-no-overflow flex-wrap EqResBigInfoArea_generalInfo">
                    <h1>
                        <NavLink to={equipmentResourceEntityDescriptor.getEntityEditorUrl(eq.id)}>{EquipmentResourceUtils.displayEqInfo(eq, this.props.mapMarkSettings)}</NavLink>
                    </h1>
                    {/* TODO by CS: should have used descriptor; I was forced to explicitly invoke Messages... */}
                    {eq.equipmentModel ? <Label color="teal">{Messages.getInstance().maybeTranslateByUser(eq.equipmentModel.name)}</Label> : null}
                    <Label basic>{Messages.getInstance().maybeTranslateByUser(eq.equipmentType?.name)}</Label>
                    {eq.tags && eq.tags.length > 0 ? <div>{equipmentResourceEntityDescriptor.getField("tags").renderTags({ value: eq.tags } as FieldRendererProps)}</div> : null}
                </div>
            </MessageExt>
            <div className="flex-container-row flex-wrap flex-center EqResBigInfoArea_available">
                <Popup
                    trigger={<Label size="large" basic color='green'><Icon name="truck" />{this.props.eq.lastDetectionDate ? moment(this.props.eq.lastDetectionDate).format(showLastDetectionDate ? Utils.dateTimeFormat : Utils.timeFormat) : _msg("general.notAvailable")}</Label>}>
                    <Popup.Header><Icon name="truck" />{_msg("EquipmentResource.lastDetectionDate.label")}</Popup.Header>
                    <Popup.Content>
                        <Label>{this.props.eq.lastDetectionDate ? moment(this.props.eq.lastDetectionDate).format(Utils.dateTimeFormat) : _msg("general.notAvailable")}</Label>
                    </Popup.Content>
                </Popup>
                <Popup
                    trigger={<Label size="large" basic color='blue'><Icon name="database" />{this.props.eq.updated ? moment(this.props.eq.updated).format(showUpdatedDate ? Utils.dateTimeFormat : Utils.timeFormat) : _msg("general.notAvailable")}</Label>}>
                    <Popup.Header><Icon name="database" />{_msg("EquipmentResource.updated.label")}</Popup.Header>
                    <Popup.Content>
                        <Label>{this.props.eq.updated ? moment(this.props.eq.updated).format(Utils.dateTimeFormat) : _msg("general.notAvailable")}</Label>
                    </Popup.Content>
                </Popup>
            </div>
            <div className="flex-container-row flex-wrap flex-center EqResBigInfoArea_available">
                <Label color={this.props.eq.available ? "green" : "grey"} size="large" content={_msg("EquipmentResource.available.true")}></Label>
                <Label color={"grey"} size="large">{_msg("EquipmentResource.available.partial")}</Label>
                <Label color={this.props.eq.available ? "grey" : "red"} size="large">{_msg("EquipmentResource.available.false")}</Label>

            </div>

            <Table celled padded selectable compact striped>
                <Table.Header>
                    <Table.Row textAlign='center'>
                        <Table.HeaderCell key={"name"}>{_msg("MapRealTime.drawer.tableHeader.info")}</Table.HeaderCell>
                        <Table.HeaderCell key={"value"}>{_msg("MapRealTime.drawer.tableHeader.values")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((entity, i) => (
                        <Table.Row key={i}>
                            <Table.Cell key={"name"}><div className="flex-container-row flex-wrap">{entity.icon}{entity.name}</div></Table.Cell>
                            <Table.Cell textAlign='center' key={"value"}>{entity.value}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>;
    }
}

export type TerritoriesFieldRendererSpecificProps = { linkToEquipmentResourceTableWithFilter?: boolean } & StringFieldRendererSpecificProps;
export class TerritoriesFieldRenderer extends FieldDescriptor {
    constructor(name: string) {
        super();
        this.name = name;
        this.type = "territoriesName";
        this.icon = "object ungroup outline";
    }

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps): ReactNode {
        const options = props.rendererSpecificProps as TerritoriesFieldRendererSpecificProps;
        if (Utils.isNullOrEmpty(props.value)) {
            return <Label><span data-tooltip={options?.showTooltip && props.fieldDescriptor.getLabel()} data-position="top center">{_msg("Territory.noTerritory")}</span></Label>
        }

        const content = props.value && props.value.split(",").map((item: string) => {
            const data = item.split("|");
            const id = data[0];
            let url = territoryEntityDescriptor.getEntityEditorUrl(id);
            if (options?.linkToEquipmentResourceTableWithFilter) {
                url = new ShareLinkLogic().createLink(false, equipmentResourceEntityDescriptor, enableAllFilters(Filter.create("territories", FilterOperators.forString.contains, data[1])));
            }
            return data.length === 2 && id ? <NavLink key={id} to={url}><Label data-tooltip={options?.showTooltip && props.fieldDescriptor.getLabel()} data-position="top center" horizontal={!options?.asLabel}>{data[1]}</Label></NavLink> : null;
        });
        return content;
    }
}
fieldTypeToPrimitiveFieldTypeMapping["territoriesName"] = FieldType.string;

export function enableAllFilters(filter: Filter | undefined) {
    if (!filter) { return filter; }
    const copy: Filter = { field: filter.field, operator: filter.operator, value: filter.value, enabled: true };
    if (!filter.filters) { return copy; }
    copy.filters = [];
    for (let childFilter of filter.filters) {
        const childCopy = enableAllFilters(childFilter);
        if (childCopy) {
            copy.filters!.push(childCopy);
        }
    }
    return copy;
}