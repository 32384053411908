import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, BigState, ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, Optional, Organization, PropsFrom, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { DashboardTab, RenderExpandedDashboardParams } from "@crispico/foundation-react/pages/dashboard/dashboardTab/DashboardTab";
import { infoPersonalHomePage, PersonalHomePage, PersonalHomePageProps, slicePersonalHomePage } from "@crispico/foundation-react/pages/PersonalHomePage";
import { organizationEntityDescriptor } from "@crispico/foundation-react/pages/SettingsEntity/settingsEntityDescriptor";
import { loadOrganizationsForMap, loadOrganizationsForMapVariables } from "apollo-gen/loadOrganizationsForMap";
import { OrganizationForMap } from "apollo-gen/OrganizationForMap";
import { InitializationsForClient, MapSettings } from "app";
import { MapContainerLeaflet, MarkerData, MARKER_TYPE, SelectedLayer, sliceMapContainerLeaflet } from "components/MapContainerLeaflet/MapContainerLeaflet";
import gql from "graphql-tag";
import { LOAD_ORGANIZATIONS_FOR_MAP } from "graphql/queries";
import React from "react";
import { Icon, Label, Button } from "semantic-ui-react";
import { airportEntityDescriptor, equipmentResourceEntityDescriptor } from "./EquipmentResource/equipmentResourceEntityDescriptor";
import { push } from "connected-react-router";

export const sliceXopsPersonalHomePage = createSliceFoundation(class SliceXopsHomePage {
    nestedSlices = {
        ...slicePersonalHomePage.nestedSlices,
        mapContainer: sliceMapContainerLeaflet
    }

    initialState = {
        ...slicePersonalHomePage.initialState,
        organizations: [] as OrganizationForMap[]
    }

    reducers = {
        ...slicePersonalHomePage.reducers, ...getBaseReducers<SliceXopsHomePage>(this),
    }

    impures = {
        ...slicePersonalHomePage.impures, ...getBaseImpures<SliceXopsHomePage>(this),

        async loadOrganizations(currentOrganization: Optional<Organization>, mapContainer: Optional<MapContainerLeaflet>) {
            this.getDispatchers().setInReduxState({ organizations: undefined });
            mapContainer?.clearMap();
            const params = currentOrganization ? FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.or, [
                Filter.create("qualifiedName", FilterOperators.forString.equals, currentOrganization.qualifiedName),
                Filter.create("qualifiedName", FilterOperators.forString.like, currentOrganization.qualifiedName + ".%")
            ])) : FindByFilterParams.create();

            const orgs: Optional<OrganizationForMap[]> = (await apolloClient.query<loadOrganizationsForMap, loadOrganizationsForMapVariables>({
                query: LOAD_ORGANIZATIONS_FOR_MAP,
                variables: params, context: { showSpinner: false }
            })).data.organizationService_findByFilter?.results as Optional<OrganizationForMap[]>;
            if (!orgs || orgs.length === 0) {
                return;
            }

            let data: MarkerData[] = [];
            let displayedOrgs: OrganizationForMap[] = [];
            orgs.forEach((org: OrganizationForMap) => {
                if (org.airport && org.airport.longitude && org.airport.latitude) {
                    data.push({ id: org.id, text: currentOrganization ? Utils.substringAfter(org.qualifiedName!, ".") : org.qualifiedName!, point: { longitude: org.airport.longitude, latitude: org.airport.latitude } });
                    displayedOrgs.push(org);
                }
            });
            if (displayedOrgs.length > 0) {
                this.getDispatchers().setInReduxState({ organizations: displayedOrgs });
                mapContainer?.addOrUpdateLayers(data, organizationEntityDescriptor.name);
                mapContainer?.fitBounds(organizationEntityDescriptor.name);
            }
        },
    }
});
export type XopsPersonalHomePageProps = PropsFrom<typeof sliceXopsPersonalHomePage> & PersonalHomePageProps & {
    mapSettings: MapSettings;
};

export class XopsPersonalHomePage extends PersonalHomePage<XopsPersonalHomePageProps> {

    private mapContainerRef = React.createRef<MapContainerLeaflet>();

    constructor(props: XopsPersonalHomePageProps) {
        super(props);
        this.renderMarkerIcon = this.renderMarkerIcon.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.props.dispatchers.loadOrganizations(this.props.currentOrganization, this.mapContainerRef.current);
    }

    renderMarkerIcon(markerData: MarkerData, type: string): React.ReactNode {
        return <Label horizontal size="large" className="flex-container-row flex-center" style={{ width: 'fit-content', backgroundColor: "white" }}><Icon name={airportEntityDescriptor.icon as any} />{markerData.text}</Label>;
    }

    protected onSelectLayer(layer: Optional<SelectedLayer>) {
        if (!layer) {
            return;
        }
        global.currentOrganizationToFilterBy = global.organizations?.find(o => o.id === layer.id);
    }

    protected renderHeader() {
        return <>
            {super.renderHeader()}
            <div className="flex-container MapRealTime_topParent XopsHomePage_map" >
                <MapContainerLeaflet {...this.props.mapContainer} dispatchers={this.props.dispatchers.mapContainer}
                    ref={this.mapContainerRef}
                    pruneClusterMode={false} renderMarkerIcon={this.renderMarkerIcon}
                    bingAPIKey={this.props.mapSettings.bingAPIKey} mapId={"homepage_map"}
                    layers={{ [organizationEntityDescriptor.name]: { layerType: MARKER_TYPE, options: { hideStyleOnSelectedLayer: true, hideStyleOnHoveredLayer: true, hideTooltipOnHoveredLayer: true, flyToSelectedMarker: false } } }}
                    enableGestureHandling
                    onSelectLayer={layer => this.onSelectLayer(layer)}
                />
            </div>
        </>;
    }

    protected renderDashboard() {
        let result = super.renderDashboard();
        if (result) {
            result = React.createElement(MyDashboardTab, result.props);
        }
        return result;
    }
}

infoPersonalHomePage.slice = sliceXopsPersonalHomePage;
infoPersonalHomePage.wrappedComponentClass = XopsPersonalHomePage;
const mapBigStateToPropsSuper = infoPersonalHomePage.mapBigStateToProps;
infoPersonalHomePage.mapBigStateToProps = (state: BigState, props: XopsPersonalHomePageProps) => {
    mapBigStateToPropsSuper!(state, props);
    props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;
};

class MyDashboardTab extends DashboardTab {

    protected renderOrganizationTitleBar(org: Organization) {
        return <div className="flex-container-row flex-center gap5 less-margin-top-bottom">{super.renderOrganizationTitleBar(org)}
            <Button basic color="green"
                onClick={() => {
                    global.currentOrganizationToFilterBy = global.organizations?.find(o => o.id === org.id);
                    this.props.dispatchers.dispatch(push(equipmentResourceEntityDescriptor.getEntityTableUrl() + "/table"));
                }}>{_msg("general.seeMore")}</Button>
        </div>;
    }

}