import { TestUtils } from "@crispico/foundation-react/utils/TestUtils";
import { EntityTablePage, SliceEntityTablePage } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { apolloMock } from "@crispico/foundation-react/entity_crud/EntityTablePage.testStates";
import { zeroTrainingArticle } from "@crispico/foundation-react/zeroTraining/dataStructures";
import { createDemoDescriptor } from "@crispico/foundation-react/entity_crud/stories/EntityTablePageCustomized.stories";
import { createSliceFoundation } from "@crispico/foundation-react/reduxHelpers";
import { AddANDFilter, AddNewCustomQuery, AddORFilter, AddSimpleFilter, AddSort, CustomQueryDetails, EditCustomQuery, EditCustomQueryFilterTab, EditCustomQuerySortTab, EditSimpleFilter, EditSortFromSortTab, ErrorOnTryingToAddaSortWithoutField, ErrorOnTryingToAddInvalidFilter, MoreSortsAdded, OpenCustomQueryDropdown, SearchCustomQuery, SelectCustomQuery1, SortAdded, SortDeleted, SortEditorOpened, SortEditorOpenedToEditSort, SortMovedRight, TableInitial } from "./testStates";
import { joyrideDecoratorParams } from "@crispico/foundation-react/zeroTraining/JoyrideDecorator";

const myApolloMock = {
    customQueryService_findByFilter: { results: [], totalCount: 0 },
    ...apolloMock
};

export default zeroTrainingArticle("CustomQuery.label", "CustomQueryBar.label");

const o = { mockApolloClient: myApolloMock, additionalProps: { zeroTrainingMode: true }, frozenReducer: true }

// CustomQuery

const employeeEntityDescriptor = createDemoDescriptor();
const sliceEntityTableEmbed = createSliceFoundation(SliceEntityTablePage).setEntityDescriptor(employeeEntityDescriptor);

export const SnapTable = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, TableInitial, o);
SnapTable.story = {
    name: "Table",
    parameters: joyrideDecoratorParams("CustomQuery.label", ["", ""])
}

const param = SnapTable.story.parameters;

export const SnapOpenCustomQueryDropdown = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, OpenCustomQueryDropdown, o)
SnapOpenCustomQueryDropdown.story = {
    name: "Custom Query Dropdown",
    parameters: param
}

export const SnapSelectCustomQuery = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, SelectCustomQuery1, o)

export const SnapSearchCustomQuery = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, SearchCustomQuery, o)

export const SnapCustomQueryDetails = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, CustomQueryDetails, o)
SnapCustomQueryDetails.story = {
    name: "Custom Query Details",
    parameters: param
}

export const SnapEditCustomQuery = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, EditCustomQuery, o)
SnapEditCustomQuery.story = {
    name: "Custom Query Editor",
    parameters: param
}

export const SnapEditCustomQuerySortTab = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, EditCustomQuerySortTab, o)

SnapEditCustomQuerySortTab.story = {
    name: "Custom Query Editor - Sort Tab",
    parameters: param
}

export const SnapEditSortFromSortTab = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, EditSortFromSortTab, o)

export const SnapErrorOnTryingToAddaSortWithoutField = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, ErrorOnTryingToAddaSortWithoutField, o)

export const SnapSortAdded = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, SortAdded, o)

export const SnapEditCustomQueryFilterTab = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, EditCustomQueryFilterTab, o)
SnapEditCustomQueryFilterTab.story = {
    name: "Custom Query Editor - Filter Tab",
    parameters: param
}

export const SnapEditSimpleFilter = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, EditSimpleFilter, o)
SnapEditSimpleFilter.story = {
    name: "Filter form",
    parameters: param
}
export const SnapErrorOnTryingToAddInvalidFilter = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, ErrorOnTryingToAddInvalidFilter, o)

export const SnapAddSimpleFilter = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, AddSimpleFilter, o)

export const SnapAddORFilter = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, AddORFilter, o)

export const SnapAddANDFilter = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, AddANDFilter, o)

export const SnapAddNewCustomQuery = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, AddNewCustomQuery, o)

// // Sort

export const SnapSortEditorOpened = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, SortEditorOpened, o)
SnapSortEditorOpened.story = {
    name: "Sort Editor",
    parameters: param
}

export const SnapAddSort = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, AddSort, o)

export const SnapMoreSortsAdded = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, MoreSortsAdded, o)

export const SnapSortEditorOpenedToEditSort = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, SortEditorOpenedToEditSort, o)

export const SnapSortMovedRight = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, SortMovedRight, o)

export const SnapSortDeleted = () => TestUtils.wrapWithStoreForSlice(sliceEntityTableEmbed, EntityTablePage, SortDeleted, o)
