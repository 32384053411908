import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { CrudFormInEditor } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { ADD, EntityEditorPage, EntityEditorPageProps } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { ENTITY, FIELDS_WRITE, Utils } from "@crispico/foundation-react/utils/Utils";
import React from "react";
import { Button, Checkbox, CheckboxProps, Icon } from "semantic-ui-react";
import { FormValues, PasswordEditor } from "./PasswordEditor";

export class UserEditorPage<P extends EntityEditorPageProps = EntityEditorPageProps> extends EntityEditorPage<P> {
    GENERATE_SPECIAL_PASSWORD: String = "GENERATE SPECIAL PASSWORD";
    editorFormSimpleClass = UserFormInEditor;
    state = { editPasswordEnabled: false, generatePasswordEnabled: false };
    refPasswordEditor = React.createRef<PasswordEditor>();

    constructor(props: P) {
        super(props);
        this.editPasswordChangeHandler = this.editPasswordChangeHandler.bind(this);
        this.generatePasswordChangeHandler = this.generatePasswordChangeHandler.bind(this);
    }

    editPasswordChangeHandler() {
        // Can not deactivate the pasword editor on new user creation. Setting the password is required
        if (this.props.match?.params.id == ADD) {
            return;
        }
        this.setState((state: any, props) => { return { editPasswordEnabled: !state.editPasswordEnabled }; });
    }

    generatePasswordChangeHandler(event: any, data: CheckboxProps) {
        this.setState({ generatePasswordEnabled: data.checked });
    }

    protected onMatchChanged(match: any) {
        super.onMatchChanged(match);
        this.resetPasswordEditorState();
    }

    protected resetPasswordEditorState() {
        if (this.props.match?.params.id == ADD) {
            // When creating a new user editing the password is required
            this.setState({ editPasswordEnabled: true, generatePasswordEnabled: true });
        } else {
            this.setState({ editPasswordEnabled: false, generatePasswordEnabled: false });
        }
    }

    isPasswordEditingAuthorized() {
        return AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.join([ENTITY, this.props.dispatchers.getSlice().entityDescriptor.name, FIELDS_WRITE, "newPassword"]));
    }

    /**
     * Avoid saving if there are any validation errors for the new password inserted by the user
     */
    protected async onSave() {
        const passwordEditor: PasswordEditor = this.refPasswordEditor.current!;
        if (this.state.editPasswordEnabled && !this.state.generatePasswordEnabled) {
            // If we don't call this some validation errors could be skipped 
            await passwordEditor.submitForm();
            // Invalid new password
            if (passwordEditor.hasErrors()) {
                return;
            }
        }
        await super.onSave();
        // reset new password and its editors after each succesfull save
        this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, newPassword: null } });
        if (passwordEditor) {
            // When editing an existing user the editPasswordEnabled is false at the begining so 
            // the password editor is not created till first time the user clicks the edit password button
            this.resetPasswordEditorState();
            passwordEditor.formikContext.resetForm();
        }
    }

    getEntityValuesFromForm() {
        // We need to copy the object because of:
        // TypeError: Cannot add property newPassword, object is not extensible
        var entityValues: any = {
            ...super.getEntityValuesFromForm()
        };

        if (this.state.editPasswordEnabled && this.isPasswordEditingAuthorized()) {
            if (this.state.generatePasswordEnabled) {
                entityValues.newPassword = this.GENERATE_SPECIAL_PASSWORD;
            } else {
                entityValues.newPassword = (this.refPasswordEditor.current!.formikContext.values as FormValues).newPassword;
            }
        }
        return entityValues;
    }
    /**
     * Because the newPassword is not in the field descriptors in order to avoid showing it in the form
     * the super.onSaveInternal() ignores it. So we manually specify the fields to save
     */
    protected async onSaveInternal() {
        const ed = this.props.dispatchers.getSlice().entityDescriptor;
        // All fields defined in the descriptor + newPassword field
        await this.props.dispatchers.save(this.props.entity, true, [...Object.keys(ed.fields).filter(f => ed.fields[f].enabled), "newPassword"]);
    }
  
};

export class UserFormInEditor extends CrudFormInEditor {

    protected isGeneratePasswordCheckBoxShown() {
        return true;
    }

    protected hasInputForCurrentPassword() {
        return false;
    }

    render() {
        const editor = this.props.editor as UserEditorPage;
        return <>
            {super.render()}
            {editor.isPasswordEditingAuthorized() ? <>
            {/*render passwordEditor component*/}
                <Button className="UserEditorPage_additionalField" icon labelPosition='left'
                    onClick={editor.editPasswordChangeHandler} active={editor.state.editPasswordEnabled}>
                    <Icon name={editor.state.editPasswordEnabled ? "check square outline" : "square outline"} />
                    {_msg("UserEditorPage.edit.password")}
                </Button>
                <div className={editor.state.editPasswordEnabled ? "UserEditorPage_passwordEditor_enabled" : "UserEditorPage_passwordEditor_disabled"}> 
                    <PasswordEditor className="UserEditorPage_additionalField" hasInputForCurrentPassword={this.hasInputForCurrentPassword()}
                        ref={editor.refPasswordEditor} disabled={editor.state.generatePasswordEnabled}></PasswordEditor>
                    {this.isGeneratePasswordCheckBoxShown() ?
                        <Checkbox className="UserEditorPage_additionalField" style={{ fontWeight: 'bold' }}
                            checked={editor.state.generatePasswordEnabled}
                            onChange={editor.generatePasswordChangeHandler}
                            label={_msg("UserEditorPage.generate.password")} /> : null}
                </div>
            </>: null}
        </>
    }
}
