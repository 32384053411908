import { FilterOperators } from "@crispico/foundation-gwt-js";
import { addEntityDescriptor, createSliceFoundation, EntityDescriptor, getBaseImpures, getBaseReducers, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { TerritoriesFieldRenderer } from "pages/EquipmentResource/EquipmentResourceUtils";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";

export const detectionEventEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "DetectionEvent",
    miniFields: ["id"],
    defaultSort: { field: "date", direction: "DESC" },
    defaultFilter: { field: "date", operator: FilterOperators.forDate.today.value, enabled: true },
    icon: "rss"
})
    .isInDefaultColumnConfig(true, "equipmentResource", "detectedBy", "date", "longitude", "latitude", "territories", "enteredTerritories", "exitedTerritories")
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "equipmentResource", type: "EquipmentResource" })
    .addFieldDescriptor({ name: "detectedBy", type: "EquipmentResource" })
    .addFieldDescriptor({ name: "date", type: FieldType.date, format: Utils.dateTimeWithSecFormat, propsForEditor: { hasTime: true } })
    .addFieldDescriptor({ name: "latitude", type: FieldType.double })
    .addFieldDescriptor({ name: "longitude", type: FieldType.double })
    .addFieldDescriptor(new TerritoriesFieldRenderer("territories"))
    .addFieldDescriptor(new TerritoriesFieldRenderer("enteredTerritories"))
    .addFieldDescriptor(new TerritoriesFieldRenderer("exitedTerritories"))
);

export const sliceEditorDetectionEvent = detectionEventEntityDescriptor.infoEditor.slice = createSliceFoundation(class Ext extends SliceEntityEditorPage {

    nestedSlices = {
        ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
    }

    impures = {
        ...sliceEntityEditorPageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        loadSuper: sliceEntityEditorPageOnlyForExtension.impures.load,
        async load(id: any) {
            // do this here to re-write queries with last version of customFields; see getFieldsToRequest()    
            // race condition for initIfNeeded and appMeta.getEntityDescriptorsForServer()   
            this.getSlice().initQueries();
            await this.loadSuper(id);
        }
    }
}).setEntityDescriptor(detectionEventEntityDescriptor);

export const sliceTableDetectionEvent = detectionEventEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
    }
    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        addOneToManyModeFilters(filters: Filter[], oneToManyModeField: string, oneToManyModeEntityDescriptor: EntityDescriptor, oneToManyModeCachedId: any) {
            filters!.push(
                Filter.createComposed(FilterOperators.forComposedFilter.or, [
                    Filter.create("equipmentResource", FilterOperators.forNumber.equals, oneToManyModeCachedId),
                    Filter.create("detectedBy", FilterOperators.forNumber.equals, oneToManyModeCachedId),
                ]));
        }
    }
}).setEntityDescriptor(detectionEventEntityDescriptor);