//@ts-ignore
import { TestUtils, WrapWithStoreOptions } from "@crispico/foundation-react/utils/TestUtils";
import { auditEntityDescriptor } from "@crispico/foundation-react/pages/Audit/auditEntityDescriptor";
import { testState1, testState2, testState3, testState4, testState5, testState6, apolloMock } from "@crispico/foundation-react/pages/Audit/testStates";
import { zeroTrainingArticle } from "@crispico/foundation-react/zeroTraining/dataStructures";
import { joyrideDecoratorParams } from "@crispico/foundation-react/zeroTraining/JoyrideDecorator";

export default zeroTrainingArticle("Audit.label", "Audit.zt");

var options: WrapWithStoreOptions = { mockApolloClient: apolloMock, addRef: true, frozenReducer: true }
const renderListenerDecorator = () => setTimeout(() => options!.extraResults!.pageHelper.componentRef!.current.forceUpdate(), 100);

export const SnapLoadEntities = () => TestUtils.wrapWithStoreForInfo(auditEntityDescriptor.infoTable, testState1, options);
SnapLoadEntities.story = { name: _msg("Audit.zt.elements"),
    parameters: joyrideDecoratorParams("Audit.zt", [
        // TODO by CS: atentie ca mesajul aferent exista inca; proabil trebuie redenumit si refolosit?
        // { shortKey: "0", target: ".tabulator", content: _msg("JoyrideDecorator.audit.loadEntities.tabulator") }, 
        // { shortKey: "1", target: ".tabulator-row-even", content: _msg("JoyrideDecorator.audit.loadEntities.tabulatorRow") }, 
        { shortKey: "2", target: ".actionColumn-106-Employee", content: _msg("JoyrideDecorator.audit.loadEntities.actionColumn") },
        { shortKey: "3", target: ".fieldColumn-106-Employee-lastName", content: _msg("JoyrideDecorator.audit.loadEntities.fieldColumn") }
    ])
};

export const SnapSimpleCheckboxPopup = () => TestUtils.wrapWithStoreForInfo(auditEntityDescriptor.infoTable, testState2, options);
SnapSimpleCheckboxPopup.story = { name: _msg("Audit.zt.elements.1"),
    parameters: { ...joyrideDecoratorParams("Audit.zt", [
        { shortKey: "0", target: ".fieldColumn-104-Employee-stillEmployed", placement:"bottom-start", content: _msg("JoyrideDecorator.audit.checkboxPopup") }
    ]), renderListenerDecorator } 
};

export const SnapSimpleDatePopup = () => TestUtils.wrapWithStoreForInfo(auditEntityDescriptor.infoTable, testState3, options);
SnapSimpleDatePopup.story = { name: _msg("Audit.zt.elements.2"),
    parameters: { ...joyrideDecoratorParams("Audit.zt", [
        { shortKey: "0", target: ".fieldColumn-105-Employee-hireDate", placement:"bottom-start", content: _msg("JoyrideDecorator.audit.datePopup") }
    ]), renderListenerDecorator } 
};

export const SnapSimpleTextPopup = () => TestUtils.wrapWithStoreForInfo(auditEntityDescriptor.infoTable, testState4, options);
SnapSimpleTextPopup.story = { name: _msg("Audit.zt.elements.3"),
    parameters: { ...joyrideDecoratorParams("Audit.zt", [
        { shortKey: "0", target: ".fieldColumn-106-Employee-lastName", placement:"bottom-start", content: _msg("JoyrideDecorator.audit.textPopup") }
    ]), renderListenerDecorator } 
};

export const SnapManyToOneRelationPopup = () => TestUtils.wrapWithStoreForInfo(auditEntityDescriptor.infoTable, testState5, options);
SnapManyToOneRelationPopup.story = { name: _msg("Audit.zt.elements.4"),
    parameters: { ...joyrideDecoratorParams("Audit.zt", [
        { shortKey: "0", target: ".fieldColumn-105-Employee-department", placement:"bottom-start", content: _msg("JoyrideDecorator.audit.manyToOneRelationPopup") }
    ]), renderListenerDecorator } 
};

export const SnapManyToOneRelationWithLabelChangedPopupOpened = () => TestUtils.wrapWithStoreForInfo(auditEntityDescriptor.infoTable, testState6, options);
SnapManyToOneRelationWithLabelChangedPopupOpened.story = { name: _msg("Audit.zt.elements.5"),
    parameters: { ...joyrideDecoratorParams("Audit.zt", [
        { shortKey: "0", target: ".fieldColumn-105-Employee-department", placement:"bottom-start", content: _msg("JoyrideDecorator.audit.manyToOneRelationWithLabelChangedPopup") }
    ]), renderListenerDecorator } 
};