import { EntityDescriptor, EntityTablePage, EntityTablePageProps, createSliceFoundation, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, getBaseReducers, getBaseImpures, PropsFrom } from "@crispico/foundation-react";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import React from "react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";

export class BaggageEntityDescriptor extends EntityDescriptor {
    protected customize() {
        this.isInDefaultColumnConfig(true, "number", "date", "airportCode", "location", "status", "inFlight", "outFlight")

        // as var because it's used in some inner functions
        const baggageEntityDescriptor = this;

        const sliceBaggageTablePage = baggageEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

            nestedSlices = {
                ...sliceEntityTablePageOnlyForExtension.nestedSlices,
            }

            initialState = {
                ...sliceEntityTablePageOnlyForExtension.initialState,
            }

            reducers = {
                ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),
            }

            impures = {
                ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
                
                addOneToManyModeFiltersSuper: sliceEntityTablePageOnlyForExtension.impures.addOneToManyModeFilters,
                addOneToManyModeFilters(filters: Filter[], oneToManyModeField: string, oneToManyModeEntityDescriptor: EntityDescriptor, oneToManyModeCachedId: any) {
                    if (oneToManyModeField === "flight") {
                        filters!.push(Filter.createComposed(FilterOperators.forComposedFilter.or, 
                            [Filter.create("inFlight", FilterOperators.forNumber.equals, oneToManyModeCachedId),
                            Filter.create("outFlight", FilterOperators.forNumber.equals, oneToManyModeCachedId)]));
                    } else {
                        this.addOneToManyModeFiltersSuper(filters, oneToManyModeField, oneToManyModeEntityDescriptor, oneToManyModeCachedId);
                    }
                    
                },
            }
        }).setEntityDescriptor(baggageEntityDescriptor);

        type Props = EntityTablePageProps & PropsFrom<typeof sliceBaggageTablePage>;
        this.infoTable.wrappedComponentClass = class extends EntityTablePage<Props> {

        }
    }
}