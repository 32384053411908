import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClientHolder, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityTablePage } from "@crispico/foundation-react";
import { ClientCustomQuery } from "@crispico/foundation-react/components/CustomQuery/ClientCustomQuery";
import { getCustomQueryFromSessionStorage, loadAndUpdateCustomQuery } from "@crispico/foundation-react/components/CustomQuery/CustomQueryDropdown";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { CrudViewer } from "@crispico/foundation-react/entity_crud/CrudViewer";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { AbstractWidgetWithFilter, AbstractWidgetWithFilterProps, SliceAbstractWidgetWithFilter, sliceAbstractWidgetWithFilterOnlyForExtension, WidgetStatus } from "@crispico/foundation-react/pages/dashboard/AbstractWidgetWithFilter";
import gql from "graphql-tag";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Label, Modal, Popup } from "semantic-ui-react";
import { diagnosticTroubleCodeEntityDescriptor, equipmentResourceEntityDescriptor } from "./equipmentResourceEntityDescriptor";

const MAX_NUMBER_OF_RECORDS_IN_WIDGET = 10;

type DiagnosticTroubleCode = {
    code: string,
    name: String
}

export const sliceDiagnosticTroubleCodeWidget = createSliceFoundation(class SliceDiagnosticTroubleCodeWidget extends SliceAbstractWidgetWithFilter {
    nestedSlices = {}

    initialState = { ...sliceAbstractWidgetWithFilterOnlyForExtension.initialState, entities: [] as DiagnosticTroubleCode[], totalCount: 0, modalOpen: undefined as string | undefined }

    reducers = {
        ...sliceAbstractWidgetWithFilterOnlyForExtension.reducers,
        ...getBaseReducers<SliceDiagnosticTroubleCodeWidget>(this),
    }

    impures = {
        ...getBaseImpures<SliceDiagnosticTroubleCodeWidget>(this),
        async loadData(entity: any) {
            const ed = diagnosticTroubleCodeEntityDescriptor;
            const crudSettingsCQId = ed.entityDescriptorSettings?.defaultCustomQuery ? Number(ed.entityDescriptorSettings?.defaultCustomQuery) : undefined;
            let cq: ClientCustomQuery = getCustomQueryFromSessionStorage("DiagnosticTroubleCode");
            const crudSettingsCQ = crudSettingsCQId ? await loadAndUpdateCustomQuery(crudSettingsCQId, true) : undefined;
            if (cq && cq.id !== -1) {
                if (crudSettingsCQ && cq.id === crudSettingsCQ.id) {
                    cq = crudSettingsCQ;
                }
            } else {
                cq = ed.getDefaultCustomQuery();
                if (crudSettingsCQ) {
                    cq = crudSettingsCQ;
                }
            }
            if (!cq) {
                return;
            }
            const { filter, sorts } = cq.customQueryDefinitionObject;
            const filterWithEntity = { enabled: true, operator: FilterOperators.forComposedFilter.and.value, filters: [filter, { enabled: true, field: "equipmentResource", operator: FilterOperators.forEntityManyToOne.equals.value, value: entity.id }] };
            const query = gql(`query q($params: FindByFilterParamsInput) {
                diagnosticTroubleCodeService_findByFilter(params: $params) {
                    results { name categoryDescription categoryCode severity description code source rawValue timestamp } totalCount
                }
            }`)
            const { data } = await apolloClientHolder.apolloClient.query({
                context: {
                    showSpinner: false
                },
                query: query,
                variables: FindByFilterParams.create().startIndex(0).pageSize(MAX_NUMBER_OF_RECORDS_IN_WIDGET).countMode(true).filter(Filter.eliminateDisabledFilters(filterWithEntity)).sorts(sorts)
            });
            this.getDispatchers().setInReduxState({ entities: data.diagnosticTroubleCodeService_findByFilter.results, totalCount: data.diagnosticTroubleCodeService_findByFilter.totalCount });
        }
    }
});

export type DiagnosticTroubleCodeWidgetProps = PropsFrom<typeof sliceDiagnosticTroubleCodeWidget> & AbstractWidgetWithFilterProps & { entity: any, widgetConfig: { entityType: "EquipmentResource" } };

export class DiagnosticTroubleCodeWidget extends AbstractWidgetWithFilter<DiagnosticTroubleCodeWidgetProps> {
    protected componentDidUpdateInternal(prevProps: DiagnosticTroubleCodeWidgetProps) {
        super.componentDidUpdateInternal(prevProps);
        if (prevProps && !_.isEqual(prevProps.entity, this.props.entity)) {
            this.refresh();
        }
    }

    protected async refresh() {
        if (this.props.status !== WidgetStatus.NONE || !this.props.entity) {
            return;
        }
        this.props.dispatchers.setInReduxState({ status: WidgetStatus.IN_PROGRESS });
        await this.props.dispatchers.loadData(this.props.entity);
        this.props.dispatchers.setInReduxState({ status: WidgetStatus.DONE });
    }

    renderDiagnosticTroubleCode(diagnosticTroubleCode: any) {
        const fields = Object.keys(diagnosticTroubleCodeEntityDescriptor.fields).filter(field => diagnosticTroubleCodeEntityDescriptor.getField(field).isInDefaultColumnConfig);
        const code = diagnosticTroubleCode.code;
        return <>
            <Label style={{ marginBottom: "0.3em" }}><Label circular color="grey">{code}</Label> <a onClick={() => this.props.dispatchers.setInReduxState({ modalOpen: code })}>{diagnosticTroubleCode.name}</a></Label>
            <ModalExt open={code && code === this.props.modalOpen} onClose={() => this.props.dispatchers.setInReduxState({ modalOpen: undefined })}>
                <Modal.Content>
                    <CrudViewer entity={diagnosticTroubleCode} entityDescriptor={diagnosticTroubleCodeEntityDescriptor} fields={fields} />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.props.dispatchers.setInReduxState({ modalOpen: undefined })}>{_msg("general.close")}</Button>
                </Modal.Actions>
            </ModalExt>
        </>;
    }

    renderMain() {
        const tabDescriptor = equipmentResourceEntityDescriptor.tabDescriptors.filter(td => td.oneToManyEntityName === "DiagnosticTroubleCode")[0];
        return <div className="flex-container flex-grow">
            {this.props.entities.map(entity => this.renderDiagnosticTroubleCode(entity))}
            <p style={{ textAlign: "center" }}>{(this.props.totalCount > MAX_NUMBER_OF_RECORDS_IN_WIDGET ? _msg("general.displaying", this.props.entities.length, this.props.totalCount) : _msg("DiagnosticTroubleCode.displaying", this.props.entities.length))  + "."} <Link to={this.props.entity?.id ? entityDescriptors["EquipmentResource"].getEntityEditorUrl(this.props.entity.id) + tabDescriptor.tabRouterPane!.routeProps!.path : entityDescriptors["DiagnosticTroubleCode"].getEntityTableUrl()}>{_msg("general.seeMore")}</Link>.</p>
        </div>;
    }
}
