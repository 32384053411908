import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import React from "react";
import { Button, Header, Input, Label, SemanticCOLORS } from "semantic-ui-react";

export type AdvancedSelectorItem = {
    id: number | string,
    level: number,
    text: string,
    color: SemanticCOLORS | undefined
}

export class AdvancedSelectorState extends State {
    inputValue: string = "";
}

export class AdvancedSelectorReducers<S extends AdvancedSelectorState = AdvancedSelectorState> extends Reducers<S> {
    update(inputValue: string) {
        this.s.inputValue = inputValue;
    }
}
type PropsNotFromState = {
    data: AdvancedSelectorItem[],
    callback: (data: AdvancedSelectorItem) => void,
    renderItemFunction?: (data: AdvancedSelectorItem, callback: (data: AdvancedSelectorItem) => void) => React.ReactNode
};
type Props = RRCProps<AdvancedSelectorState, AdvancedSelectorReducers> & PropsNotFromState;

export class AdvancedSelector extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.onSearchKeyDown = this.onSearchKeyDown.bind(this);
    }

    private renderItem(item: AdvancedSelectorItem) {
        if (item.level === 3 && !item.text?.toUpperCase().includes(this.props.s.inputValue.toUpperCase())) {
            return;
        }
        const result = this.props.renderItemFunction?.call(null, item, this.props.callback);
        if (result) {
            return result;
        }

        switch (item.level) {
            case 1:
                return <Header className="small-margin-bottom" as="h4" key={item.id} color={item.color} content={item.text} />;
            case 2:
                return <Label className="small-margin-bottom" key={item.id} color={item.color}>{item.text}</Label>;
            case 3:
                return <Button compact size="tiny" key={item.id} className="small-margin-bottom" onClick={() => this.props.callback(item)} color={item.color}>{item.text}</Button>;
            default:
                return <></>;
        }
    }

    private onSearchKeyDown(e: any, value: any) {
        if (e.key === 'Enter') {
            const items = this.props.data.filter(item => item.level === 3 && item.text?.toUpperCase().includes(this.props.s.inputValue.toUpperCase()));
            if (items.length === 1) {
                this.props.callback(items[0]);
                this.props.r.update("");
            }
        }
    }

    render() {
        return <div className="flex-grow-shrink-no-overflow">
            <Input fluid autoFocus action={{ color: "green", icon: "search" }} value={this.props.s.inputValue}
                onKeyDown={this.onSearchKeyDown} onChange={e => this.props.r.update(e.target.value)} />
            {this.props.data.map(item => this.renderItem(item))}
        </div>
    }
}

export const AdvancedSelectorHOC = ReduxReusableComponents.connectRRC(AdvancedSelectorState, AdvancedSelectorReducers, AdvancedSelector);
