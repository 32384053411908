import gql from "graphql-tag";

export const TRACKS_FOR_HISTORICAL_MAP = gql`
    fragment HistoryTrackForForHistoricalMap on HistoryTrack {
        id startTime endTime color
    }
`;

export const POSITION_FOR_HISTORICAL_MAP = gql`
    fragment PositionForHistoricalMap on Position {
        id latitude longitude date plateNumber speed gpsProvider telemetry
    }
`;

export const POSITION = gql`
    fragment Position on Position {
        id latitude longitude date plateNumber speed dateProcessed gpsProvider telemetry accuracy engineState odometer motorHour
    }
`;

export const HMA_ENTRY_FOR_HISTORICAL_MAP = gql`
    fragment HistoricalMapAnalysisEntryForHistoricalMap on HistoricalMapAnalysisEntry {
        id equipment {id identifier equipmentType {id name} plateNumber } position
    }
`;

export const LOAD_TRACKS_FOR_HISTORICAL_MAP = gql`
query loadTracksForHistoricalMap($params: FindByFilterParamsInput) { 
    historyTrackService_findByFilter(params: $params) {
        results  { ...HistoryTrackForForHistoricalMap } 
    }
}
${TRACKS_FOR_HISTORICAL_MAP}
`
export const LOAD_POSITIONS_FOR_HISTORICAL_MAP = gql`
query loadPositionsForHistoricalMap($params: FindByFilterParamsInput) {
    positionService_findByFilter(params: $params) {
        results {...PositionForHistoricalMap}
    }  
}
${POSITION_FOR_HISTORICAL_MAP}
`

export const LOAD_POSITIONS = gql`
query loadPositions($params: FindByFilterParamsInput) {
    positionService_findByFilter(params: $params) {
        results {...Position}
    }  
}
${POSITION}
`

export const LOAD_HMA_ENTRY_FOR_HISTORICAL_MAP = gql`
query loadHMAEntryForHistoricalMap($params: FindByFilterParamsInput) {
    historicalMapAnalysisEntryService_findByFilter(params: $params) {
        results {...HistoricalMapAnalysisEntryForHistoricalMap}
    }  
}
${HMA_ENTRY_FOR_HISTORICAL_MAP}
`;

// when the entity delete will be implemented
// we will delete this
export const REMOVE_HMA_ENTRY_FROM_DATABASE = gql`
mutation deleteHistoricalMapAnalysisEntry($id: Long) {
	historicalMapAnalysisEntryService_deleteById(id: $id)
}
`;

// when the fields to update from save will be implement
// there will be no need for this
export const UPDATE_POSITION_FOR_HMA_ENTRY = gql`
mutation historicalMapEntry_updatePosition($id: Long, $newPosition: Long) {
	historicalMapAnalysisEntryService_updatePosition(id: $id, newPosition: $newPosition) {id position}
}
`;

export const HMA_ENTRY_CREATE_NEW = gql`
mutation historicalMapEntry_newEntity($position:Long, $equipmentId:Long, $historicalMapAnalysisId:Long) {
    historicalMapAnalysisEntryService_createNewEntity(position: $position, historicalMapAnalysisId: $historicalMapAnalysisId, equipmentId: $equipmentId) 
    {...HistoricalMapAnalysisEntryForHistoricalMap}
}
${HMA_ENTRY_FOR_HISTORICAL_MAP}
`;