import { Optional } from "@crispico/foundation-react";
import { ResponsiveLineExt, ResponsiveLineExtProps } from "@crispico/foundation-react/components/nivoExt";
import { Serie, Datum, DatumValue, PointSymbolProps, PointMouseHandler } from "@nivo/line";
import lodash from 'lodash';
import React, { ReactElement } from "react";
import { Zoom } from "@crispico/foundation-react/components/Zoom/Zoom";
import { LineProps } from "@nivo/line";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

interface StartEndValues { inside: { x: number, y: number }, outside: { x: number, y: number } };

export class ResponsiveLineChartState extends State {
    // calculated based on this.props.initialSeries (min, max)
    // initialStartTime: 0 as number,
    // initialEndTime: 0 as number,
    // calculated each time a zoom in/out or change is done
    series = [] as Serie[];
    startDate = undefined as number | undefined;
    endDate = undefined as number | undefined;

    chartPaddingLeft = 0 as number;

    minYScale = undefined as number | undefined;
    maxYScale = undefined as number | undefined;
}

export class ResponsiveLineChartReducers<S extends ResponsiveLineChartState = ResponsiveLineChartState> extends Reducers<S> {
    changeSeries(initialSeries: Serie[], scaleYField?: string | number, curve?: LineProps["curve"]) {
        if (!this.s.startDate || !this.s.endDate) {
            return; 
        }
        this.s.series = [];
        let shouldSetYScaleMin = false;
        for (let i = 0; i < initialSeries.length; i++) {
            const newSerie = { ...initialSeries[i] };
            const data: Datum[] = [];

            // trying to get the points which are closest to the start/end of the interval:
            // 1 outside + 1 inside at the start; 1 inside + 1 outside at the end
            const startValues: StartEndValues = { inside: { x: this.s.endDate, y: Number.MIN_SAFE_INTEGER }, outside: { x: -1, y: Number.MIN_SAFE_INTEGER } };
            const endValues: StartEndValues = { inside: { x: this.s.startDate, y: Number.MIN_SAFE_INTEGER }, outside: { x: Number.MAX_SAFE_INTEGER , y: Number.MIN_SAFE_INTEGER } };
            initialSeries[i].data.forEach(value => {
                if (value.x! >= this.s.startDate! && value.x! <= this.s.endDate!) {
                    if (value.x! < startValues.inside.x) {
                        startValues.inside.x = value.x as number;
                        startValues.inside.y = value.y as number;
                    } else if (value.x! > endValues.inside.x) {
                        endValues.inside.x = value.x as number;
                        endValues.inside.y = value.y as number;
                    }
                    data.push(value);
                } else if (value.x! < this.s.startDate! && value.x! > startValues.outside.x) {
                    startValues.outside.x = value.x as number;
                    startValues.outside.y = value.y as number;
                } else if (value.x! > this.s.endDate! && value.x! < endValues.outside.x) {
                    endValues.outside.x = value.x as number;
                    endValues.outside.y = value.y as number;
                }
            });

            if (curve === "monotoneX") {
                // then building a line function to approximate the "missing" point at the exact
                // start/end of the interval, so that the lines fill the entire chart
                let y = null;
                if (startValues.outside.x > -1) {
                    const m = (startValues.inside.y - startValues.outside.y) / (startValues.inside.x - startValues.outside.x);
                    const c = startValues.inside.y - m * startValues.inside.x;
                    y = m * this.s.startDate + c;
                } else {
                    shouldSetYScaleMin = true;
                }
                data.unshift({
                    id: "start",
                    x: this.s.startDate,
                    y: y ? Math.round(y * 100) / 100 : y,
                    serieId: newSerie.id
                });
                y = null;
                if (endValues.outside.x < Number.MAX_SAFE_INTEGER) {
                    const m = (endValues.inside.y - endValues.outside.y) / (endValues.inside.x - endValues.outside.x);
                const c = endValues.inside.y - m * endValues.inside.x;
                    y = m * this.s.endDate + c;
                } else {
                    shouldSetYScaleMin = true;
                }
                data.push({
                    id: "end",
                    x: this.s.endDate,
                    y: y ? Math.round(y * 100) / 100 : y,
                    serieId: newSerie.id
                });
            } else {
                if (startValues.outside.x > -1) {
                    data.unshift({
                        id: "start",
                        x: this.s.startDate,
                        y: startValues.outside.y,
                        serieId: newSerie.id
                    });
                }
                if (endValues.outside.x < Number.MAX_SAFE_INTEGER) {
                    data.push({
                        id: "end",
                        x: this.s.endDate,
                        y: endValues.outside.y,
                        serieId: newSerie.id
                    });
                }
            }

            newSerie.data = data;
            this.s.series.push(newSerie);
        }
        if (scaleYField) {
            this.setYScaleMinMax(scaleYField);
        } else if (shouldSetYScaleMin && curve === "monotoneX") {
            this.setYScaleMinMax(this.s.series[0].id);
        }
    }

    setYScaleMinMax(serieId: string | number): { min: number, max: number } | undefined {
        let serieFound = undefined;
        for (const serie of this.s.series) {
            if (serie.id === serieId) {
                serieFound = serie;
                break;
            }
        }
        if (!serieFound || serieFound.data.length === 0) {
            return;
        }
        // find min max
        let max: Optional<DatumValue> = serieFound?.data[0].y;
        let min: Optional<DatumValue> = serieFound?.data[0].y;
        for (const dat of serieFound?.data!) {                
            if (dat.y === undefined || dat.y === null) {
                continue;
            }
            const y = Number(dat.y);
            if (max === undefined || max === null || (max < y)) {
                max = y;
            }
            if (min === undefined || min === null || (min > y)) {
                min = y;
            }
        }          
        if (min === undefined || min === null || max === undefined || max === null) {
            return;
        }
        this.s.minYScale = Number(min);
        this.s.maxYScale = Number(max);           
    }
}

export type ResponsiveLineChartProps = {
    initialSeries: Serie[],
    initialStartDate: number,
    initialEndDate: number,
    currentStartDate: number,
    currentEndDate: number,
    hasZoomSlider?: boolean,
    scaleYField?: string, // the serie.id that will be used as reference to set the min & max for Y scale
    curve?: LineProps["curve"],
    parentHeight?: number,
    sliderTooltipFormat?: (value: number) => string,
    onRangeChange?: (startDate: number, endDate: number) => void,
    pointSymbol?: (props: Readonly<PointSymbolProps>) => ReactElement,
    onClick?: PointMouseHandler,
} & ResponsiveLineExtProps;
// } & Omit<ResponsiveLineExtProps, "data">;

type Props = RRCProps<ResponsiveLineChartState, ResponsiveLineChartReducers> & ResponsiveLineChartProps;

export class ResponsiveLineChart extends React.Component<Props> {

    static defaultProps = {
        hasZoomSlider: true
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    private componentDidUpdateInternal(prevProps?: Props) {
        const { props } = this;
        if (!prevProps || !lodash.isEqual(prevProps.initialStartDate, props.initialStartDate)
            || !lodash.isEqual(prevProps?.initialEndDate, props.initialEndDate)) {
            props.r.setInReduxState({ startDate: props.initialStartDate, endDate: props.initialEndDate });
        }
        if (!prevProps || !lodash.isEqual(prevProps.currentStartDate, props.currentStartDate)
            || !lodash.isEqual(prevProps.currentEndDate, props.currentEndDate)) {
                props.r.setInReduxState({ startDate: props.currentStartDate, endDate: props.currentEndDate });
        }
        if (prevProps && (!lodash.isEqual(prevProps.s.startDate, props.s.startDate) || !lodash.isEqual(prevProps?.s.endDate, props.s.endDate))) {     
            props.r.changeSeries(props.initialSeries, props.scaleYField, props.curve);        
            props.onRangeChange?.call(null, props.s.startDate!, props.s.endDate!);
        }
        if (!lodash.isEqual(prevProps?.initialSeries, props.initialSeries)) {
            props.r.changeSeries(props.initialSeries, props.scaleYField, props.curve);
        }
    }

    private onSliderBtnResize(width: number | undefined) {
        if (width) {
            this.props.r.setInReduxState({ chartPaddingLeft: width });
        }
    }

    private getHeightBasedOnParent() {
        if (!this.props.parentHeight) {
            return 250; // default height
        }
        if (this.props.hasZoomSlider) {
            return this.props.parentHeight - 32; // subtracting the height of the zoom
        }
        return this.props.parentHeight;
    }

    render() {
        const props = this.props;
        return <div className="flex-container" style={{ height: Math.floor(this.getHeightBasedOnParent()) }}>
            <ResponsiveLineExt margin={{ top: 20, bottom: 60, left: props.hasZoomSlider ? props.s.chartPaddingLeft + 10 : 0 }} 
                {...props} data={props.s.series} />
            {props.hasZoomSlider && props.s.startDate && props.s.endDate && props.s.startDate < props.s.endDate &&
                <Zoom
                    initialStartDate={props.initialStartDate} initialEndDate={props.initialEndDate}
                    currentStartDate={this.props.currentStartDate} currentEndDate={this.props.currentEndDate}
                    onRangeChange={(startDate, endDate) => {
                        props.r.setInReduxState({ startDate: startDate, endDate: endDate })
                    }}
                    onButtonAreResize={(width) => this.onSliderBtnResize(width)}
                    sliderTooltipFormat={props.sliderTooltipFormat} />
            }
        </div>;
    }
}

export const ResponsiveLineChartRRC = ReduxReusableComponents.connectRRC(ResponsiveLineChartState, ResponsiveLineChartReducers, ResponsiveLineChart);