import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Grid, Icon, SemanticICONS } from "semantic-ui-react";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";

type Props = { zeroTrainingMode: boolean, buttonBarRef: any, id: string, entity: any };

export class VehicleInformationWidget extends React.Component<Props> {

    private renderRow(value: any, message: string, icon?: ReactNode) {
        return <Grid.Row><div className="VehicleInformationWidget_GridRow">
            <div className="VehicleInformationWidget_Icon">
                {icon}
            </div>
            <div className="VehicleInformationWidet_Value">
                <div>{message}</div>
                <div>{value}</div>
            </div>
        </div></Grid.Row>
    }

    render() {       
        const odometerFd = equipmentResourceEntityDescriptor.getField('odometer');        
        const closestAddressFd = equipmentResourceEntityDescriptor.getField('closestAddress');        
        const engineHoursFd = equipmentResourceEntityDescriptor.getField('engineHours');
        const updatedFd = equipmentResourceEntityDescriptor.getField('updated');
        try {
            return <div className="flex-container-row flex-center flex-grow-shrink-no-overflow"><Grid centered container columns="2" padded >
                <Grid.Column>                   
                    {this.renderRow(odometerFd.renderField(this.props.entity, {showMeasurementUnit : true}), odometerFd.getLabel(false, false), odometerFd.getIcon())}
                    {this.renderRow(engineHoursFd.renderField(this.props.entity, {showMeasurementUnit : true}), engineHoursFd.getLabel(false, false), engineHoursFd.getIcon())}
                </Grid.Column>
                <Grid.Column> 
                    {this.renderRow(updatedFd.renderField(this.props.entity, {showMeasurementUnit : true}), updatedFd.getLabel(false, false), updatedFd.getIcon())}               
                    {this.renderRow(closestAddressFd.renderField(this.props.entity, {showMeasurementUnit : true}), closestAddressFd.getLabel(false, false), closestAddressFd.getIcon())}
                </Grid.Column>
            </Grid></div>
        } catch (e) {
            return <>{_msg("general.error")}</>
        }
    }
}
