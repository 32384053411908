import React from 'react';
import { createSliceFoundation, getBaseReducers, PropsFrom, StateFrom } from '@crispico/foundation-react/reduxHelpers';
import { Filter } from '@crispico/foundation-react/components/CustomQuery/Filter';
import { FilterOperators } from '@crispico/foundation-gwt-js';
import _ from 'lodash';
import { EntityDescriptor, Optional, Organization, SliceEntityTablePage, TestUtils, Utils } from '@crispico/foundation-react';
import { enableAllFilters, entityDescriptors, getOrganizationFilter } from '@crispico/foundation-react/entity_crud/entityCrudConstants';
import { Container, Dimmer, Header, Loader, Segment } from 'semantic-ui-react';
import Interweave from 'interweave';

const EMPTY_FILTER: Filter = { operator: FilterOperators.forComposedFilter.and.value, filters: [] };
export enum WidgetStatus {
    NONE, ERROR_INCOMPATIBLE_TYPES, IN_PROGRESS, DONE
}

export interface AbstractWidgetWithFilterConfig {
    filter: Filter | undefined;
    entityType: string;
    applyDataExplorerFilterTo: string | undefined;
}

export class SliceAbstractWidgetWithFilter {
    initialState = {
        status: WidgetStatus.NONE as WidgetStatus
    }

    reducers = {
        ...getBaseReducers<SliceAbstractWidgetWithFilter>(this),
    }
}

export const sliceAbstractWidgetWithFilterOnlyForExtension = createSliceFoundation(class extends SliceAbstractWidgetWithFilter {});

export type AbstractWidgetWithFilterProps = PropsFrom<SliceAbstractWidgetWithFilter> & { widgetConfig: AbstractWidgetWithFilterConfig, expandedOrganization?: Organization, dataExplorerFilter?: Filter, dataExplorerEntityDescriptor?: EntityDescriptor };

export abstract class AbstractWidgetWithFilter<T extends AbstractWidgetWithFilterProps> extends React.Component<T> {

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps: any) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected componentDidUpdateInternal(prevProps: T) {
        if (prevProps && (!_.isEqual(prevProps.widgetConfig, this.props.widgetConfig)
            || !_.isEqual(prevProps.dataExplorerFilter, this.props.dataExplorerFilter)
            || !_.isEqual(prevProps.expandedOrganization, this.props.expandedOrganization))) {
           this.refresh();
        }
    }
   
    protected async refreshInternal(filter: Filter) {
        // to be overriden by extending classes
    }

    protected async refresh() {
        if (!this.shouldRefresh()) {
            return;
        }
        this.props.dispatchers.setInReduxState({ status: WidgetStatus.IN_PROGRESS });
        await this.refreshInternal(this.createFilter());
        this.props.dispatchers.setInReduxState({ status: WidgetStatus.DONE });
    }

    protected shouldRefresh() {
        if (!this.props.dataExplorerFilter && this.props.dataExplorerEntityDescriptor) {
            return false;
        }
        const ed = entityDescriptors[this.props.widgetConfig.entityType];
        if (this.props.dataExplorerEntityDescriptor) {
            if (!this.areTypesCompatible(ed, this.props.dataExplorerEntityDescriptor!)) {
                this.props.dispatchers.setInReduxState({ status: WidgetStatus.ERROR_INCOMPATIBLE_TYPES });
                return false;
            }
        }
        if (TestUtils.storybookMode || (this.props as any).zeroTrainingMode ||
            this.props.status !== WidgetStatus.NONE && this.props.status !== WidgetStatus.DONE) {
            return false;
        }
        return true;
    }

    protected areTypesCompatible(local: EntityDescriptor, dataExplorer: EntityDescriptor) {
        if (local.name === dataExplorer.name) {
            return true;
        }
        const props = this.props;
        if (Utils.isNullOrEmpty(props.widgetConfig.applyDataExplorerFilterTo)) {
            return false;
        }
        const chain = local.getFieldDescriptorChain(props.widgetConfig.applyDataExplorerFilterTo!);
        if (chain[chain.length - 1].getType() !== dataExplorer.name) {
            return false;
        }
        return true;
    }

    protected createFilter() {
        const props = this.props;
        const config = props.widgetConfig;
        const ed = entityDescriptors[config.entityType];
        let dataExplorerFilter = props.dataExplorerFilter;
        if (dataExplorerFilter && props.dataExplorerEntityDescriptor && props.dataExplorerEntityDescriptor.name !== ed.name) {
            dataExplorerFilter = applyDataExplorerFilter(_.cloneDeep(dataExplorerFilter!), config.applyDataExplorerFilterTo!);
        }
        const organizationFilter = ed ? getOrganizationFilter(ed, props.expandedOrganization) : undefined;
        if (organizationFilter) {
            organizationFilter.enabled = true;
        }
        const filter = Filter.eliminateDisabledFilters({ operator: FilterOperators.forComposedFilter.and.value, enabled: true,
            filters: [dataExplorerFilter, organizationFilter, enableAllFilters(config.filter)].filter(f => f !== undefined) as Filter[] }) as Filter;
        return filter ? filter : EMPTY_FILTER;
    }

    protected renderMain() {
        return <></>
    }

    render() {
        if (this.props.status === WidgetStatus.NONE) {
            return <></>;
        } else if (this.props.status === WidgetStatus.ERROR_INCOMPATIBLE_TYPES) {
            const ed = entityDescriptors[this.props.widgetConfig.entityType];
            const type = this.props.dataExplorerEntityDescriptor;
            if (!type) {
                return <></>;
            }
            return <Container>
                <Interweave content={_msg("WidgetWithFilter.error.incompatible", ed.icon, ed.getLabel(), type.icon, type.getLabel())} />
            </Container>;
        } else if (this.props.status === WidgetStatus.IN_PROGRESS) {
            return <Segment className="wh100">
                <Dimmer active><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
            </Segment>
        } else if (this.props.status === WidgetStatus.DONE) {
            return this.renderMain();
        }
    }
    
}

function applyDataExplorerFilter(filter: Filter, applyDataExplorerFilterTo: string) {
    if (filter.field) {
        filter.field = applyDataExplorerFilterTo + "." + filter.field;
    }
    if (filter.filters) {
        filter.filters = filter.filters.map(f => applyDataExplorerFilter(f, applyDataExplorerFilterTo));
    }
    return filter;
}
