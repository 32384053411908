import { DropdownExt, DropdownOption } from "@crispico/foundation-react/components/Dropdown/DropdownExt";
import { getDropdownItemLabel } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DropdownFieldRenderer";
import React from "react";
import { FieldInterval } from "../CrudSettings";
import { FieldEditorProps } from "../fieldRenderersEditors";

export class DropdownFieldEditor extends React.Component<FieldEditorProps> {

    protected getDropdownLabel(fi: FieldInterval): any{
        return getDropdownItemLabel(this.props.fieldDescriptor, fi);
    }

    render() {
        const { props } = this;
        const { fieldDescriptor } = props;
        const options: DropdownOption[] = [];
        const selected: DropdownOption[] = [];
        const value = fieldDescriptor.getFieldValue(props.formikProps.values);

        fieldDescriptor.fieldDescriptorSettings?.fieldIntervals?.forEach(fi => {
            const option = { value: fi.from, label: this.getDropdownLabel(fi) };
            options.push(option);
            if (String(value) === fi.from) {
                selected.push(option);
            }
        });

        return <DropdownExt selectProps={{ autoFocus: props.editorSpecificProps?.autoFocus, isClearable: true, closeMenuOnSelect: true }}
            options={options} allowMultiple={false} selected={selected}
            onChange={(selected: DropdownOption[]) => props.formikProps.setFieldValue(fieldDescriptor.name, selected[0]?.value || null)} />
    }
}

export default DropdownFieldEditor;