import { apolloClientHolder, TestUtils, Utils } from "@crispico/foundation-react";
import { Reducers, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { DatePicker } from "antd";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Segment } from "semantic-ui-react";
import ReactDOM from "react-dom";
import { IMPORT_ENTITIES_FROM_CSV } from "pages/ganttAssignment/queries";

const Timeline = require('@crispico/react-timeline-10000').default;

export interface GanttGroup {
    id: number,
    data: { [key: string]: any }
}

export interface GanttItem {
    start: number,
    end: number,
    row: number,
    key: number,
    data: { [key: string]: any }
}

export interface GanttLayer {
    start: number,
    end: number,
    rowNumber: number,
    style: {}
}

export interface GanttData {
    layers: GanttLayer[],
    groups: GanttGroup[],
    items: GanttItem[]
}

export class AbstractGanttState extends State {
    start: number = moment(Utils.now()).startOf('day').valueOf();
    end: number = moment(Utils.now()).endOf('day').valueOf();
    data: GanttData = { layers: [], groups: [], items: [] };
}

export class AbstractGanttReducers<S extends AbstractGanttState = AbstractGanttState> extends Reducers<S> {
    setStartDate(start: number) {
        this.s.start = start;
    }
    setEndDate(end: number) {
        this.s.end = end;
    }
}

export type AbstractGanttProps = RRCProps<AbstractGanttState, AbstractGanttReducers> & {
    /**
     * Keeps the data in denomalization mode.
     * Shouldn't be enriched with other fields!
     */
    entities?: { [key: string]: { [key: number]: any } },
    hideDatePicker?: boolean,
    hideTopBar?: boolean,
    /**
     * Used to store the HR/ER that shouldn't be displayed 
     * (GanttResources will hide the lines, GanttTasks will calculate if task has mission based on this)
     * This should be replaced with a more general mechanism in the future!
     */
    hideResources?: { [key: string]: number[] },
    /**
     * If set, the topBar component will be displayed on this portal container.
     */
    portalContainerForTopBar?: any
};

export abstract class AbstractGantt extends React.Component<AbstractGanttProps> {

    constructor(props: AbstractGanttProps) {
        super(props);
    }

    changeStartEnd(start: number, end: number) {
        this.props.r.setStartDate(start);
        this.props.r.setEndDate(end);
    }

    static findOne(entityName: string, field: string, value: any, entities: any): any {
        return this.find(entityName, field, value, entities)?.[0] || undefined;
    }

    static find(entityName: string, field: string, value: any, entities: any): any[] {
        if (entities) {
            const map = entities[entityName];
            if (map) {
                return Object.keys(map).filter(key => {
                    const entity = map[Number(key)];
                    return Utils.navigate(entity, field, false, ".") === value;
                }).map(key => map[Number(key)]);
            } else {
                console.log("entities doesn't contain entityName = " + entityName);
            }
        }
        return [];
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: AbstractGanttProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected entitiesChangedHandler() {
        // nop
    }

    protected startEndChangedHandler() {
        // nop
    }

    protected async componentDidUpdateInternal(prevProps?: AbstractGanttProps) {
        if (TestUtils.storybookMode) {
            return;
        }
        // CC: not sure if this works well in r-t mode; should be tested!
        if (!this.props.entities && (!prevProps || prevProps.s.start !== this.props.s.start || prevProps.s.end !== this.props.s.end)) {
            this.startEndChangedHandler();
        } else if (prevProps && (!_.isEqual(this.props.entities, prevProps.entities) || !_.isEqual(this.props.hideResources, prevProps.hideResources))) {
            this.entitiesChangedHandler();
        }
    }

    protected ganttItemRenderer(object: { item: GanttItem }): React.ReactNode {
        return <span className='rct9k-items-inner' style={{ height: "13px" }} />;
    }

    protected getTableColumns(): any {
        return [];
    }

    protected renderTopBar(): React.ReactNode {
        return <></>;
    }

    render() {
        const { props } = this;
        return <div className="flex-container flex-grow less-padding">
            {this.props.portalContainerForTopBar 
            ? ReactDOM.createPortal(this.renderTopBar(), this.props.portalContainerForTopBar) 
            : !this.props.hideTopBar ? <Segment className="less-padding flex-container-row flex-center gap5">
                {!this.props.hideDatePicker ? <DatePicker value={moment(this.props.s.start)} format={Utils.dateFormat}
                    onChange={d => {
                        let dateAsNumber: number = d?.valueOf() as number;
                        props.r.setInReduxState({ start: moment(dateAsNumber).startOf('day').valueOf(), end: moment(dateAsNumber).endOf('day').valueOf() });
                    }} /> : null}
                {this.renderTopBar()}
            </Segment> : null}
            <Timeline
                useMoment={false}
                startDate={props.s.start}
                endDate={props.s.end}
                groups={props.s.data.groups}
                items={props.s.data.items}
                onInteraction={() => { }}
                showCursorTime={false}
                itemRenderer={this.ganttItemRenderer}
                rowLayers={props.s.data.layers}
                itemHeight={20}
                groupOffset={200}
                tableColumns={this.getTableColumns()}
            />
            
        </div>
    }
}

export class CellRenderer extends React.Component<{ group: GanttGroup, columnName: string }> {
    render() {
        return this.props.group.data?.[this.props.columnName] || <></>;
    }
}

