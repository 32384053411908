import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityTableSimple, sliceEntityTableSimple } from "@crispico/foundation-react/entity_crud/EntityTableSimple";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { TestUtils } from "@crispico/foundation-react/utils/TestUtils";
import { ENT_READ, ENT_TABLE, Utils } from "@crispico/foundation-react/utils/Utils";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Redirect } from "react-router-dom";
import { Button, Container, Dropdown, DropdownItemProps, DropdownProps, Header, Icon, Menu, Segment } from "semantic-ui-react";

export enum TriggerType { FIXED_DELAY, FIXED_RATE, CRON }

enum LoadingState { NONE, RESCHEDULE_ALL, REFRESH }

const lastBeginFieldDescriptor = new class extends FieldDescriptor {
    constructor() {
        super();
        this.name = "lastBegin";
        this.type = FieldType.date;
        this.format = Utils.dateTimeWithSecFormat;
    }

    getFieldValue(values: any) {
        if (values?.currentBegin) {
            return values.currentBegin;
        }
        return super.getFieldValue(values);
    }
}

const lastEndFieldDescriptor = new class extends FieldDescriptor {
    constructor() {
        super();
        this.name = "lastEnd";
        this.type = FieldType.date;
        this.format = Utils.dateTimeWithSecFormat;
    }

    getFieldValue(values: any) {
        if (values?.currentBegin) {
            return null;
        }
        return super.getFieldValue(values);
    }
}

const lastDurationFieldDescriptor = new class extends FieldDescriptor {
    constructor() {
        super();
        this.name = "lastDuration";
        this.type = FieldType.string;
    }
    
    getFieldValue(entity: any) {
        return entity;
    }

    getDuration(currentBegin: Date, lastBegin: Date, lastEnd: Date) {
        if (currentBegin || !lastBegin || !lastEnd) {
            return "";
        }

        let seconds = moment.duration(moment(lastEnd).diff(moment(lastBegin))).asSeconds()
        const hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        const minutes = Math.floor(seconds / 60);
        seconds = Math.floor(seconds - minutes * 60);

        return (hours > 0 ? hours + " h " : "") + (minutes > 0 ? minutes + " min " : "") + (seconds >= 0 ? seconds + " sec" : "");
    }

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps) {
        const entity = props.value;
        return <span>{entity.cancelByUser ? "Cancel by user" : this.getDuration(entity.currentBegin, entity.lastBegin, entity.lastEnd) }</span>
    }
}()

export const scheduledTaskEntityDescriptor = new EntityDescriptor({
    name: "ScheduledTask"
}, false)
    .addFieldDescriptor({ name: "config.uid", type: FieldType.string })
    .addFieldDescriptor({ name: "config.triggerType", type: FieldType.string })
    .addFieldDescriptor({ name: "config.triggerExpression", type: FieldType.string })
    .addFieldDescriptor({ name: "config.task", type: FieldType.string })
    .addFieldDescriptor(lastBeginFieldDescriptor)
    .addFieldDescriptor(lastEndFieldDescriptor)
    .addFieldDescriptor(lastDurationFieldDescriptor)
    .addFieldDescriptor({ name: "currentBegin", type: FieldType.date, propsForEditor: { hasTime: true} })
    .addFieldDescriptor({ name: "nextRun", type: FieldType.date, propsForEditor: { hasTime: true }, format: Utils.dateTimeWithSecFormat })
    
export const sliceScheduledTask = scheduledTaskEntityDescriptor.infoTable.slice = createSliceFoundation(class SliceScheduledTask {

    nestedSlices = {
        tableSimple: sliceEntityTableSimple
    }

    initialState = {
        organizations: [] as DropdownItemProps[],
        selectedOrganization: "",
        loading: LoadingState.NONE,
        scheduledTaskServiceIsActive: true
    }

    reducers = {
        ...getBaseReducers<SliceScheduledTask>(this)
    }

    impures = {
        ...getBaseImpures<SliceScheduledTask>(this),
        
        async scheduledTaskServiceIsActive() {
            const operationName = "organizationService_scheduledTaskServiceIsActive";
            const result: boolean = (await apolloClientHolder.apolloClient.query({ query: gql(`query q { ${operationName} }`), variables: null })).data[operationName];

            this.getDispatchers().setInReduxState({ scheduledTaskServiceIsActive: result });
        },
        async getScheduledTaskServiceOrganizations() {
            if (TestUtils.storybookMode) {
                return;
            }

            await this.scheduledTaskServiceIsActive();

            if (!this.getState().scheduledTaskServiceIsActive) {
                return;
            }

            await this.getDispatchers().setInReduxState({ loading: LoadingState.RESCHEDULE_ALL });

            const operationName = "scheduledTaskFrontendService_scheduledTaskServiceOrganizations";
            const result: string[] = (await apolloClientHolder.apolloClient.query({ query: gql(`query q { ${operationName} }`), variables: null })).data[operationName];

            if (!result || result.length === 0) {
                return;
            }

            await this.getDispatchers().setInReduxState({ selectedOrganization: result[0], organizations: result.map(x => { return { key: x, text: x, value: x}; }), loading: LoadingState.NONE });

            await this.getInfos();
        },
        async getInfos() {
            if (TestUtils.storybookMode) {
                return;
            }

            if (!this.getState().loading) {
                await this.getDispatchers().setInReduxState({loading: LoadingState.REFRESH });
            }

            const operationName = "scheduledTaskFrontendService_infos";
            const result = (await apolloClientHolder.apolloClient.query({ query: gql(`query q($organization: String) {  
                    ${operationName} (organization: $organization) {
                        config { uid triggerType triggerExpression task } cancelByUser lastBegin lastEnd nextRun currentBegin
                    } 
                }`), variables: { organization: this.getState().selectedOrganization } })).data[operationName];

            this.getDispatchers().tableSimple.setInReduxState({entities: result ? result : []});

            await this.getDispatchers().setInReduxState({loading: LoadingState.NONE });
        },
        async scheduleTask(entity: any) {
            const mutation = gql(`mutation q($organization: String, $scheduleTaskConfigId: String) { 
                scheduledTaskFrontendService_scheduleTask(organization: $organization, scheduleTaskConfigId: $scheduleTaskConfigId)
            }`);
            await apolloClientHolder.apolloClient.mutate({ mutation, variables: { organization: this.getState().selectedOrganization, scheduleTaskConfigId: Utils.navigate(entity, ["config", "uid"]) } });

            await this.getInfos();
        },
        async scheduleTasks() {
            const mutation = gql(`mutation q($organization: String) { 
                scheduledTaskFrontendService_scheduleTasks(organization: $organization)
            }`);
            await apolloClientHolder.apolloClient.mutate({ mutation, variables: { organization: this.getState().selectedOrganization } });

            await this.getInfos();
        },
        async runTaskNow(entity: any) {
            let mutation: any;
            let variables: any;

            if (Utils.navigate(entity, ["config", "uid"])) {
                mutation = gql(`mutation q($organization: String, $scheduleTaskConfigId: String) { 
                    scheduledTaskFrontendService_runScheduledTaskNow(organization: $organization, scheduleTaskConfigId: $scheduleTaskConfigId)
                }`);
                variables = { organization: this.getState().selectedOrganization, scheduleTaskConfigId: Utils.navigate(entity, ["config", "uid"]) }
            } else {
                mutation = gql(`mutation q($organization: String, $task: String) { 
                    scheduledTaskFrontendService_runNotScheduledTaskNow(organization: $organization, task: $task)
                }`);
                variables = { organization: this.getState().selectedOrganization, task: entity["task"] }
            }

            await apolloClientHolder.apolloClient.mutate({ mutation, variables });

            await this.getInfos();
        },
        async stopTask(entity: any) {
            const mutation = gql(`mutation q($organization: String, $scheduleTaskConfigId: String) { 
                scheduledTaskFrontendService_stopTask(organization: $organization, scheduleTaskConfigId: $scheduleTaskConfigId)
            }`);
            await apolloClientHolder.apolloClient.mutate({ mutation, variables: { organization: this.getState().selectedOrganization, scheduleTaskConfigId: Utils.navigate(entity, ["config", "uid"]) } });

            await this.getInfos();
        }
    }
});

scheduledTaskEntityDescriptor.infoTable.wrappedComponentClass = class extends TabbedPage<PropsFrom<typeof sliceScheduledTask>> {
    
    constructor(props: PropsFrom<typeof sliceScheduledTask>) {
        super(props);
        
        this.renderContextMenuItems = this.renderContextMenuItems.bind(this);
        this.renderFooter = this.renderFooter.bind(this);

        this.props.dispatchers.getScheduledTaskServiceOrganizations();
    }

    protected renderContextMenuItems(entity: any): React.ReactNode {
        return (<>
            <Menu.Item icon="clock" content={_msg("ScheduledTask.table.reschedule")} onClick={() => this.props.dispatchers.scheduleTask(entity)} />
            <Menu.Item disabled={entity["running"] ? true : false} icon="play" content={_msg("ScheduledTask.table.forceRun")} onClick={() => this.props.dispatchers.runTaskNow(entity)} />
            <Menu.Item disabled={entity["running"] ? false : true} icon="stop" content={_msg("ScheduledTask.table.forceStop")} onClick={() => this.props.dispatchers.stopTask(entity)} />
        </>);
    }

    protected renderFooter() {
        return <span>{_msg("entityCrud.table.totalCount")} <b>{this.props.tableSimple.entities.length}</b></span>;
    }

    protected getTitle(): string | { icon: string | JSX.Element; title: JSX.Element | string; } {
        return { icon: scheduledTaskEntityDescriptor.icon, title: scheduledTaskEntityDescriptor.getLabel() + " [" + _msg("entityCrud.editor.table") + "]" };
    }

    renderHeader() {
        return (<Header as="h2" dividing>
            {typeof scheduledTaskEntityDescriptor.icon === 'string' ? <Icon name={scheduledTaskEntityDescriptor.icon} /> : scheduledTaskEntityDescriptor.icon}
            <Header.Content>
                {scheduledTaskEntityDescriptor.getLabel()}
                <Header.Subheader>{_msg("entityCrud.table.subheader", _msg("entityCrud.editor.save"))}</Header.Subheader>
            </Header.Content>
        </Header>);
    }
    
    renderMain() {
        const permission = Utils.pipeJoin([ENT_TABLE, scheduledTaskEntityDescriptor.name]);
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission)) {
            return <Redirect to={{ pathname: '/error', state: { from: this.props.location, headerMessage: _msg("error.insufficient.rights.title"), errorMessage: _msg("error.insufficient.rights.details", permission) } }} />;
        }
        if (!this.props.scheduledTaskServiceIsActive) {
            // TODO: i18n
            return <Redirect to={{ pathname: '/error', state: { from: this.props.location, headerMessage: "Error", errorMessage: "ScheduledTaskService is not active" } }} />;
        }
        return (
            <Container className="EntityTablePage_container" fluid>
                <Segment className="EntityTablePage_segment flex-container" compact>
                    {this.renderHeader()}
                    <div className="flex-container-row flex-center">
                        <Dropdown className="ScheduledTask_organizationDropdown" value={this.props.selectedOrganization} options={this.props.organizations} selection  
                            clearable={true} selectOnNavigation={false} selectOnBlur={false} scrolling wrapSelection search={true} placeholder={_msg("ScheduledTask.table.organization")}
                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                this.props.dispatchers.setInReduxState({ selectedOrganization : data.value as string});
                                this.props.dispatchers.getInfos(); } } />
                        <Button icon={this.props.loading === LoadingState.REFRESH ? "spinner" : "refresh"} color="blue" content={_msg("ScheduledTask.table.refresh")} onClick={() => this.props.dispatchers.getInfos()} />
                        <Button icon={this.props.loading === LoadingState.RESCHEDULE_ALL ? "spinner" : "clock"} color="blue" content={_msg("ScheduledTask.table.rescheduleAll")} onClick={() => this.props.dispatchers.scheduleTasks()} />
                    </div>
                    <EntityTableSimple {...this.props.tableSimple} dispatchers={this.props.dispatchers.tableSimple}
                        entityDescriptor={scheduledTaskEntityDescriptor}
                        renderContextMenuItems={this.renderContextMenuItems}
                        renderFooter={this.renderFooter}
                        onDoubleClickItem={undefined}
                        columns={scheduledTaskEntityDescriptor.getDefaultColumnConfig().configObject.columns!.filter(column => column.name !== "currentBegin")}
                    />
                </Segment>
            </Container>);
    }
}