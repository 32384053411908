import { Messages } from "@crispico/foundation-gwt-js";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";
import DropdownFieldEditor from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DropdownFieldEditor";
import DropdownFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DropdownFieldRenderer";
import NumberFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/NumberFieldRenderer";
import StringFieldRenderer, { StringFieldRendererSpecificProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/StringFieldRenderer";
import { FIELDS_READ, Utils } from "@crispico/foundation-react/utils/Utils";
import { Formik, FormikProps } from "formik";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { default as lodash, default as _, isArray, lowerCase } from "lodash";
import React, { ReactNode } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { NavLink, NavLinkProps } from "react-router-dom";
//@ts-ignore
import TimezonePicker from 'react-timezone-select';
import { 
    Button, Checkbox, CheckboxProps, Dropdown, DropdownItemProps, DropdownOnSearchChangeData, 
    DropdownProps, Form, Input, InputProps, Label, Modal, Progress, TextArea 
} from "semantic-ui-react";
import { apolloClient } from "../apolloClient";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";
import { COLUMN_DEFAULT_WIDTH } from "../components/ColumnConfig/dataStructures";
import { DatePickerExtFieldEditor } from "../components/DatePickerExt/DatePickerExtFieldEditor";
import { RangePickerExtFieldEditor } from "../components/DatePickerExt/RangePickerExtFieldEditor";
import { DropdownOption } from "../components/Dropdown/DropdownExt";
import { getMessageForField } from "../components/fieldNameContentAssist/FieldNameContentAssist";
import { entityDescriptors, ID } from "./entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "./EntityDescriptor";
import { ColumnConfigConfig, ColumnDefinition } from "./EntityTableSimple";
import { CronFieldEditor } from "./fieldRenderersEditors/CronFieldEditor";
import { SortFieldEditor, SortFieldRenderer } from "./fieldRenderersEditors/EntitySort";
import PieCountColorPaletteFieldEditor from "./fieldRenderersEditors/PieCountColorPaletteFieldEditor";
import PieCountColorPaletteFieldRenderer from "./fieldRenderersEditors/PieCountColorPaletteFieldRenderer";
import { EntityFieldsFieldRenderer, FieldSelector } from "./fieldRenderersEditors/SelectorExt";
import { FieldType } from "./FieldType";
import { FindByStringParams } from "./FindByStringParams";
import { TestUtils } from "../utils/TestUtils";
import { ShortcutRefForTest } from "../testsAreDemo/ShortcutRefForTest";

const QUERY_LIMIT_NUMBER = 10;

//#region 
export interface FieldRendererProps {
    entity: any,
    value: any; // field value
    fieldDescriptor: FieldDescriptor;
    innerEntityDescriptor?: EntityDescriptor;

    /**
     * Each renderer may accept some specific props. There are actually a lot of cases where we want
     * to modify the renderer's behavior only a bit. This way is lighter than having to create a new
     * renderer class which extends the default renderer.
     */
    rendererSpecificProps?: any
}

export type DefaultManyToOneFieldRendererSpecificProps = { url?: string, navLinkProps: Partial<NavLinkProps> } & StringFieldRendererSpecificProps;
export class DefaultManyToOneFieldRenderer extends React.Component<FieldRendererProps> {

    getShowManyToOneCellAsLink() {
        return this.props.fieldDescriptor.showManyToOneCellAsLink;
    }

    render() {
        const options = this.props.rendererSpecificProps as DefaultManyToOneFieldRendererSpecificProps;

        let url = options?.url || this.props.value && this.props.innerEntityDescriptor!.getEntityEditorUrl(this.props.value[ID]);
        const cellContent = Messages.getInstance().maybeTranslateByUser(this.props.innerEntityDescriptor!.toMiniString(this.props.value));
        const content = this.props.value && this.getShowManyToOneCellAsLink() ?
            <NavLink {...options?.navLinkProps} to={url} data-tooltip={options?.showTooltip && this.props.fieldDescriptor.getLabel()} data-position="top center">
                {options?.showIcon && this.props.fieldDescriptor.getIcon()}{cellContent}
            </NavLink> : cellContent;

        return <>{options?.asLabel
            ? <Label style={this.props.fieldDescriptor.getFieldColors(this.props.value)}>{content}</Label>
            : content}
        </>
    }
}

export const DefaultOneToManyFieldRenderer = (props: FieldRendererProps) => <>{props.value && props.value.map((item: any) => <Label key={item[ID]}>{Messages.getInstance().maybeTranslateByUser(props.innerEntityDescriptor!.toMiniString(item))}</Label>)}</>;

class TextFieldRenderer extends React.Component<FieldRendererProps, { modalOpen: boolean }> {

    constructor(props: FieldRendererProps) {
        super(props);
        this.state = { modalOpen: false };
    }

    render() {
        const text = this.props.value ? String(this.props.value) : "";
        return <>
            <span onDoubleClick={(evt) => { this.setState({ modalOpen: true }); evt.stopPropagation() }} >{text}</span>
            <ModalExt open={this.state.modalOpen} closeOnDocumentClick onClose={() => this.setState({ modalOpen: false })} size='tiny'>
                <Modal.Content>
                    <Form className="wh100">
                        <TextArea rows={5} >{text}</TextArea>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.setState({ modalOpen: false })}>Close</Button>
                </Modal.Actions>
            </ModalExt>
        </>
    }
}

export const fieldRenderers: { [key: string]: any } = {
    [FieldType.defaultManyToOne]: DefaultManyToOneFieldRenderer,
    [FieldType.defaultOneToMany]: DefaultOneToManyFieldRenderer,
    [FieldType.number]: NumberFieldRenderer,
    [FieldType.double]: NumberFieldRenderer,
    [FieldType.string]: StringFieldRenderer,
    [FieldType.text]: TextFieldRenderer,
    [FieldType.boolean]: (props: FieldRendererProps) => {
        const checked: boolean = props.value != null && props.value as boolean;
        return <Checkbox checked={checked} />
    },
    [FieldType.date]: DateFieldRenderer,
    [FieldType.color]: (props: FieldRendererProps) => <div className="ColorRenderer" style={{ backgroundColor: props.value ? (props.fieldDescriptor.colorType === "number" ? Utils.convertColorToHex(props.value) : props.value) : undefined }}></div>,
    [FieldType.sort]: SortFieldRenderer,
    [FieldType.password]: (props: FieldRendererProps) => <>***</>,
    [FieldType.entityName]: (props: FieldRendererProps) => <>{props.value ? String(props.value) : ""}</>,
    [FieldType.entityFields]: EntityFieldsFieldRenderer,
    [FieldType.timeZone]: (props: FieldRendererProps) => <>{props.value?.label || ""}</>,
    [FieldType.progress]: (props: FieldRendererProps) => <Progress percent={props.value && !isNaN(props.value) ? props.value : 0} indicating={(props.value && !isNaN(props.value) ? props.value : 0) < 100}
        progress color={(props.value && !isNaN(props.value) ? props.value : 0) == 100 ? 'green' : undefined} />,
    [FieldType.cron]: (props: FieldRendererProps) => <>{props.value}</>,
    [FieldType.dropdown]: DropdownFieldRenderer,
    [FieldType.pieCountColorPalette]: PieCountColorPaletteFieldRenderer
}

//#endregion

//#region 

export interface EditorSpecificProps {
    autoFocus?: boolean
}

export interface FieldEditorProps {
    formikProps: FormikProps<any>;
    fieldDescriptor: FieldDescriptor;
    innerEntityDescriptor?: EntityDescriptor;

    /**
     * Used by the filter form to command the value editor to focus.
     * The "normal" input uses this. It's rather optional for others; some editors may not be focusable.
     * 
     * TODO: not used; to be removed, see #28226
     */
    refForFocus?: React.RefObject<any>,
    /**
     * Each editor may accept some specific props. There are actually a lot of cases where we want
     * to modify the editor's behavior only a bit. This way is lighter than having to create a new
     * editor class which extends the default editor.
     */
    editorSpecificProps?: EditorSpecificProps
}

export interface ManyToOneEditorProps {
    value?: any,
    focus?: boolean,
    placeholder?: any,
    additionalSearchFields?: string[],
    onChange?: (entity: any) => void,
    sortByField?: string
};

export class ManyToOneEditor<P> extends React.Component<P & FieldEditorProps & ManyToOneEditorProps, {
    options: DropdownItemProps[],
    prevSearchQuery: string,
    clearable: boolean,
}> {

    protected dropdownRef = React.createRef<any>();
    protected operationName!: string;
    protected query!: DocumentNode;

    // OneToManyEditor will set this to true
    protected multiple: boolean = false;

    // For children to set this to false if the configQuery is not limited
    protected showHeader: boolean = true;

    constructor(props: P & FieldEditorProps & ManyToOneEditorProps) {
        super(props);
        this.state = { clearable: true, options: props.value ? [{ entity: props.value, key: props.value.id, value: props.value.id, text: this.renderOption(props.value) }] : [], prevSearchQuery: "" };
        this.configQuery = this.configQuery.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps: FieldEditorProps) {
        if (prevProps && prevProps.innerEntityDescriptor !== this.props.innerEntityDescriptor) {
            if (this.multiple) {
                this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, []);
            } else {
                this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, null);
            }
        }
    }

    private prepareMiniFieldForQuery(miniField: string) {
        const fields = miniField.split(".");
        const result = [fields[0]];
        fields.shift();
        fields.forEach(field => {
            result.push("{ " + field);
        });
        fields.forEach(field => {
            result.push("}");
        })
        return result.join(" ");
    }

    protected configQuery() {
        this.operationName = `${lodash.lowerFirst(this.getInnerEntityDescriptor().name)}Service_findByString`;
        this.query = gql(`query q($params: FindByStringParamsInput) { 
            ${this.operationName}(params: $params) {
                ${ID} ${this.getInnerEntityDescriptor().miniFields.map(mf => this.prepareMiniFieldForQuery(mf)).join(" ")} ${this.props.additionalSearchFields?.join(" ") || ""}
            }
        }`);
    }

    protected getInnerEntityDescriptor(): EntityDescriptor {
        if (this.props.innerEntityDescriptor) {
            return this.props.innerEntityDescriptor as EntityDescriptor;
        } else {
            return new EntityDescriptor({ name: 'empty' });
        }
    }

    protected setValues(newValues: any | Array<any>) {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, newValues);
    }

    protected onChange(event: any, data: DropdownProps) {
        let newValue: any | Array<any>;
        if (this.multiple) {
            // data.value is an array of selected items
            const selectedOptions = this.state.options.filter(o => data.value && (data.value as Array<any>).find(item => o.value === item));
            newValue = selectedOptions?.map(item => item?.entity);
        } else {
            const selectedOption = data.value && this.state.options.find(o => o.value === data.value);
            newValue = selectedOption ? selectedOption.entity : null;
        }
        this.setValues(newValue);
        if (this.props.onChange) {
            this.props.onChange(newValue)
        }
    }

    protected onClose() { this.setState({ prevSearchQuery: "" }); }

    protected async performQuery(searchQuery: string) {
        return (await apolloClient.query({ query: this.query, variables: this.createFindByStringParams(searchQuery), context: { showSpinner: false } })).data[this.operationName];
    }

    protected createFindByStringParams(searchQuery: string): FindByStringParams {
        let p = FindByStringParams.create().string(searchQuery).pageSize(QUERY_LIMIT_NUMBER);
        if (this.props.sortByField) {
            p.params.sorts = [{ field: this.props.sortByField as string, direction: "ASC" }];
        }
        return p;
    }

    /**
     * @param data Optional because it's called from "onOpen()"
     */
    async onSearchChange(event: any, data: DropdownOnSearchChangeData | DropdownProps) {
        if (this.getInnerEntityDescriptor().name === 'empty') return;
        const searchQuery = data.searchQuery || "";
        const entities: Array<any> = await this.performQuery(searchQuery);
        const previousSelections = this.props.formikProps.values[this.props.fieldDescriptor.name]?.filter ? this.props.formikProps.values[this.props.fieldDescriptor.name].filter((p: any) => entities.findIndex(e => e.id === p.id) === -1) : [];
        this.setState({
            prevSearchQuery: searchQuery,
            options: (previousSelections ? previousSelections : []).concat(entities).map((entity: any) => ({
                entity, // we need this later, in onChange()
                key: entity.id, // the standard React "key",
                value: entity.id
            }))
        })
    }

    protected renderOption(entity: any): ReactNode {
        return this.getInnerEntityDescriptor().toMiniString(entity) + (this.props.additionalSearchFields ? " " + this.props.additionalSearchFields?.map(field => entity[field]).join(" ") : "");
    }

    render() {
        const { formikProps, fieldDescriptor } = this.props;
        let id: any | Array<any> = 0; // if multiple then we have an arrays of ids
        let options: DropdownItemProps[] = this.state.options.map(option => ({ ...option, text: this.renderOption(option.entity) }));
        let mini : any | Array<any>;

        if (formikProps.values) {
            mini = Utils.navigate(formikProps.values, fieldDescriptor.name, false, ".");
            if (!mini) {
                id = 0;
            } else {
                id = this.multiple ? (mini as Array<any>).map(item => item[ID]) : mini[ID];
                if (this.state.prevSearchQuery === "") {
                    // make sure that among the options we can find also the current value
                    // when the popup is closed: we need this, because otherwise the label is not rendered
                    // when the popup is open: only when the input is empty: force to add the current item; e.g. the list has 1000 elements; so
                    // on client we only have 10 at the beginning; there is a big chance that the current selection IS NOT among the 10; so add
                    // it if it doesn't exist

                    // LA: a problem that i notice with this logic is that we rely on "prevSearchQuery";
                    // for "multiple" = true, we may want just to delete [x] one of the selections but
                    // because we didn't actually have a previous search, we run this code again
                    // and we set the selection to []; if we type in something before we don't lose
                    // all the other selections

                    if (this.multiple) {
                        (mini as Array<any>).forEach(item => {
                            if (!options.find(e => e.value === item[ID])) {
                                options = [{ key: item[ID], value: item[ID], text: this.renderOption(item) }, ...options];
                            }
                        })
                    } else {
                        const currentAmongOptions = options.find(e => e.value === id);
                        if (!currentAmongOptions) { options = [{ key: id, value: id, text: this.renderOption(mini) }, ...options]; }
                    }
                }
            }
        }

        // this was added for initial selected value in a standalone editor
        // NOTE: find a better way when refactoring ManyToOneEditor
        if (options.length === 0 && this.props.value) {
            options = [this.props.value].map(entity => ({
                entity,
                key: entity.id,
                value: entity.id,
                text: this.renderOption(entity)
            }))
        }

        let moreResultsThanTheLimit = false;
        if (this.showHeader && !this.multiple && options.length >= QUERY_LIMIT_NUMBER) {
            options.splice(QUERY_LIMIT_NUMBER, options.length - QUERY_LIMIT_NUMBER);
            moreResultsThanTheLimit = true;
        }
        if (this.showHeader && this.multiple && mini && options.length - (mini as Array<any>).length >= QUERY_LIMIT_NUMBER) {
            options.splice(QUERY_LIMIT_NUMBER, options.length - QUERY_LIMIT_NUMBER);
            moreResultsThanTheLimit = true;
        }

        // NOTE on "options": Dropdown actually is not that clever; it simply iterates on "options", and 
        // for each => it instantiates <DropdownItem {...currentOption}/>
        // NOTE on "search" prop of dropdown: the identity method must be used to ensure that all options are returned correctly cf: https://github.com/Semantic-Org/Semantic-UI-React/issues/3932
        return <>
            {/* this is needed when having 2 fields in a form: 1 that selects the entity type and 1 that selects an entity of that type */}
            <Utils.Observer value={this.getInnerEntityDescriptor().name} didUpdate={this.configQuery}></Utils.Observer>
            <Dropdown data-testid="ManyToOneFieldEditor_dropdown" style={{ minWidth: '150px' }} disabled={!this.props.fieldDescriptor.enabled} ref={this.dropdownRef} fluid floating value={this.props.value ? this.props.value.id : (id !== 0 ? id : null)} options={options} selection clearable={this.state.clearable} selectOnNavigation={false} selectOnBlur={false} scrolling wrapSelection
                search={_.identity} onOpen={this.onSearchChange} onSearchChange={this.onSearchChange} onClose={this.onClose} onChange={this.onChange} placeholder={this.props.placeholder}
                multiple={this.multiple} searchInput={{ autoFocus: this.props.focus || this.props.editorSpecificProps?.autoFocus }} noResultsMessage={_msg("general.noResultsFound")}
                header={moreResultsThanTheLimit ? <Dropdown.Header className='flex-center text-break-spaces'>{_msg("ManyToOneEditor.header", options.length)}</Dropdown.Header> : null} />
        </>
    }
}

// NOTE: there are lots of components that uses this behavior; I didn't understood why they didn't extend this one because lots of lines were copied!
// e.g. OrganizationManyToOneEditorStandalone
type Props = { value?: any, clearable?: boolean, entityName: string, onChange: (entity: any) => void, enabled?: boolean };
export class ManyToOneEditorStandalone extends React.Component<Props> {

    protected fieldDescriptor: FieldDescriptor;
    protected entityDescriptor: EntityDescriptor;
    protected prevValue: any;
    protected givenValue: any;

    constructor(props: Props) {
        super(props);
        const fd = new FieldDescriptor();
        fd.name = "myField"; fd.type = props.entityName;

        this.fieldDescriptor = fd;
        this.entityDescriptor = entityDescriptors[props.entityName];
    }

    protected setValues(newValues: any) {
        this.givenValue = newValues;
        // CC: this was needed if I set initial value from storybook in this editor
        // Note: I think we must refactor this givenValue props, it says as normal field in component,
        // in order to use it, I need to create a ref; maybe we can fing another way to set an initial value 
        if (TestUtils.storybookMode) {
            this.forceUpdate();
        }
    }

    render() {
        return (<Formik initialValues={{} as any} onSubmit={() => 0 as any}  >
            {formikProps => {
                if (this.givenValue) {
                    formikProps.setValues({ "myField": this.givenValue });
                    this.givenValue = null;
                }
                if (this.prevValue !== formikProps.values["myField"]) {
                    this.prevValue = formikProps.values["myField"];
                    this.props.onChange(this.prevValue);
                }
                // value={this.props.value} because the id is retreived from the value in the parent ManyToOneEditor
                return <ManyToOneEditor value={this.props.value} clearable={this.props.clearable} formikProps={formikProps} fieldDescriptor={this.fieldDescriptor} innerEntityDescriptor={this.entityDescriptor} disabled={!this.props.enabled} />
            }}
        </Formik>)
    }
}
//#endregion

//#region
export class OneToManyEditor extends ManyToOneEditor<FieldEditorProps> {

    constructor(props: FieldEditorProps) {
        super(props);
        this.multiple = true;
    }

}
//#endregion

export class StringFieldEditor extends React.Component<FieldEditorProps> {

    protected getValue() {
        const value = this.props.fieldDescriptor.getFieldValue((this.props as FieldEditorProps).formikProps.values);
        return value ? String(value) : "";
    }

    protected setFieldValue(value: string | number | undefined) {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.getFieldName(), value);
    }

    protected onChange = (event: any, data: InputProps) => {
        this.setFieldValue(data.value);
    }

    render = () => {
        const { props } = this;
        return <>
            <ShortcutRefForTest objectToPublish={this}/>
            <Input autoFocus={props.editorSpecificProps?.autoFocus} ref={this.props.refForFocus} name={props.fieldDescriptor.getFieldName()} value={this.getValue()} onChange={this.onChange} />
        </>
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    ////// Test functions
    ////////////////////////////////////////////////////////////////////////////////////////
    tadType = (value: string) => {
        this.setFieldValue(value);
    }
}

// TODO by CS: nu-mi place de loc cum e extinsa clasa parinte
export class NumberFieldEditor extends StringFieldEditor {

    protected getValue() {
        const value = this.props.fieldDescriptor.getFieldValue((this.props as FieldEditorProps).formikProps.values);
        return value || value === 0 ? value : "";
    }

    protected onChange = (event: any, data: InputProps) => {
        this.setFieldValue(event.target.valueAsNumber);
    }

    getInputProps(): Optional<InputProps> {
        return undefined;
    }

    render = () => {
        const fieldName = this.props.fieldDescriptor.getFieldName();
        return <Input autoFocus={this.props.editorSpecificProps?.autoFocus} ref={this.props.refForFocus} type='number' {...this.getInputProps()}
            name={fieldName} value={this.getValue()}
            onChange={this.onChange} />
    }
}

class DoubleFieldEditor extends NumberFieldEditor {

    getInputProps(): Optional<InputProps> {
        return { step: "any" };
    }
}

export class EntityNameFieldEditor extends React.Component<FieldEditorProps> {
    constructor(props: FieldEditorProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // this is needed when we want to pre-fill the selected option
        this.handleChange({} as any, { value: this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values) })
    }

    handleChange(event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, data.value);
    }

    render = () => {
        const value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values);
        let options: any[] = [];
        Object.keys(AppMetaTempGlobals.appMetaInstance.getUIEntityDescriptors()).sort((a, b) => entityDescriptors[a].getLabel() < entityDescriptors[b].getLabel() ? -1 : (entityDescriptors[a].getLabel() > entityDescriptors[b].getLabel() ? 1 : 0)).forEach((name: string) => {
            const ed = entityDescriptors[name];
            options.push({ key: name, text: ed.getLabel(), value: name });
        });
        return <Dropdown data-cy={"entityTypeDropdown"} clearable fluid selection search={true} options={options} onChange={this.handleChange} value={value ? value : ''} />
    }
}

export class BooleanFieldEditor extends React.Component<FieldEditorProps> {

    onChange = (event: any, data: CheckboxProps) => {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.getFieldName(), data.checked);
    }

    render = () => {
        const { formikProps, fieldDescriptor } = this.props;
        const checked: boolean = fieldDescriptor.getFieldValue(formikProps.values) || false;
        return <Checkbox data-testid="BooleanFieldEditor" ref={this.props.refForFocus} checked={checked} onChange={this.onChange} />
    }
}

export class ColumnConfigFieldSelector extends FieldSelector {

    protected getItemsFromValue(value: ColumnConfigConfig): any[] {
        return value ? value.columns! : [];
    }

    protected getOptions() {
        const entityName = this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName];
        const fields = entityDescriptors[entityName].getAuthorizedFields(FIELDS_READ);
        const value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values) as ColumnConfigConfig;
        return Object.keys(fields).filter(field => fields[field].getAppearsInUi()).map(field => {
            const column = value.columns?.find(c => c.name === field);
            return {
                value: field,
                label: fields[field].getLabel(),
                width: column ? column.width : COLUMN_DEFAULT_WIDTH
            }
        });
    }

    protected setComposedField = (composedField: string, value: any) => {
        if (!value.columns.some((e: { width: number, name: string }) => e.name === composedField)) {
            let ccc: ColumnConfigConfig = { columns: [] }
            ccc.columns = value.columns.map((i: { width: any, name: string }) => {
                return { width: i.width, name: i.name }
            })
            ccc.columns!.push({ width: COLUMN_DEFAULT_WIDTH, name: composedField })
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, ccc)
        }
    }

    protected getValue(items: DropdownOption[]): ColumnConfigConfig {
        let ccc: ColumnConfigConfig = { columns: [] }
        ccc.columns = items.map(i => {
            return { width: i.width, name: i.value }
        })
        return ccc;
    }

    protected getDropdownOptionFromItem(column: ColumnDefinition, entityName: string): DropdownOption {
        return { value: column.name, label: `${getMessageForField(column.name, entityName)} [${column.name}]`, width: column.width }
    }
}

//#region 
export const fieldEditors: { [key: string]: any } = {
    [FieldType.double]: DoubleFieldEditor,
    [FieldType.number]: NumberFieldEditor,
    [FieldType.string]: StringFieldEditor,
    [FieldType.text]: ({ formikProps, fieldDescriptor, refForFocus, editorSpecificProps }: FieldEditorProps) => <TextArea autoFocus={editorSpecificProps?.autoFocus} ref={refForFocus} rows={5} name={fieldDescriptor.getFieldName()} value={fieldDescriptor.getFieldValue(formikProps.values) || ""} onChange={formikProps.handleChange} />,
    [FieldType.password]: ({ formikProps, fieldDescriptor, refForFocus, editorSpecificProps }: FieldEditorProps) => <Input autoComplete='off' autoFocus={editorSpecificProps?.autoFocus} ref={refForFocus} type='password' name={fieldDescriptor.getFieldName()} value={fieldDescriptor.getFieldValue(formikProps.values) || ""} onChange={formikProps.handleChange} />,
    [FieldType.timeZone]: ({ formikProps, fieldDescriptor, refForFocus }: FieldEditorProps) => {
        if (!fieldDescriptor.getFieldValue(formikProps.values)) {
            formikProps.setFieldValue(fieldDescriptor.getFieldName(), { value: "GMT", label: "(GMT+0:00) Dublin, Edinburgh, Lisbon, London \n            (GMT)", abbrev: "GMT", altName: "GMT" });
        }
        return <TimezonePicker fluid value={fieldDescriptor.getFieldValue(formikProps.values)}
            onChange={(tz: any) => formikProps.setFieldValue(fieldDescriptor.getFieldName(), tz)} labelStyle={'abbrev'} />
    },
    [FieldType.progress]: ({ formikProps, fieldDescriptor, refForFocus }: FieldEditorProps) => <Progress percent={fieldDescriptor.getFieldValue(formikProps.values) && !isNaN(fieldDescriptor.getFieldValue(formikProps.values)) ? fieldDescriptor.getFieldValue(formikProps.values) : 0}
        indicating={(fieldDescriptor.getFieldValue(formikProps.values) && !isNaN(fieldDescriptor.getFieldValue(formikProps.values)) ? fieldDescriptor.getFieldValue(formikProps.values) : 0) < 100} progress
        success={(fieldDescriptor.getFieldValue(formikProps.values) && !isNaN(fieldDescriptor.getFieldValue(formikProps.values)) ? fieldDescriptor.getFieldValue(formikProps.values) : 0) == 100} />,

    // formikProps.onChange() is not compatible w/ Checkbox.onChange(); the corresponding event doesn't point as expected towards the HTML input, so formik
    // doesn't detect the field name; hence we need to use formikProps.setFieldValue(), i.e. an adaptation. Hence I created a class comp to avoid an inline
    // function (which would be recreated at each render)
    [FieldType.color]: class extends React.Component<FieldEditorProps> {

        state = {
            displayColorPicker: false,
            color: undefined as Optional<string>
        };

        handleClick = () => {
            this.setState({ displayColorPicker: !this.state.displayColorPicker })
        };

        handleClose = () => {
            this.setState({ displayColorPicker: false })
        };

        onChange = (color: ColorResult) => {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, this.prepareValueToSave(color.hex, this.props.fieldDescriptor.colorType))
            this.setState({ color: color.hex })
        };

        getCurrentValue = (): Optional<string | number> => {
            return this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values);
        }

        getColor = (): string => {
            return this.state.color || this.prepareValueToDisplay(this.getCurrentValue());
        }

        prepareValueToDisplay(color: Optional<string | number>): string {
            if (!color) {
                return "#FFFFFF"; // white, if not defined
            }
            if (typeof color === 'number') {
                return Utils.convertColorToHex(color);
            }
            return color;
        }

        prepareValueToSave(color: string | number, requestedType: string) {
            if (typeof color === 'number' && requestedType === 'string') {
                return Utils.convertColorToHex(color);
            }
            if (typeof color === 'string' && requestedType === 'number') {
                return Utils.convertColorFromHex(color);
            }
            return color;
        }

        render() {
            return (
                <div>
                    <div className="ColorPicker_swatch" onClick={this.handleClick}>
                        <div className="ColorPicker_color" style={{ backgroundColor: this.getColor() }} />
                    </div>
                    {this.state.displayColorPicker ? <div className="ColorPicker_popover">
                        <div className="ColorPicker_cover" onClick={this.handleClose} />
                        <SketchPicker disableAlpha color={this.getColor()} onChange={this.onChange} />
                    </div> : null}
                </div>
            )
        }
    },

    [FieldType.columnConfigEntityFields]: ColumnConfigFieldSelector,
    [FieldType.boolean]: BooleanFieldEditor,
    [FieldType.rangeDate]: RangePickerExtFieldEditor,
    [FieldType.date]: DatePickerExtFieldEditor,
    [FieldType.defaultManyToOne]: ManyToOneEditor,
    [FieldType.defaultOneToMany]: OneToManyEditor,
    [FieldType.sort]: SortFieldEditor,
    [FieldType.entityName]: EntityNameFieldEditor,
    [FieldType.entityFields]: FieldSelector,
    [FieldType.cron]: CronFieldEditor,
    [FieldType.dropdown]: DropdownFieldEditor,
    [FieldType.pieCountColorPalette]: PieCountColorPaletteFieldEditor
}
//#endregion