/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Action {
  ADD = "ADD",
  COMBINE = "COMBINE",
  DELETE = "DELETE",
  REFRESH = "REFRESH",
  UPDATE = "UPDATE",
}

export enum AggregateFunctionType {
  AVG = "AVG",
  COUNT = "COUNT",
  MAX = "MAX",
  MIN = "MIN",
  SUM = "SUM",
}

export enum ApuOffStatus {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  UNKNOWN = "UNKNOWN",
}

export enum AuthenticationStatus {
  CANNOT_RELOGIN = "CANNOT_RELOGIN",
  FAILURE = "FAILURE",
  FAILURE_TWO_MANY_FAILED_LOGINS = "FAILURE_TWO_MANY_FAILED_LOGINS",
  SUCCESS = "SUCCESS",
  SUCCESS_WITH_EXPIRED_PASSWORD = "SUCCESS_WITH_EXPIRED_PASSWORD",
}

export enum EngineState {
  IDLE = "IDLE",
  OFF = "OFF",
  RUNNING = "RUNNING",
}

export enum EquipmentResourceStatus {
  NOT_AVAILABLE = "NOT_AVAILABLE",
  OFF = "OFF",
  ON_NOT_OPERATING = "ON_NOT_OPERATING",
  ON_OPERATING = "ON_OPERATING",
}

export interface AggregateFunctionInput {
  field?: string | null;
  type?: AggregateFunctionType | null;
}

export interface CopyInputDataFromDbConfigInput {
  deleteDataBeforeImport: boolean;
  entityName: string;
  filter?: FilterInput | null;
  importData: boolean;
}

export interface FilterInput {
  cast?: string | null;
  field?: string | null;
  filters?: (FilterInput | null)[] | null;
  operator?: string | null;
  value?: string | null;
}

export interface FindByFilterParamsInput {
  aggregateFunctions?: (AggregateFunctionInput | null)[] | null;
  bypassOrganizationFilter?: boolean | null;
  countMode?: boolean | null;
  filter?: FilterInput | null;
  pageSize: number;
  sorts?: (SortInput | null)[] | null;
  startIndex: number;
}

export interface LoginParamsInput {
  loginType?: string | null;
  password: string;
  rememberMe: boolean;
  username: string;
}

export interface Pair_Double_DoubleInput {
  a?: number | null;
  b?: number | null;
}

export interface SaveParams_LongInput {
  duplicateFromId?: any | null;
  fieldsAndValues?: any | null;
  id?: any | null;
}

export interface SortInput {
  direction?: string | null;
  field?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
