// WARNING: if you do "organize imports", make sure that this remains on top of the file!
import "./apollo-gen-crud/entitiesGen";

import { Messages } from "@crispico/foundation-gwt-js";
import { AppMeta, BigState, CompMeta, ConnectedComponentInSimpleComponent, ConnectedPageHelper, ConnectedPageInfo, DEFAULT, DispatchersFrom, EntityDescriptor, FoundationInitializationsForClient, GLOBAL, searchOptions, Utils, Optional, Organization, MenuEntry, PrivateRouteProps, User, AppContainerProps, ENT_TABLE, ENT_READ } from '@crispico/foundation-react';
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import * as customQueryZt from '@crispico/foundation-react/components/CustomQuery/stories';
import { infoTemplateConnectedComponent } from '@crispico/foundation-react/demo/pages/_TemplateConnectedComponent/TemplateConnectedComponent';
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { dashboardEntityDescriptor } from "@crispico/foundation-react/FoundationEntityDescriptors";
import { auditEntityDescriptor } from "@crispico/foundation-react/pages/Audit/auditEntityDescriptor";
import * as auditZt from "@crispico/foundation-react/pages/Audit/stories";
import * as dashboardZt from "@crispico/foundation-react/pages/dashboard/dashboardTab/stories";
import { DashboardWidgetEntityDescriptor, DashboardWidgetFactories, DashboardWidgetFactory, DashboardWidgetType } from "@crispico/foundation-react/pages/dashboard/DashboardWidgetFactory";
import * as chartPieCountByCriteriaZt from "@crispico/foundation-react/pages/dashboard/pieCountByCriteriaTab/stories";
import { entityToTagEntityDescriptor, tagEntityDescriptor } from '@crispico/foundation-react/pages/EntityToTag/entityToTagDescriptor';
import { HomePage, HomePageProps, infoHomePage } from '@crispico/foundation-react/pages/HomePage';
import { infoLoginPage, LoginTypeEnum } from "@crispico/foundation-react/pages/loginPage/LoginPage";
import { notificationEntityDescriptor, problemEntityDescriptor } from "@crispico/foundation-react/pages/notification/notificationEntityDescriptor";
import { infoPasswordResetFinishPage } from "@crispico/foundation-react/pages/passwordReset/PasswordResetFinishPage";
import { infoPasswordResetInitPage } from "@crispico/foundation-react/pages/passwordReset/PasswordResetInitPage";
import { infoPersonalHomePage } from "@crispico/foundation-react/pages/PersonalHomePage";
import { roleEntityDescriptor, roleToUserEntityDescriptor } from "@crispico/foundation-react/pages/role/roleEntityDescriptor";
import * as roleZt from '@crispico/foundation-react/pages/role/stories';
import { scheduledTaskEntityDescriptor } from "@crispico/foundation-react/pages/ScheduledTasks/ScheduledTaskEntityDescriptor";
import { organizationEntityDescriptor, settingsEntityDescriptor } from '@crispico/foundation-react/pages/SettingsEntity/settingsEntityDescriptor';
import { userEntityDescriptor } from "@crispico/foundation-react/pages/user/userEntityDescriptor";
import { zeroTrainingIndexPageMenuEntry } from '@crispico/foundation-react/zeroTraining/ZeroTrainingIndexPage';
import { addressEntityDescriptor, AppEntityDescriptors, blocklyScriptEntityDescriptor, humanResourceEntityDescriptor, qualificationEntityDescriptor, flightEntityDescriptor, missionEntityDescriptor, baggageEntityDescriptor, chartEntityDescriptor, ganttAssignmentEntityDescriptor } from "AppEntityDescriptors";
import { MapWidget, sliceMapWidget } from "components/editorWidgets";
import { MARKER_TYPE } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { CLUSTER_MODE, MAP_CLUSTER_MODE_KEY, Props, RealTimeMap, sliceRealTimeMap } from "components/realTimeMap/RealTimeMap";
import { VehicleInformationWidget } from "components/VehicleInformationWidget";
import { push } from "connected-react-router";
import 'font-awesome/css/font-awesome.min.css';
import * as chartBarsDistanceTimeInTerritoriesZt from "pages/Chart/DistanceTimeInTerritories/BarsDistanceTimeInTerritoriesTab/stories";
import * as chartPieDistanceTimeInTerritoriesZt from "pages/Chart/DistanceTimeInTerritories/PieDistanceTimeInTerritoriesTab/stories";
import * as chartHistogramCountInTerritoriesZt from "pages/Chart/HistogramCountInTerritoriesTab/stories";
import { checklistEntityDescriptor } from 'pages/Checklist/checklistEntityDescriptor';
import { drivingEventEntityDescriptor } from "pages/DrivingEvent/drivingEventEntityDescriptor";
import {
    airportEntityDescriptor, equipmentModelEntityDescriptor, equipmentResourceEntityDescriptor, equipmentTypeEntityDescriptor,
    telemetryEventMappingEntityDescriptor, telemetrySystemEntityDescriptor, unitEntityDescriptor
} from 'pages/EquipmentResource/equipmentResourceEntityDescriptor';
import { EQUIPMENT_RESOURCE_TYPE } from "pages/EquipmentResource/EquipmentResourceUtils";
import { infoErrorPage } from "pages/ErrorPage";
import { eventValidationStatusPageMeta, PAGE_URL as eventValidationPageUrl } from 'pages/EventValidationStatusPage/EventValidationStatusPage';
import "pages/EventValidationStatusPage/testState";
import { flightScheduleEntityDescriptor } from "pages/flightSchedule/flightScheduleEntityDescriptor";
import { infoGantt } from "pages/gantt/Gantt";
import { historicalMapAnalysisEntityDescriptor } from 'pages/HistoricalMapAnalysis/historicalMapAnalysisEntityDescriptor';
import { inventoryHistoryDataEntityDescriptor } from 'pages/InventoryPage/inventoryHistoryDataEntityDescriptor';
import { gpsLocationEntityDescriptor, positionEntityDescriptor } from "pages/Position/positionEntityDescriptor";
import { infoScanBaggageFormPage } from "pages/ScanBaggage/ScanBaggageFormPage";
import * as territoryZt from 'pages/Territory/stories';
import { territoryEntityDescriptor } from 'pages/Territory/territoryEntityDescriptor';
import "pages/XopsPersonalHomePage";
import React from 'react';
import { NavLink, Route, Redirect } from "react-router-dom";
import { Location } from 'history';
import { Card, Checkbox, Icon, Menu, SemanticCOLORS } from 'semantic-ui-react';

// WARNING: if you do "organize imports", make sure this is remains at the bottom so that the styles are applied.
import 'xops6.css';
import { WidgetProps } from "@crispico/foundation-react/pages/dashboard/dashboardTab/WidgetWrapper";
import { detectionEventEntityDescriptor } from "pages/DetectionEvent/detectionEventEntityDescriptor";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { XopsLoginPage, XopsLoginTypeEnum } from "pages/XopsLoginPage";
import { xopsMobileWrapperBackboneRoutes, xopsMobileWrapperPageUrlPageMenuEntry, xopsMobileWrapperBackbonePublicRoutes, w, xopsMobileWrapperSettingsMenuEntry, XopsMobileWrapper } from "pages/XopsMobile/XopsMobileWrapper";
import { missionsAwaitingForDriversPageRoute, missionsAwaitingForDriversPageMenuEntry } from "pages/Mission2/Mission2EntityDescriptor";
import { DiagnosticTroubleCodeWidget, sliceDiagnosticTroubleCodeWidget } from "pages/EquipmentResource/DiagnosticTroubleCodeWidget";
import { WidgetStatus } from "@crispico/foundation-react/pages/dashboard/AbstractWidgetWithFilter";
import { fileBrowserPageMenuEntry, fileBrowserPageRoute } from "@crispico/foundation-react/pages/FileBrowser/FileBrowserPage";
import { ganttAssignmentFromFilesOnDiskPageMenuEntry, ganttAssignmentFromFilesOnDiskPageRoute } from "pages/ganttAssignment/GanttAssignmentFromFilesOnDiskPage";
import { GanttAssignmentEntityDescriptor, GanttAssignmentEntityEditorPage } from "pages/ganttAssignment/GanttAssignmentEntityDescriptor";
import { EntityFilterMode } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/EntityFilter";

ConnectedPageHelper.tempEnableSecurity = true;
Utils.DEFAULT_TEXT_COLOR = '#3A527B'; // same as the one from xops6.css (blue color)

const ELA_RFID_ANTENNA_FULL: string = "ELA_RFID_ANTENNA_FULL";

/**
 * Used to set dummy strings to InitializationsForClient so we can work with AppMeta routes even if 
 * we don't have them yet (needed for XopsMobile).
 */
const DUMMY_STRING: string = 'DUMMY';
export interface MarkerSettings {
    markerIdField: string,
    markerType: string,
    showTextUnderIcon: boolean,
    colors: {
        area: string;
        field: string;
        showInFilterBar: string;
        useDurationBetweenNowAndFieldValue: boolean;
        intervals: {
            label?: string;
            from: string;
            to?: string;
            color: string;
        }[];
    }[]
    bigPopupFields: { name: string }[]
    smallPopupFields: { name: string }[]
}

export interface MapSettings {
    airport: string,
    bingAPIKey: string,
    markers: MarkerSettings[]
}

export interface ConveyorEjectSettings {
    conveyorEject: string,
    color?: string,
    parkings: [{ id: number }]
}
export interface TempUnitSettings {
    organizationId?: number,
    missionTypesToDisplay?: [{ id: number }],
    defaultTripTimeToParking?: number;
    defaultLCOffset?: number;
    defaultMCOffset?: number;
    defaultSCOffset?: number;
}

export interface BaggageStatusSettings {
    name: string,
    label: string,
    color: SemanticCOLORS,
    displayMode: number
}

export interface TempMissionTypeSettings {
    missionType: number,
    startAddresses?: [{ id: number }],
}
export interface TempSettingsXops {
    tempUnitSettings: TempUnitSettings[],
    tempFlightConveyorEjectSettings: ConveyorEjectSettings[],
    tempBaggageStatusSettings: BaggageStatusSettings[],
    tempMissionTypeSettings: TempMissionTypeSettings[],
}
export interface InitializationsForClient extends FoundationInitializationsForClient {
    mapSettings: MapSettings,
    tempSettingsXops: TempSettingsXops,
    currentHumanResource: any /* this should have a type*/
}
class XopsAppMeta extends AppMeta {

    constructor(public metas: CompMeta<any, any>[]) {
        super(metas);

        this.globalPermissions.add(ELA_RFID_ANTENNA_FULL);

        this.routes.push(...xopsMobileWrapperBackboneRoutes(this.computeRoute));
        this.routes.push(...xopsMobileWrapperBackbonePublicRoutes(this.computeRoute));
        this.routes.push(missionsAwaitingForDriversPageRoute(this.computeRoute));
        this.routes.push(fileBrowserPageRoute(this.computeRoute));
        this.routes.push(ganttAssignmentFromFilesOnDiskPageRoute(this.computeRoute));

        this.showSearchBarOnTitleBar = true;
        this.showClock = true;
        if (XopsMobileWrapper.isDeviceMobileApp()) {
            this.showBackButton = true;
            // deactivated because it does a window refresh and on mobile this means the user needs to login again
            //this.showReloadButton = true;
            this.showHomeButton = false;
            this.showSearchBarOnTitleBar = false;
            this.showNotifications = false;
            this.showTimeZone = false;
            this.showOrganization = false;
            this.showHeaderLogo = false;
        }
    }

    protected hasHomePageMenuEntry() {
        return !XopsMobileWrapper.isDeviceMobileApp();
    }

    canCallOnLocationChangedFromAppContainerConstructor(): boolean {
        return !XopsMobileWrapper.isDeviceMobileApp();
    }

    protected async loadInitializationsForClient_fromConstructor() {
        // initialize xops-mobile
        XopsMobileWrapper.init();

        if (XopsMobileWrapper.isDeviceMobileApp()) {
            if (Utils.isNullOrEmpty(window.sessionStorage.getItem("login.type"))) {
                window.sessionStorage.setItem("login.type", XopsLoginTypeEnum.HRLOGIN);
            }
            // this was used because in this moment helperAppContainer wasn't initialized yet;
            // need to find another way to do it
            setTimeout(async () => {
                console.log("isMobileApp, set default props!");
                this.helperAppContainer.dispatchers.setInReduxState({
                    initializationsForClientLoaded: true, initializationsForClient: {
                        currentOrganization: undefined,
                        currentUser: undefined,
                        currentPermissions: undefined,
                        currentRole: undefined,
                        allOrganizationsAccess: undefined,
                        ldapAvailable: false,
                        version: DUMMY_STRING,
                        visualStyleSettings: {},
                        homePageSettings: { usePersonalHomePage: false, dashboardId: 0 },
                        colorSettings: { data: [] },
                        internationalizationSettings: { showBothOriginalAndTranslated: false, translatableByUserLanguagesMap: {} },
                        fileBrowserBulkDownloadMaximumFileSize: 0,
                        mapSettings: { airport: DUMMY_STRING, bingAPIKey: DUMMY_STRING, markers: [] },
                        tempSettingsXops: { tempUnitSettings: [], tempBaggageStatusSettings: [], tempFlightConveyorEjectSettings: [], tempMissionTypeSettings: [] },
                        currentHumanResource: undefined
                    } as InitializationsForClient
                });
                console.log("isMobileApp, go to xops-mobile!");
                AppMetaTempGlobals.history.push(xopsMobileWrapperPageUrlPageMenuEntry().to);
            }, 100);
            return;
        }
        super.loadInitializationsForClient_fromConstructor();
    }

    finalizeEntityDescriptors() {
        this.addEntityDescriptors([
            organizationEntityDescriptor, userEntityDescriptor, roleEntityDescriptor, roleToUserEntityDescriptor,
            settingsEntityDescriptor,
            checklistEntityDescriptor,
            chartEntityDescriptor,
            equipmentTypeEntityDescriptor,
            equipmentModelEntityDescriptor,
            equipmentResourceEntityDescriptor,
            historicalMapAnalysisEntityDescriptor,
            territoryEntityDescriptor,
            entityToTagEntityDescriptor,
            tagEntityDescriptor,
            positionEntityDescriptor,
            gpsLocationEntityDescriptor,
            inventoryHistoryDataEntityDescriptor,
            auditEntityDescriptor,
            airportEntityDescriptor,
            telemetryEventMappingEntityDescriptor, telemetrySystemEntityDescriptor,
            drivingEventEntityDescriptor,
            flightScheduleEntityDescriptor,
            notificationEntityDescriptor,
            problemEntityDescriptor,
            unitEntityDescriptor,
            detectionEventEntityDescriptor,
        ]);
        new AppEntityDescriptors().init();
        return super.finalizeEntityDescriptors();
    }

    protected getFieldsForInitializationsForClient() {
        return `currentUser { id firstName lastName username isAdmin language } 
                mapSettings { bingAPIKey airport markers { markerIdField markerType showTextUnderIcon colors { area field showInFilterBar useDurationBetweenNowAndFieldValue intervals { label from to color } } bigPopupFields { name } smallPopupFields { name } } }
                tempSettingsXops { tempUnitSettings { organizationId missionTypesToDisplay { id } defaultTripTimeToParking defaultLCOffset defaultMCOffset defaultSCOffset } tempFlightConveyorEjectSettings { conveyorEject color parkings { id } } tempBaggageStatusSettings { name label color displayMode } tempMissionTypeSettings { missionType startAddresses { id } } }
                currentHumanResource { id identifier firstName lastName pdaIdentifier unit { id name telephone } mobileDeviceId mobileDevice { id identifier } vehicle { id identifier initBusParking { name } } qualifications { qualificationType {id name } startDate endDate }  }
                `
            + super.getFieldsForInitializationsForClient();
    }

    protected resetMenuEntries() {
        super.resetMenuEntries();
        Messages.getInstance().setLanguage("en");
    }

    getUIEntityDescriptors(): { [entityName: string]: EntityDescriptor } {
        let firstTime = false;
        if (this.uiEntityDescriptors === undefined) {
            firstTime = true;
        }
        super.getUIEntityDescriptors();

        if (firstTime && this.uiEntityDescriptors) {
            delete this.uiEntityDescriptors["Leave"];
            delete this.uiEntityDescriptors["LeaveType"];
            delete this.uiEntityDescriptors["Employee"];
            delete this.uiEntityDescriptors["Department"];
            delete this.uiEntityDescriptors["Job"];
        }
        return this.uiEntityDescriptors!;
    }

    createMenuEntries(data: FoundationInitializationsForClient, entityCrudMenus: any): any {
        let menuEntries: any = [
            zeroTrainingIndexPageMenuEntry()
        ];

        if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_TABLE, dashboardEntityDescriptor.name]), false, data.currentPermissions)) {
            this.helperAppContainer.dispatchers.loadMenuEntries(dashboardEntityDescriptor.name);
        }
        if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_TABLE, chartEntityDescriptor.name]), false, data.currentPermissions)) {
            this.helperAppContainer.dispatchers.loadMenuEntries(chartEntityDescriptor.name);
        }

        // this.helperAppContainer.dispatchers.getState().menuEntries["Dashboard"]?.forEach((d: any) => {
        //     menuEntries.push({ content: d.name, to: '/DashboardEditor/' + d.id + '/dashboard', icon: d.icon, exact: true, color: d.color });
        // });

        menuEntries.push(...[
            {
                content: _msg("Menu.map"), icon: "map outline", submenus: [
                    equipmentResourceEntityDescriptor.getMenuEntry(),
                    humanResourceEntityDescriptor.getMenuEntry(),
                    historicalMapAnalysisEntityDescriptor.getMenuEntry(),
                    territoryEntityDescriptor.getMenuEntry(),
                    positionEntityDescriptor.getMenuEntry(),
                    gpsLocationEntityDescriptor.getMenuEntry(),
                    drivingEventEntityDescriptor.getMenuEntry(),
                    detectionEventEntityDescriptor.getMenuEntry(),
                ].sort(this.compareMenuEntries),
            },
            {
                content: _msg("Menu.parameters"), icon: "wrench", submenus: [
                    organizationEntityDescriptor.getMenuEntry(),
                    userEntityDescriptor.getMenuEntry(),
                    roleEntityDescriptor.getMenuEntry(),
                    roleToUserEntityDescriptor.getMenuEntry(),
                    equipmentTypeEntityDescriptor.getMenuEntry(),
                    equipmentModelEntityDescriptor.getMenuEntry(),
                    settingsEntityDescriptor.getMenuEntry(),
                    entityToTagEntityDescriptor.getMenuEntry(),
                    tagEntityDescriptor.getMenuEntry(),
                    telemetrySystemEntityDescriptor.getMenuEntry(),
                    telemetryEventMappingEntityDescriptor.getMenuEntry(),
                    addressEntityDescriptor.getMenuEntry(),
                    airportEntityDescriptor.getMenuEntry(),
                    blocklyScriptEntityDescriptor.getMenuEntry(),
                    scheduledTaskEntityDescriptor.getMenuEntry(),
                ].sort(this.compareMenuEntries),
            },
            {
                content: _msg("Menu.visualisation"), icon: "eye", submenus: [
                    chartEntityDescriptor.getMenuEntry(),
                    dashboardEntityDescriptor.getMenuEntry(),
                ].sort(this.compareMenuEntries),
            }
        ]);
        if (data.currentUser?.isAdmin) {
            menuEntries.push({
                content: _msg("Menu.developers"), icon: "computer", submenus: [
                    {
                        content: <Checkbox color="white" checked={localStorage.getItem(MAP_CLUSTER_MODE_KEY) === CLUSTER_MODE.PRUNE_CLUSTER} label={_msg("MapRealTime.prunecluster")}
                            onClick={() => {
                                let newValue = localStorage.getItem(MAP_CLUSTER_MODE_KEY) === CLUSTER_MODE.PRUNE_CLUSTER ? CLUSTER_MODE.CLASSIC_CLUSTER : CLUSTER_MODE.PRUNE_CLUSTER;
                                localStorage.setItem(MAP_CLUSTER_MODE_KEY, newValue);
                                window.location.reload();
                            }} />, to: "/"
                    },
                    { content: infoScanBaggageFormPage.sliceName, to: "/" + infoScanBaggageFormPage.sliceName, icon: "suitcase" },
                    { content: infoTemplateConnectedComponent.sliceName, to: "/" + infoTemplateConnectedComponent.sliceName, icon: "pencil" },
                    inventoryHistoryDataEntityDescriptor.getMenuEntry(),
                    { content: infoGantt.sliceName, to: "/" + infoGantt.sliceName },
                    {
                        as: "div", children: <>
                            {_msg("Menu.old")} &nbsp;
                            <NavLink to="/HumanResourceForm">HR form</NavLink> | &nbsp;
                            <NavLink to={eventValidationPageUrl("mission", "-5000")}>EventValidation</NavLink> | &nbsp;
                        </>
                    },
                    ganttAssignmentFromFilesOnDiskPageMenuEntry()
                ],
            })
        }
        let clientMenuEntries: any = [];
        clientMenuEntries.push({ ...xopsMobileWrapperPageUrlPageMenuEntry() });
        clientMenuEntries.push({ ...humanResourceEntityDescriptor.getMenuEntry() });
        clientMenuEntries.push({ ...equipmentResourceEntityDescriptor.getMenuEntry() });
        clientMenuEntries.push({ ...flightEntityDescriptor.getMenuEntry() });
        clientMenuEntries.push({ ...baggageEntityDescriptor.getMenuEntry() });
        clientMenuEntries.push({ ...missionEntityDescriptor.getMenuEntry() });
        clientMenuEntries.push(missionsAwaitingForDriversPageMenuEntry());

        if (XopsMobileWrapper.isDeviceMobileApp()) {
            clientMenuEntries.push({ ...xopsMobileWrapperSettingsMenuEntry() });
        }
        clientMenuEntries.push(fileBrowserPageMenuEntry());

        clientMenuEntries.push({
            content: _msg("Menu.referenceData"), icon: "eye", submenus: [
                addressEntityDescriptor.getMenuEntry(),
                territoryEntityDescriptor.getMenuEntry(),
            ],
        })
        if (data.currentUser?.isAdmin) {
            clientMenuEntries.push({
                content: "Advanced", icon: "computer", submenus: menuEntries.sort(this.compareMenuEntries),
            });
        }
        return clientMenuEntries;
    }

    enableModalRoutes() {
        return true;
    }

    protected getFieldsForOrg() {
        return super.getFieldsForOrg() + " airport { id }"
    }

    getOrganizationTreeIcon(itemId: string, object: any, currentOrg: Optional<Organization>) {
        if (object.children?.length === 0) {
            if (object.airport) {
                return airportEntityDescriptor.getIcon();
            }
        }
        return super.getOrganizationTreeIcon(itemId, object, currentOrg);
    }

    protected renderAdditionalUserInfo(user: User): React.ReactNode {
        const er = this.getCurrentHumanResource()?.vehicle;
        if (er) {
            return <span className="no-wrap-no-overflow-ellipsis"><Icon name='truck' className="AppContainer_menu_icon_without_label" /> {er?.identifier}</span>
        }
        super.renderAdditionalUserInfo(user);
    }
   
    getVersionInfo(version: string): React.ReactNode {
        const mobileVersion = XopsMobileWrapper.getMobileVersion();
        if (mobileVersion) {
            return <>{super.getVersionInfo(version)}<span>{mobileVersion}</span></>;
        }
        return super.getVersionInfo(version);
    }

    protected addCustomFieldsToEntityDescriptors() {
        super.addCustomFieldsToEntityDescriptors();
        //preparing data for dropdown - customFields from Settings
        entityDescriptors["TelemetryEventMapping"].getField("mappingTo").customize();
    }

    getCurrentHumanResource(): Optional<any> {
        return (this.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient).currentHumanResource;
    }

    async afterLogin() {
        await super.afterLogin();
        w.xopsMobileWrapperAfterLogin();
    }

    async beforeLogout() {
        w.xopsMobileWrapperBeforeLogout();
        super.beforeLogout();
    }
}

export const appMeta = new XopsAppMeta([
    eventValidationStatusPageMeta
]);
appMeta.addConnectedPageInfos([infoPasswordResetInitPage, infoPasswordResetFinishPage, infoErrorPage,
    infoTemplateConnectedComponent, infoScanBaggageFormPage,
    scheduledTaskEntityDescriptor.infoTable, infoGantt
]);
export const xopsHomePageWrappedComponent = class extends HomePage {
    renderUnderJumbotron() {
        const mailto = 'mailto:xops@resonate-mp4.com?subject=' + _msg('Menu.ticket.email.title') + '&body=' + encodeURIComponent(_msg('Menu.ticket.email.body'))
        return (
            <>
                <p>&nbsp;</p>
                <Card.Group itemsPerRow='4' centered>
                    <Card>
                        <Card.Content>
                            <Card.Header><Icon name="map outline" />{_msg("Menu.map")}</Card.Header>
                            <Menu vertical fluid items={[
                                historicalMapAnalysisEntityDescriptor.getMenuEntry(),
                                positionEntityDescriptor.getMenuEntry(),
                                gpsLocationEntityDescriptor.getMenuEntry(),
                                drivingEventEntityDescriptor.getMenuEntry(),
                                checklistEntityDescriptor.getMenuEntry(),
                                qualificationEntityDescriptor.getMenuEntry(),
                                detectionEventEntityDescriptor.getMenuEntry(),
                            ]}>
                            </Menu>
                        </Card.Content>
                    </Card>
                    <>
                        {AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_TABLE, dashboardEntityDescriptor.name])) ?
                            <Card>
                                <Card.Content>
                                    <Card.Header><Icon name="chart pie" />{_msg("Dashboard.label.plural")}</Card.Header>
                                    <Menu vertical fluid>
                                        {this.props.menuEntries[dashboardEntityDescriptor.name]?.map((d: MenuEntry) => {
                                            return <Menu.Item key={d.id} content={d.name} icon={<Icon name={d.icon as any} style={{ color: d.color }} />} link={true} onClick={() => this.props.dispatchers.dispatch(push(dashboardEntityDescriptor.getEntityEditorUrl(d.id) + "/dashboard"))} />
                                        })}
                                        <Menu.Item content={_msg("Dashboard.menu.viewAll")} icon={<Icon name='ellipsis horizontal' color='grey' />} link={true}
                                            onClick={() => this.props.dispatchers.dispatch(push(dashboardEntityDescriptor.getEntityTableUrl()))} />
                                    </Menu>
                                </Card.Content>
                            </Card>
                            : null}
                        {AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_TABLE, chartEntityDescriptor.name])) ?
                            <Card>
                                <Card.Content>
                                    <Card.Header><Icon name="chart pie" />{_msg("Chart.label.plural")}</Card.Header>
                                    <Menu vertical fluid>
                                        {this.props.menuEntries[chartEntityDescriptor.name]?.map((c: MenuEntry) => {
                                            return <Menu.Item key={c.id} content={c.name} icon={<Icon name={c.icon as any} style={{ color: c.color }} />} link={true} onClick={() => this.props.dispatchers.dispatch(push(chartEntityDescriptor.getEntityEditorUrl(c.id) + "/chart"))} />
                                        })}
                                        <Menu.Item content={_msg("Dashboard.menu.viewAll")} icon={<Icon name='ellipsis horizontal' color='grey' />} link={true}
                                            onClick={() => this.props.dispatchers.dispatch(push(chartEntityDescriptor.getEntityTableUrl()))} />
                                    </Menu>
                                </Card.Content>
                            </Card>
                            : null}
                    </>
                    <Card>
                        <Card.Content>
                            <Card.Header><Icon name="eye" />{_msg("Menu.datareferences")}</Card.Header>
                            <Menu vertical fluid items={[
                                equipmentResourceEntityDescriptor.getMenuEntry(),
                                humanResourceEntityDescriptor.getMenuEntry(),
                                organizationEntityDescriptor.getMenuEntry(),
                                territoryEntityDescriptor.getMenuEntry(),
                                equipmentTypeEntityDescriptor.getMenuEntry(),
                                equipmentModelEntityDescriptor.getMenuEntry(),
                                <Menu.Item key="lastGanttAssignment" content={_msg("GanttAssignment.lastGanttAssignment")} link={true} permission={Utils.pipeJoin([ENT_READ, ganttAssignmentEntityDescriptor.name])}
                                    onClick={async () => this.props.dispatchers.dispatch(push(ganttAssignmentEntityDescriptor.getEntityEditorUrl(await (ganttAssignmentEntityDescriptor as GanttAssignmentEntityDescriptor).getLastGanttAssignmentId()) + "/gantt"))}
                                />
                            ]}>
                            </Menu>
                        </Card.Content>
                        <Card.Content>
                            <Card.Header><Icon name="help circle" />{_msg("Menu.support")}</Card.Header>
                            <Menu vertical fluid items={[
                                {
                                    key: "ticket", icon: "file alternate outline", content:
                                        <a href={mailto}>{_msg("Menu.ticket")}</a>
                                },
                                // { as: NavLink, ...zeroTrainingIndexPageMenuEntry() as any, className: !allow ? "disabledItem" : undefined }
                            ]}>
                            </Menu>
                        </Card.Content>
                    </Card>
                </Card.Group>

                {/* <Segment compact style={{width: '80%', margin: 'auto', marginTop: '10px', padding: '0'}}>
                <Header as='h3' attached='top'><Icon name='dashboard' />{_msg("Dashboard.label.plural")}</Header>
                <Segment secondary attached='bottom'>     
                    <Grid centered stretched columns='4'><Grid.Row>
                        {this.props.dashboardLinks.map((d: any, index: number) => {
                            return <Grid.Column width='4'><Card link onClick={() => this.props.dispatchers.dispatch(push('/DashboardEditor/' + d.id + '/dashboard'))}>
                                <Card.Header className={'dashboardLink' + index} style={{padding: '10px', textAlign: 'center'}}>
                                    <div><style>{`
                                        .${'dashboardLink' + index} i.inverted.bordered.icon, i.inverted.circular.icon {
                                            background-color: ${d.color} !important;
                                        }
                                        `}
                                        </style>
                                            <Icon size='huge' circular bordered inverted name={d.icon} />
                                            <Header as='h3' style={{ color: d.color }}>{d.name}</Header></div>
                                    </Card.Header>
                                </Card></Grid.Column>
                            })}
                            <Grid.Column width='4'><Card link onClick={() => this.props.dispatchers.dispatch(push('/DashboardTable'))}>
                                <Card.Header style={{ padding: '10px', textAlign: 'center' }}>
                                    <Icon size='huge' style={{ margin: '0 auto' }} circular bordered color='grey' name='ellipsis horizontal' />
                                    <Header as='h3' color='grey'>{_msg("Dashboard.menu.viewAll")}</Header>
                                </Card.Header>
                            </Card></Grid.Column>
                        })}
                        <Grid.Column width='4'><Card link onClick={() => this.props.dispatchers.dispatch(push('/DashboardTable'))}>
                        <Card.Header style={{padding: '10px', textAlign: 'center'}}>
                            <Icon size='huge' style={{margin: '0 auto'}} circular bordered color='grey' name='ellipsis horizontal'/>
                            <Header as='h3' color='grey'>{_msg("Dashboard.menu.viewAll")}</Header>
                        </Card.Header>
                        </Card></Grid.Column>
                    </Grid.Row></Grid>
                </Segment></Segment> */}
            </>);
    }

};

infoLoginPage.wrappedComponentClass = XopsLoginPage;
appMeta.addConnectedPageInfos([infoLoginPage]);

infoHomePage.wrappedComponentClass = xopsHomePageWrappedComponent;
appMeta.addConnectedPageInfos([infoHomePage, infoPersonalHomePage]);

appMeta.zeroTrainingArticlesRegistry.addFromModule(territoryZt);
// appMeta.zeroTrainingArticlesRegistry.addFromModule(chartHistogramCountInTerritoriesZt);
// appMeta.zeroTrainingArticlesRegistry.addFromModule(chartPieDistanceTimeInTerritoriesZt);
// appMeta.zeroTrainingArticlesRegistry.addFromModule(chartBarsDistanceTimeInTerritoriesZt);
// appMeta.zeroTrainingArticlesRegistry.addFromModule(chartPieCountByCriteriaZt);
// TODO CC: temporary commented; we need to fix map stories before!
// appMeta.zeroTrainingArticlesRegistry.addFromModule(historicalMapEquipment);
// appMeta.zeroTrainingArticlesRegistry.addFromModule(historicalMapAnalysis);
appMeta.zeroTrainingArticlesRegistry.addFromModule(customQueryZt);
appMeta.zeroTrainingArticlesRegistry.addFromModule(dashboardZt)
appMeta.zeroTrainingArticlesRegistry.addFromModule(auditZt);
appMeta.zeroTrainingArticlesRegistry.addFromModule(roleZt);

export default appMeta.getAppComponent();

// It was added here because dashboard widget editor doesn't see it
export const REAL_TIME_MAP_WIDGET = "realTimeMapWidget";
export const MAP_WIDGET = "mapWidget";
export const VEHICLE_INFORMATION_WIDGET = "vehicleInformationWidget";
export const DIAGNOSTIC_TROUBLE_CODE_WIDGET = "diagnosticTroubleCodeWidget";

DashboardWidgetFactories.INSTANCE.widgets[REAL_TIME_MAP_WIDGET] = new class extends DashboardWidgetFactory {
    allowMultiple = false

    getEntityDescriptor(widgetConfig: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor("MapRealTime")
            .addFieldDescriptor({ name: "filter", type: FieldType.filter, mode: EntityFilterMode.OBJECT, optional: true })
            .addFieldDescriptor({ name: "airport", type: "Airport", optional: true })
    }
    hasRenderWidget2 = () => true;
    renderWidget2(connectedPageInfo: ConnectedPageInfo, widgetProps: WidgetProps) {
        return <ConnectedComponentInSimpleComponent info={connectedPageInfo} layers={{ [EQUIPMENT_RESOURCE_TYPE]: { layerType: MARKER_TYPE } }} />
    }
    renderEditor(id: string, widgetConfig: any, dispatchers: DispatchersFrom<any>) {
        return this.renderEditorInternal(id, widgetConfig, dispatchers, { ...widgetConfig, ...{ id: id } });
    }
    getConnectedPageInfo(sliceName: string, widgetConfig: any) {
        const connectedPageInfo = new ConnectedPageInfo(sliceRealTimeMap, RealTimeMap, sliceName);
        connectedPageInfo.mapBigStateToProps = (state: BigState, props: Props) => {
            props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;
            props.showGoToTableButton = true;
        }
        return connectedPageInfo
    }
    getWizardInfo() {
        return { title: _msg("MapRealTime"), description: _msg("MapRealTime.content"), image: 'images/realtime-map.png' }
    }
}();

DashboardWidgetFactories.INSTANCE.widgets[MAP_WIDGET] = new class extends DashboardWidgetFactory {
    type = DashboardWidgetType.ENTITY;
    allowMultiple = false;

    getEntityDescriptor(widgetConfig: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor("Map");
    }
    renderWidget(id: string, connectedPageInfo: ConnectedPageInfo, widgetConfig: any, dashboardEntity: any, entity: any, buttonBarRef: any) {
        return <ConnectedComponentInSimpleComponent info={connectedPageInfo} layers={{ [EQUIPMENT_RESOURCE_TYPE]: { layerType: MARKER_TYPE } }} dashboardEntity={dashboardEntity} entity={entity} />
    }
    renderEditor(id: string, widgetConfig: any, dispatchers: DispatchersFrom<any>) {
        return this.renderEditorInternal(id, widgetConfig, dispatchers, { ...widgetConfig, ...{ id: id } });
    }
    getConnectedPageInfo(id: string, widgetConfig: any) {
        const connectedPageInfo = new ConnectedPageInfo(sliceMapWidget, MapWidget, id);
        connectedPageInfo.mapBigStateToProps = (state: BigState, props: Props) => {
            props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;
        }
        return connectedPageInfo;
    }
    getWizardInfo() {
        return { title: _msg("Map"), description: _msg("MapWidget.content"), image: 'images/realtime-map.png' }
    }
}();

DashboardWidgetFactories.INSTANCE.widgets[VEHICLE_INFORMATION_WIDGET] = new class extends DashboardWidgetFactory {
    type = DashboardWidgetType.ENTITY;

    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor("FieldWidget")
            .addFieldDescriptor({ name: "title", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "entityName", type: FieldType.entityName, defaultValue: dashboardEntity.entityName, enabled: false })
    }
    renderWidget(id: string, connectedPageInfo: ConnectedPageInfo, widgetConfig: any, dashboardEntity: any, entity: any | undefined, buttonBarRef: any, zeroTrainingMode: boolean) {
        return <VehicleInformationWidget buttonBarRef={buttonBarRef} zeroTrainingMode={zeroTrainingMode} id={id} entity={entity} />
    }
    getConnectedPageInfo(id: string, widgetConfig: any) {
        return null as unknown as ConnectedPageInfo;
    }
    getWizardInfo() {
        return { title: 'Vehicle Information Widget', description: 'Displays a information section common for multiple types of equipments.', slice: null, component: VehicleInformationWidget, testState: { entity: { odometer: 2412.543, batteryLevel: "78" } } }
    };
}

DashboardWidgetFactories.INSTANCE.widgets[DIAGNOSTIC_TROUBLE_CODE_WIDGET] = new class extends DashboardWidgetFactory {
    type = DashboardWidgetType.ENTITY;
    entityType = "EquipmentResource";

    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor(DIAGNOSTIC_TROUBLE_CODE_WIDGET).addFieldDescriptor({ name: "title", type: FieldType.string, optional: true });
    }
    renderWidget(id: string, connectedPageInfo: ConnectedPageInfo, widgetConfig: any, dashboardEntity: any, entity: any | undefined, buttonBarRef: any, zeroTrainingMode: boolean) {
        return <ConnectedComponentInSimpleComponent info={connectedPageInfo} entity={entity} />;
    }
    getConnectedPageInfo(id: string, widgetConfig: any) {
        return new ConnectedPageInfo(sliceDiagnosticTroubleCodeWidget, DiagnosticTroubleCodeWidget, id);
    }
    getWizardInfo() {
        return { title: 'Diagnostic Trouble Code (DTC) Widget', description: 'Displays DTC (errors) for the equipment.', slice: null, component: DiagnosticTroubleCodeWidget, testState: { status: WidgetStatus.DONE, entities: [{ name: "Handbrake Fault", code: "62" }, { name: "SRO", code: "57" }], totalCount: 2 } };
    };
};

searchOptions["EquipmentResource"] = ["identifier", "plateNumber"];
searchOptions["Address"] = ["name"];
searchOptions["Territory"] = ["name"];
