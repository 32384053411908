import React from "react";
import { Button, Icon, Menu } from "semantic-ui-react";
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import lodash from "lodash";
import gql from "graphql-tag";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { ColumnDefinition } from "@crispico/foundation-react/entity_crud/EntityTableSimple";
import { ExportParams } from "@crispico/foundation-react/entity_crud/ExportParams";
import { CustomQueryDefinition } from "../CustomQuery/CustomQueryDropdown";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";

export enum FILE_EXPORTER_MODE {
    NORMAL, MENU_ITEM
}

export class FileExportButtonState extends State {
    status = undefined as "done" | "inProgress" | undefined;
}

export class FileExportButtonReducers extends Reducers<FileExportButtonState> {
}

// TODO by CS: see remark in storybook
export class FileExportButton extends React.Component<RRCProps<FileExportButtonState, Reducers> & { entityName: string, customQueryDefinition: CustomQueryDefinition, columns: Array<ColumnDefinition>, mode?: FILE_EXPORTER_MODE }>{

    protected async exportToFile(name: string, customQueryDefinition: CustomQueryDefinition, columns: Array<ColumnDefinition>) {
        this.props.r.setInReduxState({ status: "inProgress" });

        const columnNames = columns.filter(col => !entityDescriptors[name].getField(col.name).clientOnly).map(col => col.name);

        const { filter, sorts } = customQueryDefinition;

        const exportOperationName = `${lodash.lowerFirst(name)}Service_export`;
        const exportQuery = gql(`query q($params: ExportParamsInput) { ${exportOperationName}(params: $params)}`);

        const token = (await apolloClient.query({ query: exportQuery, variables: ExportParams.create().filter(filter).sorts(sorts).columns(columnNames).timezone(Intl.DateTimeFormat().resolvedOptions().timeZone) })).data[exportOperationName];

        // This was initially a fetch, but it had the issue that it would timeout after 2 minutes without a response
        // The approach of opening a new tab does not have this issue and is better in the sense that the XLSX file (possibly very large) is not loaded into memory,
        // but it will not work in developement because React (:3000) and the API (:8080) are on two different ports
        // For testing in development, you could make a modification to explicity request localhost:8080/file/file-export?token=...
        window.open(Utils.adjustUrlToServerContext('export/export-file?token=' + token), "_blank")

        this.props.r.setInReduxState({ status: "done" });
    }

    render() {
        if (this.props.mode === FILE_EXPORTER_MODE.MENU_ITEM) {
            return <Menu.Item onClick={() => this.exportToFile(this.props.entityName, this.props.customQueryDefinition, this.props.columns)}>
                {this.props.s.status === "inProgress" ? _msg("FileExporter.spinner") : _msg("dto_crud.export")}
                <Icon loading={this.props.s.status === "inProgress"} name={this.props.s.status === "inProgress" ? 'spinner' : 'download'} />
            </Menu.Item >
        } else {
            return <>
                <Button color="blue" onClick={() => this.exportToFile(this.props.entityName, this.props.customQueryDefinition, this.props.columns)}>
                    <Icon loading={this.props.s.status === "inProgress"} name={this.props.s.status === "inProgress" ? 'spinner' : 'download'} />
                    {this.props.s.status === "inProgress" ? _msg("FileExporter.spinner") : _msg("dto_crud.export")}
                </Button>
            </>
        }
    }
}

export const FileExportButtonRRC = ReduxReusableComponents.connectRRC(FileExportButtonState, Reducers, FileExportButton);
