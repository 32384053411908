//@ts-ignore
import { TestUtils, WrapWithStoreOptions } from '@crispico/foundation-react/utils/TestUtils';
import { zeroTrainingArticle } from '@crispico/foundation-react/zeroTraining/dataStructures';
import { joyrideDecoratorParams } from "@crispico/foundation-react/zeroTraining/JoyrideDecorator";
import { territoryEntityDescriptor } from 'pages/Territory/territoryEntityDescriptor';
import { testStateDrawEnabled, testStateEditEnabled, testStateWithData, testStateRemoveEnabled } from 'pages/Territory/testState';
import { APP_CONTAINER } from '@crispico/foundation-react';

export default zeroTrainingArticle("Territory.label", "ZeroTraining.overviewCap");

var options: WrapWithStoreOptions = { addRef: true, frozenReducer: true, 
    globalState: {[APP_CONTAINER]: { initializationsForClient: { mapSettings: {airport: "LHR"} } }} }

export const SnapMapWithData = () => TestUtils.wrapWithStoreForInfo(territoryEntityDescriptor.infoTable, testStateWithData, options);
SnapMapWithData.story = {
    name: _msg("Territory.zt.demo"),  
    parameters: joyrideDecoratorParams("Territory.zt", [
        { shortKey: "0", target: ".fixedDataTableLayout_rowsContainer", content: _msg("Territory.zt.table") },
        { shortKey: "1", target: "#map", content: _msg("Territory.zt.map") }
    ])
};

export const SnapMapWithDataEditEnabled = () => TestUtils.wrapWithStoreForInfo(territoryEntityDescriptor.infoTable, testStateEditEnabled, options);
SnapMapWithDataEditEnabled.story = {
    name: _msg("Territory.zt.edit"),    
    parameters: joyrideDecoratorParams("Territory.zt", [
        { shortKey: "0", target: ".leaflet-draw-edit-edit", content: _msg("Territory.zt.editLayer") },
        { shortKey: "1", target: "[data-cy='editModeMessage']", content: _msg("Territory.zt.details") }
    ])
};

export const SnapMapWithDataDrawEnabled = () => TestUtils.wrapWithStoreForInfo(territoryEntityDescriptor.infoTable, testStateDrawEnabled, options);
SnapMapWithDataDrawEnabled.story = {
    name: _msg("Territory.zt.draw"),  
    parameters: joyrideDecoratorParams("Territory.zt", [
        { shortKey: "0", target: ".leaflet-draw-draw-polygon", content: _msg("Territory.zt.drawPolygon") },
        { shortKey: "1", target: ".leaflet-draw-draw-rectangle", content: _msg("Territory.zt.drawRectangle") },
        { shortKey: "2", target: "[data-cy='editModeMessage']", content: _msg("Territory.zt.details") }
    ])
};

export const SnapMapWithDataRemove = () => TestUtils.wrapWithStoreForInfo(territoryEntityDescriptor.infoTable, testStateRemoveEnabled, options);
SnapMapWithDataRemove.story = {
    name: _msg("Territory.zt.remove"),  
    parameters: joyrideDecoratorParams("Territory.zt", [
        { shortKey: "0", target: ".leaflet-draw-edit-remove", content: _msg("Territory.zt.removeTerritory") },
        { shortKey: "1", target: "[data-cy='editModeMessage']", content: _msg("Territory.zt.details") }
    ])
};
