import { Messages } from "@crispico/foundation-gwt-js";
import React from "react";
import { FieldRendererProps } from "../fieldRenderersEditors";
import StringFieldRenderer from "./StringFieldRenderer";
import moment from "moment";
import { Utils } from "../../utils/Utils";

export class DateFieldRenderer extends StringFieldRenderer<FieldRendererProps> {

    protected getText() {
        const { props } = this;
        return props.value && moment(props.value).format(props.fieldDescriptor.format ? props.fieldDescriptor.format : Utils.dateTimeFormat);
    }
    
}

export default DateFieldRenderer;