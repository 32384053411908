import { createSliceFoundation, EntityEditorPage, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { HistoricalMap, sliceHistoricalMap } from "../../components/HistoricalMap/HistoricalMap"
import React from "react";

export const historicalMapAnalysisEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "HistoricalMapAnalysis",
    miniFields: ["name"],
    icon: "map outline"
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
    .addFieldDescriptor({ name: "startDate", type: FieldType.date, enabled: false })
    .addFieldDescriptor({ name: "endDate", type: FieldType.date, enabled: false })
);

export const sliceEntityHistoricalMapAnalysis = historicalMapAnalysisEntityDescriptor.infoEditor.slice = createSliceFoundation(class extends SliceEntityEditorPage {
    nestedSlices = {
        ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
        historicalMap: sliceHistoricalMap
    }
}).setEntityDescriptor(historicalMapAnalysisEntityDescriptor);

historicalMapAnalysisEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceEntityHistoricalMapAnalysis>> {
    protected getExtraTabPanes() {
        return [...super.getExtraTabPanes(),
            {
                routeProps: { path: "/historicalMap" },
                menuItemProps: { icon: "map outline", content: _msg("HistoricalMap.title") },
                render: () => <HistoricalMap {...this.props.historicalMap} dispatchers={this.props.dispatchers.historicalMap} 
                historicalMapAnalysisId={this.props.entity?.id} 
                historicalMapAnalysisEntity={this.props.entity}
                rootReducerForPages={this.props.rootReducerForPages}/>
            }
        ]
    }
}
