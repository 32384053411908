import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, createSliceFoundation, getBaseImpures, getBaseReducers, Optional, PropsFrom, RootReducerForPages, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, StateFrom, EntityTablePage, FilterAsText, Utils } from "@crispico/foundation-react";
import { CustomQueryBar, CUSTOM_QUERY_BAR_MODE, sliceCustomQueryBar } from "@crispico/foundation-react/components/CustomQuery/CustomQueryBar";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort, SortAsText } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Drawer } from "antd";
import { AirportForMap } from "apollo-gen/AirportForMap";
import { EquipmentResourceForMap } from "apollo-gen/EquipmentResourceForMap";
import { findEquipmentResourceForMapById, findEquipmentResourceForMapByIdVariables } from "apollo-gen/findEquipmentResourceForMapById";
import { Pair_Double_DoubleInput } from "apollo-gen/globalTypes";
import { loadAirportsForMap, loadAirportsForMapVariables } from "apollo-gen/loadAirportsForMap";
import { loadEquipmentResourcesForMap, loadEquipmentResourcesForMapVariables } from "apollo-gen/loadEquipmentResourcesForMap";
import { MapSettings, MarkerSettings } from "app";
import { ID, Location, MapContainerLeaflet, MarkerData, MARKER_TYPE, PolygonData, POLYGON_TYPE, POLYLINE_TYPE, sliceMapContainerLeaflet } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { DEFAULT_ZOOM_LEVEL } from "components/MapContainerLeaflet/MapLayerHelpers";
import { LOAD_AIRPORTS_FOR_MAP, LOAD_EQUIPMENT_RESOURCES_FOR_MAP, LOAD_TERRITORIES_FOR_REAL_TIME_MAP } from "components/realTimeMap/queries";
import { push } from "connected-react-router";
import { EQUIPMENT_RESOURCE_FOR_MAP, FIND_EQUIPMENT_RESOURCE_FOR_MAP_BY_ID } from "graphql/queries";
import lodash from 'lodash';
import moment from "moment";
import { airportEntityDescriptor, equipmentResourceEntityDescriptor, EquipmentResourceManyToOneEditorStandalone } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { EquipmentResourceBigInfoArea, EquipmentResourceUtils, EQUIPMENT_RESOURCE_TYPE, MarkerColorInterval } from "pages/EquipmentResource/EquipmentResourceUtils";
import React from "react";
import { Button, Dropdown, DropdownProps, Icon, Popup, Segment, SegmentGroup, Label, List, Divider, Checkbox } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { v4 as uuid } from 'uuid';
import { entityDescriptors, getOrganizationFilter } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import Measure from "react-measure";
import { List as ListVirtualized, ListRowProps } from 'react-virtualized';
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { TerritoryForMap } from "apollo-gen/TerritoryForMap";
import { loadTerritoriesForMap, loadTerritoriesForMapVariables } from "apollo-gen/loadTerritoriesForMap";
import { LOAD_TERRITORIES_FOR_MAP } from "pages/Territory/queries";
import { TERRITORY } from "pages/Territory/territoryEntityDescriptor";
import { FieldInterval } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { getDropdownItemLabel } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DropdownFieldRenderer";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ColorRegistry } from "@crispico/foundation-react/utils/ColorRegistry";

export const MAP_CLUSTER_MODE_KEY = 'map.clusterMode';
export enum CLUSTER_MODE { PRUNE_CLUSTER = 'pruneCluster', CLASSIC_CLUSTER = 'classicCluster' };

const refreshRateMillis: number = 2 * 1000;
export const sliceRealTimeMap = createSliceFoundation(class SliceRealTimeMap {

    initialState = {
        equipmentResourcesList: [] as EquipmentResourceForMap[],
        equipmentResourcesToDisplayIds: [] as any[],
        airports: [] as AirportForMap[],
        mostRecentUpdateEquipmentResource: undefined as Optional<string>,
        mostRecentUpdatedMapBounds: undefined as Optional<Pair_Double_DoubleInput[]>,
        loadUUID: undefined as Optional<string>,
        equipmentResourceToSelectAfterUpdate: undefined as Optional<any>,
        filterBarCheckboxes: {} as {[key: string]: boolean},
        territoriesForMap: [] as TerritoryForMap[]
    }

    nestedSlices = {
        mapContainer: sliceMapContainerLeaflet,
        customQueryBar: sliceCustomQueryBar,
    }

    reducers = {
        ...getBaseReducers<SliceRealTimeMap>(this),

        updateEquipmentResources(state: StateFrom<SliceRealTimeMap>, updatedEqs: EquipmentResourceForMap[]) {
            // used to optimize Array.findIndex, don't remove this, without it, for 10000 equipments, time will increase with ~10 sec
            const eqMapIdIndex: {[key: number]: number} = {};
            state.equipmentResourcesList.forEach((eq, index) => eqMapIdIndex[eq.id] = index );
            updatedEqs.forEach(updatedEq => {
                const index = eqMapIdIndex[updatedEq.id];
                if (index !== null && index !== undefined) {
                    state.equipmentResourcesList[index] = updatedEq;
                } else {
                    state.equipmentResourcesList.push(updatedEq);
                }
            });
            state.equipmentResourcesList.sort((a, b) => a.identifier && b.identifier ? a.identifier?.localeCompare(b.identifier) : 0);
        },

        changeFilterBar(state: StateFrom<SliceRealTimeMap>, checkbox: string) {
            state.filterBarCheckboxes[checkbox] = !state.filterBarCheckboxes[checkbox];
        }
    }

    impures = {
        ...getBaseImpures<SliceRealTimeMap>(this),

        async loadAirports(mapSettings: MapSettings) {
            const airports: Optional<AirportForMap[]> = (await apolloClient.query<loadAirportsForMap, loadAirportsForMapVariables>({
                query: LOAD_AIRPORTS_FOR_MAP,
                variables: FindByFilterParams.create().sorts([{ field: "code", direction: "ASC" }])
            })).data.airportService_findByFilter?.results as Optional<AirportForMap[]>;

            this.getDispatchers().setInReduxState({ airports: airports || [] });
        },

        async loadEquipmentResources(rootReducerForPages: RootReducerForPages, tableFilter: Filter | undefined, tableSorts: Sort[], mapContainer: MapContainerLeaflet | undefined, mapMarkSettings: MapSettings, bounds?: Pair_Double_DoubleInput[]) {
            if (!mapContainer?.areBoundsSmallerThen(this.getState().mostRecentUpdatedMapBounds, 0.001)) {
                // an offset was added so no changes to non-unpdated markers if the map center is modified a little
                // useful when searching & selecting ERs in visible area -> they are centered, but the map bounds doesn't change so much
                this.getDispatchers().setInReduxState({ mostRecentUpdatedMapBounds: bounds });
                this.getDispatchers().setInReduxState({ mostRecentUpdateEquipmentResource: undefined });
            }
            const filters: Filter[] = [];
            if (tableFilter) {
                tableFilter = Filter.eliminateDisabledFilters(tableFilter);
                if (tableFilter) {
                    filters.push(tableFilter);
                }
            }
            filters.push(
                { field: "visible", operator: FilterOperators.forBoolean.equals.value, value: "true" },
                { field: "updated", operator: FilterOperators.forDate.isNotEmpty.value });
            if (this.getState().mostRecentUpdateEquipmentResource) {
                filters.push({ field: "updated", operator: FilterOperators.forDate.greaterThan.value, value: moment(this.getState().mostRecentUpdateEquipmentResource).toISOString() });
            }
            const orgFilter = getOrganizationFilter(equipmentResourceEntityDescriptor, global.currentOrganizationToFilterBy);
            if (orgFilter) {
                filters.push(orgFilter);
            }

            let filter = { operator: FilterOperators.forComposedFilter.and.value, filters: filters };

            const sorts: Sort[] = Object.assign([], tableSorts);
            sorts.push({ field: "updated", direction: "DESC" });

            const uuid: Optional<string> = this.getState().loadUUID;
            const customFields = Object.values(equipmentResourceEntityDescriptor.fields).filter(x => x.isCustomField).map(x => x.name)

            const equipmentResources: Optional<EquipmentResourceForMap[]> = (await apolloClient.query<loadEquipmentResourcesForMap, loadEquipmentResourcesForMapVariables>({
                query: LOAD_EQUIPMENT_RESOURCES_FOR_MAP,
                variables: { filter: filter, sorts: sorts, coordinates: bounds, whichFields: customFields },
                context: { showSpinner: false }
            })).data.equipmentResourceService_findByRectangle?.results;
            
            if (uuid !== this.getState().loadUUID) {
                return;
            }

            if (!equipmentResources || equipmentResources.length === 0) {
                if (mapContainer !== undefined && EquipmentResourceUtils.getEquipmentResourceMapSettings(mapMarkSettings)?.colors.filter(color => color.showInFilterBar && color.useDurationBetweenNowAndFieldValue)?.length) {
                    this.updateEquipmentResourcesOnMap(rootReducerForPages, filterEquipmentsUsingFilterBar(mapMarkSettings, this.getState().filterBarCheckboxes, this.getState().equipmentResourcesList), mapContainer, mapMarkSettings);
                }
                return;
            }

            this.getDispatchers().updateEquipmentResources(equipmentResources);
            const filteredEquipmentResourceList = filterEquipmentsUsingFilterBar(mapMarkSettings, this.getState().filterBarCheckboxes, this.getState().equipmentResourcesList);
            const equipmentResourcesToDisplayIds = filteredEquipmentResourceList.map(er => er.id);
            this.getDispatchers().setInReduxState({ equipmentResourcesToDisplayIds: equipmentResourcesToDisplayIds });

            if (mapContainer !== undefined) {
                this.updateEquipmentResourcesOnMap(rootReducerForPages, equipmentResources.filter(er => equipmentResourcesToDisplayIds.includes(er.id)), mapContainer, mapMarkSettings);
            }

            this.getDispatchers().setInReduxState({ mostRecentUpdateEquipmentResource: equipmentResources[0].updated });
        },

        updateEquipmentResourcesOnMap(rootReducerForPages: RootReducerForPages, equipmentResources: EquipmentResourceForMap[], mapContainer: MapContainerLeaflet, mapSettings: MapSettings) {
            const markerSettings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(mapSettings);
            let updates: MarkerData[] = [];
            equipmentResources.forEach((eq: EquipmentResourceForMap) => {
                if (eq.identifier && eq.lastPointLongitude && eq.lastPointLatitude) {
                    updates.push(EquipmentResourceUtils.getMarkerFromEquipmentResource(rootReducerForPages, eq, markerSettings));
                }
            });

            if (updates.length > 0) {
                mapContainer.addOrUpdateLayers(updates, EQUIPMENT_RESOURCE_TYPE, true);
            }
            if (this.getState().equipmentResourceToSelectAfterUpdate) {
                this.getDispatchers().mapContainer.setInReduxState({ selectedLayer: { id: this.getState().equipmentResourceToSelectAfterUpdate, type: EQUIPMENT_RESOURCE_TYPE } });
                this.getDispatchers().setInReduxState({ equipmentResourceToSelectAfterUpdate: undefined });
            }
        },

        selectEquipmentResource(item: Optional<{ id: any, type: string }>, mapContainer?: MapContainerLeaflet) {
            this.getDispatchers().mapContainer.setInReduxState({ selectedLayer: item ? { id: item.id, type: item.type } : undefined });
        },

        selectAirport(id: number) {
            const airport: Optional<AirportForMap> = this.getState().airports.find(a => a.id === id);

            if (airport?.latitude && airport?.longitude) {
                this.getDispatchers().mapContainer.setInReduxState({ zoom: DEFAULT_ZOOM_LEVEL, center: [airport.latitude, airport.longitude] });
            }
        },

        async getEquipment(id: number) {
            const eq: Optional<EquipmentResourceForMap> = (await apolloClient.query<findEquipmentResourceForMapById, findEquipmentResourceForMapByIdVariables>({
                query: FIND_EQUIPMENT_RESOURCE_FOR_MAP_BY_ID,
                variables: { id: id }
            })).data.equipmentResourceService_findById;

            if (eq && eq.identifier && eq.lastPointLongitude && eq.lastPointLatitude) {
                const point: Location = { longitude: eq.lastPointLongitude, latitude: eq.lastPointLatitude };
                this.getDispatchers().mapContainer.setInReduxState({ center: [point.latitude, point.longitude] });
                if (MapContainerLeaflet.pointInsideBounds(this.getState().mostRecentUpdatedMapBounds, point)) {
                    // if marker is visible, select directly              
                    this.getDispatchers().mapContainer.setInReduxState({ selectedLayer: { id: eq.id, type: EQUIPMENT_RESOURCE_TYPE } });
                } else { // marker not visible, wait until the update is done
                    this.getDispatchers().setInReduxState({ equipmentResourceToSelectAfterUpdate: eq.id });
                }
            }
        },

        async loadTerritoriesForMap(currentBounds?: Pair_Double_DoubleInput[]) {  
            const territories: Optional<TerritoryForMap[]> = (await apolloClient.query({
                query: LOAD_TERRITORIES_FOR_REAL_TIME_MAP,
                variables: {coordinates: currentBounds},
                context: { showSpinner: false }
            })).data.territoryService_findByRectangle?.results;
            
            if (territories){
                if (!lodash.isEqual(territories, this.getState().territoriesForMap)) {
                    this.getDispatchers().setInReduxState({ territoriesForMap: territories });
                }
            }    
        },

        addTerritoriesOnMap(mapContainer: Optional<MapContainerLeaflet>) {
            let polygons: PolygonData[] = [];
            this.getState().territoriesForMap?.forEach((territory: TerritoryForMap) => {
                const points: Location[] = [];
                territory.coordinates?.forEach(p => {
                    points.push({ longitude: p.a, latitude: p.b });
                });
                polygons.push({ id: territory.id, points: points, color: territory.color || undefined, text: territory.name || undefined });
            });
            mapContainer?.addOrUpdateLayers(polygons, TERRITORY, true);
        },
    }

}, true)

type PropsNotFromState = {
    mapSettings: MapSettings,
    rootFilter?: Filter,
    showCustomQueryBar?: boolean,
    showGoToTableButton?: boolean,
    airport?: any,
    mapId?: string
};

type RealTimeMapLocalState = { drawerOpen: boolean, isFindEquipmentResourcePopupOpen: boolean, isGoToAirportPopupOpen: boolean, showTerritories: boolean, measuredWidth: number, measuredHeight: number };
export type Props = PropsFrom<typeof sliceRealTimeMap> & PropsNotFromState;
export class RealTimeMap extends React.Component<Props, RealTimeMapLocalState> {

    private mapContainerRef = React.createRef<MapContainerLeaflet>();
    private listRef = React.createRef<ListVirtualized>();
    private airportDropdownRef = React.createRef<any>();
    public buttonBarRef: React.MutableRefObject<any> = React.createRef<any>();

    private timer: number | undefined = undefined;

    constructor(props: Props) {
        super(props);
        this.state = { drawerOpen: false, isFindEquipmentResourcePopupOpen: false, isGoToAirportPopupOpen: false, showTerritories: false, measuredWidth: 0, measuredHeight: 0 };

        this.loadEquipmentResourcesOnMap = this.loadEquipmentResourcesOnMap.bind(this);
        this.renderTooltipContent = this.renderTooltipContent.bind(this);
        this.renderMarkerIcon = this.renderMarkerIcon.bind(this);
        this.listRowRenderer = this.listRowRenderer.bind(this);

        this.props.dispatchers.loadAirports(this.props.mapSettings);
    }

    protected getRootFilter(): Filter | undefined {
        if (this.props.showCustomQueryBar) {
            return this.props.customQueryBar.customQuery?.customQueryDefinitionObject.filter;
        }
        return this.props.rootFilter;
    }

    protected async loadEquipmentResourcesOnMap(resetAtNextUpdate?: boolean) {
        if (this.mapContainerRef.current) {
            if (resetAtNextUpdate) {
                this.props.dispatchers.setInReduxState({ equipmentResourcesList: [], equipmentResourcesToDisplayIds: [], mostRecentUpdateEquipmentResource: undefined, mostRecentUpdatedMapBounds: undefined });
                this.mapContainerRef.current!.clearMap();
            }
            this.props.dispatchers.setInReduxState({ loadUUID: uuid() });
            await this.props.dispatchers.loadEquipmentResources(this.props.rootReducerForPages!, this.getRootFilter(), [], this.mapContainerRef.current!, this.props.mapSettings, this.mapContainerRef.current!.getCurrentBounds());
            this.startTimer();
        }
    }
    
    protected async loadAndAddTerritoriesOnMap(){
        await this.props.dispatchers.loadTerritoriesForMap(this.mapContainerRef.current?.getCurrentBounds());
        this.props.dispatchers.addTerritoriesOnMap(this.mapContainerRef.current);
    }

    protected showTerritoriesOnMap(showTerritories: boolean){
        if (showTerritories){
            this.loadAndAddTerritoriesOnMap();
        } else {
            this.mapContainerRef.current?.removeLayers(TERRITORY);
        }

        this.setState({showTerritories: showTerritories});
    }

    componentDidMount() {
        const filterBarCheckboxes: {[key: string]: boolean} = {};
        if (this.props.mapSettings) {
            EquipmentResourceUtils.getEquipmentResourceMapSettings(this.props.mapSettings)?.colors.filter(color => color.showInFilterBar).forEach(color => {
                EquipmentResourceUtils.getMarkerColorIntervals(color).forEach(interval => { filterBarCheckboxes[color.field + interval.from] = true; });
            });
            this.props.dispatchers.setInReduxState({ filterBarCheckboxes: filterBarCheckboxes });
        }
        if (this.props.equipmentResourcesList.length > 0) {
            this.props.dispatchers.updateEquipmentResourcesOnMap(this.props.rootReducerForPages!, filterEquipmentsUsingFilterBar(this.props.mapSettings, filterBarCheckboxes, this.props.equipmentResourcesList), this.mapContainerRef.current!, this.props.mapSettings);
        }
        this.componentDidUpdateInternal();

        this.loadEquipmentResourcesOnMap(true);
    }

    private startTimer() {
        this.timer = window.setTimeout(() => this.loadEquipmentResourcesOnMap(), refreshRateMillis);
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    private componentDidUpdateInternal(prevProps?: Props, prevState?: RealTimeMapLocalState) {
        // verify if [0, 0] -> default value; if not [0, 0], then it means the center was set by value from session storage, so we don't want to reset it
        if (lodash.isEqual(this.props.mapContainer.center, [0, 0]) && prevProps && prevProps.airports.length !== this.props.airports.length && this.props.mapSettings.airport !== null) {
            const airport = this.props.airports?.find(a => a.code === this.props.mapSettings.airport);
            this.props.dispatchers.selectAirport(airport?.id);
        }
        if (prevProps && (prevProps.airport !== this.props.airport || prevProps.airports.length !== this.props.airports.length)) {
            if (this.props.airport?.id) {
                this.props.dispatchers.selectAirport(this.props.airport.id);
            }
        }
        selectAirportOnCurrentOrganizationToFilterByChange(prevProps, this.props);
        if (prevProps?.mapContainer.selectedLayer === undefined && this.props.mapContainer.selectedLayer !== undefined) {
            this.setState({ drawerOpen: true });
        }
        if (!lodash.isEqual(prevProps?.mapContainer.selectedLayer, this.props.mapContainer.selectedLayer)) {
            this.listRef.current?.forceUpdateGrid();
            if (this.props.mapContainer.selectedLayer) {
                this.listRef.current?.scrollToRow(this.props.equipmentResourcesList.findIndex(er => er.id === this.props.mapContainer.selectedLayer?.id));
            }
        }
        if (prevState?.drawerOpen && !this.state.drawerOpen) {
            this.props.dispatchers.mapContainer.setInReduxState({ selectedLayer: undefined });
        }
        if (prevProps && (!lodash.isEqual(prevProps?.currentOrganizationToFilterBy, this.props.currentOrganizationToFilterBy) || (this.props.showCustomQueryBar ? !lodash.isEqual(prevProps?.customQueryBar.customQuery?.customQueryDefinitionObject.filter, this.props.customQueryBar.customQuery?.customQueryDefinitionObject.filter) : !lodash.isEqual(prevProps?.rootFilter, this.props.rootFilter)))) {
            this.loadEquipmentResourcesOnMap(true);
        }

        if (prevProps?.mostRecentUpdatedMapBounds != this.props.mostRecentUpdatedMapBounds) {
            if (this.state.showTerritories){
                this.loadAndAddTerritoriesOnMap();
            }
        }
        if (prevProps && Object.keys(prevProps.filterBarCheckboxes).length > 0 && prevProps.filterBarCheckboxes !== this.props.filterBarCheckboxes && this.mapContainerRef) {
            this.mapContainerRef.current!.clearMap();
            const filteredEquipmentResourceList = filterEquipmentsUsingFilterBar(this.props.mapSettings, this.props.filterBarCheckboxes, this.props.equipmentResourcesList);
            this.props.dispatchers.setInReduxState({ equipmentResourcesToDisplayIds: filteredEquipmentResourceList.map(er => er.id) });
            this.props.dispatchers.updateEquipmentResourcesOnMap(this.props.rootReducerForPages!, filteredEquipmentResourceList, this.mapContainerRef.current!, this.props.mapSettings);
        }
    }

    componentDidUpdate(prevProps: Props, prevState: RealTimeMapLocalState) {
        this.componentDidUpdateInternal(prevProps, prevState);
    }

    renderTooltipContent(layerData: any, type: string, additionalInfo?: { pointId?: ID }): React.ReactElement {
        if (type == EQUIPMENT_RESOURCE_TYPE) {
            const er = this.props.equipmentResourcesList.find(er => er.id === layerData.id!);
            return er ? EquipmentResourceUtils.renderSmallInfoArea(er, this.props.mapSettings) : <></>;
        } else if (type == TERRITORY) {            
            const terr = this.props.territoriesForMap?.find(terr => terr.id === layerData.id!);
            return terr ? <><div>{layerData.text}</div> {layerData.readableArea}</> : <></>;            
        }
        
        return <></>;
    }

    renderMarkerIcon(markerData: MarkerData, type: string): React.ReactNode {
        const er = this.props.equipmentResourcesList.find(er => er.id === markerData.id!);
        const markerSettings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(this.props.mapSettings);
        return er ? EquipmentResourceUtils.renderEquipmentResourceIcon(er, markerData, markerSettings) : <></>;
    }

    renderFilterBar() {
        const colors = EquipmentResourceUtils.getEquipmentResourceMapSettings(this.props.mapSettings)?.colors.filter(color => color.showInFilterBar);
        if (!colors?.length) {
            return null;
        }
        return <Segment style={{ padding: "0.5em 0.7em" }}>{colors?.map(color => {
            return EquipmentResourceUtils.getMarkerColorIntervals(color).map(interval => {
                const label = getDropdownItemLabel(equipmentResourceEntityDescriptor.getField(color.field), interval as FieldInterval);
                const checkbox = <Checkbox label={label} checked={this.props.filterBarCheckboxes[color.field + interval.from]} onChange={() => this.props.dispatchers.changeFilterBar(color.field + interval.from)} />;
                const colorAsNumber = ColorRegistry.INSTANCE!.get(interval.color);
                const colorAsHex = Utils.convertColorToHex(colorAsNumber);
                const dotProps = {
                    className: "MapRealTime_filterBar_item_dot MapRealTime_filterBar_item_dot_" + color.area,
                    style: colorAsNumber ? { display: "inline-block", backgroundColor: colorAsHex } : undefined
                };
                if (color.area === "main") {
                    return <div className="MapRealTime_filterBar_item" style={colorAsNumber ? { borderColor: colorAsHex } : undefined }>{checkbox}</div>;
                } else if (color.area === "topLeft" || color.area === "bottomLeft") {
                    return <>
                        <div {...dotProps} />
                        <div className="MapRealTime_filterBar_item" >{checkbox}</div>
                    </>
                } else if (color.area === "topRight" || color.area === "bottomRight") {
                    return <>
                        <div className="MapRealTime_filterBar_item" >{checkbox}</div>
                        <div {...dotProps} />
                    </>;
                }
            }).map(interval => <div className="MapRealTime_filterBar_item_container">{interval}</div>);
        })}</Segment>;
    }

    protected listRowRenderer(listRowProps: ListRowProps) {
        const { props } = this;
        const er = props.equipmentResourcesList.find(er => er.id === props.equipmentResourcesToDisplayIds[listRowProps.index!])!;
        return <div style={listRowProps.style} key={listRowProps.key}
            onClick={() => props.dispatchers.mapContainer.setInReduxState({ selectedLayer: { id: er.id, type: EQUIPMENT_RESOURCE_TYPE } })}>
            <div className="flex-container-row flex-center gap5" style={{ backgroundColor: props.mapContainer.selectedLayer?.id === er.id ? "lightgray" : undefined }}>
                <div>{EquipmentResourceUtils.getImage(er, true)}</div>
                <div className="flex-container gap3">
                    <h4 className="no-margin">{equipmentResourceEntityDescriptor.getField("identifier").renderField(er)}</h4>
                    <div className="flex-container gap3">
                        <div className="flex-container-row gap3">
                            {equipmentResourceEntityDescriptor.getField("engineState").renderField(er, { asLabel: true, showIcon: true, showTooltip: true })}
                            {equipmentResourceEntityDescriptor.getField("status").renderField(er, { asLabel: true, showIcon: true, showTooltip: true })}
                        </div>
                        <Popup flowing position="bottom center"
                            trigger={<span style={{ whiteSpace: "nowrap" }}>{_msg("EquipmentResource.lastDetectionDate.label")}: {moment(er.lastDetectionDate).fromNow()}</span>}>
                            <Popup.Content className="flex-container-row gap3" >
                                <div className="flex-container flex-center">
                                    <div><Icon name="truck" />{_msg("EquipmentResource.lastDetectionDate.label")}</div>
                                    <Label>{er.lastDetectionDate ? moment(er.lastDetectionDate).format(Utils.dateTimeWithSecFormat) : _msg("general.notAvailable")}</Label>
                                </div>
                                <div className="flex-container flex-center">
                                    <div><Icon name="database" />{_msg("EquipmentResource.updated.label")}</div>
                                    <Label>{er.updated ? moment(er.updated).format(Utils.dateTimeWithSecFormat) : _msg("general.notAvailable")}</Label>
                                </div>
                            </Popup.Content>
                        </Popup>
                    </div>
                </div>
            </div>
            <Divider className="less-margin-top-bottom" /></div>
    }

    render() {
        var that = this;
        return (<div className="flex-container flex-grow less-padding" >
            <Segment className="less-padding less-margin-bottom MapRealTime_topBar">
                <span ref={this.buttonBarRef} />
                <div style={{ width: "100%", display: "flex" }}>
                    {this.props.showCustomQueryBar ? <div className="CustomQueryBar_div">
                        <CustomQueryBar key="customQueryBar"
                            entityDescriptor={equipmentResourceEntityDescriptor.name} screen={equipmentResourceEntityDescriptor.name} {...this.props.customQueryBar}
                            dispatchers={this.props.dispatchers.customQueryBar} mode={CUSTOM_QUERY_BAR_MODE.TABLE} sortDisabled={true} />
                    </div> : null}
                    {this.props.showGoToTableButton ? <Button onClick={(e) => this.props.dispatchers.dispatch(push(equipmentResourceEntityDescriptor.getEntityTableUrl()))}
                        key={_msg('CustomQueryBar.widget.goToTable')}><Icon name={equipmentResourceEntityDescriptor.icon as SemanticICONS}></Icon>{_msg('CustomQueryBar.widget.goToTable')}</Button> : null}
                    <div style={{marginLeft: "auto", flexShrink: 0}}>
                        <Popup
                            open={this.state.isFindEquipmentResourcePopupOpen}
                            onOpen={() => that.setState({ isFindEquipmentResourcePopupOpen: true })}
                            trigger={<Button color='olive' icon={equipmentResourceEntityDescriptor.icon} content={_msg("MapRealTime.equipment")} />}
                            content={<div className="MapRealTime_topBar_padding MapRealTime_topRightBar_search" style={{ maxWidth: "250px", minWidth: "250px" }}>
                                <EquipmentResourceManyToOneEditorStandalone rootFilter={that.getRootFilter()}
                                    additionalSearchFields={["plateNumber"]}
                                    focus onChange={async value => {
                                        if (value) {
                                            await that.props.dispatchers.getEquipment(value.id);
                                        }
                                        that.setState({ isFindEquipmentResourcePopupOpen: false });
                                    }}
                                /></div>}
                            on='click' position='bottom right' />
                        <Popup
                            open={this.state.isGoToAirportPopupOpen}
                            trigger={<Button color='olive' icon={airportEntityDescriptor.icon} content={_msg("MapRealTime.airport")} />}
                            onOpen={() => that.setState({ isGoToAirportPopupOpen: true })}
                            content={<Dropdown style={{ maxWidth: "250px", minWidth: "250px" }} data-cy={"dropdownMRT"} ref={this.airportDropdownRef} search selection
                                searchInput={{ autoFocus: true }}
                                options={this.props.airports.map((airport: AirportForMap) => ({ key: airport.id, value: airport.id, text: airport.code + " - " + airport.name }))}
                                onChange={(evt: any, props: DropdownProps) => {
                                    this.props.dispatchers.selectAirport(props.value as number);
                                    that.setState({ isGoToAirportPopupOpen: false });
                                }} />}
                            on='click' position='bottom right'
                        />
                        <Button color={this.state.showTerritories ? "orange" : "teal"} onClick={() => this.showTerritoriesOnMap(!this.state.showTerritories)}><Icon name='object ungroup outline' />{_msg(this.state.showTerritories ? "MapRealTime.hideTerritories" : "MapRealTime.showTerritories")}</Button>
                    </div></div>
            </Segment>
            <SplitPaneExt minSize={250} size="25%">
                <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width || 0, measuredHeight: contentRect.bounds?.height || 0 })}>
                    {({ measureRef }) => (<div ref={measureRef} className="flex-container flex-grow-shrink-no-overflow gap5">
                        <Label basic color="orange">{_msg("MapRealTime.noEquipmentResources", this.props.equipmentResourcesToDisplayIds.length)}</Label>
                        <ListVirtualized ref={this.listRef} className="wh100" style={{ padding: "5px", backgroundColor: "white" }}
                            width={this.state.measuredWidth}
                            height={this.state.measuredHeight}
                            rowCount={this.props.equipmentResourcesToDisplayIds.length}
                            rowHeight={85} estimatedRowSize={85}
                            rowRenderer={this.listRowRenderer}
                        />
                    </div>)}
                </Measure>
                <div className="no-padding-margin flex-container flex-grow-shrink-no-overflow MapRealTime_topParent" >
                    {this.renderFilterBar()}
                    <MapContainerLeaflet {...this.props.mapContainer} dispatchers={this.props.dispatchers.mapContainer} ref={this.mapContainerRef}
                        pruneClusterMode={localStorage.getItem(MAP_CLUSTER_MODE_KEY) === CLUSTER_MODE.PRUNE_CLUSTER}
                        renderTooltipContent={this.renderTooltipContent} renderMarkerIcon={this.renderMarkerIcon}
                        bingAPIKey={this.props.mapSettings.bingAPIKey} mapId={this.props.mapId} saveCenterZoomInStorage={true}
                        layers={{ [EQUIPMENT_RESOURCE_TYPE]: { layerType: MARKER_TYPE, options: { flyToSelectedMarker: false }}, 
                        [TERRITORY]: { layerType: POLYGON_TYPE, options: { flyToSelectedMarker: false, hideStyleOnSelectedLayer: true, hideStyleOnHoveredLayer: true }}}} />
                    <Drawer data-cy={"drawerMRT"} className="MapRealTime_drawer" getContainer={false} closable={false}
                        onClose={() => this.setState({ drawerOpen: false })}
                        afterVisibleChange={visible => !visible}
                        placement="right" visible={this.state.drawerOpen && this.props.mapContainer.selectedLayer?.type === EQUIPMENT_RESOURCE_TYPE} width={this.state.drawerOpen ? 400 : 0}>
                        {this.props.mapContainer.selectedLayer ? 
                        <EquipmentResourceBigInfoArea eq={this.props.equipmentResourcesList.find(er => er.id === this.props.mapContainer.selectedLayer?.id)!} mapMarkSettings={this.props.mapSettings} /> 
                        : undefined}
                    </Drawer>
                </div>
            </SplitPaneExt>
        </div>
        )
    }
}

// added here because multiple maps are using airport coordinates to center the map and,
// for the moment, this behavior isn't centralized in a single class
export function selectAirportOnCurrentOrganizationToFilterByChange(prevProps: any, props: any) {
    let org = global.currentOrganizationToFilterBy;
    if (org && org.id !== prevProps?.currentOrganizationToFilterBy?.id) {
        let airport: Optional<AirportForMap>;
        while (!airport && org) {
            airport = (org as any).airport;
            org = org?.parent;
        }
        airport?.id && props.dispatchers.selectAirport(airport.id);
    }
}

function filterEquipmentsUsingFilterBar(mapSettings: MapSettings, fitlerBarCheckboxes: {[key: string]: boolean}, equipmentResources: EquipmentResourceForMap[]) {
    const markerSettings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(mapSettings);
    markerSettings?.colors.filter(color => color.showInFilterBar).forEach(color => {
        const intervals = EquipmentResourceUtils.getMarkerColorIntervals(color).filter(interval => fitlerBarCheckboxes[color.field + interval.from]);
        equipmentResources = intervals.length === 0 ? [] : equipmentResources.filter(er => {
            try {
                const fd = equipmentResourceEntityDescriptor.getField(color.field);                
                let fieldValue = equipmentResourceEntityDescriptor.getField(color.field).getFieldValue(er);
                for (let i = 0; i < intervals.length; i ++) {
                    if (EquipmentResourceUtils.checkIfEquipmentInInterval(intervals[i], color.useDurationBetweenNowAndFieldValue, fieldValue, fd)) {
                        return true;
                    }
                }
            } catch (e) {
                console.log("Field not found: " + color.field);
            }
           
            return false;
        });
    });
    return equipmentResources;
}
