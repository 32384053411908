import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { AddressEntityDescriptor } from "pages/Address/AddressEntityDescriptor";
import { BlocklyScriptXopsEntityDescriptor } from "pages/blocklyScriptXops/BlocklyScriptXopsEntityDescriptor";
import { FoundationEntityDescriptors } from "@crispico/foundation-react/FoundationEntityDescriptors";
import { QualificationEntityDescriptor } from "pages/QualificationEntityDescriptor";
import { FlightEntityDescriptor } from "pages/flight/FlightEntityDescriptor";
import { Mission2EntityDescriptor } from "pages/Mission2/Mission2EntityDescriptor";
import { HumanResourceEntityDescriptor } from "pages/HumanResourceEntityDescriptor";
import { BaggageEntityDescriptor } from "pages/BaggageEntityDescriptor";
import { ChartEntityDescriptor } from "pages/Chart/chartEntityDescriptor";
import { GanttAssignmentEntityDescriptor } from "pages/ganttAssignment/GanttAssignmentEntityDescriptor";
import { GanttAssignmentParamsEntityDescriptor } from "pages/ganttAssignment/GanttAssignmentParamsEntityDescriptor";

// TODO by CS: I think they will disappear because of the new menu system
export let addressEntityDescriptor!: EntityDescriptor;
export let blocklyScriptEntityDescriptor!: EntityDescriptor;
export let chartEntityDescriptor!: EntityDescriptor;
export let ganttAssignmentEntityDescriptor!: EntityDescriptor;
export let ganttAssignmentParamsEntityDescriptor!: EntityDescriptor;
export let humanResourceEntityDescriptor: EntityDescriptor;
export let qualificationEntityDescriptor: EntityDescriptor;
export let flightEntityDescriptor: EntityDescriptor;
export let missionEntityDescriptor: EntityDescriptor;
export let baggageEntityDescriptor: EntityDescriptor;

export class AppEntityDescriptors extends FoundationEntityDescriptors {

    init() {
        super.init();

        // please keep sorted alphabetically
        addressEntityDescriptor = addEntityDescriptor(new AddressEntityDescriptor({ name: "Address", icon: "map marker" }));
        blocklyScriptEntityDescriptor = addEntityDescriptor(new BlocklyScriptXopsEntityDescriptor({ name: "BlocklyScriptXops" }));
        addEntityDescriptor(new EntityDescriptor({ name: "EquipmentUsageLog", icon: "clock outline" }))
            .isInDefaultColumnConfig(false, "user");

        chartEntityDescriptor = addEntityDescriptor(new ChartEntityDescriptor());

        flightEntityDescriptor = addEntityDescriptor(new FlightEntityDescriptor({
            name: "Flight", icon: "plane",
            defaultFilter: { field: "date", operator: FilterOperators.forDate.today.value, enabled: true },
            defaultSort: { field: "date", direction: "DESC" }
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "FlightAndObjectAction",
            icon: "plane"
        })
        );

        ganttAssignmentEntityDescriptor = addEntityDescriptor(new GanttAssignmentEntityDescriptor());

        ganttAssignmentParamsEntityDescriptor = addEntityDescriptor(new GanttAssignmentParamsEntityDescriptor());

        humanResourceEntityDescriptor = addEntityDescriptor(new HumanResourceEntityDescriptor({
            name: "HumanResource", icon: "male",
            miniFields: ["identifier", "firstName", "lastName"],
        }));

        missionEntityDescriptor = addEntityDescriptor(new Mission2EntityDescriptor({
            name: "Mission2", icon: "list",  miniFields: ["id"],
            defaultFilter: { field: "creationDate", operator: FilterOperators.forDate.today.value, enabled: true },
            defaultSort: { field: "creationDate", direction: "DESC" }
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "MissionType",
            miniFields: ["name"],
        }).addFieldDescriptor({ name: "color", type: FieldType.color }));

        addEntityDescriptor(new EntityDescriptor({ name: "Puck", icon: "paperclip", miniFields: ["name", "territory.name"] }));
        qualificationEntityDescriptor = addEntityDescriptor(new QualificationEntityDescriptor({ name: "Qualification", icon: "check circle outline", miniFields: ["qualificationType.name"] }));

        addEntityDescriptor(new EntityDescriptor({ name: "QualificationType", icon: "check circle outline" })
            .isInDefaultColumnConfig(true, "name", "description")
            .addFieldDescriptor({ name: "description", type: "text" }));

        addEntityDescriptor(new EntityDescriptor({ name: "PlaneType", icon: "plane" }));

        baggageEntityDescriptor = addEntityDescriptor(new BaggageEntityDescriptor({
            name: "Baggage",
            miniFields: ["number"],
            defaultFilter: { field: "date", operator: FilterOperators.forDate.today.value, enabled: true },
            defaultSort: { field: "date", direction: "DESC" }
        }));
        return this;
    }
}
