import { StatePartialFrom } from "@crispico/foundation-react";
import { TerritoryForMap } from "apollo-gen/TerritoryForMap";
import { sliceEntityTablePageTerritory } from "pages/Territory/territoryEntityDescriptor";
import { EditMode } from "components/MapContainerLeaflet/MapContainerLeaflet";

const territories: { [key: string]: TerritoryForMap } = {
    '-1': {
        id: -1,
        name: 'North Escape Shaft',
        color: '#30741a',
        version: 0,
        coordinates: [
            {
                a: -0.4541194438934326,
                b: 51.47225493713279,
            },
            {
                a: -0.45409798622131353,
                b: 51.47201435128375,
            },
            {
                a: -0.45379757881164545,
                b: 51.47202103424112,
            },
            {
                a: -0.45378684997558594,
                b: 51.47230840048249,
            },
            {
                a: -0.4541194438934326,
                b: 51.47225493713279,
            }
        ],
    },
    '-2': {
        id: -2,
        name: 'Heathrow Police',
        color: '#e823cd',
        version: 0,
        coordinates: [
            {
                a: -0.4536259174346923,
                b: 51.48030046642205,
            },
            {
                a: -0.45360445976257313,
                b: 51.47918460152741,
            },
            {
                a: -0.4513728618621825,
                b: 51.479191283434375,
            },
            {
                a: -0.45134067535400385,
                b: 51.48030714816554,
            },
            {
                a: -0.4536259174346923,
                b: 51.48030046642205,
            }
        ],
    },
    '-3': {
        id: -3,
        name: 'South Escape Shaft',
        color: '#11abea',
        version: 0,
        coordinates: [
            {
                a: -0.45352399349212635,
                b: 51.47348123695309,
            },
            {
                a: -0.4534649848937988,
                b: 51.47266927645421,
            },
            {
                a: -0.45242965221405024,
                b: 51.472679300745995,
            },
            {
                a: -0.4524564743041991,
                b: 51.47350796791724,
            },
            {
                a: -0.45352399349212635,
                b: 51.47348123695309,
            }
        ],
    },
    '-4': {
        id: -4,
        name: 'Terminal 1',
        color: null,
        version: 0,
        coordinates: [
            {
                a: -0.4555141925811767,
                b: 51.47256569197663,
            },
            {
                a: -0.45528888702392567,
                b: 51.47187400895257,
            },
            {
                a: -0.45411407947540283,
                b: 51.47228835173368,
            },
            {
                a: -0.45347571372985834,
                b: 51.472582399166356,
            },
            {
                a: -0.4537761211395264,
                b: 51.47296332143162,
            },
            {
                a: -0.4555141925811767,
                b: 51.47256569197663,
            }
        ],
    },
    '-5': {
        id: -5,
        name: 'Bus Station',
        color: null,
        version: 0,
        coordinates: [
            {
                a: -0.4542267322540283,
                b: 51.471452979368564,
            },
            {
                a: -0.4542320966720581,
                b: 51.47076796261484,
            },
            {
                a: -0.4522901773452758,
                b: 51.47081474457403,
            },
            {
                a: -0.452311635017395,
                b: 51.47167017765506,
            },
            {
                a: -0.4542428255081176,
                b: 51.47163676260135,
            },
            {
                a: -0.4542267322540283,
                b: 51.471452979368564,
            }
        ],
    }
}

const tableTerr = [
    { id: -1, name: "North Escape Shaft", color: '#30741a' },
    { id: -2, name: "Heathrow Police", color: '#e823cd' },
    { id: -3, name: "South Escape Shaft", color: '#11abea' },
    { id: -4, name: "Terminal 1" },
    { id: -5, name: "Bus Station" }
]

const mapContainer = {
    pruneClusterMode: false,
    editDrawEnabledOnType: "territory",
    editInProgress: false,
    center: [51.479745878300115, -0.45248329639434814],
    zoom: 18,
    highlightedPointsOnLayer: [],
    selectedLayer: {
        id: -2,
        type: "territory"
    }
}

export const testStateWithData: StatePartialFrom<typeof sliceEntityTablePageTerritory> = {
    totalCount: tableTerr.length,
    loaded: tableTerr.length,
    territories: territories,
    tableSimple: {
        selected: -2,
        contextMenuRowIndex: -1,
        entities: tableTerr
    },
    columnConfigDropdown: {
        columnConfig: {
            dirty: false,
            id: null,
            name: _msg('general.default'),
            entityName: 'Territory',
            configObject: {
                columns: [
                    { name: "id", width: 120 },
                    { name: "name", width: 120 },
                    { name: "description", width: 120 },
                    { name: "color", width: 120 },
                    { name: "tags", width: 120 }
                ]
            },
            fromCrudSettings: false,
            organization: null
        },
    },
    mapContainer: {
        ...mapContainer,
        hoveredLayer: {
            id: -2,
            type: "territory"
        }
    }
}

export const testStateEditEnabled: StatePartialFrom<typeof sliceEntityTablePageTerritory> = {
    ...testStateWithData,
    mapContainer: {
        ...mapContainer,
        editMode: EditMode.EDIT
    }
}

export const testStateDrawEnabled: StatePartialFrom<typeof sliceEntityTablePageTerritory> = {
    ...testStateWithData,
    mapContainer: {
        ...mapContainer,
        editMode: EditMode.DRAW
    }
}

export const testStateRemoveEnabled: StatePartialFrom<typeof sliceEntityTablePageTerritory> = {
    ...testStateWithData,
    mapContainer: {
        ...mapContainer,
        editMode: EditMode.REMOVE
    }
}
