import { apolloClient, DispatchersFrom, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { DatePickerExt } from "@crispico/foundation-react/components/DatePickerExt/DatePickerExt";
import { Reducers, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Chart } from "apollo-gen/Chart";
import gql from "graphql-tag";
import moment from "moment";
import { Moment } from "moment";
import React from "react";
import { Button, Form, Popup, Segment } from "semantic-ui-react";
import { sliceEntityEditorPageChart } from "./chartEntityDescriptor";

export class ChartTabState extends State {
    selectedDate = undefined as number | undefined;
}

export class ChartTabReducers<S extends ChartTabState = ChartTabState> extends Reducers<S> {
    prepareData(p: { savedData: string, config: ChartConfig }) {
        // nop; to be implemented by each chart type
    }
}

export interface ChartConfig {
    startDate: string;
    endDate: string;
    equipmentFilter: Filter;
    territoryIds: Array<number>;
    tolerance: number;
}

export type ChartTabProps = RRCProps<ChartTabState, ChartTabReducers> & { editorDispatchers: DispatchersFrom<typeof sliceEntityEditorPageChart>, entity: Chart, config: ChartConfig, zeroTrainingMode: boolean };
export type ChartTabLocalState = {};
export abstract class ChartTab<C extends ChartTabProps, L extends ChartTabLocalState> extends React.Component<C, L> {
    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: ChartTabProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: ChartTabProps) {
        const props = this.props;
        if (!prevProps && props.entity.savedData) {
            if (props.zeroTrainingMode) { return };
            props.r.setInReduxState({ selectedDate: moment(props.config.startDate).toDate().getTime() });
            props.r.prepareData({ savedData: props.entity.savedData, config: props.config });
        }
    }
    
    async generateChart(entity: Chart, config: ChartConfig, editorDispatchers: DispatchersFrom<typeof sliceEntityEditorPageChart>) {
        editorDispatchers.setInReduxState({ config: {...config, ...{ startDate: this.props.s.selectedDate ? this.props.s.selectedDate : moment().toDate().getTime(), endDate: moment().toDate().getTime() }} });
        await editorDispatchers.save(entity);
        await apolloClient.query({
            query: gql(`query q($id: Long!) {
                chartService_runForcedGeneration(id: $id)
                }`),
            variables: { id: entity.id }
        });
        editorDispatchers.load(entity.id);
    }

    renderTopBar(startDate: Moment, endDate: Moment) {
        const props = this.props;
        return <Segment className="flex-container-row flex-center less-padding no-margin">
            {_msg("HistogramCountInTerritoriesTab.startDate.label")}: <Form style={{ display: "inline-block" }}><DatePickerExt style={{ width: 130, margin: "0 1em" }} value={props.s.selectedDate ? moment(props.s.selectedDate) : null} onSelect={(data) => props.r.setInReduxState({ selectedDate: data.toDate().getTime() }) } /></Form>
            <Popup
                trigger={<Button color='green' onClick={(event: any) => this.generateChart(props.entity, props.config, props.editorDispatchers)}>{_msg("EquipmentUsageReport.generate")}</Button>}
                position="right center"
            >{_msg("HistogramCountInTerritoriesTab.generate.tooltip")}</Popup>
            {props.s.selectedDate && props.entity.savedData ? <span className="flex-grow-shrink-no-overflow" style={{textAlign: "right"}}>{_msg("HistogramCountInTerritoriesTab.generate.period")}: <>{startDate.format(Utils.dateTimeFormat)}</> &#8212; <>{endDate.format(Utils.dateTimeFormat)}</></span> : null}
        </Segment>;
    }
}

export function getChartGenerationPeriodFromConfig(config: ChartConfig) {
    const startDate = moment(config.startDate).startOf("day").toDate().getTime();
    const endDate = startDate === moment(config.endDate).startOf("day").toDate().getTime() ? moment(config.endDate).toDate().getTime() : moment(config.startDate).endOf("day").toDate().getTime();
    return { startDate, endDate };
}
