import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import gql from "graphql-tag";
import React from "react";
import lodash from "lodash";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";

export const sliceAuditFieldRenderer = createSliceFoundation(class sliceAuditFieldRenderer {
    initialState = {
        fd: {} as FieldDescriptor | undefined,
        entity: {} as any
    }

    reducers = {
        ...getBaseReducers<sliceAuditFieldRenderer>(this),
    }

    impures = {
        ...getBaseImpures<sliceAuditFieldRenderer>(this),
        async loadData(fd?: FieldDescriptor, entity?: any){
            if (!fd){
                return null;
            }
            const fieldRenderer = fieldRenderers[fd.type];
            if (fieldRenderer){
                // normal field, nothing to do
                return entity
            } 

            // entity field, needs call for miniFields
            const loadOperationName = `${lodash.lowerFirst(entityDescriptors[fd.type].name)}Service_findById`;
            const loadQuery = gql(`query q($id: Long) { 
                ${loadOperationName}(id: $id) {
                    ${ID} ${entityDescriptors[fd.type].miniFields.join(" ")} 
                }
            }`);
            const entityResult = (await apolloClientHolder.apolloClient.query({ query: loadQuery, variables: { id: entity[fd.name] } })).data[loadOperationName];
            if (!entityResult){
                // if the entity was deleted, we return nothing
                return null;
            }
            return {[fd.name]: entityResult}
        }
    }
})

export class AuditFieldRenderer extends React.Component<PropsFrom<typeof sliceAuditFieldRenderer>, {entity: any}> {

    constructor(props: PropsFrom<typeof sliceAuditFieldRenderer>){
        super(props);
        this.state = {entity: undefined}
    }

    async componentDidMount(){
        const entity = await this.props.dispatchers.loadData(this.props.fd, this.props.entity)
        if (!entity){
            return;
        }        
        this.setState({entity: entity})    
    }

    render() {
        return <>
            {this.state.entity ? this.props.fd?.renderField(this.state.entity) : this.props.fd?.getFieldValue(this.props.entity)}
        </>
    }
}