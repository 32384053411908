import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, Optional, TestUtils, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { InitializationsForClient } from "app";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Button, Header, Label, Message } from "semantic-ui-react";
import { MISSIONS_AWAITING_FOR_DRIVERS_PAGE_LOAD_MISSIONS_QUERY } from "../flight/flightsAssignmentsPage/queries";
import { MissionsAwaitingForDriversPage_Mission } from "apollo-gen/MissionsAwaitingForDriversPage_Mission";
import { MissionsAwaitingForDriversPage_loadMissionsQuery, MissionsAwaitingForDriversPage_loadMissionsQueryVariables } from "apollo-gen/MissionsAwaitingForDriversPage_loadMissionsQuery";

type MissionData = {
    conveyorEjects: Optional<string[]>
}
export class MissionsAwaitingForDriversPageState extends State {
    missions: { [key: string]: MissionsAwaitingForDriversPage_Mission } = {};
    data: { [key: string]: MissionData } = {};
}

export class MissionsAwaitingForDriversPageReducers<S extends MissionsAwaitingForDriversPageState =MissionsAwaitingForDriversPageState> extends Reducers<S> {

}

export class MissionsAwaitingForDriversPage extends React.Component<RRCProps<MissionsAwaitingForDriversPageState, MissionsAwaitingForDriversPageReducers>> {

    private timer: number | undefined = undefined;
    private static REFRESH_RATE: number = 5000; // 5s

    protected async loadMissions() {
        const { tempSettingsXops, currentOrganization } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        const organizationSettings = tempSettingsXops.tempUnitSettings.find(s => s.organizationId === currentOrganization?.id);
        const missionType = organizationSettings?.missionTypesToDisplay?.[0].id;
        const filters: Filter[] = [
            { field: "creationDate", operator: FilterOperators.forDate.greaterThan.value, value: moment(Utils.now()).add(-12, "hours").toISOString() },
            { field: "creationDate", operator: FilterOperators.forDate.lessThan.value, value: moment(Utils.now()).add(12, "hours").toISOString() },
            { field: "humanResource", operator: FilterOperators.forEntityManyToOne.isEmpty.value, value: "" }
        ];
        if (missionType) {
            filters.push({ field: "type", operator: FilterOperators.forEntityManyToOne.equals.value, value: missionType.toString() },)
        }
        const sorts: Sort[] = [{ field: "creationDate", direction: "ASC" }];

        const result = (await apolloClient.query<MissionsAwaitingForDriversPage_loadMissionsQuery, MissionsAwaitingForDriversPage_loadMissionsQueryVariables>({
            query: MISSIONS_AWAITING_FOR_DRIVERS_PAGE_LOAD_MISSIONS_QUERY,
            variables: FindByFilterParams.create().filter({ operator: FilterOperators.forComposedFilter.and.value, filters: filters }).sorts(sorts)
        })).data.mission2Service_findByFilter?.results;

        var newMissions: { [key: string]: MissionsAwaitingForDriversPage_Mission } = {};
        var newData: { [key: string]: MissionData } = {};
        if (result) {
            result.forEach(m => {
                newMissions[m.id] = m;

                let conveyorEjects: string[] = [];
                const { tempSettingsXops } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
                m.missionSubsets?.forEach(ms => {
                    const fj = tempSettingsXops.tempFlightConveyorEjectSettings.find(fj => fj.parkings.find(p => p.id === ms?.flight?.parking?.id));
                    if (fj) {
                        conveyorEjects.push(fj.conveyorEject);
                    }
                });
                newData[m.id] = {
                    conveyorEjects: conveyorEjects
                }
            })
        }
        this.props.r.setInReduxState({ missions: newMissions, data: newData });
    }

    protected async assignHumanResourceToMission(missionId: number, humanResourceId?: number) {
        (await apolloClient.mutate({
            mutation: gql(`mutation mission2Service_assignHumanResourceToMission($missionId: Long, $humanResourceId: Long) {
                mission2Service_assignHumanResourceToMission(missionId: $missionId, humanResourceId: $humanResourceId)
        }`), variables: { missionId: missionId, humanResourceId: humanResourceId} 
        }));
        if ((AppMetaTempGlobals.appMetaInstance as any).getCurrentHumanResource()) {
            AppMetaTempGlobals.history.push("/xops-mobile/missions");
        } else {
            this.loadMissions();
        }        
    }

    private startTimer() {
        this.timer = window.setTimeout(() => {
            this.loadMissions();
            this.startTimer();
        }, MissionsAwaitingForDriversPage.REFRESH_RATE);
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    componentDidMount() {
        if (TestUtils.storybookMode) {
            return;
        }
        this.loadMissions();
        this.startTimer();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    protected renderMission(id: any) {
        const rawMission = this.props.s.missions[id];
        const dataMission = this.props.s.data[id];

        const duration = moment.duration(moment(rawMission.creationDate).diff(Utils.now()));
        return <Message key={id} color="red" className="less-margin-top-bottom MissionsAwaitingForDriversPage_message">
            <Message.Header>
                <div className="flex-container-row flex-center" >
                    <Header className="flex-grow">{_msg("FlightsAssignmentsPage.conveyorEject")} {!Utils.isNullOrEmpty(dataMission.conveyorEjects) ? dataMission.conveyorEjects?.join(" ") : _msg("general.unknown") }</Header>
                    <div className="flex-container float-right MissionsAwaitingForDriversPage_message_right">
                        <Header as='h6' style={{ color: "var(--textColor)" }}>{_msg("MissionsAwaitingForDriversPage.waitingFor")}</Header>
                        <span>{duration.humanize()}</span>
                        <Header as='h6' style={{ color: "var(--textColor)" }}>{_msg("MissionsAwaitingForDriversPage.from", moment(rawMission.creationDate).format(Utils.timeFormat))}</Header>
                    </div>
                    <Button primary onClick={() => this.assignHumanResourceToMission(id, undefined)}>{_msg("general.activate")}</Button>
                </div>
            </Message.Header>
            {rawMission.comment && <Message.List>
                <Message.Item>{rawMission.comment}</Message.Item>
            </Message.List>}
        </Message>
    }

    render() {
        return <div className="flex-container flex-center">            
            {Object.keys(this.props.s.missions).map(id => this.renderMission(id))}
        </div>;
    }
}

export const MissionsAwaitingForDriversPageHOC = ReduxReusableComponents.connectRRC(MissionsAwaitingForDriversPageState, MissionsAwaitingForDriversPageReducers, MissionsAwaitingForDriversPage);


