import { BlocklyReadOnly } from "@crispico/foundation-react/blockly/BlocklyReadOnly";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import React from "react";
import { Button, Icon, Label, Segment } from "semantic-ui-react";
import { Severity } from "../ModalExt/ModalExt";
import { createSortBlock, sliceSortBar, Sort } from "./SortBar";
import { SortForm } from "./SortForm";

interface SortTabProps {
    noApply?: boolean
    entityDescriptor: string
    setSortsInCustomQuery?: (sorts: Array<Sort>) => void
}

export class SortTab extends React.Component<PropsFrom<typeof sliceSortBar> & SortTabProps> {

    onSave = (sort: Sort) => {
        if (sort.field && !entityDescriptors[this.props.entityDescriptor].getField(sort.field).sortable) {
            Utils.showGlobalAlert({ message: _msg('Tag.noSort'), severity: Severity.INFO });
            return;
        }
        this.props.dispatchers.onSave(sort, this.props.setSortsInCustomQuery);
    }

    closeSortEditor = () => this.props.dispatchers.closeSortEditor();

    moveLeft = () => this.props.dispatchers.moveLeft(this.props.setSortsInCustomQuery);

    moveRight = () => this.props.dispatchers.moveRight(this.props.setSortsInCustomQuery);

    remove = () => this.props.dispatchers.deleteSort(this.props.indexForEditor, true, this.props.setSortsInCustomQuery);

    openSortEditor = (e: any, index: number) => {
        this.props.dispatchers.openSortEditor({ sortIndex: index, editorPostion: [e.clientX, e.clientY] });
    }

    render() {
        return (
            <>
                <div className='SortTab_container'>
                    <Segment className='SortBar_segment'>
                        {this.props.sorts.map((s, index) => <SortBlockForTab entityDescriptor={this.props.entityDescriptor} index={index} key={index} sort={s} openSortEditor={(e: any) => this.openSortEditor(e, index)}></SortBlockForTab>)}
                        <div className='SortBar_buttons'>
                            <Button color='orange' onClick={(e) => this.openSortEditor(e, -1)}>{_msg('general.add')}</Button>
                        </div>
                        <SortForm error={this.props.error} sorts={this.props.sorts} formOpened={this.props.editorOpened} {...this.props.sortForm} dispatchers={this.props.dispatchers.sortForm}
                            embeddedMode={true} entityDescriptor={this.props.entityDescriptor}
                            remove={this.remove} moveLeft={this.moveLeft} moveRight={this.moveRight}
                            onSave={this.onSave} closeSortEditor={this.closeSortEditor} indexForEditor={this.props.indexForEditor}
                        />
                    </Segment>
                </div>
            </>
        );
    }

}

interface SortBlockProps {
    index: number,
    toggleDirection?: any,
    deleteSort?: any,
    openSortEditor: any,
    sort: Sort,
    entityDescriptor: string
}

function SortBlockForTab(props: SortBlockProps) {
    return (
        <Segment>
            <div className='CustomQueryEditor_SortDiv'>
                <Label key={props.index} size='mini' className='CustomQueryBar_sortDiv'>
                    <div>
                        <Icon className='SortBar_sortIcon' size='large' name={props.sort.direction === 'ASC' ? 'sort up' : 'sort down'} />
                    </div>
                    <BlocklyReadOnly key={props.index}>{createSortBlock(props.sort, props.entityDescriptor)}</BlocklyReadOnly>
                </Label>
            </div>
            <Button floated='right' className='SortBar_editButton' onClick={props.openSortEditor} size='mini' icon="bars"></Button>
        </Segment>
    );
}