import { PropsForEditor } from "@crispico/foundation-react/components/DatePickerExt/DatePickerExt";
import { CrudViewer } from "@crispico/foundation-react/entity_crud/CrudViewer";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { ShortcutRefForTest } from "@crispico/foundation-react/testsAreDemo/ShortcutRefForTest";
import React from "react";
import { components, MenuProps } from "react-select";
import { Button, Container, Icon, Label, Menu, Modal, Table } from "semantic-ui-react";
import { CustomMenuProps, DropdownExt, DropdownOption } from "../../components/Dropdown/DropdownExt";
import { ChainItem, FieldNameContentAssistRRC, getMessageForField, FieldNameContentAssist } from "../../components/fieldNameContentAssist/FieldNameContentAssist";
import { ModalExt } from "../../components/ModalExt/ModalExt";
import { entityDescriptors } from "../../entity_crud/entityCrudConstants";
import { FieldEditorProps, FieldRendererProps } from "../../entity_crud/fieldRenderersEditors";
import { ConnectedComponentInSimpleComponent, ConnectedPageInfo } from "../../reduxHelpers/ConnectedPageHelper";
import { FIELDS_READ } from "../../utils/Utils";

export class FieldSelector extends React.Component<FieldEditorProps> {

    fieldNameContentAssistRef = React.createRef<FieldNameContentAssist>();

    dropdownRef = React.createRef<any>();

    state = {
        contextMenuPosition: false as boolean | [number, number],
        fieldNameContentAssistOpen: false,
        selectionError: false,
        entityName: undefined as string | undefined
    }

    componentDidUpdate(prevProps: FieldEditorProps) {
        if (prevProps.formikProps.values[this.props.fieldDescriptor.fieldForEntityName] !== this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName]) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, '')
        }
    }

    protected onChange = (selectedAsString: DropdownOption[]) => { this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, this.getValue(selectedAsString)) }

    protected setComposedField = (composedField: string, value: any) => {
        if (!value.includes(composedField)) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, (value.length > 1 && this.props.fieldDescriptor.allowMultiple ? value + ',' : '') + composedField)
        }
    }

    protected onSubmit = (options: any[], chain: ChainItem[]) => {
        if (chain.length === 0) {
            this.setState({ selectionError: true })
        } else {
            const composedField = chain.map(c => c.field).join('.')
            this.setComposedField(composedField, this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values))
            this.setState({ fieldNameContentAssistOpen: false })
        }
    }

    protected addAll = () => {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, this.getValue(this.getOptions()))
    }

    removeAll = () => {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, this.getValue([]))
    }

    protected getItemsFromValue(value: any): any[] {
        return value ? value.split(',') : [];
    }

    protected getValue(items: DropdownOption[]): any {
        return items.map(i => i.value).join(',');
    }

    protected getDropdownOptionFromItem(field: any, entityName: string): DropdownOption {
        return { value: field, label: getMessageForField(field, entityName) }
    }

    protected getEntityName() {
        return this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName];
    }

    protected getOptions() {
        const entityName = this.getEntityName();        
        const fields = entityDescriptors[entityName].getAuthorizedFields(FIELDS_READ);
        const options = [] as { value: string, label: string }[];

        for (const field of Object.keys(fields)) {
            if (this.props.fieldDescriptor.showOnlyAuditableFields && EntityDescriptorForServerUtils.getFieldId(entityName, field) === undefined) {
                continue;
            }
            if (this.props.fieldDescriptor.showOnlyComposedFields && !fields[field].typeIsEntity()) {
                continue;
            }
            options.push({ value: field, label: fields[field].getLabel() });
        }

        return options;
    }

    protected getDropdownProps() {
        const entityName = this.getEntityName();
        const entityDescriptor = entityDescriptors[entityName];
        if (this.state.entityName && this.state.entityName !== entityName) {
            // entityName has changed, but componentDidUpdate is called after this! so the reset done also here
            this.setState({ entityName: entityName });
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, '');
        }
        let selected: DropdownOption[] = []
        let options: DropdownOption[] = []
        if (entityDescriptor) {
            const fields = entityDescriptor.getAuthorizedFields(FIELDS_READ);
            options = this.getOptions()
            const value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values)
            const items = this.getItemsFromValue(value)
            selected = value ? items.map((item: any) => {
                const option = this.getDropdownOptionFromItem(item, entityName);
                if (!fields[item]) {
                    options.push(option)
                }
                return option
            }) : []
        }
        return { entityName: entityName, selected: selected, options: options };
    }

    getMenuOptions() {
        return [
            <Menu.Item disabled={!this.props.fieldDescriptor.allowMultiple} onClick={() => this.addAll()}><Icon name='add circle' /> {_msg('ColumnConfigEntityEditorPage.addAll')}</Menu.Item>,
            <Menu.Item onClick={() => this.removeAll()}><Icon name='remove circle' /> {_msg('ColumnConfigEntityEditorPage.removeAll')}</Menu.Item>,
            <Menu.Item disabled={this.props.fieldDescriptor.noComposedFields} onClick={() => this.setState({ fieldNameContentAssistOpen: true })}><Icon name='list layout' /> {_msg('form.composedField')}</Menu.Item>
        ];
    }

    renderModals(entityName: string) {
        return <>
            <ModalExt className='EntityTableSimple_menuModal' closeIcon={false} open={this.state.contextMenuPosition} onClick={() => this.setState({ contextMenuPosition: false })} onClose={() => this.setState({ contextMenuPosition: false })}>
                {this.state.contextMenuPosition && <Menu vertical className="wh100">{this.getMenuOptions()}</Menu>}
            </ModalExt>
            <ModalExt canChangeDimension open={this.state.fieldNameContentAssistOpen} onClose={() => this.setState({ fieldNameContentAssistOpen: false })} maxWidth={580}>
                <Modal.Header>{_msg('form.composedField.selection')}</Modal.Header>
                <Modal.Content>
                    <FieldNameContentAssistRRC id='fieldNameContentAssist' ref={this.fieldNameContentAssistRef} rootEntity={entityName} includeScalarFields={false} field=''/>
                    <ModalExt open={this.state.selectionError} onClose={() => this.setState({ selectionError: false })} maxWidth={400}>
                        <Modal.Header>{_msg('form.composedField.selection.error.header')}</Modal.Header>
                        <Modal.Content>
                            <p>{_msg('form.composedField.selection.error.message')}</p>
                            <Button centered onClick={() => this.setState({ selectionError: false })}>{_msg('general.ok')}</Button>
                        </Modal.Content>
                    </ModalExt>
                </Modal.Content>
                <Modal.Actions>
                    <Button className='FilterForm_applyButton' color='green' onClick={() => this.onSubmit(this.fieldNameContentAssistRef.current?.getOptions()!, this.fieldNameContentAssistRef.current?.getChain()!)}>{_msg('general.ok')}</Button>
                    <Button className='FilterForm_applyButton' color='red' onClick={() => this.setState({ fieldNameContentAssistOpen: false })}>{_msg('general.cancel')}</Button>
                </Modal.Actions>
            </ModalExt>
        </>;
    }

    renderButtons(propsForEditor: any) {
        return !propsForEditor?.hideOptions && !this.props.fieldDescriptor.showOnlyComposedFields && <Button primary icon="bars" floated='right' onClick={e => { e.preventDefault(); this.setState({ contextMenuPosition: [e.clientX, e.clientY] }) }} />;
    }

    renderMain(selected: DropdownOption[], options: DropdownOption[], propsForEditor: any) {
        return <div className='flex-container-row flex-grow-shrink-no-overflow'>
            <div className='flex-grow-shrink-no-overflow'>
                <DropdownExt options={options} selected={selected} onChange={this.onChange}
                    allowMultiple={this.props.fieldDescriptor.allowMultiple}
                    hideOptions={propsForEditor?.hideOptions} appendDoneMenuEntry={true} closeOnKeys={["Enter", "ArrowUp", "ArrowDown"]}
                    selectProps={{ placeholder: propsForEditor?.placeholder, isSearchable: propsForEditor?.isSearchable,
                        formatOptionLabel: propsForEditor?.formatOptionLabel, customMenu: propsForEditor?.customMenu
                    }} 
                />
            </div>
            <div style={{flexGrow: 0}}>{this.renderButtons(propsForEditor)}</div>
            <ShortcutRefForTest objectToPublish={this}/>
        </div>
    }

    render() {
        const { entityName, selected, options } = this.getDropdownProps();
        return <>
            {this.renderMain(selected, options, this.props.fieldDescriptor.propsForEditor)}
            {this.renderModals(entityName)}
        </>
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    ////// Test functions
    ////////////////////////////////////////////////////////////////////////////////////////
    tadRemoveAll = () => {
        this.removeAll();
    }

    tadAddOption = (value: string) => {
        let selectedOption = this.getOptions().filter((option: { value: string, label: string }) => option.label.toLowerCase().includes(value.toLowerCase()));
        let previousValues = this.props.formikProps.values[this.props.fieldDescriptor.name];
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, {columns : [...previousValues?.columns, ...this.getValue(selectedOption)?.columns ]});
    }
}

export const EntityFieldsFieldRenderer = (props: FieldRendererProps) => {
    const { value, entity, fieldDescriptor } = props;
    return <>
        {value ? value.split(',').map((field: string) =>
            <Label>{getMessageForField(field, entity[fieldDescriptor.fieldForEntityName])}</Label>
        ) : []}
    </>
}

const CrudViewerAsDropdownMenu = (entityDescriptor: EntityDescriptor, entity: any, selected: DropdownOption[], onRowClick: (field: string) => void) => (p: CustomMenuProps) => (props: MenuProps<any>) => {
    const selectedValues = selected.map(s => s.value);
    const inputValue = props.selectProps.inputValue ? props.selectProps.inputValue : "";
    const optionsFilteredBySearch = props.options.filter(o => {
        const input = inputValue.toLowerCase();
        const label = o.label.toLowerCase();
        const value = o.value.toLowerCase();
        if (label && label !== value && label.indexOf(input) > -1) {
            return true;
        } else if (value && value.indexOf(input) > -1) {
            return true;
        } else {
            return false;
        }
    });
    const optionsWithoutSelected = optionsFilteredBySearch.map(o => o.value).filter(o => !selectedValues.includes(o));

    return (
        <components.Menu {...props}>
            <Container className="CrudViewerAsDropdown">
                {p.appendDoneMenuEntry ? 
                    <Table attached="top" celled padded compact style={{borderBottom: "0px"}}><Table.Body>
                        <Table.Row className="SelectorExt_DoneTableRow" onClick={() => p.onDoneMenuEntryClick && p.onDoneMenuEntryClick()}>
                            <Table.Cell key="done"><div className="flex-container-row flex-wrap">{_msg("DropdownExt.done")}</div></Table.Cell>
                        </Table.Row>
                    </Table.Body></Table> : null}
                <CrudViewer entityDescriptor={entityDescriptor} entity={entity} fields={optionsWithoutSelected} onRowClick={onRowClick} {...{tableProps: {attached: p.appendDoneMenuEntry ? "bottom" : undefined}}} />
            </Container>
        </components.Menu>
    );
}
 
export type FieldSelectorWithCrudViewerPropsForEditor = PropsForEditor & {
    entityDescriptor: EntityDescriptor;
    entity?: any;
}
export class FieldSelectorWithCrudViewer extends FieldSelector {

    protected get propsForEditorCasted(): FieldSelectorWithCrudViewerPropsForEditor {
        return this.props.fieldDescriptor.propsForEditor as FieldSelectorWithCrudViewerPropsForEditor;
    }

    componentDidUpdate(prevProps: FieldEditorProps) {
        const { formikProps, fieldDescriptor } = this.props;
        if (prevProps.formikProps.values[fieldDescriptor.name] !== formikProps.values[fieldDescriptor.name]) {
            this.propsForEditorCasted.onFieldsChange?.call(null, fieldDescriptor.name, formikProps.values[fieldDescriptor.name]);
        }
    }

    renderButtons(propsForEditor: any) {
        return <></>;
    }

    render() {
        const { entityName, selected, options } = this.getDropdownProps();
        const ed = entityDescriptors[entityName];
        const propsForEditor: any = {...this.props.fieldDescriptor.propsForEditor};
        propsForEditor.customMenu = CrudViewerAsDropdownMenu(ed, this.propsForEditorCasted.entity, selected, (field) => {
            const value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values);
            const items = this.getItemsFromValue(value);
            if (!items.includes(field)) {
                this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, items.concat([field]).join(","));
            }
        });
        return this.renderMain(selected, options, propsForEditor);
    }
}
