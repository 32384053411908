import { DEFAULT_COMPOSED_FILTER } from "../components/CustomQuery/BlocklyEditorTab";
import { Filter } from "../components/CustomQuery/Filter";
import { Sort } from "../components/CustomQuery/SortBar";
import { enableAllFilters } from "./entityCrudConstants";
import { EntityDescriptor } from "./EntityDescriptor";

export class ShareLinkLogic {

    private serializeForArray(objArr: any[]) {
        const newObjArr: any[] = [];
        objArr.forEach(obj => {
            let newObj: any = new Object();
            newObjArr.push(newObj);
            obj && Object.keys(obj).forEach(key => {
                if (key === "operator") {
                    newObj["$O"] = obj.operator;
                } else if (key === "value") {
                    newObj["$V"] = obj.value;
                } else if (key === "field") {
                    newObj["$F"] = obj.field;
                } else if (key === "direction") {
                    newObj["$D"] = obj.direction;
                } else if (key === "name") {
                    newObj["$N"] = obj.name;
                } else if (key === "width") {
                    newObj["$W"] = obj.width;
                }
            });
            if (obj?.filters) {
                newObj["$FS"] = this.serializeForArray(obj.filters);
            }
        });
        return newObjArr;
    }    

    serializeForLink(filter: Filter | undefined, sorts: Array<Sort> = [], goToEditIfSingleEntity: boolean, allowEmptyFilter: boolean) {
        const result: string[] = [];
        filter = filter ? Filter.eliminateDisabledFilters(filter) : undefined;
        if (!filter && allowEmptyFilter) {
            filter = DEFAULT_COMPOSED_FILTER;
        }
        if (filter) {
            if (filter.filters && filter.filters.length === 1) {
                filter = filter.filters[0];
            }
            result.push("cqf=" + JSON.stringify(this.serializeForArray([filter])[0]));
        }
        if (sorts && sorts.length) {
            result.push("cqs=" + JSON.stringify(this.serializeForArray(sorts)));
        }
        if (goToEditIfSingleEntity) {
            result.push("goToEditIfSingleEntity");
        }
        return (result.length > 0 ? "?" : "") + result.join("&");
    }

    private deserializeForArray(objArr: any[]) {
        const newObjArr: any[] = [];
        objArr.forEach(obj => {
            let newObj: any = new Object();
            newObjArr.push(newObj);
            obj && Object.keys(obj).forEach(key => {
                if (key === "$O") {
                    newObj.operator = obj["$O"];
                } else if (key === "$V") {
                    newObj.value = obj["$V"];
                } else if (key === "$F") {
                    newObj.field = obj["$F"];
                } else if (key === "$D") {
                    newObj.direction = obj["$D"];
                } else if (key === "$N") {
                    newObj.name = obj["$N"];
                }
            });
            if (obj && obj["$FS"]) {
                newObj.filters = this.deserializeForArray(obj["$FS"]);
            }
        });
        return newObjArr;
    }

    deserializeFromLink(query: string) {
        const params = query.split("&");
        const result = { filter: DEFAULT_COMPOSED_FILTER, sorts: [] as Sort[], goToEditIfSingleEntity: false };
        params.forEach(param => {
            const [name, value] = param.split("=");
            if (name === "cqf") {
                result.filter = enableAllFilters(this.deserializeForArray([JSON.parse(value)])[0])!;
                if (!result.filter.filters || result.filter.filters.length === 0) {
                    result.filter = {...DEFAULT_COMPOSED_FILTER, ...{filters: [result.filter]}};
                }
            } else if (name === "cqs") {
                result.sorts = this.deserializeForArray(JSON.parse(value));
            } else if (name === "goToEditIfSingleEntity") {
                result.goToEditIfSingleEntity = true;
            }
        });
        return result;
    }

    createLink(includeOrigin: boolean, ed: EntityDescriptor, filter: Filter | undefined, sorts: Array<Sort> = [], params?: { goToEditIfSingleEntity?: boolean, allowEmptyFilter?: boolean }) {
        return (includeOrigin ? (window.location.origin + "/#") : "") + ed.getEntityTableUrl() + "/table" + this.serializeForLink(filter, sorts, !!params?.goToEditIfSingleEntity, !!params?.allowEmptyFilter);
    }
}
