import { addAfterStartupRunnable } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { RoleEditorPage, sliceRoleEditorPage } from "@crispico/foundation-react/pages/role/RoleEditorPage";

export let roleEntityDescriptor: EntityDescriptor;
export let roleToUserEntityDescriptor: EntityDescriptor;
addAfterStartupRunnable(() => {
    // ROLE ENTITY DESCRIPTOR
    roleEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "Role",
        icon: "bookmark",
        miniFields: ["name"]
    }) 
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "name", type: FieldType.string })    
        .addFieldDescriptor({ name: "permissions", type: FieldType.string, enabled: false })   
    );

    roleEntityDescriptor.infoEditor.slice = sliceRoleEditorPage.setEntityDescriptor(roleEntityDescriptor);
    roleEntityDescriptor.infoEditor.wrappedComponentClass =  RoleEditorPage;

    // ROLE TO USER ENTITY DESCRIPTOR
    roleToUserEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "UserToOrganization",
        icon: "bookmark",
        miniFields: ["id"]
    }) 
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "user", type: "User" })   
        .addFieldDescriptor({ name: "role", type: "Role" })
        .addFieldDescriptor({ name: "organization", type: "Organization" })
    );
})