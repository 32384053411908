import { FilterOperators } from "@crispico/foundation-gwt-js";
import { ColumnConfig } from "@crispico/foundation-react/apollo-gen-foundation/ColumnConfig";
import { columnConfigService_findByFilter } from "@crispico/foundation-react/apollo-gen-foundation/columnConfigService_findByFilter";
import { CustomQuery } from "@crispico/foundation-react/apollo-gen-foundation/CustomQuery";
import { customQueryService_findByFilter } from "@crispico/foundation-react/apollo-gen-foundation/customQueryService_findByFilter";
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { BlocklyReadOnly } from "@crispico/foundation-react/blockly/BlocklyReadOnly";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { ENT_DELETE, Utils } from "@crispico/foundation-react/utils/Utils";
import gql from "graphql-tag";
import lodash from 'lodash';
import React from "react";
import { Button, Checkbox, Dropdown, Icon, Input, Label, Modal, Segment } from "semantic-ui-react";
import { FIND_BY_FILTER as FIND_COLUMN_CONFIG_BY_FILTER } from "../ColumnConfig/queries";
import { ModalExt, Severity } from "../ModalExt/ModalExt";
import { DEFAULT_COMPOSED_FILTER } from "./BlocklyEditorTab";
import { createFilterBlock } from "./createFilterBlock";
import { ClientCustomQuery } from "./ClientCustomQuery";
import { CustomQueryEntityEditorPage, sliceCustomQueryEntityEditorPage } from "./CustomQueryEntityEditorPage";
import { CustomQuerySource } from "./dataStructures";
import { Filter } from "./Filter";
import { checkValidFilter } from "./FilterForm";
import { FIND_BY_FILTER as FIND_CUSTOM_QUERY_BY_FILTER, GET_CUSTOM_QUERY_BY_ID } from "./queries";
import { createSortBlock, Sort } from "./SortBar";
import { CustomQueryBarProps, CUSTOM_QUERY_BAR_MODE } from "./CustomQueryBar";
import { customQueryService_findById } from "@crispico/foundation-react/apollo-gen-foundation/customQueryService_findById";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";

export const currentCustomQueryId = 'currentCustomQueryId';

export function addCustomQueryToSessionStorage(entityCopy: ClientCustomQuery) {
    try {
        let objectForSessionStorage: any = {};
        if (window.sessionStorage.getItem(currentCustomQueryId)) {
            objectForSessionStorage = lodash.cloneDeep(JSON.parse(window.sessionStorage.getItem(currentCustomQueryId)!));
        }
        objectForSessionStorage[entityCopy.screen!] = entityCopy;
        window.sessionStorage.setItem(currentCustomQueryId, JSON.stringify(objectForSessionStorage));
    }
    catch (e) {
        console.log(e);
    }
}

export function deleteCustomQueryFromSessionStorage(entityDescriptorName: string) {
    try {
        if (window.sessionStorage.getItem(currentCustomQueryId)) {
            let objectForSessionStorage = lodash.cloneDeep(JSON.parse(window.sessionStorage.getItem(currentCustomQueryId)!));
            objectForSessionStorage[entityDescriptorName] = undefined;
            window.sessionStorage.setItem(currentCustomQueryId, JSON.stringify(objectForSessionStorage));
        } else {
            window.sessionStorage.setItem(currentCustomQueryId, JSON.stringify({}));
        }
    }
    catch (e) {
        console.log(e);
    }
}

export function getCustomQueryFromSessionStorage(entityDescriptorName: string) {
    try {
        let objectForSessionStorage = window.sessionStorage.getItem(currentCustomQueryId);
        if (objectForSessionStorage) {
            return JSON.parse(objectForSessionStorage)[entityDescriptorName];
        } else {
            window.sessionStorage.setItem(currentCustomQueryId, JSON.stringify({}));
            return undefined;
        }
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}

export function getCustomQueryForServer(clientCustomQuery: ClientCustomQuery) {
    return {
        id: clientCustomQuery.id,
        name: clientCustomQuery.name,
        screen: clientCustomQuery.screen,
        customQueryDefinition: JSON.stringify(clientCustomQuery.customQueryDefinitionObject),
        preferredColumnConfig: clientCustomQuery.preferredColumnConfig,
        displayAsCards: clientCustomQuery.displayAsCards,
        emailScheduleRecipientList: clientCustomQuery.emailScheduleRecipientList,
        emailScheduleCron: clientCustomQuery.emailScheduleCron,
        organization: clientCustomQuery.organization
    } as CustomQuery;
}

export function getCustomQueryForClient(customQuery: CustomQuery, fromCrudSettings?: boolean) {
    return {
        id: customQuery.id,
        name: customQuery.name,
        preferredColumnConfig: customQuery.preferredColumnConfig,
        screen: customQuery.screen,
        color: '',
        enabled: true,
        dirty: false,
        customQueryDefinitionObject: customQuery.customQueryDefinition ? JSON.parse(customQuery.customQueryDefinition) : { sorts: [], filter: DEFAULT_COMPOSED_FILTER },
        displayAsCards: customQuery.displayAsCards,
        fromCrudSettings: fromCrudSettings ? true : false,
        emailScheduleRecipientList: customQuery.emailScheduleRecipientList,
        emailScheduleCron: customQuery.emailScheduleCron,
        organization: customQuery.organization
    } as ClientCustomQuery;
}


export async function loadAndUpdateCustomQuery(customQueryId: number, fromCrudSettings: boolean, crudSettingsCQId?: number, updateCustomQuery?: (cq: ClientCustomQuery) => void) {
    const result = (await apolloClient.query<customQueryService_findById>({
        query: GET_CUSTOM_QUERY_BY_ID,
        variables: { id: customQueryId }
    })).data.customQueryService_findById;
    if (result) {
        let cq = getCustomQueryForClient(result as CustomQuery, fromCrudSettings);
        if (crudSettingsCQId !== undefined && cq.id === crudSettingsCQId) {
            cq.fromCrudSettings = true;
        }
        updateCustomQuery && updateCustomQuery(cq);
        return cq;
    }
    return undefined;
}

export interface CustomQueryDefinition {
    filter: Filter,
    sorts: Array<Sort>
}

interface CustomQueryDropdownMenuOption {
    key: string;
    text: string;
    value: string;
    label: {
        color: string;
        empty: boolean;
        circular: boolean;
    };
    id: any;
    index: number;
    disabled: boolean;
    selected: boolean;
    onClick: () => Promise<void>;
}

export const sliceCustomQueryDropdown = createSliceFoundation(class SliceCustomQueryDropdown {

    saveMutation = gql(`mutation q($params: SaveParams_LongInput) { 
            customQueryService_save(params: $params) { ${ID} }
        }`);

    initialState = {
        customQueries: [] as Array<ClientCustomQuery>,
        dropdownOpened: false as boolean,
        search: '',
        viewCustomQueryPopup: {
            editorOpened: false as [number, number] | boolean,
            index: -1
        },
        confirmPopup: {
            open: false,
            cqIndex: -1,
            cqId: -1
        },
        defaultCustomQuery: undefined as ClientCustomQuery | undefined,
        crudSettingsCQId: undefined as number | undefined
    }

    nestedSlices = {
        customQueryEntityEditorPage: sliceCustomQueryEntityEditorPage,
    }

    reducers = {
        ...getBaseReducers<SliceCustomQueryDropdown>(this),

        openCustomQueryEntityEditorPage(state: StateFrom<SliceCustomQueryDropdown>, customQuery: ClientCustomQuery) {
            this.getReducers().customQueryEntityEditorPage.setModalOpen(state.customQueryEntityEditorPage, true);
            this.getReducers().customQueryEntityEditorPage.clearStateBeforeAddOrEdit(state.customQueryEntityEditorPage);
            this.getReducers().customQueryEntityEditorPage.onModeLoadedForClientCustomQuery(state.customQueryEntityEditorPage, customQuery);
        },

        editDropdownCustomQuery(state: StateFrom<SliceCustomQueryDropdown>) {
            let customQuery = lodash.cloneDeep(state.customQueries[state.viewCustomQueryPopup.index]);
            this.openCustomQueryEntityEditorPage(state, customQuery);
            state.customQueryEntityEditorPage.entitySource = CustomQuerySource.DROPDOWN_LIST;

            state.viewCustomQueryPopup.editorOpened = false;
            state.viewCustomQueryPopup.index = -1;
        },

        openEditorForSaveAs(state: StateFrom<SliceCustomQueryDropdown>, customQuery: ClientCustomQuery) {
            let cq = lodash.cloneDeep(customQuery);
            cq.id = null;
            cq.name = customQuery.name + '-copy';
            this.openCustomQueryEntityEditorPage(state, cq);
            state.customQueryEntityEditorPage.entitySource = CustomQuerySource.SAVE_AS;
        },

        editCustomQuery(state: StateFrom<SliceCustomQueryDropdown>, customQuery: ClientCustomQuery) {
            this.openCustomQueryEntityEditorPage(state, customQuery!);
            state.customQueryEntityEditorPage.entitySource = CustomQuerySource.EDIT;
        },

        // TODO by CS: I commented this because 1) it shouldn't be needed because on each new editor show, the state is cleaned up and properly set
        // so no need to do cleanup at exit. 2) I see it's used twice as handler; !OK I think. 3) Was the cause of #24336 and #24316. There is a double
        // commit, by design. But that should be harmless. However, because of this cleanup, the second commit would have empty data.
        // I leave this here, because having such spaghetti code in this section, I cannot be sure that we don't have regressions. And if we do, looking
        // at this code might give inspiration
        closeCustomQueryEditor(state: StateFrom<SliceCustomQueryDropdown>) {
            // this.getReducers().customQueryEntityEditorPage.setModalOpen(state.customQueryEntityEditorPage, false);
            // state.customQueryEntityEditorPage.entitySource = CustomQuerySource.NO_CQ;
            // state.customQueryEntityEditorPage.sortTab = {
            //     ...this.getSlice().nestedSlices.customQueryEntityEditorPage.nestedSlices.sortTab.initialState,
            //     sortForm: {
            //         fieldNameContentAssist: { ...this.getSlice().nestedSlices.customQueryEntityEditorPage.nestedSlices.sortTab.nestedSlices.sortForm.nestedSlices.fieldNameContentAssist.initialState }
            //     }
            // }
            // state.customQueryEntityEditorPage.blocklyEditorTab = {
            //     ...this.getSlice().nestedSlices.customQueryEntityEditorPage.nestedSlices.blocklyEditorTab.initialState,
            //     filterForm: {
            //         ...this.getSlice().nestedSlices.customQueryEntityEditorPage.nestedSlices.blocklyEditorTab.nestedSlices.filterForm.initialState,
            //         fieldNameContentAssist: { chain: [], options: [], opened: false }
            //     }
            // };
            // state.customQueryEntityEditorPage.entitySource = CustomQuerySource.NO_CQ;
        }

    }

    impures = {
        ...getBaseImpures<SliceCustomQueryDropdown>(this),

        async loadCustomQueries(entityName: string) {
            let entityDescriptor = entityDescriptors[entityName];
            const result = (await apolloClient.query<customQueryService_findByFilter>({
                query: FIND_CUSTOM_QUERY_BY_FILTER,
                variables: FindByFilterParams.create().filter(Filter.create('screen', FilterOperators.forString.equals, entityName)),
                context: { showSpinner: false }
            })).data;
            if (result.customQueryService_findByFilter!.results!.length > 0) {
                const customQueries = result.customQueryService_findByFilter!.results!.map(cq => getCustomQueryForClient(cq as CustomQuery));
                customQueries.unshift(entityDescriptor.getDefaultCustomQuery());
                if (this.getState().defaultCustomQuery) {
                    customQueries.unshift(this.getState().defaultCustomQuery as ClientCustomQuery);
                }
                this.getDispatchers().setInReduxState({ customQueries: customQueries })
            } else {
                if (this.getState().defaultCustomQuery) {
                    this.getDispatchers().setInReduxState({ customQueries: [this.getState().defaultCustomQuery as ClientCustomQuery, entityDescriptor.getDefaultCustomQuery()] })
                } else {
                    this.getDispatchers().setInReduxState({ customQueries: [entityDescriptor.getDefaultCustomQuery()] })
                }
            }
        },

        async openDropdown(entityName: string) {
            this.loadCustomQueries(entityName);
            this.getDispatchers().setInReduxState({ dropdownOpened: true });
        },

        async saveCustomQuery(customQuery: ClientCustomQuery, updateCustomQuery: (cq: ClientCustomQuery) => void) {
            await this.getDispatchers().customQueryEntityEditorPage.save(customQuery, undefined, undefined, { performNavigationAtEnd: false });
            this.getDispatchers().setInReduxState({ dropdownOpened: false });
            updateCustomQuery({...customQuery, ...{dirty: false}});
        },

        async delete(id: any, customQuery: ClientCustomQuery, updateCustomQuery: (cq: ClientCustomQuery) => void) {
            if (this.getState().crudSettingsCQId === id) {
                Utils.showGlobalAlert({ message: _msg("CustomQueryDropdown.errorRemovingSettingsCQ") });
                return;
            }

            let customQueries = lodash.cloneDeep(this.getState().customQueries).filter(cq => cq.id !== id);
            const removeMutation = gql(`mutation deleteEntity($id: Long){customQueryService_deleteById(id: $id)}`);
            await apolloClient.mutate({ mutation: removeMutation, variables: { id: id } });
            let cq = customQuery!.id === id ? customQueries.find(cq => cq.id === -1) : customQuery;
            this.getDispatchers().setInReduxState({
                viewCustomQueryPopup: { editorOpened: false, index: -1 },
                customQueries: customQueries
            });
            setTimeout(() => updateCustomQuery(cq!));
            this.getDispatchers().customQueryEntityEditorPage.setInReduxState({modalOpen: false});
        },

        async selectCQ(customQuery: ClientCustomQuery, entityDescriptor: string, updateCustomQuery: (cq: ClientCustomQuery) => void, applyColumnConfigFromCustomQuery?: (customQuery: ClientCustomQuery) => void) {
            let validFilter = await checkValidFilter(customQuery.customQueryDefinitionObject.filter, entityDescriptor);
            if (!validFilter) { return; }
            if (customQuery.id === this.getState().crudSettingsCQId) {
                customQuery = {...customQuery, ...{fromCrudSettings: true}};
            }
            this.getDispatchers().setInReduxState({
                viewCustomQueryPopup: {
                    index: -1,
                    editorOpened: false
                }
            });
            applyColumnConfigFromCustomQuery && applyColumnConfigFromCustomQuery(customQuery);
            this.getDispatchers().setInReduxState({ dropdownOpened: undefined, search: ''});
            updateCustomQuery(customQuery);
        },

        async initializeFromSessionStorage(entityDescriptor: string, customQuery: ClientCustomQuery | undefined, updateCustomQuery: (cq: ClientCustomQuery) => void) {
            const ed: EntityDescriptor = entityDescriptors[entityDescriptor];
            const crudSettingsCQId = ed.entityDescriptorSettings?.defaultCustomQuery ? Number(ed.entityDescriptorSettings?.defaultCustomQuery) : undefined;
            this.getDispatchers().setInReduxState({crudSettingsCQId: crudSettingsCQId});
            if (customQuery?.dirty && customQuery.id !== -1) {
                return;
            }
            let cq = getCustomQueryFromSessionStorage(entityDescriptor);
            const crudSettingsCQ = crudSettingsCQId ? await loadAndUpdateCustomQuery(crudSettingsCQId, true, this.getState().crudSettingsCQId, updateCustomQuery) : undefined;
            if (cq && cq.id !== -1) {
                if (cq.id === crudSettingsCQ?.id) {
                    cq = crudSettingsCQ;
                }
                updateCustomQuery(cq);
            } else {
                let cq = ed.getDefaultCustomQuery();
                if (crudSettingsCQ) {
                    cq = crudSettingsCQ;
                }
                updateCustomQuery(cq);
            }
        },

        async revert(entityDescriptor: string, customQuery: ClientCustomQuery, updateCustomQuery: (cq: ClientCustomQuery) => void) {
            let cq;
            if (customQuery!.id === -1) {
                cq = entityDescriptors[entityDescriptor].getDefaultCustomQuery();
            } else {
                const result = (await apolloClient.query<customQueryService_findByFilter>({
                    query: FIND_CUSTOM_QUERY_BY_FILTER,
                    variables: FindByFilterParams.create().pageSize(20)
                        .filter(Filter.createComposed(FilterOperators.forComposedFilter.or, [
                            Filter.create('screen', FilterOperators.forString.equals, entityDescriptor),
                            Filter.create('id', FilterOperators.forNumber.equals, customQuery.id)
                        ]))
                })).data;
                cq = getCustomQueryForClient(result.customQueryService_findByFilter!.results![0] as CustomQuery);
            }
            if (cq.id === this.getState().crudSettingsCQId) {
                cq.fromCrudSettings = true;
            }
            updateCustomQuery(cq);
        },

        async openViewCustomQueryPopup(index: number, popupPosition: [number, number], entityDescriptor: string) {
            let customQuery = this.getState().customQueries[index];
            let validFilter = await checkValidFilter(customQuery.customQueryDefinitionObject.filter, entityDescriptor);
            if (!validFilter) { return; }
            this.getDispatchers().setInReduxState({
                viewCustomQueryPopup: {
                    editorOpened: popupPosition,
                    index: index
                }
            })
        },

        async onApply(entity: ClientCustomQuery, entityDescriptor: string, apply: (customQuery: ClientCustomQuery) => void, updateCustomQuery: (cq: ClientCustomQuery) => void, applyColumnConfigFromCustomQuery?: (customQuery: ClientCustomQuery) => void) {
            const cq = lodash.cloneDeep(entity);
            if (cq.preferredColumnConfig) {
                const cc = (await apolloClient.query<columnConfigService_findByFilter>({
                    query: FIND_COLUMN_CONFIG_BY_FILTER,
                    variables: FindByFilterParams.create().pageSize(20).filter(Filter.create('id', FilterOperators.forNumber.equals, cq.preferredColumnConfig.id))
                })).data;
                let columnConfigs = cc.columnConfigService_findByFilter!.results as Array<ColumnConfig>;

                cq.preferredColumnConfig = columnConfigs[0];
            }
            
            const id = cq.id;
            let  currentCQ;
            //-1 and -2 are for default entity/shared cq
            if (id !== -1 && id !== -2) {
                currentCQ = (await this.getSlice().nestedSlices.customQueryEntityEditorPage.impures.invokeLoadQuery({ query: this.getSlice().nestedSlices.customQueryEntityEditorPage.loadQuery, variables: { id, pageSize: 20 } })).data[this.getSlice().nestedSlices.customQueryEntityEditorPage.loadOperationName];
                currentCQ = getCustomQueryForClient(currentCQ);
            } else {
                currentCQ = entityDescriptors[entityDescriptor].getDefaultCustomQuery();
            }
            
            cq.dirty = lodash.isEqual(cq, currentCQ) ? false : true;
            apply(cq);
            applyColumnConfigFromCustomQuery && applyColumnConfigFromCustomQuery(cq);
            this.getDispatchers().closeCustomQueryEditor();
            updateCustomQuery(cq);
        }
    }

});

interface CustomQueryDropdownProps extends CustomQueryBarProps {
    customQuery: ClientCustomQuery | undefined,
    updateCustomQuery: (cq: ClientCustomQuery) => void,
    apply: (customQuery: ClientCustomQuery) => void,
    mode: CUSTOM_QUERY_BAR_MODE
}

export class CustomQueryDropdown extends React.Component<PropsFrom<typeof sliceCustomQueryDropdown> & CustomQueryDropdownProps>  {

    customQueryEntityEditorPageRef = React.createRef<any>();
    inputRef = React.createRef<any>();

    componentDidMount() {
        this.props.dispatchers.initializeFromSessionStorage(this.props.entityDescriptor, this.props.customQuery, this.props.updateCustomQuery);
    }

    renderPopup() {
        if (this.props.viewCustomQueryPopup.index < 0) { return };
        const customQuery = this.props.customQueries[this.props.viewCustomQueryPopup.index];
        let customQueryDefinition = customQuery.customQueryDefinitionObject;

        return (
            <ModalExt onClose={() => this.props.dispatchers.setInReduxState({ viewCustomQueryPopup: { editorOpened: false, index: -1 } })} open={this.props.viewCustomQueryPopup.editorOpened} size='tiny' addNiceLookingOffsets>
                <Modal.Header>{_msg('CustomQueryDropdown.previewTitle')}</Modal.Header>
                <Modal.Content scrolling>
                    <Segment className='AddCustomQueryDropdown_segment'>
                        <Label color='orange'>{_msg('AddCustomQueryDropdown.sortsLabel')}</Label>
                        {customQueryDefinition.sorts && customQueryDefinition.sorts.map((s, index) => {
                            return (
                                <Label key={index} size='mini' className='CustomQueryBar_sortDiv'>
                                    <div>
                                        <Icon className='SortBar_sortIcon' size='large' name={(s as Sort).direction === 'ASC' ? 'sort up' : 'sort down'} />
                                    </div>
                                    <BlocklyReadOnly key={index}>{createSortBlock(s as Sort, this.props.entityDescriptor)}</BlocklyReadOnly>
                                </Label>
                            );
                        })}
                        <Label color='green'>{_msg('AddCustomQueryDropdown.filterLabel')}</Label>
                        {customQueryDefinition.filter && (customQueryDefinition.filter.filters! as Array<Filter>).map((f: Filter, index: number) => {
                            return (
                                <Label key={index} className={`CustomQueryBar_filterDiv`}>
                                    <Checkbox checked={f.enabled ? true : false} />
                                    <BlocklyReadOnly key={index}>{createFilterBlock(f, { entityDescriptorName: this.props.entityDescriptor})}</BlocklyReadOnly>
                                </Label>
                            );
                        })}
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' data-testid="CustomQueryDropdown_previewApply" onClick={() => this.props.dispatchers.selectCQ(customQuery, this.props.entityDescriptor, this.props.updateCustomQuery, this.props.applyColumnConfigFromCustomQuery)}>{_msg('general.apply')}</Button>
                    {customQuery.id !== -1 && <Button data-testid="CustomQueryDropdown_previewEdit" color='blue' onClick={this.props.dispatchers.editDropdownCustomQuery}>{_msg('AddCustomQueryDropdown.edit')}</Button>}
                    {customQuery.id !== -1 && this.isDeleteAuthorized(false) && <Button data-testid="CustomQueryDropdown_previewDelete" color='red' onClick={(e) => this.openConfirm(e, this.props.viewCustomQueryPopup.index, customQuery.id)}>{_msg('general.delete')}</Button>}
                    <Button color='grey' onClick={() => this.props.dispatchers.setInReduxState({ viewCustomQueryPopup: { editorOpened: false, index: -1 } })}>{_msg('general.cancel')}</Button>
                </Modal.Actions>
            </ModalExt>
        );
    }

    openViewCustomQueryPopup = (e: any, index: number) => {
        e.stopPropagation();
        this.props.dispatchers.openViewCustomQueryPopup(index, [e.clientX, e.clientY], this.props.entityDescriptor)
    }

    onSearchChange = (e: any) => this.props.dispatchers.setInReduxState({ search: e.target.value });

    onOpenDropdown = (e: any) => {
        if (!this.props.dropdownOpened) {
            this.props.dispatchers.openDropdown(this.props.screen);
            setTimeout(() => {
                if (this.inputRef.current) {
                    this.inputRef.current.autocomplete = 'off';
                    this.inputRef.current.focus();
                }
            }, 100);
        }
    }

    onBlur = (e: any) => {
        if (e.relatedTarget && e.relatedTarget.id === 'CustomQueryDropdown_inputID') { return; }
        this.props.dispatchers.setInReduxState({ dropdownOpened: false, search: '' })
    }

    onClose = (e: any) => {
        if (e && e.key === 'Escape') {
            this.props.dispatchers.setInReduxState({ dropdownOpened: false, search: '' })
        }
    }

    loadCustomQueriesOptions() {
        return this.props.customQueries.map((cq, index) => {
            return {
                key: cq.name ? cq.name + index : cq.name as string,
                text: cq.name as string,
                value: cq.name as string,
                label: { color: cq.color ? cq.color : 'black' as string, empty: true, circular: true },
                id: cq.id,
                index: index,
                disabled: this.props.customQuery?.id === cq.id,
                selected: this.props.customQuery?.id === cq.id,
                onClick: () => this.props.dispatchers.selectCQ(this.props.customQueries.find(e => e.id === cq.id) as ClientCustomQuery, this.props.entityDescriptor, this.props.updateCustomQuery, this.props.applyColumnConfigFromCustomQuery)
            } as CustomQueryDropdownMenuOption
        }).filter(option => {
            if (!this.props.search) {
                return true;
            }
            return option.text?.toUpperCase().includes(this.props.search.toUpperCase());
        });
    }

    // CZ: I wanted to use Form to use the onSubmit handler, but inside Dropdown, pressing 'enter' didnt fire 'onSubmit' so I used this approach
    onKeyDown(e: any, id: any) {
        if (e.key === 'Enter') {
            this.props.dispatchers.selectCQ(this.props.customQueries.find(e => e.id === id) as ClientCustomQuery, this.props.entityDescriptor, this.props.updateCustomQuery, this.props.applyColumnConfigFromCustomQuery)
        }
    }

    openConfirm = (e: any, index: number, id: number) => {
        e.stopPropagation();
        this.props.dispatchers.setInReduxState({ confirmPopup: { open: true, cqIndex: index, cqId: id } })
    }

    closeConfirm = () => this.props.dispatchers.setInReduxState({ confirmPopup: { open: false, cqIndex: -1, cqId: -1 } })

    onConfirm = () => {
        if (this.isDeleteAuthorized(true)) {
            this.props.dispatchers.delete(this.props.confirmPopup.cqId, this.props.customQuery!, this.props.updateCustomQuery);
        }
        this.closeConfirm();
        setTimeout(() => {
            document.getElementById('CustomQueryDropdown_inputID')?.focus()
        });
    }

    isDeleteAuthorized(showErrorMessageIfNoPermission: boolean): boolean {
        const entityDescriptorName = this.props.dispatchers.getSlice().nestedSlices.customQueryEntityEditorPage.entityDescriptor.name;
        const permission = Utils.pipeJoin([ENT_DELETE, entityDescriptorName]);
        return AppMetaTempGlobals.appMetaInstance.hasPermission(permission, showErrorMessageIfNoPermission);
    }

    onApply = (entity: ClientCustomQuery) => this.props.dispatchers.onApply(entity, this.props.entityDescriptor, this.props.apply, this.props.updateCustomQuery, this.props.applyColumnConfigFromCustomQuery)

    renderMenuEntries(options: CustomQueryDropdownMenuOption[]) {
        return <Dropdown.Menu className="DropdownMenu" data-testid="CustomQueryDropdown_dropdown">
            <Dropdown.Header icon='filter' content={<h4 className="DropdownMenuHeader">{_msg('CustomQueryBar.savedQueries')}</h4>} />
            <Dropdown.Divider />
            <Dropdown.Header>
                <Input ref={this.inputRef} fluid onKeyDown={(e: any) => options.length > 0 && this.onKeyDown(e, options[0].id)} placeholder={_msg('CustomQueryDropdown.searchLabel')} id='CustomQueryDropdown_inputID' icon='search' iconPosition='left' value={this.props.search} onChange={this.onSearchChange} ></Input>
            </Dropdown.Header>
            {options.map((cq) => (
                <Dropdown.Item {...cq} content={
                    <div className="DropdownMenuItemWrapper"><div className="DropdownMenuItem" data-testid={`CustomQueryDropdown_option_${cq.text}`}>
                        <label className="DropdownMenuItem_Name">{cq.text}</label>
                        {cq.id !== -1 && <Button className='AddCustomQueryDropdown_itemButton DropdownMenuItem_Button' data-testid="CustomQueryDropdown_delete" color='red' onClick={(e) => this.openConfirm(e, cq.index, cq.id)} floated='right' size='mini' icon="delete" />}
                        <Button className='AddCustomQueryDropdown_itemButton DropdownMenuItem_Button' data-testid="CustomQueryDropdown_preview" onClick={(e) => this.openViewCustomQueryPopup(e, cq.index)} floated='right' size='mini' icon="eye"></Button>
                    </div></div>
                } />
            ))}
            <ModalExt
                severity={Severity.INFO}
                open={this.props.confirmPopup.open}
                header={_msg("general.info")}
                content={_msg('CustomQueryDropdown.confirmMessage')}
                onClose={this.closeConfirm}
                actions={[
                    <Button key="cancel" onClick={this.closeConfirm}>{_msg("general.cancel")}</Button>,
                    <Button key="ok" data-testid="CustomQueryDropdown_deleteOK" primary onClick={this.onConfirm}>{_msg("general.ok")}</Button>
                ]}
            />
        </Dropdown.Menu>;
    }

    render() {
        let value = this.props.customQuery ? this.props.customQuery!.name! : '';
        if (this.props.customQuery?.fromCrudSettings) {
            value = _msg('general.default') + " (" + value + ")";
        }
        value = this.props.customQuery?.dirty ? value + '*' : value;
        const options = this.loadCustomQueriesOptions();
        return (
            <>
                <div>
                    <Button.Group>
                        <Button data-testid="CustomQueryDropdown_button" onClick={(e) => this.props.dispatchers.editCustomQuery(this.props.customQuery!)}><Icon name="filter" className="icon small-margin-right" /> {value}</Button>
                        {AppMetaTempGlobals.appMetaInstance.showCrudButtons.showCustomQueryButton &&
                            <Dropdown data-testid="CustomQueryDropdown_options" onClose={this.onClose} onBlur={this.onBlur} onOpen={this.onOpenDropdown} open={this.props.dropdownOpened}
                                upward={false} floating className='icon button DropdownMenu_Darker'>
                                {this.renderMenuEntries(options)}
                            </Dropdown>
                        }
                    </Button.Group>
                </div>
                {this.renderPopup()}

                <CustomQueryEntityEditorPage {...this.props.customQueryEntityEditorPage} sortDisabled={this.props.sortDisabled} dispatchers={this.props.dispatchers.customQueryEntityEditorPage} ref={this.customQueryEntityEditorPageRef} applyColumnConfigFromCustomQuery={this.props.applyColumnConfigFromCustomQuery} entityDescriptor={this.props.entityDescriptor} closeEditor={this.props.dispatchers.closeCustomQueryEditor}
                    updateCustomQuery={(cq: ClientCustomQuery) => this.props.updateCustomQuery(cq)} embeddedMode={true} renderHeaderParams={{columnConfig: false}}
                    modalProps={{ onClose: this.props.dispatchers.closeCustomQueryEditor, open: this.props.customQueryEntityEditorPage.modalOpen, centered: false }}
                    openConfirm={this.openConfirm}
                    // LA: APPLY for SAVE_AS is disabled for the moment due to issues with duplicated ids in react state, to be addressed
                    onApply={this.props.customQueryEntityEditorPage.entitySource === CustomQuerySource.EDIT /*|| this.props.customQueryEntityEditorPage.entitySource === CustomQuerySource.SAVE_AS*/ ? this.onApply : undefined}
                    onSave={() => this.props.updateCustomQuery({ ...this.props.customQuery!, dirty: false })}
                />
            </>
        );
    }
};