import { Utils } from "@crispico/foundation-react";
import { ResponsiveLineChartRRC, ResponsiveLineChartProps, ResponsiveLineChart} from "./ResponsiveLineChart";
//@ts-ignore
import { Serie, useSlices, PointTooltipProps } from "@nivo/line";
import lodash from 'lodash';
import moment from "moment";
import React from "react";
import { ChartMapping } from "../BarChart/BarChart";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { CartesianMarkerProps } from "@nivo/core";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

export class LineChartState extends State {
    series = [] as Array<Serie>;
    startDate = undefined as number | undefined;
    endDate = undefined as number | undefined;
    chartPaddingLeft = 0 as number;
}

export class LineChartReducers<S extends LineChartState = LineChartState> extends Reducers<S> {
}

type LineChartProps = ResponsiveLineChartProps & { mapping?: ChartMapping[], parentHeight?: number } & RRCProps<LineChartState, LineChartReducers>;

export class LineChart extends React.Component<LineChartProps> {

    protected responsiveLineChartRef = React.createRef<ResponsiveLineChart>();

    constructor(props: LineChartProps) {
        super(props);

        this.areaLayer = this.areaLayer.bind(this);
        this.lineLayer = this.lineLayer.bind(this);
        this.renderLineChartTooltip = this.renderLineChartTooltip.bind(this);
    }

    slices = [] as any[];

    // in order not use an expensive cache (like lodash.memoize).
    // we are strictly interested to recompute only when one of this parameters change
    protected localDataToRecompute: any = { width: undefined, height: undefined, data: undefined }

    /**
     * Compute the slices needed for faster compute where the mouse is when hovering/clicking on the graphs
     * 
     * It should be faster using the slice than to make a for on each of the series and compare the 
     * current cursor coordinates with the x data transformed.
     * 
     * Uses this.localDataToRecompute to know when to recompute the slices. We are not interested in keeping many values
     * (just the last one), since we do not envison people to play with the graph dimension
     * 
     * Next upgrade should be binary seaching in the slice
     * 
     * @param p generic nivo object received by layer functions. We use it to get the data and width/height 
     */
    protected computeSlicesIfNeeded(p: any) {
        if (this.localDataToRecompute.width !== p.innerWidth
            || this.localDataToRecompute.height !== p.innerHeight
            || this.localDataToRecompute.data !== p.data) {
            this.localDataToRecompute = {
                width: p.innerWidth,
                height: p.innerHeight,
                data: p.data
            }
            // TODO use of hooks is not allowed in class components. It's odd that the code actually works. This is the error message:
            // React Hook "useSlices" cannot be called in a class component. React Hooks must be called in a React function component or a custom React Hook function  react-hooks/rules-of-hooks
            // I silenced the error temporarily below:
            // eslint-disable-next-line react-hooks/rules-of-hooks
            this.slices = useSlices({ enableSlices: "x", points: p.points, width: p.innerWidth, height: p.innerHeight });
        } else {
            // need this bogus call to avoid "Rendered fewer hooks than expected. This may be caused by an accidental early return statement." error
            // UPDATE: I think that the comment above is somehow a fix for the already bad practice of using the hook in a class component
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useSlices({ enableSlices: false, points: [], width: p.innerWidth, height: p.innerHeight });
        }
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: LineChartProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    private componentDidUpdateInternal(prevProps?: LineChartProps) {
        const { props } = this;
        if (!prevProps || !lodash.isEqual(prevProps.initialStartDate, props.initialStartDate) || !lodash.isEqual(prevProps?.initialEndDate, props.initialEndDate)) {
            props.r.setInReduxState({ startDate: props.initialStartDate, endDate: props.initialEndDate });
        }
        if (!prevProps || !lodash.isEqual(prevProps.currentStartDate, props.currentStartDate) || !lodash.isEqual(prevProps.currentEndDate, props.currentEndDate)) {
            props.r.setInReduxState({ startDate: props.currentStartDate, endDate: props.currentEndDate });
        }
        if (prevProps && (!lodash.isEqual(prevProps.s.startDate, props.s.startDate) || !lodash.isEqual(prevProps?.s.endDate, props.s.endDate))) {
            props.onRangeChange?.call(null, props.s.startDate!, props.s.endDate!);
        }
    }

    componentWillUnmount() {
        this.slices = [];
        this.localDataToRecompute = {};
    }

    protected areaLayer(p: any): JSX.Element | undefined {
        this.computeSlicesIfNeeded(p);
        return;
    }

    protected lineLayer(p: any) {
        const { series, lineGenerator, xScale, yScale } = p;
        return series.map((p1: any) => (
            <path
                key={p1.id}
                d={lineGenerator(
                    p1.data.map((d: any) => ({
                        x: xScale(d.data.x),
                        y: d.data.y != null ? yScale(d.data.y) : null,
                    }))
                )}
                fill="none"
                stroke={p1.color}
                strokeWidth={2}
            />
        ))
    }

    protected renderLineChartTooltip(value: PointTooltipProps): React.ReactNode {
        const point = value.point;
        const pointMapping = this.props.mapping?.find(t => t.start !== undefined ? point.data.y! >= t.start! && point.data.y! < t.end! : point.data.y == t.value);

        return <>
            <div style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={point.serieId + "." + point.id}>
                <span style={{ color: pointMapping?.color as any }}><strong>{point.data.y}</strong></span>
                <span> [{_msg("general.at", moment(point.data.x).format(Utils.dateTimeWithSecFormat))}]</span>
            </div>
            {pointMapping ? <div style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={point.serieId + "." + pointMapping.id}>
                <span style={{ color: pointMapping.color as any }}>{pointMapping.text}</span>
            </div> : null}
        </>;
    }

    render() {
        const { props } = this;
        let markers: CartesianMarkerProps[] = [];
        props.mapping?.forEach(m => {
            if (m.color) {
                markers.push({ axis: "y", value: m.start !== undefined ? m.start! : m.value!, lineStyle: { stroke: AppMetaTempGlobals.appMetaInstance.getColor(m.color), strokeWidth: 2 } });
            }
        });
        return <ResponsiveLineChartRRC id="RLC" {...props} ref={this.responsiveLineChartRef}
            sliderTooltipFormat={value => moment(value).format(Utils.dateFormatShorter + " " + Utils.timeFormat)}
            axisBottom={{ tickRotation: 45, legend: "", format: value => `${moment(value).format(Utils.dateFormatShorter + " " + Utils.timeFormat)}` }}
            colors={{ datum: 'color' }} curve={props.curve ? props.curve : "monotoneX"}
            markers={markers} layers={[
                'grid',
                'markers',
                'axes',
                'areas',
                this.areaLayer,
                'lines',
                this.lineLayer,
                'slices',
                'points',
                'mesh',
                'legends',
            ]}
            pointSize={4} pointBorderWidth={undefined}
            animate={false}
            enableCrosshair={false}
            renderTooltipContent={this.renderLineChartTooltip}
        />;
    }
}

export const LineChartRRC = ReduxReusableComponents.connectRRC(LineChartState, LineChartReducers, LineChart);