import { FilterInput, SortInput } from "@crispico/foundation-react/apollo-gen-foundation/globalTypes";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { enableAllFilters, entityDescriptors, TABLE_PAGE_ICON } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityNumberDisplayed } from "@crispico/foundation-react/entity_crud/EntityNumberDisplayed";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import { AbstractWidgetWithFilter, AbstractWidgetWithFilterConfig, AbstractWidgetWithFilterProps, SliceAbstractWidgetWithFilter, sliceAbstractWidgetWithFilterOnlyForExtension } from "@crispico/foundation-react/pages/dashboard/AbstractWidgetWithFilter";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { push } from 'connected-react-router';
import gql from "graphql-tag";
import lodash from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, List, Table } from "semantic-ui-react";
import { Filter } from "../CustomQuery/Filter";

type ListOfRecordsWidgetConfig = {
    sorts: SortInput[], fields: string, nrOfRecords: number, displayAsTable: boolean, filter?: Filter | null
} & AbstractWidgetWithFilterConfig;

export const sliceListOfRecords = createSliceFoundation(class SliceListOfRecords extends SliceAbstractWidgetWithFilter {
    nestedSlices = {}

    initialState = { ...sliceAbstractWidgetWithFilterOnlyForExtension.initialState, entities: [] as any[], count: 0 }

    reducers = {
        ...sliceAbstractWidgetWithFilterOnlyForExtension.reducers,
        ...getBaseReducers<SliceListOfRecords>(this),
    }

    impures = {
        ...getBaseImpures<SliceListOfRecords>(this),
        async getEntities(entityType: string, filter: FilterInput, sorts: SortInput[], fields: string, pageSize: number) {
            let fieldsToRequest = entityDescriptors[entityType].getGraphQlFieldsToRequest(fields.split(','));
            const config = { entity: entityType, filter: filter, sorts: sorts, pageSize: pageSize }

            const operationName = `${lodash.lowerFirst(entityType)}Service_listOfRecords`;
            const query = gql(`query q($config: RecordsWidgetConfigInput) {
                ${operationName}(config: $config) {
                    id ${fieldsToRequest} ${entityDescriptors[entityType].getGraphQlFieldsToRequest(entityDescriptors[entityType].miniFields)} 
                }
            }`);
            const result = (await apolloClientHolder.apolloClient.query({ query: query, variables: { config }, context: { showSpinner: false } })).data[operationName]

            const countName = `recordsWidgetService_countOfRecords`;
            const countQuery = gql(`query q($config: RecordsWidgetConfigInput) {
                ${countName}(config: $config)
            }`);
            const countResult = (await apolloClientHolder.apolloClient.query({ query: countQuery, variables: { config }, context: { showSpinner: false }  })).data[countName]

            this.getDispatchers().setInReduxState({ entities: result, count: countResult });
        }
    }
});

export type ListOfRecordsProps = PropsFrom<typeof sliceListOfRecords> & AbstractWidgetWithFilterProps & { widgetConfig: ListOfRecordsWidgetConfig, buttonBarRef: any }

export class ListOfRecords extends AbstractWidgetWithFilter<ListOfRecordsProps> {

    protected async refreshInternal(filter: Filter) {
        await this.props.dispatchers.getEntities(this.props.widgetConfig.entityType, filter!, this.props.widgetConfig.sorts, this.props.widgetConfig.fields, this.props.widgetConfig.nrOfRecords);
    }

    private getFieldName(field: string) {
        const fieldDescriptorChain = entityDescriptors[this.props.widgetConfig.entityType]?.getFieldDescriptorChain(field);
        return entityDescriptors[this.props.widgetConfig.entityType]?.getComposedFieldLabel(fieldDescriptorChain);
    }

    private getFieldValue(rootEntity: any, field: string) {
        let entity = { ...rootEntity }
        const fieldDescriptorChain = entityDescriptors[this.props.widgetConfig.entityType]?.getFieldDescriptorChain(field);
        for (let index = 0; index < fieldDescriptorChain.length - 1; index++) {
            entity = entity[fieldDescriptorChain[index].getFieldName()]
            if (!entity) break
        }
        return fieldDescriptorChain[0] ? fieldDescriptorChain[fieldDescriptorChain.length - 1].renderField(entity) : ""
    }

    private renderFields(rootEntity: any, i: number, fields: string[]) {
        return fields.map((field, pos) => {
            return <span key={i + "_" + field}>{this.getFieldValue(rootEntity, field)}<>{pos < fields.length - 1 ? " - " : ""}</></span>
        })
    }

    protected renderMain() {
        const fields = this.props.widgetConfig.fields.split(',')
        const ed = entityDescriptors[this.props.widgetConfig.entityType];
        const filter = this.createFilter();
        return (<>
            {this.props.widgetConfig.displayAsTable ?
                <Table celled compact size='small'>
                    <Table.Header key={ed.name}>
                        <Table.Row>
                            <Table.HeaderCell>{ed.getLabel()}</Table.HeaderCell>
                            {fields.map(field => <Table.HeaderCell key={field}>{this.getFieldName(field)}</Table.HeaderCell>)}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.entities.map(entity =>
                            <Table.Row>
                                <Table.Cell><Link to={ed.getEntityEditorUrl(entity?.id)}>{entityDescriptors[this.props.widgetConfig.entityType].toMiniString(entity)}</Link></Table.Cell>
                                {fields.map(field => <Table.Cell>{this.getFieldValue(entity, field)}</Table.Cell>)}
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table> :
                <List divided relaxed selection>
                    {this.props.entities.map((entity: any, i) =>
                        <List.Item key={i} onClick={() => entity.id && this.props.dispatchers.dispatch(push(entityDescriptors[this.props.widgetConfig.entityType].getEntityEditorUrl(entity.id)))}>
                            <List.Header key={i}>{this.renderFields(entity, i, fields)}</List.Header>
                        </List.Item>
                    )}
                </List>}
            <EntityNumberDisplayed ed={ed} displayed={this.props.entities.length} total={this.props.count} />
            <Link to={new ShareLinkLogic().createLink(false, ed, enableAllFilters(filter), [])}><Button><Icon name={TABLE_PAGE_ICON} /> {_msg("ListOfRecords.openQuery")}</Button></Link>
            {/* {this.props.buttonBarRef && ReactDOM.createPortal(createButtonForWidget({ positive: true, icon: "refresh", className: "dashboardWidgetRefresh" }, () => this.refresh()), this.props.buttonBarRef)} */}
        </>);
    }

}