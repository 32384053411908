import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Utils } from "@crispico/foundation-react";
import { FindByFilterParamsInput } from "@crispico/foundation-react/apollo-gen-foundation/globalTypes";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import gql from "graphql-tag";
import lodash from 'lodash';
import moment from 'moment';
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Icon, Label, Menu, Popup, Segment } from "semantic-ui-react";
import { LOAD_NOTIFICATIONS, SAVE_NOTIFICATION_AND_USER } from "./queries";

var refreshRateMillis = 10 * 1000;
var maxNumberOfNotificationDisplayed = 10;

interface AdditionalFields { 
    hideRead?: boolean, 
    hideIgnoredCodes?: boolean, 
    onlyRead?: boolean, 
    userId?: number
}

export class CustomFindByFilterParams extends FindByFilterParams {
    params: FindByFilterParamsInput & AdditionalFields = { startIndex: 0, pageSize: -1 };
    setParams(p: FindByFilterParamsInput) {
        for (let k in p) {
            (this.params as any)[k] = (p as any)[k];
        }
        return this;
    }
    setAdditionalFields(p: AdditionalFields) {
        for (let k in p) {
            (this.params as any)[k] = (p as any)[k];
        }
        return this;
    }
    static create() {
        return new CustomFindByFilterParams();
    }
}

export const sliceNotificationComponent = createSliceFoundation(class SliceNotificationComponent {

    initialState = {
        popupOpen: false,
        countUnread: 0,
        entities: [] as any,
        allRead: false
    }

    reducers = {
        ...getBaseReducers<SliceNotificationComponent>(this),
    }

    impures = {
        ...getBaseImpures<SliceNotificationComponent>(this),
        async getNotifications() {
            this.getDispatchers().setInReduxState(this.getSlice().initialState);

            const userId = AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.id;

            if (!userId) {
                return;
            }

            const nr = await this.processNotifications(userId, maxNumberOfNotificationDisplayed, true, false);

            if (nr === maxNumberOfNotificationDisplayed) {
                return;
            }

            await this.processNotifications(userId, maxNumberOfNotificationDisplayed - nr, false, true);
        },
        async processNotifications(userId: number, pageSize: number, hideRead: boolean, onlyRead: boolean) {
            const filter = Filter.create("creationDate", FilterOperators.forDate.today, "");
            const sorts: Sort[] = [{ field: "creationDate", direction: "DESC" }];

            const params = CustomFindByFilterParams.create().pageSize(pageSize).filter(filter).sorts(sorts).setAdditionalFields({hideRead, hideIgnoredCodes: true, userId, onlyRead});

            const result = (await apolloClientHolder.apolloClient.query({ query: LOAD_NOTIFICATIONS, variables: params, context: { showSpinner: false } }, )).data["notificationService_findByFilter"];

            for (const entity of result.results) {
                entity["alreadyRead"] = !hideRead;
                entity["miniFields"] = await this.getEntityMiniFields(entity.entityName, entity.entityId);
            }

            const { countUnread, entities } = this.getState();

            if (result.results.length > 0) {
                this.getDispatchers().setInReduxState({ countUnread: countUnread + (hideRead ? result.results.length : 0), entities: entities.concat(result.results) });
            }

            return result.results.length;
        },
        async markNotificationsAsRead() {
            for (const entity of this.getState().entities.filter((x: any) => !x.alreadyRead)) {
                await this.saveNotificationAndUser(entity);
            }
        },
        async saveNotificationAndUser(entity: any) {
            const fieldsAndValues = { "user": AppMetaTempGlobals.appMetaInstance.getCurrentUser(), "notification": entity, "readDate": new Date() }
            return (await apolloClientHolder.apolloClient.mutate({ mutation: SAVE_NOTIFICATION_AND_USER, variables: { params: { fieldsAndValues } } }));
        },
        async getEntityMiniFields(entityName: string, entityId: number) {
            const entityDescriptor = entityDescriptors[entityName];
            const loadOperationName = `${lodash.lowerFirst(entityDescriptor.name)}Service_findByFilter`;
            const query = gql(`query q($params: FindByFilterParamsInput) { 
                ${loadOperationName}(params: $params) {
                    results { ${ID} ${entityDescriptor.getGraphQlFieldsToRequest(entityDescriptor.miniFields)} }
                }
            }`);
            const params = FindByFilterParams.create().filter(Filter.create(ID, FilterOperators.forNumber.equals, entityId.toString()));
            const result = (await apolloClientHolder.apolloClient.query({ query: query, variables: params, context: { showSpinner: false } })).data[loadOperationName];

            return result.results.length > 0 ? result.results[0] : {};
        }
    }
});

export type NotificationComponentProps = PropsFrom<typeof sliceNotificationComponent>

export class NotificationComponent extends React.Component<NotificationComponentProps> {
    private timer: number | undefined = undefined;

    constructor(props: NotificationComponentProps) {
        super(props);

        const { notificationSettings } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient;

        if (notificationSettings) {
            refreshRateMillis = notificationSettings.refreshRate ? notificationSettings.refreshRate * 1000 : refreshRateMillis;
            maxNumberOfNotificationDisplayed = notificationSettings.maxNumberOfNotificationDisplayed ? notificationSettings.maxNumberOfNotificationDisplayed : maxNumberOfNotificationDisplayed;
        }

        this.onPopupOpen = this.onPopupOpen.bind(this);
        this.onPopupClose = this.onPopupClose.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    protected loadData = () => {
        this.props.dispatchers.getNotifications();
        this.startTimer();
    }

    protected startTimer() {
        this.timer = window.setTimeout(this.loadData, refreshRateMillis);
    }

    protected stopTimer() {
        clearTimeout(this.timer);
    }

    protected onPopupClose() {
        this.props.dispatchers.setInReduxState({ popupOpen: false, countUnread: 0, allRead: true });
        this.props.dispatchers.markNotificationsAsRead();
        this.startTimer();
    }

    protected onPopupOpen() {
        this.stopTimer();
        this.props.dispatchers.setInReduxState({ popupOpen: true });
    }

    protected getEntityNavLink(entityDescriptor: EntityDescriptor, entity: any) {
        return <NavLink key={entity.id} to={entityDescriptor.getEntityEditorUrl(entity.entityId)}>
            <Label style={{ marginBottom: "5px" }} basic horizontal color="blue">
                {typeof entityDescriptor.icon === 'string' ? <Icon name={entityDescriptor.icon} /> : entityDescriptor.icon}
                {entityDescriptor.toMiniString(entity.miniFields)}</Label>
        </NavLink>
    }

    render() {
        const trigger = (<Menu.Item as="a">
            {this.props.countUnread > 0
                ? <><Icon name="bell" color="red" />{this.props.countUnread}</>
                : <Icon name="bell" className="no-padding-margin" />}
        </Menu.Item>);

        return (<>
            <Popup on="click" wide="very"
                content={<>
                    {this.props.entities.map((x: any) =>
                        <Segment key={x.id} style={{ fontWeight: x.alreadyRead || this.props.allRead ? null : "bold" }}>
                            <Label color={x.deletionDate ? "green" : "red"} content={x.deletionDate ? _msg("Notification.problemDisappear") : _msg("Notification.problemAppear")} />
                                    at <Label basic>{moment(x.creationDate).format(Utils.dateTimeFormat)}</Label> for {this.getEntityNavLink(entityDescriptors[x.entityName], x)}
                            <div>{x.description}</div>
                            <Link to={new ShareLinkLogic().createLink(false, entityDescriptors[x.entityName], { field: 'id', operator: FilterOperators.forNumber.equals.value, value: x.entityId, enabled: true }, [])}>
                                <Label basic color="green" content={_msg("general.seeMore")} />
                            </Link>
                        </Segment>
                    )}
                </>}
                trigger={trigger}
                open={this.props.popupOpen} onClose={this.onPopupClose} onOpen={this.onPopupOpen} closeOnEscape />
        </>);
    }
}