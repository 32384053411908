import { AppMetaTempGlobals } from "../AppMetaTempGlobals";

export const atLeastOneSubmenuVisible = function(parentMenu: any) {
    // The submenus are hidden when the global settings removeMenuEntryWhenNoPermission = true and the permissions are missing
    var atLeastOneSubmenuVisible: boolean = false;
    for (var i = 0; i < parentMenu.submenus.length; i++) {
        if (!AppMetaTempGlobals.appMetaInstance.removeMenuEntryWhenNoPermission || AppMetaTempGlobals.appMetaInstance.hasPermission(parentMenu.submenus[i].permission)) {
            atLeastOneSubmenuVisible = true;
            break;
        }
    } 
    
    return atLeastOneSubmenuVisible;
}