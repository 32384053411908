import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { HistogramRRC, Point, Serie } from "pages/Chart/Histogram/Histogram";
import React from "react";
import moment from "moment";
import _ from "lodash";
import lodash from 'lodash';
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { AbstractGantt } from "pages/gantt/AbstractGantt";
import { GanttAssignmentEntityDescriptor } from "./GanttAssignmentEntityDescriptor";

export class GanttAssignmentHistogramState extends State {
    histogramData = [] as Array<Serie>;
}

export class GanttAssignmentHistogramReducers<S extends GanttAssignmentHistogramState = GanttAssignmentHistogramState> extends Reducers<GanttAssignmentHistogramState> { }

type Props = RRCProps<GanttAssignmentHistogramState, GanttAssignmentHistogramReducers> & { entity?: any };
export class GanttAssignmentHistogram extends React.Component<Props> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
        this.prepareData();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    private componentDidUpdateInternal(prevProps?: Props) {
        const { props } = this;
        if (!prevProps || !lodash.isEqual(prevProps.entity, props.entity)) {
            this.prepareData();
        }
    }

    protected getLabelForMission(mission: any, entities: { [key: string]: { [key: number]: any; }; }): string {
        let hr = AbstractGantt.findOne("HumanResource", "id", mission.humanResource?.id, entities);
        const oag = AbstractGantt.findOne("ObjectActionGroup", "mission.id", mission.id, entities);
        let task = AbstractGantt.findOne("Task", "id", oag?.object?.id, entities);
        const flights = AbstractGantt.find("Flight", "id", task?.taskGroup?.id, entities);
        return hr?.firstName + " " + hr?.lastName + " " + flights.map(flight => flight.airline + flight.number).join(" ");
    }

    protected async prepareData() {
        let entities = {} as { [key: string]: { [key: number]: any } };
        if (this.props.entity?.inputEntitiesCsv)
            await GanttAssignmentEntityDescriptor.importEntitiesFromJson(entities, this.props.entity?.inputEntitiesCsv);
        if (this.props.entity?.outputEntitiesCsv)
            await GanttAssignmentEntityDescriptor.importEntitiesFromJson(entities, this.props.entity?.outputEntitiesCsv);
        let histogramData = [] as Array<Serie>;
        const ET = entities["EquipmentType"];
        console.log(ET);
        ET && Object.keys(ET).forEach((key) => {
            const id = Number(key);
            const missions = AbstractGantt.find("Mission2", "equipmentType.id", id, entities);
            const points: Array<Point> = [];
            let idP = 0;
            const data: { id: any; time: moment.Moment; type: string; label: string }[] = [];
            missions.forEach((key) => {
                data.push({ id: key.id, time: moment(key.startTime), type: "in", label: this.getLabelForMission(key, entities) });
                data.push({ id: key.id, time: moment(key.endTime), type: "out", label: this.getLabelForMission(key, entities) });
            });
            let total: any[] = [];
            data.sort((a: any, b: any) => a.time - b.time).forEach((d) => {
                let inMission: any[] = [];
                let outMission: any[] = [];
                if (d.type === "in") {
                    inMission.push(d);
                    total.push(d);
                } else {
                    outMission = total.filter(x => x.id === d.id);
                    total = total.filter(x => x.id !== d.id);
                }
                points.push({
                    id: idP++,
                    x: moment(d.time).valueOf(),
                    y: total.length,
                    total: _.cloneDeep(total),
                    in: inMission,
                    out: outMission,
                    series: id,
                })
            })
            histogramData.push({ id: ET[id]?.name, color: AppMetaTempGlobals.appMetaInstance.getColor(ET[id]?.color), data: points });
        })
        this.props.r.setInReduxState({ histogramData })
    }

    render() {
        return <HistogramRRC id={"histogram"} data={this.props.s.histogramData} startDate={moment(this.props.entity?.flightsDate).valueOf()} endDate={moment(this.props.entity?.flightsDate).endOf("day").valueOf()} countBy="Mission2" groupedBy="EquipmentType" />;
    }

}

export const GanttAssignmentHistogramRRC = ReduxReusableComponents.connectRRC(GanttAssignmentHistogramState, GanttAssignmentHistogramReducers, GanttAssignmentHistogram);