import { BlocklyReadOnly } from "@crispico/foundation-react/blockly/BlocklyReadOnly";
import { createSortBlock, Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { sliceSortForm, SortForm } from "@crispico/foundation-react/components/CustomQuery/SortForm";
import { FieldEditorProps, FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { ConnectedComponentInSimpleComponent, ConnectedPageInfo } from "@crispico/foundation-react/reduxHelpers/ConnectedPageHelper";
import { Formik } from "formik";
import React from "react";
import { Button, Icon, Label, Popup } from "semantic-ui-react";
import lodash from 'lodash'

export class SortFieldEditor extends React.Component<FieldEditorProps> {

    protected ref = React.createRef<ConnectedComponentInSimpleComponent>();

    state = {
        sorts: [] as Array<Sort>,
        indexForEditor: -1,
        error: false,
        editorOpened: false,
        entityName: undefined
    }

    constructor(props: FieldEditorProps) {
        super(props);
        this.renderForm = this.renderForm.bind(this);
    }

    componentDidMount() {
        if (this.props.formikProps.values[this.props.fieldDescriptor.name] && this.props.formikProps.values[this.props.fieldDescriptor.name].length > 0) {
            this.setState({ sorts: this.props.formikProps.values[this.props.fieldDescriptor.name] });
        }
    }

    componentDidUpdate(prevProps: FieldEditorProps) {
        if (prevProps.formikProps.values[this.props.fieldDescriptor.fieldForEntityName] !== this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName]) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, []);
            this.setState({ sorts: [] })
        }
    }

    closeEditor = () => {
        this.setState({
            editorOpened: false,
            indexForEditor: -1,
            error: false,
            entityType: ''
        })
        // TODO FieldNameContentAssistRRC: aici nu era lasat comentat de Daniel. L-am pus eu din diff, pentru ca am dubii
        // ca a fost testat acest editor
        /*
        
        this.ref.current?.getDispatchers<typeof sliceSortForm>().fieldNameContentAssist.setInReduxState({
            chain: [] as Array<ChainItem>,
            options: [] as any[],
            opened: false
        })

        */
    }

    openEditor = (sortIndex: number) => {
        if (this.state.editorOpened) { return; }
        this.setState({
            indexForEditor: sortIndex,
            editorOpened: true
        })
    }

    onSave = (sort: Sort) => {
        if (!sort.field) {
            this.setState({ error: true })
            return;
        }
        this.setState({ error: false })
        let sorts = lodash.cloneDeep(this.state.sorts);
        if (this.state.indexForEditor === -1) {
            sorts.push(sort);
        } else {
            sorts[this.state.indexForEditor] = sort;
        }
        this.setState({ sorts: sorts })
        this.closeEditor();
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, sorts);
    }

    moveLeft = () => {
        if (this.state.indexForEditor <= 0) { return; }
        let indexForEditor = this.state.indexForEditor;
        let sorts = lodash.cloneDeep(this.state.sorts);
        let sort = sorts[indexForEditor];
        sorts[indexForEditor] = sorts[indexForEditor - 1];
        sorts[indexForEditor - 1] = sort;
        this.setState({ indexForEditor: indexForEditor - 1, sorts: sorts })
    }

    moveRight = () => {
        if (this.state.indexForEditor >= this.state.sorts.length - 1) { return; }
        let indexForEditor = this.state.indexForEditor;
        let sorts = lodash.cloneDeep(this.state.sorts);
        let sort = sorts[indexForEditor];
        sorts[indexForEditor] = sorts[indexForEditor + 1];
        sorts[indexForEditor + 1] = sort;
        this.setState({ indexForEditor: indexForEditor + 1, sorts: sorts })
    }

    remove = () => {
        let sorts = lodash.cloneDeep(this.state.sorts);
        sorts = sorts.filter((f, index) => {
            return index !== this.state.indexForEditor;
        });
        this.setState({ sorts: sorts })
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, sorts);
        this.closeEditor();
    }

    renderForm(formikProps: any, entityType: string) {
        return <ConnectedComponentInSimpleComponent ref={this.ref} info={new ConnectedPageInfo(sliceSortForm, SortForm, "sortForm",
            {
                closeSortEditor: this.closeEditor,
                onSave: this.onSave,
                embeddedMode: false,
                remove: this.remove,
                moveLeft: this.moveLeft,
                moveRight: this.moveRight,
            })} formikProps={formikProps} entityDescriptor={entityType} indexForEditor={this.state.indexForEditor} formOpened={this.state.editorOpened} error={this.state.error} sorts={this.state.sorts} />
    }

    render = () => {
        const entityName = this.props.fieldDescriptor.entityDescriptor ? this.props.fieldDescriptor.entityDescriptor.name : this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName];
        let sorts = this.state.sorts;
        if (this.state.entityName !== entityName) {
            this.setState({ entityName: entityName })
            sorts = [];
        }
        const initialValues = this.state.indexForEditor >= 0 ? this.state.sorts[this.state.indexForEditor] : { field: '', direction: 'ASC' };
        return (
            <>
                <div className='FilterBar_div'>
                    {sorts?.map((sort: Sort, index: number) =>
                        <Label key={index} size='mini' className='SortBar_sortDiv'>
                            <div>
                                <Icon className='SortBar_sortIcon' size='large' name={sort.direction === 'ASC' ? 'sort up' : 'sort down'} />
                            </div>
                            <BlocklyReadOnly openEditor={(e: any) => this.openEditor(index)} key={index}>{createSortBlock(sort, entityName)}</BlocklyReadOnly>
                        </Label>)}
                    <Button type='button' onClick={e => this.openEditor(-1)}>...</Button>
                    <Formik initialValues={initialValues} onSubmit={() => { }}>
                        {(formikProps: any) => this.renderForm(formikProps, entityName)}
                    </Formik>
                </div>
            </>
        );
    }
}


export const SortFieldRenderer = (props: FieldRendererProps) => {
    const { value, entity, fieldDescriptor } = props;
    const entityName = props.fieldDescriptor.entityDescriptor ? props.fieldDescriptor.entityDescriptor.name : entity[fieldDescriptor.fieldForEntityName];
    return <>
        <Popup flowing on='click' trigger={<Button size='mini' content='...' />}>
            <div className='FilterBar_div'>
                {value?.map((sort: Sort, index: number) =>
                    <Label key={index} size='mini' className='SortBar_sortDiv'>
                        <div>
                            <Icon className='SortBar_sortIcon' size='large' name={sort.direction === 'ASC' ? 'sort up' : 'sort down'} />
                        </div>
                        <BlocklyReadOnly key={index}>{createSortBlock(sort, entityName)}</BlocklyReadOnly>
                    </Label>)}
            </div>
        </Popup>
    </>
}