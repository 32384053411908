import { AnyAction } from "@reduxjs/toolkit";
import { match } from "react-router-dom";
import { RootReducerForPages } from "./RootReducerForPages";
import { Organization } from "@crispico/foundation-react/AppMeta";
import { Location } from "history";

export * from "./ConnectedPageHelper";
export * from "./RootReducerForPages";

// #region Type magic, reusable types

// Sorry for the names; difficult to find something better. Bigger: yes; but not necessarily better.

/**
 * let i: Extract2<{ a: MyType, b: string }, "a">;  => i: MyType
 * 
 * NonNullable: to allow the main type to have "?" = optional.
 * extends...: to not return never if the key doesn't exist
 */
type Extract2<T, selector> = selector extends keyof T ? NonNullable<T[Extract<keyof T, selector>]> : {};

// This is used in doc below; uncomment if playing around is needed
// 
// interface I {
//     s1: {
//         disp: { r1: number, r2: number },
//         ceva: number
//     },
//     s2: {
//         disp: { r3: number, r4: number }
//     }
// }

/**
 * let e: Extract3<I, "disp">; => e: { 
 *     s1: { r1: number, r2:number }, 
 *     s2: { r3: number, r4:number }
 * }
 */
type Extract3<T, selector> = {
    [K in keyof T]: Extract2<T[K], selector>
}

/**
 * let e: Omit2<I, "disp"> => e: { s1: { ceva: number }, s2: {} }
 */
type Omit2<T, selector extends string> = {
    [K in keyof T]: Omit<T[K], selector>
}

//#endregion

//#region Type magic for SliceFoundation

export interface SliceFoundation {
    initialState?: any,
    reducers?: any,
    impures?: any,
    nestedSlices?: any,
    onBeforeMergeByConnectedPageHelper?: Function
}

export type StateFrom<T> = ExtractInitialState<T> & PropagateState<ExtractNestedSlices<T>>;
type ExtractInitialState<T> = Extract2<T, "initialState">;
type ExtractNestedSlices<T> = Extract2<T, "nestedSlices">
type PropagateState<T> = {
    [K in keyof T]: StateFrom<T[K]>
}

export type StatePartialFrom<T> = Partial<ExtractInitialState<T>> & PropagateStatePartial<ExtractNestedSlices<T>>;
type PropagateStatePartial<T> = {
    [K in keyof T]?: StatePartialFrom<T[K]>
}

// Idem cf. below, but first level doesn't have "impures"
type Dispatchers0From<T> = ConvertReducers<Extract2<T, "reducers">> // omit impures for first level
    & { dispatch: (action: AnyAction) => void }
    & PropagateDispatchers<ExtractNestedSlices<T>>; // recursion

// the recursion, quite similar cf. above
export type DispatchersFrom<T> = ConvertReducers<Extract2<T, "reducers">>
    & Omit<Extract2<T, "impures">, "getDispatchers">
    & PropagateDispatchers<ExtractNestedSlices<T>>; // recursion

type PropagateDispatchers<T> = {
    [K in keyof T]: DispatchersFrom<T[K]>
}

type ConvertReducers<R> = {
    [K in keyof R]: ConvertReducer<R[K]>;
}

type ConvertReducer<T> = T extends (state: any) => infer R ? () => R :
    T extends (state: any, params: infer P) => infer R ? (params: P) => R : never;

// type ConvertImpures<Impures> = {
//     [K in keyof Impures]: ConvertImpure<Impures[K]>;
// }

// type ConvertImpure<T> = T extends () => infer R ? () => R :
//     T extends (params: infer P) => infer R ? (params: P) => R : never;

/**
 * 'match' and 'location' are populated only for pages! 
*/
export type PropsFrom<T> = StateFrom<T> & {
    dispatchers: DispatchersFrom<T>, dataCy?: string, match?: match<any>, location?: Location<any>,
    rootReducerForPages?: RootReducerForPages, currentUser?: any, currentOrganization?: Organization, currentOrganizationToFilterBy?:Organization,
    __path?: string
}; // added ? for quick an dirty fix; it shouldn't have it: because it always exist; but some components complain because we didn't provide it

type ReducersFrom<T> = // ommit for first level
    PropagateReducers<ExtractNestedSlices<T>>; // recursion

type ReducersFrom1<T> = Extract2<T, "reducers">
    & PropagateReducers<ExtractNestedSlices<T>>; // recursion

type PropagateReducers<T> = {
    [K in keyof T]: ReducersFrom1<T[K]>
}

export type SliceFoundationFromNested<T> = { nestedSlices: T };

//#endregion

//#region exported functions

type RecordedInvocation = {
    functionName: string,
    arguments: IArguments
}

/**
 * To cope w/ the cycles, by default this function returns a "holder" which knows to create the slice. This
 * will happen in ConnectedPageHelper.
 */
export function createSliceFoundationFromCallback<P>(callback: (() => (new () => P)), immediate?: boolean): P {
    return createSliceFoundationInternal(true, callback, immediate);
}

export function createSliceFoundation<P>(optionsClass: new () => P, immediate?: boolean): P {
    return createSliceFoundationInternal(false, optionsClass, immediate);
}

function createSliceFoundationInternal<P>(isCallback: boolean, optionsClassOrCallback: (new () => P) | (() => (new () => P)), immediate?: boolean): P {
    // TODO CS: toggle on/off pt a vedea daca de aici vine problema
    // immediate = true;
    if (immediate) {
        //@ts-ignore
        const inst = !isCallback ? new optionsClassOrCallback() : new optionsClassOrCallback()();
        return inst;
    }

    // returning a Proxy because we want to record (and apply later) the calls that are being
    // made to the slice
    return new Proxy(new class {

        recordedInvocations?: RecordedInvocation[]
        actualSlice?: P;

        getActualSlice(): P {
            if (!this.actualSlice) {
                // @ts-ignore
                const inst = !isCallback ? new optionsClassOrCallback() : new (optionsClassOrCallback())();
                if (this.recordedInvocations) {
                    this.recordedInvocations.forEach(r => (inst[r.functionName] as Function).apply(inst, r.arguments));
                }

                this.actualSlice = inst;
            }
            return this.actualSlice!;
        }
    }(), {
        get(target, p, receiver): any {
            if ("getActualSlice" === p || "actualSlice" === p || "recordedInvocations" === p) {
                return target[p];
            } else if ("initialState" === p || "reducers" === p || "impures" === p || "nestedSlices" === p) {
                // needed for the inheritance use case; in this case: on access, the slice is created.
                // @ts-ignore
                return target.getActualSlice()[p];
            } else {
                return function () {
                    if (!target.recordedInvocations) { target.recordedInvocations = []; }
                    // console.log("Recording invocation", p, arguments, target.actualSlice);
                    if (target.actualSlice) {
                        // in some rare cases (seen at Gabi), this is called when the actual slice has been
                        // created; so no need to record the call any more; the actual object needs to be invoked
                        // @ts-ignore
                        return target.actualSlice[p].apply(target.actualSlice, arguments);
                    } else {
                        // from my observations, only "setEntityDescriptor()" is attempted on a freshly created slice
                        target.recordedInvocations.push({ functionName: p as string, arguments });
                        // in only one place we use the result of setEntityDescriptor(); hence this return
                        return receiver;
                    }
                }
            }
        }
    }) as unknown as P;
}

//#endregion

export function getBaseReducers<SLICE, STATE = StateFrom<SLICE>>(slice: SLICE) {
    const result = {
        // ...base,

        getSlice(): SLICE {
            return slice;
        },

        setInReduxState(state: STATE, p: Partial<STATE>) {
            for (let k in p) {
                (state as any)[k] = (p as any)[k];
            }
        },

        getReducers(): ReducersFrom<SLICE> {
            throw new Error("The framework should have replaced it!");
        }
    }
    return result;
}

export function getBaseImpures<SLICE>(slice: SLICE) {
    return {
        getSlice(): SLICE {
            return slice;
        },

        // we are forced to have it as a function (not attrib, not getter), because
        // otherwise we get the error "used during initialization"; we call it
        // "getDispatchers()" and not "dispatchers()" to avoid confusion w/ "props.dispatchers"
        getDispatchers(): Dispatchers0From<SLICE> {
            throw new Error("The framework should have replaced it!");
        },

        getState(): StateFrom<SLICE> {
            throw new Error("The framework should have replaced it!");
        },

        dispatch(action: AnyAction): void {
            throw new Error("The framework should have replaced it!");
        }
    }
}


