import React, { ReactNode } from "react";
import { Icon, Label } from "semantic-ui-react";
import { MessageExt } from "../semanticUiReactExt";

type ChartCurrentSelectionProps = {
    date?: string,
    contentHeader?: ReactNode,
    contentMain?: ReactNode,
    currentPointDataCyAttribute?: string
}

export class ChartCurrentSelection extends React.Component<ChartCurrentSelectionProps> {

    render() {
        return <>
            <MessageExt>
                {this.props.contentHeader}
                <span style={{ float: "left", margin: "0.3em 0.5em" }}>{_msg("ChartCurrentSelection.currentPoint")}: <Label basic data-cy={this.props.currentPointDataCyAttribute}><Icon name='calendar alternate outline' /> {this.props.date}</Label></span>
            </MessageExt>
            {this.props.contentMain}
        </>;
    }
}