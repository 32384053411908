import React from "react";
import { Size } from "react-split-pane";
import { Button, Checkbox, CheckboxProps, Divider, Form, Radio } from "semantic-ui-react";
import { Utils } from "..";
import { SplitPaneExt } from "../components/ReactSplitPaneExt/ReactSplitPaneExt";
import { TestsAreDemoSlave } from "./TestsAreDemoSlave";

export enum StepByStepMode { OFF, ON, CONTROLLED_BY_PROGRAM }

interface MasterState {
    finishWaitingCallback?: Function;
    running: boolean;
    loggedMessages: string;
    horizontalLayout: boolean;
    size1?: Size;
    size2?: Size;
    autoPressNextEnabled: boolean;
    autoPressNextAt?: number;
    // normally the result should be a number (in the browser); but the TS typings (coming from node.js?) have another type
    autoPressTimerId?: any;
    stepByStepMode: StepByStepMode;
}

declare global {
    interface Window {
        testsAreDemoMaster?: TestsAreDemoMaster;
    }
}

const LOCAL_STORAGE_STEP_BY_STEP = "testsAreDemo.stepByStep";
const LOCAL_STORAGE_AUTO_RUN = "testsAreDemo.autoRun";
const LOCAL_STORAGE_HORIZONTAL_LAYOUT = "testsAreDemo.horizontalLayout";
const LOCAL_STORAGE_SIZE1 = "testsAreDemo.size1";
const LOCAL_STORAGE_SIZE2 = "testsAreDemo.size2";
const LOCAL_STORAGE_AUTO_PRESS_NEXT = "testsAreDemo.autoPressNext";

/**
 * Holds the IFrame, control panel, and log (for mocha).
 */
export class TestsAreDemoMaster extends React.Component<any, MasterState> {

    state: MasterState = {
        running: false,
        loggedMessages: "",
        horizontalLayout: false,
        autoPressNextEnabled: false,
        stepByStepMode: StepByStepMode.OFF
    }

    private _slave?: TestsAreDemoSlave;

    public get slave(): TestsAreDemoSlave {
        if (!this._slave) {
            // UPDATE: since we are rendering the UI only if slave exists, I don't think it's possible any more to enter here
            throw new Error("TestsAreDemoSlave (which should normally sit in the IFrame) did not connect to TestsAreDemoMaster");
        }
        return this._slave;
    }

    /**
     * Called when the slave "connects" to the master. 1) on page refresh. 2) if only the IFrame/slave refreshes.
     * I don't know why, but regarding auto refresh of CRA/Webpack: sometimes both master + slave are refreshed. 
     * But other times, only the slave refreshes.
     */
    public set slave(value: TestsAreDemoSlave) {
        this._slave = value;
        this.readStepByStep();
        this.forceUpdate(); // to show the checkbox at startup
        if ("true" === localStorage.getItem(LOCAL_STORAGE_AUTO_RUN)) {
            setTimeout(() => this.run()); // setTimeout() is just in case; I'm affraid that starting the tests during the initialization may generate some glitches
        }
    }

    componentDidMount() {
        // NOTE: cf. https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects, the constructor IS NOT a good
        // place to put such code, because of the double invocation
        if (window.testsAreDemoMaster) {
            throw new Error("Cannot create more than one instances of TestsAreDemoMaster");
        }
        window.testsAreDemoMaster = this; // to communicate this to the slave
        if ("true" === localStorage.getItem(LOCAL_STORAGE_HORIZONTAL_LAYOUT)) {
            this.setState({ horizontalLayout: true });
        }
        const size1 = Number(localStorage.getItem(LOCAL_STORAGE_SIZE1));
        if (!isNaN(size1)) {
            this.setState({ size1 });
        }
        const size2 = Number(localStorage.getItem(LOCAL_STORAGE_SIZE2));
        if (!isNaN(size2)) {
            this.setState({ size2 });
        }
        if ("true" === localStorage.getItem(LOCAL_STORAGE_AUTO_PRESS_NEXT)) {
            this.setState({ autoPressNextEnabled: true });
        }
    }

    readStepByStep() {
        const stored = localStorage.getItem(LOCAL_STORAGE_STEP_BY_STEP);
        let stepByStepMode: StepByStepMode;
        if (stored === null || isNaN(Number(stored))) {
            stepByStepMode = StepByStepMode.OFF;
        } else {
            stepByStepMode = Number(stored);
        }
        this.slave.stepByStep = stepByStepMode === StepByStepMode.ON;
        this.setState({ stepByStepMode });
    }

    log(what: string) {
        this.setState({ loggedMessages: this.state.loggedMessages + "\n" + what });
    }

    run() {
        this.setState({ running: true, loggedMessages: "" });
        this.slave.run();
    }

    nextStep() {
        this.disableAutoPressTimerIfEnabled();
        this.slave.hideSpotlight();
        this.state.finishWaitingCallback!()
        this.setState({ finishWaitingCallback: undefined });
    }

    public enableNextStepButton(finishWaitingCallback: Function) {
        this.setState({ finishWaitingCallback });
        if (this.state.autoPressNextEnabled) {
            this.enableAutoPressTimer();
        }
    }

    protected disableAutoPressTimerIfEnabled(shouldClear: boolean = true) {
        if (shouldClear) {
            clearTimeout(this.state.autoPressTimerId);
        }
        this.setState({ autoPressNextAt: undefined, autoPressTimerId: undefined });
    }

    protected enableAutoPressTimer() {
        this.setState({ autoPressNextAt: Utils.now().getTime() + 3000 });
        this.onAutoPressTimerTick();
    }

    protected onAutoPressTimerTick = () => {
        if (this.state.autoPressNextAt && Utils.now().getTime() > this.state.autoPressNextAt) {
            this.disableAutoPressTimerIfEnabled(false);
            this.nextStep();
        } else {
            this.setState({ autoPressTimerId: setTimeout(this.onAutoPressTimerTick, 1000) });
            this.forceUpdate();
        }
    }

    protected renderIFramePane() {
        return <div className="flex-container flex-grow">
            <iframe width="100%" height="100%" src="./?testsAreDemoSlave" />
        </div>
    }

    protected renderMochaLog() {
        return <textarea style={{ width: "100%", height: "100%" }} value={this.state.loggedMessages} />
    }

    protected onStepByStepModeChanged = (e: any, { value }: CheckboxProps) => {
        const mode = value as StepByStepMode;
        this.slave.stepByStep = mode === StepByStepMode.ON;
        localStorage.setItem(LOCAL_STORAGE_STEP_BY_STEP, "" + mode);
        this.setState({ stepByStepMode: mode });
    }

    protected renderControlPanel() {
        let nextStepSuffix = "";
        if (this.state.autoPressNextAt) {
            nextStepSuffix = " (" + Math.round((this.state.autoPressNextAt - Utils.now().getTime()) / 1000) + ")";
        }
        return <div>
            <p>TestsAreDemoMaster</p>
            <Button content={`Layout: ${this.state.horizontalLayout ? "horizontal" : "vertical"}`} onClick={() => {
                this.setState({ horizontalLayout: !this.state.horizontalLayout });
                this.resetSizes();
                setTimeout(() => localStorage.setItem(LOCAL_STORAGE_HORIZONTAL_LAYOUT, "" + this.state.horizontalLayout));
            }} />
            <Button content="Reset sizes" onClick={this.resetSizes} />
            <Button content="Run" disabled={this.state.running} onClick={() => this.run()} />
            {this.state.finishWaitingCallback && <Button content={"Next step" + nextStepSuffix} onClick={() => this.nextStep()} />}
            {this.state.finishWaitingCallback && <Button content="Run normally" onClick={() => {
                // we set this, and we'll re-read from local storage at the end
                this.slave.stepByStep = false;
                this.nextStep();
            }} />}
            <Divider className="TestsAreDemo_divider" />
            <Checkbox checked={"true" === localStorage.getItem(LOCAL_STORAGE_AUTO_RUN)} label="Auto run after page load" onChange={() => {
                localStorage.setItem(LOCAL_STORAGE_AUTO_RUN, "true" === localStorage.getItem(LOCAL_STORAGE_AUTO_RUN) ? "false" : "true");
                this.forceUpdate();
            }} />
            <Checkbox checked={this.state.autoPressNextEnabled} label="Auto press 'Next step' with timer" onChange={() => {
                if (this.state.autoPressNextEnabled) {
                    this.disableAutoPressTimerIfEnabled();
                } else {
                    this.enableAutoPressTimer();
                }
                localStorage.setItem(LOCAL_STORAGE_AUTO_PRESS_NEXT, "" + !this.state.autoPressNextEnabled);
                this.setState({ autoPressNextEnabled: !this.state.autoPressNextEnabled });
            }} />
            <br />
            <Form>
                <Form.Group inline>
                    <Form.Field>
                        Step by step:
                    </Form.Field>
                    <Form.Field>
                        <Radio label='OFF' value={StepByStepMode.OFF} checked={this.state.stepByStepMode === StepByStepMode.OFF} onChange={this.onStepByStepModeChanged} />
                    </Form.Field>
                    <Form.Field>
                        <Radio label='ON' value={StepByStepMode.ON} checked={this.state.stepByStepMode === StepByStepMode.ON} onChange={this.onStepByStepModeChanged} />
                    </Form.Field>
                    <Form.Field>
                        <Radio label='Controlled by program' value={StepByStepMode.CONTROLLED_BY_PROGRAM} checked={this.state.stepByStepMode === StepByStepMode.CONTROLLED_BY_PROGRAM} onChange={this.onStepByStepModeChanged} />
                    </Form.Field>
                </Form.Group>
            </Form>
        </div>
    }

    protected onModifSize1 = (newSize: number) => {
        this.setState({ size1: newSize });
        localStorage.setItem(LOCAL_STORAGE_SIZE1, "" + newSize);
    }

    protected onModifSize2 = (newSize: number) => {
        this.setState({ size2: newSize });
        localStorage.setItem(LOCAL_STORAGE_SIZE2, "" + newSize);
    }

    protected resetSizes = () => {
        localStorage.removeItem(LOCAL_STORAGE_SIZE1);
        localStorage.removeItem(LOCAL_STORAGE_SIZE2);
        this.setState({ size1: undefined, size2: undefined });
    }

    render() {
        return this.state.horizontalLayout
            ? <SplitPaneExt size={this.state.size1 || "60%"} split="horizontal" onDragFinished={this.onModifSize1} onResizerDoubleClick={this.resetSizes}>
                {this.renderIFramePane()}
                {!this._slave ? <p>TestsAreDemoSlave not yet loaded</p> : <SplitPaneExt split="vertical" size={this.state.size2 || "30%"} onDragFinished={this.onModifSize2} onResizerDoubleClick={this.resetSizes}>
                    {this.renderMochaLog()}
                    {this.renderControlPanel()}
                </SplitPaneExt>}
            </SplitPaneExt>
            : <SplitPaneExt size={this.state.size1 || "30%"} split="vertical" onDragFinished={this.onModifSize1} onResizerDoubleClick={this.resetSizes}>
                {!this._slave ? <p>TestsAreDemoSlave not yet loaded</p> : <SplitPaneExt split="horizontal" size={this.state.size2 || "50%"} onDragFinished={this.onModifSize2} onResizerDoubleClick={this.resetSizes}>
                    {this.renderControlPanel()}
                    {this.renderMochaLog()}
                </SplitPaneExt>}
                {this.renderIFramePane()}
            </SplitPaneExt>
    }

}