import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { apolloClientHolder, createSliceFoundation, EntityDescriptor, EntityEditorPage, ENT_TABLE, getBaseReducers, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { MultiCsvEditorPage, MultiCsvEditorPageHOC } from "@crispico/foundation-react/components/MultiCsvEditor/MultiCsvEditor";
import React from "react";
import { Segment } from "semantic-ui-react";
import moment from "moment-timezone";
import _ from 'lodash';
import { GanttAssignmentPage, GanttAssignmentPageRRC } from './GanttAssignmentPage';
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import gql from "graphql-tag";
import { GanttAssignmentHistogram, GanttAssignmentHistogramRRC } from "./GanttAssignmentHistogram";
import { IMPORT_ENTITIES_FROM_CSV } from "./queries";

export const sliceGanttAssignmentEntityEditorPage = createSliceFoundation(class Ext extends SliceEntityEditorPage {
    
    reducers = {
        ...sliceEntityEditorPageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),
    }
});

export class GanttAssignmentEntityEditorPage extends EntityEditorPage<PropsFrom<typeof sliceGanttAssignmentEntityEditorPage>> {
    
    protected inputMultiCsvEditorRef = React.createRef<MultiCsvEditorPage>();
    protected outputMultiCsvEditorRef = React.createRef<MultiCsvEditorPage>();
    protected ganttAssignmentPageRef = React.createRef<GanttAssignmentPage>();
    protected ganttAssignmentHistogramRef = React.createRef<GanttAssignmentHistogram>();;

    constructor(props: PropsFrom<typeof sliceGanttAssignmentEntityEditorPage>) {
        super(props);
        this.saveEntity = this.saveEntity.bind(this);
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceGanttAssignmentEntityEditorPage>) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected renderTabButtons() {
        return <Segment className="buttonBar EntityEditorFormSimple_bar">
            {this.renderButtons({ hideDuplicate: true })}
        </Segment>;
    }

    protected saveEntity(entity: any) {
        this.props.dispatchers.setInReduxState({ entity });
        this.onSave();
    }

    protected getExtraTabPanes() {

        return super.getExtraTabPanes()?.concat([
            { 
                routeProps: { path: "/inputEntitiesCsvEditor" }, menuItemProps: { icon: "file alternate outline", content: _msg("GanttAssignment.inputEntitiesCsv.label") },
    
                commit: () => {
                    if (this.inputMultiCsvEditorRef.current) {
                        this.props.dispatchers.setInReduxState({ entity: {...this.props.entity, inputEntitiesCsv: this.inputMultiCsvEditorRef.current.getCsv() }});
                    }
                },

                render: () => {
                    return <>
                        {this.renderTabButtons()}
                        <MultiCsvEditorPageHOC id="multiCsvEditorInput" csvText={this.props.entity?.inputEntitiesCsv} ref={this.inputMultiCsvEditorRef} />
                    </>
                }
            },
            { 
                routeProps: { path: "/outputEntitiesCsvEditor" }, menuItemProps: { icon: "file alternate", content: _msg("GanttAssignment.outputEntitiesCsv.label") },
    
                commit: () => {
                    if (this.outputMultiCsvEditorRef.current) {
                        this.props.dispatchers.setInReduxState({ entity: {...this.props.entity, outputEntitiesCsv: this.outputMultiCsvEditorRef.current.getCsv() }});
                    }
                },

                render: () => {
                    return <>
                        {this.renderTabButtons()}
                        <MultiCsvEditorPageHOC id="multiCsvEditorOutput" csvText={this.props.entity?.outputEntitiesCsv} ref={this.outputMultiCsvEditorRef} />
                    </>
                }
            },
            {
                routeProps: { path: "/gantt" },
                menuItemProps: { icon: "chart bar", content: "Gantt" },
                render: () => <GanttAssignmentPageRRC id="GanttAssignmentPageRRC" ref={this.ganttAssignmentPageRef} entity={this.props.entity} saveEntity={this.saveEntity} />
            },
            {
                routeProps: { path: "/histogram" },
                menuItemProps: { icon: "chart pie", content: "Histogram" },
                render: () => <GanttAssignmentHistogramRRC id="GanttAssignmentHistogramRRC" ref={this.ganttAssignmentHistogramRef} entity={this.props.entity}/>
            },
        ]);
    }
}

export class GanttAssignmentEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "GanttAssignment"
        });
    }

    protected customize() {
        this.addFieldDescriptor({ name: "name", type: FieldType.string })
        this.addFieldDescriptor({ name: "flightsDate", type: FieldType.date, format: Utils.dateFormat, defaultValue: moment().toISOString() })
        this.addFieldDescriptor({ name: "inputEntitiesCsv", type: FieldType.text })
        this.addFieldDescriptor({ name: "outputEntitiesCsv", type: FieldType.text, optional: true })
        this.addFieldDescriptor({ name: "params", type: "GanttAssignmentParams" })
        this.addFieldDescriptor({ name: "organization", type: "Organization" })
        this.infoEditor.slice = sliceGanttAssignmentEntityEditorPage.setEntityDescriptor(this);
        this.infoEditor.wrappedComponentClass = GanttAssignmentEntityEditorPage;
        this.isInDefaultColumnConfig(false, "inputEntitiesCsv", "outputEntitiesCsv");
    }

    protected getInfoEditor() {
        const result = super.getInfoEditor();
        result.routeProps!.routeIsModal = false;
        return result;
    }

    async getLastGanttAssignmentId() {
        const query = gql(`query ganttAssignmentService_findByFilter($params: FindByFilterParamsInput) {
            ganttAssignmentService_findByFilter(params: $params) { 
                results { id }
            }
        }`);
        return (await apolloClientHolder.apolloClient.query({
            query, variables: FindByFilterParams.create().sorts([{ field: ID, direction: "DESC" }]).pageSize(1)
        })).data["ganttAssignmentService_findByFilter"].results[0].id;
    }

    static async importEntitiesFromJson(entities: { [key: string]: { [key: number]: any } }, csv: string) {
        const json = (await apolloClientHolder.apolloClient.query({ query: IMPORT_ENTITIES_FROM_CSV, variables: { csv} })).data["ganttAssignmentService_importEntitiesFromCsv"];
        const object = JSON.parse(json);
        const simpleNames: string[] = [];
        Object.keys(object).forEach(entityType => {
            const simpleName = entityType.slice(entityType.lastIndexOf(".") + 1);
            simpleNames.push(simpleName);
            entities[simpleName] = {};
            object[entityType].forEach((entity: any) => {
                entities[simpleName][entity.id] = entity;
            });
        });
        return simpleNames;
    }
}
