import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";

export class GanttAssignmentParamsEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "GanttAssignmentParams"
        });
    }

    protected customize() {
        this.addFieldDescriptor({ name: "name", type: FieldType.string })
        this.addFieldDescriptor({ name: "flightAndObjectActionFilter", type: FieldType.filter, entityDescriptor: entityDescriptors["FlightAndObjectAction"], filterable: false, sortable: false })
    }
}
