import React from "react";
import { Color, ColorSettings } from "../AppMeta";
import "../messages";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "../reduxReusableComponents/ReduxReusableComponents";

export class ColorRegistryState extends State {
    data: { [key: string]: string | number } = {};
}

export class ColorRegistryReducers<S extends ColorRegistryState = ColorRegistryState> extends Reducers<S> {
    put(key: string, value: number) {
        this.s.data[key] = value;
    }
}

/**
 * Holds colors. Practically a map between a name and a value (numeric), e.g. "red" -> 0xFF0000.
 * It can also manage "aliases", e.g. "error" -> "red", "importantNotification" -> "red". 
 * 
 * It's recommended to uses aliases rather than raw colors. This allows users to have more control
 * when customizing the colors.
 * 
 * And speaking about customization, this is intended to be used together w/ `ColorSettings`. This
 * way, the user can override some colors from the UI.
 * 
 * From the Redux perspective, this is an interesting component, because it's not visual. So why
 * didn't we implemented as a normal class? Because we are interested to hold its contents in the Redux
 * state.
 * 
 * @see Utils.convertColor*()
 * 
 * @author Cristian Spiescu
 */
export class ColorRegistry extends React.Component<RRCProps<ColorRegistryState, ColorRegistryReducers>> {
    
    static INSTANCE: ColorRegistry;

    init(colorSettings: ColorSettings) {
        let data = {} as any;
        colorSettings.data.forEach((color: Color) => {
            data[color.name] = color.thisColorIsAliasFor ? color.thisColorIsAliasFor : color.value;
        });
        this.props.r.setInReduxState({ data });
    }

    get(key: string): number {
        return this._get(key);
    }

    _get(key: string, visited?: string[]): number {
        if ("none" === key) {
            return -1;
        }

        let orig: string | number = this.props.s.data[key];

        if (!orig) {
            return 0;
        }

        if (typeof orig === "number") {
            return orig;
        }

        let thisColorIsAliasFor: string = orig as string;
        if (!visited) {
            visited = [];
        }

        let resolvedColor: number;
        if (visited.find(v => v === thisColorIsAliasFor)) {
            // bad; a loop; put a default color which would maybe alert there is a loop; we don't want to break execution by throwing an error
            resolvedColor = 0x750f27; // darker red
        } else {
            visited.push(thisColorIsAliasFor);
            resolvedColor = this._get(thisColorIsAliasFor, visited);
        }
        // the current function is probably called from code within a render() method. And because this will trigger an
        // update of the component, React will issue a warning (e.g. while rendering comp A, you are updating comp B = ColorRegistry)
        // hence calling later
        setTimeout(() => this.props.r.put(key, resolvedColor));
        return resolvedColor;
    }
    
    render() {
        // this is a non visual component
        return null;
    }
}

export const ColorRegistryRRC = ReduxReusableComponents.connectRRC(ColorRegistryState, ColorRegistryReducers, ColorRegistry);

export const preloadedStateWithSomeColors = {
    [ReduxReusableComponents.getLongIdForSingleton(ColorRegistry)]: {
        data: {
            "red": 0xFF0000,
            "error": "red"
        }
    }
}