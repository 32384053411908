import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export const Draggable = (props: any) => {
  const [{isDragging}, drag] = useDrag(() => ({
    type: props.type ? props.type : 'draggable',
    item: props.item,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return <div ref={drag} style={{opacity: isDragging ? 0.5 : 1, cursor: 'move'}}>{props.children}</div>
}

export const Droppable = (props: any) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: props.accept ? props.accept : 'draggable',
      drop: (item: unknown) => props.drop && props.drop(item),
      canDrop: () => {return props.canDrop ? props.canDrop() : true},
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop()
      })
    })
  );

  const color = isOver ? canDrop ? 'green' : 'red' : canDrop ? 'yellow' : undefined;
  return <div ref={drop} className="wh100">
    {props.children}
    <div style={{
      position: 'absolute', top: 0, left: 0, backgroundColor: color, margin: '3px',
      width: '100%', height: '32px', zIndex: 10, opacity: 0.7, transition: '0.2s'
    }} />
  </div>;
}

export class DragAndDropArea extends React.Component<{}> {
  render() {
    return <DndProvider backend={HTML5Backend}>{this.props.children}</DndProvider>
  }
}