import React from "react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import { Button } from "semantic-ui-react";

export class FilterPreview extends React.Component<{ entityName: string, getFilter: () => Filter | undefined, displayName?: boolean }> {

    render() {
        return <Button icon="eye" compact content={this.props.displayName === true ? _msg("Filter.preview") : undefined} onClick={() => {
            const filter = this.props.getFilter();
            if (filter) {
                window.open("#" + new ShareLinkLogic().createLink(false, entityDescriptors[this.props.entityName], filter, []))
            }
        }} />
    }
}