import React from "react";
import { ClassToNameRegistry } from "../utils/ClassToNameRegistry";

interface Props {
    objectToPublish: any;
    dataTestIdSuffix?: string,
    className?: string;
}

/**
 * @author Cristian Spiescu
 */
export class ShortcutRefForTest extends React.Component<Props> {

    ref = React.createRef<any>();

    componentDidMount() {
        this.ref.current.shortcutRefForTest = this.props.objectToPublish;
    }

    render() {
        const dataTestIdSuffix = this.props.dataTestIdSuffix ? "_" + this.props.dataTestIdSuffix : "";
        return <div data-testid={`${ClassToNameRegistry.INSTANCE.getClassNameFromObject(this.props.objectToPublish)}${dataTestIdSuffix}`} ref={this.ref} className={this.props.className}></div>
    }
}