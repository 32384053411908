//@ts-ignore
import { TestUtils, WrapWithStoreOptions } from '@crispico/foundation-react/utils/TestUtils';
//@ts-ignore
import StoryRouter from 'storybook-react-router';
import { zeroTrainingArticle } from '../../zeroTraining/dataStructures';
import { joyrideDecoratorParams } from '../../zeroTraining/JoyrideDecorator';
import { RoleEditorPage, sliceRoleEditorPage } from './RoleEditorPage';
import { roleEntityDescriptor } from './roleEntityDescriptor';
import { apolloMock, testState1, testState2, indexAudit } from './testState';

export default zeroTrainingArticle("RoleEditor.label", "RoleEditor.zt", {
    decorators: [StoryRouter({}, { initialEntries: [roleEntityDescriptor?.getEntityEditorUrl("undefined") + "/config"] })],
});

var options: WrapWithStoreOptions = { mockApolloClient: apolloMock, wrapInRouter: false, frozenReducer: true, additionalProps: { numberzeroTrainingMode: true } }
var options2: WrapWithStoreOptions = { ...options, additionalProps: { accordionActiveIndex: indexAudit + 1, numberzeroTrainingMode: true } }

sliceRoleEditorPage.setEntityDescriptor(roleEntityDescriptor);
export const SnapGeneral = () => TestUtils.wrapWithStoreForSlice(sliceRoleEditorPage, RoleEditorPage, testState1, options);
SnapGeneral.story = {
    name: _msg("RoleEditor.general"),
    parameters: joyrideDecoratorParams("RoleEditor.zt", [
        { shortKey: "0", target: ".accordion.ui.fluid.styled", content: _msg("RoleEditor.general.all") },
        { shortKey: "1", target: ".content.active", content: _msg("RoleEditor.general.top") },
        { shortKey: "2", target: "[data-cy=roleOverview]", content: _msg("RoleEditor.overview") },
    ])
};

export const SnapRecord = () => TestUtils.wrapWithStoreForSlice(sliceRoleEditorPage, RoleEditorPage, testState2, options2);
SnapRecord.story = {
    decorators: [StoryRouter({}, { initialEntries: ["/RoleEditor/undefined/config"] })],
    name: _msg("RoleEditor.record"),
    parameters: joyrideDecoratorParams("RoleEditor.zt", [
        { shortKey: "0", target: ".content.active", content: _msg("RoleEditor.record1") },
        { shortKey: "1", target: ".ui.orange.circular.horizontal.label", content: _msg("RoleEditor.record2") },
        { shortKey: "2", target: ".accordion.ui.fluid.styled.Role2EditorPage", content: _msg("RoleEditor.record3") },
        { shortKey: "3", target: "[data-cy=roleOverview]", content: _msg("RoleEditor.overview") }
    ])
};