import { StatePartialFrom } from "@crispico/foundation-react/reduxHelpers";
import { SliceEntityTablePage } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { sliceBlocklyEditorTab } from "./BlocklyEditorTab";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { CustomQuerySource, ErrorType } from "./dataStructures";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { employees } from "@crispico/foundation-react/entity_crud/EntityTablePage.testStates";
import { createDemoDescriptor } from "@crispico/foundation-react/entity_crud/stories/EntityTablePageCustomized.stories";
import { sliceColumnConfigDropdown } from "../ColumnConfig/ColumnConfigDropdown";
import { sliceCustomQueryDropdown } from "./CustomQueryDropdown";
import { sliceSortBar, Sort } from "./SortBar";
import { sliceFilterForm } from "./FilterForm";
import { COLUMN_DEFAULT_WIDTH } from "../ColumnConfig/dataStructures";
import { ClientColumnConfig } from "../ColumnConfig/ClientColumnConfig";
import { CUSTOM_QUERY_BAR_MODE } from "./CustomQueryBar";

const employeeEntityDescriptor = createDemoDescriptor();

const employeeEntityDescriptorDefaultCC: ClientColumnConfig = {
    dirty: false,
    id: null,
    name: _msg('general.default'),
    entityName: 'Employee',
    configObject: {
        columns: [{ name: 'id', width: COLUMN_DEFAULT_WIDTH }, { name: 'firstName', width: COLUMN_DEFAULT_WIDTH }, { name: 'name', width: COLUMN_DEFAULT_WIDTH }, { name: 'stillEmployed', width: COLUMN_DEFAULT_WIDTH }]
    },
    fromCrudSettings: false,
    organization: null
}

export const TableInitial: StatePartialFrom<SliceEntityTablePage> = {
    totalCount: employees.length,
    loaded: employees.length,
    tableSimple: {
        entities: employees,
        selected: -101
    },
    columnConfigDropdown: {
        ...sliceColumnConfigDropdown.initialState,
        columnConfig: employeeEntityDescriptorDefaultCC
    },
    customQueryBar: {
        customQueryDropdown: {
            ...sliceCustomQueryDropdown.initialState,
            customQuery: employeeEntityDescriptor.getDefaultCustomQuery()
        }
    }
}

export const OpenCustomQueryDropdown: StatePartialFrom<SliceEntityTablePage> = {
    ...TableInitial,
    customQueryBar: {
        ...TableInitial.customQueryBar,
        customQueryDropdown: {
            ...TableInitial.customQueryBar?.customQueryDropdown,
            dropdownOpened: true,
            customQueries: [employeeEntityDescriptor.getDefaultCustomQuery(),
            {
                name: 'CustomQuery 1',
                screen: 'Employee',
                enabled: true,
                color: '',
                id: 111,
                customQueryDefinitionObject: {
                    filter: { operator: FilterOperators.forComposedFilter.or.value, filters: [{ field: 'id', operator: FilterOperators.forNumber.equals.value, value: '1', enabled: true }], enabled: true },
                    sorts: [] as Array<Sort>
                },
                dirty: false,
                preferredColumnConfig: undefined,
                displayAsCards: false,
                fromCrudSettings: false,
                emailScheduleRecipientList: undefined,
                emailScheduleCron: '',
                organization: null
            }]
        }
    }

}

export const SelectCustomQuery1: StatePartialFrom<SliceEntityTablePage> = {
    ...OpenCustomQueryDropdown,
    customQueryBar: {
        ...OpenCustomQueryDropdown.customQueryBar,
        customQueryDropdown: {
            ...OpenCustomQueryDropdown.customQueryBar?.customQueryDropdown,
            dropdownOpened: undefined,
            customQuery: {
                name: 'CustomQuery 1',
                screen: 'Employee',
                enabled: true,
                color: '',
                id: 111,
                customQueryDefinitionObject: {
                    filter: { operator: FilterOperators.forComposedFilter.or.value, filters: [{ field: 'id', operator: FilterOperators.forNumber.equals.value, value: '1', enabled: true }], enabled: true },
                    sorts: [] as Array<Sort>
                },
                dirty: false,
                preferredColumnConfig: undefined,
                displayAsCards: false,
                fromCrudSettings: false
            }
        },
        filterBar: {
            ...OpenCustomQueryDropdown.customQueryBar?.filterBar,
            rootFilter: { operator: FilterOperators.forComposedFilter.or.value, filters: [{ field: 'id', operator: FilterOperators.forNumber.equals.value, value: '1', enabled: true }], enabled: true }
        }
    }
}

export const SearchCustomQuery: StatePartialFrom<SliceEntityTablePage> = {
    ...SelectCustomQuery1,
    customQueryBar: {
        ...SelectCustomQuery1.customQueryBar,
        customQueryDropdown: {
            ...SelectCustomQuery1.customQueryBar?.customQueryDropdown,
            dropdownOpened: true,
            search: 'Cu'
        }
    }
}

export const CustomQueryDetails: StatePartialFrom<SliceEntityTablePage> = {
    ...SelectCustomQuery1,
    customQueryBar: {
        ...SelectCustomQuery1.customQueryBar,
        customQueryDropdown: {
            ...SelectCustomQuery1.customQueryBar?.customQueryDropdown,
            customQuery: employeeEntityDescriptor.getDefaultCustomQuery(),
            dropdownOpened: true,
            viewCustomQueryPopup: {
                editorOpened: true,
                index: 1
            }
        }
    }
}

export const EditCustomQuery: StatePartialFrom<SliceEntityTablePage> = {
    ...SelectCustomQuery1,
    customQueryBar: {
        ...SelectCustomQuery1.customQueryBar,
        customQueryDropdown: {
            ...SelectCustomQuery1.customQueryBar?.customQueryDropdown,
            dropdownOpened: true,
            customQueryEntityEditorPage: {
                ...SelectCustomQuery1.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
                modalOpen: true,
                entitySource: CustomQuerySource.EDIT,
                blocklyEditorTab: {
                    ...SelectCustomQuery1.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage?.filterBar?.blocklyEditor,
                    rootFilter: { operator: FilterOperators.forComposedFilter.or.value, filters: [{ field: 'id', operator: FilterOperators.forNumber.equals.value, value: '1', enabled: true }], enabled: true }
                },
                sortTab: {
                    ...SelectCustomQuery1.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage?.sortTab,
                    sorts: []
                },
                entity: {
                    name: 'CustomQuery 1',
                    screen: 'Employee',
                    enabled: true,
                    color: '',
                    id: 111,
                    customQueryDefinition: {
                        filter: { operator: FilterOperators.forComposedFilter.or.value, filters: [{ field: 'id', operator: FilterOperators.forNumber.equals.value, value: '1', enabled: true }], enabled: true },
                        sorts: [] as Array<Sort>
                    }
                }
            }
        }
    }
}

export const EditCustomQuerySortTab: StatePartialFrom<SliceEntityTablePage> = {
    ...EditCustomQuery,
    customQueryBar: {
        ...EditCustomQuery.customQueryBar,
        customQueryDropdown: {
            ...EditCustomQuery.customQueryBar?.customQueryDropdown,
            customQueryEntityEditorPage: {
                ...EditCustomQuery.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
            }
        }
    }
}

export const EditSortFromSortTab: StatePartialFrom<SliceEntityTablePage> = {
    ...EditCustomQuerySortTab,
    customQueryBar: {
        ...EditCustomQuerySortTab.customQueryBar,
        customQueryDropdown: {
            ...EditCustomQuerySortTab.customQueryBar?.customQueryDropdown,
            customQueryEntityEditorPage: {
                ...EditCustomQuerySortTab.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
                sortTab: {
                    ...EditCustomQuerySortTab.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage?.sortTab,
                    editorOpened: [800, 170],

                }
            }
        }
    }
}

export const ErrorOnTryingToAddaSortWithoutField: StatePartialFrom<SliceEntityTablePage> = {
    ...EditSortFromSortTab,
    customQueryBar: {
        ...EditSortFromSortTab.customQueryBar,
        customQueryDropdown: {
            ...EditSortFromSortTab.customQueryBar?.customQueryDropdown,
            customQueryEntityEditorPage: {
                ...EditSortFromSortTab.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
                sortTab: {
                    ...EditSortFromSortTab.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage?.sortTab,
                    error: true,
                }
            }
        }
    }
}

export const SortAdded: StatePartialFrom<SliceEntityTablePage> = {
    ...ErrorOnTryingToAddaSortWithoutField,
    customQueryBar: {
        ...ErrorOnTryingToAddaSortWithoutField.customQueryBar,
        customQueryDropdown: {
            ...ErrorOnTryingToAddaSortWithoutField.customQueryBar?.customQueryDropdown,
            customQueryEntityEditorPage: {
                ...ErrorOnTryingToAddaSortWithoutField.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
                sortTab: {
                    ...ErrorOnTryingToAddaSortWithoutField.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage?.sortTab,
                    editorOpened: false,
                    sorts: [{ field: 'id', direction: 'ASC' }]
                }
            }
        }
    }
}

export const EditCustomQueryFilterTab: StatePartialFrom<SliceEntityTablePage> = {
    ...SortAdded,
    customQueryBar: {
        ...SortAdded.customQueryBar,
        customQueryDropdown: {
            ...SortAdded.customQueryBar?.customQueryDropdown,
            customQueryEntityEditorPage: {
                ...SortAdded.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
                blocklyEditorTab: {
                    ...SortAdded.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage?.filterBar?.blocklyEditor,
                }
            }
        }
    }
}
export const EditSimpleFilter: StatePartialFrom<SliceEntityTablePage> = {
    ...EditCustomQueryFilterTab,
    customQueryBar: {
        ...EditCustomQueryFilterTab.customQueryBar,
        customQueryDropdown: {
            ...EditCustomQueryFilterTab.customQueryBar?.customQueryDropdown,
            customQueryEntityEditorPage: {
                ...EditCustomQueryFilterTab.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
                blocklyEditorTab: {
                    ...EditCustomQueryFilterTab.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage?.filterBar?.blocklyEditor,
                    rootFilter: { operator: FilterOperators.forComposedFilter.or.value, filters: [{ field: 'id', operator: FilterOperators.forNumber.equals.value, value: '1', enabled: true }, { field: '', operator: '', value: '', enabled: true }], enabled: true },
                    filterFormOpened: true,
                    selectedFilterPath: `filters${Utils.defaultIdSeparator}1`,
                    filterForm: {
                        ...sliceFilterForm.initialState
                    },
                }
            }
        }
    }
}

export const ErrorOnTryingToAddInvalidFilter: StatePartialFrom<SliceEntityTablePage> = {
    ...EditSimpleFilter,
    customQueryBar: {
        ...EditSimpleFilter.customQueryBar,
        customQueryDropdown: {
            ...EditSimpleFilter.customQueryBar?.customQueryDropdown,
            customQueryEntityEditorPage: {
                ...EditSimpleFilter.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
                blocklyEditorTab: {
                    ...EditSimpleFilter.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage?.filterBar?.blocklyEditor,
                    filterForm: {
                        ...sliceFilterForm.initialState,
                        error: ErrorType.INVALID
                    },
                }
            }
        }
    }
}

export const AddSimpleFilter: StatePartialFrom<SliceEntityTablePage> = {
    ...ErrorOnTryingToAddInvalidFilter,
    customQueryBar: {
        ...ErrorOnTryingToAddInvalidFilter.customQueryBar,
        customQueryDropdown: {
            ...ErrorOnTryingToAddInvalidFilter.customQueryBar?.customQueryDropdown,
            customQueryEntityEditorPage: {
                ...ErrorOnTryingToAddInvalidFilter.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
                blocklyEditorTab: {
                    ...ErrorOnTryingToAddInvalidFilter.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage?.filterBar?.blocklyEditor,
                    filterFormOpened: false,
                    rootFilter: { operator: FilterOperators.forComposedFilter.or.value, filters: [{ field: 'id', operator: FilterOperators.forNumber.equals.value, value: '1', enabled: true }, { field: 'name', value: 'CCC', operator: FilterOperators.forString.equals.value, enabled: true }], enabled: true },
                    filterForm: {
                        ...sliceFilterForm.initialState,
                        error: ErrorType.NONE
                    },
                }
            }
        }
    }
}

export const AddORFilter: StatePartialFrom<SliceEntityTablePage> = {
    ...AddSimpleFilter,
    customQueryBar: {
        ...AddSimpleFilter.customQueryBar,
        customQueryDropdown: {
            ...AddSimpleFilter.customQueryBar?.customQueryDropdown,
            customQueryEntityEditorPage: {
                ...AddSimpleFilter.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
                blocklyEditorTab: {
                    ...AddSimpleFilter.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage?.filterBar?.blocklyEditor,
                    rootFilter: { operator: FilterOperators.forComposedFilter.or.value, filters: [{ field: 'id', operator: FilterOperators.forNumber.equals.value, value: '1', enabled: true }, { field: 'name', value: 'CCC', operator: FilterOperators.forString.equals.value, enabled: true }, { operator: FilterOperators.forComposedFilter.or.value, filters: [], enabled: true }], enabled: true },
                }
            }
        }
    }
}

export const AddANDFilter: StatePartialFrom<SliceEntityTablePage> = {
    ...AddSimpleFilter,
    customQueryBar: {
        ...AddSimpleFilter.customQueryBar,
        customQueryDropdown: {
            ...AddSimpleFilter.customQueryBar?.customQueryDropdown,
            customQueryEntityEditorPage: {
                ...AddSimpleFilter.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
                blocklyEditorTab: {
                    ...AddSimpleFilter.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage?.filterBar?.blocklyEditor,
                    rootFilter: { operator: FilterOperators.forComposedFilter.or.value, filters: [{ field: 'id', operator: FilterOperators.forNumber.equals.value, value: '1', enabled: true }, { field: 'name', value: 'CCC', operator: FilterOperators.forString.equals.value, enabled: true }, { operator: FilterOperators.forComposedFilter.and.value, filters: [], enabled: true }], enabled: true },
                }
            }
        }
    }
}

export const AddNewCustomQuery: StatePartialFrom<SliceEntityTablePage> = {
    ...AddSimpleFilter,
    customQueryBar: {
        ...AddSimpleFilter.customQueryBar,
        filterBar: {
            ...AddSimpleFilter.customQueryBar?.filterBar,
            rootFilter: { operator: FilterOperators.forComposedFilter.or.value, filters: [{ field: 'id', operator: FilterOperators.forNumber.equals.value, value: '1', enabled: true }, { field: 'name', value: 'CCC', operator: FilterOperators.forString.equals.value, enabled: true }], enabled: true }
        },
        sortBar: {
            ...AddSimpleFilter.customQueryBar?.sortBar,
            sorts: [{ field: 'id', direction: 'ASC' }]
        },
        customQueryDropdown: {
            ...AddSimpleFilter.customQueryBar?.customQueryDropdown,
            dropdownOpened: true,
            customQuery: {
                ...AddSimpleFilter.customQueryBar?.customQuery!,
                customQueryDefinitionObject: {
                    sorts: [{ field: 'id', direction: 'ASC' }],
                    filter: { operator: FilterOperators.forComposedFilter.or.value, filters: [{ field: 'id', operator: FilterOperators.forNumber.equals.value, value: '1', enabled: true }, { field: 'name', value: 'CCC', operator: FilterOperators.forString.equals.value, enabled: true }], enabled: true }
                }
            },
            customQueryEntityEditorPage: {
                ...AddSimpleFilter.customQueryBar?.customQueryDropdown?.customQueryEntityEditorPage,
                entity: undefined,
                modalOpen: false,
                sortTab: {
                    ...sliceSortBar.initialState
                },
                blocklyEditorTab: {
                    ...sliceBlocklyEditorTab.initialState
                }
            }
        }
    }
}

export const SortEditorOpened: StatePartialFrom<SliceEntityTablePage> = {
    ...AddNewCustomQuery,
    customQueryBar: {
        ...AddNewCustomQuery.customQueryBar,
        sortBar: {
            ...AddNewCustomQuery.customQueryBar?.sortBar,
            editorOpened: [500, 140],
        }
    }
}

export const AddSort: StatePartialFrom<SliceEntityTablePage> = {
    ...SortEditorOpened,
    customQueryBar: {
        ...SortEditorOpened.customQueryBar,
        sortBar: {
            ...SortEditorOpened.customQueryBar?.sortBar,
            editorOpened: false,
            sorts: [{ field: 'id', direction: 'ASC' }, { field: 'name', direction: 'ASC' }]
        }
    }
}

export const MoreSortsAdded: StatePartialFrom<SliceEntityTablePage> = {
    ...AddSort,
    customQueryBar: {
        ...AddSort.customQueryBar,
        sortBar: {
            ...AddSort.customQueryBar?.sortBar,
            editorOpened: false,
            sorts: [{ field: 'id', direction: 'ASC' }, { field: 'name', direction: 'ASC' }, { field: 'firstName', direction: 'DESC' }]
        }
    }
}

export const SortEditorOpenedToEditSort: StatePartialFrom<SliceEntityTablePage> = {
    ...MoreSortsAdded,
    customQueryBar: {
        ...MoreSortsAdded.customQueryBar,
        sortBar: {
            ...MoreSortsAdded.customQueryBar?.sortBar,
            editorOpened: [500, 140],
            indexForEditor: 1,
        }
    }
}

export const SortMovedRight: StatePartialFrom<SliceEntityTablePage> = {
    ...SortEditorOpenedToEditSort,
    customQueryBar: {
        ...SortEditorOpenedToEditSort.customQueryBar,
        sortBar: {
            ...SortEditorOpenedToEditSort.customQueryBar?.sortBar,
            editorOpened: false,
            indexForEditor: -1,
            sorts: [{ field: 'id', direction: 'ASC' }, { field: 'firstName', direction: 'DESC' }, { field: 'name', direction: 'ASC' }]
        }
    }
}

export const SortDeleted: StatePartialFrom<SliceEntityTablePage> = {
    ...SortMovedRight,
    customQueryBar: {
        ...SortMovedRight.customQueryBar,
        sortBar: {
            ...SortMovedRight.customQueryBar?.sortBar,
            sorts: [{ field: 'id', direction: 'ASC' }, { field: 'firstName', direction: 'DESC' }]
        }
    }
}