import { FilterOperators } from "@crispico/foundation-gwt-js";
import { QueryOptions } from "apollo-client";
import { push } from "connected-react-router";
import gql from "graphql-tag";
import { default as lodash } from "lodash";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { BreadcrumbSectionProps, Button, Dimmer, Icon, Input, Label, Loader, Menu, MenuItemProps, Message, Modal, Segment, SemanticShorthandCollection } from "semantic-ui-react";
import { apolloClient, apolloClientHolder, ApolloContext, CatchedGraphQLError } from "../apolloClient";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";
import { ColumnConfigDropdown, COLUMN_CONFIG_DROPDOWN_MODE, getColumnConfigForClient, sliceColumnConfigDropdown } from "../components/ColumnConfig/ColumnConfigDropdown";
import { ColumnConfigDropdownSource } from "../components/ColumnConfig/dataStructures";
import { DEFAULT_COMPOSED_FILTER } from "../components/CustomQuery/BlocklyEditorTab";
import { ClientCustomQuery } from "../components/CustomQuery/ClientCustomQuery";
import { CustomQueryBar, CUSTOM_QUERY_BAR_MODE, sliceCustomQueryBar } from "../components/CustomQuery/CustomQueryBar";
import { CustomQueryDefinition } from "../components/CustomQuery/CustomQueryDropdown";
import { Filter } from "../components/CustomQuery/Filter";
import { FilterAsText } from "../components/CustomQuery/FilterBar";
import { Sort, SortAsText } from "../components/CustomQuery/SortBar";
import { FileExportButtonRRC, FileExportButton, FILE_EXPORTER_MODE } from "../components/fileExportButton/FileExportButton";
import { FileImportButtonRRC, FileImportButton, FILE_IMPORTER_MODE } from "../components/fileImportButton/FileImportButton"
import { ModalExt, Severity } from "../components/ModalExt/ModalExt";
import { TabbedPageProps, TabRouterPane } from "../components/TabbedPage/TabbedPage";
import { ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "../reduxHelpers";
import { TestUtils } from "../utils/TestUtils";
import { DEFAULT, DELETE_ALL_FILTERED_ROWS_MENU_ENTRY, ENT_DELETE, ENT_TABLE, GLOBAL, Utils } from "../utils/Utils";
import { AbstractCrudPage, SliceAbstractCrudPage, sliceAbstractCrudPageOnlyForExtension } from "./AbstractCrudPage";
import { CrudGlobalSettings } from "./CrudGlobalSettings";
import { EDITOR_PAGE_ICON, getOrganizationFilter, ID, TABLE_PAGE_ICON } from "./entityCrudConstants";
import { EntityDescriptor } from "./EntityDescriptor";
import { ADD, DUPLICATE } from "./EntityEditorPage";
import { ColumnDefinition, EntityTableCommonProps, EntityTableSimple, sliceEntityTableSimple } from "./EntityTableSimple";
import { FindByFilterParams } from "./FindByFilterParams";
import { ShareLinkLogic } from "./ShareLinkLogic";
import { Location } from "history";
import { Dashboard } from "../pages/dashboard/DashboardEntityDescriptor";
import { Optional } from "..";
import { Formik } from "formik";
import Interweave from "interweave";
import { AggregateFunctionInput } from "../apollo-gen-foundation/globalTypes";

export const INDEX = "_index";

const DEFAULT_REFRESH_RATE = 60;

const MIN_REFRESH_RATE = 10;

const MAX_REFRESH_RATE = 600;

export enum EntityTableMode {
    NORMAL, CARDS
}

export type EntityTableOptions = {
    /**
     * Number of entitites to load in bulk; needed for infinite scroll/progressive loading
     * default: 1000
     */
    bulkSize: number,
    /**
     * Tells when to start loading another bulk of entities.
     * If we want to load when the last row is visible in table, then value = 0.
     * If we want to load before reaching the last row, then value > x (x = number of rows)
     * default: 500
     */
    rowOffsetStartLoadingBulk: number,
    /**
     * If true, table counts and displays the number of total entities.
     * If false, tables displays only loaded number of entities.
     * default: true
     */
    countMode: boolean,
}

export class SliceEntityTablePage extends SliceAbstractCrudPage {

    private _options: EntityTableOptions = { bulkSize: 100, rowOffsetStartLoadingBulk: 50, countMode: true };

    loadParamType = "FindByFilterParamsInput";

    protected getGraphQlIdType() {
        return this.entityDescriptor && this.entityDescriptor.graphQlIdType ? this.entityDescriptor.graphQlIdType : CrudGlobalSettings.INSTANCE.defaultGraphQlIdType;
    }

    get options(): EntityTableOptions {
        return this._options;
    }

    setOptions(value: Partial<EntityTableOptions>): SliceEntityTablePage {
        this._options = Object.assign(this._options, value);
        return this;
    }

    nestedSlices = {
        tableSimple: sliceEntityTableSimple,
        customQueryBar: sliceCustomQueryBar,
        columnConfigDropdown: sliceColumnConfigDropdown
    }

    initialState = {
        ...sliceAbstractCrudPageOnlyForExtension.initialState,
        mostRecentStartIndex: -1, // needed for loading entities in bulks
        loaded: -1, // number of loaded entities
        totalCount: -1,
        initialLoaded: 0,
        initialTotalCount: 0,
        totalAdjustment: 0,
        /**
         * When new data arrives from the server, if it contains records that are already displayed: we'll discard them.
         * This may happen if the user has kept the screen open e.g. 2 minutes, he scrolls and meanwhile somebody else has
         * added records that will "shift" the current records below. This is more likely to happen when we display data
         * sorted by date/desc, and meanwhile new records have been added. But in theory may happen for all types of filters.
         */
        loadedIds: {} as any,
        confirmDeleteEntity: false as boolean,

        /**
         * Heads up! The primary info exists as the prop "oneToMany" of the component. We save it in the state, i.e. create a 
         * bit of duplication because
         * otherwise it would mean a lot of modifications (i.e. all methods should somehow receive the props of the comp).
         * This is not a very good practice. Is almost like a global var in our case.
         */
        oneToManyModeCachedField: undefined as string | undefined,
        oneToManyModeCachedId: undefined as any,
        oneToManyModeEntityDescriptor: undefined as EntityDescriptor | undefined,
        shareLinkResult: { link: undefined, error: false } as { link: string | undefined, error: boolean },
        goToEditIfSingleEntity: false,
        compactBarModal: false as boolean | [number, number],
        compactBarMenuModal: false as boolean | [number, number],
        confirmDeleteByFilter: undefined as number | undefined,
        confirmDeleteByFilterInput: "",
        refreshRate: DEFAULT_REFRESH_RATE,
        refreshIntervalId: undefined as number | undefined,
        refreshModal: false as boolean | [number, number]
    }

    reducers = {
        ...sliceAbstractCrudPageOnlyForExtension.reducers,
        ...getBaseReducers<SliceEntityTablePage>(this),

        applyColumnConfigFromCustomQuery(state: StateFrom<SliceEntityTablePage>, customQuery: ClientCustomQuery) {
            if (customQuery.preferredColumnConfig) {
                this.getSlice().nestedSlices.columnConfigDropdown.reducers.updateColumnConfig(state.columnConfigDropdown, getColumnConfigForClient(customQuery.preferredColumnConfig))
                state.columnConfigDropdown.dropdownOpened = false;
                state.columnConfigDropdown.search = '';
            }
        },

        closeCompactBarMenuModal(state: StateFrom<SliceEntityTablePage>) {
            state.compactBarMenuModal = false;
        }
    }

    impures = {
        ...sliceAbstractCrudPageOnlyForExtension.impures,
        ...getBaseImpures<SliceEntityTablePage>(this),

        _eliminateDisabledCustomQueries(customQueries: Array<ClientCustomQuery>): Array<ClientCustomQuery> {
            return customQueries.filter(cq => cq.enabled)
        },

        /**
         * Meant to be overridden if needed.
         */
        adjustFilterBeforeLoad(filter: Filter): Filter {
            return filter;
        },

        /**
        * Meant to be overridden if needed.
        */
        adjustSortsBeforeLoad(sorts: Sort[]): Sort[] {
            return sorts;
        },

        getFilterForLoad() {
            let customQueryDefinition = this.getState().customQueryBar.customQuery ? this.getState().customQueryBar.customQuery!.customQueryDefinitionObject : { sorts: [], filter: DEFAULT_COMPOSED_FILTER };
            let filter: Filter = customQueryDefinition.filter;

            filter = Filter.eliminateDisabledFilters(filter)!; // root filter shouldn't be disabled; so it always exists

            filter = filter ? filter : Filter.createComposed(FilterOperators.forComposedFilter.and, []);
            const oneToManyModeField = this.getState().oneToManyModeCachedField;
            const oneToManyModeCachedId = this.getState().oneToManyModeCachedId;
            if (oneToManyModeField) {
                if (filter.operator !== FilterOperators.forComposedFilter.and.value) {
                    filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [filter]);
                }
                this.addOneToManyModeFilters(filter.filters!, oneToManyModeField, this.getState().oneToManyModeEntityDescriptor!, oneToManyModeCachedId);
            }

            const orgFilter = getOrganizationFilter(this.getSlice().entityDescriptor, global.currentOrganizationToFilterBy);
            if (orgFilter) {
                if (filter.operator !== FilterOperators.forComposedFilter.and.value) {
                    filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [filter]);
                }
                filter.filters?.push(orgFilter);
            }

            return filter;
        },

        addOneToManyModeFilters(filters: Filter[], oneToManyModeField: string, oneToManyModeEntityDescriptor: EntityDescriptor, oneToManyModeCachedId: any) {
            filters!.push(Filter.create(oneToManyModeField, FilterOperators.forNumber.equals, oneToManyModeCachedId));
        },

        async refresh() {
            if (TestUtils.storybookMode) {
                return;
            }
            // reset to initialState
            this.getDispatchers().setInReduxState({ mostRecentStartIndex: -1, totalCount: -1, loaded: -1, initialLoaded: 0, initialTotalCount: 0, totalAdjustment: 0, loadedIds: {} });
            this.getDispatchers().tableSimple.setInReduxState({ entities: [], selected: undefined });

            await this.loadEntities({ startIndex: 0, pageSize: this.getSlice().options.bulkSize, countMode: this.getSlice().options.countMode });

            if (this.getState().goToEditIfSingleEntity && this.getState().tableSimple.entities.length === 1) {
                this.getDispatchers().dispatch(push(this.getDispatchers().getSlice().entityDescriptor.getEntityEditorUrl(this.getState().tableSimple.entities[0].id)));
            }

            this.getDispatchers().setInReduxState({ goToEditIfSingleEntity: false });
        },

        addIndexOnEntity(entities: any[]) {
            let count = this.getState().tableSimple.entities.length;
            for (const entity of entities) {
                entity[INDEX] = count++;
            }
        },

        adjustEntities(entities: any[]): any[] {
            this.addIndexOnEntity(entities);
            return entities;
        },

        async invokeLoadQuery(options: QueryOptions<FindByFilterParams>) {
            return await apolloClientHolder.apolloClient.query(options);
        },

        getFieldsToRequest(fieldsToRequest: string[]) {
            return CrudGlobalSettings.INSTANCE.fieldId + " " + this.getSlice().entityDescriptor.getGraphQlFieldsToRequest(fieldsToRequest);
        },

        getLoadQueryParams() {
            const ed = this.getSlice().entityDescriptor;
            const loadOperationName = `${lodash.lowerFirst(ed.name)}Service_findByFilter`;
            const columns = this.getState().columnConfigDropdown.columnConfig?.configObject.columns;
            let fieldsToRequest: string[] = [];
            if (!columns) {
                fieldsToRequest = ed.getDefaultColumnConfig().configObject.columns!.map(column => column.name);
            } else {
                // only request the fields that are selected; including composed fields
                columns.forEach(column => fieldsToRequest.push(column.name));
            }
            const fieldsToRequestStr = this.getFieldsToRequest(fieldsToRequest);
            return {
                loadOperationName,
                loadQuery: gql(`query q($params: ${this.getSlice().loadParamType}) { 
                    ${loadOperationName}(params: $params) {
                        results { ${fieldsToRequestStr} } totalCount
                    }
                }`)
            };
        },

        getCustomQueryDefinitionForLoad(): CustomQueryDefinition {
            let customQueryDefinition = this.getState().customQueryBar.customQuery ? this.getState().customQueryBar.customQuery!.customQueryDefinitionObject : { sorts: [], filter: DEFAULT_COMPOSED_FILTER };

            let sorts: Sort[] = customQueryDefinition.sorts;
            sorts = this.adjustSortsBeforeLoad(sorts);

            let filter = this.getFilterForLoad();
            filter = this.adjustFilterBeforeLoad(filter);

            return { filter, sorts };
        },

        getAggregateFunctions(): AggregateFunctionInput[] | null {
            return null;
        },

        async loadEntities(p: { startIndex: number, pageSize: number, countMode?: boolean }) {
            if (TestUtils.storybookMode) {
                return;
            }
            if (this.getState().mostRecentStartIndex >= p.startIndex) {
                return;
            }

            this.getDispatchers().setInReduxState({ mostRecentStartIndex: p.startIndex });

            const { filter, sorts } = this.getCustomQueryDefinitionForLoad();
            const aggregateFunctions = this.getAggregateFunctions();

            const loadQueryParams = this.getLoadQueryParams();

            const { data } = (await this.invokeLoadQuery({
                context: {
                    [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                        this.getDispatchers().setInReduxState({ loaded: 0, totalCount: 0 });
                        return true;
                    },
                    showSpinner: false
                },
                query: loadQueryParams.loadQuery,
                variables: FindByFilterParams.create().startIndex(p.startIndex).pageSize(p.pageSize).filter(filter).sorts(sorts)
                    .countMode(p.countMode).aggregateFunctions(aggregateFunctions)
            }));

            const result = data[loadQueryParams.loadOperationName];

            if (result.results.length !== 0) {
                this.getDispatchers().setInReduxState({ initialLoaded: this.getState().initialLoaded + result.results.length });

                const loadedIds: any = { ...this.getState().loadedIds };
                for (let i = 0; i < result.results.length; i++) {
                    const id = this.getSlice().getId(result.results[i]);
                    if (loadedIds[id]) {
                        /* 
                         * discard; @see doc of loadedIds
                         * delete does not affect the structure of the array as it happens at splice, the line is replaced with undefined
                         */
                        delete result.results[i];
                    } else {
                        loadedIds[id] = true;
                    }
                }
                //filter is needed to remove undefined records that may occur after deleting lines from the array in the above code.
                const newEntities = this.adjustEntities(result.results.filter((x: any) => x));

                this.getDispatchers().tableSimple.setInReduxState({ entities: this.getState().tableSimple.entities.concat(newEntities) });
                this.getDispatchers().setInReduxState({ loadedIds, loaded: this.getState().tableSimple.entities.length, totalAdjustment: this.getState().tableSimple.entities.length - this.getState().initialLoaded });
            } else {
                if (this.getState().loaded === -1) {
                    this.getDispatchers().setInReduxState({ loaded: 0 });
                }
            }

            if (!this.getSlice().options.countMode) {
                this.getDispatchers().setInReduxState({ totalCount: this.getState().tableSimple.entities.length });
            } else {
                if (p.countMode && this.getState().totalCount === -1) {
                    this.getDispatchers().setInReduxState({ initialTotalCount: result.totalCount })
                }
                this.getDispatchers().setInReduxState({ totalCount: this.getState().initialTotalCount + this.getState().totalAdjustment });
            }

            return result;
        },

        async deleteEntity(entityDescriptorName: string, id: number, location: any) {
            const { entityDescriptor } = this.getSlice();
            const permission = Utils.pipeJoin([ENT_DELETE, entityDescriptor.name]);
            if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission, true)) {
                return;
            }

            const removeOperationName = `${lodash.lowerFirst(entityDescriptorName)}Service_deleteById`;
            const removeMutation = gql(`mutation deleteEntity($id: ${this.getSlice().getGraphQlIdType()}){${removeOperationName}(id: $id)}`);

            await apolloClient.mutate({ mutation: removeMutation, variables: { id: id } })

            this.refresh();
        },

        async prepareDeleteEntitiesByFilter() {
            this.getDispatchers().closeCompactBarMenuModal();
            if (this.getSlice().options.countMode) {
                await this.loadEntities({ startIndex: 0, pageSize: -1, countMode: true });
            }
            this.getDispatchers().setInReduxState({ confirmDeleteByFilter: this.getState().totalCount >= 0 ? this.getState().totalCount : undefined });
        },

        async confirmDeleteEntitiesByFilter() {
            const input = this.getSlice().options.countMode ? _msg("entityCrud.table.deleteFilteredRows.type", String(this.getState().confirmDeleteByFilter)) : _msg("entityCrud.table.deleteFilteredRows.type.countMode");
            if (this.getState().confirmDeleteByFilterInput === input) {
                const name = `${lodash.lowerFirst(this.getSlice().entityDescriptor.name)}Service_deleteByFilter`;
                const mutation = gql(`mutation deleteByFilter($filter: FilterInput){${name}(filter: $filter)}`);
                await apolloClient.mutate({ mutation: mutation, variables: { filter: Filter.eliminateDisabledFilters(this.getState().customQueryBar.customQuery?.customQueryDefinitionObject.filter!) } });
                this.refresh();
            }
            this.getDispatchers().setInReduxState({ confirmDeleteByFilter: undefined, confirmDeleteByFilterInput: "" });
        },

        getNextEntities(entity: any) {
            if (this.getState().initialLoaded >= this.getSlice().options.rowOffsetStartLoadingBulk && entity[INDEX] >= this.getState().loaded - this.getSlice().options.rowOffsetStartLoadingBulk) {
                setTimeout(async (startIndex: number) => {
                    const bulkSize = this.getSlice().options.bulkSize;
                    if (startIndex >= bulkSize) {
                        this.loadEntities({ startIndex: startIndex, pageSize: this.getSlice().options.bulkSize });
                    }
                }, undefined, this.getState().initialLoaded);
            }
        },

        getEntityAt(index: number): any | undefined {
            if (index >= this.getState().loaded) {
                return undefined;
            }
            this.getNextEntities(this.getState().tableSimple.entities[index])

            return this.getState().tableSimple.entities[index];
        },

        async loadAttachedDashboards() {
            if (AppMetaTempGlobals.appMetaInstance.dashboardsAvailable && this.getSlice().entityDescriptor.hasAttachedDashboards) {
                const query = gql(`query q($id: Long, $forEditor: Boolean, $entityName: String) { 
                    dashboardService_attachedDashboards(id: $id, forEditor: $forEditor, entityName: $entityName) {
                        id name configJson icon forEditor
                    }
                }`);
                const attachedDashboards = (await apolloClient.query({ query: query, variables: { forEditor: false, entityName: this.getSlice().entityDescriptor.name }, context: { showSpinner: false } })).data["dashboardService_attachedDashboards"];
                this.getDispatchers().setInReduxState({ attachedDashboards: attachedDashboards || [] });
            }
        },

        applyTableConfig(filter: Filter, sorts?: Array<Sort>) {
            const entityName = this.getSlice().entityDescriptor.name;

            const cq = {} as ClientCustomQuery;
            cq.customQueryDefinitionObject = {} as CustomQueryDefinition;
            cq.customQueryDefinitionObject.filter = filter;
            cq.customQueryDefinitionObject.sorts = [];
            cq.id = -2;
            cq.screen = entityName;
            cq.name = _msg("entityCrud.table.shared.cq");
            cq.dirty = true;
            cq.fromCrudSettings = false;

            if (sorts) {
                this.getDispatchers().customQueryBar.sortBar.setInReduxState({ sorts: sorts });
                cq.customQueryDefinitionObject.sorts = sorts;
            }

            this.getDispatchers().customQueryBar.updateCustomQuery(cq);
        },

        startOrUpdateAutomaticRefresh(refreshRate: number) {
            this.getDispatchers().setInReduxState({ refreshRate: refreshRate })
            if (isNaN(refreshRate) || refreshRate < MIN_REFRESH_RATE || refreshRate > MAX_REFRESH_RATE) {
                return;
            }
            if (this.getState().refreshIntervalId) {
                clearInterval(this.getState().refreshIntervalId);
            } 
            const intervalId = window.setInterval(() => this.refresh(), refreshRate * 1000);
            this.getDispatchers().setInReduxState({ refreshIntervalId: intervalId });
        }
    }

}

/**
 * As it's name suggests, this INSTANCE is provided for convenience for extension. In normal operation,
 * a new INSTANCE is created per entity.
 */
export const sliceEntityTablePageOnlyForExtension = createSliceFoundation(class extends SliceEntityTablePage { get entityDescriptor(): EntityDescriptor { throw new Error("This instance is only an utility for extension; it cannot be used.") } }, true);

export type EntityTablePageProps = TabbedPageProps & EntityTableCommonProps & PropsFrom<SliceEntityTablePage> & {
    hideActionsBar?: boolean,
    hideActionsCell?: boolean,
    screen?: string,
    oneToManyMode?: OneToManyMode,
    pageHeaderClassName?: string,
    itemsHidedFromCell?: string[];
    automaticRefresh?: boolean;
    defaultRefreshRate?: number;
};

type OneToManyMode = { field: string, entity: any, entityDescriptor: EntityDescriptor, entityField?: string };

export class EntityTablePage<P extends EntityTablePageProps = EntityTablePageProps> extends AbstractCrudPage<P> {

    tableSimpleClass = EntityTableSimple;

    tablesimpleRef = React.createRef<EntityTableSimple>();

    shareLinkLogic = new ShareLinkLogic();


    static defaultProps = {
        showDtoCrudButtons: true,
        showImportButton: true,
        showExportButton: true
    }

    constructor(props: P) {
        super(props);
        this.renderContextMenuItems = this.renderContextMenuItems.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.onDoubleClickItem = this.onDoubleClickItem.bind(this);
    }

    componentDidMount() {
        const { entityDescriptor } = this.props.dispatchers.getSlice();

        // This was added in order to work the hideActionsBar case, 
        // otherwise because CustomQueryDropdown.componentDidMount isn't executed, the refresh/loadEntities will not be called
        // The CQ mechaism must be refactored!
        if (this.props.hideActionsBar) {
            !this.props.columnConfigDropdown.columnConfig && this.props.dispatchers.columnConfigDropdown.initializeCC(entityDescriptor, ColumnConfigDropdownSource.TABLE);
            !this.props.customQueryBar.customQuery && this.props.dispatchers.customQueryBar.customQueryDropdown.initializeFromSessionStorage(entityDescriptor.name, this.props.customQueryBar.customQuery, this.props.dispatchers.customQueryBar.updateCustomQuery);
        }

        this.props.dispatchers.loadAttachedDashboards();

        if (this.props.automaticRefresh) {
            const defaultRefreshRate = this.props.defaultRefreshRate ? this.props.defaultRefreshRate : DEFAULT_REFRESH_RATE;
            this.props.dispatchers.startOrUpdateAutomaticRefresh(defaultRefreshRate!);
        }
        // in the past this was done in "onMatchChanged()"; I don't see any reason to happen there (opposed to an editor page, 
        // where this is interesting, because the ID may change). And having it here also covers the cases for embedded mode
        this.componentDidUpdateInternal();
    }

    protected getTitle(): string | { icon: JSX.Element | string; title: JSX.Element | string; } {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return { icon: entityDescriptor.icon, title: entityDescriptor.getLabel() + " [" + _msg("entityCrud.editor.table") + "]" };
    }

    protected onDoubleClickItem(entity: any) {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        this.props.dispatchers.dispatch(push(entityDescriptor.getEntityEditorUrl(entity.id)));
    }

    protected getMainRoutePath(): string {
        // don't show table tab if embededMode and no extra tabs available      
        if (this.props.embeddedMode && this.getExtraTabPanes().length === 0) { return "" };
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return entityDescriptor.getEntityTableUrl();
    }

    protected getMainPaneSubPath() {
        return "table";
    }

    protected getMainMenuItemProps(): string | MenuItemProps {
        return { icon: TABLE_PAGE_ICON, content: _msg("entityCrud.editor.table") };
    }

    /**
     * This is needed when we want to call it from componentDidMount also.
     * Added for extension use.
     */
    protected componentDidUpdateInternal(prevProps?: P) {
        const { props } = this;

        super.componentDidUpdateInternal(prevProps);

        this.onLocationUpdate();

        /**
         * To make this shorter, we can consider that we hava an async race condition. 1) Either the entity arrives first and then later comes the CQ. 
         * Or 2) first comes the CQ and then arrives the entity. 1) happens I enter the editor/form and then click on the tab w/ this class in "one-to-many mode".
         * And 2) happens if I write directly the URL of that tab.
         * So the mechanism is simple: if A arrived => is B already arrived? If yes => GO. And vice versa. 
         */
        const oneToManyModeChanged = props.oneToManyMode && props.oneToManyMode.entity?.[ID] && props.oneToManyMode.entity?.[ID] !== prevProps?.oneToManyMode?.entity?.[ID];
        const customQueryChanged = !lodash.isEqual(props.customQueryBar.customQuery?.customQueryDefinitionObject, prevProps?.customQueryBar.customQuery?.customQueryDefinitionObject);

        if (customQueryChanged && (!props.oneToManyMode || props.oneToManyMode.entity?.[ID] !== undefined) // A just arrive; is "oneToManyMode" disabled or B present? 
            || oneToManyModeChanged && props.customQueryBar.customQuery?.customQueryDefinitionObject) { // B just arrive; is A present?

            // if just changed (from undefined to something, or from something to something else)
            // OR we didn't store yet the value in the cache (so that the code can know that we are in one-to-many mode) ...
            if (props.oneToManyMode
                && (oneToManyModeChanged || !props.oneToManyModeCachedField)) {

                // ... then store it and call "refresh()" w/ "setTimeout" to let it propagate; so that the code in "refresh()" see this
                props.dispatchers.setInReduxState({
                    oneToManyModeCachedField: props.oneToManyMode.field, oneToManyModeEntityDescriptor: props.oneToManyMode.entityDescriptor,
                    oneToManyModeCachedId: props.oneToManyMode.entity?.[props.oneToManyMode.entityField ? props.oneToManyMode.entityField : ID]
                });
                setTimeout(() => props.dispatchers.refresh());
                // prevent the normal "refresh()"
                return;
            } // else, even if oneToMany mode, we don't need the delay, because the "oneToManyModeCached" is already populated
            if (!(props.location && props.location.search.length > 0)) {
                props.dispatchers.refresh();
            }
        } else if (props.columnConfigDropdown.columnConfig?.configObject.columns && prevProps?.columnConfigDropdown.columnConfig?.configObject.columns // we had a CC and now we also have a CC
            && lodash.differenceBy(props.columnConfigDropdown.columnConfig.configObject.columns, prevProps.columnConfigDropdown.columnConfig.configObject.columns, "name").length) { // and are there cols in the new CC that are NOT in the old CC?
            props.dispatchers.refresh();
        } else if (prevProps && !prevProps.goToEditIfSingleEntity && this.props.goToEditIfSingleEntity) {
            props.dispatchers.refresh();
        }

        if (!lodash.isEqualWith(props.customQueryBar.customQuery, prevProps?.customQueryBar.customQuery)
            || !lodash.isEqualWith(props.columnConfigDropdown.columnConfig, prevProps?.columnConfigDropdown.columnConfig)) {
            props.dispatchers.setInReduxState({ compactBarModal: false });
        }

        if (prevProps && !lodash.isEqual(prevProps?.currentOrganizationToFilterBy, props.currentOrganizationToFilterBy)) {
            props.dispatchers.refresh();
        }
    }

    protected onLocationUpdate() {
        const props = this.props;
        if (props.location && props.location.search.length > 0) {
            const search = props.location?.search.replaceAll("%22", "\"").replaceAll("%20", " ");
            const shareLink = this.shareLinkLogic.deserializeFromLink(search.substring(1, search.length));
            if (shareLink.goToEditIfSingleEntity) {
                props.dispatchers.setInReduxState({ goToEditIfSingleEntity: true });
            }
            props.dispatchers.applyTableConfig(shareLink.filter, shareLink.sorts);
            AppMetaTempGlobals.history.replace(props.location.pathname);
        }
    }

    protected onMatchChanged(match: any) {
        this.onLocationUpdate();
    }

    protected shareLink = () => {
        const props = this.props;
        const link = this.shareLinkLogic.createLink(true, props.dispatchers.getSlice().entityDescriptor, props.customQueryBar.customQuery?.customQueryDefinitionObject.filter,
            props.customQueryBar.sortBar.sorts);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(link);
            this.props.dispatchers.setInReduxState({ shareLinkResult: { link: link, error: false } });
        } else {
            this.props.dispatchers.setInReduxState({ shareLinkResult: { link: link, error: true } });
        }
    }

    protected refresh = () => {
        this.props.dispatchers.refresh();
    }

    protected getBreadcrumbSections(): SemanticShorthandCollection<BreadcrumbSectionProps> {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return [
            { key: 'Home', content: <><Icon name="home" /><Link to="/">{_msg("HomePage.title")}</Link></> },
            { key: entityDescriptor.name, content: <>{entityDescriptor.getIcon()}{entityDescriptor.getLabel(true)}</> },
        ];
    }

    protected renderConfirmDeleteByFilterModal() {
        return <ModalExt closeIcon={true} open={this.props.confirmDeleteByFilter !== undefined} onClose={() => this.props.dispatchers.setInReduxState({ confirmDeleteByFilter: undefined })} >
            <Modal.Header><Icon name="warning sign" color="red" /> {_msg("entityCrud.table.danger")}</Modal.Header>
            <Modal.Content>
                <Interweave content={
                    this.props.dispatchers.getSlice().options.countMode
                        ? _msg("entityCrud.table.deleteFilteredRows.message", String(this.props.confirmDeleteByFilter), _msg("entityCrud.table.deleteFilteredRows.type", String(this.props.confirmDeleteByFilter)))
                        : _msg("entityCrud.table.deleteFilteredRows.message.countMode", String(this.props.confirmDeleteByFilter), _msg("entityCrud.table.deleteFilteredRows.type.countMode"))
                } />
                <p></p>
                <Input fluid autoFocus value={this.props.confirmDeleteByFilterInput}
                    onChange={(data) => this.props.dispatchers.setInReduxState({ confirmDeleteByFilterInput: data.target.value })} />
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={() => this.props.dispatchers.confirmDeleteEntitiesByFilter()}>{_msg("general.delete")}</Button>
                <Button negative onClick={() => this.props.dispatchers.setInReduxState({ confirmDeleteByFilter: undefined, confirmDeleteByFilterInput: "" })}>{_msg("general.cancel")}</Button>
            </Modal.Actions>
        </ModalExt>
    }

    protected getOneToManyModeAddParams() {
        const miniFields = this.props.oneToManyMode!.entityDescriptor.miniFields;
        const miniEntity = lodash.pick(this.props.oneToManyMode?.entity, (miniFields.includes("id") ? [] : ["id"]).concat(miniFields));
        return "?" + this.props.oneToManyMode?.field + "=" + JSON.stringify(miniEntity);
    }

    protected fileExporterRef = React.createRef<FileExportButton>();
    protected fileImporterRef = React.createRef<FileImportButton>();

    protected renderCompactBarMenuModal() {
        const entityDescriptor = this.props.dispatchers.getSlice().entityDescriptor;
        let columns: Array<ColumnDefinition> = [];
        if (this.props.columnConfigDropdown.columnConfig) {
            columns = this.props.columnConfigDropdown.columnConfig.configObject.columns!;
        }
        const hasDelete = AppMetaTempGlobals.appMetaInstance.hasPermission(DELETE_ALL_FILTERED_ROWS_MENU_ENTRY);
        const addItem = this.props.oneToManyMode ? <Menu.Item key="add" onClick={() => {
            this.props.dispatchers.closeCompactBarMenuModal();
            this.props.oneToManyMode && this.props.dispatchers.dispatch(push({ pathname: entityDescriptor.getEntityEditorUrl(ADD), search: this.getOneToManyModeAddParams() }));
        }} icon="plus" content={_msg("entityCrud.table.add")} /> : <Menu.Item as={NavLink} key="add" to={entityDescriptor.getEntityEditorUrl(ADD)}
            onClick={this.props.dispatchers.closeCompactBarMenuModal} icon="plus" content={_msg("entityCrud.table.add")}
        />;
        const deleteAllFilteredRowsItem = hasDelete ?
            <Menu.Item key="deleteAllFilteredRows" className="EntityTablePage_menu_deleteByFilter" icon="remove" onClick={() => this.props.dispatchers.prepareDeleteEntitiesByFilter()} content={_msg("entityCrud.table.deleteFilteredRows")} />
            : null
        const shareLinkItem = AppMetaTempGlobals.appMetaInstance.showCrudButtons.showShareLinkButton ?
            <Menu.Item key="shareLink" onClick={() => { this.shareLink(); this.props.dispatchers.closeCompactBarMenuModal() }} icon="share alternate" content={_msg("entityCrud.table.shareLink")} />
            : null
        const fileImportItem = AppMetaTempGlobals.appMetaInstance.showCrudButtons.showImportButton ?
            <FileImportButtonRRC id="fli" ref={this.fileImporterRef} key="fileImport" mode={FILE_IMPORTER_MODE.MENU_ITEM} entityName={entityDescriptor.name} refresh={() => this.props.dispatchers.refresh()} />
            : null
        const fileExportItem = AppMetaTempGlobals.appMetaInstance.showCrudButtons.showExportButton ?
            <FileExportButtonRRC id="fle" ref={this.fileExporterRef} key="fileExport" mode={FILE_EXPORTER_MODE.MENU_ITEM} entityName={entityDescriptor.name} customQueryDefinition={this.props.dispatchers.getCustomQueryDefinitionForLoad()} columns={columns} />
            : null
        const menuItems = [addItem, deleteAllFilteredRowsItem, shareLinkItem, fileImportItem, fileExportItem];
        return <Menu vertical className="wh100">
            {menuItems.filter((item: any) => item && !this.props.itemsHidedFromCell?.includes(item.key))}
        </Menu>;
    }
    
    protected renderCompactBar() {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return <Segment className="less-margin-top-bottom EntityTablePage_bar" >
            <Button primary icon="bars" data-testid="tableMenu" onClick={(e) => {
                this.props.dispatchers.setInReduxState({ compactBarMenuModal: [e.clientX, e.clientY] })
            }} />
            {this.renderRefreshButton()}
            <ColumnConfigDropdown key="columnConfig" entityDescriptor={entityDescriptor} {...this.props.columnConfigDropdown}
                dispatchers={this.props.dispatchers.columnConfigDropdown} source={ColumnConfigDropdownSource.TABLE} showSearchAndOptions={true}
                mode={COLUMN_CONFIG_DROPDOWN_MODE.TABLE_COMPACT} />
            <span className="tiny-margin-right" />
            <CustomQueryBar key="customQueryBar" applyColumnConfigFromCustomQuery={this.props.dispatchers.applyColumnConfigFromCustomQuery}
                entityDescriptor={entityDescriptor.name} screen={this.props.screen ? this.props.screen! : entityDescriptor.name} {...this.props.customQueryBar}
                dispatchers={this.props.dispatchers.customQueryBar} mode={CUSTOM_QUERY_BAR_MODE.TABLE} />
            <span className="tiny-margin-right" />
            {this.preRenderButtons({}).map((button: any, i) => {
                let key = button?.element?.key;
                if (!key) {
                    key = button?.props?.key;
                }
                if (key) {
                    if (button.element) {
                        return button.element;
                    }
                    if (!button.props.key) {
                        button.props.key = i;
                    }
                    return React.createElement(button.elementType, button.props);
                } else {
                    return null;
                }
            })}
            <ModalExt className='EntityTableSimple_menuModal' closeIcon={false} open={this.props.compactBarMenuModal} onClose={() => this.props.dispatchers.closeCompactBarMenuModal()}>
                {this.renderCompactBarMenuModal()}
            </ModalExt>
            {this.renderConfirmDeleteByFilterModal()}
        </Segment>;
    }

    resetRefreshRate() {
        const defaultRefreshRate = this.props.defaultRefreshRate ? this.props.defaultRefreshRate : DEFAULT_REFRESH_RATE;
        this.props.dispatchers.startOrUpdateAutomaticRefresh(defaultRefreshRate!);
    }

    onRefreshInputChange(e: any) {
        const newValue = parseInt(e.target.value);
        this.props.dispatchers.startOrUpdateAutomaticRefresh(newValue);
    }

    openAutomaticRefreshMenu(e: any) {
        const rect = document.getElementById("refreshDropdownRef")!.getBoundingClientRect();
        this.props.dispatchers.setInReduxState({ refreshModal: [rect.left, rect.bottom] });
    }

    closeAutomaticRefreshMenu() {
        this.props.dispatchers.setInReduxState({ refreshModal: false })
    }

    onRefreshInputBlur() {
        if (this.props.refreshRate < MIN_REFRESH_RATE || isNaN(this.props.refreshRate)) {
            this.props.dispatchers.startOrUpdateAutomaticRefresh(MIN_REFRESH_RATE);
        } else if (this.props.refreshRate > MAX_REFRESH_RATE) {
            this.props.dispatchers.startOrUpdateAutomaticRefresh(MAX_REFRESH_RATE);
        }
    }

    protected renderRefreshButton() {
        return (
            <div className="tiny-margin-right EntityTablePage_refreshContainer">
                <Button key="refresh" className={this.props.automaticRefresh ? "EntityTablePage_refreshButton" : "tiny-margin-right"} color="green" onClick={this.refresh} icon="refresh" />
                {this.props.automaticRefresh && <>
                    <Button key="dropdown" id="refreshDropdownRef" className="EntityTablePage_refreshDropdownButton" color="green" icon="dropdown" onClick={e => this.openAutomaticRefreshMenu(e)} />
                    <ModalExt className="EntityTablePage_refreshModal" open={this.props.refreshModal} transparentDimmer onClose={() => this.closeAutomaticRefreshMenu()}>
                        <div className="flex-center gap3 EntityTablePage_refreshDropdown">
                            <Label as='p' content={_msg("entityCrud.table.refreshDropdown.label")} />
                            <Input input={<input className="EntityTablePage_refreshDropdownInput" />} type='number' min={MIN_REFRESH_RATE}
                                max={MAX_REFRESH_RATE} value={this.props.refreshRate} onChange={(e) => this.onRefreshInputChange(e)}
                                onBlur={() => this.onRefreshInputBlur()} />
                            <Button size="mini" icon='undo' className="EntityTablePage_refreshDropdownResetButton" onClick={() => this.resetRefreshRate()} />
                        </div>
                    </ModalExt></>}
            </div>
        );
    }

    protected renderShareLinkModal() {
        return <ModalExt severity={this.props.shareLinkResult.error ? Severity.WARNING : Severity.INFO}
            open={this.props.shareLinkResult.link !== undefined}
            onClose={() => this.props.dispatchers.setInReduxState({ shareLinkResult: { link: undefined, error: false } })}>
            <Modal.Content>
                <Modal.Description>
                    {this.props.shareLinkResult.error ? <Message warning>{_msg("entityCrud.table.copiedToClipboard.error")}</Message>
                        : <p>{_msg("entityCrud.table.copiedToClipboard.main")}</p>}
                    <Message data-testid="shareLinkResult">{this.props.shareLinkResult.link}</Message>
                    {this.props.shareLinkResult.link && this.props.shareLinkResult.link.length > 2000 ? <p>{_msg("entityCrud.table.copiedToClipboard.limit")}</p> : null}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => this.props.dispatchers.setInReduxState({ shareLinkResult: { link: undefined, error: false } })} primary>{_msg("general.ok")}</Button>
            </Modal.Actions>
        </ModalExt>
    }

    protected renderContextMenuItems(entity: any): React.ReactNode {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return (<>
            <Menu.Item data-cy="edit" icon={EDITOR_PAGE_ICON} content={_msg("entityCrud.table.edit")} as={NavLink} to={entityDescriptor.getEntityEditorUrl(entity.id)}
                onClick={() => this.props.dispatchers.tableSimple.closeContextMenu()} />
            {entityDescriptor.hasDuplicateButton ? <Menu.Item data-cy="duplicate" icon="clone" content={_msg('dto_crud.duplicate')} onClick={() => {
                this.props.dispatchers.dispatch(push({
                    pathname: entityDescriptor.getEntityEditorUrl(ADD) + DUPLICATE + entity.id
                }));
            }} /> : null}
            <Menu.Item icon="remove" content={_msg("entityCrud.table.delete")}
                onClick={() => {
                    this.props.dispatchers.tableSimple.closeContextMenu();
                    this.props.dispatchers.setInReduxState({ confirmDeleteEntity: true });
                }} />
        </>);
    }

    /**
     * It's not obvious to me why this works; I mean which prop that triggers a rerender
     * makes this to be invoked as well. Initially I wanted to inject in the TableSimple this
     * prop, to make sure this gets called.
     */
    protected renderFooter() {
        return <span>{_msg(this.props.dispatchers.getSlice().options.countMode ? "entityCrud.table.totalCount" : "entityCrud.table.loadedCount")} <b data-testid="recordsCount">{this.props.totalCount === -1 ? _msg("general.loading") : this.props.totalCount}</b>.&nbsp;</span>;
    }

    protected renderPane(entityDescriptor: EntityDescriptor, columns: Array<ColumnDefinition>) {
        const displayAsCards = this.props.customQueryBar.customQuery?.displayAsCards;
        return <>
            <this.tableSimpleClass {...this.props.tableSimple} dispatchers={this.props.dispatchers.tableSimple} ref={this.tablesimpleRef} columns={columns}
                entityDescriptor={entityDescriptor} parentProps={this.props} renderFooter={this.renderFooter} mode={displayAsCards ? EntityTableMode.CARDS : EntityTableMode.NORMAL}
                onSelectItem={this.props.onSelectItem}
                onDoubleClickItem={this.onDoubleClickItem}
                renderContextMenuItems={!this.props.hideActionsCell ? this.renderContextMenuItems : undefined}
                screen={this.props.screen ? this.props.screen! : entityDescriptor.name}
                onColumnMoved={(event: any) => this.props.dispatchers.columnConfigDropdown.updateColumnOrder(event)}
                onColumnResized={(width: number, name: string) => this.props.dispatchers.columnConfigDropdown.updateColumnSize({ width: width, name: name })}
                compactBar={this.props.hideActionsBar ? null : this.renderCompactBar()}
            />
            {this.renderShareLinkModal()}
        </>;
    }

    protected renderAttachedDashboard(info: ConnectedPageInfo, dashboard: Dashboard) {
        const entityDescriptor = this.props.dispatchers.getSlice().entityDescriptor;
        return <>
            <Segment className="less-margin-top-bottom EntityTablePage_bar" style={{ margin: "0 5px", width: "calc(100% - 10px)" }}>
                <CustomQueryBar key="customQueryBar" className="tiny-margin-right" applyColumnConfigFromCustomQuery={this.props.dispatchers.applyColumnConfigFromCustomQuery}
                    entityDescriptor={entityDescriptor.name} screen={this.props.screen ? this.props.screen! : entityDescriptor.name} {...this.props.customQueryBar}
                    dispatchers={this.props.dispatchers.customQueryBar} mode={CUSTOM_QUERY_BAR_MODE.TABLE} />
            </Segment>
            {super.renderAttachedDashboard(info, dashboard, { dataExplorerFilter: this.props.customQueryBar.customQuery?.customQueryDefinitionObject.filter, dataExplorerEntityDescriptor: entityDescriptor })}
        </>;
    }

    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        if (super.getExtraTabPanes().length > 0) {
            return [...super.getExtraTabPanes()!, null];
        }
        return [];
    }

    protected renderConfirmDeleteEntity() {
        const props = this.props;
        return (
            <ModalExt
                severity={Severity.INFO}
                open={props.confirmDeleteEntity}
                header={_msg("dto_crud.deleteConfirmation.header", props.tableSimple.selected, props.dispatchers.getSlice().entityDescriptor.getLabel())}
                content={_msg("dto_crud.deleteConfirmation")}
                onClose={() => props.dispatchers.setInReduxState({ confirmDeleteEntity: false })}
                actions={[
                    <Button key="close" onClick={() => props.dispatchers.setInReduxState({ confirmDeleteEntity: false })}>{_msg("general.cancel")}</Button>,
                    <Button key="ok" primary onClick={() => {
                        props.dispatchers.deleteEntity(props.dispatchers.getSlice().entityDescriptor.name, props.tableSimple.selected, props.location);
                        props.dispatchers.setInReduxState({ confirmDeleteEntity: false });
                    }}>{_msg("general.ok")}</Button>
                ]}
            />
        );
    }

    renderTableDimmer() {
        return <Dimmer inverted active={this.props.loaded === -1} page={true}><Loader size='large'>{_msg("general.loading")}</Loader></Dimmer>
    }

    renderTableSimple() {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        let columns: Array<ColumnDefinition> = [];
        if (this.props.columnConfigDropdown.columnConfig) {
            columns = this.props.columnConfigDropdown.columnConfig.configObject.columns!;
        }
        return <>
            {this.renderTableDimmer()}
            {this.renderPane(entityDescriptor, columns)}
            {this.renderConfirmDeleteEntity()}</>;
    }

    renderMain() {
        const tableSimple = this.renderTableSimple();
        return <div className="EntityTablePage_embbeded">{tableSimple}</div>;
    }

    render() {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        const permission = Utils.pipeJoin([ENT_TABLE, entityDescriptor.name]);
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission)) {
            return AppMetaTempGlobals.appMetaInstance.getRedirectToError(permission, this.props.location);
        }
        return super.render();
    }
}

