import { apolloClientHolder } from "@crispico/foundation-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import gql from "graphql-tag";
import { delay } from "lodash";
import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { GalleryMedia } from "./GalleryMedia";

const error = _msg("general.error");
const awaitMilisForEmptyResponse = 15000;
export class LiveVideoProps {
    entityId!: number;
}

export class LiveVideoState {
    showModal!: boolean;
    liveIsLoading!: boolean;
    currentImages = {} as { [key: string]: string; };
}

export class LiveVideo extends React.Component<LiveVideoProps, LiveVideoState> {

    constructor(props: LiveVideoProps) {
        super(props);
        this.state = { showModal: false, liveIsLoading: false, currentImages: {} };
    }

    async getLiveImage(entityId: any) {
        const query = gql(`query getLiveImage($entityId: Long!){
            smartwitnessService_liveImages(
                entityId: $entityId
            ) 
			} `);

        while (this.state.showModal) {
            var result = await (await apolloClientHolder.apolloClient.query({ query, variables: { entityId: entityId }, context: { showSpinner: false } })).data.smartwitnessService_liveImages;
            this.setState({ currentImages: result, liveIsLoading: false });
            if (Object.keys(result).length == 0 || Object.entries(result).find(([key, value]) => key == error)) {
                await new Promise(f => setTimeout(f, awaitMilisForEmptyResponse));
            }
        }
    }

    protected renderGalleryImages() {
        if (Object.entries(this.state.currentImages).find(([key, value]) => key == error)) {
            return <>
                <Modal.Description><h1>{error}</h1></Modal.Description>
                <h2>{Object.entries(this.state.currentImages!).find(([key, value]) => key == error)?.[1]}</h2>
            </>
        }

        return <GalleryMedia data={this.state.currentImages} loading={this.state.liveIsLoading} />
    }

    render() {
        return <>
            <Button color="red" onClick={() => this.setState({ liveIsLoading: true, showModal: true }, () => { this.getLiveImage(this.props.entityId) })}>
                <Icon name="play" style={{color: 'white'}}/>
                {_msg("Smartwitness.liveVideo")}
            </Button>
            <ModalExt open={this.state.showModal} onClose={() => this.setState({ showModal: false, currentImages: {} })}
                style={{ width: '95%', height: '95%' }} >
                <Modal.Content className="wh100">
                    {this.renderGalleryImages()}
                </Modal.Content>
            </ModalExt>
            </>
    }


}