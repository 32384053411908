import React, { ReactNode } from "react";
import { TestsAreDemoFunctions_ImportProtection } from "./TestsAreDemoFunctions_ImportProtection";
import { TestsAreDemoMaster } from "./TestsAreDemoMaster";
import { TestsAreDemoSlave } from "./TestsAreDemoSlave";

/**
 * Is the root of the application. Based on the query string, it renders the main app, the master or the slave.
 */
export class TestsAreDemoAppWrapper extends React.Component<{ app: ReactNode, importTestsCallback: () => Promise<any> }> {

    protected static INSTANCE: TestsAreDemoAppWrapper;

    /**
     * May be overridden to provide a customized TestsAreDemoFunctions.
     * It's async, to allow an async import of the customized class.
     * It's not typed, to avoid a "normal" import just for the type + an async import, which
     * may make things look confuse.
     */
    protected async createTestsAreDemoInstance(): Promise<any> {
        return null;
    }

    async componentDidMount() {
        if (TestsAreDemoAppWrapper.INSTANCE) {
            throw new Error("Multiple instances of TestsAreDemoAppWrapper are not allowed.");
        }
        TestsAreDemoAppWrapper.INSTANCE = this;

        try {
            TestsAreDemoFunctions_ImportProtection.active = false;
            const { TestsAreDemoFunctions, setTad } = await import("./TestsAreDemoFunctions");
            const tad = await this.createTestsAreDemoInstance() || new TestsAreDemoFunctions();
            setTad(tad);
        } finally {
            TestsAreDemoFunctions_ImportProtection.active = false;
        }
    }

    render() {
        const props = this.props;
        const search = new URLSearchParams(window.location.search);
        if (search.has("testsAreDemo")) {
            return <TestsAreDemoMaster />
        } else if (search.has("testsAreDemoSlave")) {
            return <TestsAreDemoSlave importTestsCallback={props.importTestsCallback} />
        } else {
            return props.app as any; // if using a functional comp (instead of a class comp), it doesn't accept ReactNode as return type; I think there's some kind of error
        }
    }
}
