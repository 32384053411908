import { FilterOperators, Operator } from "@crispico/foundation-gwt-js";
import { EntityDescriptor } from "../../entity_crud/EntityDescriptor";
import { Utils } from "../../utils/Utils"
import { ReactNode } from 'react';
import React from 'react';
import { AUDITABLE_ENTITY_TYPE, AUDITABLE_FIELD_TYPE, AuditUtils } from '@crispico/foundation-react/pages/Audit/AuditUtils';
import { EntityDescriptorForServerUtils } from '@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils';
import moment from "moment";

export interface Filter {

    field?: string;
    operator: string;
    value?: string;
    filters?: Array<Filter>;

    /**
     * Only in JS!
     */
    enabled?: boolean;

    /**
     * Only in JS!
     */
    label?: string
}

export class Filter {
    static AND_DELIMITER = " AND ";
    static BETWEEN_DELIMITER = ',';
    static IN_DELIMITER = ',';

    static create(field: string, operator: Operator, value?: string, enabledOnlyForJs?: boolean) {
        return { field, operator: operator.value, value, enabled: enabledOnlyForJs } as Filter;
    }

    static createComposed(operator: Operator, filters: Filter[], enabledOnlyForJs?: boolean) {
        return { operator: operator.value, filters, enabled: enabledOnlyForJs } as Filter
    }

    static renderAsText(filter: Filter, ed: EntityDescriptor | undefined, foundAuditEntityMappingId?: string, paranthesis?: boolean) {
        let result: ReactNode[] = [];
        if (filter.enabled) {
            if (filter.label && filter.label.length > 0) {
                result.push(<b>{filter.label}</b>);
            } else if (filter.operator === FilterOperators.forComposedFilter.and.value || filter.operator === FilterOperators.forComposedFilter.or.value) {
                if (paranthesis) {
                    result.push("(");
                }

                const r: ReactNode[] = [];

                filter.filters?.forEach((f, index) => {
                    const result = this.renderAsText(f, ed, foundAuditEntityMappingId, filter.filters!.length > 1);
                    if (result.length > 0) {
                        r.push(result);
                    }
                });

                r.forEach((f, index) => {
                    result.push(f);
                    if (index + 1 < r.length) {
                        result.push(<i key={index}> {(filter.operator as string).toUpperCase()} </i>);
                    }
                });

                if (paranthesis) {
                    result.push(")");
                }
            } else if (filter.field && filter.field.length > 0) {
                const fd = ed?.getField(filter.field);
                let value: any = filter.value;
                if (value === true) {
                    value = "TRUE";
                } else if (value === false) {
                    value = "FALSE";
                } else if (value === 0) {
                    value = 0;
                } else if (fd && fd.type === AUDITABLE_ENTITY_TYPE) {
                    const ed = EntityDescriptorForServerUtils.getEntityDescriptor(value);
                    if (ed) {
                        value = ed.getLabel();
                    }
                } else if (fd && fd.type === AUDITABLE_FIELD_TYPE && foundAuditEntityMappingId) {
                    value = AuditUtils.getFieldLabelsFromIds(foundAuditEntityMappingId, value)
                } else if (fd && fd.type === 'date' && !FilterOperators.dateOperatorsWithNumberValue.find(operator => operator.value === filter.operator)
                && !FilterOperators.noValueOperators.find(operator => operator.value === filter.operator)) {
                    if (FilterOperators.twoValuesOperators.find(operator => operator.value === filter.operator)) {
                        const values = value.split(Filter.AND_DELIMITER);
                        const date1 = moment(values[0], moment.ISO_8601).format(Utils.dateTimeFormat);
                        const date2 = moment(values[1], moment.ISO_8601).format(Utils.dateTimeFormat);
                        value = date1 + Filter.AND_DELIMITER + date2;
                    } else if (filter.operator === FilterOperators.forDate.dayOf.value) {
                        value = moment(value, moment.ISO_8601).format(Utils.dateFormat);
                    } else {
                        value = moment(value, moment.ISO_8601).format(Utils.dateTimeFormat);
                    }
                } else {
                    if (value && value.length && value.split("|/|").length === 3) {
                        value = value.split("|/|")[1];
                    }
                }
                const fieldDescriptorChain = filter.field && ed?.getFieldDescriptorChain(filter.field);
                const composedFieldLabel = fieldDescriptorChain && ed?.getComposedFieldLabel(fieldDescriptorChain);
                result.push(<b key="field">{composedFieldLabel ? composedFieldLabel : filter.field} </b>);
                result.push(filter.operator);
                if (!FilterOperators.noValueOperators.find(operator => operator.value === filter.operator)) {
                    result.push(<b key="value"> {value}</b>);
                } else {
                    result.push();
                }
            }
        }
        return result;
    }

    static eliminateDisabledFilters(filter: Filter) {
        if (!filter?.enabled) { return undefined; }
        const copy: Filter = { field: filter.field, operator: filter.operator, value: filter.value };
        if (!filter.filters) { return copy; } // has no subfilters; so exit now
        copy.filters = [];
        for (let childFilter of filter.filters) {
            const childCopy = this.eliminateDisabledFilters(childFilter);
            if (childCopy) {
                copy.filters!.push(childCopy);
            } // else undefined => the filter was disabled; so we have nothing to add
        }
        if (copy.filters.length === 0) { return undefined; }
        return copy;
    }
}
