import React, { useState, useEffect, ReactNode } from "react";
import { Route, Link } from "react-router-dom";
import { Header, Label, Message, Segment, SegmentGroup, Icon } from "semantic-ui-react";
import { Optional } from "../CompMeta";
import { SplitPaneExt } from "../components/ReactSplitPaneExt/ReactSplitPaneExt";
import { TabbedPage } from "../components/TabbedPage/TabbedPage";
import { ZeroTrainingArticle, ZeroTrainingContext } from "./dataStructures";
import { JoyrideDecorator } from "./JoyrideDecorator";
import { Utils } from "../utils/Utils";
import ReactDOM from "react-dom";
import { TestUtils } from "../utils/TestUtils";
import { zeroTrainingIndexPageUrl } from "./ZeroTrainingIndexPage";

export class ZeroTrainingArticleComponent extends React.Component<{ article: ZeroTrainingArticle }> {

    state = {
        selected: 0,
        stepIndex: 0
    }

    protected setStepIndexOnClickFunction!: (stepIndex: number) => void;

    render() {
        const props = this.props;
        const { selected, stepIndex } = this.state;
        const selectedStory = props.article.stories[selected];
        const steps = selectedStory.story.parameters.joyrideDecorator.steps;
        let renderedStory: ReactNode;
        if (selectedStory.story.parameters.renderListenerDecorator) {
            renderedStory = <TestUtils.RenderListenerDecoratorWrapper render={selectedStory} callback={selectedStory.story.parameters.renderListenerDecorator as any} trigger={selectedStory} />
        } else {
            renderedStory = selectedStory();
        }

        return (
            <SplitPaneExt size="20%" pane1Style={{ display: "block", paddingTop: "5px" }} // I don't know why a few pixels of the header are cut; hence this dirty hack
            >
                <div className="myClass">
                    <HighlightForLetPanel />
                    <Header as="h2" block>
                        <Icon name="leanpub" />
                        <Header.Content>
                            {props.article.title}
                            <Header.Subheader>
                                {_msg("ZeroTraining.article")}
                                <Link style={{ float: "right" }} to={zeroTrainingIndexPageUrl}>{_msg("ZeroTraining.backToIndex")}</Link>
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                    <SegmentGroup>
                        {props.article.stories.map((story, i) => <Segment key={i} secondary={i === selected} className="ZeroTraining_segment"
                            onClick={() => {
                                if (i === selected) { return; }
                                this.setState({ selected: i, stepIndex: 0 });
                            }}>
                            <div>
                                <Label circular size="big" color="blue">{i + 1}</Label>
                            </div>
                            <div>
                                <b>{story.story.name}</b>
                                {i === selected && <><br /> {steps.map((e, j) => (
                                    <Label key={j} as="a" circular color={j === stepIndex ? "green" : undefined} onClick={() => this.setStepIndexOnClickFunction(j)}>{j + 1}</Label>)
                                )}</>}
                            </div>
                        </Segment>)}
                    </SegmentGroup>
                </div>
                <JoyrideDecorator steps={steps} stepIndex={stepIndex} obtainSetStepIndexOnClickFunction={func => this.setStepIndexOnClickFunction = func}
                    updateStepIndex={index => this.setState({ stepIndex: index })} onFinish={() => 0}>
                    {renderedStory}
                </JoyrideDecorator>
            </SplitPaneExt>
        );
    }
}

function HighlightForLetPanel() {
    const [overlay, setOverlay] = useState<Element>();
    useEffect(() => {
        setTimeout(() => {
            const overlayLocal = document.getElementsByClassName("react-joyride__overlay")[0];
            setOverlay(overlayLocal);
        });
    });

    let comp: any = document.getElementsByClassName("myClass")[0]
    let rect;
    if (comp) {
        comp = comp.parentElement;
        const rectLocal = comp.getBoundingClientRect();
        rectLocal.width += 10; // didn't have time to understand why a few pixels
        rect = { left: rectLocal.left + "px", width: rectLocal.width + "px", top: rectLocal.top + "px", height: rectLocal.height + "px", };
    }

    return (<>
        {overlay && ReactDOM.createPortal(<div style={{ position: "absolute", backgroundColor: "gray", ...rect }} />, overlay)}
    </>);
}

class ZeroTrainingArticlePage extends TabbedPage<any> {

    protected getTitle() {
        return { icon: "leanpub", title: _msg("ZeroTraining.article") };
    }

    static contextType = ZeroTrainingContext;
    context!: React.ContextType<typeof ZeroTrainingContext>;

    state = {
        article: undefined as Optional<ZeroTrainingArticle>
    }

    protected onMatchChanged = (match: any) => {
        const article = this.context.articles[match.id];
        this.setState({ article });
    }

    renderMain() {
        return (<>
            <Utils.MatchObserver onMatchChanged={this.onMatchChanged} />
            {!this.state.article ?
                <Message negative icon="meh" content={_msg("ZeroTraining.articleNotFound")} /> :
                <ZeroTrainingArticleComponent article={this.state.article} />
            }
        </>);
    }
}

export const getZeroTrainingArticleUrl = (id: string) => "/ZeroTrainingArticle/" + id;
export const ZeroTrainingArticlePageRoute = () => <Route key="ztapr" exact path={getZeroTrainingArticleUrl(":id")} component={ZeroTrainingArticlePage} />
