import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from 'apollo-link';
// @ts-ignore
import { ErrorResponse, onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { getMainDefinition } from "apollo-utilities";
import { GraphQLError, OperationDefinitionNode } from "graphql";
import fetch from 'node-fetch';
// @ts-ignore
import omitDeep from "omit-deep-lodash";
import { AppMetaTempGlobals } from "./AppMetaTempGlobals";
import { Severity } from "./components/ModalExt/ModalExt";
import { Utils } from "./utils/Utils";
import { Operation } from "apollo-link/lib/types"; 

/**
 * We use this technique to set properties in context to obtain a little bit of typing. Context is "any", and doesn't allow us
 * to set a type. Having ALL setable props in one place (i.e. this one), helps.
 */
export enum ApolloContext {
    DONT_SHOW_GLOBAL_ERROR_MESSAGE, ON_ERROR_EXPECTED_EXCEPTION, ON_ERROR_HANDLER
};

// enum also defined in Java
export enum GraphQLErrorExtensions { ERROR_ID = "ERROR_ID", EXCEPTION_SIMPLE_NAME = "EXCEPTION_SIMPLE_NAME", ORIGINAL_EXCEPTION_MESSAGE = "ORIGINAL_EXCEPTION_MESSAGE" };

export type CatchedGraphQLError = { graphQLErrors?: ReadonlyArray<GraphQLError>, networkError?: Error };

const cache = new InMemoryCache();

export const apolloHttpLinkOptions: any = {
    uri: Utils.adjustUrlToServerContext("graphql"),

    // seems to be needed, at least when running in node.js (and no issues observed in browser); otherwise we get:
    // fetch is not found globally and no fetcher passed, to fix pass a fetch for
    // your environment like https://www.npmjs.com/package/node-fetch.
    // ...
    fetch: fetch as any
};

// strips "__typename" in mutations for input objects
// inspired from: https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-576687277
const cleanTypenameLink = new ApolloLink((operation, forward) => {
    const keysToOmit = ['__typename'] // more keys like timestamps could be included here

    const def = getMainDefinition(operation.query)
    if (def && (def as OperationDefinitionNode).operation === 'mutation') {
        operation.variables = omitDeep(operation.variables, keysToOmit)
    }
    return forward ? forward(operation) : null
})

/**
 * Initially, network-only was used for fetchPolicy, but in some cases the data was returned from the cache, so I changed the setting to "no-cache" to stop using cache at all.
 * https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980
 */
export const apolloOptions = {
    cache,
    defaultOptions: {
        query: {
            fetchPolicy: "no-cache" as "no-cache"
        }
    }
};

export let apolloClient: ApolloClient<NormalizedCacheObject>;
let pendingOperations: Array<Operation>;
// experimental by CS, cf. #29033
let pendingOperations2: Array<Operation> = [];

// TODO by CS: suspectez ca ne-am chinuit degeaba; trebuia pus un global error handler la nivel de app js!
/**
 * This function is the apollo (graphql) global error handler. Hence the first form of parameter. And it is meant also to be invoked by
 * the user, within a "catch" block. And pass the catched error as param. Both param types share "graphQLErrors" and "networkError".
 * I understand that they are mutually exclusive.
 * 
 * @see The Manual / Errors
 * 
 * @author Cristian Spiescu
 * @author Mircea Negreanu
 */
export function apolloGlobalErrorHandler(errorInfo: ErrorResponse | CatchedGraphQLError) {
    
    if ((errorInfo as ErrorResponse).operation) {
        // errorInfo instanceof ErrorResponse
        const context = (errorInfo as ErrorResponse).operation.getContext();

        // in case of 401, no need to do the rest of the function (since the user was not 
        // logged in, but it needed to be). Just call logout (which will clear everything 
        // about the current user - session, other data locally saved - and 
        // redirect to login page)
        if ((context.response as Response)?.status == 401) {
            AppMetaTempGlobals.appMetaInstance.logout();
            return;
        }

        if (context[ApolloContext.DONT_SHOW_GLOBAL_ERROR_MESSAGE]) {
            return;
        }

        const onErrorHandler = context[ApolloContext.ON_ERROR_HANDLER];
        const onErrorExpectedException = context[ApolloContext.ON_ERROR_EXPECTED_EXCEPTION];
        if (onErrorHandler && (!onErrorExpectedException // not a particular exception specified; user wants all
            || onErrorExpectedException === apolloGetExtensionFromError(errorInfo, GraphQLErrorExtensions.EXCEPTION_SIMPLE_NAME))
            && !((errorInfo as any).recursionMarker)) {

            const shouldStillDisplayMessage = onErrorHandler({ ...errorInfo, recursionMarker: true }); // we need this flag to avoid infinite recursion; modifying the context doesn't work
            if (!shouldStillDisplayMessage) {
                return;
            } // else continue down
        }

    }

    // from now on, errorInfo can be both types

    if (errorInfo.graphQLErrors && errorInfo.graphQLErrors.length) { // second condition needed for CatchedGraphQLError; it seems that even if it's a network error, this list exists (and is empty)
        // normally we have only 1 error w/ "extensions" != null; 
        // however, if "extensions" doesn't exist, the code also works; who knows what is broken
        // on the Java side, and we don't like the error code throwing errors; hence where we use
        // "extensions" => we use ?.
        const extensions = errorInfo.graphQLErrors[0].extensions;
        const exceptionSimpleName = extensions?.[GraphQLErrorExtensions.EXCEPTION_SIMPLE_NAME];
        // may be null, if we are dealing w/ an UserError
        const errorId = extensions?.[GraphQLErrorExtensions.ERROR_ID];
        const originalExceptionMessage = extensions?.[GraphQLErrorExtensions.ORIGINAL_EXCEPTION_MESSAGE];

        if (originalExceptionMessage && !errorId) {
            // CASE 1: UserException
            //
            // exception not logged; so a "normal" error; we only display the error; we don't expect the user
            // to contact support. "originalExceptionMessage" should always be there. If not for an unknown reason =>
            // will display the "server error", and probably "errorId" won't be there eihter, so we will know that 
            // something went wrong with our error code in Java
            Utils.showGlobalAlert({ message: originalExceptionMessage, title: _msg("error.globalErrorMessage.server.title"), severity: Severity.ERROR });
            return;
        }

        let message = _msg("error.globalErrorMessage.server.message", errorId, exceptionSimpleName, errorInfo.graphQLErrors[0].message); // this is the original exception message, prefixed by the operation name, which adds a bit of additional info        

        // the current server impl always sends 1 error; so the code below is currently not reacheable. But who knows about the future?
        for (let i = 1; i < errorInfo.graphQLErrors.length; i++) {
            message += "\n" + errorInfo.graphQLErrors[i];
        }

        // CASE 2: server error
        Utils.showGlobalAlert({ title: _msg("error.globalErrorMessage.server.title"), message, severity: Severity.ERROR });
    } else {
        // CASE 3: network error
        console.log('network error')
        Utils.showGlobalAlert({
            title: _msg("error.globalErrorMessage.network.title"),
            message: _msg("error.globalErrorMessage.network.message", errorInfo.networkError?.message,
                // @ts-ignore
                errorInfo.networkError?.name, errorInfo.networkError?.statusCode, errorInfo.networkError?.bodyText),
            severity: Severity.ERROR
        });
    }
};

/**
 * Doesn't do much. But it's an ugly expression to keep copying/pasting it.
 * NOTE: the result can be nullish. Meaning that "extensions" exist, but the wanted key doesn't exist.
 * Or more probably, because "extensions" doesn't exist, because maybe this is an unexpected type of error (e.g. 
 * NetworkError, or an unexpected type of Java error).
 */
export function apolloGetExtensionFromError(error: CatchedGraphQLError, key: string) {
    return error.graphQLErrors?.[0]?.extensions?.[key];
}

const pendingOperationsLink = new ApolloLink((operation, forward) => {
    // executed before operation is sent to the server
    // TODO CS: atentie: la outgoing, adica mai jos, avem un IF ...; dar la incoming, nu mai e acest IF; deci in cazul in care mai jos avem false
    // si nu se aprinde spinner, oricum, se va da comanda de stingere. Nu-mi dau seama daca asta nu poate avea cazuri negative la imperecherea lui pendingOperations
    if (operation.getContext().showSpinner !== false) {
        pendingOperations.push(operation);
        if (pendingOperations.length === 1) {
            Utils.showSpinner();
        }
    }

    pendingOperations2.push(operation);
    apolloClientHolder.communicationInProgress = true;
    // console.log("One communication IN PROGRESS at", Date.now());

    // executed upon the return of the operation to the server
    return forward(operation).map(response => {
        // TODO CS: cred ca daca s-ar face IF-ul si aici (cf. mai sus), NU ar mai trebui testat indexof != -1
        if (pendingOperations.indexOf(operation) != -1) {
            pendingOperations.splice(pendingOperations.indexOf(operation), 1);
        }
        if (pendingOperations.length === 0) {
            Utils.hideSpinner();
        }
        
        pendingOperations2.splice(pendingOperations2.indexOf(operation), 1);
        // console.log("One communication ENDED. Remaining:", pendingOperations2.length, "communicationInProgess", apolloClientHolder.communicationInProgress);
        if (!pendingOperations2.length) {
            apolloClientHolder.communicationInProgress = false;
            apolloClientHolder.lastCommunicationEndedAt = Date.now();
            // console.log("Last communication ENDED at", apolloClientHolder.lastCommunicationEndedAt);
        }
        
        return response;
    });
});

export function initApolloClient() {
    const links = [
        // if we need to modify the HTTP headers => @see https://stackoverflow.com/a/48578567/306143
        // modifyHttpHeadersLink
        onError(apolloGlobalErrorHandler), // on GraphQL error in test mode it would just find AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers as undefined
        cleanTypenameLink,
        pendingOperationsLink,
        new HttpLink(apolloHttpLinkOptions)
    ];

    const link = ApolloLink.from(links);

    (apolloOptions as any).link = link;
    apolloClient = new ApolloClient(apolloOptions);
    pendingOperations = new Array();
}

export const apolloClientHolder = {

    communicationInProgress: false,
    lastCommunicationEndedAt: 0,

    get apolloClient() { return apolloClient; },
    set apolloClient(value: typeof apolloClient) { apolloClient = value; }
}

