import { EnhancedStore } from "@reduxjs/toolkit";
import produce from "immer";
import React from "react";
import { connect, ReactReduxContext, ReactReduxContextValue } from "react-redux";
import { Utils } from "../utils/Utils";
import { OmitFieldsOfTypeFunction } from "../CompMeta";

const SUFFIX_IMMER_RO = "_immerReadOnly";

export class StateAndLogic {

    protected _parent: StateAndLogic | null;
    protected fieldNameInParent: string;

    protected _store!: EnhancedStore;
    protected _path!: string;

    constructor(parent: StateAndLogic | null, fieldNameInParent: string) {
        this._parent = parent;
        this.fieldNameInParent = fieldNameInParent;
        if (!parent) {
            this._path = fieldNameInParent;
        }
    }

    get parent() {
        if (!this._parent) { throw new Error("Trying to access parent for a root SL."); }
        return this._parent;
    }

    get store() {
        if (!this._store) {
            this._store = this.parent.store;
        }
        return this._store;
    }
    set store(value) { this._store = value; };

    get path() {
        this.verifyWasEnhanced();
        if (!this._path) {
            this._path = this.parent.path + Utils.defaultIdSeparator + this.fieldNameInParent;
        }
        return this._path;
    }

    private verifyWasEnhanced() {
        throw new Error("This SL was instantiated via 'new ...'. SLs should be instatiated via 'createSL(...)'");
    }

}

const propsToBeSkipped = Object.getOwnPropertyNames(new StateAndLogic(null, "")).reduce((accumulator, crt) => {
    accumulator[crt] = true;
    return accumulator;
}, {} as any);

export function createSL<T extends unknown[], K extends StateAndLogic>(slClass: new (...args: T) => K, ...args: T) { // credit for type magic: https://stackoverflow.com/a/65556183/306143
    const sl = new slClass(...args);
    for (const property of Object.getOwnPropertyNames(sl)) {
        if (propsToBeSkipped[property]) {
            continue;
        }
        //@ts-ignore
        const def = sl[property];
        if (def instanceof StateAndLogic) { continue; }

        Object.defineProperty(sl, property, {
            get: () => {
                // TODO: atentie, poate in state e undef legitim!
                return Utils.navigate(sl.store.getState(), sl.path + Utils.defaultIdSeparator + property, false) || def;
            }, set: () => { throw new Error("Property is not writable"); }
        });
    }

    let proto = Object.getPrototypeOf(sl);

    while (proto !== StateAndLogic.prototype) {
        for (const func of Object.getOwnPropertyNames(proto)) {
            if (func === "constructor" || func.endsWith(SUFFIX_IMMER_RO)) { continue; }
            // @ts-ignore
            const originalFunc: Function = sl[func];
            if (!(originalFunc instanceof Function)) {
                throw new Error("Prop from prototype is not a function: " + originalFunc);
            }

            // @ts-ignore
            sl[func] = (...args: any[]) => {
                sl.store.dispatch({ type: "calling " + func + "() on " + sl.path });
                return originalFunc.apply(sl, args);
            }
        }
        proto = Object.getPrototypeOf(proto);
    }

    sl["verifyWasEnhanced"] = () => null;

    return sl;
}

type PropsWithSL = { sl: StateAndLogic };

// TODO tip pt clasa
function createStoreSettingHOC<T>(ComponentClass: T): T {
    return class extends React.Component<PropsWithSL> {
        static contextType = ReactReduxContext;

        constructor(props: PropsWithSL, context: ReactReduxContextValue) {
            super(props);
            props.sl.store = context.store;
        }

        render() {
            // @ts-ignore
            return <ComponentClass {...this.props} />
        }
    } as any;
}

// TODO: Sa nu uitam de selectori memo-izati, care nu au fost inclusi in FoundSlice v2. 
// UPDATE: Tr sa ne uitam pe net din nou. Nu-mi mai amintesc exact cum se foloseau, si daca aici ne ajuta cu ceva
export function connectForSL<T>(originalComponentClass: T) {
    function mapStateToProps(state: any, ownProperties: PropsWithSL) {
        if (!ownProperties.sl) {
            throw Error("The props of the component should have a field sl: StateAndLogic.");
        }
        // TODO ca mai sus cu navigate / undefined
        const current = Utils.navigate(state, ownProperties.sl.path, false);
        if (!current) {
            return {};
        }
        // TODO sa inlaturam copiii?
        return current;
    }
    // @ts-ignore
    const connectedComponent = connect(mapStateToProps)(originalComponentClass);
    let connectedComponentStoreSetting: typeof connectedComponent;

    return {
        originalComponent: originalComponentClass,
        connectedComponent,
        get connectedComponentStoreSetting() {
            if (!connectedComponentStoreSetting) {
                connectedComponentStoreSetting = connect(mapStateToProps)(createStoreSettingHOC(originalComponentClass) as any);
            }
            return connectedComponentStoreSetting;
        }
    };
}

export function setInReduxState<L extends StateAndLogic>(sl: L, modification: Partial<OmitFieldsOfTypeFunction<L>>) {
    sl.store.dispatch({ type: "setInReduxState:" + sl.path, payload: modification })
}

export function reducer_setInReduxState(state: any, action: { type: string, [key: string]: any }) {
    if (action.type.startsWith("setInReduxState:")) {
        // e.g. MyPage or MyPage.component1.counter
        const pathStr = Utils.substringAfter(action.type, "setInReduxState:");
        const path = pathStr.split(Utils.defaultIdSeparator);
        const result = { ...state };
        let current = result;
        for (const currentPathElement of path) {
            current[currentPathElement] = { ...current[currentPathElement] };
            current = current[currentPathElement];
        }
        Object.assign(current, action.payload);
        return result;
    } else {
        return state;
    }
}


function createCopyAsPlainObject<T extends StateAndLogic>(sl: T) {
    const result: T = {} as any;
    for (const key in sl) {
        if (propsToBeSkipped[key]) { continue; }
        const value = sl[key];
        if (value instanceof Function) { continue; }
        result[key] = value;
    }
    return result;
}

export type ImmerizedDraftState<T> = OmitFieldsOfTypeFunction<T>;

export function setInReduxStateImmer<L extends StateAndLogic>(sl: L, callback: (draft: ImmerizedDraftState<L>) => void) {
    const newState = produce(createCopyAsPlainObject(sl), callback);
    // @ts-ignore
    setInReduxState(sl, newState);
}
