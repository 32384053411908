import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, apolloClientHolder, BigState, ConnectedPageInfo, createSliceFoundation, EntityTableSimple, getBaseImpures, getBaseReducers, Optional, PropsFrom, RootReducerForPages, sliceEntityTableSimple, StateFrom, TestUtils, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { data1 } from "@crispico/foundation-react/components/AdvancedSelector/testState";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { MessageExt } from "@crispico/foundation-react/components/semanticUiReactExt";
import { Expanded, LinearizedItem, RenderItemParams, SliceTree, Tree } from "@crispico/foundation-react/components/TreeNew/Tree";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { ManyToOneEditorStandalone } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { DatePicker } from 'antd';
import { EquipmentResourceForMap } from "apollo-gen/EquipmentResourceForMap";
import { findEquipmentResourceForMapById, findEquipmentResourceForMapByIdVariables } from "apollo-gen/findEquipmentResourceForMapById";
import { getHistoryTracksByIdAndDate_experimentalWebService_historyTracks_getByIdAndDate as TrackDto, getHistoryTracksByIdAndDate_experimentalWebService_historyTracks_getByIdAndDate_historyTrack as HistoryTrack, getHistoryTracksByIdAndDate_experimentalWebService_historyTracks_getByIdAndDate_points as PointDto } from "apollo-gen/getHistoryTracksByIdAndDate";
import { HistoricalMapAnalysisEntryForHistoricalMap } from "apollo-gen/HistoricalMapAnalysisEntryForHistoricalMap";
import { historicalMapEntry_newEntity, historicalMapEntry_newEntityVariables } from "apollo-gen/historicalMapEntry_newEntity";
import { HistoryTrackForForHistoricalMap } from "apollo-gen/HistoryTrackForForHistoricalMap";
import { loadHMAEntryForHistoricalMap, loadHMAEntryForHistoricalMapVariables } from "apollo-gen/loadHMAEntryForHistoricalMap";
import { loadPositions, loadPositionsVariables } from "apollo-gen/loadPositions";
import { loadPositionsForHistoricalMap, loadPositionsForHistoricalMapVariables } from "apollo-gen/loadPositionsForHistoricalMap";
import { loadTracksForHistoricalMap, loadTracksForHistoricalMapVariables } from "apollo-gen/loadTracksForHistoricalMap";
import { Position } from "apollo-gen/Position";
import { PositionForHistoricalMap } from "apollo-gen/PositionForHistoricalMap";
import { InitializationsForClient, MapSettings, MarkerSettings } from "app";
import { GalleryMedia } from "components/LiveVideo/GalleryMedia";
import { eqImages256x256, getIcon, HoveredLayer, ID, Location, MapContainerLeaflet as MapContainer, MarkerData, MARKER_TYPE, PolygonData, PolylineData, POLYLINE_TYPE, SelectedLayer, sliceMapContainerLeaflet } from "components/MapContainerLeaflet/MapContainerLeaflet";
import getDistance from 'geolib/es/getDistance';
import gql from "graphql-tag";
import { FIND_EQUIPMENT_RESOURCE_FOR_MAP_BY_ID } from "graphql/queries";
import moment from 'moment';
import { EquipmentResourceUtils, EQUIPMENT_RESOURCE_TYPE } from "pages/EquipmentResource/EquipmentResourceUtils";
import { processVideosToGalleryImagesData, Video, VideoElement, VIDEO_TYPE } from "pages/EquipmentResource/VideoTab";
import React from "react";
import SplitPane from "react-split-pane";
import { Button, Dimmer, Divider, Dropdown, Form, FormGroup, Header, Icon, Label, List, Loader, Message, Modal, Popup, Segment, Tab, Table } from 'semantic-ui-react';
import {
    EQUIPMENT_USAGE_LOGS,
    getHistoricalMapColor, HistoricalMapChartComponent, PERCENTAGE_FOR_USAGE_LOGS_POSITION,
    PointType, sliceHistoricalMapChartComponent, TASKS
} from './HistoricalMapChartComponent';
import { positionEntityDescriptorForHistoricalMap, positionEntityDescriptorForHistoricalMapFull } from "./HistoricalMapEntityDescriptor";
import {
    HMA_ENTRY_CREATE_NEW, LOAD_HMA_ENTRY_FOR_HISTORICAL_MAP, LOAD_POSITIONS, LOAD_POSITIONS_FOR_HISTORICAL_MAP, LOAD_TRACKS_FOR_HISTORICAL_MAP, REMOVE_HMA_ENTRY_FROM_DATABASE, UPDATE_POSITION_FOR_HMA_ENTRY
} from "./queries";


const { RangePicker } = DatePicker;

const formatTime = (date: Date) => { return date ? moment(date).format(Utils.timeFormat) : _msg("HistoricalMap.inProgress") };
const formatDate = (date: Date) => { return date !== null ? moment(date).format(Utils.timeFormat + " " + Utils.dateFormat) : _msg("HistoricalMap.inProgress") };
const formatTimeS = (date: Date) => moment(date).format(Utils.dateTimeFormat);
const parseTime = (time: string) => moment(time).toDate().getTime();

const MAX_DISTANCE_BETWEEN_TRACKS = 10;
enum TrackColor { MOVEMENT = "green", STOP = "orange", QUESTION = "yellow" }
const START_TERRITORY_COLOR = "#3399cc";
const END_TERRITORY_COLOR = "#552ce9";
export const TRACK_TYPE = "track";

export enum TreeIcon { PLUS = 'plus square outline', MINUS = 'minus square outline', POINT = 'point' };

export enum TreeItemType { TRACKS, TASKS, EQUIPMENT_USAGE_LOGS, TRACK_SEGMENTED, TRACK_MISSION, USAGE_LOG, CATEGORY, VEHICLE, GPS_LOCATIONS_LIST, SPINNER };

export enum TreeItemCodes { TRACKS = "tracks", TASKS = "tasks", EQUIPMENT_USAGE_LOGS = "equipmentUsageLogs" }

const sliceTree1 = createSliceFoundation(class SliceTree1 extends SliceTree {
    hasChildren(item: any) {
        return item !== undefined
            && (item.type === undefined
                || item.type === TreeItemType.VEHICLE
                || item.type === TreeItemType.TRACKS
                || item.type === TreeItemType.TASKS
                || item.type === TreeItemType.EQUIPMENT_USAGE_LOGS);
    }
    getChildren(item: any): { localId: string, item: any }[] {
        if (item.type === TreeItemType.VEHICLE) {
            // for vehicle is a little work, because we need to filter only known
            // items (type is not null)
            return Object.keys(item).reduce((filtered, key) => {
                if (item[key].type != null && item[key].type !== TreeItemType.GPS_LOCATIONS_LIST) {
                    filtered.push({
                        localId: key,
                        item: item[key]
                    })
                }
                return filtered;
            }, [] as { localId: string, item: any }[]);
        }
        return super.getChildren(item).filter(itm => itm.item.type === null || (itm.localId !== "positions" && itm.localId !== "type"));
    }
});

// no easy way to get the mission and its flights (probably will implement
// something on the backed, or we'll modify this part with mission3?)
// until then, I'll define here a subtype
// when implementing the communication with backed this will be imported from apollo-gen
export interface MissionDto {
    id: number,
    startTime: any,
    endTime: any,
    flights: Array<FlightDto> | null,
    inactivityType: InactivityTypeDto | null
}

export interface FlightDto {
    name: string,
    departure: boolean
}

export interface InactivityTypeDto {
    name: String
}

export interface EquipmentUsageLogs {
    id: number,
    startDate: any,
    // this is type on the backend, for the moment type is used by tree to know what to render
    changedBy: string,
    driver: HumanResource | null
}

export interface HumanResource {
    firstName: String,
    lastName: String
}

// general functions
export function distanceBetweenTracks(track1: any, track2?: any) {
    if (!track1.firstPoint || !track2?.lastPoint || Object.keys(track1.firstPoint).length === 0 || Object.keys(track2?.firstPoint).length === 0) {
        return 0;
    }

    return getDistance({ latitude: track1.firstPoint.latitude!, longitude: track1.firstPoint.longitude! },
        { latitude: track2.lastPoint.latitude!, longitude: track2.lastPoint.longitude }, 1);
}

export const getDuration = (startDate: Date, endDate: Date) => {
    // if no endDate, we cannot compute the duration ...
    if (endDate === null || endDate === undefined) {
        return "";
    }
    let minutes = Math.floor((new Date(endDate).valueOf() - new Date(startDate).valueOf()) / (60 * 1000));
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);

    return (days > 0 ? days + " d " : "") + (hours > 0 ? hours + " h " : "") + (minutes > 0 ? minutes + " min" : "")
};

// slice for the main component in this file
export const sliceHistoricalMap = createSliceFoundation(class SliceHistoricalMap {
    nestedSlices = {
        tree: sliceTree1,
        chart: sliceHistoricalMapChartComponent,
        map: sliceMapContainerLeaflet,
        tableSimple: sliceEntityTableSimple,
    }

    initialState = {
        startDate: '' as string | number,
        endDate: '' as string | number,
        multipleVehicles: false,
        popup: undefined as { id: string, isStop: boolean } | undefined,
        addEquipmentPopupOpened: false,
        equipmentResource: undefined as Optional<EquipmentResourceForMap>,
        tableDataOnlyFromSelectedSeries: false as boolean,
        activeTableModeCompleteFields: false as boolean,
        currentSelectedTrack: undefined as any,
        entityDescriptor: positionEntityDescriptorForHistoricalMap.name as string | undefined,
        showTableSpinner: false,
        videos: undefined as unknown as { [key: string]: Video[]; },
        showVideoModal: false
    }

    reducers = {
        ...getBaseReducers<SliceHistoricalMap>(this),

        // completes the value for a serie in serieValues
        // used by TASKS/EQUIPMENT_USAGE_LOGS since they are very specific
        _reduceSpecificTrees(root: any, serieValues: any[], type: PointType, values: any[], y: any, chartId: string) {
            Object.values(root).reduce(
                (acc: any[], item: any) => {
                    if (item.id !== undefined) {
                        let lastValue = undefined;
                        for (let i = 0; i < values.length; i++) {
                            lastValue = values[i];
                            acc.push({
                                id: item.id,
                                x: item[lastValue],
                                y: y === undefined ? null : y,
                                type: type,
                                serieId: chartId
                            })
                        }
                        acc.push({
                            id: item.id,
                            x: item[lastValue],
                            y: null,
                            serieId: chartId
                        })
                    }
                    return acc;
                }, serieValues);
        },

        _recreateChartValues(chartId: string, multipleVehicles: boolean, filterByTrack: any | undefined,
            currentScaleMinValue: number | undefined, currentScaleMaxValue: number | undefined, chartScaleId: string | undefined, treeRoot: any)
            : { serieValues: any[], minValue: number | undefined, maxValue: number | undefined, updateScale: boolean } {
            const serieValues: any[] = [];
            let masterObject = treeRoot[TreeItemCodes.TRACKS];
            let valueName = chartId;
            let positions = treeRoot.positions;
            if (multipleVehicles) {
                masterObject = treeRoot[chartId];
                valueName = chartScaleId!;
                positions = masterObject.positions;
            }

            // Algo:
            //  - cycle by gpslLocations
            //     - filter them by filterByTrackId, or filterByTrack.startTime/filterByTrack.endTime/filterByTrack.id (for those on the same track)
            //  - cycle on the result and add start/stop markers where track id is different (and at the start/end)
            let maxValue = 0;
            let minValue: number | undefined = undefined;
            let lastElement: any | undefined = undefined;
            let lastTrackId: any | undefined = undefined;
            Object.values(positions).reduce(
                (acc: any[], item: any) => {
                    if (item.trackId !== undefined
                        && (filterByTrack === undefined
                            || (item.trackId === filterByTrack.id
                                || (item.date >= filterByTrack.startTime && item.date <= filterByTrack.endTime)))) {
                        let itemValue: any;
                        const telemetryCode = "," + valueName + ":";
                        if (item.telemetry?.indexOf(telemetryCode) > -1) {
                            itemValue = item.telemetry.split(telemetryCode)[1].split(",")[0];
                        } else {
                            itemValue = item[valueName];
                        }

                        if (itemValue === undefined) {
                            return acc;
                        }

                        // TODO HM: SCALE ADJUSTING TEMPORARY DISABLED; modified the serie y axis, and we don't want that for bar charts
                        // waiting for new specs v6.5 

                        // const charPosition = (str: any) => {
                        //     const strChars = str.split('');
                        //     let arr = 0;
                        //     const alpha = '[A-Za-z]';
                        //     for (let i = 0; i < strChars.length; i++) {
                        //         if (strChars[i].match(alpha) && strChars[i].match(alpha).length > 0) {
                        //             arr += strChars[i].charCodeAt(0) - 65;
                        //         } else {
                        //             continue;
                        //         };
                        //     };
                        //     return arr === 0 ? parseInt(str) : arr;
                        // }
                        // itemValue = typeof itemValue === "string" ? charPosition(itemValue) : itemValue;

                        let obj: any = {
                            id: item.id,
                            x: typeof item.date === "string" ? parseTime(item.date) : item.date,
                            y: itemValue,
                            type: PointType.GPS_LOCATION,
                            serieId: chartId
                        };
                        if (!multipleVehicles) {
                            obj[valueName] = itemValue;
                        }

                        // reverse TRACK_START and TRACK_END since the points are in reverse order
                        // if the points order will be changed, please also change here
                        if (lastElement === undefined) {
                            obj.type = PointType.TRACK_END;
                        } else if (lastTrackId !== item.trackId) {
                            lastElement.type = PointType.TRACK_START;
                            obj.type = PointType.TRACK_END;
                            // inject a stop point
                            acc.push({
                                x: lastElement.x,
                                y: null,
                                serieId: chartId
                            })
                        }
                        lastElement = obj;
                        lastTrackId = item.trackId;

                        acc.push(obj);
                        if (maxValue < itemValue) {
                            maxValue = itemValue;
                        }
                        if (minValue === undefined || minValue > itemValue) {
                            minValue = itemValue;
                        }
                    }
                    return acc;
                }, serieValues);

            // add markers at start and end
            if (serieValues.length > 0) {
                serieValues[0].type = PointType.TRACK_END;
                serieValues[serieValues.length - 1].type = PointType.TRACK_START;
            }

            let updateScale = false;

            // TODO HM: SCALE ADJUSTING TEMPORARY DISABLED; modified the serie y axis, and we don't want that for bar charts
            // waiting for new specs v6.5 

            // for multipleVehicles don't worry about scalling
            if (!multipleVehicles) {
                if (chartScaleId === chartId) {
                    if (maxValue > 0) {
                        updateScale = true
                    }
                } else {
                    // need to scale
                    if (currentScaleMaxValue !== undefined && maxValue > 0) {
                        const maxScale = currentScaleMaxValue;
                        const minScale = currentScaleMinValue;
                        const valueDiff = maxValue - minValue! === 0 ? 1 : maxValue - minValue!;
                        for (let i = 0; i < serieValues.length; i++) {
                            if (serieValues[i].y !== null) {
                                serieValues[i].y = serieValues[i].y * (maxScale - minScale!) / valueDiff + minScale!;
                            }
                        }
                    }
                }
            }

            return { serieValues: serieValues, minValue: minValue, maxValue: maxValue, updateScale: updateScale };
        },

        _getAvailableSerie(chart: any, chartId: string) {
            const availableSeries = chart.availableSeries;
            for (let i = 0; i < availableSeries.length; i++) {
                if (availableSeries[i].id === chartId) {
                    return i;
                }
            }
            return undefined;
        },

        onToggleSeries(state: StateFrom<SliceHistoricalMap>, info: { chartId: string, toggle: boolean }) {
            const availableSeriesIndex = this._getAvailableSerie(state.chart, info.chartId);
            if (info.toggle) {
                // value: true -> adds chart from gpsPoints 
                //   - TASKS/EQUIPMENT_USAGE_LOGS are taken from TASKS/EQUIPMENT_USAGE_LOGS part
                //   - rest are taken from gpsPositions, and get the value that is has the same name as chartId (if it exists)
                // takes info from gpsPoints and moves them to chart
                let serieValues: any[] = [];
                if (info.chartId === TASKS) {
                    // cycle on tree.root.tasks (if there are any)
                    //    add x=start/end and y=null with PointType.TASK
                    this._reduceSpecificTrees(state.tree.root[TreeItemCodes.TASKS], serieValues, PointType.TASK, ["startTime", "endTime"], null, info.chartId);
                } else if (info.chartId === EQUIPMENT_USAGE_LOGS) {
                    // cyle on tree.root.equipmentUsageLogs (if there are any)
                    // add x=start and y=null with PointType.GPS_LOCATION
                    const value = state.chart.currentScaleMaxValue! * PERCENTAGE_FOR_USAGE_LOGS_POSITION / 100;
                    this._reduceSpecificTrees(state.tree.root[TreeItemCodes.EQUIPMENT_USAGE_LOGS], serieValues, PointType.GPS_LOCATION, ["startDate"], value, info.chartId);

                } else {
                    const result = this._recreateChartValues(info.chartId, state.multipleVehicles, state.chart.filteredByTrack, state.chart.currentScaleMinValue,
                        state.chart.currentScaleMaxValue, state.chart.scaleId, state.tree.root);
                    if (result.updateScale) {
                        state.chart.currentScaleMinValue = result.minValue;
                        state.chart.currentScaleMaxValue = result.maxValue;
                    }
                    serieValues = result.serieValues;
                }

                // add the new serie to chart
                state.chart.series.push({
                    id: info.chartId,
                    color: state.chart.availableSeries[availableSeriesIndex!].color,
                    data: serieValues
                });

                // mark the checkbox checked
                state.chart.availableSeries[availableSeriesIndex!].checked = true;
            } else {
                // value: false -> removes chartId from charts
                //   - unchecks availableSeries
                if (availableSeriesIndex !== undefined) {
                    state.chart.availableSeries[availableSeriesIndex].checked = false;
                }
                if (this.getSlice().nestedSlices.chart.impures._containsPoint(state.chart.selectedPoints, info.chartId, undefined)) {
                    state.chart.selectedPoints = this.getSlice().nestedSlices.chart.impures._removePoint(state.chart.selectedPoints, info.chartId, undefined);
                }

                state.chart.series = state.chart.series.filter(serie => serie.id !== info.chartId);
            }

            if (!state.multipleVehicles) {
                // check to see if we only have one telemetry serie, so we can reset the scaling to this serie
                const telemetrySeries = state.chart.series.filter(serie => serie.id !== TASKS && serie.id !== EQUIPMENT_USAGE_LOGS);
                if (telemetrySeries.length === 1 && telemetrySeries[0].id !== state.chart.scaleId) {
                    this.getReducers().chart.changeScale(state.chart, telemetrySeries[0].id as string);
                }
            }

            if (state.tableDataOnlyFromSelectedSeries) {
                this.getTableEntities(state);
            }
        },

        onHoverInMap(state: StateFrom<SliceHistoricalMap>, p: { data?: Optional<HoveredLayer> }) {
            if (p.data?.type === TRACK_TYPE) {
                state.tree.hoveredId = p.data?.id as string;
            }
        },

        // for multiple charts
        populateAvailableChartsFromTree(state: StateFrom<SliceHistoricalMap>) {
            for (let treeVehicleKey of Object.keys(state.tree.root)) {
                const treeVehicle = state.tree.root[treeVehicleKey];
                if (treeVehicle.type !== undefined && treeVehicle.type === TreeItemType.VEHICLE
                    && !this.getReducers().chart._alreadyContainsSeries(state.chart.series, treeVehicleKey)) {
                    state.chart.availableSeries.push({
                        id: treeVehicleKey,
                        label: treeVehicle.identifier,
                        checked: false,
                        color: treeVehicle.color
                    })
                }
            }
        },

        resetState(state: StateFrom<SliceHistoricalMap>, p: { startDate: any, endDate: any }) {
            const resetState: any = {
                ...sliceHistoricalMap.initialState,
                startDate: p.startDate,
                endDate: p.endDate
            }

            this.setInReduxState(state, resetState);
            // also reset chart
            this.getReducers().chart.setInReduxState(state.chart, sliceHistoricalMapChartComponent.initialState);
        },

        //adds available series after parsing positions telemetry variable
        computeSeriesBasedOnTelemetry(state: StateFrom<SliceHistoricalMap>) {
            let gpsPoints: Array<Position> = [];
            if (state.multipleVehicles) {
                gpsPoints = Object.keys(state.tree.root).reduce(
                    (acc, key) => { acc = acc.concat(Object.values(state.tree.root[key].positions)); return acc; },
                    []);
            } else {
                gpsPoints = Object.values(state.tree.root.positions);
            }

            let series: Array<string> = []
            for (const gpsPoint of gpsPoints) {
                if (gpsPoint.telemetry) {
                    const telemetryData = gpsPoint.telemetry.split(",");
                    for (const telemetry of telemetryData) {
                        const telemetryName = telemetry.split(":");
                        if (!series.includes(telemetryName[0])
                            && telemetryName[0] !== "plateNumber"
                            && telemetryName[0] !== "accessoryName"
                            && telemetryName[0].length > 0) {

                            series.push(telemetryName[0]);

                            this._addScaleFieldAndAvailableSerie(state, { id: telemetryName[0], label: telemetryName[0] });
                        }
                    }
                }
            }
        },

        _addScaleFieldAndAvailableSerie(state: StateFrom<SliceHistoricalMap>, p: { id: string, label: string }) {
            state.chart.telemetryFields[p.id] = {
                id: p.id,
                label: p.label,
                color: getHistoricalMapColor(state.chart.availableSeries.length)
            };
            if (p.id !== "speed" && !state.multipleVehicles) {
                state.chart.availableSeries.push({
                    id: p.id,
                    label: p.label,
                    checked: false,
                    color: getHistoricalMapColor(state.chart.availableSeries.length)
                });
            }
        },

        getTableEntities(state: StateFrom<SliceHistoricalMap>) {
            let gpsPoints: Array<PointDto> = [];
            if (state.multipleVehicles) {
                const root = state.tree.root;
                gpsPoints = Object.keys(root).reduce(
                    (acc, key) => { acc = acc.concat(Object.values(root[key].positions)); return acc; },
                    []);
            } else {
                gpsPoints = Object.values(state.tree.root.positions);
            }

            if (state.tableDataOnlyFromSelectedSeries) {
                gpsPoints = gpsPoints.filter(function (gpsPoint: any) {
                    for (let index = 0; index < state.chart.availableSeries.length; index++) {
                        const seriesId = state.chart.availableSeries[index].id;
                        if (state.chart.availableSeries[index].checked && (gpsPoint[seriesId] || gpsPoint.telemetry?.indexOf("," + seriesId + ":") > -1)) {
                            return true;
                        }
                    }
                    return false;
                });
            }

            let tableSimple = { ...state.tableSimple };
            tableSimple.entities = gpsPoints;
            state.tableSimple = tableSimple;

        },

        //changes table mode from all points to selected series and vice versa
        onTableSelectionChange(state: StateFrom<SliceHistoricalMap>, value: number) {
            if (value) {
                if (value === 2) {
                    state.tableDataOnlyFromSelectedSeries = true;
                } else {
                    state.tableDataOnlyFromSelectedSeries = false;
                }
                this.getTableEntities(state);
            }
        },

    }

    impures = {
        ...getBaseImpures<SliceHistoricalMap>(this),

        _getTrackIdForMap(pointId: string, serieId: string): string {
            let trackId = undefined;
            if (this.getState().multipleVehicles) {
                const position = this.getState().tree.root[serieId].positions[this.computeGpsPositionKey(pointId)];
                trackId = TestUtils.replaceDotWithSeparator(`${serieId}.T${position.trackId}`)
            } else {
                const position = this.getState().tree.root.positions[this.computeGpsPositionKey(pointId)];
                trackId = TestUtils.replaceDotWithSeparator(`${TreeItemCodes.TRACKS}.T${position.trackId}`)
            }

            return trackId;
        },

        onClickOrDragInChart(points: any[] | undefined, drag: boolean) {
            if (points !== undefined) {
                this.getDispatchers().map.setInReduxState({ hoveredLayer: undefined, highlightedPointsOnLayer: [] });
                for (const currentPoint of points) {
                    const trackId = this._getTrackIdForMap(currentPoint.data.id, currentPoint.serieId as string);

                    if (this.getState().chart.animationInProgress) {
                        this.getDispatchers().map.setInReduxState({ hoveredLayer: undefined });
                    } else {
                        // select on tree
                        this.getDispatchers().tree.reveal({ ids: [trackId], expandIds: true, collapseOthers: false })
                        this.getDispatchers().tree.selectItem(trackId);

                        // select on map 
                        this.getDispatchers().map.setInReduxState({ selectedLayer: { type: TRACK_TYPE, id: trackId } });
                    }
                    // highlight point on map   
                    this.getDispatchers().map.highlightPoint({ layerType: TRACK_TYPE, layerId: trackId, pointId: currentPoint.data.id });
                }

                this.getDispatchers().chart.setInReduxState({
                    selectedPoints: points.map((value) => {
                        return {
                            serieId: value.serieId,
                            pointId: value.data.id,
                        }
                    })
                });
            }
        },

        onHoverInChart(points: any[] | undefined, mapContainer: MapContainer | undefined) {
            // search serieId, set currentSeries/currentPoint in state
            // set hoverTrack in mapContainer

            // no point in going further if the series has not been found
            if (points !== undefined) {
                // if (serieId !== undefined && serieId !== EQUIPMENT_USAGE_LOGS && serieId !== TASKS) {
                let otherPoints = [];
                for (let point of points) {
                    if (point.serieId !== EQUIPMENT_USAGE_LOGS && point.serieId !== TASKS) {
                        otherPoints.push({ serieId: point.serieId, pointId: point.data.id, data: { color: point.serieColor, x: moment(point.data.x).valueOf() } })
                    }
                }

                if (otherPoints.length > 0) {
                    this.getDispatchers().chart.setInReduxState({ currentPoints: otherPoints });
                    // now highligh it on the map
                    for (const entry of otherPoints) {
                        const trackId = this._getTrackIdForMap(entry.pointId!, entry.serieId as string);
                        this.getDispatchers().map.setInReduxState({ hoveredLayer: { id: trackId, type: TRACK_TYPE, additionalInfo: { pointId: entry.pointId } } });
                    }

                    return;
                }
            }

            // serieId/pointId == undefined => the mouse is leaving graphs, so reset currentPoint on chart
            if (this.getState().chart.currentPoints !== undefined && this.getState().chart.currentPoints.length > 0) {
                this.getDispatchers().chart.setInReduxState({ currentPoints: [] });
            }
        },

        _filterCharts(track: any | undefined = undefined) {
            let result: any[] = [];
            for (let i = 0; i < this.getState().chart.availableSeries.length; i++) {

                const serie = this.getState().chart.availableSeries[i];
                if (serie.checked === undefined || !serie.checked) {
                    continue;
                }

                if (serie.id !== TASKS && serie.id !== EQUIPMENT_USAGE_LOGS) {
                    const chartValues = this.getSlice().reducers._recreateChartValues(serie.id as string,
                        this.getState().multipleVehicles, track, this.getState().chart.currentScaleMinValue,
                        this.getState().chart.currentScaleMaxValue, this.getState().chart.scaleId, this.getState().tree.root);
                    if (chartValues.serieValues.length > 0) {
                        result.push({ id: serie.id, color: serie.color, data: chartValues.serieValues });
                    }
                } else {
                    for (let i = 0; i < this.getState().chart.series.length; i++) {
                        const chart = this.getState().chart.series[i];
                        if (chart.id === serie.id) {
                            result.push(chart);
                            break;
                        }
                    }
                }
            }
            this.getDispatchers().chart.setInReduxState({ series: result, filteredByTrack: track });
            if (this.getDispatchers().chart._containsPoint(this.getState().chart.selectedPoints, TASKS, undefined)
                || this.getDispatchers().chart._containsPoint(this.getState().chart.selectedPoints, EQUIPMENT_USAGE_LOGS, undefined)) {
                // usually tasks/equipmentUsageLogs are selected individuals (so nothing else in selectedPoints)
                // so we can reset the entire selectedPoints
                this.getDispatchers().chart.setInReduxState({ selectedPoints: [] });
            }
        },

        _selectOtherThanTracks(item: any, select: boolean, mapContainer: MapContainer | undefined) {
            let selectedPoints = [];
            if (item.type === TreeItemType.TRACK_MISSION) {
                selectedPoints.push({ serieId: TASKS, pointId: item.id })

            } else if (item.type === TreeItemType.USAGE_LOG) {
                selectedPoints.push({ serieId: EQUIPMENT_USAGE_LOGS, pointId: item.id })
            }
            // reset selection
            if (select) {
                if (this.getState().chart.filteredByTrack !== undefined) {
                    this._filterCharts();
                }
                this.getDispatchers().map.setInReduxState({ selectedLayer: undefined });
            }
            this.getDispatchers().map.setInReduxState({ hoveredLayer: undefined, highlightedPointsOnLayer: [] });

            this.getDispatchers().chart.setInReduxState({
                hoverTrack: undefined,
                currentPoints: [], selectedPoints: selectedPoints
            })
        },

        onSelectInTree(itemId: string, mapContainer: MapContainer | undefined) {
            /**
             * Algo:
             *     - get track item 
             *     - filter charts
             *     - (modify add charts to see what is selected)
             *     - select on map
             */

            // apply on charts that already exists
            const item = Utils.navigate(this.getState().tree.root, itemId);
            if (item.type === TreeItemType.TRACK_SEGMENTED) {
                this.getDispatchers().setInReduxState({ currentSelectedTrack: item });
                this._filterCharts(item.historyTrack);
                if (mapContainer !== undefined) {
                    this.getDispatchers().map.setInReduxState({ selectedLayer: { id: itemId, type: TRACK_TYPE, additionalInfo: { flyToLayer: true } } });
                }
            } else {
                this._selectOtherThanTracks(item, true, mapContainer);
            }
        },

        // hoverTrackOnMap(trackId: string, mapContainer: MapContainer) {
        onHoverInTree(itemId: string, mapContainer: MapContainer | undefined) {
            const item = Utils.navigate(this.getState().tree.root, itemId);
            if (item.type === TreeItemType.TRACK_SEGMENTED) {
                this.getDispatchers().chart.setInReduxState({ hoverTrack: item.historyTrack.id })
                this.getDispatchers().map.setInReduxState({ hoveredLayer: { id: itemId, type: TRACK_TYPE } });
            } else {
                this._selectOtherThanTracks(item, false, mapContainer);
            }
        },

        onSelectInMap(p: { data?: Optional<SelectedLayer>, multipleVehicles: boolean }) {
            if (p.data?.type === TRACK_TYPE) {
                this.getDispatchers().tree.reveal({ ids: [p.data.id as string], expandIds: true, collapseOthers: false })
                this.getDispatchers().tree.selectItem(p.data.id as string);
                this.onSelectInTree(p.data.id as string, undefined);

                if (p.data.additionalInfo?.pointId) {
                    const gpsPositionKey = this.computeGpsPositionKey(p.data.additionalInfo.pointId);
                    let position;
                    if (p.multipleVehicles) {
                        for (let key of Object.keys(this.getState().tree.root)) {
                            position = this.getState().tree.root[key].positions[gpsPositionKey];
                            if (position !== undefined) {
                                break;
                            }
                        }
                    } else {
                        position = this.getState().tree.root.positions[gpsPositionKey];
                    }
                    this.getDispatchers().chart.setInReduxState({ animationCurrentTime: parseTime(position.date) });
                }
            }

            if (p.data?.type == VIDEO_TYPE){
                this.getDispatchers().setInReduxState({showVideoModal: true});
            }
        },

        async _loadHistoryTracks(entityId: any) {
            const params = FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                Filter.create("startTime", FilterOperators.forDate.greaterThanOrEqualTo, moment(this.getState().startDate).toISOString()),
                Filter.create("startTime", FilterOperators.forDate.lessThanOrEqualTo, moment(this.getState().endDate).toISOString()),
                Filter.create("equipmentResource", FilterOperators.forNumber.equals, entityId),
            ])).sorts([{ field: "startTime", direction: "DESC" }]);

            const tracksFromServer: Optional<HistoryTrackForForHistoricalMap[]> = (await apolloClient.query<loadTracksForHistoricalMap, loadTracksForHistoricalMapVariables>({
                query: LOAD_TRACKS_FOR_HISTORICAL_MAP,
                context: {showSpinner: false},
                variables: params
            })).data.historyTrackService_findByFilter?.results as Optional<HistoryTrackForForHistoricalMap[]>;
            return tracksFromServer || [];

        },

        async _loadPositions(plateNumber: string) {
            const params = FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                Filter.create("date", FilterOperators.forDate.greaterThanOrEqualTo, moment(this.getState().startDate).toISOString()),
                Filter.create("date", FilterOperators.forDate.lessThanOrEqualTo, moment(this.getState().endDate).toISOString()),
                Filter.create("plateNumber", FilterOperators.forString.equals, plateNumber)
            ])).sorts([{ field: "date", direction: "DESC" }]);

            const positions: Optional<PositionForHistoricalMap[]> = (await apolloClient.query<loadPositionsForHistoricalMap, loadPositionsForHistoricalMapVariables>({
                query: LOAD_POSITIONS_FOR_HISTORICAL_MAP,
                context: {showSpinner: false},
                variables: params
            })).data.positionService_findByFilter?.results as Optional<PositionForHistoricalMap[]>;

            return positions || [];
        },

        async _loadLastPosition(plateNumber: string, useSort: boolean) {
            const params = FindByFilterParams.create().pageSize(1).filter(Filter.create("plateNumber", FilterOperators.forString.equals, plateNumber))
                .sorts(useSort ? [{ field: "date", direction: "DESC" }] : []);

            const positions: Optional<PositionForHistoricalMap[]> = (await apolloClient.query<loadPositionsForHistoricalMap, loadPositionsForHistoricalMapVariables>({
                query: LOAD_POSITIONS_FOR_HISTORICAL_MAP,
                context: {showSpinner: false},
                variables: params
            })).data.positionService_findByFilter?.results as Optional<PositionForHistoricalMap[]>;

            return positions || [];
        },

        _iterateTracksRecursive(node: any, parentNode: any, treeItemId: string, callback: (item: any, parentItem: any, treeItemId: string) => void) {
            if (!node || // "null" is object; odd but true; so we need this in addition to the one below
                typeof node !== "object") { // failsafe; if e.g. node = "2" => infinite recursion
                return;
            }
            if (node.type !== undefined) {
                if (node.type === TreeItemType.TRACK_SEGMENTED) {
                    callback(node, parentNode, treeItemId);
                } else if (node.type === TreeItemType.VEHICLE || node.type === TreeItemType.TRACKS) {
                    // let it hit the recursively below
                } else {
                    return;
                }
            }
            Object.keys(node).forEach(key => this._iterateTracksRecursive(node[key], node, treeItemId ? treeItemId + Utils.defaultIdSeparator + key : key, callback));
        },

        _getPoints(currentState: any, item: any, parentItem: any): Location[] {
            if (item.type === TreeItemType.TRACK_SEGMENTED) {
                let positionsStorage = {};
                if (currentState.multipleVehicles) {
                    positionsStorage = parentItem.positions;
                } else {
                    // need to filter the points by start end
                    positionsStorage = currentState.tree.root.positions;
                }
                return positionsStorage ? Object.values(positionsStorage).filter((position: any) =>
                    position.date >= item.historyTrack.startTime && (item.historyTrack.endTime === null || position.date <= item.historyTrack.endTime))
                    .sort((a: any, b: any) => a.date.toString().localeCompare(b.date.toString())) as Location[] : [];
            }
            return [];
        },

        _addTracksOnMap(mapContainer: MapContainer, currentState: any) {
            let tracks: PolygonData[] = [];
            this._iterateTracksRecursive(currentState.tree.root, null, "", (item, parentItem, treeItemId) => {
                const points: Location[] = this._getPoints(currentState, item, parentItem);
                if (points.length > 0) {
                    tracks.push({
                        id: treeItemId, points: points, color: parentItem.color ? parentItem.color : ""
                    })
                }
            }
            );
            if (tracks.length > 0) {
                mapContainer.addOrUpdateLayers(tracks, TRACK_TYPE);
            }
        },

        _updateMap(mapContainer: MapContainer | undefined) {
            mapContainer?.clearMap([TRACK_TYPE]);
            this._addTracksOnMap(mapContainer!, this.getState());
        },

        _getTrackIdForPosition(position: PositionForHistoricalMap, tracks: HistoryTrackForForHistoricalMap[]) {
            for (let track of tracks) {
                if (position.date >= track.startTime && (track.endTime === null || position.date <= track.endTime)) {
                    return track.id;
                }
            }

            return undefined;
        },

        computeTrackKey(trackId: any) {
            return "T" + trackId;
        },

        computeGpsPositionKey(gpsPositionId: any) {
            return "G" + gpsPositionId;
        },

        _buildTreeTrack(track: HistoryTrackForForHistoricalMap) {
            return {
                type: TreeItemType.TRACK_SEGMENTED,
                historyTrack: track,
                startTerritories: [],
                endTerritories: [],
                firstPoint: {},
                lastPoint: {}
            }
        },

        async _loadTracksAndPosition(startDate: string, endDate: string, equipmentId: any, plateNumber: any) {
            let tracks = await this._loadHistoryTracks(equipmentId);

            let treeTracks = {} as any;
            for (let track of (tracks || [])) {
                treeTracks[this.computeTrackKey(track.id)] = this._buildTreeTrack(track);
            }

            const positions = this.getState().activeTableModeCompleteFields ? await this.loadPositionsWithAllFields(plateNumber) : await this._loadPositions(plateNumber);

            // if no tracks, but we have points -> just create a dynamic track
            let uniqueTrack = false;
            // try and make a unique id for this track
            let uniqueId = -1 * equipmentId;
            if (Object.keys(treeTracks).length === 0 && positions.length > 0) {
                tracks = [
                    ...tracks,
                    {
                        id: uniqueId,
                        startTime: this.getState().startDate,
                        endTime: this.getState().endDate,
                        color: -1,
                    }
                ];
                // make a track for the entire day
                treeTracks[this.computeTrackKey(uniqueId)] = this._buildTreeTrack(tracks[0]);
                treeTracks[this.computeTrackKey(uniqueId)].uniqueTrack = true;
                uniqueTrack = true;
            }

            let positionsObj = {} as any;
            let firstLastPoint = {} as any;
            for (let position of positions) {
                // I have an error of can not assign to readonly property trackId
                // so I'll make a duplicate :()
                let gpsLoca: any = { ...position };

                let trackId = this._getTrackIdForPosition(position, tracks);
                const trackKey = this.computeTrackKey(trackId);
                gpsLoca["trackId"] = trackId;

                let pointCombo = firstLastPoint[trackKey];
                if (!pointCombo) {
                    pointCombo = {
                        firstPoint: undefined,
                        lastPoint: undefined
                    }
                    firstLastPoint[trackKey] = pointCombo;
                }

                if (!pointCombo.firstPoint || pointCombo.firstPoint.date > gpsLoca.date) {
                    pointCombo.firstPoint = gpsLoca;
                }
                if (!pointCombo.lastPoint || pointCombo.lastPoint.date < gpsLoca.date) {
                    pointCombo.lastPoint = gpsLoca;
                }

                positionsObj[this.computeGpsPositionKey(position.id)] = gpsLoca;
            }

            for (let key of Object.keys(treeTracks)) {
                let firstCombo = firstLastPoint[key];
                if (firstLastPoint[key]) {
                    treeTracks[key].firstPoint = firstCombo.firstPoint;
                    treeTracks[key].startTerritories = firstCombo.firstPoint?.territory ? firstCombo.firstPoint?.territory?.split(",").map((text: string) => {
                        return {
                            label: text,
                            color: START_TERRITORY_COLOR
                        }
                    }) : [];
                    treeTracks[key].lastPoint = firstCombo.lastPoint;
                    treeTracks[key].endTerritories = firstCombo.lastPoint?.territory ? firstCombo.lastPoint?.territory?.split(",").map((text: string) => {
                        return {
                            label: text,
                            color: END_TERRITORY_COLOR
                        }
                    }) : [];
                }
            }

            if (uniqueTrack) {
                // put startTime/endTime for the unique marker to be the same as firstPoint/lastPoint
                treeTracks[this.computeTrackKey(uniqueId)].historyTrack.startTime = treeTracks[this.computeTrackKey(uniqueId)].firstPoint.date;
                treeTracks[this.computeTrackKey(uniqueId)].historyTrack.endTime = treeTracks[this.computeTrackKey(uniqueId)].lastPoint.date;
            }

            return { treeTracks: treeTracks, positions: positionsObj, nbTracks: (tracks || []).length }
        },

        async lodaDataSingleEquipmentFromServer(rootReducerForPages: RootReducerForPages, startDate: string, endDate: string, entityId: any, plateNumber: any, mapContainer: MapContainer, 
            initialLoad: boolean, mapSettings?: MapSettings, reloadTable: boolean = false) {
            this.getDispatchers().tree.setInReduxState({
                root: {
                    "spinner": {
                        type: TreeItemType.SPINNER,
                    }
                }
            });

            if (entityId === undefined || entityId === null) {
                console.error("Error loading info - entityId is null")
                return;
            }

            const { treeTracks, positions } = await this._loadTracksAndPosition(startDate, endDate, entityId, plateNumber);

            this.getDispatchers().tree.setInReduxState({
                root: {
                    "tracks": {
                        type: TreeItemType.TRACKS,
                        ...treeTracks
                    },
                    positions: positions
                },
                expandedIds: {
                    "tracks": true
                }
            });

            // if no points and this is the first load -> get the latest day when we have points
            // and use that
            if (!this.getState().multipleVehicles && Object.keys(treeTracks).length === 0 && initialLoad) {
                const positionsWithoutSort = await this._loadLastPosition(plateNumber, false);
                if (positionsWithoutSort.length > 0) {
                    const positions = await this._loadLastPosition(plateNumber, true);
                    const lastDate = positions[0].date;
                    this.loadData(rootReducerForPages, true, entityId, plateNumber, moment(lastDate).startOf('day').toISOString(), moment(lastDate).endOf('day').toISOString(), mapContainer, mapSettings, false, true)
                    return;
                }
            }

            if (!this.getState().multipleVehicles && !this.getState().equipmentResource) {
                this._loadEquipmentResource(rootReducerForPages, entityId, mapContainer, mapSettings);
                this.getVideos(entityId, startDate.valueOf(), endDate.valueOf(), mapContainer);
            }
            this._updateMapAndCharts("speed", mapContainer);

            if (reloadTable) {
                this.getDispatchers().getTableEntities();
            }

            this.getDispatchers().computeSeriesBasedOnTelemetry();
        },

        async _loadEquipmentResource(rootReducerForPages: RootReducerForPages, entityId: number, mapContainer: MapContainer, mapSettings?: MapSettings) {
            mapContainer.removeLayers(EQUIPMENT_RESOURCE_TYPE);

            const eq: Optional<EquipmentResourceForMap> = (await apolloClient.query<findEquipmentResourceForMapById, findEquipmentResourceForMapByIdVariables>({
                query: FIND_EQUIPMENT_RESOURCE_FOR_MAP_BY_ID,
                context: {showSpinner: false},
                variables: { id: entityId }
            })).data.equipmentResourceService_findById;

            this.getDispatchers().setInReduxState({ equipmentResource: eq });

            if (eq) {
                this.addEquipmentResourceOnMap(eq, rootReducerForPages, mapContainer, mapSettings);
            }
        },

        async getVideos(equipmentResourceId: number, startDate: string, endDate: string, mapContainer: MapContainer) {
            const query = gql(`query getVideos($equipmentResourceId: Long!, $startDate: Date, $endDate: Date){
                smartwitnessService_media(
                    equipmentResourceId: $equipmentResourceId
                    startDate: $startDate
                    endDate: $endDate
                ) {date, thumbnailPath, videoPath, eventType, cameraChannel, entityId, latitude, longitude, duration}
              } `);

            mapContainer?.removeLayers(VIDEO_TYPE);

            const result: Video[] = await (await (await apolloClientHolder.apolloClient.query({ query, variables: { equipmentResourceId, startDate, endDate } }))).data.smartwitnessService_media;
            if (!result || result.length == 0){
                this.getDispatchers().setInReduxState({ videos: undefined });
                return
            }
            const groupedVideos = {} as { [key: string]: Video[]; }
            result.forEach((video: Video) => {
                groupedVideos[video.entityId] = result.filter(video1 => video.entityId == video1.entityId)
            })

            this.getDispatchers().setInReduxState({ videos: groupedVideos });

            let data: MarkerData[] = [];
            Object.entries(groupedVideos).forEach(([key, videos]) => {
                let video = videos[0];
                if (video.longitude !== undefined && video.latitude !== undefined &&
                    video.longitude !== null && video.latitude !== null) {
                    data.push({ id: video.entityId, point: { longitude: video.longitude, latitude: video.latitude }, text: video.eventType + " " + moment(video.date).format(Utils.dateTimeWithSecFormat) || "" });
                }
            })

            mapContainer?.addOrUpdateLayers(data, VIDEO_TYPE);
        },

        addEquipmentResourceOnMap(eq: EquipmentResourceForMap, rootReducerForPages: RootReducerForPages, mapContainer: MapContainer, mapSettings?: MapSettings) {
            if (!mapSettings) {
                throw Error("mapSettings wasn't provided for this HistoricalMap!");
            }
            const markerSettings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(mapSettings);
            if (eq.identifier && eq.lastPointLongitude && eq.lastPointLatitude) {              
                let marker: MarkerData = EquipmentResourceUtils.getMarkerFromEquipmentResource(rootReducerForPages, eq, markerSettings);         
                mapContainer?.addOrUpdateLayers([marker], EQUIPMENT_RESOURCE_TYPE);
                this.getDispatchers().map.setInReduxState({ center: [marker.point.latitude, marker.point.longitude] });
            }
        },

        async _loadHMAEntry(historicalMapAnalysisId: any) {
            const params = FindByFilterParams.create().filter(Filter.create("historicalMapAnalysis", FilterOperators.forNumber.equals, historicalMapAnalysisId))
                .sorts([{ field: "position", direction: "ASC" }]);

            const hMAEntries: Optional<HistoricalMapAnalysisEntryForHistoricalMap[]> = (await apolloClient.query<loadHMAEntryForHistoricalMap, loadHMAEntryForHistoricalMapVariables>({
                query: LOAD_HMA_ENTRY_FOR_HISTORICAL_MAP,
                context: {showSpinner : false},
                variables: params
            })).data.historicalMapAnalysisEntryService_findByFilter?.results as Optional<HistoricalMapAnalysisEntryForHistoricalMap[]>;

            return hMAEntries || [];
        },

        async _addTracksAndPosition(startDate: string, endDate: string, hmaEntry: HistoricalMapAnalysisEntryForHistoricalMap, color: any) {
            const { treeTracks, positions, nbTracks } = await this._loadTracksAndPosition(startDate, endDate, hmaEntry.equipment?.id, hmaEntry.equipment?.plateNumber);
            return ({
                type: TreeItemType.VEHICLE,
                hmaEntry: {
                    id: hmaEntry.id,
                    position: hmaEntry.position
                },
                color: color,
                identifier: hmaEntry.equipment?.identifier,
                equipmentType: {
                    name: hmaEntry.equipment?.equipmentType?.name ? hmaEntry.equipment?.equipmentType?.name : "Undefined"
                },
                positions: positions,
                nbTracks: nbTracks,
                ...treeTracks
            });
        },

        /**
         * Atentie la parametru. Ar fi trebuit sa fie o lista? (vezi multiple charts)
         * @param chartId 
         */
        calculateBarsData(chartId: any) {

        },

        /**
         * reload points on map
         * regenerate availableCharts on chart
         * and loads chart content
         * 
         * @param chartId chart to be reloaded, if undefined, regenerate all
         */
        _updateMapAndCharts(chartId: any, mapContainer: MapContainer) {
            this._updateMap(mapContainer);
            if (this.getState().multipleVehicles) {
                this.getDispatchers().populateAvailableChartsFromTree();
            } else {
                this.getDispatchers().chart.populateAvailableSeries(true);
            }
            if (chartId === undefined) {
                for (let availableChart of this.getState().chart.availableSeries) {
                    this.getDispatchers().onToggleSeries({ chartId: availableChart.id, toggle: false });
                    this.getDispatchers().onToggleSeries({ chartId: availableChart.id, toggle: true });
                }
            } else {
                this.getDispatchers().onToggleSeries({ chartId: chartId, toggle: false });
                this.getDispatchers().onToggleSeries({ chartId: chartId, toggle: true });
            }
        },

        async loadDataMultipleEquipmentsFromServer(startDate: string, endDate: string, historicalMapAnalysisId: any, mapContainer: MapContainer, reloadTable: boolean) {
            this.getDispatchers().tree.setInReduxState({
                root: {
                    "spinner": {
                        type: TreeItemType.SPINNER,
                    }
                }
            });

            const hmaEntries = await this._loadHMAEntry(historicalMapAnalysisId);
            let stateToChange: any = {
                root: {

                }
            }

            let i = 0;
            for (let hmaEntry of hmaEntries) {
                stateToChange.root["V" + hmaEntry.equipment!.id] = await this._addTracksAndPosition(startDate, endDate, hmaEntry, getHistoricalMapColor(i));
                i++;
            }

            this.getDispatchers().tree.setInReduxState(stateToChange);

            this._updateMapAndCharts(undefined, mapContainer);

            if (reloadTable) {
                this.getDispatchers().getTableEntities();
            }

            this.getDispatchers().computeSeriesBasedOnTelemetry();
        },

        async removeSelectedVehicleFromTree(mapContainer: MapContainer) {
            const selectedId = this.getState().tree.selectedId!;
            const item = Utils.navigate(this.getState().tree.root, selectedId!);

            // remove item from db
            await apolloClient.mutate({ mutation: REMOVE_HMA_ENTRY_FROM_DATABASE, variables: { id: item.hmaEntry.id } })

            // remove tracks from map/
            // do it like this I think there are a lot more tracks in total (multiple vehicles) 
            // than on a single vehice.
            for (let key of Object.keys(item)) {
                const info = item[key];
                if (info.type !== undefined && info.type === TreeItemType.TRACK_SEGMENTED) {
                    mapContainer.removeLayers(TRACK_TYPE, [TestUtils.replaceDotWithSeparator(`${selectedId}.${key}`)])
                }
            }

            // remove from tree
            let result: any = {}
            for (let key of Object.keys(this.getState().tree.root)) {
                if (key !== selectedId) {
                    result[key] = this.getState().tree.root[key]
                }
            }

            this.getDispatchers().tree.setInReduxState({
                root: result,
                selectedId: undefined
            })

            // unselect chart
            this.getDispatchers().onToggleSeries({ chartId: selectedId, toggle: false })
            // and remove from available series
            this.getDispatchers().chart.setInReduxState({
                availableSeries: this.getState().chart.availableSeries.filter(serie => serie.id !== selectedId)
            })

        },

        // applies on the selected vehicle to move it up or down
        // updates the positions from hmaEntry
        async changeVehicleOrder(moveDown: boolean) {
            const selectedItemId = this.getState().tree.selectedId;
            const currentItem = Utils.navigate(this.getState().tree.root, selectedItemId!);

            // prepare the next position
            const position = currentItem.hmaEntry.position;
            if (position === 0 && !moveDown) {
                // already at the top -> nothing to do
                return;
            }

            // compute next position
            const nextPosition = position + (moveDown ? 1 : -1);

            let nextItem = undefined;
            let nextItemKey = undefined;
            // search for the current hmaEntry that has the nextPosition
            for (let key of Object.keys(this.getState().tree.root)) {
                const item = this.getState().tree.root[key];
                if (item.type !== undefined
                    && item.type === TreeItemType.VEHICLE
                    && item.hmaEntry.position === nextPosition) {
                    nextItem = item;
                    nextItemKey = key;
                    break;
                }
            }

            // not found -> means we are at the end -> nothing to do
            if (nextItem === undefined) {
                return;
            }

            // update db
            await apolloClient.mutate({
                mutation: UPDATE_POSITION_FOR_HMA_ENTRY, variables: {
                    id: currentItem.hmaEntry.id,
                    newPosition: nextPosition
                }
            });
            await apolloClient.mutate({
                mutation: UPDATE_POSITION_FOR_HMA_ENTRY, variables: {
                    id: nextItem.hmaEntry.id,
                    newPosition: position
                }
            });

            // update tree
            let root: any = {}
            for (let key of Object.keys(this.getState().tree.root)) {
                const item = this.getState().tree.root[key];
                if (item.type && item.type === TreeItemType.VEHICLE) {
                    // reverse
                    if (item.hmaEntry.position === currentItem.hmaEntry.position) {
                        root[nextItemKey!] = { ...nextItem };
                        root[nextItemKey!].hmaEntry = { ...nextItem.hmaEntry };
                        root[nextItemKey!].hmaEntry.position = position;
                    } else if (item.hmaEntry.position === nextItem.hmaEntry.position) {
                        root[selectedItemId!] = { ...currentItem };
                        root[selectedItemId!].hmaEntry = { ...currentItem.hmaEntry };
                        root[selectedItemId!].hmaEntry.position = nextPosition;
                    } else {
                        root[key] = this.getState().tree.root[key];
                    }
                } else {
                    root[key] = item;
                }
            }

            this.getDispatchers().tree.setInReduxState({
                root: root
            })

            // update chart
            let currentSerie: any = undefined;
            let nextSerie: any = undefined;
            for (let serie of this.getState().chart.availableSeries) {
                if (serie.id === selectedItemId) {
                    currentSerie = serie;
                } else if (serie.id === nextItemKey) {
                    nextSerie = serie;
                }
            }

            // reverse
            const availableSeries = this.getState().chart.availableSeries
            let newSeries: typeof availableSeries = [];
            for (let serie of this.getState().chart.availableSeries) {
                if (serie.id === selectedItemId) {
                    newSeries.push(nextSerie);
                } else if (serie.id === nextItemKey) {
                    newSeries.push(currentSerie);
                } else {
                    newSeries.push(serie);
                }
            }

            this.getDispatchers().chart.setInReduxState({
                availableSeries: newSeries
            })
        },

        async addEquipment(equipmentResource: any, historicalMapAnalysisId: any, mapContainer: MapContainer) {
            // first search to see if the equipment is already added
            let found = false;
            let maxPosition = undefined;
            const stateRoot = this.getState().tree.root;
            for (let key of Object.keys(stateRoot)) {
                if (key === "V" + equipmentResource.id) {
                    // found it
                    found = true;
                }
                if (maxPosition === undefined || stateRoot[key].hmaEntry.position > maxPosition) {
                    maxPosition = stateRoot[key].hmaEntry.position;
                }
            }

            if (found) {
                return;
            }
            if (maxPosition !== undefined) {
                maxPosition++;
            } else {
                maxPosition = 0;
            }

            // // create a hmaEntry and save it to db
            const response = (await apolloClient.mutate<historicalMapEntry_newEntity, historicalMapEntry_newEntityVariables>({
                mutation: HMA_ENTRY_CREATE_NEW, variables: {
                    position: maxPosition,
                    equipmentId: equipmentResource.id,
                    historicalMapAnalysisId: historicalMapAnalysisId
                }
            })).data?.historicalMapAnalysisEntryService_createNewEntity as HistoricalMapAnalysisEntryForHistoricalMap;

            // add the new hmaEntry
            let stateToChange: any = {
                root: {
                    ...this.getState().tree.root
                }
            }

            const equipmentKey = "V" + response.equipment!.id;

            stateToChange.root[equipmentKey] = await this._addTracksAndPosition(this.getState().startDate as string,
                this.getState().endDate as string, response, getHistoricalMapColor(Object.keys(stateToChange.root).length));

            this.getDispatchers().tree.setInReduxState(stateToChange);

            this._updateMapAndCharts(equipmentKey, mapContainer);
        },

        async loadData(rootReducerForPages: RootReducerForPages, singleEquipment: boolean, entityId: any, plateNumber: any, startDate: string, endDate: string, mapContainer: MapContainer, 
                       mapSettings?: MapSettings, initialLoad: boolean = false, reloadTable: boolean = false) {
            if (singleEquipment && !plateNumber) {
                return;
            }
            
            this.getDispatchers().resetState({ startDate, endDate });
            await this.getDispatchers().setInReduxState({ showTableSpinner: true });
            mapContainer.clearMap([TRACK_TYPE]);
            this.getDispatchers().setInReduxState({ startDate, endDate });
            if (singleEquipment) {
                this.getDispatchers().chart.setInReduxState({ addDefaultAvailableSeries: false });
                this.lodaDataSingleEquipmentFromServer(rootReducerForPages, startDate, endDate, entityId, plateNumber, mapContainer, initialLoad, mapSettings, reloadTable);
            } else {
                // reset current state
                this.getDispatchers().chart.setInReduxState({ addDefaultAvailableSeries: false });
                this.getDispatchers().setInReduxState({ multipleVehicles: true })
                this.loadDataMultipleEquipmentsFromServer(startDate, endDate, entityId, mapContainer, reloadTable);
            }
            this.getDispatchers().setInReduxState({ showTableSpinner: false });

        },

        async saveDates(historicalMapAnalysisId: any, startDate: string, endDate: string) {
            await apolloClient.mutate({
                mutation: gql(`mutation q($params: SaveParams_LongInput) {
                historicalMapAnalysisService_save(params: $params) {
                    id, startDate, endDate
                }}`),
                variables: {
                    params: {
                        id: historicalMapAnalysisId,
                        fieldsAndValues: { startDate: startDate, endDate: endDate }
                    }
                }
            });
        },

        //change table mode between:
        //1. reduced position fields
        //2. complete position fields
        onLoadAdditionalFieldsToggle() {
            let valueInState = !this.getState().activeTableModeCompleteFields;
            const entityDescriptor = valueInState ? positionEntityDescriptorForHistoricalMapFull : positionEntityDescriptorForHistoricalMap;
            this.getDispatchers().setInReduxState({ activeTableModeCompleteFields: valueInState as boolean, entityDescriptor: entityDescriptor.name });
        },

        async loadPositionsWithAllFields(plateNumber: string) {
            const params = FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                Filter.create("date", FilterOperators.forDate.greaterThanOrEqualTo, moment(this.getState().startDate).toISOString()),
                Filter.create("date", FilterOperators.forDate.lessThanOrEqualTo, moment(this.getState().endDate).toISOString()),
                Filter.create("plateNumber", FilterOperators.forString.equals, plateNumber)
            ])).sorts([{ field: "date", direction: "DESC" }]);

            const positions: Optional<Position[]> = (await apolloClient.query<loadPositions, loadPositionsVariables>({
                query: LOAD_POSITIONS,
                context: {showSpinner : false},
                variables: params
            })).data.positionService_findByFilter?.results as Optional<Position[]>;

            return positions || [];
        },

        getSeriesValueFromPositions(series: string, date: Date) {
            const gpsPoints = Object.values(this.getState().tree.root.positions);
            const telemetryCode = "," + series + ":";
            const relevantGpsPoint: Array<any> = gpsPoints.filter((gpsPoint: any) => gpsPoint.date <= date && (gpsPoint[series] !== undefined || (gpsPoint.telemetry?.indexOf(telemetryCode) > -1 && gpsPoint.telemetry.split(telemetryCode)[1].split(",")[0].toString().length > 0)));
            if (relevantGpsPoint.length > 0) {
                return [relevantGpsPoint[0][series] !== undefined ? relevantGpsPoint[0][series] : relevantGpsPoint[0].telemetry.split(telemetryCode)[1].split(",")[0], relevantGpsPoint[0].date];
            } else {
                return ["", ""];
            }
        },

        exportSeriesAsCSV() {
            let rows:any[] = [[_msg("HistoricalMap.serie"), _msg("general.color"), _msg("general.id"), _msg("general.x"), _msg("general.y")]];
            this.getState().chart.series.forEach(s => s.data.forEach(d => rows.push([d.serieId, s.color, d.id, d.x, d.y])));
            Utils.exportToCsv("HistoricalMapSeries_" + new Date().getTime() + ".csv", rows);
        }

    }
})

// Extends Tree so we can modify styles for roots
// Did not modify the original class since there appears to be code that is incorrectly
// rendered when alignItems is not center
export class TreeMine extends Tree {
    protected createItemWrapperProps(linearizedItem: LinearizedItem) {
        let result = super.createItemWrapperProps(linearizedItem);

        if (linearizedItem.expanded === Expanded.LEAF) {
            result.style.flexDirection = "column";
            result.style.alignItems = "left";
        }

        return result;
    }
}

type PropsNotFromState = {
    entityId?: any;
    plateNumber?: any;
    historicalMapAnalysisId?: any;
    historicalMapAnalysisEntity?: any;
    mapSettings?: MapSettings;
}
type Props = PropsFrom<typeof sliceHistoricalMap> & PropsNotFromState

// main component renders the entire screen (tree, map, chart, list of gps points)
export class HistoricalMap extends React.Component<Props> {

    mapContainerRef = React.createRef<MapContainer>();

    protected getTitle() {
        return { icon: "map outline", title: _msg("HistoricalMap.title") };
    }

    componentDidMount() {
        this.componentDidUpdateInternal();

        // force updateMap, in case the state is composed before (e.g. storybook)
        this.props.dispatchers._updateMap(this.mapContainerRef.current!);

        // if storybook, add eq on map, otherwise don't because we will habe a flicker on map (add old + center, then remove + add new + center)
        if (TestUtils.storybookMode && this.props.equipmentResource) {
            this.props.dispatchers.addEquipmentResourceOnMap(this.props.equipmentResource, this.props.rootReducerForPages!, this.mapContainerRef.current!, this.props.mapSettings);
        }
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: Props) {
        let singleEquipment = true;
        let entityId = undefined;
        let startDate = moment(Utils.now()).startOf('day').toISOString();
        let endDate = moment(Utils.now()).startOf('day').add(1, 'hour').toISOString();
        if (this.props.entityId && !prevProps?.entityId) {
            singleEquipment = true;
            entityId = this.props.entityId;
        } else if (this.props.historicalMapAnalysisEntity && !prevProps?.historicalMapAnalysisEntity) {
            singleEquipment = false;
            entityId = this.props.historicalMapAnalysisEntity.id;
            if (this.props.historicalMapAnalysisEntity.startDate != null && this.props.historicalMapAnalysisEntity.endDate != null) {
                // force dates from server
                startDate = this.props.historicalMapAnalysisEntity.startDate;
                endDate = this.props.historicalMapAnalysisEntity.endDate;
            }
        }

        if (entityId !== undefined) {
            this.props.dispatchers.loadData(this.props.rootReducerForPages!, singleEquipment,
                entityId,
                this.props.plateNumber,
                startDate,
                endDate,
                this.mapContainerRef.current!,
                this.props.mapSettings,
                true,
                true);
        }
        if (prevProps && prevProps.chart.scaleId !== this.props.chart.scaleId) {
            // here I want to re-create the series for charts based on selected scaleId           
            if (this.props.multipleVehicles) {
                for (let availableChart of this.props.chart.availableSeries) {
                    this.props.dispatchers.onToggleSeries({ chartId: availableChart.id, toggle: false });
                    this.props.dispatchers.onToggleSeries({ chartId: availableChart.id, toggle: true });
                }
            } else {               
                this.props.dispatchers.onToggleSeries({ chartId: this.props.chart.scaleId!, toggle: true });
            }
        }
    }

    rangePickerHandleChange = (dates: any) => {
        // for multipleVehicles -> update db too
        let startDate = dates[0].toISOString();
        let endDate = dates[1].toISOString();
        if (this.props.multipleVehicles) {
            this.props.dispatchers.saveDates(this.props.historicalMapAnalysisId, startDate, endDate);
        }

        // reset state
        this.props.dispatchers.loadData(this.props.rootReducerForPages!, this.props.entityId !== undefined ? true : false,
            this.props.entityId !== undefined ? this.props.entityId : this.props.historicalMapAnalysisId,
            this.props.plateNumber,
            startDate,
            endDate,
            this.mapContainerRef.current!,
            this.props.mapSettings);
    }

    checkIfVehicleSelected() {
        // sometimes this triggers during state reset -> navigate will fail (this is the reason for checking root.length)
        if (this.props.tree.selectedId
            /* && this.props.tree.root.length > 0 */
            && this.props.tree.root[this.props.tree.selectedId] !== undefined) {
            const item = Utils.navigate(this.props.tree.root, this.props.tree.selectedId);
            return item && item.type === TreeItemType.VEHICLE;
        }

        return false;
    }

    renderMultiVehicleInterface() {
        if (!this.props.multipleVehicles) {
            return;
        }

        return (<>
            <Popup
                trigger={
                    <Button positive content={_msg("general.add")} data-cy="HistoricalMapAnalysis.add" />
                }
                content={<Form>
                    <Form.Field>
                        <label>{_msg("HistoricalMap.selectEquipment")}</label>
                        <ManyToOneEditorStandalone entityName="EquipmentResource" onChange={value => {
                            // defer a little the call, because of the errror
                            // "Cannot update a component (`ConnectFunction`) while rendering a different component (`Formik`). To locate the bad setState() call inside `Formik`, follow the stack trace as described in https://fb.me/setstate-in-render"
                            setTimeout(() => {
                                // close the popup
                                this.props.dispatchers.setInReduxState({ addEquipmentPopupOpened: false })
                                // execute the add equipment
                                this.props.dispatchers.addEquipment(value, this.props.historicalMapAnalysisId, this.mapContainerRef.current!);
                            })
                        }} />
                    </Form.Field>
                </Form>}
                on='click'
                open={this.props.addEquipmentPopupOpened}
                onOpen={() => this.props.dispatchers.setInReduxState({ addEquipmentPopupOpened: true })}
                onClose={() => this.props.dispatchers.setInReduxState({ addEquipmentPopupOpened: false })}
            />
            <Button disabled={!this.checkIfVehicleSelected()}
                onClick={() => this.props.dispatchers.changeVehicleOrder(false)}
                data-cy="HistoricalMapAnalysis.moveUp">{_msg("HistoricalMap.moveUp")}</Button>
            <Button disabled={!this.checkIfVehicleSelected()}
                onClick={() => this.props.dispatchers.changeVehicleOrder(true)}>{_msg("HistoricalMap.moveDown")}</Button>
            <Button negative floated='right' disabled={!this.checkIfVehicleSelected()}
                onClick={() => this.props.dispatchers.removeSelectedVehicleFromTree(this.mapContainerRef.current!)}
                data-cy="HistoricalMapAnalysis.remove">{_msg("general.remove")}</Button>
            <div style={{ clear: "both" }}></div>
        </>
        )
    }

    positionInfoFromPoint(seriesId: any) {
        if (seriesId.id === TreeItemCodes.TASKS || seriesId.id === TreeItemCodes.EQUIPMENT_USAGE_LOGS) {
            return;
        }

        const props = this.props;
        const selectedPointsInChart = props.chart.selectedPoints;
        let position = undefined;
        for (let i = 0; i < selectedPointsInChart.length; i++) {
            if (props.multipleVehicles) {
                position = props.tree.root[seriesId.id].positions[this.props.dispatchers.computeGpsPositionKey(selectedPointsInChart[i].pointId)];
            } else {
                position = props.tree.root.positions[this.props.dispatchers.computeGpsPositionKey(selectedPointsInChart[i].pointId)];
            }

            if (position) {
                break;
            }
        }

        let date = "";
        let value = "";
        if (position) {
            if (props.multipleVehicles) {
                value = position[this.props.chart.scaleId!];
                date = value ? formatTime(position.date) : "";
            } else {
                const data = props.dispatchers.getSeriesValueFromPositions(seriesId.id, position.date);
                value = data[0];
                date = (data[0] !== undefined && data[0].toString().length > 0) ? formatTime(data[1]) : "";
            }
        }

        return (<Table.Row key={seriesId.id}>
            <Table.Cell key={seriesId.label}>{seriesId.label}</Table.Cell>
            <Table.Cell key={value}>{value}</Table.Cell>
            <Table.Cell key={date}>{date}</Table.Cell>
        </Table.Row>)
    }

    renderCurrentLocationTable() {
        const props = this.props;

        return (<Table definition compact selectable fixed striped columns={3} celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>{_msg("HistoricalMap.value")}</Table.HeaderCell>
                    <Table.HeaderCell>{_msg("HistoricalMap.date")}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {props.chart.availableSeries.map(seriesId => this.positionInfoFromPoint(seriesId))}
            </Table.Body>
        </Table>);
    }

    onLoadAdditionalFieldsToggle() {
        this.props.dispatchers.onLoadAdditionalFieldsToggle();

        this.props.dispatchers.loadData(this.props.rootReducerForPages!, this.props.entityId !== undefined ? true : false,
            this.props.entityId !== undefined ? this.props.entityId : this.props.historicalMapAnalysisId,
            this.props.plateNumber,
            moment(this.props.startDate).toISOString(),
            moment(this.props.endDate).toISOString(),
            this.mapContainerRef.current!,
            this.props.mapSettings,
            false,
            true);
    }

    render() {
        const props = this.props;

        if (this.props.entityId !== undefined && !this.props.plateNumber) {
            return <Segment fluid="true" className="flex-container flex-grow flex-center" >{_msg("HistoricalMap.invalidEqProps")}</Segment>
        }

        const menuPanes = [
            {
                menuItem: _msg("HistoricalMap.tracks"), render: () => <div className="MapTabHistory_leftQuarterWrapper">
                    <Message color="blue" data-cy="HistoricalMap.selectDate">
                        <Form><FormGroup inline>
                            <Message.Header><Icon name="map outline" />{_msg("HistoricalMap.tracks")}</Message.Header>
                            <RangePicker format={Utils.dateTimeFormat} showTime={true} value={[moment(this.props.startDate), moment(this.props.endDate)]}
                                onChange={this.rangePickerHandleChange}
                                ranges={{
                                    [_msg("telematics.today")]: [moment(Utils.now()).startOf('day'), moment(Utils.now()).endOf('day')],
                                    [_msg("HistoricalMap.yesterday")]: [moment(Utils.now()).add(-1, "day").startOf('day'), moment(Utils.now()).add(-1, "day").endOf('day')],
                                    [_msg("HistoricalMap.thisWeek")]: [moment(Utils.now()).startOf('week'), moment(Utils.now()).endOf('week')],
                                }} />
                        </FormGroup></Form>
                    </Message>
                    {this.renderMultiVehicleInterface()}
                    <TreeMine {...this.props.tree} dispatchers={this.props.dispatchers.tree} renderItemFunction={this.renderTreeItem}
                        onHoverItem={(params) => this.props.dispatchers.onHoverInTree(params.itemId, this.mapContainerRef?.current!)}
                        onSelectItem={(params) => { this.props.dispatchers.onSelectInTree(params.itemId, this.mapContainerRef?.current!) }}
                        dataCy="HistoricalMap.tree"
                    ></TreeMine>
                </div>
            },
            {
                menuItem: _msg("HistoricalMap.details"), render: () => <div>
                        <Segment fluid style={{height: "300px"}}>
                            <Dimmer active={this.props.showTableSpinner}><Loader size='large'>Loading</Loader></Dimmer>
                            <Button compact toggle primary active={props.activeTableModeCompleteFields} onClick={() => this.onLoadAdditionalFieldsToggle()}>Load additional fields</Button>
                            <Dropdown compact defaultValue={1} selection options={[
                                { key: 1, text: _msg("HistoricalMap.allPoints"), value: 1 },
                                { key: 2, text: _msg("HistoricalMap.visibleSeries"), value: 2 },
                            ]}
                                onChange={(e, { value }) => this.props.dispatchers.onTableSelectionChange(value as number)} />
                            {this.listGpsPositions()}
                        </Segment>
                        <Segment fluid>
                            <MessageExt headerClassName="flex-container-row flex-center"> Current track </MessageExt>
                            <Table definition compact selectable fixed striped columns={2} celled>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>{_msg("HistoricalMap.startTime")}</Table.Cell>
                                        <Table.Cell>{(props.currentSelectedTrack ? formatTime(props.currentSelectedTrack.historyTrack.startTime) : "")}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{_msg("HistoricalMap.endTime")}</Table.Cell>
                                        <Table.Cell>{(props.currentSelectedTrack ? formatTime(props.currentSelectedTrack.historyTrack.endTime) : "")}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                        <Segment fluid>
                            <MessageExt headerClassName="flex-container-row flex-center"> Current location </MessageExt>
                            {this.renderCurrentLocationTable()}
                        </Segment>
                    </div>
            }
        ]

        return (<>
            <SplitPane split="vertical" defaultSize="25%" className="flex-container flex-grow less-padding" style={{ position: "static" }}
                onChange={() => this.mapContainerRef.current?.updateSize()}>
                <Tab className="flex-container flex-grow" panes={menuPanes} />
                <SplitPane split="horizontal" defaultSize="50%" className="flex-container flex-grow" style={{ position: "static" }}
                    onChange={() => this.mapContainerRef.current?.updateSize()}>
                    <MapContainer ref={this.mapContainerRef} mapId="historical-map" {...this.props.map} dispatchers={this.props.dispatchers.map}
                        renderTooltipContent={this.renderSmallMapPopup}
                        renderPopupContent={this.renderBigMapPopup} renderMarkerIcon={this.renderMarkerIcon}
                        onHoverLayer={(data: Optional<HoveredLayer>) => this.props.dispatchers.onHoverInMap({ data })}
                        onSelectLayer={(data: Optional<SelectedLayer>) => this.props.dispatchers.onSelectInMap({ data: data, multipleVehicles: this.props.multipleVehicles })}
                        pruneClusterMode={false} bingAPIKey={this.props.mapSettings?.bingAPIKey}
                        layers={{ [TRACK_TYPE]: { layerType: POLYLINE_TYPE }, [EQUIPMENT_RESOURCE_TYPE]: { layerType: MARKER_TYPE }, [VIDEO_TYPE]: { layerType: MARKER_TYPE } }}
                    />
                    <div className="flex-container flex-grow">
                        <HistoricalMapChartComponent {...this.props.chart} dispatchers={this.props.dispatchers.chart}
                            onToggleSeries={(chartId: string, value: boolean) => this.props.dispatchers.onToggleSeries({ chartId: chartId, toggle: value })}
                            onPointHover={(points) => this.props.dispatchers.onHoverInChart(points, this.mapContainerRef?.current!)}
                            onClickOrDragPoint={(points, drag) => this.props.dispatchers.onClickOrDragInChart(points, drag)}
                            customButtons={[this.props.equipmentResource && <Button color="green"
                                onClick={() => this.props.dispatchers._loadEquipmentResource(props.rootReducerForPages!, this.props.entityId, this.mapContainerRef.current!, this.props.mapSettings)}>
                                <Icon name='pin' />{_msg("HistoricalMap.goToCurrentLocation")}</Button>,
                            <Button color="blue" onClick={() => this.props.dispatchers.exportSeriesAsCSV()}><Icon name='download' />{_msg("dto_crud.export")}</Button>,
                            ]}
                            getNameAndValue={(data) => {
                                if (this.props.multipleVehicles) {
                                    let position = this.props.tree.root[data.serieId].positions[this.props.dispatchers.computeGpsPositionKey(data.id)];
                                    return { nameSerie: this.props.chart.scaleId!, valueSerie: position[this.props.chart.scaleId!] };
                                } else {
                                    let position = this.props.tree.root.positions[this.props.dispatchers.computeGpsPositionKey(data.id)];
                                    let itemValue: any;
                                    const telemetryCode = "," + data.serieId + ":";
                                    if (position.telemetry?.indexOf(telemetryCode) > -1) {
                                        itemValue = position.telemetry.split(telemetryCode)[1].split(",")[0];
                                    } else {
                                        itemValue = position[data.serieId];
                                    }
                                    return { nameSerie: data.serieId, valueSerie: itemValue }
                                }

                            }}
                            isPointIdOnTrack={(pointId, trackId, serieId) => {

                                let value = undefined;
                                if (this.props.multipleVehicles) {
                                    value = this.props.tree.root[serieId].positions[this.props.dispatchers.computeGpsPositionKey(pointId)];
                                } else {
                                    value = this.props.tree.root.positions[this.props.dispatchers.computeGpsPositionKey(pointId)];
                                }
                                return value !== undefined && value.trackId === trackId;
                            }} />
                    </div>
                </SplitPane>
            </SplitPane>
            {this.renderVideoPart()}
            </>
        );
    }

    protected listGpsPositions() {
        const props = this.props;
        if (!props.entityDescriptor) {
            return (<></>);
        }

        return (
            <EntityTableSimple screen={entityDescriptors[props.entityDescriptor].name} {...props.tableSimple} dispatchers={props.dispatchers.tableSimple}
                entityDescriptor={entityDescriptors[props.entityDescriptor]}
            />
        );
    }

    protected renderVideoPart(){
        if (!this.props.videos || this.props.map.selectedLayer?.type !== VIDEO_TYPE){
            return null
        }
        const currentKey = this.props.map.selectedLayer?.id;
        return (<>
        <ModalExt open={this.props.showVideoModal} style={{ width: '95%', height: '95%', background: 'black' }} onClose={() => this.props.dispatchers.setInReduxState({ showVideoModal: false })} >
            <Modal.Content className="wh100">
                <GalleryMedia data={processVideosToGalleryImagesData(this.props.videos, this.props.map.selectedLayer?.id.toString())} loading={false} video={true} 
                extraInfo={this.props.videos && currentKey ?
                    this.props.videos[currentKey][0].eventType + " " + moment(this.props.videos[currentKey][0].date).format(Utils.dateTimeWithSecFormat)
                    : undefined}/>
            </Modal.Content>
        </ModalExt>
        </>
        )
    }

    protected renderSmallMapPopup = (data: any, type: string, additionalInfo?: { pointId?: ID }) => {
        if (type === EQUIPMENT_RESOURCE_TYPE) {
            return this.props.equipmentResource ? EquipmentResourceUtils.renderSmallInfoArea(this.props.equipmentResource, this.props.mapSettings!) : <></>;
        }
        if (type === TRACK_TYPE) {
            data = data as PolylineData;
            let point;
            if (additionalInfo?.pointId) {
                const gpsPositionKey = this.props.dispatchers.computeGpsPositionKey(additionalInfo.pointId);
                if (this.props.multipleVehicles) {
                    for (let key of Object.keys(this.props.tree.root)) {
                        point = this.props.tree.root[key].positions[gpsPositionKey];
                        if (point !== undefined) {
                            break;
                        }
                    }
                } else {
                    point = this.props.tree.root.positions[gpsPositionKey];
                }
            }
            return (
                <React.Fragment>
                    <div style={{ textAlign: "center" }}>
                        {formatTimeS(point?.date)}
                    </div>
                </React.Fragment>
            )
        }

        if (type == VIDEO_TYPE){
            return <div className="flex-container">{data.text}</div>;
        }

        return <></>;
    }

    protected renderBigMapPopup = (data: any, type: string, point?: Location): React.ReactElement => {
        if (type === EQUIPMENT_RESOURCE_TYPE) {
            return this.props.equipmentResource ? EquipmentResourceUtils.renderSmallInfoArea(this.props.equipmentResource, this.props.mapSettings!) : <></>;
        }
        if (type === TRACK_TYPE) {
            const trackId = (data as PolylineData).id as string;
            const object = Utils.navigate(this.props.tree.root, trackId, false) as TrackDto;
            if (object === undefined) {
                return <></>;
            }
            const index = this.props.tree.linearizedItems.findIndex(e => e.itemId === trackId);
            let nextObject = this.props.dispatchers.tree.navigateToItem({ index: index + 1 });
            if (nextObject != null && nextObject.type !== TreeItemType.TRACK_SEGMENTED) {
                // pass nextObject only if it is track
                nextObject = null;
            }
            return this.getTrackSegmented(trackId, false, object, nextObject);
        }
        return <></>;
    }

    protected renderMarkerIcon = (markerData: MarkerData, type: string): React.ReactNode => {
        if (type == VIDEO_TYPE){
            return <span id={markerData.id?.toString()} className='fa fa-stack fa-lg'><i className={'fa-solid fa-video-camera fa-stack-1x'} ></i></span>   
        }

        if (this.props.equipmentResource && this.props.mapSettings) {
            const markerSettings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(this.props.mapSettings);
            return EquipmentResourceUtils.renderEquipmentResourceIcon(this.props.equipmentResource, markerData, markerSettings);
        }
    }

    protected getVehicleIcon() {
        const props = this.props;
        return props.equipmentResource?.equipmentType && props.equipmentResource.equipmentType.icon
            ? <img className="EqRes_mapIcon_svg EqRes_icon_svg" src={
                (props.equipmentResource.equipmentType.icon as string).endsWith(".svg")
                    ? props.equipmentResource.equipmentType.icon
                    : (eqImages256x256[props.equipmentResource.equipmentType.icon] || getIcon(props.equipmentResource.equipmentType.icon)?.image.src)} alt={props.equipmentResource.equipmentType.icon}></img>
            : null;
    }

    protected renderTreeItem = ({ props, linearizedItem }: RenderItemParams) => {
        const object = Utils.navigate(props.root, linearizedItem.itemId);

        switch (object.type) {
            case TreeItemType.TRACK_SEGMENTED:
                let nextObject = this.props.dispatchers.tree.navigateToItem({ index: linearizedItem.index + 1 });
                if (nextObject != null && nextObject.type !== TreeItemType.TRACK_SEGMENTED) {
                    // pass nextObject only if it is track
                    nextObject = null;
                }
                return this.getTrackSegmented(linearizedItem.itemId, true, object, nextObject);
            case TreeItemType.TRACK_MISSION:
                return this.getMissionInfo(object);
            case TreeItemType.USAGE_LOG:
                return this.getUsageLogInfo(object);
            case TreeItemType.VEHICLE:
                return (<>
                    {this.getVehicleIcon()}&nbsp;
                    <span style={{ color: object.color ? object.color : '#808080' }}><b>{object.identifier}</b></span>
                    <Label size="mini" circular style={{ color: object.color ? object.color : '#808080' }}>{object.equipmentType.name}</Label>
                    <Label color={object.nbTracks > 0 ? "teal" : "red"} size="tiny" circular>{object.nbTracks ? object.nbTracks : "0"}</Label>
                </>);
            case TreeItemType.TRACKS:
                return (<>{_msg("HistoricalMap.tracks")}</>);
            case TreeItemType.TASKS:
                return (<>{_msg("HistoricalMap.tasks")}</>);
            case TreeItemType.EQUIPMENT_USAGE_LOGS:
                return (<>{_msg("HistoricalMap.equipmentUsageLog")}</>);
            case TreeItemType.SPINNER:
                return (<Header as="h4" /* color="grey" */ textAlign="center" icon style={{ paddingTop: "10px" }}>
                    <Icon name='spinner' loading />
                </Header>);
            default:
                return null;
        }
    }

    protected getTrackSegmented = (id: string, activePopup: boolean, currentItem: any, nextItem?: any): React.ReactElement => {
        const distance = distanceBetweenTracks(currentItem, nextItem);

        // also use currentItem.historyTrack.lastPoint.date in case we are still in progress
        return (
            <React.Fragment>
                <div style={{ paddingBottom: "5px" }}>
                    {this.getItemPopup(id, currentItem.historyTrack, false, getDuration(currentItem.historyTrack.startTime, currentItem.historyTrack.endTime ? currentItem.historyTrack.endTime : currentItem.lastPoint?.date), 0, activePopup)}
                    {currentItem.endTerritories?.map((x: any) => <Label key={id + x.label} basic content={x.label} style={{ backgroundColor: x.color ? Utils.hexToRGB(x.color) : null, padding: "5px" }} />)}
                    {currentItem.uniqueTrack && <Label circular color="teal" empty key="teal" />}
                </div>
                <div>
                    {this.getItemPopup(id, currentItem.historyTrack, true, nextItem ? getDuration(nextItem.historyTrack.endTime, currentItem.historyTrack.startTime) : "", distance, activePopup)}
                    {currentItem.startTerritories?.map((x: any) => <Label key={id + x.label} basic content={x.label} style={{ backgroundColor: x.color ? Utils.hexToRGB(x.color) : null, padding: "5px" }} />)}
                </div>
            </React.Fragment>
        )
    }

    protected getItemPopup = (id: string, item: HistoryTrack, isStop: boolean, duration: string, distance: number, activePopup: boolean) => {
        const isQuestion = distance > MAX_DISTANCE_BETWEEN_TRACKS;
        const color = isStop ? (isQuestion ? TrackColor.QUESTION : TrackColor.STOP) : TrackColor.MOVEMENT;
        const icon = isStop ? (isQuestion ? "question" : "stop") : "play";
        const detail =
            <React.Fragment>
                <Label color={color} icon={icon} content={(isStop ? (isQuestion ? _msg("HistoricalMap.probablyStopped", duration) : _msg("HistoricalMap.stopped", duration)) : _msg("HistoricalMap.movement", duration))} />
                {isQuestion ?
                    <div>
                        <b>{_msg("HistoricalMap.note")}:</b> {_msg("HistoricalMap.missingTelemetry")}
                        <br />
                        <b>{_msg("HistoricalMap.distance")}:</b> {distance.toFixed(2)} m
                </div> : null}
            </React.Fragment>;

        const info = formatTime(isStop ? item.startTime : item.endTime);

        return (
            <Popup
                open={this.props.popup && this.props.popup.id === id && this.props.popup.isStop === isStop}
                onOpen={() => this.props.dispatchers.setInReduxState({ popup: { id, isStop } })}
                onClose={() => this.props.dispatchers.setInReduxState({ popup: undefined })}
                closeOnEscape
                disabled={!activePopup || duration === ""}
                content={
                    <React.Fragment>
                        <div style={{ textAlign: "center" }}>{formatDate(isStop ? item.startTime : item.endTime)}</div>
                        <Divider />
                        {detail}
                    </React.Fragment>
                }
                trigger={
                    <span onClick={(e: React.MouseEvent<HTMLElement>) => { e.stopPropagation(); e.preventDefault(); }}>
                        {isStop ? <>&nbsp;<Icon name={TreeIcon.POINT} /></> : this.getVehicleIcon()}&nbsp;
                        {!activePopup || duration === "" ? info : <a href="/#" style={{ textDecoration: "underline", opacity: "1", color: "black" }}>{info}</a>}
                        {duration === "" ? null : <Label color={color} icon={icon} style={{ padding: "5px" }} content={activePopup ? <a href="/#" style={{ textDecoration: "underline", opacity: "1" }}>{duration}</a> : duration} />}
                    </span>}
            />
        )
    }

    protected getMissionInfo(currentItem: MissionDto) {
        return (<React.Fragment><List><List.Item key={currentItem.id}>
            <List.Icon name={currentItem.inactivityType ? 'clipboard' : 'plane'} verticalAlign='middle' />
            <List.Content>
                <List.Header as='a'>
                    {formatTime(currentItem.startTime) + "-" + formatTime(currentItem.endTime)}
                </List.Header>
                <List.Description as='a'>
                    {currentItem.flights?.map(x =>
                        <Label key={x.name} size='small' color={x.departure ? TrackColor.MOVEMENT : TrackColor.STOP} content={x.name} icon={"plane"} />
                    )}
                    {currentItem.inactivityType &&
                        <Label content={currentItem.inactivityType?.name} icon="coffee" />
                    }
                </List.Description>
            </List.Content></List.Item></List>
        </React.Fragment>);
    }

    protected getUsageLogInfo(currentItem: EquipmentUsageLogs) {
        return (<React.Fragment><List><List.Item key={currentItem.id}>
            <List.Icon name={currentItem.changedBy === "DRIVER" ? "user" : "id card"} verticalAlign='middle' />
            <List.Content>
                <List.Header as='a'>
                    {formatTime(currentItem.startDate)}
                </List.Header>
                <List.Description as='a'>
                    <Label content={currentItem.driver?.firstName + " " + currentItem.driver?.lastName} /> by {currentItem.changedBy}
                </List.Description>
            </List.Content></List.Item></List>
        </React.Fragment>);
    }
}

export const infoHistoricalMap = new ConnectedPageInfo(sliceHistoricalMap, HistoricalMap, "HistoricalMap");
infoHistoricalMap.mapBigStateToProps = (state: BigState, props: Props) => {
    props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;
}
