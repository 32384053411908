import { Formik } from "formik";
import React from "react";
import { EntityEditorFormSimple, EntityEditorFormSimpleProps } from "./EntityEditorFormSimple";
import { EntityEditorPage } from "./EntityEditorPage";

export interface CrudFormInEditorProps extends EntityEditorFormSimpleProps {
    editor: EntityEditorPage
}

/**
 * This is a `CrudForm` that is contained by `CrudEditor`. Hence `CrudForm`
 * knows its parent editor, so that it can act accordingly.
 */
export class CrudFormInEditor extends EntityEditorFormSimple<CrudFormInEditorProps> {
    render() {
        const props = this.props;
        return (<Formik innerRef={this.formik as any} initialValues={props.entity} enableReinitialize onSubmit={this.onSubmit}>
            {props.editor.props.duplication ? this.renderFormikChildDuplicate : this.renderFormikChild}
        </Formik>);
    }
}