import React from "react";
import { ScaleToContainer } from "@crispico/foundation-react/utils/Utils";

export interface FreeTextWidgetConfig {
    text: string | undefined,
    fontSize?: string
}

type Props = { config: FreeTextWidgetConfig };

export class FreeTextWidget extends React.Component<Props> {
    render() {
        return <div className="flex-container flex-grow  no-margin FieldWidget_lineHeight">
            <ScaleToContainer className="FreeTextWidget flex-center" fixedFontSize={this.props.config.fontSize}>{this.props.config.text}</ScaleToContainer>
        </div>

    }
}
