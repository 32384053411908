import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import gql from "graphql-tag";
import lodash, { isObject } from "lodash";
import moment from "moment";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Grid, GridColumn, GridRow, Header, Icon, Label, Popup, Segment } from "semantic-ui-react";
import { auditEntityDescriptor } from "../auditEntityDescriptor";
import { ActionName, AuditUtils, TRUE_VALUES, VERSION } from "../AuditUtils";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";

export enum Direction { FROM = "from", TO = "to" };

interface ISecondaryPopupValues {
    currentOldValues?: any,
    currentNewValues?: any,
    previousOldValues?: any,
    previousNewValues?: any
}

export const sliceAuditedFieldDetails = createSliceFoundation(class SliceAuditedFieldDetails {
    initialState = {
        entity: {} as any,
        fieldName: "",
        openForSelector: "",
        openLabelChangedPopup: "",
        secondaryPopupValues: {} as ISecondaryPopupValues
    }

    reducers = {
        ...getBaseReducers<SliceAuditedFieldDetails>(this),
    }

    impures = {
        ...getBaseImpures<SliceAuditedFieldDetails>(this),

        async getPopupCurrentValues(entityDescriptor: EntityDescriptor, newEntityId: Number, oldEntityId: Number) {
            const loadOperationName = `${lodash.lowerFirst(entityDescriptor.name)}Service_findById`;
            const loadQuery = gql(`query q($id: Long) { 
                ${loadOperationName}(id: $id) {
                    ${ID} ${entityDescriptor.miniFields.join(" ")} ${VERSION}
                }
            }`);

            //first time we look in the entity for to the current data and in case it does not exist we look in the Audit
            let oldValues = (await apolloClientHolder.apolloClient.query({ query: loadQuery, variables: { id: oldEntityId ? oldEntityId : 0 } })).data[loadOperationName];
            const newValues = (await apolloClientHolder.apolloClient.query({ query: loadQuery, variables: { id: newEntityId ? newEntityId : 0 } })).data[loadOperationName];

            let currentOldValues;
            let currentNewValues;

            if (oldEntityId) {
                if (oldValues) {
                    currentOldValues = { id: oldEntityId, name: entityDescriptor.toMiniString(oldValues), version: oldValues.version };
                } else {
                    let oldValues = (await this.getAuditOldValue(entityDescriptor, oldEntityId, Utils.now(), false));
                    currentOldValues = oldValues && { id: oldEntityId, name: this.getEntityMiniFieldsFromAuditRow(entityDescriptor, oldValues), version: oldValues.version };
                }
            }

            if (newEntityId) {
                if (newValues) {
                    currentNewValues = { id: newEntityId, name: entityDescriptor.toMiniString(newValues), version: newValues.version };
                } else {
                    let newValues = (await this.getAuditOldValue(entityDescriptor, newEntityId, Utils.now(), false));
                    currentNewValues = newValues && { id: newEntityId, name: this.getEntityMiniFieldsFromAuditRow(entityDescriptor, newValues), version: newValues.version };
                }
            }

            this.getDispatchers().setInReduxState({ secondaryPopupValues: { ...this.getState().secondaryPopupValues, currentOldValues, currentNewValues } });
        },
        async getPopupPreviousValues(entityDescriptor: EntityDescriptor, newEntityId: Number, oldEntityId: Number, date: Date) {
            const oldResult = oldEntityId && (await this.getAuditOldValue(entityDescriptor, oldEntityId, date, true));
            const newResult = newEntityId && (await this.getAuditOldValue(entityDescriptor, newEntityId, date, true));

            const previousOldValues = oldResult && { id: oldEntityId, name: this.getEntityMiniFieldsFromAuditRow(entityDescriptor, oldResult), date: oldResult.date, version: oldResult.version }
            const previousNewValues = newResult && { id: newEntityId, name: this.getEntityMiniFieldsFromAuditRow(entityDescriptor, newResult), date: newResult.date, version: newResult.version }

            this.getDispatchers().setInReduxState({ secondaryPopupValues: { ...this.getState().secondaryPopupValues, previousNewValues, previousOldValues } });
        },
        getEntityMiniFieldsFromAuditRow(entityDescriptor: EntityDescriptor, entity: { auditableEntity: number, auditableField: number[], oldValue: any[] }): string {
            let value = "";
            for (let field of entityDescriptor.miniFields) {
                if (entity?.auditableField && entity.oldValue) {
                    const ed = EntityDescriptorForServerUtils.getEntityDescriptor(entity.auditableEntity)!;
                    const fieldMappingId = EntityDescriptorForServerUtils.getFieldId(ed.name, field);
                    value += value !== "" ? " " : "" + entity.oldValue[entity.auditableField.findIndex((x: any) => x === fieldMappingId)];
                }
            }
            return value;
        },
        processAuditInsertedDeletedValue(entity: any): any {
            let entityValue: any = {};
            if (entity.oldValue !== "") {
                entityValue = JSON.parse(entity.oldValue);

                entity.oldValue = Object.values(entityValue);
                entity.newValue = [];
            } else {
                entityValue = JSON.parse(entity.newValue);

                entity.oldValue = [];
                entity.newValue = Object.values(entityValue);
            }

            entity.auditableField = Object.keys(entityValue);

            return entity;
        },
        async getAuditOldValue(entityDescriptor: EntityDescriptor, entityId: Number, date: Date, firstRow: boolean):
            Promise<Optional<{ id: number, date: Date, auditableEntity: number, auditableField: number[], oldValue: any[], version?: string }>> {

            const auditableEntityFilter = AuditUtils.getFilterForAuditableEntity(entityDescriptor.name);
            const sorts = [{ field: "date", direction: firstRow ? "ASC" : "DESC" }]
            let filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [
                auditableEntityFilter,
                Filter.create("entityId", FilterOperators.forNumber.equals, entityId.toString()),
                Filter.create("date", firstRow ? FilterOperators.forDate.greaterThan : FilterOperators.forDate.lessThan, moment(date).toISOString())
            ]);

            const loadOperationName = `${lodash.lowerFirst(auditEntityDescriptor.name)}Service_findByFilter`;
            const query = gql(`query q($params: FindByFilterParamsInput) { 
                ${loadOperationName}(params: $params) {
                    results { auditableField oldValue date }
                }
            }`);

            let result = (await apolloClientHolder.apolloClient.query({ query, variables: FindByFilterParams.create().pageSize(1).filter(filter).sorts(sorts) })).data[loadOperationName];

            if (result.results.length === 0) {
                return null;
            }

            let entity = result.results[0];
            if ([ActionName.UPDATE, ActionName.REFRESH].includes(entity.action)) {
                filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [
                    auditableEntityFilter,
                    Filter.create("entityId", FilterOperators.forNumber.equals, entityId.toString()),
                    Filter.create("date", FilterOperators.forDate.greaterThanOrEqualTo, moment(entity.date).toISOString()),
                    Filter.create("date", FilterOperators.forDate.lessThanOrEqualTo, moment(entity.date).toISOString())
                ]);
                result = (await apolloClientHolder.apolloClient.query({ query, variables: { filter, sorts: null, pageSize: -1 } })).data[loadOperationName];

                if (result.results.length === 0) {
                    return null;
                }

                entity = { ...entity, auditableField: [], oldValue: [] }
                for (const row of result.results) {
                    entity.auditableField.push(row.auditableField);
                    entity.oldValue.push(row.oldValue);
                }
            } else {
                entity = this.processAuditInsertedDeletedValue(entity);
            }

            return entity;
        }
    }
})

export class AuditedFieldDetails extends React.Component<PropsFrom<typeof sliceAuditedFieldDetails>> {

    constructor(props: PropsFrom<typeof sliceAuditedFieldDetails>) {
        super(props);
        this.onLabelChangedPopupClose = this.onLabelChangedPopupClose.bind(this);
        this.onPopupClose = this.onPopupClose.bind(this);
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceAuditedFieldDetails>) {
        if (this.props.openForSelector === "" || prevProps.openForSelector === this.props.openForSelector) {
            return;
        }

        const ed = EntityDescriptorForServerUtils.getEntityDescriptor(this.props.entity.auditableEntity)!;
        const fieldDescriptor = ed.getFieldDescriptorChain(this.props.fieldName)?.[0];

        if (!fieldRenderers[fieldDescriptor?.type]) {
            const innerEntityDescriptor = entityDescriptors[fieldDescriptor?.type];
            if (innerEntityDescriptor) {
                const fieldMappingId = EntityDescriptorForServerUtils.getFieldId(ed.name, this.props.fieldName);
                const fieldPosition = (this.props.entity.auditableField as number[])?.findIndex(fieldId => fieldId === fieldMappingId)

                this.props.dispatchers.getPopupCurrentValues(innerEntityDescriptor, Number.parseInt(this.props.entity.newValue[fieldPosition]), Number.parseInt(this.props.entity.oldValue[fieldPosition]));
                this.props.dispatchers.getPopupPreviousValues(innerEntityDescriptor, Number.parseInt(this.props.entity.newValue[fieldPosition]), Number.parseInt(this.props.entity.oldValue[fieldPosition]), this.props.entity.date);
            }
        }
    }

    onPopupClose() {
        this.props.dispatchers.setInReduxState({ openForSelector: "" });
    }

    onLabelChangedPopupClose = (value: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
        this.props.dispatchers.setInReduxState({ openLabelChangedPopup: value });
    }

    getSecondaryPopupRenderer(currentProps: FieldRendererProps & { secondaryPopupPreviousValues: any, direction: Direction }) {
        if (!currentProps.secondaryPopupPreviousValues) {
            return null;
        }

        const secondaryPopupContent =
            <span>
                <Header as='h3' attached='top'>
                    <b>Entity </b>
                    <NavLink to={currentProps.innerEntityDescriptor!.getEntityEditorUrl(currentProps.value[ID])}>
                        <Label basic horizontal color="blue">
                            {typeof currentProps.innerEntityDescriptor!.icon === 'string' ? <Icon name={currentProps.innerEntityDescriptor!.icon} /> : currentProps.innerEntityDescriptor!.icon}
                            {this.getRowValue(currentProps)} <Icon name="share" />
                        </Label>
                    </NavLink>
                </Header>
                <Segment attached>
                    Label changed meanwhile
                    <Segment>
                        {this.getPopupDetailRender({ ...currentProps, value: currentProps.secondaryPopupPreviousValues, detail: <>at <Label basic horizontal>{moment(currentProps.secondaryPopupPreviousValues.date).format(Utils.dateTimeFormat)}</Label>{currentProps.secondaryPopupPreviousValues.version ? <>at <Label basic horizontal>v{currentProps.secondaryPopupPreviousValues.version}</Label></> : ""}label was </> })}
                    </Segment>
                    <Segment>
                        {this.getPopupDetailRender({ ...currentProps, detail: <>now {currentProps.value.version ? <>at <Label basic horizontal>v{currentProps.value.version}</Label></> : ""}label is </> })}
                    </Segment>
                </Segment>
            </span>;

        const secondaryPopupTrigger = <Label basic horizontal color="orange">Label changed</Label>;

        return (
            <Popup wide="very" className={"BringComponentToFront"}
                //on="click"
                open={this.props.openLabelChangedPopup === currentProps.direction}
                onOpen={this.onLabelChangedPopupClose(currentProps.direction)}
                onClose={this.onLabelChangedPopupClose("")}
                content={secondaryPopupContent}
                trigger={secondaryPopupTrigger}
            />
        )
    }

    getPopupDetailRender(props: FieldRendererProps & { detail: ReactNode }) {
        return <>
            {props.detail ? props.detail : <>{this.getRowValue(props)}<br /></>}
            <NavLink to={props.innerEntityDescriptor!.getEntityEditorUrl(props.value[ID])}>
                {<span>
                    {typeof props.innerEntityDescriptor!.icon === 'string' ? <Icon name={props.innerEntityDescriptor!.icon} /> : props.innerEntityDescriptor!.icon}
                    {props.innerEntityDescriptor!.toMiniString(props.value)} <Icon name="share" /></span>}
            </NavLink>
        </>;
    }

    getRowValue(props: FieldRendererProps) {
        return props.innerEntityDescriptor ? props.innerEntityDescriptor.getLabel() + "/" + (isObject(props.value) ? (props.value as any)[ID] : props.value) : props.value;
    }

    customManyToOneRenderer: ReactNode = (props: FieldRendererProps & { detail: ReactNode, secondaryPopupPreviousValues: any, direction: Direction }) =>
        <Segment>
            {this.getPopupDetailRender(props)}
            {this.getSecondaryPopupRenderer(props)}
        </Segment>

    getPrimaryPopupLabel(fieldRenderer: any, fieldProps: FieldRendererProps & { entity: any, fieldPosition: number }, direction: Direction) {
        const secondaryPopupValues: ISecondaryPopupValues = this.props.secondaryPopupValues;

        let value =
            direction === Direction.FROM ?
                fieldProps.innerEntityDescriptor && secondaryPopupValues?.currentOldValues ? secondaryPopupValues.currentOldValues : fieldProps.entity.oldValue[fieldProps.fieldPosition]
                : fieldProps.innerEntityDescriptor && secondaryPopupValues?.currentNewValues ? secondaryPopupValues.currentNewValues : fieldProps.entity.newValue[fieldProps.fieldPosition];

        if (!value && ![ActionName.UPDATE, ActionName.REFRESH].includes(fieldProps.entity.action)) {
            return null;
        }

        if (fieldProps.fieldDescriptor.type === FieldType.boolean) {
            value = TRUE_VALUES.includes(value);
        }

        const secondaryPopupPreviousValues = fieldProps.innerEntityDescriptor ? (direction === Direction.FROM ? secondaryPopupValues?.previousOldValues : secondaryPopupValues?.previousNewValues) : undefined

        return (
            <GridRow className="AuditTablePage_grid_row">
                <GridColumn className="AuditTablePage_popup_grid_firstColumn">
                    Changed {direction}:
                </GridColumn>
                <GridColumn className="AuditTablePage_popup_grid_secondColumn">
                    {React.createElement(fieldRenderer, { ...fieldProps, value, secondaryPopupPreviousValues, direction })}
                </GridColumn>
            </GridRow>
        );
    }

    render() {
        const props = this.props;

        if (props.openForSelector === "") {
            return null;
        }

        const element = document.getElementsByClassName(props.openForSelector)[0] as HTMLElement;

        if (!element) {
            return null;
        }
        const ed = EntityDescriptorForServerUtils.getEntityDescriptor(props.entity.auditableEntity)!;
        let fieldDescriptor = ed.getFieldDescriptorChain(props.fieldName)[0];
        if (!fieldDescriptor) {
            fieldDescriptor = new FieldDescriptor();
            fieldDescriptor.name = props.fieldName;
            fieldDescriptor.type = FieldType.defaultScalar;
        }

        const fieldMappingId = EntityDescriptorForServerUtils.getFieldId(ed.name, props.fieldName);
        const fieldPosition = (props.entity.auditableField as number[])?.findIndex(fieldId => fieldId === fieldMappingId)

        let fieldProps: FieldRendererProps & { entity: any, fieldPosition: number } = { value: undefined, fieldDescriptor, entity: props.entity, fieldPosition };
        let fieldRenderer = fieldRenderers[fieldDescriptor?.type];
        if (!fieldRenderer) {
            const innerEntityDescriptor = entityDescriptors[fieldDescriptor?.type];
            if (innerEntityDescriptor) {
                fieldProps.innerEntityDescriptor = innerEntityDescriptor;
                fieldRenderer = this.customManyToOneRenderer;
            } else {
                fieldRenderer = fieldRenderers[FieldType.defaultScalar];
            }
        }

        const popupContent =
            <div>
                <Header as='h3' attached='top'>Field {<Label basic horizontal color="blue">{props.fieldName}</Label>}</Header>
                <Segment attached>
                    <Grid>
                        {this.getPrimaryPopupLabel(fieldRenderer, fieldProps, Direction.FROM)}
                        {this.getPrimaryPopupLabel(fieldRenderer, fieldProps, Direction.TO)}
                    </Grid>
                </Segment>
            </div>

        return <Popup on="click" position="top right" wide="very" content={popupContent} open={props.openForSelector !== ""} context={element} onClose={this.onPopupClose} closeOnEscape />
    }
}