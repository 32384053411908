import { cleanup } from "@testing-library/react/pure";
import React, { ReactNode, useEffect, useState } from "react";
import ReactFloater from "react-floater";
import { Divider, Label, Modal } from "semantic-ui-react";
import { ModalExt } from "../components/ModalExt/ModalExt";
import { StepByStepMode, TestsAreDemoMaster } from "./TestsAreDemoMaster";
import Markdown from "markdown-to-jsx";
import chai from "chai";
import chaiSubset from "chai-subset";

/**
 * Made a data structure (instead of a simple message) because maybe in the future we'll
 * want to have additional graphical params, e.g. color, etc.
 */
export interface SpotlightParams {
    message?: ReactNode;
    focusOnLastElementCaptured: boolean;
}

interface SlaveState {
    spotlightParams?: SpotlightParams
}

interface SlaveProps {
    /**
     * We force the tests to be imported async (via this callback), to make sure that they are imported only by the "slave".
     * Importing tests in "master" or in normal app, would trigger importing from "instrumentedFunctions", which throws error
     * if not "slave" mode.
     */
    importTestsCallback: () => Promise<any>
}

/**
 * Runs the tests (via `mocha.run()`) and displays the spotlight.
 */
export class TestsAreDemoSlave extends React.Component<SlaveProps, SlaveState> {

    protected static _INSTANCE: TestsAreDemoSlave;

    state: SlaveState = {};

    protected master!: TestsAreDemoMaster;

    /**
     * I don't store this in the state, because it's a high traffic item. And I'm affraid
     * that the state is proxified (e.g. to output warnings in the dev mode), hence slower.
     */
    stepByStep = false;

    /**
     * Idem as `stepByStep`. W/ the difference that there it's a precaution. And here is a certitude,
     * because the access to this attribute is in write mode. So every set would generate additional logic
     * being executed.
     */
    lastElementCaptured?: HTMLElement;

    static mochaInitialized = false;
    protected runRequestedWhileMochaInitializing = false;

    currentComment: ReactNode;
    currentCommentSlideIndex?: number;

    /**
     * After the execution, either the connection to the parent window / TestsAreDemoSlave is correctly
     * established or an error is thrown.
     */
    componentDidMount() {
        // @see TestsAreDemoMaster.componentDidMount(); curious thing though, the double invocation didn't happen here as well
        if (TestsAreDemoSlave._INSTANCE) {
            throw new Error("Cannot create more than one instances of TestsAreDemoSlave");
        }
        TestsAreDemoSlave._INSTANCE = this;

        if (!window.parent?.testsAreDemoMaster) {
            throw new Error("Looking at the parent of this IFrame to find TestsAreDemoMaster. But was not found.");
        }
        this.master = window.parent.testsAreDemoMaster;
        this.master.slave = this;

        this.initMocha();
    }

    protected async initMocha() {
        // for a "normal" import, @ts-ignore wasn't needed; however it seems to be needed here
        // @ts-ignore
        await import("script-loader!mocha/mocha-es2018"); // eslint-disable-line import/no-webpack-loader-syntax
        const { ReporterFromSlaveToMaster } = await import("./ReporterFromSlaveToMaster");
        mocha.setup({
            ui: "bdd", // I don't exactly known what this is; but is needed
            timeout: 1000000, // for the moment a high value, for the "step by step case"
        });

        const that = this;
        mocha.reporter(class extends ReporterFromSlaveToMaster {
            log(what: string): void {
                super.log(what);
                that.master.log(what);
            }
        });

        // @ts-ignore 
        mocha.cleanReferencesAfterRun(false); // needed to be able to rerun the tests
        // This doesn't work. I didn't look if we could do something or not.
        // mocha.checkLeaks();

        // Configure global "hooks" for mocha

        beforeEach(() => {
            cleanup();
        });

        TestsAreDemoSlave.mochaInitialized = true;

        await this.props.importTestsCallback();

        // copied from common.test.ts
        chai.use(chaiSubset);

        if (this.runRequestedWhileMochaInitializing) {
            this.run();
        }
    }

    static get INSTANCE() {
        if (!TestsAreDemoSlave._INSTANCE) {
            throw new Error("There is no TestsAreDemoSlave sigleton available. This might happen if somehow you run tests that are NOT in the IFrame of TestsAreDemoMaster (i.e. in TestsAreDemoSlave)");
        }
        return TestsAreDemoSlave._INSTANCE;
    }

    run() {
        // this probably happens if "auto run" is checked
        if (!TestsAreDemoSlave.mochaInitialized) {
            this.runRequestedWhileMochaInitializing = true;
            return;
        }
        mocha.run(() => {
            this.master.setState({ running: false });
            this.master.readStepByStep(); // because maybe clicked on "Run normally", which changed the attribute, but not local storage
        });
    }

    showSpotlight(spotlightParams: SpotlightParams, finishWaitingCallback: Function) {
        this.master.enableNextStepButton(finishWaitingCallback);
        this.setState({ spotlightParams });
    }

    hideSpotlight() {
        this.setState({ spotlightParams: undefined });
    }

    protected renderSpotlight(spotlightRect: DOMRect, additionalClassNames: string = "") {
        const OFFSET = 5;
        spotlightRect.x -= OFFSET;
        spotlightRect.y -= OFFSET;
        spotlightRect.width += 2 * OFFSET;
        spotlightRect.height += 2 * OFFSET;

        return <div className={"TestsAreDemo_spotlight " + additionalClassNames} style={{ left: `${spotlightRect!.x}px`, top: `${spotlightRect!.y}px`, width: `${spotlightRect!.width}px`, height: `${spotlightRect!.height}px` }} />
    }

    public enableStepByStepProgrammatically() {
        if (this.master.state.stepByStepMode === StepByStepMode.OFF) {
            throw new Error("You are calling 'enableStepByStepProgrammatically()', but 'Step by step' is not 'Controlled by program'. This function is meant to be used temporarily; maybe you forgot to remove it?");
        } // else "controlled by program" or "on". For "on", this practically doesn't do anything
        this.stepByStep = true;
    }

    render() {
        const messageAndComment = <MessageAndComment tads={this} spotlightParams={this.state.spotlightParams} />;
        return <>
            <p>TestsAreDemoSlave</p>
            <div id="rtl" className="flex-container flex-grow" />
            <div id="mocha" />
            {this.state.spotlightParams && this.lastElementCaptured && this.state.spotlightParams.focusOnLastElementCaptured && <>
                <div className="TestsAreDemo_overlay">
                    {this.renderSpotlight(this.lastElementCaptured.getBoundingClientRect())}
                </div>
                <ReactFloater content={messageAndComment} open styles={{ container: { borderRadius: "4px" }, floater: { maxWidth: "800px" } }}
                    target={this.lastElementCaptured} // it accepts a class name also (e.g. for the spotlight), which would have been more convenient; however, when the spotlight moves, the popup doesn't follow
                />
            </>}
            {this.state.spotlightParams && (!this.lastElementCaptured || !this.state.spotlightParams.focusOnLastElementCaptured) && <>
                <ModalExt open={true}>
                    <Modal.Content>{messageAndComment}</Modal.Content>
                </ModalExt>
            </>}

        </>
    }
}

/**
 * `spotlightParams` is optional, because maybe the user only wants to show the comment.
 */
function MessageAndComment({ tads, spotlightParams }: { tads: TestsAreDemoSlave, spotlightParams?: SpotlightParams }) {
    const [comment, setComment] = useState<ReactNode>(null);
    const [lastSlideIndex, setLastSlideIndex] = useState<number | undefined>(undefined);
    useEffect(() => {
        if (typeof tads.currentComment === "string") {
            setComment(<Markdown>{tads.currentComment}</Markdown>);    
        } else {
            // an element
            setComment(tads.currentComment);
        }
        setLastSlideIndex(tads.currentCommentSlideIndex);

        // clear lastCapturedSlide after it's used; but we saved it in the state; so in case of
        // re-render, we'll still have the value
        tads.currentComment = undefined; 
        tads.currentCommentSlideIndex = undefined
    }, []);
    let message = spotlightParams?.message;
    // use divider only when message is not empty;
    // for example, if we capture a slide and we want to show only the content of that slide we don't have an
    // additional message
    const hasMessage = message ? true : false;
    if (typeof message === "string") {
        message = <Markdown>{message}</Markdown>
    } // else is an element
    return <>
        {lastSlideIndex && <Label circular color="brown" content={lastSlideIndex} />}
        {message}
        {comment &&
            <>
                {hasMessage && <Divider />}
                <p>{comment}</p>
            </>}
    </>
}