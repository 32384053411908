
import { expandPermissions, expandPermissionsVariables } from "@crispico/foundation-react/apollo-gen-foundation/expandPermissions";
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityEditorPage, SliceEntityEditorPage } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { EXPAND_PERMISSIONS } from "@crispico/foundation-react/pages/role/queries";
import { createSliceFoundation, DispatchersFrom, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { ALLOW, AUDIT_FULL, DEFAULT, DENY, ENTITY, ENT_DELETE, ENT_EXPORT, ENT_IMPORT, ENT_READ, ENT_SAVE, ENT_ADD, FIELDS_READ, FIELDS_WRITE, GLOBAL, SETTINGS_FULL, SCHEDULED_TASK_FULL, Utils, DELETE_ALL_FILTERED_ROWS_MENU_ENTRY, FILE_BROWSER, FILE_BROWSER_FULL, ENT_AUDIT, ENT_TABLE, ENT_EXTERNAL_LINK } from "@crispico/foundation-react/utils/Utils";
import Msg from "blockly/msg/en";
import lodash from "lodash";
import React from "react";
import { Accordion, AccordionPanelProps, Button, Container, Header, Icon, Label, Segment } from "semantic-ui-react";
import { sliceEntityEditorPageOnlyForExtension } from "../../entity_crud/EntityEditorPage";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";

const s = Utils.join;
const p = Utils.pipeJoin;

const globalKeys: string[] = [
    p([ENT_READ, DEFAULT]),
    p([ENT_SAVE, DEFAULT]),
    p([ENT_ADD, DEFAULT]),
    p([ENT_TABLE, DEFAULT]),
    p([ENT_DELETE, DEFAULT]),
    p([ENT_IMPORT, DEFAULT]),
    p([ENT_EXPORT, DEFAULT]),
    p([ENT_AUDIT, DEFAULT]),
    p([ENT_EXTERNAL_LINK, DEFAULT]),
    AUDIT_FULL,
    SETTINGS_FULL,
    SCHEDULED_TASK_FULL,
    DELETE_ALL_FILTERED_ROWS_MENU_ENTRY,
    FILE_BROWSER,
    FILE_BROWSER_FULL,
    s([GLOBAL, FIELDS_READ, DEFAULT]),
    s([GLOBAL, FIELDS_WRITE, DEFAULT])
];

const entityKeys = (entityName: string): string[] => {
    return [
        p([ENT_READ, entityName]), p([ENT_READ, entityName, DENY]),
        p([ENT_SAVE, entityName]), p([ENT_SAVE, entityName, DENY]),
        p([ENT_ADD, entityName]), p([ENT_ADD, entityName, DENY]),
        p([ENT_TABLE, entityName]), p([ENT_TABLE, entityName, DENY]),
        p([ENT_DELETE, entityName]), p([ENT_DELETE, entityName, DENY]),
        p([ENT_IMPORT, entityName]), p([ENT_IMPORT, entityName, DENY]),
        p([ENT_EXPORT, entityName]), p([ENT_EXPORT, entityName, DENY]),
        p([ENT_AUDIT, entityName]), p([ENT_AUDIT, entityName, DENY]),
        p([ENT_EXTERNAL_LINK, entityName]), p([ENT_EXTERNAL_LINK, entityName, DENY]),
        s([ENTITY, entityName, FIELDS_READ, DEFAULT, ALLOW]), s([ENTITY, entityName, FIELDS_READ, DEFAULT, DENY]),
        s([ENTITY, entityName, FIELDS_WRITE, DEFAULT, ALLOW]), s([ENTITY, entityName, FIELDS_WRITE, DEFAULT, DENY])]
}

const fieldKeys = (entityName: string, fieldName: string): string[] => {
    const fieldId = entityDescriptors[entityName]?.fields[fieldName]?.getId();   
    return [s([ENTITY, entityName, FIELDS_READ, fieldId]), s([ENTITY, entityName, FIELDS_WRITE, fieldId])]
}

export const sliceRoleEditorPage = createSliceFoundation(class SliceRoleEditorPage extends SliceEntityEditorPage {

    initialState = {
        ...sliceEntityEditorPageOnlyForExtension.initialState,
        selfCheckedPermissions: {} as { [key: string]: boolean },
        defaultCheckedPermissions: {} as { [key: string]: boolean },
    }

    reducers = {
        ...sliceEntityEditorPageOnlyForExtension.reducers,
        ...getBaseReducers<SliceRoleEditorPage>(this),

        updateSelfCheckedPermission(state: StateFrom<SliceRoleEditorPage>, p: { key: string }) {
            const isDefaultChecked = state.defaultCheckedPermissions[p.key];
            state.selfCheckedPermissions[p.key] = state.selfCheckedPermissions[p.key] === undefined ? !isDefaultChecked : !state.selfCheckedPermissions[p.key];

            const endsWithAllow = p.key.endsWith("." + ALLOW);
            const endsWithDeny = p.key.endsWith("." + DENY);

            if (endsWithAllow || endsWithDeny) {
                const parentKey = endsWithDeny ? p.key.slice(0, p.key.length - DENY.length - 1) : p.key.slice(0, p.key.length - ALLOW.length - 1);
                const oppositeKey = endsWithDeny ? s([parentKey, ALLOW]) : s([parentKey, DENY]);
                if (state.selfCheckedPermissions[oppositeKey] !== undefined) {
                    delete state.selfCheckedPermissions[oppositeKey];
                }
            }
        },

        deleteSelfCheckedPermission(state: StateFrom<SliceRoleEditorPage>, p: { key: string }) {
            delete state.selfCheckedPermissions[p.key];
        }
    }

    impures = {
        ...sliceEntityEditorPageOnlyForExtension.impures,
        ...getBaseImpures<SliceRoleEditorPage>(this),

        updatePermissions(key: string, deletePermission?: boolean) {
            if (deletePermission) {
                this.getDispatchers().deleteSelfCheckedPermission({ key: key });
            } else {
                this.getDispatchers().updateSelfCheckedPermission({ key: key });
            }

            const newPermissions = Object.keys(this.getState().selfCheckedPermissions).filter(p => p.startsWith(GLOBAL) ? this.getState().selfCheckedPermissions[p] === true : true).join(",");
            this.updateDefaultCheckedPermissions(newPermissions);
        },

        async updateDefaultCheckedPermissions(newPermissions: string) {
            const response = (await apolloClient.mutate<expandPermissions, expandPermissionsVariables>({ mutation: EXPAND_PERMISSIONS, variables: { permissions: newPermissions } })).data?.permissionService_expandPermissions!;
            const newDefaultCheckedPermissions = {} as { [key: string]: boolean };
            response.forEach(p => newDefaultCheckedPermissions[p] = true);

            this.getDispatchers().setInReduxState({ defaultCheckedPermissions: newDefaultCheckedPermissions });
        },

        saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
        async save(entity: any) {
            const permissions = Object.keys(this.getState().selfCheckedPermissions).filter(key => this.getState().selfCheckedPermissions[key] === true);
            const newPermissions = permissions.length > 0 ? permissions.join(",") : undefined;
            const response = await this.saveSuper({ ...entity, permissions: newPermissions ? newPermissions : null }, true, ["name", "permissions"]);

            return response;
        }

        
    }
});

export class RoleEditorPage extends EntityEditorPage<PropsFrom<typeof sliceRoleEditorPage> & { accordionActiveIndex?: number }> {

    private allPermissions: string[];

    constructor(props: any) {
        super(props);

        this.allPermissions = [];
        globalKeys.concat(...AppMetaTempGlobals.appMetaInstance.globalPermissions).forEach(key => this.allPermissions.push(key));

        Object.keys(AppMetaTempGlobals.appMetaInstance.getUIEntityDescriptors()).forEach((name: string) => {
            const ed = entityDescriptors[name];
            entityKeys(name).forEach(key => this.allPermissions.push(key));
            Object.keys(ed.fields).forEach(field => {
                fieldKeys(name, field).forEach(key => this.allPermissions.push(key));
            });
        });
    }

    componentDidMount() {
        this.props.dispatchers.setInReduxState({ selfCheckedPermissions: {} });
        this.props.dispatchers.setInReduxState({ defaultCheckedPermissions: {} });
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceRoleEditorPage>) {
        if (!lodash.isEqual(prevProps.entity, this.props.entity)) {
            const permissions: string[] | undefined = this.props.entity?.permissions?.split(",");
            if (permissions) {
                let selfCheckedPermissions = {} as { [key: string]: boolean };
                permissions.map(p => selfCheckedPermissions[p] = true);
                this.props.dispatchers.setInReduxState({ selfCheckedPermissions: selfCheckedPermissions });

                this.props.dispatchers.updateDefaultCheckedPermissions(this.props.entity.permissions);
            }
        }
    }

    private getLocalMessage(key: string) {
        if (key.startsWith("ENT_")) {
            // new permissions.
            const fields = key.split('|');
            return _msg("Role.global." + this.translatePermission(fields[0]) + ".default", _msg("Role." + ((fields.length > 2) ? fields[2] : "allow")));
        }
        return _msg("Role.global." + key.split('.')[2] + '.' + key.split('.')[3], _msg("Role." + key.split('.')[4]))
    }

    private translatePermission(newPerm: string) {
        let use: string = "";
        switch (newPerm) {
            case ENT_READ:
                use = "read"
                break;
            case ENT_TABLE:
                use = "tablePage";
                break;
            case ENT_SAVE:
                use = "editorPageModify";
                break;
            case ENT_ADD:
                use = "editorPageAdd";
                break;
            case ENT_DELETE:
                use = "editorPageDelete";
                break;
            case ENT_IMPORT:
                use = "tablePageImport";
                break;
            case ENT_EXPORT:
                use = "tablePageExport";
                break;
            case ENT_AUDIT:
                use = "editorPageShowAudit";
                break;
            case ENT_EXTERNAL_LINK:
                use = "editorPageShowExternalLink";
                break;
            default:
                use = newPerm;
                break;
        }
        return use;
    }

    public getGlobalMessage(key:string) {
        if (key === AUDIT_FULL || key === SCHEDULED_TASK_FULL || key === SETTINGS_FULL || key === DELETE_ALL_FILTERED_ROWS_MENU_ENTRY || key === FILE_BROWSER || key === FILE_BROWSER_FULL) {
            return this.specialPermissionsTranslation(key);
        } else if (AppMetaTempGlobals.appMetaInstance.globalPermissions.has(key)) {
            return _msg("Role.specific." + key);
        } else {
            return _msg("Role.global." + this.translatePermission(key.split("|")[0]) + ".default", "")
        }
    }

    private specialPermissionsTranslation(key:string) {
        let start:String = key;
        switch(key) {
            case AUDIT_FULL:
                start = "Audit";
                break;
            case SCHEDULED_TASK_FULL:
                start = "Scheduled";
                break;
            case SETTINGS_FULL:
                start = "Settings";
                break;
            case DELETE_ALL_FILTERED_ROWS_MENU_ENTRY:
                start = "DeleteFilteredRows";
                break;
            case FILE_BROWSER:
                return "FileBrowser : " + _msg("Role.one.fieldsRead");
            case FILE_BROWSER_FULL:
                start = "FileBrowser";
                break;
        }
        return start + " : " + _msg("Role.global.all");
    }

    private getPanels() {
        let panels = [] as AccordionPanelProps[];

        panels.push({
            key: GLOBAL, title: _msg("general.general"), content: {
                content: <>
                    {globalKeys.concat(...AppMetaTempGlobals.appMetaInstance.globalPermissions).map(key => <RolePermissionLabel permission={key} label={this.getGlobalMessage(key)} selfChecked={this.props.selfCheckedPermissions[key]} defaultChecked={this.props.defaultCheckedPermissions[key]} showDefaultLabel dispatchers={this.props.dispatchers} />)}
                </>
            }
        })
        Object.keys(AppMetaTempGlobals.appMetaInstance.getUIEntityDescriptors()).sort((a, b) => entityDescriptors[a].getLabel() < entityDescriptors[b].getLabel() ? -1 : (entityDescriptors[a].getLabel() > entityDescriptors[b].getLabel() ? 1 : 0)).forEach((name: string) => {
            // Eliminate audit since it is included in AUDIT_FULL
            if (name === "Audit") {
                return;
            }
            const ed = entityDescriptors[name];
            panels?.push({
                key: "ent." + name, title: ed.getLabel(), content: {
                    content: <>
                        {entityKeys(name).map(key => <RolePermissionLabel permission={key} label={this.getLocalMessage(key)}
                            selfChecked={this.props.selfCheckedPermissions[key]} defaultChecked={this.props.defaultCheckedPermissions[key]} showDefaultLabel dispatchers={this.props.dispatchers} />)}

                        <Accordion className="Role2EditorPage" exclusive={false} defaultActiveIndex={[0, 1]} styled fluid panels={[
                            {
                                key: "fieldsRead", title: _msg("Role.fieldsRead"), content: {
                                    content: <>{Object.keys(ed.fields).map(field => <RolePermissionLabel permission={fieldKeys(name, field)[0]} label={field}
                                        selfChecked={this.props.selfCheckedPermissions[fieldKeys(name, field)[0]]} defaultChecked={this.props.defaultCheckedPermissions[fieldKeys(name, field)[0]]}
                                        dispatchers={this.props.dispatchers} />)}</>
                                }
                            }, {
                                key: "fieldsWrite", title: _msg("Role.fieldsWrite"), content: {
                                    content: <>{Object.keys(ed.fields).map(field => <RolePermissionLabel permission={fieldKeys(name, field)[1]} label={field}
                                        selfChecked={this.props.selfCheckedPermissions[fieldKeys(name, field)[1]]} defaultChecked={this.props.defaultCheckedPermissions[fieldKeys(name, field)[1]]}
                                        dispatchers={this.props.dispatchers} />)}</>
                                }
                            }
                        ]} />
                    </>
                }
            });
        });
        return panels;
    }

    private getSelfCheckedPermissions() {
        const permissions = Object.keys(this.props.selfCheckedPermissions).filter(key => this.props.selfCheckedPermissions[key] === true);
        return permissions.length > 0 ? permissions : undefined;
    }

    private getMessage(key: string) {
        if (key.startsWith("ENT_")) {
            const parts: string[] = key.split("|");
            if (parts[1] == "global") {
                return _msg("general.general") + ":" + _msg("Role.global." + this.translatePermission(parts[0]) + ".default");
            } else if (parts.length > 2) {
                return parts[1] + " : " + _msg("Role.global." + this.translatePermission(parts[0]) + '.default', _msg("Role." + parts[2]));
            } else {
                return parts[1] + " : " + _msg("Role.global." + this.translatePermission(parts[0]) + '.default', _msg("Role.allow"));
            }
        } else if (key === AUDIT_FULL || key === SCHEDULED_TASK_FULL || key === SETTINGS_FULL || key === DELETE_ALL_FILTERED_ROWS_MENU_ENTRY || key === FILE_BROWSER || key === FILE_BROWSER_FULL) {
            return this.specialPermissionsTranslation(key);
        } else if (AppMetaTempGlobals.appMetaInstance.globalPermissions.has(key)) {
            return _msg("Role.specific." + key);
        } else {
            let e = key.split('.');
            if (e[0] === 'global') {
                return _msg("general.general") + " : " + _msg("Role." + key, "");
            } else if (e[e.length - 1] === 'allow' || e[e.length - 1] === 'deny') {
                return e[1] + " : " + _msg("Role.global." + e[2] + '.' + e[3], _msg("Role." + e[4]));
            } else {
                return e[1] + "." + EntityDescriptorForServerUtils.getFieldDescriptor(e[1], e[e.length - 1])?.getLabel() + " : " + _msg("Role.one." + e[2]);
            }
        }
    }

    protected getExtraTabPanes() {
        return [...super.getExtraTabPanes(),
        {
            routeProps: { path: "/config" },
            menuItemProps: { icon: "bookmark", content: _msg("general.config") },
            render: () => <Container className="EntityEditorPage_container " fluid>

                <Segment className="Role2EditorPage_segment">
                    <Segment className="buttonBar EntityEditorFormSimple_bar">
                        <Button primary onClick={() => this.props.dispatchers.save(this.props.entity)} >{_msg("entityCrud.editor.save")}</Button>
                        <Button secondary onClick={() => AppMetaTempGlobals.history.goBack()} >{_msg("general.cancel")}</Button>
                    </Segment>
                    {this.getSelfCheckedPermissions() ? <Segment data-cy="roleOverview">
                        <Header as='h5'>{_msg("RoleEditor.overviewCap")}</Header>
                        <div className="RoleEditorPage_overview">{this.getSelfCheckedPermissions()?.map((e, index) => <Label className="Role2EditorPage_label" key={index}>{this.getMessage(e)}
                            <Button className="RoleEditorPage_removeButton" icon='delete' color='red' size='mini' onClick={() => this.props.dispatchers.updatePermissions(e, true)} /></Label>)}
                        </div>
                    </Segment> : undefined}
                    {this.allPermissions ? <Accordion styled fluid defaultActiveIndex={this.props.accordionActiveIndex ? this.props.accordionActiveIndex : 0} panels={this.getPanels()} /> : undefined}
                </Segment>
            </Container>
        }
        ];
    }
}

type RolePermissionLabelProps = {
    permission: string,
    selfChecked: Optional<boolean>,
    defaultChecked: boolean
    label: string
    showDefaultLabel?: boolean,
}
class RolePermissionLabel extends React.Component<RolePermissionLabelProps & { dispatchers: DispatchersFrom<typeof sliceRoleEditorPage> }> {

    private isChecked() {
        if (this.props.selfChecked !== undefined) {
            return this.props.selfChecked;
        }
        return this.props.defaultChecked;
    }
   
    render() {       
        return <Label as='a' horizontal className="Role2EditorPage_label" key={this.props.permission} onClick={() => this.props.dispatchers.updatePermissions(this.props.permission)}>
            {<Label horizontal circular color={this.isChecked() ? "orange" : "grey"}>{this.props.selfChecked ? <>M</> : <>D</>}</Label>}
            <Icon size="large" name={this.isChecked() ? "check square outline" : "square outline"}></Icon>
            {this.props.showDefaultLabel ? <Label horizontal color="grey">{_msg("Role.default")}</Label> : undefined}
            {this.props.label}
        </Label>;
    }

}
