import { DatePicker } from "antd";
import React from "react";
import { Moment } from "moment";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import ReactDOM from "react-dom";
import { ShortcutRefForTest } from "@crispico/foundation-react/testsAreDemo/ShortcutRefForTest";
import moment from "moment";

export type PropsForEditor = PickerProps<Moment> & {
    [key: string]: any,
    dateFormat?: string,
    timeFormat?: string,
    hasTime?: boolean,
    additionalTimeInputSeparators?: string[],
    onChange?: (date: Moment | null, dateString: string | null) => void,
}

export class DatePickerExt extends React.Component<PropsForEditor> {

    datePickerRef = React.createRef<any>();

    static defaultProps = {
        additionalTimeInputSeparators: [".", ""]
    }

    onChange = (date: Moment | null, dateString: string) => {
        if (!this.props.hasTime) {
            date?.set({hour:0,minute:0,second:0,millisecond:0});
        }
        this.props.onChange?.(date, dateString);
        const input: any = ReactDOM.findDOMNode(this.datePickerRef.current)?.firstChild?.firstChild;
        setTimeout(() => {
            input.focus();
            if ((this.props.timeFormat || this.props.hasTime) && dateString) {
                const rangeLength = this.props.timeFormat ? this.props.timeFormat.length : Utils.timeFormat.length; 
                input.setSelectionRange(dateString.length - rangeLength, dateString.length);
            }
        })
    }

    render() {
        const format = getDatePickerFormat(this.props.dateFormat, this.props.timeFormat, this.props.hasTime, this.props.additionalTimeInputSeparators);
        let datePickerProps = { ...this.props, format: format, ref: this.datePickerRef, onChange: this.onChange };

        delete datePickerProps.timeFormat;
        delete datePickerProps.dateFormat;
        delete datePickerProps.hasTime;
        delete datePickerProps.additionalTimeInputSeparators;

        return <>            
            <ShortcutRefForTest objectToPublish={this} className={"ShortcutRefForTest"}/>
            <DatePicker {...datePickerProps} data-cy='date-picker' className="DatePicker" placeholder={_msg("DatePicker.placeholder")}></DatePicker>
        </>
    }

    tadSetDate = (date: string) => {
        const format = getDatePickerFormat(this.props.dateFormat, this.props.timeFormat, this.props.hasTime, this.props.additionalTimeInputSeparators);
        this.onChange(moment(date, format), date);
    }
}

export function getDatePickerFormat(dateFormat: string | undefined, timeFormat: string | undefined, hasTime: boolean | undefined, additionalTimeInputSeparators: string[] | undefined) {
    let formats: string[] = [];
    let format: string;
    if (!dateFormat) {
        if (timeFormat) {
            format = Utils.dateFormat + " " + timeFormat;
        } else if (hasTime) {
            format = Utils.dateTimeFormat;
        } else {
            format = Utils.dateFormat;
        }
    } else {
        if (timeFormat) {
            format = dateFormat + " " + timeFormat;
        } else if (hasTime) {
            format = dateFormat + " " + Utils.timeFormat;
        } else {
            format = dateFormat;
        }
    }

    if (format.indexOf(':') >= 0 && additionalTimeInputSeparators && additionalTimeInputSeparators.length > 0) {
        formats = additionalTimeInputSeparators.map(sep => format!.replace(':', sep))
    }
    formats.unshift(format);
    return formats;
}