import { apolloClient, BigState, ConnectedPageInfo, createSliceFoundation, FoundationInitializationsForClient, getBaseImpures, getBaseReducers, HomePageSettings, PropsFrom } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { DashboardTab, sliceDashboardTab } from "@crispico/foundation-react/pages/dashboard/dashboardTab/DashboardTab";
import gql from "graphql-tag";
import React from "react";
import { Header } from "semantic-ui-react";
import { dashboardEntityDescriptor } from "../FoundationEntityDescriptors";
import { Dashboard, processDashboardAfterLoad } from "./dashboard/DashboardEntityDescriptor";

export const slicePersonalHomePage = createSliceFoundation(class SliceXopsHomePage {
    nestedSlices = {
        dashboardTab: sliceDashboardTab
    }

    initialState = {
        dashboard: undefined as Dashboard | undefined
    }

    reducers = {
        ...getBaseReducers<SliceXopsHomePage>(this),
    }

    impures = {
        ...getBaseImpures<SliceXopsHomePage>(this),

        async loadDashboard(id: number) {
            const dashboard: Dashboard = (await apolloClient.query({
                query: gql(`query dashboardService_findById {
                dashboardService_findById(id: ${id}) { ${dashboardEntityDescriptor.getGraphQlFieldsToRequest()} }
            }`)
            })).data["dashboardService_findById"];

            if (!dashboard) { return };

            processDashboardAfterLoad(dashboard);
            this.getDispatchers().setInReduxState({ dashboard });
        }

    }
});
export type PersonalHomePageProps = PropsFrom<typeof slicePersonalHomePage> & {
    homePageSettings: HomePageSettings;
};

/**
 * TODO: probably needs a bit of work to properyl work in any FBA. Not a lot. The heavy work/separation should
 * be pretty OK.
 */
export class PersonalHomePage<P extends PersonalHomePageProps = PersonalHomePageProps> extends TabbedPage<P> {

    protected getTitle() {
        return { icon: "home", title: _msg("HomePage.title") };
    }

    componentDidMount() {
        if (this.props.homePageSettings.dashboardId) {
            this.props.dispatchers.loadDashboard(this.props.homePageSettings.dashboardId);
        }
    }

    protected renderHeader() {
        /* TODO: de redenumit (PersonalHomePage) si mutat clasele in found */
        return <div className="flex-container XopsHomePage_header">
            {/* TODO: de mutat aceste stiluri intr-o clasa. Si hai sa facem urmatorul experiment: sa nu punem o insiruire de clasa. Punem doar o clasa. Si
                apoi cred ca putem din less, sa zicem ca clasa asta de fapt contine si no-margin. Eu cred ca acest use case face parte din avantajele less. Daca
                reusim, ar fi interesant sa generalizam. Si in ac fel, putem externaliza stilul complet. Acum, cand punem o succesiune de clasa, inseamna ca nu putem
                controla complet stilul doar din .css sau .less  */}
            {/* TODO: de mutat/redenumit si mesajele */}
            <Header as="h1" style={{ fontSize: '46px', fontWeight: 900 }} className="no-margin">{_msg('XopsHomePage.welcome.header', this.props.currentUser?.firstName || this.props.currentUser?.username)}</Header>
            <Header as="h1" style={{ fontSize: '30px', fontWeight: 'normal' }} className="no-margin">{_msg('XopsHomePage.welcome.subheader')}</Header>
        </div>
    }

    protected renderDashboard() {
        if (!this.props.dashboard) {
            return null;
        }
        return <DashboardTab {...this.props.dashboardTab} dispatchers={this.props.dispatchers.dashboardTab}
                currentOrganizationToFilterBy={this.props.currentOrganizationToFilterBy}
                currentOrganization={this.props.currentOrganization} // currently only root Conn Comp have this; nested: not; hence this workaround to pass the info to the nested
                dashboardEntity={this.props.dashboard!} // it's a mistery why I need to put !, despite the condition w/ if; I tried also w/ &&; a bug in TS I'd say; in VS Code we don't have it
        />;
    }

    protected renderMain() {
        return <div className="flex-container">
            {this.renderHeader()}
            <div className="XopsHomePage_dashboardTabWrapper">
                <p/>
                {this.renderDashboard()}
            </div>
        </div>;
    }
}

export const infoPersonalHomePage = new ConnectedPageInfo(slicePersonalHomePage, PersonalHomePage, "PersonalHomePage");
infoPersonalHomePage.routeProps = { path: "/personalHomePage", exact: true, homePageType: "personal" };
infoPersonalHomePage.mapBigStateToProps = (state: BigState, props: PersonalHomePageProps) => {
    props.homePageSettings = (state.AppContainer.initializationsForClient as FoundationInitializationsForClient).homePageSettings;
};