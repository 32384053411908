import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, BigState, createSliceFoundation, EntityTablePage, EntityTablePageProps, EntityTableSimple, ENT_ADD, ENT_DELETE, ENT_SAVE, getBaseImpures, getBaseReducers, Optional, Organization, PropsFrom, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, StateFrom, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { EntityToTagFieldDescriptor } from "@crispico/foundation-react/pages/EntityToTag/entityToTagDescriptor";
import { Upload } from "antd";
import { UploadChangeParam, UploadProps } from "antd/lib/upload";
import { AirportForMap } from "apollo-gen/AirportForMap";
import { SaveParams_LongInput } from "apollo-gen/globalTypes";
import { loadAirportsForMap, loadAirportsForMapVariables } from "apollo-gen/loadAirportsForMap";
import { loadTerritoriesForMap, loadTerritoriesForMapVariables } from "apollo-gen/loadTerritoriesForMap";
import { TerritoryForMap } from "apollo-gen/TerritoryForMap";
import { InitializationsForClient, MapSettings } from "app";
import { EditMode, ID, Location, MapContainerLeaflet, PolygonData, POLYGON_TYPE, sliceMapContainerLeaflet } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { DEFAULT_POLYGON_COLOR, DEFAULT_ZOOM_LEVEL } from "components/MapContainerLeaflet/MapLayerHelpers";
import { LOAD_AIRPORTS_FOR_MAP } from "components/realTimeMap/queries";
import gql from "graphql-tag";
import Interweave from "interweave";
import lodash from "lodash";
import { airportEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { LOAD_TERRITORIES_FOR_MAP, SAVE_TERRITORIES_FOR_MAP } from "pages/Territory/queries";
import React from "react";
import { Button, Confirm, Dimmer, Dropdown, DropdownProps, Form, Icon, Loader, Menu, Message, Modal, Popup, TextArea } from "semantic-ui-react";
import { selectAirportOnCurrentOrganizationToFilterByChange } from "components/realTimeMap/RealTimeMap";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";

export const TERRITORY: string = "territory";
const NUMBER_OF_TERRITORIES_FOR_MAP = 100;

export const territoryEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "Territory",
    miniFields: ["name"],
    icon: "object ungroup outline",
    defaultSort: { field: "name", direction: "ASC" }
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
    .addFieldDescriptor({ name: "description", type: FieldType.string })
    .addFieldDescriptor({ name: "color", type: FieldType.color })
    .addFieldDescriptor({ name: "organization", type: "Organization" })
    .addFieldDescriptor(new EntityToTagFieldDescriptor())
);
export const sliceEntityTablePageTerritory = territoryEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
        mapContainer: sliceMapContainerLeaflet,
    }

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
        territories: undefined as Optional<{ [key: string]: TerritoryForMap }>,
        airports: [] as AirportForMap[],
        isGoToAirportPopupOpen: false as boolean,
        importKMLInfo: undefined as Optional<{ status?: string, showConfirmation?: boolean, polygons?: PolygonData[] }>,
        showModal: false as boolean
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),

        add(state: StateFrom<Ext>, newTerritories: TerritoryForMap[]) {
            if (!state.territories) {
                state.territories = {};
            }
            newTerritories.forEach(newTerr => {
                state.territories![newTerr.id] = newTerr;
            });
        },

        remove(state: StateFrom<Ext>, ids: ID[]) {
            ids.forEach(id => {
                delete state.territories![id];
            });
        }
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        async loadAirports() {
            const airports: Optional<AirportForMap[]> = (await apolloClient.query<loadAirportsForMap, loadAirportsForMapVariables>({
                query: LOAD_AIRPORTS_FOR_MAP,
                variables: FindByFilterParams.create().sorts([{ field: "code", direction: "ASC" }])
            })).data.airportService_findByFilter?.results as Optional<AirportForMap[]>;

            this.getDispatchers().setInReduxState({ airports: airports || [] });
        },

        async loadTerritoriesOnMap(mapContainer: Optional<MapContainerLeaflet>, ids?: Optional<ID[]>) {

            let filterFromCQ = this.getFilterForLoad();

            let filter: Filter;
            if (ids && ids.length > 0) {
                const filters: Filter[] = [];
                filters.push(filterFromCQ);
                filters.push({ field: "id", operator: FilterOperators.forNumber.in.value, value: ids.join(", ") });
                filter = { operator: FilterOperators.forComposedFilter.and.value, filters: filters };
            } else {
                filter = filterFromCQ;
            }

            const territories: Optional<TerritoryForMap[]> = (await apolloClient.query<loadTerritoriesForMap, loadTerritoriesForMapVariables>({
                query: LOAD_TERRITORIES_FOR_MAP,
                variables: FindByFilterParams.create().filter(filter)
            })).data.territoryService_findByFilter?.results;

            if (!territories || territories.length === 0) {
                this.getDispatchers().add([]);
                return;
            }

            if (territories.length <= NUMBER_OF_TERRITORIES_FOR_MAP){
                this.getDispatchers().add(territories);
                this.addTerritoriesOnMap(territories, mapContainer);
            } else {
                this.getDispatchers().add([]);
                this.getDispatchers().setInReduxState({showModal: true});
            }
        },

        addTerritoriesOnMap(territories: TerritoryForMap[], mapContainer: Optional<MapContainerLeaflet>) {
            let polygons: PolygonData[] = [];
            territories.forEach((territory: TerritoryForMap) => {
                const points: Location[] = [];
                territory.coordinates?.forEach(p => {
                    points.push({ longitude: p.a, latitude: p.b });
                });
                polygons.push({ id: territory.id, points: points, color: territory.color || undefined, text: territory.name || undefined});
            });
            mapContainer?.addOrUpdateLayers(polygons, TERRITORY);
        },

        isAllowed(editMode: EditMode): boolean {
            const mode = editMode === EditMode.DRAW ? ENT_ADD : editMode === EditMode.EDIT ? ENT_SAVE : ENT_DELETE;
            const permission = Utils.pipeJoin([mode, territoryEntityDescriptor.name]);
            if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission)) {
                return false;
            }
            return true;
        },

        async updateTerritories(polygons: PolygonData[], tableSimple: EntityTableSimple, mapContainer: MapContainerLeaflet, org?: Organization) {
            let ids: number[] = [];

            let params: SaveParams_LongInput[] = [];
            for (const polygon of polygons) {
                let territory = polygon.id ? { ...this.getState().territories![polygon.id] } : { name: polygon.text, color: polygon.color } as TerritoryForMap;
                territory.coordinates = [];
                polygon.points.forEach(p => territory.coordinates?.push({ a: p.longitude, b: p.latitude }));
                let fieldsAndValue: any = {
                    name: territory.name,
                    color: territory.color ? territory.color : DEFAULT_POLYGON_COLOR,
                    coordinates: territory.coordinates,
                };
                if (org) {
                    fieldsAndValue['organization'] = { id: org?.id }
                }
                params.push({ id: territory.id, fieldsAndValues: fieldsAndValue });
            }
            const result: [{ id: number }] = (await apolloClient.mutate({ mutation: SAVE_TERRITORIES_FOR_MAP, variables: { params: params } })).data.territoryService_saveAll;
            result.forEach(t => ids.push(t.id));

            // refresh the table
            this.refresh();

            // update only modified & added features
            await this.loadTerritoriesOnMap(mapContainer, ids);

            if (polygons.length === 1 && polygons[0].id === undefined) {
                this.getDispatchers().mapContainer.setInReduxState({ selectedLayer: { id: ids[0], type: TERRITORY } });
            }

        },

        async removeTerritories(polygons: PolygonData[], tableSimple: EntityTableSimple) {
            const permission = Utils.pipeJoin([ENT_DELETE, territoryEntityDescriptor.name]);
            if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission, true)) {
                return;
            }
            const removeOperationName = `${lodash.lowerFirst(territoryEntityDescriptor.name)}Service_deleteById`;
            const removeMutation = gql(`mutation deleteEntity($id: Long){${removeOperationName}(id: $id)}`);
            let ids: ID[] = [];
            for (const polygon of polygons) {
                ids.push(polygon.id!);
                await apolloClient.mutate({ mutation: removeMutation, variables: { id: polygon.id } });
            }
            // refresh the table
            this.refresh();

            this.getDispatchers().remove(ids);
        },

        selectAirport(id: number) {
            const airport: Optional<AirportForMap> = this.getState().airports.find(a => a.id === id);

            if (airport?.latitude && airport?.longitude) {
                this.getDispatchers().mapContainer.setInReduxState({ zoom: DEFAULT_ZOOM_LEVEL, center: [airport.latitude, airport.longitude] });
            }
        }
    }
}).setEntityDescriptor(territoryEntityDescriptor);

type PropsNotFromState = { mapSettings: MapSettings };

territoryEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<EntityTablePageProps & PropsFrom<typeof sliceEntityTablePageTerritory> & PropsNotFromState> {

    mapContainerRef = React.createRef<MapContainerLeaflet>();

    constructor(props: EntityTablePageProps & PropsFrom<typeof sliceEntityTablePageTerritory> & PropsNotFromState) {
        super(props);
        this.renderTooltipContent = this.renderTooltipContent.bind(this);
        const that = this;
        this.tableSimpleClass = class extends EntityTableSimple {
            onSelectItem(selectId: any) {
                that.props.dispatchers.mapContainer.setInReduxState({ selectedLayer: { id: selectId, type: TERRITORY } });
            }
        }
        this.props.dispatchers.loadAirports();
    }

    componentDidMount() {
        this.props.dispatchers.mapContainer.setInReduxState({ editDrawEnabledOnType: TERRITORY, editMode: undefined });
        this.props.dispatchers.loadTerritoriesOnMap(this.mapContainerRef.current);
    }

    componentDidUpdate(prevProps: PropsFrom<typeof sliceEntityTablePageTerritory> & PropsNotFromState) {
        super.componentDidUpdate(prevProps);
        if (!lodash.isEqual(this.props.customQueryBar.customQuery?.customQueryDefinitionObject, prevProps.customQueryBar.customQuery?.customQueryDefinitionObject)) {
            this.props.dispatchers.setInReduxState({ territories: undefined });
            this.props.dispatchers.tableSimple.setInReduxState({ selected: undefined });
            this.mapContainerRef.current?.clearMap();
            this.props.dispatchers.loadTerritoriesOnMap(this.mapContainerRef.current);
        }

        // verify if [0, 0] -> default value; if not [0, 0], then it means the center was set by value from session storage, so we don't want to reset it
        if (lodash.isEqual(this.props.mapContainer.center, [0, 0]) && prevProps && prevProps.airports.length !== this.props.airports.length && this.props.mapSettings.airport !== null) {
            const airport = this.props.airports?.find(a => a.code === this.props.mapSettings.airport);
            this.props.dispatchers.selectAirport(airport?.id);
        }

        if (this.props.totalCount != prevProps.totalCount){
            this.mapContainerRef.current?.clearMap();
            this.props.dispatchers.loadTerritoriesOnMap(this.mapContainerRef.current);
        }

        selectAirportOnCurrentOrganizationToFilterByChange(prevProps, this.props);
    }

    renderTooltipContent(layerData: PolygonData, type: string, additionalInfo?: { pointId?: ID }): React.ReactElement {
        return <><div>{layerData.text}</div> {layerData.readableArea}</>;
    }

    protected preRenderButtons(params: any): Array<OverrideableElement> {
        const props: UploadProps = {
            onChange: (info: UploadChangeParam) => {
                const file = info.fileList[info.fileList.length - 1];
                if (file.status !== 'uploading') {
                    this.props.dispatchers.setInReduxState({ importKMLInfo: { status: "inProgress" } });
                    let reader = new FileReader();
                    reader.onload = async (e) => {
                        if (e.target) {
                            const polygons: PolygonData[] = this.mapContainerRef.current!.parseKML(e.target.result as string);
                            if (polygons.length === 0) {
                                AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.showGlobalAlert({ message: _msg('Territory.importKMLFile.none') });
                                this.props.dispatchers.setInReduxState({ importKMLInfo: undefined })
                            } else {
                                this.props.dispatchers.setInReduxState({ importKMLInfo: { status: "inProgress", showConfirmation: true, polygons: polygons } });
                            }
                        }
                    }
                    if (file.originFileObj) {
                        reader.readAsText(file.originFileObj as Blob);
                    }
                }
                if (file.status === 'error') {
                    Utils.showGlobalAlert({ message: _msg('Territory.importKMLFile.error') });
                }
            },
            multiple: false,
            accept: ".kml",
            showUploadList: false
        };
        return [
            ...super.preRenderButtons(params),
            {
                element:
                    <div key="selectAirportAndImportKML" className="flex-container-row MapContainerHeader_segment">
                        <Dimmer inverted active={this.props.territories === undefined}></Dimmer>

                        <Upload  {...props} disabled={this.props.importKMLInfo?.status === "inProgress"} beforeUpload={() => false}>
                            <Button disabled={this.props.importKMLInfo?.status === "inProgress"} color="blue"><Icon loading={this.props.importKMLInfo?.status === "inProgress"} name={this.props.importKMLInfo?.status === "inProgress" ? 'spinner' : 'upload'} />
                                {this.props.importKMLInfo?.status === "inProgress" ? _msg("Territory.importKMLFile.inProgress", this.props.importKMLInfo.polygons?.length) : _msg("Territory.importKMLFile")}
                            </Button>
                            <Confirm
                                open={this.props.importKMLInfo?.showConfirmation === true}
                                header={_msg("Territory.importKMLFile")}
                                content={() => {
                                    let names = "";
                                    this.props.importKMLInfo?.polygons?.forEach(p => names += p.text + "\n");
                                    return <Form>
                                        <TextArea rows="10">{_msg("Territory.importKMLFile.info", names)}</TextArea>
                                    </Form>
                                }}
                                onCancel={() => this.props.dispatchers.setInReduxState({ importKMLInfo: undefined })}
                                onConfirm={async () => {
                                    this.props.dispatchers.setInReduxState({ importKMLInfo: { ...this.props.importKMLInfo, showConfirmation: false } });
                                    await this.props.dispatchers.updateTerritories(this.props.importKMLInfo?.polygons!, this.tablesimpleRef.current!, this.mapContainerRef.current!, this.props.currentOrganization);
                                    this.props.dispatchers.setInReduxState({ importKMLInfo: undefined });
                                }}
                                cancelButton={_msg("general.cancel")}
                                confirmButton={_msg("general.ok")}
                            />
                        </Upload>

                        <Popup
                            open={this.props.isGoToAirportPopupOpen}
                            trigger={<Button color='olive' icon={airportEntityDescriptor.icon} content={_msg("MapRealTime.airport")} />}
                            onOpen={() => this.props.dispatchers.setInReduxState({ isGoToAirportPopupOpen: true })}
                            content={<Dropdown style={{ maxWidth: "250px", minWidth: "250px" }} data-cy={"dropdownMRT"} search selection
                                searchInput={{ autoFocus: true }}
                                options={this.props.airports.map((airport: AirportForMap) => ({ key: airport.id, value: airport.id, text: airport.code + " - " + airport.name }))}
                                onChange={(evt: any, props: DropdownProps) => {
                                    this.props.dispatchers.selectAirport(props.value as number);
                                    this.props.dispatchers.setInReduxState({ isGoToAirportPopupOpen: false });
                                }} />}
                            on='click' position='bottom left'
                        />
                    </div>
            }
        ];
    };

    renderMain() {
        return (<>           
            <SplitPaneExt size="30%">
                {this.props.mapContainer.editMode
                    ? <Message data-cy="editModeMessage" className="flex-container flex-grow">
                        <Interweave content={_msg("Territory." + this.props.mapContainer.editMode + "Mode")} />
                    </Message>
                    : this.renderTableSimple()}

                <>
                    <Dimmer inverted active={this.props.territories === undefined}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>

                    <MapContainerLeaflet {...this.props.mapContainer} dispatchers={this.props.dispatchers.mapContainer} ref={this.mapContainerRef} mapId={"territory-map"}
                        layers={{ [TERRITORY]: { layerType: POLYGON_TYPE, options: { flyToSelectedMarker: true, hideTooltipOnHoveredLayer: false } } }}
                        pruneClusterMode={false} bingAPIKey={this.props.mapSettings.bingAPIKey}
                        isAllowed={(editMode: EditMode) => { return this.props.dispatchers.isAllowed(editMode) }}
                        onLayerAdded={(polygon: PolygonData, type: string) => this.props.dispatchers.updateTerritories([polygon], this.tablesimpleRef.current!, this.mapContainerRef.current!, this.props.currentOrganization)}
                        onLayersEdited={(polygons: PolygonData[], type: string) => this.props.dispatchers.updateTerritories(polygons, this.tablesimpleRef.current!, this.mapContainerRef.current!, this.props.currentOrganization)}
                        onLayersRemoved={(polygons: PolygonData[], type: string) => this.props.dispatchers.removeTerritories(polygons, this.tablesimpleRef.current!)}
                        renderTooltipContent={this.renderTooltipContent} saveCenterZoomInStorage={true} />
                    <ModalExt open={this.props.showModal} onClose={() =>  this.props.dispatchers.setInReduxState({ showModal: false })}>
                        <Modal.Content className="wh100">
                            <Modal.Description><h1>{_msg("Territory.tooManyTeritoriesForMap")}</h1></Modal.Description>
                        </Modal.Content>
                    </ModalExt>
                    
                </>
            </SplitPaneExt>
        </>)
    }
}

territoryEntityDescriptor.infoTable.mapBigStateToProps = (state: BigState, props: any) => {   
    props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;
    props.itemsHidedFromCell = ["add"];
}
