import React from "react";
import moment from "moment";
import { ChartConfig, ChartTab, ChartTabProps, ChartTabReducers, ChartTabState, getChartGenerationPeriodFromConfig } from "../ChartTab";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { HistogramRRC, Point, Serie } from "../Histogram/Histogram";


export class HistogramCountInTerritoriesTabState extends ChartTabState {
    histogramData = [] as Array<Serie>;
    initialStartDate = 0 as number;
    initialEndDate = 0 as number;
}

export class HistogramCountInTerritoriesTabReducers<S extends HistogramCountInTerritoriesTabState = HistogramCountInTerritoriesTabState> extends ChartTabReducers<S> {

    prepareData(p: { savedData: string, config: ChartConfig }) {
        const { startDate, endDate } = getChartGenerationPeriodFromConfig(p.config);
        this.s.initialStartDate = startDate;
        this.s.initialEndDate = endDate;

        let result = JSON.parse(p.savedData) as Array<{ territory: any, entries: Array<{ date: string, total: Array<any>, exited: Array<any>, entered: Array<any> }> }>;

        const histogramData: Array<Serie> = [];
        let id = 0;
        result.forEach(h => {
            const points: Array<Point> = []

            h.entries.forEach(e => {
                points.push({
                    id: id++,
                    x: moment(e.date).valueOf(),
                    y: e.total.length,
                    total: e.total,
                    out: e.exited,
                    in: e.entered
                })
            })
            histogramData.push({ id: h.territory.name, color: h.territory.color, data: points });
        })
        this.s.histogramData = histogramData;
    }

}

type Props = RRCProps<HistogramCountInTerritoriesTabState, HistogramCountInTerritoriesTabReducers> & ChartTabProps;
type LocalState = {}
export class HistogramCountInTerritoriesTab extends ChartTab<Props, LocalState> {


    render() {
        return <div className="flex-container flex-grow-shrink-no-overflow less-padding gap5">
            {this.renderTopBar(moment(this.props.s.initialStartDate), moment(this.props.s.initialEndDate))}
            <HistogramRRC id={"histogram"} data={this.props.s.histogramData} startDate={this.props.s.initialStartDate} endDate={this.props.s.initialEndDate} countBy="EquipmentResource" groupedBy="Territory" />;
        </div>;
    }
}

export const HistogramCountInTerritoriesTabRRC = ReduxReusableComponents.connectRRC(HistogramCountInTerritoriesTabState, HistogramCountInTerritoriesTabReducers, HistogramCountInTerritoriesTab);
