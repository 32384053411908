import Blockly from "blockly";
import { Utils } from "@crispico/foundation-react/utils/Utils";

Blockly.CustomConstantProvider = function () {
    Blockly.CustomConstantProvider.superClass_.constructor.call(this);

    this.CORNER_RADIUS = 0;
};

Blockly.utils.object.inherits(Blockly.CustomConstantProvider, Blockly.geras.ConstantProvider);

Blockly.CustomRenderer = function (name) {
    Blockly.CustomRenderer.superClass_.constructor.call(this, name);
};

Blockly.utils.object.inherits(Blockly.CustomRenderer, Blockly.geras.Renderer);

Blockly.CustomRenderer.prototype.makeConstants_ = function () {
    return new Blockly.CustomConstantProvider();
};

const typemap = Blockly.registry.typeMap_[Blockly.registry.Type.RENDERER];
//added !Utils.isTest() because with it test doesn't run and without it app doesn't run
if (!Utils.isTest() && !typemap['custom_renderer']) {
    Blockly.blockRendering.register('custom_renderer', Blockly.CustomRenderer);
}