import React from "react";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, Optional, TestUtils, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Reducers, ReduxReusableComponents, RRCProps, State, EnrichProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Dropdown, DropdownItemProps, Popup, Segment } from "semantic-ui-react";
import { DatePicker } from "antd";
import { loadHumanResourceScheduleForGantt, loadHumanResourceScheduleForGanttVariables } from "apollo-gen/loadHumanResourceScheduleForGantt";
import { LOAD_HUMAN_RESOURCE_SCHEDULE_FOR_GANTT, LOAD_MISSION_FOR_GANTT } from "./queries";
import { HumanResourceScheduleForGantt } from "apollo-gen/HumanResourceScheduleForGantt";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { loadMissionForGantt, loadMissionForGanttVariables, loadMissionForGantt_objectActionGroupService_findByFilter_results } from "apollo-gen/loadMissionForGantt";
import _ from "lodash";
import { AbstractGantt, AbstractGanttState, AbstractGanttReducers, AbstractGanttProps, GanttGroup, GanttItem, GanttLayer, GanttData, CellRenderer } from "./AbstractGantt";


var moment = require("moment");
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

export enum Grouping {
    HUMAN_RESOURCE, EQUIPMENT_RESOURCE, NONE
}
class GanttResourcesState extends AbstractGanttState {
    selectedGroupBy: Grouping = Grouping.HUMAN_RESOURCE;
    oags: loadMissionForGantt_objectActionGroupService_findByFilter_results[] = [];
    hrSchedules: HumanResourceScheduleForGantt[] = [];
}
class GanttResourcesReducers<S extends GanttResourcesState = GanttResourcesState> extends AbstractGanttReducers<S> {

}
export class GanttResources extends AbstractGantt {

    props!: EnrichProps<AbstractGantt, GanttResourcesState, GanttResourcesReducers, {
        hideGroupBy?: boolean,
        sortByTimeUsed?: boolean
    }>;

    protected async loadMissions() {
        let filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [
            Filter.create("object.startTime", FilterOperators.forDate.greaterThanOrEqualTo, this.props.s.start.toString()),
            Filter.create("object.endTime", FilterOperators.forDate.lessThanOrEqualTo, this.props.s.end.toString())
        ]);

        let oags = (await apolloClient.query<loadMissionForGantt, loadMissionForGanttVariables>({
            query: LOAD_MISSION_FOR_GANTT,
            variables: FindByFilterParams.create().filter(filter)
        })).data.objectActionGroupService_findByFilter?.results;

        if (oags === null || oags === undefined) {
            oags = [];
        }

        this.props.r.setInReduxState({ oags, hrSchedules: [] });

        if (this.props.s.selectedGroupBy === Grouping.HUMAN_RESOURCE) {
            await this.loadHRS()
        }
    }

    protected async loadHRS() {
        if (this.props.s.hrSchedules.length !== 0) {
            return;
        }

        const filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [
            Filter.create("startTime", FilterOperators.forDate.greaterThan, this.props.s.start.toString()),
            Filter.create("endTime", FilterOperators.forDate.lessThan, this.props.s.end.toString())
        ]);
        const sorts: Sort[] = [{ field: "startTime", direction: "ASC" }, { field: "humanResource.lastName", direction: "ASC" },
        { field: "humanResource.firstName", direction: "ASC" }];

        let result = (await apolloClient.query<loadHumanResourceScheduleForGantt, loadHumanResourceScheduleForGanttVariables>({
            query: LOAD_HUMAN_RESOURCE_SCHEDULE_FOR_GANTT,
            variables: FindByFilterParams.create().filter(filter).sorts(sorts)
        })).data.humanResourceScheduleService_findByFilter?.results;

        if (result === null || result === undefined) {
            result = [];
        }
        this.props.r.setInReduxState({ hrSchedules: result });
    }

    protected groupDataByResource(oags: any[], schedules: any[], per: "HumanResource" | "EquipmentResource" | undefined): { [key: number]: any } {
        const map: { [key: number]: any } = {};
        schedules.forEach(schedule => {
            // for ER schedules probably we'll need to look somewhere else
            const s = AbstractGantt.findOne("HumanResourceSchedule", "id", schedule.id, this.props.entities);
            if (!s) {
                return;
            }
            let id = 0;
            if (per) {
                id = per === "HumanResource" ? s.humanResource?.id : s.equipmentResource?.id;
            }
            if (!map[id]) {
                const resource = per ? AbstractGantt.findOne(per, "id", id, this.props.entities) : {};
                map[id] = { ...resource };
                map[id].timeUsed = 0;
                map[id].missions = {};
                map[id].schedules = {};
            }
            if (!map[id].schedules[s.id]) {
                map[id].schedules[s.id] = { id: s.id, startTime: s.startTime, endTime: s.endTime };
            }
        });

        oags.forEach(oag => {
            const m = AbstractGantt.findOne("Mission2", "id", oag.mission.id, this.props.entities);
            if (!m) {
                return;
            }
            let id = 0;
            if (per) {
                id = (per === "HumanResource" ? m.humanResource?.id : m.equipmentResource?.id) || 0;
            }
            if (!map[id]) {
                const resource = per ? AbstractGantt.findOne(per, "id", id, this.props.entities) : {};
                map[id] = { ...resource };
                map[id].timeUsed = 0;
                map[id].missions = {};
                map[id].schedules = {};
            }
            if (!map[id].missions[m.id]) {
                map[id].missions[m.id] = { id: m.id, startTime: m.startTime, endTime: m.endTime };
                map[id].missions[m.id].oags = [];
                map[id].timeUsed += moment(m.endTime).toDate().getTime() - moment(m.startTime).toDate().getTime();
            }
            map[id].missions[m.id].oags.push(oag);
        });
        return map;
    }

    protected sortData(data: { [key: number]: any }, byField: string): any[] {
        return Object.keys(data).map(key => data[Number(key)]).sort((a, b) => b[byField] - a[byField]);
    }

    protected processData() {
        const data: GanttData = { layers: [], items: [], groups: [] };

        let grouping = this.props.s.selectedGroupBy;
        let oags;
        let hrSchedules;
        if (this.props.entities) {
            const entities = this.props.entities;
            const oagMap = entities["ObjectActionGroup"] ? _.cloneDeep(entities["ObjectActionGroup"]) : {};
            const hrSchedulesMap = entities["HumanResourceSchedule"] ? _.cloneDeep(entities["HumanResourceSchedule"]) : {};

            oags = Object.keys(oagMap).map(key => oagMap[Number(key)]);
            hrSchedules = Object.keys(hrSchedulesMap).map(key => hrSchedulesMap[Number(key)]);
        } else {
            oags = _.cloneDeep(this.props.s.oags);
            hrSchedules = _.cloneDeep(this.props.s.hrSchedules);
        }
        const perType = grouping === Grouping.HUMAN_RESOURCE ? "HumanResource" : grouping === Grouping.EQUIPMENT_RESOURCE ? "EquipmentResource" : undefined;
        const sortedLines = this.sortData(
            this.groupDataByResource(oags, grouping === Grouping.HUMAN_RESOURCE ? hrSchedules : [], perType),
            this.props.sortByTimeUsed ? "timeUsed" : "identifier");

        sortedLines.forEach((r, index) => {
            if (perType && this.props.hideResources?.[perType] && this.props.hideResources[perType]?.findIndex(id => id === r.id) !== -1) {
                return;
            }
            data.groups.push({ id: index, data: { identifier: grouping === Grouping.HUMAN_RESOURCE?r.firstName + " " + r.lastName:r.identifier, timeUsed: r.timeUsed } });
            Object.keys(r.schedules).forEach(sKey => {
                const s = r.schedules[sKey];
                const startTime = moment(s.startTime).valueOf();
                const endTime = moment(s.endTime).valueOf();
                if (startTime >= endTime) {
                    return;
                }
                data.layers.push({
                    rowNumber: index,
                    start: startTime,
                    end: endTime,
                    style: { backgroundColor: 'lightgray' }
                });
            })
            Object.keys(r.missions).forEach(mKey => {
                const mission = r.missions[mKey];
                let tooltip = "";
                mission.oags.forEach((oag: any) => {
                    const task = AbstractGantt.findOne("Task", "id", oag.object.id, this.props.entities);
                    const taskType = AbstractGantt.findOne("TaskType", "id", task.taskType?.id, this.props.entities);
                    const flight = AbstractGantt.findOne("Flight", "id", task.taskGroup.id, this.props.entities);
                    tooltip += (flight?.airline || "") + (flight?.number || "") + " " + (taskType?.name || "") + "\n";
                });
                data.items.push({ row: index, start: mission.startTime, end: mission.endTime, key: mission.id, data: { tooltip: tooltip } });
            });
        });
        this.props.r.setInReduxState({ data });
    }

    protected entitiesChangedHandler() {
        this.processData();
    }

    protected startEndChangedHandler() {
        this.loadMissions();
    }

    async componentDidUpdateInternal(prevProps?: any) {
        super.componentDidUpdateInternal(prevProps);

        if (!prevProps || prevProps.s.selectedGroupBy !== this.props.s.selectedGroupBy) {
            this.processData();
        }
    }

    protected ganttItemRenderer(object: { item: GanttItem }): React.ReactNode {
        const backgroundColor = object.item.data?.backgroundColor ? Utils.convertColorToHex(object.item.data.backgroundColor) : "lightblue";
        const trigger = <span className='rct9k-items-inner' style={{ backgroundColor, height: "13px" }} />;
        if (!object.item.data?.tooltip) {
            return trigger;
        }
        const content = <div className="flex-container-row flex-center no-wrap-no-overflow-ellipsis text-break-spaces">{object.item.data.tooltip}</div>;
        return <Popup hoverable={false} position="top center" size="small" trigger={trigger} content={content} />;
    }

    protected getTableColumns(): any {
        return [
            { headerRenderer: null, width: 150, cellRenderer: (group: { group: GanttGroup }) => <CellRenderer group={group.group} columnName="identifier" /> },
            { headerRenderer: <TotalTimeHeader groups={this.props.s.data.groups} />, width: 100, cellRenderer: (group: { group: GanttGroup }) => <DurationRenderer group={group.group} columnName="timeUsed" /> }
        ]
    }

    protected renderTopBar() {
        const options: DropdownItemProps[] = [{ value: Grouping.HUMAN_RESOURCE, text: entityDescriptors["HumanResource"].getLabel() },
        { value: Grouping.EQUIPMENT_RESOURCE, text: entityDescriptors["EquipmentResource"].getLabel() },
        { value: Grouping.NONE, text: _msg("GanttResources.none") }];
        return !this.props.hideGroupBy ? <>
            <span className="flex-center tiny-margin-right">{_msg("GanttResources.groupBy")}:</span>
            <Dropdown className="tiny-margin-right" selection options={options} value={this.props.s.selectedGroupBy} onChange={(e, data) => this.props.r.setInReduxState({ selectedGroupBy: data.value as number })} />
        </> : <></>;
    }
}



class DurationRenderer extends CellRenderer {

    render() {
        return formatDuration(this.props.group.data?.[this.props.columnName]);
    }
}
class TotalTimeHeader extends React.Component<{ groups: GanttGroup[] }> {
    render() {
        let totalTime = 0;
        this.props.groups.forEach(group => {
            if (group.data.timeUsed) {
                totalTime += group.data.timeUsed;
            }
        });       
        return <div className="wh100 flex-center flex-justify-content-center">
            <div style={{ textAlign: "center" }}>
                <div>{_msg("GanttAssignment.totalTime")}: </div>
                <div>{formatDuration(totalTime)}</div>
            </div>
        </div>;
    }
}

// CC: this should be a general component for duration
function formatDuration(duration: number) {
    if (!duration || duration < 3600000) {
        return "00:" + moment.duration(duration).format("mm");
    } else {
        return moment.duration(duration).format("HH:mm");
    }
}

export const GanttResourcesRRC = ReduxReusableComponents.connectRRC(GanttResourcesState, GanttResourcesReducers, GanttResources);