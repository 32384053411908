import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Utils } from "@crispico/foundation-react";
import { ResponsiveLineExt } from "@crispico/foundation-react/components/nivoExt";
import { LineSvgProps, Serie } from "@nivo/line";
import lodash from 'lodash';
import moment from "moment";
import React from "react";
import Measure from "react-measure";
import { Button, Icon } from "semantic-ui-react";
import { Slider } from "antd";

// TODO: Delete this when the chart from historical map will be replaced with the new component, maybe rewrite the code from there.
// CC: I tried to replace it, but to much comlicated code involved + we don't know exactly the future of historical map
export const sliceResponsiveLineChartOld = createSliceFoundation(class SliceResponsiveLineChartOld {

    initialState = {
        // calculated based on this.props.initialSeries (min, max)
        initialStartTime: 0 as number,
        initialEndTime: 0 as number,
        // calculated each time a zoom in/out or change is done
        series: [] as Serie[],
        startTime: 0 as number,
        endTime: 0 as number,

        chartPaddingLeft: 0 as number
    }

    reducers = {
        ...getBaseReducers<SliceResponsiveLineChartOld>(this),

        initState(state: StateFrom<SliceResponsiveLineChartOld>, p: { initialSeries: Serie[] }) {
            let min = 0;
            let max = 0;
            for (let i = 0; i < p.initialSeries.length; i++) {
                const data = p.initialSeries[i].data;
                data.forEach((value) => {
                    if (!min || min > value.x!) {
                        min = value.x! as number;
                    }
                    if (!max || max < value.x!) {
                        max = value.x! as number;
                    }
                });
            }
            state.initialStartTime = min;
            state.initialEndTime = max;
            this.changeRange(state, {
                initialSeries: p.initialSeries,
                startTime: state.initialStartTime,
                endTime: state.initialEndTime
            });
        },

        changeRange(state: StateFrom<SliceResponsiveLineChartOld>, p: { initialSeries: Serie[], startTime: number, endTime: number }) {
            state.series = [];
            for (let i = 0; i < p.initialSeries.length; i++) {
                let newSerie = { ...p.initialSeries[i] };
                newSerie.data = p.initialSeries[i].data.filter((value) => value.x! >= p.startTime && value.x! <= p.endTime);
                state.series.push(newSerie);
            }
            state.startTime = p.startTime;
            state.endTime = p.endTime;
        },
    }

    impures = {
        ...getBaseImpures<SliceResponsiveLineChartOld>(this),

        getRange(type: "zoomIn" | "zoomOut" | "change", startTime: number, endTime: number, initialSeries: Serie[], factor: number): number[] | undefined {
            const offset = (endTime - startTime) * factor / 2;
            switch (type) {
                case "zoomIn": {
                    startTime = startTime + offset;
                    endTime = endTime - offset;
                    break;
                }
                case "zoomOut": {
                    startTime = startTime - offset;
                    endTime = endTime + offset;
                    break;
                }
            }
            let min = undefined as number | undefined;
            let max = undefined as number | undefined;

            for (let i = 0; i < initialSeries.length; i++) {
                let minPoint = undefined as number | undefined;
                let maxPoint = undefined as number | undefined;
                const data = initialSeries[i].data;
                switch (type) {
                    case "zoomIn":
                    case "change": { // get next min max in interior            
                        data.forEach((value) => {
                            if (value.x! >= startTime && (!minPoint || minPoint >= value.x!)) {
                                minPoint = value.x! as number;
                            }
                            if (value.x! <= endTime && (!maxPoint || maxPoint <= value.x!)) {
                                maxPoint = value.x! as number;
                            }
                        });
                        if (minPoint !== undefined && (!min || minPoint < min)) {
                            min = minPoint;
                        }
                        if (maxPoint !== undefined && (!max || maxPoint > max)) {
                            max = maxPoint;
                        }
                        break;
                    }
                    case "zoomOut": { // get next min max in exterior
                        data.forEach((value) => {
                            if (value.x! <= startTime && (!minPoint || minPoint <= value.x!)) {
                                minPoint = value.x! as number;
                            }
                            if (value.x! >= endTime && (!maxPoint || maxPoint >= value.x!)) {
                                maxPoint = value.x! as number;
                            }
                        });
                        if (minPoint !== undefined && (!min || minPoint > min)) {
                            min = minPoint;
                        }
                        if (maxPoint !== undefined && (!max || maxPoint < max)) {
                            max = maxPoint;
                        }
                        break;
                    }
                }
            }
            if (type === "zoomOut") { // if no min & max for zoomOut (cannot zoom out any more), consider them as initialStartTime & initialEndTime
                if (min === undefined) {
                    min = this.getState().initialStartTime;
                }
                if (max === undefined) {
                    max = this.getState().initialEndTime;
                }
            }
            return min !== undefined && max !== undefined && min < max ? [min, max] : undefined;
        }
    }
})

type PropsNotFromState = {
    initialSeries: Serie[],
    hasZoomSlider?: boolean,
    sliderZoomFactor?: number,
    sliderTooltipFormat?: (value: number) => string
} & LineSvgProps & { legendX?: string, legendY?: string, xScaleFormat?: string }

type Props = PropsFrom<typeof sliceResponsiveLineChartOld> & PropsNotFromState;

export class ResponsiveLineChartOld extends React.Component<Props> {

    private sliderRef = React.createRef<any>();

    static defaultProps = {
        sliderZoomFactor: 0.5,
        hasZoomSlider: true
    }

    constructor(props: Props) {
        super(props);

        this.onZoomIn = this.onZoomIn.bind(this);
        this.onZoomOut = this.onZoomOut.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onReset = this.onReset.bind(this);
        this.format = this.format.bind(this);
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    private componentDidUpdateInternal(prevProps?: Props) {
        if (!prevProps || !lodash.isEqual(prevProps.initialSeries, this.props.initialSeries)) {
            this.props.dispatchers.initState({ initialSeries: this.props.initialSeries });
        }
        if (prevProps && (!lodash.isEqual(prevProps.startTime, this.props.startTime) || !lodash.isEqual(prevProps?.endTime, this.props.endTime))) {
            this.sliderRef.current?.setState({ bounds: [this.props.startTime, this.props.endTime] });
        }
    }

    private format(value: number | undefined) {
        if (!value) {
            return "";
        }
        if (this.props.sliderTooltipFormat) {
            return this.props.sliderTooltipFormat(value);
        }
        return moment(value).format(Utils.dateFormatShorter + " " + Utils.timeFormat);
    }

    private onSliderBtnResize(width: number | undefined) {
        if (width) {
            this.props.dispatchers.setInReduxState({ chartPaddingLeft: width });
        }
    }

    private canZoomIn(startTime: number, endTime: number): boolean {
        return this.props.dispatchers.getRange("zoomIn", startTime, endTime, this.props.initialSeries, this.props.sliderZoomFactor!) !== undefined;
    }

    private canZoomOut(startTime: number, endTime: number): boolean {
        const values = this.props.dispatchers.getRange("zoomOut", startTime, endTime, this.props.initialSeries, this.props.sliderZoomFactor!);
        return values !== undefined && (values[0] !== startTime || values[1] !== endTime);
    }

    private onZoomIn() {
        const newValues = this.props.dispatchers.getRange("zoomIn", this.props.startTime, this.props.endTime, this.props.series, this.props.sliderZoomFactor!);
        if (newValues) {
            this.props.dispatchers.changeRange({ startTime: newValues[0], endTime: newValues[1], initialSeries: this.props.series });
        }
    }

    private onZoomOut() {
        const newValues = this.props.dispatchers.getRange("zoomOut", this.props.startTime, this.props.endTime, this.props.initialSeries, this.props.sliderZoomFactor!);
        if (newValues) {
            this.props.dispatchers.changeRange({ startTime: newValues[0], endTime: newValues[1], initialSeries: this.props.initialSeries });
        }
    }

    private onChange(values: any[]) {
        const newValues = this.props.dispatchers.getRange("change", values[0], values[1], this.props.initialSeries, this.props.sliderZoomFactor!);
        if (newValues !== undefined) {
            this.props.dispatchers.changeRange({ startTime: newValues[0], endTime: newValues[1], initialSeries: this.props.series });
        } else {
            this.sliderRef.current.setState({ bounds: [this.props.startTime, this.props.endTime] });
        }
    }

    private onReset() {
        this.props.dispatchers.changeRange({ startTime: this.props.initialStartTime, endTime: this.props.initialEndTime, initialSeries: this.props.initialSeries });
    }

    render() {
        const that = this;
        const props = this.props;

        const rightPadding = 45 + Math.abs(props.axisBottom?.legendOffset || 0);
        const leftPadding = 10;

        return <div className="flex-container flex-grow">
            <div className="wh100 flex-grow HistoricalMapChartComponent_chartContainer">
                <ResponsiveLineExt margin={{ top: 20, bottom: 60, right: rightPadding, left: props.hasZoomSlider ? (this.props.chartPaddingLeft + leftPadding) : 60 }} {...props}
                    data={props.series} />
            </div>
            {props.hasZoomSlider && props.startTime < props.endTime &&
                <div className="flex-container-row flex-center">
                    <Measure bounds onResize={contentRect => that.onSliderBtnResize(contentRect.bounds?.width)}>
                        {({ measureRef }) => (<div className="flex-container-row" ref={measureRef}>
                            <Button size='mini' icon positive disabled={!this.canZoomIn(props.startTime, props.endTime)} onClick={this.onZoomIn}><Icon name='zoom-in' /></Button>
                            <Button icon size='mini' negative disabled={!this.canZoomOut(props.startTime, props.endTime)} onClick={this.onZoomOut}><Icon name='zoom-out' /></Button>
                            <Button icon size='mini' color="orange" onClick={this.onReset}><Icon name='remove circle' /></Button>
                        </div>)}
                    </Measure>
                    <Slider ref={this.sliderRef} style={{ width: "100%", right: rightPadding, marginLeft: leftPadding + rightPadding, marginRight: 0 }}
                        range
                        step={1} min={props.startTime} max={props.endTime}
                        defaultValue={[props.startTime, props.endTime]}
                        onAfterChange={this.onChange} tipFormatter={this.format} />
                </div>
            }
        </div>;
    }
}
