import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";

export const positionEntityDescriptorForHistoricalMap = addEntityDescriptor(new EntityDescriptor({
    name: "PositionForHistoricalMap",
    miniFields: ["date", "longitude", "latitude", "speed", "telemetry"],
    icon: "database",
    showInUI: false
})
    .addFieldDescriptor({ name: "date", type: FieldType.date })
    .addFieldDescriptor({ name: "longitude", type: FieldType.string })
    .addFieldDescriptor({ name: "latitude", type: FieldType.string })
    .addFieldDescriptor({ name: "speed", type: FieldType.string })
    .addFieldDescriptor({ name: "telemetry", type: FieldType.string })
);

export const positionEntityDescriptorForHistoricalMapFull = addEntityDescriptor(new EntityDescriptor({
    name: "PositionForHistoricalMapFull",
    miniFields: [],
    icon: "database",
    showInUI: false
})
    .addFieldDescriptor({ name: "date", type: FieldType.date })
    .addFieldDescriptor({ name: "dateProcessed", type: FieldType.date })
    .addFieldDescriptor({ name: "gpsProvider", type: FieldType.string })
    .addFieldDescriptor({ name: "longitude", type: FieldType.string })
    .addFieldDescriptor({ name: "latitude", type: FieldType.string })
    .addFieldDescriptor({ name: "speed", type: FieldType.string })
    .addFieldDescriptor({ name: "telemetry", type: FieldType.string })
    .addFieldDescriptor({ name: "engineState", type: FieldType.string })
    .addFieldDescriptor({ name: "odometer", type: FieldType.double })
    .addFieldDescriptor({ name: "motorHour", type: FieldType.double })
    .addFieldDescriptor({ name: "accuracy", type: FieldType.string })  
);