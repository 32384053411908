import gql from "graphql-tag";

export const LOGIN = gql`
    mutation login($loginParams: LoginParamsInput!) { 
        authService_login(loginParams: $loginParams) { status userDetails { username } messageKey messageParams token }
    }
`;

export const LOGOUT = gql`
    mutation logout($oktaUserId: String) {
        authService_logout(oktaUserId: $oktaUserId)
    }
`;
