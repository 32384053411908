import { FieldRendererProps } from "../fieldRenderersEditors";
import StringFieldRenderer, { StringFieldRendererSpecificProps } from "./StringFieldRenderer";
import { Optional } from "@crispico/foundation-react";
import { Messages, FilterOperators } from "@crispico/foundation-gwt-js";
import { FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldInterval } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { addAfterStartupRunnable, enableAllFilters } from "../entityCrudConstants";
import { NavLink } from "react-router-dom";
import React from "react";
import { ShareLinkLogic } from "../ShareLinkLogic";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";

export let DropdownFieldRenderer: any;
/* TODO: CC commented this code because this renderer wasn't executed anymore when wrapped in addAfterStartupRunnable.
   To discuss with CS why he added this */
// addAfterStartupRunnable(() => 
DropdownFieldRenderer = class extends StringFieldRenderer<FieldRendererProps> {

    protected getText() {
        const { fieldDescriptor, value } = this.props;
        const fi = fieldDescriptor.getFieldInterval(value);
        return fi ? this.getDropdownLabel(fi) : value;
    }

    getDropdownLabel(fi: FieldInterval): any {
        return getDropdownItemLabel(this.props.fieldDescriptor, fi)
    }

    render() {
        const content = super.render();
        const { props } = this;
        const options = props.rendererSpecificProps as StringFieldRendererSpecificProps;

        let url = undefined;
        if (props.value && options?.asLabel) {
            url = new ShareLinkLogic().createLink(false, props.fieldDescriptor.parent, enableAllFilters(Filter.create(props.fieldDescriptor.name, FilterOperators.forString.equals, props.value)));
        }
        return url ? <NavLink to={url}>{content}</NavLink> : content;
    }
}
// );

export function getDropdownItemLabel(fieldDescriptor: FieldDescriptor, fieldInterval: FieldInterval): string {
    let label: Optional<string> =
        _msg({ missingKeyStrategy: "RETURN_NULL" }, [fieldDescriptor.parent?.name, fieldDescriptor.nameBeforeCopyToFilter || fieldDescriptor.name, fieldInterval.from].join(".")) ||
        _msg({ missingKeyStrategy: "RETURN_NULL" }, [fieldDescriptor.parent?.name, fieldDescriptor.nameBeforeCopyToFilter || fieldDescriptor.name, fieldInterval.enumOrderIndex].join(","));
    if (!label) {
        label = fieldInterval.label;
    }
    if (label) {
        label = Messages.getInstance().maybeTranslateByUser(label);
    }
    if (!label) {
        label = fieldInterval.from;
    }
    return label;

}
export default DropdownFieldRenderer;